import { apiEligibleCampaigns } from '@/resource'
import { addFEOnlyCampaign } from '@/constants/promotion'
import xmasMakeAWishStore from './xmasMakeAWish'
import firstTimeDepositStore from './firstTimeDeposit'

const state = () => ({
  eligibleCampaigns: [],
})

const mutations = {
  setEligibleCampaigns(state, value) {
    state.eligibleCampaigns = value
  },
  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      state[key] = []
    })
  },
}

const actions = {
  async actionGetEligibleCampaigns({ commit, rootState }) {
    await apiEligibleCampaigns().then(result => {
      const { data , code} = result.data
      if (code === 0 && data) {
        // api eligible campaigns + FE only campaign (please refer to src\constants\promotion.js)
        commit('setEligibleCampaigns', addFEOnlyCampaign([...data], rootState.common.countryCode))
      }
    })
  },
}

export default {
  namespaced: true,
  modules: {
    xmasMakeAWish: xmasMakeAWishStore,
    firstTimeDeposit: firstTimeDepositStore,
  },
  state,
  mutations,
  actions,
}
