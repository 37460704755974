export default {
  en: 'English',
  zh: '中文',
  fr: 'Français',
  common: {
    liveChat: { desc: '도움이 필요하신가요?실시간 채팅' },
    country: { international: '국제은행송금', australia: '호주' },
    verification: {
      toSlide: '퍼즐을 완료하려면 미세요.',
    },
    field: {
      nameOnCard: '카드소유자영문명',
      cardholderName: `카드소유자명`,
      first4Digits: '카드 앞 4자리 숫자',
      last3Digits: '카드 끝 3자리 숫자',
      first6Digits: '카드 앞 6자리 숫자',
      last4Digits: '카드 끝 4자리 숫자',
      bankName: '수취인 거래은행',
      bankAddress: '은행주소',
      branchAddress: '지점주소',
      bankBranch: '은행지점',
      bankCity: '시/군/구',
      bankProvince: '시/도',
      bankBeneficiaryName: '수취인명',
      bankBeneAccName: '수취인명',
      bankBeneAccNum: '수취인계좌번호',
      bankBeneAddress: '수취인주소',
      bankAccNum: '은행계좌번호',
      bankAccName: '은행계좌이름',
      bankHolderAddress: '예금주주소',
      address: '상세주소',
      accName: '예금주',
      accNum: '계좌번호',
      accNumTo: '계좌번호',
      bsbNum: 'BSB 번호',
      swift: '스위프트코드 (SWIFT code)',
      swiftNonAUD: '스위프트코드 (AUD 계좌 외)',
      amt: '금액',
      amtUSD: '금액 (USD)',
      availableAmount: '사용가능금액',
      transitNumber: '환승번호',
      institutionNumber: '기관번호',
      imptNotes: '비고',
      yes: '예',
      no: '아니오',
      payMethod: '지불방식 :',
      expDate: '유효기간',
      bsb: 'BSB',
      abaSortCode: 'ABA/Sort Code:',
      country_region: '국가/지역',
      currLeverage: '현재 레버리지',
      accEquity: '계좌 잔액',
      newLeverage: '신청 레버리지',
      currPw: '현재 비밀번호',
      newPw: '새 비밀번호 입력',
      confirmPw: '새 비밀번호 확인',
      loginEmail: '이메일 로그인',
      email: '이메일',
      pw: '비밀번호',
      accType: '계좌유형',
      name: '성명',
      emailAdd: '이메일',
      phone: '연락처',
      dob: '생년월일',
      nat: '국가',
      country: '거주지',
      street: '상세주소',
      suburbOrCity: '시/도',
      provinceOrState: '시/군/구',
      postcode: '우편번호',
      empStat: '고용상태',
      annIncome: '연수입',
      save: '투자',
      source: '소득근원',
      industry: '직업',
      statusDef: '처리현황설명',
      month: '월',
      year: '년도',
      select: '선택',
      completed: '승인',
      incomplete: '미완료',
      submitted: '제출됨',
      successful: '성공',
      processing: '처리중',
      cancelled: '취소됨',
      failed: '실패',
      rejected: '거절',
      upload: '업로드',
      pending: `보류 중`,
      ccNum: '신용카드번호',
      ccExpireDate: '유효기간',
      bankCardNumber: '은행카드번호',
      cardHolderName: '카드소유자영문명',
      reservedMobileNumber: '은행에 등록된 핸드폰 번호',
      nationalId: '주민등록증',
      cardPhoto: '카드 앞면 사진',
      notes: '비고',
      bankAccOrIBAN: '은행계좌번호/IBAN',
      selectWithdrawCard: '출금하고자 하는 카드를 선택 하십시오.',
      updateCardPhoto: '카드 앞면 사진을 업로드 하십시오.',
      actualAmount: '실제 금액',
      resetBalTo: '다음 금액으로 잔액 재설정',
      ipChangeWarning: '다른 위치에서 계정에 로그인되면 알림 이메일을 받게 됩니다',
      enterVerificationCode: '확인 코드 입력',
      tips: '',
      pleaseInput: '',
      confirmClose: '',
      notBind: '',
      infoDetails: '',
      loginWarn: '',
      noDataNow: '',
      hasAccountAlready: '',
    },
    column: {
      date: '일시',
      type: '유형',
      server: '서버',
      status: '처리현황',
      comment: '비고',
      accNum: '계좌번호',
      currency: '통화',
      equity: '잔액',
      leverage: '레버리지',
      resetMT4PW: 'MT4 비밀번호 재설정',
      resetMT5PW: 'MT5 비밀번호 재설정',
      tradingAcc: '거래 계좌',
      method: '방식',
      amt: '금액',
      procNote: '처리정보',
      number: '카드번호',
      PLATFORM: '플랫폼',
      resetBal: '잔액 재설정',
    },
    key: { from: '보내는계좌', to: '받는계좌' },
    fund: {
      must: '필수사항',
      infoHover: '카드 영문명은 {must} 당사의 계좌 개설시의 영문명과 동일해야 합니다.',
      ccUploadReminder:
        '최초 인증을 위해 카드 앞 면의 사진을 업로드 하십시오. (사진 촬영시 카드 중간 6자리를 가려주시길 바랍니다.)',
    },
    button: {
      bkToHm: '처음으로 돌아가기',
      smsLogin: '로그인 전화 번호',
      goToIB: '파트너포털접속',
      logout: '로그아웃',
      submit: '제출하기',
      selectFile: '찾아보기',
      depositFunds: '자금예치',
      withdrawFunds: '출금신청',
      ibPortal: '파트너포털',
      download: '다운로드',
      changeLeverage: '레버리지변경',
      cancel: '취소',
      changePw: '비밀번호변경',
      forgetPw: '비밀번호찾기',
      confirm: '확인',
      login: '로그인',
      forgotPw: '비밀번호찾기',
      completeQuestionnaire: '설문지작성',
      identityProof: '지원서작성',
      upload: '업로드',
      export: '내보내기',
      ok: '확인',
      hide: '숨기기',
      showAll: '모두 보기',
      createLiveAccount: `라이브 계정 만들기`,
      redeem: `보내기`,
      sendCode: '코드 발송',
      resendCode: '코드 재전송',
    },
    formValidation: {
      accReq: '계좌번호를 입력하십시오.',
      amtReq: '금액을 입력하십시오.',
      ccReq: '신용카드번호를 입력하십시오.',
      cardReq: '카드번호를 입력하십시오.',
      cardOnNameReq: '카드상 영문명을 입력하십시오.',
      cardholderNameReq: `카드소유자명은 필수 입력사항입니다.`,
      digits16: '카드 16자리 숫자를 입력하십시오.',
      digits3or4: '숫자 3자리 혹은 4자리를 입력하십시오.',
      digitsFirst6: '첫 6자리를 입력하십시오.',
      digitsLast4: '뒷 4자리를 입력하십시오.',
      digitsLast3: '뒷 3자리를 입력하십시오.',
      mid6Digits: '중간 {숫자}자리를 입력하십시오.',
      expDateReq: '유효기간을 입력하십시오.',
      cvvReq: 'CVV보안코드를 입력하십시오.',
      file: '파일을 업로드 하십시오.',
      filePhoto: '신용카드 사진을 업로드 하십시오.',
      fileReceipt: '영수증을 업로드 하십시오.',
      filePhotoReq: '카드 사진을 업로드 하십시오.',
      amtLarger: '금액은 빈칸으로 제출할 수 없으며 {minLimit} {currency}보다 크거나 같아야 합니다.',
      amt0: '0 이상의 금액을 입력하십시오.',
      amtLess: '1회 최대 입금액은 {maxLimit} 입니다.',
      note256: '글자수는 최대 256자 입니다.',
      noteMoreThan: '글자수는 최대 {limit}자 입니다.',
      noteLessThan: '글자수는 최대 {limit}자 입니다.',
      emailFormat: '올바른 이메일을 입력해 주십시오.',
      payMethodReq: '올바른 지불방식을 선택해 주십시오.',
      expReq: '유효기간을 입력하십시오.',
      bankNameReq: '은행명을 입력하십시오.',
      bankCityReq: '은행주소(City시/구/군)를 입력하십시오.',
      bankProvinceReq: '은행주소(Province 시/도)를 입력하십시오.',
      bankAddressReq: '은행주소(상세주소)를 입력하십시오.',
      bankBranchReq: '은행지점 (Bank Branch)을 입력하십시오.',
      bankAccNumReq: '은행계좌번호를 입력하십시오.',
      bankBeneficiaryNameReq: '계좌예금주를 입력하십시오.',
      bankHolderAddressReq: '계좌예금주의 주소를 입력하십시오.',
      swiftReq: '스위프트코드를 입력하십시오.',
      accHolderAddress: '계좌예금주의 주소를 입력하십시오.',
      bsbReq: 'BSB를 입력하십시오.',
      accNumIBANReq: '은행계좌번호/IBAN 번호를 입력하십시오.',
      accNameReq: '은행계좌번호를 입력하십시오.',
      withdrawMethodReq: '출금방식을 선택하십시오.',
      tnc: '약관을 읽고 동의하시길 바랍니다.',
      currPwReq: '기존 비밀번호를 입력하십시오.',
      currPwWrong: '기존 비밀번호가 올바르지 않습니다.',
      newPwReq: '새 비밀번호를 입력해주십시오.',
      newPwFormat:
        '암호는 8-16자 사이여야 하며 문자(a~z 및 A~Z), 숫자(0~9) 및 !@#$%^&*.() 와 같은 특수 문자가 혼합되어 있어야 합니다.',
      IdenficalPw: '새 비밀번호는 기존 비밀번호와 다르게 설정하십시오.',
      confirmPwReq: '새 비밀번호를 다시 입력해주십시오.',
      confirmNotMatch: '두 입력이 일치하지 않습니다.',
      pwReq: '비밀번호를 입력하십시오.',
      accCurrReq: 'MT4 계좌 통화를 선택하십시오.',
      tradAccReq: '거래 계좌를 선택하십시오.',
      answerReq: '선택하기',
      bankCardReq: '카드를 선택하십시오.',
      phoneNumberReq: '은행에 등록된 핸드폰 번호를 입력해 주십시오.',
      nationaIdReq: '유효한 신분증번호를 입력해 주십시오.',
      numberReq: '번호를 입력해주십시오.',
      alphanumericReq: '영숫자를 입력해주십시오.',
      idTypeReq: '신분증명유형을 선택해주십시오.',
      dobReq: '생년월일을 선택해주십시오.',
      pobReq: '출생지를 선택해주십시오.',
      nationalityReq: '국적을 선택해주십시오.',
      titleReq: '호칭을 선택해주십시오.',
      firstNameReq: '이름은 필수입력 사항입니다.',
      lastNameReq: '성은 필수입력 사항입니다.',
      emailReq: '이메일을 입력해주십시오.',
      addressReq: '상세 주소를 입력해주십시오.',
      suburbReq: '시/군/구를 입력해주십시오.',
      stateReq: '시/도를 입력해주십시오.',
      postcodeReq: '우편번호를 입력해주십시오.',
      countryReq: '거주 국가를 입력해주십시오.',
      masterCardReq: '입금하려면 마스터 카드를 사용하십시오. ',
      masterCardJCBReq: `마스터 카드 / JCB로 입금 해주세요.`,
      masterCardVisaReq: `마스터 카드 / Visa로 입금 해주세요.`,
      masterCardAmexJCBReq: `Master Card / AMEX / JCB를 사용하여 입금하세요.`,
      bankCodeReq: '카드발급은행을 입력해주십시오.',
      dynamicReq: '{dynamic} 은 필수입력 사항입니다.',
      mobReq: '전화 번호 입력해주십시오.',
      resetBalAmt: '0에서 1,000,000 사이의 금액을 설정하세요',
      verCodeReq: '확인 코드가 필요합니다',
      emailFormatWrong: '잘못된 이메일 형식',
      passwordFormatWrong: '잘못된 암호 형식',
    },
    // for WITHDRAW FUNDS
    withdrawChannel: {
      banktransferbpaypolipay: '은행송금/Bpay/PoliPay',
      banktransfer: '은행송금',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: '유니온페이',
      unionpaytransfer: '유니온페이이체',
      fasapay: 'FasaPay',
      creditcard: '신용카드 ',
      bpay: 'Bpay',
      creditdebitcard: '신용/체크 카드',
      bankwiretransferinternational: '해외송금(국제)',
      banktransferaustralia: '은행송금(오스트레일리아)',
      banktransferinternational: '은행송금(국제)',
      banktransferchina: '은행송금(국제)',
      banktransferequals: '은행송금(국제)',
      mobilepay: '모바일결제',
      brokertobrokertransfer: '브로커 간 이체',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: '인터넷뱅킹 (태국)',
      internetbankingnigeria: '인터넷뱅킹 (나이지리아)',
      internetbankingvietnam: '인터넷뱅킹 (베트남)',
      internetbankingmalaysia: '인터넷뱅킹 (말레이시아)',
      internetbankingindonesia: '인터넷뱅킹 (인도네시아)',
      internetbankingkorea: '인터넷뱅킹 (한국)',
      internetbankingphilippine: '인터넷뱅킹 (필리핀 제도)',
      internetbankingsouthafrica: '인터넷뱅킹 (남아프리카)',
      internetbankinguganda: '인터넷뱅킹 (우간다)',
      internetbankingrwanda: '인터넷뱅킹 (르완다)',
      internetbankingzambia: '인터넷뱅킹 (잠비아)',
      internetbankingcongo: '인터넷뱅킹 (콩고)',
      internetbankingcameroon: '인터넷뱅킹 (카메룬)',
      internetbankingburundi: '인터넷뱅킹 (부룬디)',
      internetbankingkenya: '인터넷뱅킹 (케냐)',
      internetbankingghana: '인터넷뱅킹 (가나)',
      internetbankingtanzania: '인터넷뱅킹 (탄자니아)',
      internetbankingcanada: '인터넷뱅킹 (캐나다)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: '베트남 은행송금이체',
      nigeriabanktransfer: '나이지리아 은행송금이체',
      polipayment: 'Poli Payment',
      bankwiretransferaustralia: '은행송금 (오스트레일리아)',
      thailandinstantbankwiretransfer: '태국 은행 계좌이체',
      nigeriainstantbankwiretransfer: '나이지리아 은행계좌이체',
      vietnaminstantbankwiretransfer: '베트남 은행계좌이체',
      malaysiainstantbankwiretransfer: '말레이시아 은행계좌이체',
      indonesiainstantbankwiretransfer: '인도네시아 은행계좌이체',
      southkoreabanktransfer: '한국 현지 은행 송금',
      southafricainstantbankwiretransfer: '남아프리카 은행계좌이체',
      philippinesinstantbankwiretransfer: '필리핀 제도 은행계좌이체',
      ugandabanktransfer: '우간다 국내 은행이체',
      rwandabanktransfer: '르완다 국내 은행 이체',
      zambiabanktransfer: '잠비아 국내 은행 이체',
      congobanktransfer: '콩고 국내 은행 이체',
      cameroonbanktransfer: '카메룬 국내 은행 이체',
      burundibanktransfer: '부룬디 국내 은행 이체',
      kenyabanktransfer: '케냐 국내 은행 이체',
      ghanabanktransfer: '가나 국내 은행 이체',
      tanzaniabanktransfer: '탄자니아 국내 은행 이체',
      indiabanktransfer: '인도 은행송금이체',
      banktransferbpaypolipayaustralia: '은행송금/BPay/PoliPay (오스트레일리아)',
      banktransferbpaypolipayinternational: '은행송금/BPay/PoliPay (국제)',
      paypal: '페이팔',
      canadianlocalbanktransfer: '캐나다 로컬 은행 계좌 이체',
      cryptobitcoin: '암호 화폐-Bitcoin',
      cryptotetheromni: '암호 화폐-Tether(OMNI)',
      cryptotethererc20: '암호 화폐-Tether(ERC20)',
      cryptotethertrc20: '암호 화폐-Tether(TRC20)',
      cryptocurrencybitcoin: '암호 화폐-Bitcoin',
      cryptocurrencyusdt: '암호 화폐-USDT',
      cryptocurrencyeth: '암호 화폐-ETH',
      cryptocurrencyusdc: '암호 화폐-USDC(ERC-20)',
      cryptocurrencyusdcerc20: '암호 화폐-USDC(ERC-20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      E_WALLET: '전자 지갑',
      CRYPTOCURRENCY: '암호 화폐',
      LOCAL_TRANSFER: '로컬 전송',
      archiveThisCard: '이 카드가 더 이상 유효하지 않거나 사용 중인 경우 보관하십시오.',
      manageCreditCards: '신용 카드 관리',
      update: '업데이트',
      archive: '보관',
      requiredDocument: '카드 보관에 필요한 문서 ',
      officialLetter: '카드를 더 이상 사용할 수 없다는 은행 편지/이메일 명시',
      bankStatement: '이 카드로 이루어진 거래가 있는 은행 명세서',
      expiredCard: '만료/사용할 수 없는 카드 ',
      confirmArchive: '이 카드를 보관하시겠습니까?',
      confirmUpdate:
        '이 카드의 만료 날짜를업데이트하시겠습니까?참고:제공하신 만료 날짜가 잘못되어 인출에 실패할 경우 Puprime는 책임을 지지 않습니다.',
      pendingArchiveRequest:
        '귀하의 신용카드 보관 요청은 현재 승인 대기 중입니다. 절차가 완료될 때까지 기다린 후 인출하거나 신속한 처리를 위해 실시간 채팅을 통해 고객 서비스에 문의하세요.',
    },
  },
  home: {
    pendingTooltip:
      '실 계좌가 승인되면 거래를 시작할 수 있도록 MT{number} 로그인 세부 정보가 포함된 이메일이 전송됩니다! 심사 승인전에 미리 자금을 예치한다면 우선적으로 계좌를 심사하여 개설해 드립니다.',
    leverage: {
      tnc:
        '<p>본인은 이 거래 약관에 동의함으로써 증거금을 동반한 거래 상품의 운용은 고 위험 투자 방식이며, 높은 위험성을 수반하고, 손실 규모는 예치한 자금에 국한되지 않고 더 큰 손실을 입힐 수 있다는 것을 알고 있음을 인정합니다.<br><br>또한 상품 발행자는 사전 경고나 알림 없이 제 거래 활동을 검토하고 거래 계좌의 레버리지 수준을 재량에 따라 조정할 수 있다는 데에 동의합니다.<br><br>높은 레버리지를 선택함으로써 적은 증거금을 활용할 수 있으나 잠재적으로 상당한 손실을 초래할 수 있다는 것을 이해합니다.</p>',
      changeLeverageConfirm: '귀하의 요청은 정상적으로 제출되었으며 곧 담당부서팀에서 처리할 예정입니다.',
      changeLeverageAutoComplete: '레버리지 변경 요청이 완료되었습니다.',
      changeLeverageReject: '레버리지 변경 요청이 거부되었습니다.',
      changeLeverageFail: '레버리지 변경 요청이 실패했습니다. 나중에 시도하십시오.',
      changeLeverageDefault: '현재 오류가 발생했습니다. 귀하의 신청을 완료하려면 {supportEmail}에 문의하십시오.',
      alreadyApplied:
        '귀하의 계좌에 대한 레버리지 변경을 이미 요청했으며 현재 {platform} 담당 부서에서 검토 중입니다. 다른 요청을 제출하기 전에 이 요청이 완료될 때까지 기다려 주십시오.',
      reduceLeverageWarn: `참고: 계정에 충분한 증거금이 없는 경우, 레버리지를 줄이면 현재 포지션이 강제로 닫힐 수 있습니다.`,
      reduceLeverageConfirm: `계속 하시겠습니까?`,
      autoRestore: `귀하의 계좌 자산이 20,000달러를 초과했기 때문에 귀하가 사용할 수 있는 최대 레버리지는 500:1입니다. 계좌 자본이 20,000달러 이하인 경우 레버리지를 1000:1로 변경할 수 있습니다.`,
    },
    pw: {
      error: '비밀번호를 재설정하지 못했습니다. 나중에 다시 시도해 주세요.',
      confirm:
        '귀하의 비밀번호가 재설정되었습니다.<br>이제 새 비밀번호를 사용하여 MT4/MT5 거래 계좌에 접속 할 수 있습니다.',
      email:
        '<p class=first_info">귀하의 이메일로 비밀번호 재설정 링크가 포함된 이메일이 발송됬습니다. </p><p>이메일의 스팸 폴더를 확인하세요.</p>',
    },
    activate: {
      question: '실 계좌를 다시 활성화 하시겠습니까?',
      confirm: '귀하의 활성화 요청을 받았습니다.<br>당신의 계좌는 곧 활성화될 것입니다.',
    },
    accStatus: {
      active: '활성화',
      rejected: '거절',
      inactive: '비활성화',
      processing: '처리중',
      activate: '활성화됨',
      expired: '만료됨',
    },
    pro1000: {
      desc1: `레버리지가 1000:1인 계좌에는 다음과 같은 제한이 적용됩니다.`,
      conditions: [
        `해당 계좌는 크레딧 관련 프로모션에 참여할 수 없습니다.`,
        `해당 계좌의 자본이 20,000달러 또는 그에 상응하는 금액을 초과할 경우 시스템에 의해 레버리지가 자동으로 1:500으로 낮아질 수 있습니다. 보유중인 포지션이 있는 경우에는 레버리지 조정 전에 이메일 알림이 전송됩니다.`,
        `레버리지 제한 적용은 해당 규정에 따라 달라질 수 있습니다.`,
        `가상화폐, 주식, 은, 상품군에 속하는 거래상품에는 1:1000 레버리지가 제공되지 않습니다. 상기 금융상품들의 마진은 고정적으로 마진 요구사항에 따라 유지되며, 1:1000 레버리지 적용에 제외되며, 영향을 받지 않습니다.`,
      ],
      desc2: `본 요청을 제출함으로써 높은 레버리지 적용이 자본에 큰 위험을 초래한다는 것을 알고 있으며, 예치한 자본보다 크고 제한되지 않는 손실을 감수할 수 있음에 동의합니다.`,
      desc3: `본인은 상품의 발행인이 사전 경고나 통지 없이 재량에 따라 거래 활동을 검토하고 본인의 거래 계좌 레버리지 수준을 조정할 권리가 있다는 것에 동의합니다.`,
      desc4: `높은 레버리지를 선택하면 더 많은 마진으로 거래할 수 있지만 잠재적으로 상당한 손실을 입을 수 있다는 것을 이해합니다.`,
    },
  },
  paymentDetails: {
    subHeader: '지불방식추가',
    payMethod: '지불방식 ',
    tip: `<p>아래 메뉴에서 선호하시는 입출금 방식을 선택하십시오.</p><br />
    <p><b>주의: </b> 귀하의 입출금 요청이 신속하게 처리될 수 있도록 하기 위해 AML/CTF 규정상 선택한 지불 방법에 표시된 이름이 <b> {individualUserName}</b> 로 되어 있어야 합니다.</p>`,
    result: {
      defaultSuccessMessage:
        '거래 계좌에 대한 입 출금 방법을 제출해 주셔서 감사합니다. 현재 귀하의 요청을 처리 중이며 곧 연락드리겠습니다.',
      unionPayCardSuccessMessage:
        '유니온 페이 카드 세부 정보가 성공적으로 제출되었습니다. 담당부서에서 새로운 은행 세부 정보를 승인하면 바로 사용 가능합니다.',
    },
    history: { header: '지불방식' },
    cc: {
      field: {
        ccNum: '신용카드번호',
        first6: '첫번째 여섯번째',
        last4: '마지막 네번째',
        withrawlable: '이 카드의 최대 인출 가능 금액은 {amount}입니다.',
      },
      formValidation: { upload: '신용카드 사진을 업로드 하십시오.' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: '예치 상황을 가져오는 중입니다... {countDown}초 남았습니다.',
      successMsg: '입금해 주셔서 감사합니다. 귀하의 입금 금액은 곧 계좌에 반영될 예정입니다. 잠시만 기다려주십시오.',
      rejectMsg: '거절',
      failureMsg: '귀하의 입금이 성공하지 못했습니다. 다시 시도해 주십시오.',
      message:
        '입금해 주셔서 감사합니다. 곧 {platform}담당부서에서 귀하의 예치금을 수동으로 승인할 것입니다. 자금이 승인되면 고객님의 계좌로 반영될 것입니다.',
      defaultException:
        '{platform}의 귀하의 예치금을 확인이 필요하시면, 고객지원팀 {email}이메일로 연락하시기 바랍니다.',
    },
    failed: { countDownMsg: '예치 상황을 가져오는 중입니다... {countDown}초 남았습니다.', rejectMsg: '거절' },
  },
  deposit: {
    alert: {
      depositBonus50: `50% 상당의 크레딧 보너스를 받으실 수 있습니다. 꼭 입금 전 프로모션을 활성화 해주세요!`,
    },
    default: {
      deposit: '자금예치',
      reference: '참고 사항',
      sendReceipt: '송금 내역서',
      sendReceiptTip:
        '해외 송금 완료 후 아래 양식을 작성하시고 귀하의 계좌 번호와 입금 금액이 명확히 표시된 은행 송금 내역서의 사진 혹은 스캔본을 첨부하여 담당 부서가 신속하게 처리 할 수 있도록 해 주시기 바랍니다.',
      reminder: '귀하의 입금 반영 처리는 영업일 1~2일 정도 소요될 예정입니다. 양해 부탁드립니다.',
      successMsg:
        '귀하가 송금내역서를 업로드 후 당사의 담당부서에서 자금을 확인하면 귀하의 계좌에 반영 처리하게 되며 계좌상에 해당 예치금액이 나타납니다.',
      alt: '또는 {mailTo}로 송금 내역서를 첨부하여 이메일로 보내셔도 됩니다.',
      reminderFundAppear: '이전 브로커로부터 자금을 받는 즉시 귀하의 계좌상에 해당 자금이 표시됩니다.',
      failed: '귀하의 입금 신청이 실패했습니다. 잠시 후 다시 시도해 주십시오.',
      fee: '수수료',
      instant: '즉시',
      hour: '시간',
      hours: '소요시간',
      businessDays: '영업일',
      withinOneBusinessDays: '영업일 1일 이내',
      secondaryNGN: '(나이지리아 나이라(NGN) 통화를 사용하려는 경우, 거래 시 해당 계좌번호를 사용할 수 있습니다.)',
      disclaimer: `<li>{platform}에 예치하신 자금은 기밀 보장되며, 귀하의 거래 계좌에서 입 출금시에만 사용됩니다. 모든 전자 결제는 SSL (Secure Socket Layer, 개인정보유지프로토콜) 기술로 처리되고보안이 보장되도록 암호화 되어 처리됩니다.</li>
      <li>{platform}은 본인 명의 외의 제3자의 지불을 엄격하게 금지하고 있습니다. 귀하의 거래 계좌에 입금되는 모든 자금은 {platform} 개설시 등록된 본인 명의로만 진행되어야 합니다.</li>
      <li>입금 신청을 제출하는 것이 {platform}에  자금 수령을 구성하는 것은 아닙니다. 이 점을 귀하는 숙지 하고 있어야만 합니다. 만약 귀하의 계좌가 마진콜에 가깝거나 마진콜에 있다면, 다른 준비가 되어 있는지 확인하셔야 합니다.</li>
      <li>{platform}은 결제 시스템으로 인한 지연 및 에러로 인한 어떠한 책임을 지지 않습니다.</li>
      <li>{platform}은 고객 입출금에 대해 별도의 수수료를 부과하지 않습니다. 단, 금융기관 혹은 페이먼트사측에서 귀하에게 환전 수수료 및 중개은행간 서비스 수수료가 부과될 수는 있으며, 이러한 이유로 발생하는 비용에 대하여 당사는 어떠한 책임도 지지 않습니다.</li>
      <li>고객은 입금 요청시 처리할 때 {platform}이 자금 출처를 식별하는 데 필요하다고 판단하는 기타 정보, 일반적인 고객의 재산에 대한 정보를 제공해야 할 수 있음을 인정하고 {platform}이 요구하는 모든 정보를 제공하는 데 동의합니다. 필요한 정보를 제공하지 않으면 요청 프로세스가 상당히 지연될 수 있습니다. {platform}은 이러한 지연으로 인해 발생하는 입금 지연 및 손실에 대해 책임을 지지 않습니다.</li>`,
      channels: {
        creditOrDebit: '신용/체크 카드',
        i12BankTransfer: '해외송금',
        unionPay: '유니온페이결제',
        unionPayCN: '유니온페이계좌이체',
        vietInstant: '베트남 은행 즉시이체',
        thaiInstant: '태국 은행 계좌이체',
        nigeriaInstant: '나이지리아 은행 즉시이체',
        malayInstant: '말레이시아 은행 즉시이체',
        indonesiaInstant: '인도네시아 은행 즉시이체',
        philippineInstant: '필리핀 제도 은행 즉시이체',
        mobilePay: '모바일결제',
      },
      rate: {
        rate: '환율({oldCurrency}/{newCurrency}): ',
        result: {
          rateError: '{oldCurrency}를 {newCurrency} 로 전송이 실패했습니다.',
          channelError: '결제 채널을 가져오지 못했습니다.',
        },
      },
      cn: { rate: '환율(USD/RMB):', amt: 'RMB:' },
      upgrade:
        '현재 이 지불방식은 업데이트 중에 있으며, 해당 지불방식을 통해 입금한 내역이 있는 고객의 경우만 사용 가능합니다.',
      depositInstruction: '예치시 주의사항',
      depositNote: '예금주',
    },
    method: {
      desc: '{method} 계좌에 입금하는 방법',
      inst1: `{number}.   아래의 양식을 작성한 후 '제출하기' 버튼을 눌러 예치를 진행하세요.`,
      inst2: `{number}.   은행 선택 페이지에서 귀하의 {country} 은행을 선택한 후 해당 은행의 온라인뱅킹에 로그인 합니다.`,
      inst3: '{number}.   지시에 따라 온라인/모바일 뱅크를 통해 다음단계로 넘어가 결제를 진행합니다.',
      inst4: '{number}.   다음의 양식으로 이동하여 카드 세부정보를 입력해주십시오',
      inst5: '{number}.   신용카드 정보를 입력 후 "제출"버튼을 클릭해주십시오',
      inst6: '{number}.   고객 포털 사이트로 이동 후 거래가 완료됩니다',
      inst7: `{number}.   통화가 {currency} 달러가 아닌 경우 은행에서 현재 은행 환율에 따라 통화 변환 수수료를 부과 할 수 있습니다.`,
      inst8: `{number}.   EUR, GBP, AUD, SGD, NZD, HKD, JPY 및 USD를 사용한 신용 카드 입금은 거래 당 최대 $ 5,000USD로 제한됩니다. CAD로 표시된 신용 카드 입금은 거래 당 최대 $ 6,500CAD까지 허용됩니다`,
      inst9: `{number}.   1회 최대 예치금 $1500 USD`,
      inst10: `{number}.   결제 세부 정보 페이지가 팝업창으로 나타납니다.`,
      inst11: `{number}.   은행 선택 페이지에서 은행을 선택하고 온라인 뱅킹에 로그인합니다.`,
      inst12: `{number}.   당사는 CAD 결제를 받을 수 있습니다.예치 금액은 1회 최대 $9,999 CAD 입니다.`,
      form: {
        header: '{method} 신청양식',
      },
    },
    reminder: {
      inst1: `아래의 양식을 작성한 후 '제출하기' 버튼을 눌러 예치를 진행하세요.`,
      inst2: '귀하의 {method}계정에 로그인 하십시오.',
      inst3: '{method} 계정의 메시지에 따라 결제를 완료합니다.',
      inst4: '다음의 양식으로 이동하여 카드 세부정보를 입력해주십시오',
      inst5: '신용카드 정보를 입력 후 "제출"버튼을 클릭해주십시오',
      inst6: '고객 포털 사이트로 이동 후 거래가 완료됩니다',
      inst7: '{method} 지갑 주소가 표시된 새 창이 나타납니다.',
      inst8:
        '{method} 지갑 주소를 복사한 다음 개인 {method} 지갑에 로그인하여 원하는 금액을 복사한 {method} 주소로 전송합니다.',
      inst9: '지원하는 모바일 어플리케이션을 열어 QR코드를 스캔합니다.',
      inst10: '모바일 장치의 안내에 따라 예치금 결제를 완료합니다.',
      inst11: '자금 손실을 방지하기 위해 재 연결된 결제 페이지를 통해 온라인으로 결제를 시도하십시오.',
      inst12: '은행 선택 페이지에서 은행을 선택하고 온라인 뱅킹에 로그인합니다.',
      inst13: '지시에 따라 온라인/모바일 뱅크를 통해 다음단계로 넘어가 결제를 진행합니다.',
      inst14:
        '입금 후 아래 양식을 작성하시고, 고객님의 성함, 계좌번호, 입금 금액이 명확하게 표시된 이체 내역서 혹은 영수증 사본을 사진 촬영 후 첨부하여 업로드를 진행하십시오.',
      inst15:
        '해외 전신환 송금은 당사에 도착까지 평균 영업입 2-5일이 소요되며, 제출해주신 이체 영수증을 기반으로 담당 부서에서 귀하의 입금을 확인하여 신속하게 반영드리겠습니다.',
      inst16: '예치하신 금액은 귀하의 거래 계좌에 반영까지 영업일 1일이 소요될 예정입니다.',
      inst17: '거래를 처리할 수 있도록 귀하의 전체 이름, 계좌 번호 및 예금 금액이 명확히 표시되어 있는지 확인하십시오.',
      inst18: '귀하의 계좌로 자금이 입금될 때까지 1~6시간을 기다려 주십시오.',
      note1: '{method} 예치 한도는 회당 $ 25,000달러, 일내 최대 $ 75,000달러입니다.',
    },
    southAfrica: { header: '남아프리카 은행 즉시이체', country: '남아프리카' },
    idn: { header: '인도네시아 은행 즉시이체', country: '인도네시아' },
    ida: { header: '인도 은행 즉시이체', country: '인도' },
    nigeria: { header: '나이지리아 은행 즉시이체', country: '나이지리아' },
    thailand: { header: '태국 은행 계좌이체', country: '태국' },
    malay: { header: '말레이시아 은행 즉시이체', country: '말레이시아' },
    php: { header: '필리핀 제도 은행 즉시이체', country: '필리핀 제도' },
    vietnam: { header: '베트남 은행 즉시이체', country: '베트남' },
    korea: { header: '한국 현지 은행 송금' },
    uganda: { header: '우간다 국내 은행이체', country: '우간다' },
    rwanda: { header: '르완다 국내 은행 이체', country: '르완다' },
    zambia: { header: '잠비아 국내 은행 이체', country: '잠비아' },
    congo: { header: '콩고 국내 은행 이체', country: '콩고' },
    cameroon: { header: '카메룬 국내 은행 이체', country: '카메룬' },
    burundi: { header: '부룬디 국내 은행 이체', country: '부룬디' },
    kenya: { header: '케냐 국내 은행 이체', country: '케냐' },
    ghana: { header: '가나 국내 은행 이체', country: '가나' },
    tanzania: { header: '탄자니아 국내 은행 이체', country: '탄자니아' },
    creditOrDebit: {
      header: '신용카드',
      worldcard: {
        note1:
          'EUR, GPB, USD를 이용한 신용카드 예치는 회당 최대 $20,000으로 제한됩니다.USD외 결제되는 통화는 USD와 동등한 금액으로 제한됩니다.',
        note2: '사용자당 3개의 카드까지 결제 가능합니다. 한도를 초과하는 예치 시도는 승인되지 않을 것 입니다.',
      },
      virtualPay: {
        note1:
          'EUR, GBP, AUD, SGD, NZD, HKD, JPY, USC 및 USD를 사용하는 신용 카드 예치는 1회당 최대 10,000 USD로 제한됩니다. ',
        note2:
          '24시간 이내에 카드당 최대 50,000달러 또는 최대 10건의 승인이 가능합니다. 한도를 초과하는 예치 시도는 승인되지 않습니다.',
      },
      Sdpay: {
        note1:
          'EUR, GBP 및 USD를 사용하는 신용 카드 예치는 거래당 최대 $2,000 USD 또는 이에 상응하는 통화로 제한됩니다. ',
        note2: '하루에 최대 8건의 예치를 처리합니다. 한도를 초과하는 예치 시도는 승인되지 않습니다.',
        note3:
          '최초 결제 시 보류가 적용될 수 있습니다. 당사의 관련 부서에서 귀하의 지불을 수기로 확인하는 동안 잠시만 기다려 주십시오. 긴급한 경우 고객 지원 팀에 문의하여 추가 지원을 받을 수 있습니다.',
      },
      maxisPay: {
        note1: 'USD를 사용한 신용 카드 보증금은 거래당 최대 $5,000USD로 제한됩니다',
      },
    },
    net: {
      header: 'Neteller',
      note1: 'AUD(호주달러), USD(미국달러), EUR(유로), CAD(캐나다달러) 및 GBP(파운드)로 결제 가능합니다.',
      note2: '계좌간 이체는 각 MT4 거래 계좌의 통화가 동일해야만 자금을 이체할 수 있습니다.',
      note3:
        'Neteller(넷텔러)는 잘못된 이메일을 사용할 경우 자동으로 자금을 계좌의 기준 통화로 변환합니다. 수수료가 적용될 수 있습니다.',
      note4: '자금 예치시 PU Prime에 등록된 이름이여만 정상적으로 처리됩니다.',
    },
    vload: {
      header: 'VLOAD 입금',
      inst1: '2.   USD, EUR, JPY로 결제 가능합니다.',
      inst2: `<li>3.   Vload 상품권 구매, 클릭하십시오 <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">여기를</a>.</li>`,
      form: {
        voucherpin: '바우처 핀',
        voucherpinReq: '바우처 PIN이 필요합니다',
      },
    },
    bankWireAu: {
      header: '은행계좌이체: 오스트레일리아',
      instruction:
        '해외송금은 귀하의 명의로 개설된 은행 온라인 뱅킹을 통해 {platform}에 이체를 진행하거나 혹은 직접 은행창구로 방문하여 {platform}에 전신환 송금을 통해 이체를 진행하면 됩니다.',
      tip: '입금하고자 하는 계좌통화를 선택하세요. 거래 계좌의 통화와 동일한 통화를 선택하십시오.',
      form: {
        bankDetails: '은행 세부 정보(AUD 예치만 해당)',
        refTip: '여기에 귀하의 로그인 계좌를 남겨주시길 바랍니다.',
        afp: '호주내 입금방식(호주 국내 AUD 예치만 가능)',
        payIdOrABNNum: 'PayID/ABN Number',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip: '은행 송금 참조 필드에 {플랫폼} 거래 계좌 번호를 포함하세요.',
      },
    },
    bPay: {
      header: 'Bpay 입금',
      option: 'BPay로 입금 하는 방법 (호주만 해당)',
      inst1: '1. MT4/MT5 계좌를 선택하여 BPay Biller Code 및 Reference Number를 확인합니다.',
      inst2:
        '2. 결제 세부 정보, BPay Biller Code 및 Reference Number를 인터넷 뱅킹에서 입력하고 결제 정보를 제출합니다.',
      inst3:
        '3. 이 페이지로 돌아가서 아래 BPay 신청양식을 작성하십시오. 담당부서에서 귀하의 요청을 신속하게 처리할 수 있도록 할 것 입니다.',
      form: {
        header: 'BPay 신청양식',
        billerCode: 'BPay Biller Code',
        refNum: 'BPay Reference Number',
        receipt: 'Bpay 이체 영수증',
        reminderNotUploaded:
          '귀하는 이체 영수증을 업로드하지 않았습니다. 신속한 입금 처리를 위해 가능한 한 빨리 영수증 사본을 {mailTo}로 첨부하여 보내주시기 바랍니다.',
        reminderProc: '참고 : 이체 영수증을 받기 전까지는 요청을 처리할 수 없습니다.',
      },
    },
    bitwallet: {
      header: 'Bitwallet',
      note1: 'USD(미국달러), AUD(호주달러), EUR(유로), USC(미국센트), JPY(일본엔)로 결제 가능합니다.',
    },
    astropay: {
      desc: 'ASTROPAY 입금방법',
      note1: '모든 결제 통화로 지불할 수 있습니다.',
      form: { header: 'ASTROPAY 신청양식' },
    },
    b2b: {
      header: '브로커 간 이체',
      option: '타사 브로커로부터 {platform}로 자금을 이체 신청 하는 방법',
      inst1: '1. {download} 다운로드하여 설치를 완료합니다.',
      inst2: '2. 아래의 온라인 신청 양식을 작성하고 브로커 간 이체 양식을 해당 브로커에 업로드합니다.',
      inst3:
        '3. 브로커 간 이체가 정상적으로 진행될 수 있도록, 작성 완료된 이체 양식 사본을 기존 브로커에게 보내주십시오.',
      b2bForm: '브로커 간 이체 신청양식',
      formValidation: { upload: '브로커 간 이체 신청양식을 업로드 하십시오.' },
    },
    cc: {
      header: '신용 체크 카드 예치',
      remind:
        '기본 방법을 사용하여 계정에 자금을 조달하는 데 어려움이있는 경우 보조 신용 / 직불 카드 방법을 사용하십시오',
      field: {
        fundNow: '신용/체크 카드로 자금을 예치합니다.',
        newCard: '새로운 카드',
        cardNum: '신용카드번호',
        nameOnCard: '카드소유자영문명',
        expDate: '유효기간(월/년도)',
        cvv: 'CVV 보안코드',
        bankCode: '카드 발행 은행',
        example: `예시`,
      },
      placeholder: { bankCode: '카드발행은행을 입력해주십시오.' },
      cvvTip: '카드 뒷면에 표시된 3자리',
      threeDomainSecQ: '귀하의 카드가 3D-Secure를 지원합니까?',
      threeDomainSec:
        '3D Secure 인증이란? <br /><strong>3D Secure 해외 안심클릭 인증방식은 비자 및 마스터카드가 인정한 보안 방지 방법입니다.<br/> 만약 당신의 카드가 3D Secure를 지원한다면, 귀하의 자금은 즉시 처리될 것입니다.',
      cardUpload:
        '귀하가 자금을 예치하는 데 사용하는 카드가 현 거래 계좌에 연동되지 않았습니다. 최초 인증을 위해 카드 앞면 사진을 올려 주십시오. (카드 중간 6자리는 가리고 업로드 하십시오.)',
      result: {
        success:
          '감사합니다. 담당부서에서 현재 귀하의 자금과 인증 자료를 검토중입니다. 인증이 통과되면 24시간 내로 귀하의 거래 계좌에 예치하신 자금이 반영될 것입니다.',
        depositSuccess: '예치해 주셔서 감사합니다. 귀하의 자금은 곧 거래 계좌에 반영될 예정입니다.',
        error:
          '현재 입금 시도 횟수 초과로 인하여,<br> 귀하의 계좌를 보호하기 위한 조치로 일시적으로 예치가 정지되었습니다.<br> {email}로 문의해 주십시오.',
      },
      tipOnlyTaiwan: `고객 여러분, 신용 카드를 통한 출금은 은행 처리로 인해 지연 될 수 있으며 도착 시간은 전적으로 각 은행에 따라 다릅니다. 긴급한 자금이 필요한 경우에는 다른 방법으로 입금 / 출금하는 것이 좋습니다.`,
      reminder: `<li>1. EUR, GBP 및 USD를 사용한 신용 카드 입금은 거래 당 최대 $1,900 USD 또는 이에 상응하는 통화로 제한됩니다.</li>
      <li>2. 1일 최대 8회 거래 가능.</li>
      <li>3. 신용/체크카드 첫 회 결제 시 카드 소유자 본인 확인 인증절차 이후 입금이 반영되며 일정 시간이 소요될 수 있습니다. 추가 문의 사항이 있으실 경우 고객지원팀에 문의 바랍니다.</li>`,
    },
    fasa: {
      description: 'FasaPay로 입금하는 방법',
      form: { header: 'FASAPAY 신청양식' },
    },
    intSwift: {
      header: '해외 전신환 송금 : 은행 스위프트 정보',
      desc:
        '해외송금은 귀하의 명의로 개설된 은행 온라인 뱅킹을 통해 {platform}에 이체를 진행하거나 혹은 직접 은행창구로 방문하여 {platform}에 전신환 송금을 통해 이체를 진행하면 됩니다.',
      desc2:
        '입금 후 아래 양식을 작성하시고, 고객님의 성함, 계좌번호, 입금 금액이 명확하게 표시된 이체 내역서 혹은 영수증 사본을 사진 촬영 후 첨부하여 업로드를 진행하십시오.',
      alertSAandNGA:
        '미국 달러가 아닌 다른 통화를 보유하고있는 경우 송금하기 전에 송금을 미국 달러로 환전 해주세요. 직접 송금하는 경우, 당시 발생한 환율 차액 및 수수료는 고객 부담입니다.',
      options: '예치를 진행할 실계좌를 선택하세요.',
      refInfo: '은행 송금 비고란에 {platform} 실계좌번호를 입력해주세요.',
      bussDayInfo:
        '해외 전신환 송금은 당사에 도착까지 평균 영업입 2-5일이 소요되며, 제출해주신 이체 영수증을 기반으로 담당 부서에서 귀하의 입금을 확인하여 신속하게 반영드리겠습니다.',
      tip: '송금 내역서에 <b>이름</b>, <b>계좌 번호</b>,<b>입금 금액</b>이 명확하게 표시되어 있는지 확인하세요.',
    },
    mobile: {
      header: '모바일결제',
      desc: '모바일결제로 자금을 예치하는 방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 지원하는 모바일 어플리케이션을 열어 QR코드를 스캔합니다.',
      inst3: '3. 모바일 장치의 안내에 따라 예치금 결제를 완료합니다.',
      form: { payMethod: '지불방식:', rate: '환율(USD/CNY)', amt: 'CNY:' },
      formValidation: { cnyAmt: 'CNY 금액은 ${limit} 이하여야 합니다.' },
    },
    crypto: {
      header: '암호 화폐 예금',
      choose: '선호하는 암호 화폐를 선택하십시오',
      proceedQuestion: '<b>{method}</b> 입금을 진행 하시겠습니까?',
      note:
        'BUSDT를 통해 입금 또는 출금할 수 없습니다. 주소와 암호화폐가 허용되는 체인 및 통화와 일치하는지 확인하세요. 그렇지 않으면 자금을 잃을 수 있습니다.',
      note2:
        '당사는 BUSDT 또는 ETH를 통해 입금 또는 인출할 수 없습니다. 해당 주소와 가상화폐가 당사가 수락하는 체인 및 통화와 일치하는지 확인하십시오. 그렇지 않으면 자금을 손실할 수 있습니다. 고객과실로 인해 발생한 어떠한 손실에 대해서도 책임지지 않습니다.',
      remark:
        'BUSDT를 통해 입금 또는 출금할 수 없습니다. 주소와 암호화폐가 허용되는 체인 및 통화와 일치하는지 확인하세요. 그렇지 않으면 자금을 잃을 수 있습니다.',
      inst2: '2.   비트코인 지갑 주소가 표시된 새 창이 나타납니다.',
      inst3:
        '3.   비트코인 지갑 주소를 복사한 다음 개인 비트코인 지갑에 로그인하여 원하는 금액을 복사한 비트코인 주소로 전송합니다.',
      inst4: '주의 : 비트코인 예치는 B2BinPay의 당시 환율에 의해 적용되어 처리됩니다.',
      bitcoin: {
        desc: 'BITCOIN 입금방법',
      },
      usdt: {
        desc: 'USDT(OMNI) 입금방법',
        inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 전송을 진행하세요.",
        inst2: '2. 비트코인 지갑 주소가 표시된 새 창이 나타납니다.',
        inst3:
          '3. 비트코인 지갑 주소를 복사한 다음 개인 비트코인 지갑에 로그인하여 원하는 금액을 복사한 비트코인 주소로 전송합니다.',
        inst4: '주의 : 비트코인 예치는 B2BinPay의 당시 환율에 의해 적용되어 처리됩니다.',
      },
      usdt_CPS: {
        desc: 'USDT({name}) 입금방법',
      },
      form: {
        header_bitcoin: 'BITCOIN 신청양식',
        header_usdt: 'USDT(OMNI) 신청양식',
        header_usdt_CPS: 'USDT({name}) 신청양식',
      },
    },
    skrill: {
      header: 'SKRILL/MONEYBOOKERS 입금 방법',
      desc: 'Skrill 입금 방법',
      note1: 'USD(미국달러), EUR(유로), GBP(파운드), CAD(캐나다달러)로 결제 가능합니다.',
      note2: '자금 예치시 지불수단의 통화와 MT4 거래 계좌의 통화가 동일해야만 처리할 수 있습니다.',
      note3:
        'Skrill(스크릴)는 잘못된 이메일을 사용할 경우 자동으로 자금을 계좌의 기준 통화로 변환합니다. 수수료가 적용될 수 있습니다.',
      note4: '자금 예치시 PU Prime에 등록된 이름이여만 정상적으로 처리됩니다.',
      form: { header: '스크릴 신청양식', email: '스크릴 계정 이메일' },
      reminder: `<li> 참고: </ li>
      <li> * 계정은 MT4 거래 계정과 동일한 통화로만 자금을 조달 할 수 있습니다. 잘못된 이메일을 사용하는 경우 Skrill은 자동으로 자금을 계정의 기본 통화로 변환합니다. 요금이 부과 될 수 있습니다. </ li>
      <li> * 예금은 {platform} 계정과 동일한 이름이어야합니다. </ li>`,
    },
    sticPay: {
      header: 'STICPAY 입금 방법',
      desc: 'SticPay 입금 방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 귀하의 SticPay계정에 로그인 하십시오.',
      inst3: '3. SticPay 계정의 메시지에 따라 결제를 완료합니다.',
      form: { header: 'SticPay 신청양식', email: 'SticPay 계정 이메일' },
    },
    unionPay: {
      header: '유니온페이결제',
      desc: '유니온페이 자금 예치 방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 은행 선택 페이지에서 은행을 선택하고 온라인 뱅킹에 로그인합니다.',
      inst3: '3. 지시에 따라 온라인/모바일 뱅크를 통해 다음단계로 넘어가 결제를 진행합니다.',
      form: { header: '중국 유니온페이 신청양식' },
    },
    unionPayCN: {
      header: '유니온페이계좌이체',
      desc: '중국 유니온 페이 이체 계좌 자금 예치 방법',
      inst1: '결제 세부 정보 페이지가 나타납니다.',
      inst2: '입금하는 동안 회원 번호를 요청받을 수 있습니다. 거래 계좌 번호를 회원 번호로 입력하십시오.',
      note1: '최소 예치 금액은 500 CNY(중국위안화) 1회 최대 예치 금액은 49,999 CNY (위안)입니다.',
      note2: '예치 한도는 시장에 따라 수시로 갱신될 수 있으니 참고하시기 바랍니다.',
      note3: '표시된 은행 수취인 세부 정보는 수시로 업데이트 될 수 있으므로 복사하지 마시길 바랍니다.',
      form: {
        header: '중국 유니온 페이 이체 신청서',
        holderName: '유니온페이 카드 소유자 이름:',
        holderNameInfo: '위에 입력한 영문명과 유니온페이 카드 소유자 이름이 동일해야 합니다.',
      },
    },
    paypal: {
      header: 'PayPal',
      desc: 'PAyPal 입금 방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. PayPal 계정으로 로그인해주십시오.',
      inst3: '3. 다음의 안내에 따라 페이팔 계정에서 결제를 완료해주십시오.',
      inst4: '주의 : 제3자의 임의적인 지불은 허용되지 않습니다.',
      form: { header: 'PayPal 양식' },
    },
    cardpay: {
      desc: '신용카드 입금 방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 다음의 양식으로 이동하여 카드 세부정보를 입력해주십시오',
      inst3: '3. 신용카드 정보를 입력 후 "제출"버튼을 클릭해주십시오',
      inst4: '4. 고객 포털 사이트로 이동 후 거래가 완료됩니다',
      form: { header: '신용카드 신청양식 ' },
    },
    tinkbit: { inst5: '5. 1회 최대 예치금 $1500 USD' },
    perfectMoney: {
      inst1: '2.   귀하의 Perfect Money계정에 로그인 하십시오.',
      inst2: '3.   Perfect Money 계정의 메시지에 따라 결제를 완료합니다.',
    },
    indiaBankTransfer: {
      desc1: '입금이 완료되면 아래 양식을 작성하여 은행 송금 영수증 사본을 첨부해 주십시오.',
      desc2: '거래를 처리할 수 있도록 귀하의 전체 이름, 계좌 번호 및 예금 금액이 명확히 표시되어 있는지 확인하십시오.',
      bussDayInfo: '귀하의 계좌로 자금이 입금될 때까지 1~6시간을 기다려 주십시오.',
    },
  },
  withdraw: {
    title: '출금신청',
    default: {
      accountDetail: '계정 세부정보',
      withdrawalAmount: '출금 금액',
      example: 'Credit/Debit 예',
      alert:
        'AML 법률에 따라 인출에 사용하는 방법은 입금에 사용 된 방법과 동일해야합니다. 인출 금액이 계좌 기본 통화의 100 단위 미만인 경우 은행에서 처리 수수료 20 단위를 부과합니다.',
      how: '출금 방법 선택',
      success: '귀하의 출금 신청이 정상적으로 제출되었으며, 곧 처리될 예정입니다. ',
      selectCC: '신용카드 선택',
      selectBA: '은행 계좌 선택',
      anotherCC: '신용카드를 수동으로 입력하세요.',
      anotherBA: '은행 계좌 추가',
      remember: '내 계정 기억',
      ccFail:
        '현재 입금 시도 횟수 초과로 인하여, 귀하의 계좌를 보호하기 위한 일시적 조치로 입출금이 정지되었습니다. {email}로 문의해 주십시오.',
      checkPosFailed: '포지션 확인 실패',
      fetchTradeAccFailed: '거래 계좌를 불러오는데 실패했습니다.',
      chooseAnother: `<p>이 카드의 최대 인출 금액: 0</p> 
      <p>다른 카드 또는 다른 결제 방법을 선택하세요</p>`,
      notEnoughFunds: '입력한 금액이 해당 카드 한도를 초과합니다.',
      failed: '출금에 실패하였습니다. 잠시 후 다시 시도해주십시오.',
      unfortunate:
        '더 이상은 신용 카드 인출을 처리 할 수 없습니다. 이 후의 출금액은 은행 계좌를 통해 지정된 은행 계좌로 처리됩니다.',
      noCardWarn: '출금 가능한 카드가 없습니다. 먼저 출금 세부 사항에서 카드를 등록하십시기 바랍니다.',
      equityStop: '귀하의 계좌에 사용 가능한 마진이 강제청산 레벨인 100% 아래로 떨어져 출금 처리를 진행할 수 없습니다.',
      equityMargin: '죄송합니다. 고객님의 계정이 완전히 헤지되어 현재 인출을 처리할 수 없습니다.',
      equityContinue: '현재 보유중인 포지션이 존재합니다. 출금신청을 할 경우 포지션이 청산될 수 있습니다. ',
      disclaimer: `<li>포지션을 열 때 인출하면 마진 비율에 영향을 미칩니다. 계좌를 숏 포지션에 유지하거나 마진 콜을 피하기 위해 계좌에 충분한 자금이 있는지 확인하십시오.</li>
      <li>{platform}은 본인 명의 외의 제3자의 지불을 엄격하게 금지하고 있습니다. 귀하의 거래 계좌에서 출금되는 모든 자금은 {platform} 개설시 등록된 본인 명의로만 진행되어야 합니다.</li>
      <li>모든 초기 신용/직불카드, 전자지갑, 머니부커스 및 은행을 통한 인출 요청은 계좌 소유자 정보와 은행 세부 정보가 포함된 은행 명세서의 제공에 의해 안전성 및 보안을 확인해야 합니다.</li>
      <li>{platform}이 인출 요청을 처리 한 후 거래 계좌가 마이너스 잔액이 될 수 있다고 믿을만한 합리적인 근거가있는 경우 {platform}은 인출 요청 처리를 거부 할 권리가 있습니다.</li>
      <li>거래 계좌가 환불 또는 기타 진행중인 조사 대상인 경우 {platform}은 인출 요청을 거부 할 권리를 보유합니다.</li>
      <li>{platform}은 수신 금융 기관 또는 제 3 자 지불 제공 업체가 부과 할 수있는 수수료에 대해 책임을지지 않습니다.</li>`,
      YourPaymentGreaterThanAvailableBalance: '입력하신 지불 금액이 잔액을 초과합니다',
      blackList: `죄송합니다. 현재 인출 요청을 제출할 수 없습니다. 자세한 내용은 {supportEmail}에 문의하십시오.`,
      NetellerChargeAlert: 'Neteller 인출은 거래 당 $ 10,000로 제한되며 2 % 수수료 ($ 30로 제한)가 적용됩니다.',
      agreePolicy: '저는{link}을 읽고 동의합니다',
      withdrawPolicy: '출금 정책',
      addWithdrawalMethod: '인출 방법을 추가하세요.',
      addSecondaryMethod: '다른 인출 방법을 추가하세요.',
      withdrawNote:
        '인출은 주로 신용카드를 통해 처리된다는 것을 알려드립니다. 신용카드의 한도가 다 찼을 경우, 다른 인출 방법을 통해 결제를 진행할 수 있도록 요청드리겠습니다.',
      submitYourWithdrawal: '출금 제출',
      remainingWithdraw: '남은 인출 금액은 다른 신용카드 혹은 다른 인출 방법으로 분할됩니다.',
      onlyWithdrawalCcDesc: `신용카드 금액 {ccAmount}만 전액 인출됩니다.`,
    },
    fasa: {
      field: { accName: 'FasaPay 계정이름', accNum: 'FasaPay 계정번호' },
      formValidation: {
        accNameReq: 'FasaPay 계정이름을 입력해주십시오.',
        accNumReq: 'FasaPay 계정번호를 입력해주십시오.',
      },
      fasaPayChargeAlert: 'FasaPay는 모든 출금에 대해 0.5 %의 거래 수수료를 부과합니다.',
    },
    neteller: { field: { email: 'Neteller 이메일' }, formValidation: { emailReq: 'Neteller 이메일을 입력해주십시오' } },
    vload: { field: { email: 'Vload 가입 이메일' }, formValidation: { emailReq: 'Vload 등록 이메일이 필요합니다' } },
    bitcoin: {
      field: { address: 'Bitcoin 지갑 주소' },
      formValidation: {
        addressReq: 'Bitcoin 지갑 주소를 입력해주십시오',
        addressFormat: `지갑 주소는 '1', '3' 또는 'bc1'로 시작해야 합니다.`,
        addressLength: '비트코인 지갑 주소는 26~42자 사이여야 합니다',
      },
    },
    eth: {
      field: { address: 'ETH 지갑 주소' },
      formValidation: {
        addressReq: 'ETH 지갑 주소를 입력해주십시오',
        addressFormat: 'ETH 지갑 주소를 입력해주십시오',
        addressLength: '비트코인 지갑 주소는 26~42자 사이여야 합니다',
      },
    },
    usdc: {
      field: { address: 'USDC 지갑 주소' },
      formValidation: {
        addressReq: 'USDC 지갑 주소를 입력해주십시오',
        addressFormat: 'USDC 지갑 주소를 입력해주십시오',
        addressLength: '비트코인 지갑 주소는 26~42자 사이여야 합니다',
      },
    },
    usdt: {
      field: { address: 'USDT 지갑 주소' },
      formValidation: {
        addressReq: 'USDT 지갑 주소를 입력해주십시오',
        trc20WalletFormatIncorrect: `지갑 주소는 'T'로 시작해야 합니다.`,
        erc20WalletFormatIncorrect: `지갑 주소가 '0x'로 시작되어야 합니다.`,
      },
    },
    paypal: { field: { email: 'Paypal 이메일' }, formValidation: { emailReq: 'Paypal 이메일을 입력해주십시오' } },
    skrill: {
      field: { email: 'Skrill 이메일' },
      formValidation: { emailReq: 'Skrill 이메일을 입력해주십시오 ' },
      skrillChargeAlert: 'Skrill은 모든 출금에 대해 1 %의 거래 수수료를 부과합니다.',
    },
    bitwallet: {
      field: { email: 'Bitwallet 이메일' },
      formValidation: { emailReq: 'Bitwallet 이메일을 입력해주십시오 ' },
    },
    astropay: {
      field: {
        accountNumber: 'Astropay 계정',
      },
      formValidation: {
        accountNumberReq: 'Astropay 계정이 필요합니다',
      },
    },
    sticpay: {
      field: {
        email: 'SticPay 이메일',
      },
      formValidation: {
        emailReq: 'SticPay 이메일을 입력해주십시오 ',
      },
    },
    perfectMoney: {
      field: { email: 'Perfect Money 이메일' },
      formValidation: { emailReq: 'Perfect Money 이메일을 입력해주십시오 ' },
    },
    transfer: {
      info:
        '{platform}은 매 월 1회 은행 송금 출금 수수료를 무료로 면제합니다. 한 달 안에 추가로 인출할 경우 인출 통화의 20불의 은행 송금 수수료가 발생합니다.',
      label: { upload: '은행 명세서를 업로드해주십시오. (최근 3개월 거래내역 포함)' },
      formValidation: {
        upload: '이체내역서를 업로드해주십시오.',
        emailReq: '이메일',
      },
    },
    otherMethod: {
      withdrawalMethod: '인출 방법',
      manageWithdrawalDetails: '출금 내역 관리',
      verificationCode:
        '{email}에 확인 코드가 전송되었습니다. 추가로 고객 지원이 필요한 경우 {supportEmail}에 문의하십시오',
    },
  },
  transfer: {
    creditWarn: '프로모션 자금이 포함되어 있는 계좌는 이체 신청을 진행할 수 없습니다. ',
    blackListWarn:
      'MAM 계좌에서 자금이체를 신청할 경우 유지중인 포지션이 청산될 수 있습니다. 자금이체를 희망하실 경우 {mail to} 로 문의하시기 바랍니다. ',
  },
  changePw: {
    header: '비밀번호변경',
    field: { currPw: '현재 비밀번호', newPw: '새 비밀번호 입력', confirmPw: '새 비밀번호 확인' },
    succ: '비밀번호 재설정이 완료되었습니다. 변경된 비밀번호로 고객포털 사이트에 로그인해주십시오.',
  },
  promotion: {
    clickToGet: '단 한 번의 클릭으로 $ 50의 제로 입금 보너스를 받으세요',
    apply: '지금 신청하세요',
    start: '시작하기',
    tnc: `
    <li> 1. 해당 프로모션은 활성화 한 30일 내에 유효합니다.</li>
    <li> 2. PU Prime에 최초로 실계좌를 개설한 신규 계좌 개설 고객을 대상으로 제공됩니다. 신규 고객은 당사에 계좌를 최초로 개설하며, 입금한 적이 없는 고객을 말합니다.</li>
    <li> 3. 이 제안은 다음 지역의 신규 고객에게 제공됩니다. 프랑스, 영국, 대만, 이탈리아, 캐나다, 벨기에, 스위스, 홍콩, 나이지리아, 네덜란드, 일본, 룩셈부르크, 몰타, 레위니옹, 독일, 마르티니크, 스페인, 라트비아, 폴란드 , 코트디부아르, 덴마크, 브라질, 뉴질랜드, 포르투갈, 맨 섬, 남아프리카 공화국, 터키, 루마니아, 과들루프, 오스트리아, 아일랜드, 프랑스령 기아나, 스와질란드, 아르헨티나, 우크라이나, 그리스, 스웨덴, 모나코, 헝가리, 아이티 , 핀란드, 볼리비아, 칠레, 코스타리카, 크로아티아, 체코, 에스토니아, 멕시코, 노르웨이, 필리핀.</li>
    <li> 4. 스탠다드 계좌 및 이슬람 스탠다드 계좌 개설 고객만 참여 가능합니다.</li>
    <li> 5. 본 이벤트는 세대구성원, IP 주소, ID 신분증, 여권 등이 기존 고객과 중복되지 않을 경우에만 적용됩니다.  또한 기존 고객의 동명 계좌 개설을 통해서는 적용되지 않으며, PU Prime 에 보유중인 거래 계좌 수에 관계없이 하나의 계좌에만 적용됩니다.</li>
    <li> 6. 이벤트 참가자는 약관에 동의 한 후, $50의 보너스 금액을 지급 받을 수 있습니다. 지급된 보너스 금액은 당사의 실 계좌에서 거래할 수 있으며, 별도의 추가 입금이 필요 없습니다.</li>
    <li> 7. 지급된 이벤트 금액은 출금이 불가하나, 이벤트 금액을 통해 거래에서 발생한 수익의 일부는 출금이 가능합니다.</li>
    <li> 8. 출금 신청 시에는 진입중인 포지션을 모두 청산해야 합니다. 출금 신청을 선택하면 지급된 이벤트 금액은 자동으로 소멸되며, 본 이벤트는 종료됩니다.</li>
    <li> 9. PU Prime은 언제든지 조건을 변경하거나 보너스 발행을 거부 할 권리가 있습니다.</li>`,
    selectDesp: '* "No Deposit Bonus"캠페인에 참여할 계정을 선택하십시오.',
    descp1: '입금 없이 실거래를 시작해보세요! ',
    descp2: `$ 50 제로 예금 보너스를 신청하고 투자 <br>위험을 감수 할 필요없이 수익을 올리십시오.`,
    header: '프로모션 이용 약관',
    tnc2:
      '<li> 최소 인출 금액은 100 USD (또는 이에 상응하는 통화)이고 최대 인출 금액은 200 USD (또는 이에 상응하는 통화)입니다. 200 USD (또는 이에 상응하는 통화) 이상의 잔액은 신청 후 크레딧과 동시에 차감됩니다.</li>',
    successful: '성공적으로 제출되었습니다.',
    failed: '제출 실패',
    referFriends: {
      intro: `{amount}를 받으려면 다음 3 단계를 완료하세요.`,
      step1: '첫 번째 단계',
      step1Descp: '성공적으로 계좌 개설',
      step2: '두번째 단계',
      step2Descp: '성공적인 입금',
      step3: '세 번째 단계',
      step3Descp: '거래량',
      totalEanringTitle: `총 출금 금액 <br/>((USD 또는 이에 상응하는 통화))`,
      totalEarningTip: '* 보상 금액은 검토 대상입니다.',
      redeem: '추출물',
      intro2: '동시에 친구를 추천 할 수도 있습니다.',
      shareLink: '맞춤 추천 링크 공유',
      shareLinkDescp: '공유를 클릭하여 고유 한 추천 링크를 얻고 다양한 소셜 미디어에 복사합니다.',
      share: '공유',
      total: '총 권장 사용 금액',
      social: '주요 소셜 미디어를 통해 링크 공유',
      clients: '고객',
      table: {
        referralsNo: '추천수',
        name: '이름',
        country: '국가',
        openAccount: '성공적으로 계좌 개설',
        despositSuccess: '성공적인 입금',
        volumeCompleted: '거래량',
      },
      totalRedeemableTitle: `총 출금 금액 <br/>(USD 또는 기타 이에 상응하는 통화)`,
      totalRedeemableTip: '* 보상 금액은 검토 대상입니다.',
      referrer: '추천인',
      referral: '심판',
      dialog: {
        confirmQ: '수입 상환',
        confirmQ2: '기본 통화로 변환 된 총 금액은 {amount} {currency}입니다.',
        redeem: '지금 인출',
        agree: '* 지금 사용을 클릭하면 모든 {link}에 동의하는 것입니다.',
        overBonus:
          '시스템에서 귀하가 최대 누적 한도에 도달했음을 감지했습니다. 추가 신청은 고객 서비스 담당자에게 문의하십시오.',
      },
    },
    depositBonus: {
      banner: '최대 $10,000까지 적립되는 50% 입금 보너스 신청!',
      depositBonus: '보너스 신청 및 거래 시작',
      title: '증거금과 거래량 증가를 원하십니까? 그럼 바로 금액 예치 후 50% 입금 보너스를 신청하세요!',
      fund: '예치하기',
      tnc: '*이용 약관을 확인하시려면, {link}',
      link: '여기를 눌러주세요',
      bonusHistory: '보너스 지급 내역',
      overBonus:
        '* 최대 ${totalLimit} 보너스 크레딧에 도달했습니다. 추가 입금에 대한 보너스 크레딧을 받을 수 없습니다.',
      opt: '이벤트 참여 신청',
      tick: '이벤트 참가를 희망하실 경우 체크해주세요',
      saveChange: '변경사항 저장',
      depositDate: '자금 예치일',
      credit: '크레딧',
      optIn: '성공적으로 참여하세요',
      optOut: '성공적으로 옵트 아웃',
      optInDescription: '50% 입금 보너스를 신청하려면 아래 버튼을 클릭하십시오.',
      optOutDescription: '이벤트를 종료하시려면 체크 박스를 클릭해주세요',
      activate: '활성화',
      signOut: '이벤트 종료',
      banner1: '지금 바로 50% 입금 보너스를 신청하세요!',
      claimDescription: `<p>신청방법</p>
      <li>1. 자금 예치 전 이벤트 참여 활성화 </li>
      <li>2. 거래 계좌에 자금 예치</li>
      <li>3. 자금 예치 완료시 거래계좌에 크레딧 자동 반영 </li>
      <li>4. 거래시작 </li>`,
      eligibleDescription: `<p>참여조건</p>
      <li>1. 자금 예치 전 입금 보너스 참여를 활성화합니다</li>
      <li>2. 본 이벤트는 스탠다드 계좌, 이슬람 스탠다드 계좌, 프라임 계좌, 이슬람 프라임 계좌만 참여 가능합니다</li>
      <li>3. 최초 입금의 입금 보너스는 예치금의 50 %이며, 차후 입금에 대한 입금 보너스는 20%로 계산됩니다 </li>`,
      optInDialog: `축하합니다! 50% 입금 보너스 이벤트 참여가 활성화되었습니다.`,
      optOutDialog: `50% 입금 보너스 이벤트 참여가 종료되었습니다. `,
    },
    cryptoCashback: {
      banner: {
        bannerContext: `
          <span class="title">두 배의 비용 절감 혜택</span></br>
          <span class="desc">최대 $10,000 캐시백과 스왑 수수료 면제로</span>
        `,
        activityBannerContext: `
          <span class="title">수익을 두 배로 높이세요!</span></br>
          <span class="desc">하락장에서 저가 매수를 할까? 하락에 투자할까?</span></br>
          <span class="desc">PU Prime에서 가상화폐를 거래하면, 최대 10,000달러의 캐시백을 받으실 수 있습니다!</span>
        `,
      },
      active: {
        title: '가상화폐 캐시백 활성화 하기',
        desc: '이벤트에 참여하려면 아래 활성화 버튼을 클릭하십시오.',
      },
      deactive: {
        title: `가상화폐 캐시백 비활성화하기`,
        desc: `본 가상화폐 캐시백 프로모션을 비활성화 및 출금 신청을 원하면, 아래 버튼을 클릭하십시오.`,
      },
      button: {
        active: '활성화하기',
        deactive: '비활성화하기',
        submit: '제출하기',
        fund: '예치하기',
      },
      claimTitle: '캐시백 받는 방법',
      claimDescriptions: [
        `입금 전에 이벤트 활성화 버튼을 클릭하세요.`,
        `이벤트 참가를 위한 최소 예치금 이상을 거래 계좌에 입금합니다.( ≥ 500 USD).`,
        '계약당 캐시백은 총 순 예치금액에 따라 달라집니다. (T&C 참고).',
        '거래량에 따라 더 많은 캐시백을 얻으세요!',
      ],
      eligibilityTitle: '이벤트 조건 및 자격',
      eligibleDescriptions: [
        `본 이벤트를 참여하기 위해서는 반드시 거래 계좌에 입금 전 이 이벤트를 활성화해야 합니다.`,
        `본 이벤트는 스탠다드 계좌 및 이슬람 스탠다드 계좌에서만 참가 할 수 있습니다. 만약 동일 고객 명의로 다 유형의 거래 계좌가 여러 개 있는 경우, 본 이벤트에 참여하기 위한 거래 계좌를 지정해야 합니다.`,
        `본 이벤트는 활성화 후 최초 입금 시점부터 30일 동안 계속됩니다.`,
        `캐시백 이벤트는 ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU을 제외한 모든 가상화폐 거래상품에서 적용되며, 거래량을 기준으로 합니다.`,
        `지급되는 총 캐시백은 고객당 최대 10,000달러로 상한선이 정해져 있습니다.`,
      ],
      tnc: '*이용 약관을 확인하시려면, {link}',
      link: '여기를 눌러주세요',
      message: {
        congratulate: `축하합니다!`,
        congratulateContext: `가상화폐 캐시백 이벤트를 성공적으로 활성화했습니다.`,
        signedOutPromo: `확인 버튼을 클릭하면 본 이벤트가 비활성화됩니다.`,
        deactivePromo: `가상화폐 캐시백 이벤트가 비 활성화됬습니다.`,
      },
      dialog: {
        selectAccountContext: `이벤트에 참여할 거래 계좌를 선택하십시오.`,
        tnc: `이용약관`,
        tncContext: {
          tnc1: `1. 본 이벤트에 참여를 원할 경우, 이벤트 페이지에서 활성화한 후 최소 예치금 이상의 자금을 입금 후, 이벤트 적용 가상화폐 상품을 거래해야 캐시백을 받을 수 있습니다.`,
          tnc1desc: `* 이벤트 기간 중 신규 입금된 금액만 본 이벤트에 유효합니다. 계좌 간 자금 이전, 상여금, 지급액 등은 이벤트 참여에 제외됩니다.`,
          tnc2: `2. 본 문서에 명시된 기준을 충족하는 적격 고객(신규 및 기존 고객 모두)은 다음과 같은 캐시백을 받을 수 있습니다.`,
          tnc3: `3. 이벤트 적용 상품: ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU를 제외한 모든 가상화폐 거래상품.`,
          tnc4: `4. 유효 거래량: 본 이벤트 참가 기간 중 신규 진입 및 청산된 포지션을 말합니다. 진입 후 5분 이내에 청산한 포지션 및 헷지 포지션은 해당 이벤트에 대한 유효한 거래로 간주되지 않습니다.`,
          tnc5: `5. 이벤트 기간 : 활성화 버튼을 누른 후 최초 입금한 날로부터 30일.`,
          tnc5desc: `* 이벤트 활성화 후 입금을 하지 않은 경우, 이벤트를 활성화 된 날로부터 30일이 지나면 자동으로 종료됩니다.`,
          tnc6: `6. 본 이벤트는 타 이벤트와 중복해서 참여 할 수 없습니다. 예) 트레이더가 될 기회,  레퍼럴 이벤트,  50% 입금 보너스 등)`,
        },
        agreeTnc: `본 이벤트의 이용약관을 읽고 동의합니다.`,
      },
      field: {
        netDeposit: `순예치액`,
        usddesc: `USD (또는 동등한 통화)`,
        cashback: `환급액`,
        standardLot: `표준 계약`,
        date: `일시`,
        depositAmount: `입금액`,
        withdrawAmount: `출금액`,
        netDepositAmount: `순입금액`,
        rebateLot: `환급 / 계약`,
        tradingDate: `거래일시`,
        tradingLot: `거래량`,
        cashRebate: `환급액`,
      },
      depositHistory: `입금내역`,
      tradingRewardHistory: `거래내역`,
    },
    xmasMakeAWish: {
      banner: {
        bannerContext: `
          <div class="title">최대 5,000 달러 상당의 상금을 타세요!</div>
          <div class="desc">확실한 행운 추첨 확인하기</div>
        `,
      },
      active: {
        title: `크리스마스 소원 성취 프로모션 참가하기`,
        desc: '아래 버튼을 클릭하여 크리스마스 프로모션을 활성화하시길 바랍니다.',
      },
      deactive: {
        title: 'X-Mas 소원성취 프로모션',
        desc: '아래 버튼을 클릭하면, 본 크리스마스 프로모션이 비 활성화됩니다.',
      },
      button: {
        active: '프로모션 참가',
        deactive: '비 활성화하기',
        submit: '신청하기',
        fund: '내 거래계좌에 입금하기',
        enterLuckyDraw: '행운 추첨하기',
        redeemed: '교환완료',
      },
      claimTitle: '사은품 청구 방법',
      claimDescriptions: [
        '입금하기 전에 본 프로모션을 신청 및 활성화하세요.​​',
        '원하는 이벤트 사은품에 해당하는 입금 조건을 충족하도록 고객님의 실거래 계좌에 자금을 예치 후, 증정품 획득을 위한 목표된 계약수를 거래하세요. (아래 사은품표 참고).',
        '이벤트 조건에 충족하면, 해당되는 증정품 중 하나를 받을 수 있는 기회를 얻게 됩니다. (아래 사은품표 참조).',
        '행운 추첨 버튼을 클릭하여 사은품을 확인합니다.',
        '당첨된 사은품은 기록되며, 본 프로모션 종료 7일 후 배포됩니다.',
      ],
      eligibilityTitle: '이벤트 대상',
      eligibleDescriptions: [
        '적용되는 계좌: 본 이벤트는 MT4/MT5의 스탠다드 계좌 및 이슬람 스탠다드 계좌에만 적용됩니다.',
        '고객이 다수의 스탠다드 계좌을 가지고 있는 경우 본 프로모션에 참여할 스탠다드 계좌를 하나만 지정 선택할 수 있습니다.',
        '대상 국가: 중국 본토를 제외한 모든 지역',
        '본 이벤트는 이벤트 신청이 필수로 요구되며, 해당 이벤트 기간동안에만 예치된 자금과 거래에 대해서만 프로모션 거래로 산정됩니다.',
        '유효 거래: 외환, 금, 은, 오일 - 현물,선물 상품만 이벤트 거래 상품으로 간주되며, 주문 포지션이 5분 이상 유지시에만 산정됩니다.',
      ],
      moreDetail: `사은품에 대한 더 자세한 내용은 여기에서 {prize}을 참조하십시오.`,
      prize: '사은품 목록',
      tnc: '*약관이 적용됩니다. 자세한 내용은 {link}를 클릭하십시오.',
      link: '여기',
      luckyDraw: {
        description: [
          '위의 상품 중 <span class="font-bold">하나</span>에 당첨하려면 행운 추첨 버튼을 누르세요!',
          '당신이 찾고 있는 선물이 아닌가요? 더 좋은 사은품을 획득할 수 있도록 예치와 거래를 계속하세요.',
        ],
        mainTitle: '현재 해당되는 사은품:',
        commingSoon: {
          title: `소원 성취하기`,
          content: `곧 11월 중순에 행운 추첨에 참여할 예정입니다.`,
        },
        noChanceToDraw: {
          title: `지금 예치하기`,
          content: `행운권 추첨을 위해 거래 계좌에 200달러 이상의 최소 자금을 예치하세요.`,
        },
        endPromotion: {
          title: `행운권 추첨이 끝났습니다.`,
          content: `이벤트에 참여해 주셔서 감사합니다! 2023년 3월 31일까지 경품을 교환했는지 확인하세요.`,
        },
      },
      currentDepositLots: `현재 입금액 / 거래된 계약수`,
      transactionHistory: `예치 내역`,
      transactionHistoryDesc: `*예치 내역은 행운권 추첨을 위한 입금액과 실제 예치한 금액을 차감한 금액이다.`,
      giftRedemption: `사은품 수령신청`,
      giftRedemptionDesc: [
        `실물 사은품 수령을 위해 다시 연락드리겠습니다.`,
        `*모든 경품은 2023년 2월 2일부터 2023년 3월 2일까지만 교환할 수 있습니다.`,
      ],
      field: {
        depositAmount: `입금액`,
        lotsTraded: `거래된 계약수`,
        date: `일시`,
        type: `유형`,
        deposit: `*입금`,
        tradingLots: `계약수`,
        prizeWon: `획득 사은품`,
        creditBonus: `크레딧 보너스`,
        cashVoucher: `현금 바우처`,
        physicalRewards: `실물 사은품 수령`,
        depositTradingVolume: `입금액 / 계약수`,
        gifts: `선물 (USD)`,
        accountID: `계좌 ID`,
        current: `최신`,
        luckyDrawDeposit: `입금`,
      },
      dialog: {
        selectAccountContext: `크레딧 보너스를 받을 거래 계좌를 선택하십시오(MT4/MT5 계좌만 해당).`,
        tnc: '프로모션 약관',
        tncContext: {
          tnc1: `1. 먼저 고객 포털 내에서 본 프로모션을 필수로 활성화한 다음, 이벤트 사은품 조건에 따라 자금을 예치하고 목표된 계약수를 거래 해야 해당되는 사은품을 받을 수 있습니다.`,
          tnc2: `2.	유효 거래량: 통화쌍, 금, 은, 원유 현물 및 선물 1계약을 기준으로 합니다. 프로모션 기간 동안 매매 포지션 주문을 개시 및 청산 합니다. 5분 미만 청산된 주문 포지션, 상쇄 포지션, 헷지 포지션은 본 프로모션에 유효한 거래수량으로 계산되지 않습니다.`,
          tnc3: `3.	본 프로모션에 산정되는 유효 주문은 MT4시간 2022년 11월 1일 부터 2023년 1월 31일까지를 기준으로 합니다.`,
          tnc4: `4.	사은품이 판매되지 않거나 품절, 배송불가 등 불가항력적인 요인으로 인해 구매할 수 없는 경우, 해당 사은품 시세에 상응하는 현금을 받을 수 있도록 신청할 수 있습니다. 사은품 가격은 미국 달러 가격(또는 동등한 통화)을 기준으로 합니다.`,
          tnc5: `5.	본 프로모션은 타 이벤트와 중복해서 참여할 수 없습니다. (100% 트레이딩 보너스, 20% 독점 보너스, 50% 입금 보너스, 베트남 20% 입금 보너스 등).`,
        },
        agreeTnc: `이용 약관을 읽고 동의합니다.`,
        prizeList: `사은품 목록`,
        luckyDrawTransaction: `행운권 추첨 내역`,
        xmasGift: `고객님의 크리스마스 선물 획득을 축하드립니다!`,
      },
      message: {
        congratulate: '축하합니다!',
        congratulateContext: `크리스마스 프로모션이 성공적으로 신청되었습니다 소원 성취 프로모션.`,
        signedOutPromo: `확인 버튼을 클릭하면 본 프로모션이 취소됩니다!`,
        deactivePromo: `X-Mas 소원 성취 프로모션을 취소했습니다.`,
        redeemAmount: `행운 추첨 금액은 고객님의 실 계좌로 입금될 예정입니다!`,
      },
      tableContent: {
        luckyDraw: `행운 추첨권`,
        depositTrade: `입금 / 거래`,
      },
    },
    firstTimeDeposit: {
      banner: {
        bannerContext: `
          <div class="title">최초 예치금으로 <span class='bg_yellow'>현금보상</span> 지급 받기</div>
          <div class="desc">PU Prime에서 첫 입금하고 50달러까지 현금으로 받으세요!</div>
        `,
      },
      active: {
        title: `첫 입금 현금 증정 이벤트 활성화하기`,
        desc: `본 이벤트를 활성화하고 참여하려면 아래 버튼을 클릭하십시오.`,
      },
      deactive: {
        title: `첫 입금 현금 증정 이벤트 비활성화하기`,
        desc: `본 이벤트를 비활성화하고 탈퇴하려면 아래 버튼을 클릭하십시오첫 입금 현금 증정 이벤트`,
      },
      button: {
        active: `활성화`,
        deactive: `비활성화`,
        fund: `거래 계좌에 입금을 시작하세요`,
      },
      claimTitle: `이벤트 참여 방법`,
      claimDescriptions: [
        `첫 입금 전 본 이벤트 페이지에서 활성화 버튼을 누릅니다.​`,
        `현금 증정 조건 ($200 USD 또는 그에 동등한 통화)을 충족할 수 있도록 귀하의 거래 계좌에 예치를 진행하세요.`,
        `현금 교환권의 가치는 최초 입금액에 따라 달라집니다(이벤트 약관의 캐시백 표 참조).`,
        `현금 교환권을 활성화하려면 FX, 금, 은 또는 오일 2계약 이상을 거래하세요.`,
        `현금 교환권이 활성화되면 사용할 수 있습니다. 해당 교환권의 금액은 귀하의 거래 계좌 잔고에 반영됩니다.`,
      ],
      eligibilityTitle: `조건 및 주의 사항`,
      eligibleDescription1: `참가 계좌 유형 : 본 이벤트는 MT4/MT5의 스탠다드 계좌, 이슬람 스탠다드 계좌에만 제공됩니다.`,
      eligibleDescription2: `다수의 동명 스탠다드 계좌를 가지고 있는 경우 본 이벤트에 참여할 스탠다드 계좌를 하나만 지정하여 참여할 수 있습니다.`,
      eligibleDescription3: `대상 국가: 중국 본토를 제외한 모든 국가 및 시장`,
      eligibleDescription4: `이벤트 대상 : 입금 내역이 없는 신규 및 기존 고객`,
      eligibleDescription4Desc: `* 파트너/CPA의 개인 거래 계좌는 참여 제외`,
      eligibleDescription5: `본 이벤트 기간 중 최초 입금이 이루어져야 합니다.`,
      eligibleDescription6: `5분 미만 청산된 포지션 주문은 본 이벤트에서 유효한 거래로 정산되지 않습니다.`,
      tnc: `*약관이 적용됩니다. {link}를 통해 확인하실 수 있습니다.`,
      link: `약관보기`,
      dialog: {
        selectAccountContext: `이벤트에 참여할 거래 계좌를 선택하십시오.`,
        tnc: `이벤트 약관`,
        tncContext: {
          tnc1: `1. 본 이벤트를 활성화한 후 해당 현금 교환권을 받으려면 이벤트 활성화 전 먼저 입금을 진행하고 스탠다드 2계약을 거래해야 합니다.`,
          tnc1desc: `* 이벤트 기간 중 신규 입금된 금액만 프로모션에 해당됩니다. 여기에는 계좌간 내부 이체, 친구 추천 보너스, 현금 조정은 유효한 입금으로 포함되지 않습니다.`,
          tnc2: `2.	이 기간 동안 최초로 입금된 금액만 이벤트 증정 기준으로 반영됩니다.`,
          tnc3: `3.	본 문서에 명시된 기준을 충족하는 대상 고객(신규 및 기존 고객)은 다음과 동일한 현금 교환권을 받을 수 있습니다:`,
          tnc4: `4.	이벤트 해당 거래 상품: 외환, 금, 은, 오일`,
          tnc5: `5.	유효되는 거래량: 본 이벤트 기간 중 신규 진입 및 청산된 포지션 주문입니다. 5분 미만의 청산된 포지션, 헷지 및 위험에 노출된 포지션은 이 프로모션에 유효한 거래로 간주되지 않습니다.`,
        },
        agreeTnc: `약관을 읽고 동의합니다.`,
        grossDeposit: `총 입금액 USD`,
        grossDepositDesc: `또는 동등한 통화`,
        cbVoucher: `현금 교환권`,
        tableDesc: `* 인당 최대 50달러(또는 동등한 금액)의 현금 교환권이 증정됩니다.`,
      },
      voucher: {
        title: `FTD 캐시백 이벤트`,
        accountID: `계좌 ID`,
        accumulateVolume: `누적 거래량`,
        standardLots: `스탠다드 계약`,
        desc: `FX, 금, 은, 오일 2 계약을 거래하여 현금 교환권을 활성화합니다.`,
        clickForRedeem: `귀하의 계좌 잔고로 보내시려면 아래 버튼을 클릭하세요.`,
        ftdLessThan200: `귀하의 최초 예치금이 최소 입금액보다 적습니다.`,
      },
      message: {
        congratulate: `축하합니다!`,
        congratulateContext: `성공적으로 FTD 캐시백 이벤트가 활성화되었습니다.`,
        signedOutPromo: `확인 버튼을 클릭하면 본 프로모션이 종료됩니다!`,
        deactivePromo: `첫 입금 현금 증정 이벤트가 비활성화 되었습니다.`,
        successRedeem: `FTD 캐시백을 성공적으로 획득하셨습니다.`,
      },
    },
    notification: {
      ndb: {
        inst1: '고객님께,',
        inst2: `PU Prime의 크레딧 보너스 이벤트는 <span>{date} (GMT+3)</span>에 종료될 예정입니다.`,
        inst3:
          '이벤트가 종료되면 약관에 따라 반영된 크레딧 보너스는 자동으로 제거됩니다. 입금 또는 거래 내역이 없는 계좌의 경우, 이벤트 종료 후 크레딧 보너스와 잔액 모두 자동으로 제거됩니다. ',
        inst4: `문의 사항이 있으실 경우, 라이브 채팅, 이메일<span>({mail})</span>, 전화<span>({phone})</span>를 통해 문의하시기 바랍니다.`,
        cantInerTransfer: 'NDB 캠페인에 참여 중이므로 이전 요청을 처리할 수 없습니다.',
      },
      db: {
        inst1: '고객님께,',
        inst2: `현재 고객님이 참가중인 투자 지원금 이벤트는 <span>{date} (GMT+3)</span>에 종료됨을 알려드립니다.`,
        inst3:
          '해당 이벤트 약관에 따라 프로모션이 완료되면 거래계좌에 있는 지원금 크레딧은 자동으로 없어집니다. 입금 및 거래 기록이 없는 계좌의 경우 이벤트가 종료되면 지원금 및 잔액도 자동으로 없어집니다.',
        inst4: `궁금하신 점이나 도움이 필요한 경우 실시간 채팅, 이메일<span>({mail})</span> 혹은 유선<span>({phone})</span>로 고객지원팀에 문의하시길 바랍니다.`,
      },
    },
    editSuccess: '',
  },
  downloads: {
    mt4: 'METATRADER4',
    mt4Desc: 'PC, 모바일, 태블릿 등 사용하는 소프트웨어에 적합한 MT4 거래 플랫폼을 다운로드 할 수 있습니다.',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android/Tablet',
    web: 'WebTrader',
    mt5: 'METATRADER 5',
    mt5Desc: 'PC 데스크탑,  웹 브라우저, 스마트폰 및 태블릿용 MetaTrader 5 다운로드',
  },
  forgetPwReq: {
    tip: '비밀번호를 재설정하려면 계정 이메일 주소를 입력하세요.',
    formValidation: { emailReq: '이메일을 입력해주십시오' },
    succ:
      '비밀번호 재설정 메일이 발송되었습니다. <br><br>받은 메일함에 확인되지 않을 경우 스팸 메일함을 확인해주십시오.',
  },
  loginClient: {
    header: '고객포털',
    failedMasseage: '다시 시도하십시오',
    existingClientPortalAccess:
      '이미 등록되어 있는 이메일입니다. 등록된 이메일로 로그인하여 실계좌를 개설하시기 바랍니다.',
    footer: `위험고지 : CFD (차액결제거래)거래는 증거금을 동반하는 거래 상품으로 고 위험 투자 방식이며, 높은 위험성을 수반합니다. 해외파생상품거래에서 발생할 수 있는 손실규모는 위탁증거금에 한정되지 않습니다. 따라서 예탁한 위탁증거금 전액을 손실로 상실 할 수 있을 뿐 아니라 손실 금액이 예탁금을 초과할 수도 있습니다. 모든 매매 손실은 투자자에게 귀속되며, 투자자는 개인의 경험과 투자 목적, 자금의 출처에 대해 발생할 수 있는 위험성을 숙지하시길 바랍니다.<br><br><br>
      {platformEntityName} 는 세이셸 금융서비스당국(FSA)의 감독 및 규제를 준수합니다. 감독번호: SD050.<br>
      PU Prime LLC는 라이선스 번호 271 LLC 2020으로 세인트 빈센트 그레나딘의 금융 서비스 당국에 의해 승인 및 규제됩니다.<br>
      The entities above are duly authorized to operate under the PU Prime brand and trademark.<br>`,
    formValidation: { emailReq: '이메일을 입력해주십시오.' },
    invailidRafLink: `사용된 추천 링크가 잘못되었습니다. 아래에서 새 계정에 가입하세요.`,
  },
  myProfile: { changeDetails: '프로필 세부 정보의 업데이트가 필요하시면 {mailTo}로 문의하시기 바랍니다.' },
  openAdditAcc: {
    chooseTradePlat: '거래 플랫폼 선택',
    chooseAccType: '계좌 유형 선택',
    chooseCurrency: '계좌 통화 선택',
    notes: '비고',
    currError: 'MT4 계좌 통화를 선택하십시오.',
    platErr: '거래 플랫폼을 선택하십시오',
    accTypeErr: '계좌 유형을 선택하십시오',
    succ:
      '<p>귀하의 추가 실계좌 개설이 완료 되었습니다. 로그인 세부 내용은 귀하의 이메일로 발송됩니다. 또한 고객포털의 대시보드에서도  확인 하실 수 있습니다.</p>',
    byTicking: `{tnc}을 읽고 이에 동의합니다.`,
    tnc: '이용 약관',
    pamm: `PAMM 계정은 기존 Money Manager 계약이있는 투자자 만 사용할 수 있습니다. PAMM 계정은 Money Manager를 제외한 누구도 거래 할 수 없습니다.`,
    pammConfirm: `이 계정 유형을 계속 선택할 것인지 확인하십시오.`,
  },
  openDemoAdditAcc: {
    header: '데모계좌 환경설정',
    button: '데모계좌개설',
    liveButton: '실계좌개설',
    succ: '귀하의 추가 데모계좌 개설이 완료되었습니다. 로그인 세부내용은 귀하의 이메일로 발송됩니다. ',
  },
  resetPassword: {
    header: '비밀번호 재설정',
    succ: '비밀번호 재설정이 완료되었습니다. 변경된 비밀번호로 MT4/MT5 거래 계좌를 로그인해주십시오.',
    succ2: '비밀번호 재설정이 완료되었습니다. ',
    failed: '토큰이 만료되었습니다. ',
    emailNotFound: '귀하의 이메일 주소가 유효하지 않습니다. ',
  },
  fileUploader: {
    supportedFormat: '지원 파일 형태 : png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: '파일 업로드 크기: {maxSize} MB',
    maxFileNum: '최대 파일업로드 개수:{maxFileNum}',
  },
  transcationHistory: {
    deposit: '예치',
    withdraw: '인출',
    desc: {
      incomplete:
        '최종 단계에서 혹은 지불 단계에서 예치를 정상적으로 진행하지 않고 종료 할 경우, 표시되는 상태 입니다. 지불이 정상적으로 승인이 되지 않았으므로, 입금을 재 요청을 해야만 합니다. ',
      success: '성공적으로 지불이 승인되었으며, 곧 자금이 고객님의 계좌에 반영될 것입니다.',
      proc: `귀하의 거래 요청이 승인중입니다. 자세한 내용은 {mailTo} 로 문의하시기 바랍니다. `,
      failed: `귀하의 거래 요청이 실패하였습니다. 자세한 내용은 {mailTo} 로 문의하시기 바랍니다.  `,
      rejected: `귀하의 거래 요청이 거절되었습니다. 자세한 사항은 {mailTo} 로 문의하시기 바랍니다. `,
    },
    withdrawDesc: {
      sub: '귀하의 거래 요청이 제출되었습니다. 승인이 완료까지 잠시만 기다려주시기 바랍니다. ',
      suc: '성공적으로 지불이 승인되었으며, 곧 자금이 고객님의 계좌에 반영될 것입니다.',
      proc: `귀하의 거래 요청이 승인중입니다. 자세한 내용은 {mailTo} 로 문의하시기 바랍니다. `,
      incomp: `귀하의 거래를 완료하기 위하여 추가적인 단계가 필요합니다. 자세한 사항은 {mailTo} 로 문의하시기 바랍니다.`,
      cancel: `귀하의 거래가 취소되었습니다. 자세한 사항은 {mailTo} 로 문의하시기 바랍니다.`,
      fail: `귀하의 거래 요청이 실패하였습니다. 자세한 내용은 {mailTo} 로 문의하시기 바랍니다.  `,
      rej: `귀하의 거래 요청이 거절되었습니다. 자세한 사항은 {mailTo} 로 문의하시기 바랍니다. `,
    },
    cancelWithdraw: `출금신청을 취소하시겠습니까?`,
    cancelError: `귀하의 출금이 현재 처리 중입니다. 따라서 취소 요청을 수락할 수 없습니다. 자세한 내용은 라이브 채팅 기능을 사용하여 문의할 수 있습니다.`,
    dialog: {
      appliedAmount: `신청금액`,
      applicationTime: `신청시간`,
      processedTime: `처리시간`,
      accountCurrency: `계좌통화`,
      creditDeducted: `공제된 신용금액`,
      processedNote: `비고`,
      bankName: `은행명`,
      bankAccountNumber: `은행계좌번호`,
      abaSortCode: `ABA넘버 /Sort 코드`,
      swiftCode: `스위프트코드`,
      bankStatement: `은행명세서`,
      bankAddress: `은행주소`,
      accountName: `예금주`,
      accountNumber: `계좌번호`,
      cardholderName: `카드소유자명`,
      cardNumber: `카드번호`,
      cardExpiryDate: `카드유효기간`,
      walletAddress: `지갑주소`,
      ewalletEmailAddress: `지갑연동된이메일주소`,
    },
  },
  transferFunds: {
    default: {
      equityStop:
        '죄송합니다. 계정의 사용 가능한 마진이 스탑 아웃 수준인 100 % 이하로 떨어지기 때문에 이체를 처리할 수 없습니다.',
      equityMargin: '죄송합니다. 귀하의 계정이 완전히 헤지되어 현재 이체를 처리할 수 없습니다.',
      equityContinue:
        '현재 귀하의 계정에 오픈 포지션이 있는 것으로 확인되었습니다. 이체 신청서를 제출한 후 스탑 아웃이 될 수 있습니다. 계속하려면 확인해주세요.',
      blackList: '계정 간 이체가 제한된 계정입니다. {supportEmail}로 문의하시기 바랍니다.',
    },
    pleaseNote: '주의사항:',
    suc: '귀하의 자금이 성공적으로 이체되었습니다.',
    warn1:
      '계좌 간 이체시, 보내는 계좌상에 유지 포지션이 있을 경우  충분한 여유 자금을 확인하시길 바랍니다. 계좌 간 이체로 발생된 마진콜에 대한 책임과 판단은 전적으로 귀하에게 귀속됩니다. 현재 모든 포지션이 청산 된 상태에서 이체를 진행하시기를 권장 드립니다. 계좌 간의 통화가 동일할 경우, 이체 신청은 즉시 처리됩니다.',
    warn2: '각 MT4 계좌의 통화가 다른 경우 계좌 간 이체의 진행을 원하신다면 {mailTo} 으로 이메일을 보내 주십시오.',
    warn3: '읽기 전용 계좌는 계좌 간 이체가 불가능합니다.',
  },
  transferHistory: { payStatus: { processing: '처리중', paid: '지불', unpaid: '미지불', rejected: '거절' } },
  identityProof: {
    home: {
      header: '계좌 개설 미완료',
      content: '안녕하세요. {name}님, 계좌개설이 거의 완료되었습니다. 다음의 자료들을 제출해주십시오.',
    },
    shufti: {
      content: `제공된 세부 정보가 ID 문서와 일치하고 동일한 언어로되어 있는지 확인하십시오.`,
      countryOfDocument: `문서에 표시된 국가`,
    },
    popup: {
      header: '안녕하세요. {name}님, 실계좌 개설을 완료하기 전 아래의 자료들을 업로드 해주십시오.',
      addressProofSpan: `<strong>주소확인</strong><br />
      다음 서류를제출해 주시길 바랍니다.<br />
      - 공과금(수도,전기,가스) 고지서 사본 (최근 6개월 이내 본인명의로 발급된 출력본)<br />
      - 은행 명세서 사본 (최근 6개월 이내 거래 내역 출력본)<br />
      <br />
      주의!파일명을 반드시 영문명으로 변경하여 업로드해 주시기 바랍니다. (예: Gil Dong Hong)`,
      idProofSpan: `<strong>신분증명</strong><br />
      선택합니다.아래의 신분증명 중 하나를 <br />
      - 여권(서명란 포함)<br/>
      - 운전면혀증(앞/뒤)<br />
      - 신분증(앞/뒤)<br />
      <br />
      주의!파일명을 반드시 영문명으로 변경하여 업로드해 주시기 바랍니다. (예: Gil Dong Hong)`,
    },
  },
  header: { secPortal: '보안되는<br />고객페이지 입니다.' },
  menu: {
    home: '메인',
    accManagement: '계좌관리',
    liveAcc: '실계좌',
    openAdditAcc: '추가 계정',
    homeDemo: '데모계좌',
    depositFund: '자금예치',
    withdrawFunds: '출금신청',
    transferBetweenAccs: '자금 이체',
    payDetails: '지불방식상세정보',
    funds: '자금',
    transactionHis: '거래내역',
    tranHis: '이체내역',
    download: '다운로드',
    profile: '프로필',
    myProfile: '내 정보',
    cpPassword: '비밀번호 변경',
    contactUs: '연락하기',
    register: '신청 계속하기',
    tradingTools: '전문 트레이더 도구',
    MARKET_BUZZ: '시장전망',
    ECON_CALENDAR: '경제캘린더',
    FEAT_FX: '아이디어',
    FX_IDEAS: '애널리스트 분석',
    promotion: '프로모션',
    noDeposit: '제로 입금 보너스',
    referFriends: '추천인 이벤트',
    depositBonus: '50% 입금 보너스',
    cryptoCashback: '가상화폐 트레이딩 캐시백',
    firstTimeDeposit: `FTD 캐시백 이벤트`,
    tradingTool: '거래 도구',
  },
  contact: {
    desc: '전문가로 구성된 {platform}의 글로벌 고객지원팀이 고객님께 실시간 고객지원 서비스를 제공합니다.',
    followUs: '우리를 따르라',
    tip: '고객지원팀 {email} 이메일로 연락주십시오.',
    liveChat: '실시간 채팅',
  },
  depositResultDialog: { msg: '자금 예치는 완료하셨습니까?' },
  dialog: {
    identityProof: { success: '감사합니다. 귀하의 자료는 성공적으로 접수되었으며 현재 담당부서에서 심사중입니다.' },
  },
  notification: {
    pendingIdentityProof: {
      header: '계좌 개설 심사중',
      id: '신분증명',
      poa: '주소증명',
      both: '신분증명 및 주소증명',
      content:
        '귀하가 제출하신 {doc}서류는 담당부서에서 심사중이며 곧 승인이 완료됩니다.  만약 계좌의 우선 개설을 원하신다면, 자금 예치를 통해 개설 활성화에 우선권을 부여 할 수 있습니다.',
    },
  },
  register: {
    progressStatus: { title: '계좌 인증 심사 진행중', estimatedTimeTitle: '예상시', min: '분' },
    tabs: {
      personalDetails: '개인정보',
      moreAboutYou: '더 알아보기',
      accountConfig: '계좌 환경',
      confirmYourId: '신분증명',
      fundYourAcc: '자금예치',
    },
    btn: { next: '다음', back: '이전', fundnow: '입금하기', upload: '업로드', done: '완료' },
    authority: {
      dialog1: '해당 페이지의 접속을 희망하실 경우 실계좌로 로그인 하시기 바랍니다.',
      dialog2: '지금 바로 실계좌를 개설하여 해당 페이지에 대한 접근 권한을 활성화 하시길 바랍니다.',
      openLiveAcc: '실계좌개설',
    },
    demo: {
      congrad: '축하드립니다!',
      title: '데모계좌는 60일간 유효합니다.',
      title2: '고객님의 데모 계좌 상세정보',
      li: { login: '로그인', srv: '서버', expDate: '유효기간' },
      emailSent: '*로그인 정보는 가입하신 이메일로도 발송되었습니다.',
      dl: 'MT4 거래 플랫폼 다운로드',
      improveQ: '당사의 트레이딩 환경이 궁금하신가요?',
      improveWay: '지금 실계좌를 개설하면 다양한 트레이딩 툴과 특별한 프로모션의 기회를 누릴 수 있습니다.',
      proTrade: '전문 마케팅 보고서',
      customerService: '24/7 고객서비스',
      competitivePromotionals: '경쟁력있는 프로모션',
      secure: '3D 보안 거래',
      openLiveAcc: '실계좌개설',
    },
    personalDetails: {
      page1: {
        pageTitle: '개인정보',
        userTitle: '호칭',
        firstName: '이름',
        lastName: '성',
        email: '이메일',
        dob: '생년월일',
        idType: '신분증명유형',
        referral: '당사를 추천해 주신 분이 있으신가요?  (선택사항)',
        middleName: '중간 이름',
        nationality: '국가',
        phoneNum: '연락처',
        date: '일',
        month: '월',
        year: '년도',
        placeOfBirth: '출생지',
        idNum: '신분증번호',
        passportNum: '여권번호',
        driverLicenseNum: '운전면허증번호',
        titleDropdown: { mr: '남성', mrs: '기혼 여성', ms: '여성', miss: '미혼 여성', dr: '의사', prof: '전문가/교수' },
        idTypeDropdown: { passport: '여권', idCard: '주민등록증', driverLicense: '운전면허증' },
      },
      page2: {
        pageTitle: '현 주소지 정보',
        mainResidency: '현재 거주지',
        streetNumber: '상세주소 (번지수, 동, 호)',
        streetName: '도로명',
        address: '상세주소',
        provinceOrState: '시/도',
        cityOrSuburb: '시/군/구',
        postcode: '우편번호',
        usQuestion: '미국에 세금을 납부하는 시민권/영주권을 가진 국민이거나 미국 소재의 은행 계좌가 있습니까?',
      },
    },
    moreAboutYou: {
      pageTitle1: '고용 현황 및 재무 상태 입력',
      pageTitle2: '거래 지식 및 경험',
      complianceExplain:
        '아래의 질문은 준법감시부의 AML/CTF (자금세탁방지 및 테러자금조달방지) 규범 요구 사항을 준수하기 위한 목적으로 수집됩니다. ',
    },
    accountConfig: {
      pageTitle: '계좌 환경',
      chooseTradingPlatform: '거래 플랫폼 선택',
      chooseAccType: '계좌 유형 선택',
      chooseAccCurrency: '계좌 통화 선택',
    },
    confirmYourId: {
      pageTitle: '신분증명',
      description:
        '당사는 정식으로 금융 규제를 준수하는 외환 중개사로서 귀하의 신원을 확인해야 합니다. 신분증명 및 거주증명 확인을 위해 다음 문서 중 하나를 업로드해 주십시오. 혹은 {mail}로 증명 문서를 보낼 실 수 있습니다.',
      poidTitle: '신분증명자료',
      porTitle: '주소증명자료',
      uploadDescription: '문서 유형을 선택한 후 파일을 업로드 해주십시오.',
      note: '중요: 파일명에 반드시 영문명을 입력해 주시기 바랍니다. (예: Gil Dong Hong)',
      pending: { upload: '업로드 대기중', verification: '승인 대기중' },
      poidTypes: { photoId: '신분증', passport: '여권', drivingLicence: '운전면허증' },
      porTypes: `  <li>공과금 고지서</li>
        <li>은행명세서</li>
        <li>영문등초본</li> `,
    },
    finishPage: {
      title: '{individualUserName} 님, {platformName} 실계좌 개설을 신청해주셔서 감사합니다!',
      contentDefault:
        '귀하의 계좌 개설 신청이 정상적으로 접수되었으며, <b>{account}</b> 실계좌가 개설 완료되었습니다.<br />\n      곧 귀하의 {email}로 로그인 세부 정보 및 계좌의 활성화 상태를 볼 수있는 링크가 포함 된 이메일이 발송됩니다. <br />',
      contentWithoutId3WithAccount:
        '귀하의 거래 계좌는 승인중이며 이와 관련된 사항은 이메일로 발송됩니다.<br /> <br />\n      만약 계좌의 우선 개설을 원하신다면, 자금 예치를 통해 개설 활성화에 우선권을 부여 할 수 있습니다. <br />',
      contentWithoutId3WithoutAccount:
        '귀하의 실계좌는 담당부서에서 심사중이며 이와 관련된 사항은 이메일로 발송됩니다. <br />',
    },
  },
  autochartist: {
    1: {
      title: `시장 스캐너`,
      desc01: `거래 기회에 대한 시장 관심 목록을 스캔할 수 있는 세계 최초의 시장 스캐너입니다. `,
      button01: `다운로드`,
    },
    2: {
      title: `시장 리포트`,
      desc01: `Autochartist 시장 리포트는 주요 금융시장 개장 전 시장의 기술적 전망에 대한 스냅샷을 제공합니다.`,
      button01: `시장 리포트 보러가기`,
    },
    3: {
      title: `Autochartist 검색 포털`,
      desc01: `사용 가능한 모든 데이터를 한 곳에서 확인할 수 있는 포괄적인 검색 포털입니다. `,
      button01: `시작하기`,
      button02: `사용자 안내`,
      button03: `OTP 접속`,
    },
    4: {
      title: `거래기회`,
      desc01: `Autochartist의 모든 분석 유형을 기반으로 15분마다 새로운 설정을 통해 업데이트된 거래 기회를 확인하십시오.`,
      button01: `거래 기회 보러가기`,
    },
    5: {
      title: `Autochartist 모바일 앱`,
      desc01: `거래 플랫폼 내의 관심 목록을 기반으로 알림 수신 후, 높은 확률로 필터링된 거래 기회를 추적합니다. `,
      desc02: `참조:`,
      desc03: `QR 코드는 일반 카메라로 스캔 가능하거나 (사용자의 휴대폰에 QR 스캐너가 내장되어 있을 경우) Autochartist 모바일 앱에서 'QR 코드를 사용하여 로그인' 버튼을 클릭하여 스캔할 수 있습니다. `,
      desc04: `QR 코드는 10분 동안만 유효하며 시간이 초과된 후에는 QR 코드를 새로고침 해주세요.`,
      desc05: `본 QR 코드는 1회만 사용 가능하며, 이후에는 QR 코드를 새로고침 해주세요.`,
      button01: `IOS 버전 다운로드`,
      button02: `안드로이드 버전 다운로드`,
    },
    6: {
      title: `뉴스레터 구독`,
      desc01: `뉴스레터를 구독하시면 Autochartist가 제공하는 이메일에서 글로벌 외환 시장의 스냅샷 확인이 가능합니다. `,
      button01: `구독하기`,
    },
    footer: `Autochartist는 현재 차트 패턴, 피보나치 패턴 및 주요 가격 포인트의 형태로 거래 기회를 자동으로 식별하는 데 사용할 수있는 세계에서 가장 진보 된 거래 도구 중 하나입니다. Autochartist의 고유 한 기술을 통해 검색 엔진은 하루 24 시간 수만 개의 금융 상품을 모니터링하여 잠재적 인 기회를 찾을 수 있습니다. 또한 Autochartist는 "변동성 분석"기능을 제공하여 거래하는 제품의 위험과 변동성을 더 잘 평가할 수 있습니다.`,
  },
  responseMsg: {
    410: '매개변수 검증에 실패하였습니다',
    411: '비밀번호 불일치',
    420: '사용자가 존재하지 않습니다',
    421: '비밀번호 재설정이 거부되었습니다',
    490: '중복된 카드 정보입니다. ',
    500: '에러가 발생했습니다.',
    501: '실행이 취소되었습니다. 나중에 다시 시도하십시오.',
    505: '파일 업로드에 실패하였습니다',
    520: '로그인에 실패했습니다. 다시 로그인하시기 바랍니다.',
    521: '사용자가 이미 동일 기기에 로그인했습니다.',
    522: '다시 로그인해주십시오.',
    523: '다시 로그인해주십시오.',
    524: '귀하의 이메일 주소가 유효하지 않습니다. ',
    525: '아이디 또는 비밀번호가 일치하지 않습니다.',
    527: '사용자가 IB가 아닙니다',
    528: '사용자가 존재하지 않습니다',
    529: '아이디 또는 비밀번호가 일치하지 않습니다.',
    540: '귀하의 거래 계좌를 찾을 수 없습니다.',
    541: '리베이트 계좌를 찾을 수 없습니다.',
    542: '같은 이름으로 계좌를 개설 할 수 없습니다.',
    544: '신분증이 인증될 때까지 추가 계정을 신청할 수 없습니다',
    550: '계좌번호 불일치',
    551: '잔액이 부족합니다.',
    553: '계정의 크레딧으로 인해 출금 / 이체 할 수 없습니다. 추가 도움이 필요하면 {email}에 문의하십시오.',
    554: '요청하신 금액이 0 또는 무효입니다.',
    564: '국가가 결제 채널과 일치하지 않습니다',
    565: '은행코드 불일치',
    566: '지불 방식을 찾을 수 없습니다.',
    568: '이 계정은 입금이 제한되어 있습니다. info@puprime.com으로 문의해주세요',
    581: '프로모션에 성공적으로 선택했습니다. 자금을 인출하려면 입금해야합니다.',
    582: '이벤트에 참여 중이며 직위가 있으므로 탈퇴 신청서를 제출할 수 없습니다.',
    587: '잔액이 부족합니다 고객 서비스에 문의하십시오.',
    584: '요청 가능한 리베이트가 존재하지 않습니다.',
    590: '송금 계좌를 찾을 수 없습니다.',
    591: '귀하의 은행 계좌를 찾을 수 없습니다.',
    593: '귀하의 계정에 크레딧이 포함되어 있기 때문에 선택한 계정에서 자금 이체를 처리할 수 없습니다.',
    594: '이 계정에서 자금 이체는 허용되지 않습니다',
    595: '귀하가 당사 활동에 참여하고 있으므로 현재 귀하의 이전 요청을 처리 할 수 ​​없습니다.',
    647: '확인 코드가 잘못 입력되었습니다',
    1001: '계정을 일시적으로 사용할 수 없습니다. 계정 관리자에게 문의하십시오.',
    session_timeout: '장 시간 로그인 하지 않았습니다. 재 로그인 해주십시오.',
    vload: {
      4019: '바우처 유형 또는 값이 허용되지 않습니다',
      4071: '사기 의혹',
      4072: '바우처 사용 완료',
      4073: '바우처가 활성화되지 않았습니다',
      4074: '바우처가 만료되었습니다',
      4075: '바우처를 찾을 수 없습니다',
      4080: '바우처의 값이 부족합니다',
      4444: '잘못된 바우처가 사용되었습니다. 통화가 일치하지 않습니다.',
      5000: '스토어 데이터를 사용할 수 없습니다',
    },
  },
  tradingTool: {
    mt4: {

    },
    pamm: {

    },
    copytrade: {

    }
  },
  activity: {
    myActivity: '',
    allActivity: '',
    activityContent: '',
    applyNow: '',
    activityStatus1: '',
    activityStatus2: '',
    activityStatus3: '',
    noActivity: '',
  }
}
