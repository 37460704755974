export default {
  en: '英語',
  zh: '中文',
  fr: 'Français',
  common: {
    liveChat: { desc: '助けが必要ですか？ライブチャット' },
    country: { international: '海外', australia: 'オーストラリア' },
    verification: {
      toSlide: 'スライドしてパズルを完成させます',
    },
    field: {
      nameOnCard: 'カード上の名前',
      cardholderName: `カード所有者の名前`,
      first4Digits: 'カードの最初の4桁',
      last3Digits: 'カードの最後の3桁',
      first6Digits: 'カードの最初の6桁',
      last4Digits: 'カードの最後の4桁',
      bankName: '銀行名',
      bankAddress: '銀行住所',
      branchAddress: '支店住所',
      bankBranch: '銀行支店',
      bankCity: '銀行の市',
      bankProvince: '銀行の都道府県',
      bankBeneficiaryName: '銀行の受取人名',
      bankBeneAccName: '銀行の受取人口座名',
      bankBeneAccNum: '銀行の受取人口座番号',
      bankBeneAddress: '受取人住所',
      bankAccNum: '銀行の口座番号',
      bankAccName: '銀行口座名',
      bankHolderAddress: '口座名義人の住所',
      address: '住所',
      accName: '口座名',
      accNum: '口座番号',
      accNumTo: '口座番号',
      bsbNum: 'BSB番号',
      swift: 'SWIFTコード',
      swiftNonAUD: 'SWIFTコード（AUD口座ではない場合）',
      amt: '金額',
      amtUSD: '金額（米ドル）',
      availableAmount: '利用可能額',
      transitNumber: '乗り継ぎ番号',
      institutionNumber: '機関番号',
      imptNotes: '重要事項',
      yes: 'はい',
      no: 'いいえ',
      payMethod: '支払方法：',
      expDate: '有効期限',
      bsb: 'BSB',
      abaSortCode: 'ABA/Sort Code:',
      country_region: '国/地域',
      currLeverage: '現在のレバレッジ',
      accEquity: '口座のエクイティ',
      newLeverage: '期待どおりのレバレッジ',
      currPw: '現在のパスワード',
      newPw: '新しいパスワード',
      confirmPw: '新しいパスワードを確認',
      loginEmail: 'ログイン用Eメール',
      email: 'Eメールアドレス',
      pw: 'パスワード',
      accType: 'アカウントタイプ',
      name: '名前',
      emailAdd: 'Eメールアドレス',
      phone: '電話番号',
      dob: '生年月日',
      nat: '国籍',
      country: '居住国',
      street: '番地/通りの名前',
      suburbOrCity: '郊外/都市',
      provinceOrState: '都道府県',
      postcode: '郵便番号',
      empStat: '雇用形態',
      annIncome: '年収',
      save: '投資',
      source: '収入源',
      industry: '業界',
      statusDef: 'ステータスの定義',
      month: '月',
      year: '年',
      select: '選択する',
      completed: '完了しました',
      incomplete: '未完成',
      submitted: '提出されました',
      successful: '成功しました',
      processing: '処理中',
      cancelled: 'キャンセルされました',
      failed: '失敗しました',
      rejected: '却下されました',
      upload: 'アップロードする',
      pending: `保留中`,
      ccNum: 'クレジットカード番号',
      ccExpireDate: '有効期限',
      bankCardNumber: '銀行の暗証番号',
      cardHolderName: 'カード名義人',
      reservedMobileNumber: '銀行用の携帯電話番号',
      nationalId: '国民IDカード',
      cardPhoto: 'カードの顔写真（表面）',
      notes: '注記',
      bankAccOrIBAN: '銀行口座番号/IBAN',
      selectWithdrawCard: '出金するカードを選択してください',
      updateCardPhoto: 'カードの顔写真をアップロードしてください（表面）',
      actualAmount: '実際金額',
      resetBalTo: '残高を以下までリセット：',
      ipChangeWarning: '誰かが別の位置からあなたのアカウントにログインしたときに、アラートメールを受け取る',
      enterVerificationCode: '認証コードを入力してください',
      tips: '',
      pleaseInput: '',
      confirmClose: '',
      notBind: '',
      infoDetails: '',
      loginWarn: '',
      noDataNow: '',
      hasAccountAlready: '',
    },
    column: {
      date: '日付',
      type: 'タイプ',
      server: 'サーバー',
      status: 'ステータス',
      comment: 'コメント',
      accNum: '口座番号',
      currency: '通貨',
      equity: 'エクイティ',
      leverage: 'レバレッジ',
      resetMT4PW: 'MT4パスワードのリセット',
      resetMT5PW: 'MT5パスワードのリセット',
      tradingAcc: '取引口座',
      method: '方法',
      amt: '金額',
      procNote: 'プロセス注記',
      number: 'カード番号',
      PLATFORM: 'プラットフォーム',
      resetBal: '残高をリセット',
    },
    key: { from: 'から', to: 'まで' },
    fund: {
      must: '必須',
      infoHover: 'カードの名義は、{must}取引口座の名前と一致している必要があります',
      ccUploadReminder: '確認のため、カード表面の写真をアップロードしてください。（真ん中の6桁を覆い隠してください）',
    },
    button: {
      bkToHm: 'ホームページに戻る',
      smsLogin: 'モバイルログイン',
      goToIB: 'IBポータルに進む',
      logout: 'ログアウト',
      submit: '提出する',
      selectFile: 'ファイルを選択',
      depositFunds: '資金を入金する',
      withdrawFunds: '資金を出金する',
      ibPortal: 'IBポータル',
      download: 'ダウンロード',
      changeLeverage: 'レバレッジの変更',
      cancel: 'キャンセル',
      changePw: 'パスワードを変更する',
      forgetPw: 'パスワードを忘れた',
      confirm: '確認する',
      login: 'LOG IN',
      forgotPw: 'パスワードを忘れましたか？',
      completeQuestionnaire: 'アンケートを完了する',
      identityProof: '申込を完了する',
      upload: 'アップロード',
      export: 'エクスポート',
      ok: 'OK',
      hide: '隠す',
      showAll: 'すべて表示',
      createLiveAccount: `ライブアカウントを作成`,
      redeem: `バウチャーを引き換える`,
      sendCode: 'コードを送信',
      resendCode: 'コードを再送する',
    },
    formValidation: {
      accReq: '口座番号は必須です',
      amtReq: '金額は必須です',
      ccReq: 'クレジットカード番号は必須です',
      cardReq: 'カード番号は必須です',
      cardOnNameReq: 'カード名義人の名前は必須です',
      cardholderNameReq: `カード所有者の名前が必要です`,
      digits16: '16桁を入力してください',
      digits3or4: '3桁または4桁を入力してください',
      digitsFirst6: '最初の6桁を入力してください',
      digitsLast4: '下4桁を入力してください',
      digitsLast3: '下3桁を入力してください',
      mid6Digits: '中央の {number} 桁を入力してください',
      expDateReq: '有効期限は必須です',
      cvvReq: 'セキュリティコードCVVは必須です',
      file: 'ファイルをアップロードしてください',
      filePhoto: 'クレジットカードの写真が必須です',
      fileReceipt: '領収書をアップロードしてください',
      filePhotoReq: 'カードの顔写真が必須です',
      amtLarger: '金額は空白にできません。{minLimit} {currency} 以上でなければなりません',
      amt0: '金額を0にすることはできません',
      amtLess: '預入金額は{maxLimit}米ドルを超えることはできません',
      note256: '長さの制限は256文字であることに注意してください',
      noteMoreThan: '長さの制限は{limit}文字であることに注意してください',
      noteLessThan: '長さの制限は{limit}文字であることに注意してください',
      emailFormat: '正しいメールアドレスを入力してください',
      payMethodReq: '支払方法は必須です',
      expReq: '有効期限は必須です',
      bankNameReq: '銀行名は必須です',
      bankCityReq: '銀行の市は必須です',
      bankProvinceReq: '銀行の県は必須です',
      bankAddressReq: '銀行の住所は必須です',
      bankBranchReq: '銀行支店は必須です',
      bankAccNumReq: '銀行口座番号は必須です',
      bankBeneficiaryNameReq: '銀行受取人の名前は必須です',
      bankHolderAddressReq: '口座名義人の住所は必須です',
      swiftReq: 'SWIFTコードは必須です',
      accHolderAddress: '口座名義人の住所は必須です',
      bsbReq: 'BSBは必須です',
      accNumIBANReq: '銀行口座番号/IBANは必須です',
      accNameReq: '銀行口座名は必須です',
      withdrawMethodReq: '出金方法を選択してください',
      tnc: '利用規約に同意してください。',
      currPwReq: '現在のパスワードが必須です',
      currPwWrong: '現在のパスワードが正しくありません',
      newPwReq: '新しいパスワードが必須です',
      newPwFormat:
        'パスワードは8～16文字で、英字（a～z、A～Z）、数字（0～9）、!@#$%^&*.() などの特殊文字が混在している必要があります',
      IdenficalPw: '新しいパスワードを古いパスワードと同じにすることはできません',
      confirmPwReq: '新しいパスワードをもう一度入力してください',
      confirmNotMatch: '2つの入力が一致しません',
      pwReq: 'パスワードが必須です',
      accCurrReq: '口座の通貨を選択する必要があります',
      tradAccReq: '取引口座を選択してください',
      answerReq: '選択してください',
      bankCardReq: 'カードを選択してください',
      phoneNumberReq: '銀行用の携帯電話番号は必須です',
      nationaIdReq: '国民IDが必須です',
      numberReq: '番号を入力してください',
      alphanumericReq: '英数字を入力してください',
      idTypeReq: 'IDの種類を選択してください',
      dobReq: '生年月日を選択してください',
      pobReq: '出生地を選択してください',
      nationalityReq: '国籍を選択してください',
      titleReq: '職業を選択してください',
      firstNameReq: '名を入力してください',
      lastNameReq: '姓を入力してください',
      emailReq: 'Eメールアドレスを入力してください',
      addressReq: '住所を入力してください',
      suburbReq: '市または郊外を入力してください',
      stateReq: '都道府県は必須です',
      postcodeReq: '郵便番号を入力してください',
      countryReq: '居住国を選択してください',
      masterCardReq: '入金にはマスターカードをご利用ください',
      masterCardJCBReq: `マスターカード/ JCBを使用して入金してください`,
      masterCardVisaReq: `マスターカード/ Visaを使用して入金してください`,
      masterCardAmexJCBReq: `マスターカード / AMEX / JCBを使用して入金してください`,
      bankCodeReq: 'カード発行会社を入力してください',
      dynamicReq: '{dynamic} は必須です',
      mobReq: '電話番号を入力してください',
      resetBalAmt: 'アカウント番号は0から1,000,000の間で設定してください',
      verCodeReq: '認証コードが必要です',
      emailFormatWrong: 'eメールの形式が誤っています',
      passwordFormatWrong: 'パスワードの形式が誤っています',
    },
    // for WITHDRAW FUNDS
    withdrawChannel: {
      banktransferbpaypolipay: '銀行振込/ BPay / /PoliPay',
      banktransfer: '銀行振込',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'UnionPay送金',
      fasapay: 'FasaPay',
      creditcard: 'クレジットカード',
      bpay: 'BPAY',
      creditdebitcard: 'クレジット/デビットカード',
      bankwiretransferinternational: '銀行電信送金（国際）',
      banktransferaustralia: '銀行振込（オーストラリア）',
      banktransferinternational: '銀行振込（国際）',
      banktransferchina: '銀行振込（国際）',
      banktransferequals: '銀行振込（国際）',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'ブローカーからブローカーへの転送',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'インターネットバンキング（タイ）',
      internetbankingnigeria: 'インターネットバンキング（ナイジェリア）',
      internetbankingvietnam: 'インターネットバンキング（ベトナム）',
      internetbankingmalaysia: 'インターネットバンキング（マレーシア）',
      internetbankingindonesia: 'インターネットバンキング（インドネシア）',
      internetbankingphilippine: 'インターネットバンキング（フィリピン）',
      internetbankingsouthafrica: 'インターネットバンキング（南アフリカ）',
      internetbankingkorea: 'インターネットバンキング（韓国）',
      internetbankinguganda: 'インターネットバンキング (ウガンダ)',
      internetbankingrwanda: 'インターネットバンキング (ルワンダ)',
      internetbankingzambia: 'インターネットバンキング (ザンビア)',
      internetbankingcongo: 'インターネットバンキング (コンゴ)',
      internetbankingcameroon: 'インターネットバンキング (カメルーン)',
      internetbankingburundi: 'インターネットバンキング (ブルンジ)',
      internetbankingkenya: 'インターネットバンキング (ケニア)',
      internetbankingghana: 'インターネットバンキング (ガーナ)',
      internetbankingtanzania: 'インターネットバンキング (タンザニア)',
      internetbankingcanada: 'インターネットバンキング (カナダ)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'ベトナム銀行振込',
      nigeriabanktransfer: 'ナイジェリア銀行振込',
      polipayment: 'Poli支払い',
      bankwiretransferaustralia: '銀行電信送金（オーストラリア）',
      thailandinstantbankwiretransfer: 'タイの即時銀行電信送金',
      nigeriainstantbankwiretransfer: 'ナイジェリアの即時銀行電信送金',
      vietnaminstantbankwiretransfer: 'ベトナムの即時銀行電信送金',
      malaysiainstantbankwiretransfer: 'マレーシアの即時銀行電信送金',
      indonesiainstantbankwiretransfer: 'インドネシアの即時銀行電信送金',
      southkoreabanktransfer: '韓国地方銀行振込',
      southafricainstantbankwiretransfer: '南アフリカの即時銀行電信送金',
      philippinesinstantbankwiretransfer: 'フィリピンの即時銀行電信送金',
      ugandabanktransfer: 'ウガンダ国内銀行送金',
      rwandabanktransfer: 'ルワンダ国内銀行送金',
      zambiabanktransfer: 'ザンビア国内銀行送金',
      congobanktransfer: 'コンゴ国内銀行送金',
      cameroonbanktransfer: 'カメルーン国内銀行送金',
      burundibanktransfer: 'ブルンジ国内銀行送金',
      kenyabanktransfer: 'ケニア国内銀行送金',
      ghanabanktransfer: 'ガーナ国内銀行送金',
      tanzaniabanktransfer: 'タンザニア国内銀行送金',
      indiabanktransfer: 'インド銀行振込',
      banktransferbpaypolipayaustralia: '銀行振込/ BPay / PoliPay （オーストラリア）',
      banktransferbpaypolipayinternational: '銀行振込/ BPay / PoliPay （国際）',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'カナダの国内銀行送金',
      cryptobitcoin: '暗号通貨-Bitcoin',
      cryptotetheromni: '暗号通貨-Tether(OMNI)',
      cryptotethererc20: '暗号通貨-Tether(ERC20)',
      cryptotethertrc20: '暗号通貨-Tether(TRC20)',
      cryptocurrencybitcoin: '暗号通貨-Bitcoin',
      cryptocurrencyusdt: '暗号通貨-USDT',
      cryptocurrencyeth: '暗号通貨-ETH',
      cryptocurrencyusdc: '暗号通貨-USDC(ERC-20)',
      cryptocurrencyusdcerc20: '暗号通貨-USDC(ERC-20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      E_WALLET: '電子財布',
      CRYPTOCURRENCY: '暗号通貨',
      LOCAL_TRANSFER: 'ローカル転送',
      archiveThisCard: 'このカードは無効もしく使用中の場合は、情報を保存してください',
      manageCreditCards: 'クレジットカードの管理',
      update: 'アップデート',
      archive: 'アーカイブ',
      requiredDocument: 'カード情報を保存するために必要な書類',
      officialLetter: 'カードが使えなくなったことを明記した銀行からの正式な手紙/Eメール',
      bankStatement: 'このカードで取引された銀行明細書',
      expiredCard: '期限切れか利用不可のカード',
      confirmArchive: 'このカードをアーカイブしてもよろしいでしょうか？',
      confirmUpdate:
        'このカードの有効期限を更新しますか？ ご注意:Puprimeは、提供されたカードの有効期限が正しくないために出金が失敗した場合は、責任を負いかねます。',
      pendingArchiveRequest:
        'お客様のクレジットカードのアーカイブリクエストは現在承認を保留しています。出勤は処理が完了するまでお待ちください。あるいは、処理をお急ぎの方はライブチャット経由でカスタマーサービスにお問い合わせください。',
    },
  },
  home: {
    pendingTooltip:
      'ライブアカウントが承認されると、MT{number}ログインの詳細が記載されたメールが届くので、取引を開始できます！ その間、あなたは優先的な口座開設を受け取るためにあなたの口座に資金を入金することができます。',
    leverage: {
      tnc:
        '<p>このリクエストを送信することにより、高いレバレッジが私の資本に大きなリスクをもたらし、入金した資本に限らず、それ以上の損失を被る可能性があることを認識しています。 商品発行者は私の裁量で、事前の警告や通知なしに、私の取引活動を確認し、私の取引口座のレバレッジレベルを調整する権利があることに同意します。 高いレバレッジを選択することで、より大きなマージンで取引できるが、大きな損失が発生する可能性があることを理解しています。</p>',
      changeLeverageConfirm: 'リクエストが送信されました。まもなくサポートチームによって処理されます。',
      changeLeverageAutoComplete: 'レバレッジ変更リクエストが完了しました',
      changeLeverageReject: 'レバレッジの変更リクエストは却下されました',
      changeLeverageFail: 'レバレッジの変更リクエストが失敗しました。しばらくしてからもう一度お試しください',
      changeLeverageDefault: 'エラーが発生しました。{supportEmail}に連絡して、アプリケーションを完成させてください',
      alreadyApplied:
        'アカウントのレバレッジの変更をすでにリクエストしており、現在{platform}サポートチームによるレビュー中です。別のリクエストを送信する前に、このリクエストが確定するまで待ってください。',
      reduceLeverageWarn: `注意：レバレッジを下げると、アカウントのマージンが不十分な場合、オープンポジションが強制的に閉鎖される可能性があります。`,
      reduceLeverageConfirm: `続行しますか？`,
      autoRestore: `お客様の口座資本が20,000米ドルを超えたため、利用可能な最大レバレッジは500倍です。口座資本が20,000米ドル以下の場合、レバレッジを1,000倍に変更することができます。`,
    },
    pw: {
      error: 'パスワードをリセットできませんでした。 しばらくしてから、もう一度お試しください',
      confirm: 'パスワードが更新されました。<br>新しいパスワードを使用してMT4 / MT5取引アカウントにアクセスできます。',
      email:
        '<p class="first_info">パスワードをリセットする手順を記載したEメールを送信しました。</p><p>必ずメールの迷惑メールフォルダをご確認ください。</p>',
    },
    activate: {
      question: 'ライブアカウントを再度有効にしますか？',
      confirm: 'アクティベーションリクエストを受け取りました。<br>アカウントはまもなく有効化されます。',
    },
    accStatus: {
      active: '有効',
      rejected: '却下されました',
      inactive: '非有効',
      processing: '処理中',
      activate: '有効にする',
      expired: '期限切れ',
    },
    pro1000: {
      desc1: `レバレッジ1,000倍の口座には、以下の制限があります`,
      conditions: [
        `選択された口座は、クレジット関連のプロモーションに参加することはできません。`,
        `選択された口座の資本が20,000米ドルまたはその相当額を超える場合、レバレッジはシステムにより自動的に500倍に引き下げられることがあります。未決済の取引がある場合は、レバレッジ調整前にメールで通知されます。`,
        `レバレッジの上限は、適用される規制によって異なる場合があります。`,
        `1,000倍のレバレッジは、暗号通貨、株式、銀、コモディティの金融商品には適用されませんのでご注意ください。これらの商品の証拠金は、商品の固定証拠金制度に従って保有されており、1,000倍のレバレッジの影響を受けません。`,
      ],
      desc2: `このリクエストを提出することにより、私は、高いレバレッジは私の資本に大きなリスクをもたらし、私が預けた資本を超える、またはそれに限定されない損失を被る可能性があることを認識していることを認めます。`,
      desc3: `私は、商品発行者が私の取引活動を確認し、事前の警告や通知なしに、その裁量で私の取引口座のレバレッジレベルを調整する権利があることに同意します。`,
      desc4: `ハイレバレッジを選択することで、より大きな証拠金で取引ができる反面、大きな損失を被る可能性があることを理解しています。`,
    },
  },
  paymentDetails: {
    subHeader: 'お支払い方法を追加',
    payMethod: 'お支払い方法',
    tip:
      '<p>以下のメニューから、今後の入金と出金に使用するお支払い方法を選択してください。</p><br />\n    <p><b>注意：</b> 入金と出金のリクエストを迅速に処理できるようにするために、AML / CTF規制では、選択したお支払い方法に表示される名前が\n      <b> {individualUserName}</b>であることを義務付けています。</p>',
    result: {
      defaultSuccessMessage:
        '取引口座の資金調達方法を提出していただきありがとうございます。現在、お客様のリクエストを処理しています。近日中にご連絡いたします。',
      unionPayCardSuccessMessage:
        'Union Payカードの詳細が送信されました。新しい銀行の詳細は、承認されると利用可能になります',
    },
    history: { header: 'お支払い方法' },
    cc: {
      field: {
        ccNum: 'クレジットカード番号',
        first6: '最初の6桁',
        last4: '下4桁',
        withrawlable: 'このカードの最大引き出し可能額は{amount}です。',
      },
      formValidation: { upload: 'クレジットカードの写真が必須です' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: '入金状況を取得しています...残り{countDown}秒。',
      successMsg: 'ご入金ありがとうございます。資金はまもなくアカウントに表示されます。',
      rejectMsg: '却下されました',
      failureMsg: '入金は失敗しました。しばらくしてからもう一度やり直してください。',
      message:
        'アカウントにご入金いただきありがとうございます。入金はまもなく{platform}アカウントチームによって手動で承認されます。資金は承認されるとアカウントに入金されます',
      defaultException: '{platform}で入金を確認するには、{email}サポートにお問い合わせください',
    },
    failed: { countDownMsg: '入金状況を取得しています...残り{countDown}秒。', rejectMsg: '却下されました' },
  },
  deposit: {
    alert: {
      depositBonus50: `50%のデポジットボーナスを受け取ることができます。入金前にアクティベート!`,
    },
    default: {
      deposit: '入金',
      reference: 'ご参考',
      sendReceipt: '領収書を送信',
      sendReceiptTip:
        '振込が完了したら、以下のフォームに記入し、銀行振込の領収書のスクリーンショット、写真、またはスキャンしたコピーを添付してください。これにより、お客様の氏名、口座番号、入金金額が明確に示され、取引を処理できます。',
      reminder: '資金がアカウントに入金されるまで、1〜2営業日かかります。',
      successMsg: '入金を受け取り、取引の領収書を確認したら、資金が取引口座に表示されます。',
      alt: 'または、取引の領収書をできるだけ早く{mailTo}にメールで送信することもできます。',
      reminderFundAppear: '以前のブローカーから資金を受け取り次第、資金はアカウントに表示されます。',
      failed: '入金できませんでした。しばらくしてからもう一度お試しください。',
      fee: '手数料',
      instant: '即時',
      hour: '時間',
      hours: '時間',
      businessDays: '営業日',
      withinOneBusinessDays: '1営業日以内',
      secondaryNGN: '(ナイジェリアの通貨ナイラ(NGN)をご利用される場合は、このアカウントをお使いください。)',
      disclaimer: `<li>{platform}は、サードパーティの支払いを厳格に受け付けません。取引口座に入金されるすべての資金は、{platform}取引口座と同じ名前である必要があります。</li>
      <li>{platform}はいかなるサードパーティーからの支払も受け付けていません。取引口座に入金されたすべての資金は、名義が{platform}のクライアントポータルの口座と同じでなければなりません。</li>
      <li>クライアントは、入金リクエストの送信が{platform}による資金の受け入れを構成しないことを理解しています。アカウントがマージンコールに近い場合、またはマージンコール中の場合は、他の準備が整っていることを確認してください。</li>
      <li>{platform}は、支払いシステムに起因する遅延/エラーの責任を負いません。</li>
      <li>{platform}は、送金金融施設またはサードパーティの支払いプロバイダーが支払いの送金に対してお客様に請求する可能性がある手数料（つまり、変換手数料、前払い料金など）の責任を負いません。</li>
      <li>クライアントは、入金リクエストを処理する際に資金源を特定するために{platform}が必要だと判断した資金源や、クライアント資産一般、および他の情報を提供することを求められる可能性があることを認め、{platform}によって求められたすべての情報を提供することに同意するものとします。求められた情報を提供できなかった場合は、リクエストの処理が著しく遅延する可能性があります。{platform}は、そういった遅延の結果として生じるあらゆる遅延や損害に一切の責任を負いません。</li>`,
      channels: {
        creditOrDebit: 'クレジット/デビットカード',
        i12BankTransfer: '国際銀行振込',
        unionPay: 'Union Pay入金',
        unionPayCN: '中国Union Pay送金',
        vietInstant: 'ベトナム即時銀行電信送金',
        thaiInstant: 'タイの即時銀行電信送金',
        nigeriaInstant: 'ナイジェリア即時銀行電信送金',
        malayInstant: 'マレーシアの即時銀行電信送金',
        indonesiaInstant: 'インドネシア即時銀行電信送金',
        philippineInstant: 'フィリピン即時銀行電信送金',
      },
      rate: {
        rate: '為替レート （{oldCurrency} / {newCurrency}）： ',
        result: {
          rateError: '{oldCurrency}から{newCurrency}へのレートの取得に失敗しました。',
          channelError: '支払い手段の取得に失敗しました。',
        },
      },
      cn: { rate: '為替レート（USD / RMB）： ', amt: 'RMB: ' },
      upgrade:
        'ただ今入出金システムをアップデートしております。以前、この入金方法で正常に入金したことあるお客様のみご利用いただけます。ご迷惑おかけ致しまして大変申し訳ございません。',
      depositInstruction: '預け入れのご案内',
      depositNote: '預け入れ記録',
    },
    method: {
      desc: '{method}で入金する方法',
      inst1: '{number}.   下のフォームに記入して‘提出する’をクリックしてください',
      inst2: '{number}.   銀行選択ページで銀行を選択し、{country} のオンライン銀行にログインします',
      inst3: '{number}.   指示に従って、オンライン/モバイル銀行から支払いを行います',
      inst4: '{number}.   次に、カードの詳細を入力するための別のフォームにリダイレクトされます',
      inst5: '{number}.   カードの詳細を入力したら、‘提出する’を押します',
      inst6: '{number}.   クライアントポータルにリダイレクトされ、トランザクションが完了します',
      inst7: `{number}.   通貨が{currency}でない場合、銀行は現在の銀行の為替レートに基づいて変換手数料を請求する場合があります。`,
      inst8: `{number}.   EUR、GBP、AUD、SGD、NZD、HKD、JPY、USDを使用したクレジットカードのデポジットは、1回の取引あたり最大$ 5,000USDに制限されています。 CAD建てのクレジットカード預金は、1回の取引あたり最大6,500カナダドルまで受け付けています`,
      inst9: `{number}.   デポジットごとに最大$ 1500 USD`,
      inst10: `{number}.   支払いの詳細ページがポップアップします`,
      inst11: `{number}.   銀行選択ページで銀行を選択し、オンライン銀行にログインします`,
      inst12: `{number}.   CADでの支払いを受け入れることができます。入金ごとに最大$9,999 CAD`,
      form: {
        header: '{method}フォーム',
      },
    },
    reminder: {
      inst1: '下のフォームに記入して‘提出する’をクリックしてください',
      inst2: '{method}アカウントにログインします',
      inst3: '{method}アカウントのプロンプトに従って、支払いを完了します',
      inst4: '次に、カードの詳細を入力するための別のフォームにリダイレクトされます',
      inst5: 'カードの詳細を入力したら、‘提出する’を押します',
      inst6: 'クライアントポータルにリダイレクトされ、トランザクションが完了します',
      inst7: '次に、{method}のウォレットアドレスを表示する新しいウィンドウが表示されます',
      inst8:
        '{method}のウォレットアドレスをコピーしてから、{method}ウォレットにログインし、希望する金額を{method}アドレスに転送します。',
      inst9: 'QRコードをスキャンして、モバイルバンキングアプリケーションを開きます',
      inst10: 'モバイルデバイスのプロンプトに従って、入金を完了します',
      inst11: '資金が失われることのないよう、必ずリダイレクトされた決済ページからオンラインでお支払いください。',
      inst12: '銀行選択ページで銀行を選択し、オンライン銀行にログインします',
      inst13: '指示に従って、オンライン/モバイル銀行から支払いを行います',
      inst14:
        '振込が完了したら、下のフォームに記入し、氏名、口座番号、入金金額が明確に示されている銀行振込領収書のコピーを添付して、取引を処理できるようにしてください。',
      inst15: '資金がアカウントに入金されるまで2〜5営業日かかります。',
      inst16: 'アカウントへのご入金には1営業日ほどかかります。',
      inst17: 'トランザクションを実行できるよう、お客様の氏名、口座番号、送金額がはっきりと写るようにしてください。',
      inst18:
        'お客様の口座に返金処理が行われるまで1～6時間を要する可能性がございますので、ご理解のほどよろしくお願いいたします。',
      note1: '{method} での入金は、1日あたり1回につき$25,000 USD、最大$75,000 USDに制限されています。',
    },
    southAfrica: { header: '南アフリカの即時銀行電信送金', country: '南アフリカ' },
    idn: { header: 'インドネシア即時銀行電信送金', country: 'インドネシア' },
    ida: { header: 'インド即時銀行電信送金', country: 'インド' },
    nigeria: { header: 'ナイジェリア即時銀行電信送金', country: 'ナイジェリア' },
    thailand: { header: 'タイの即時銀行電信送金', country: 'タイ' },
    malay: { header: 'マレーシアの即時銀行電信送金', country: 'マレーシア' },
    php: { header: 'フィリピンの即時銀行電信送金', country: 'フィリピン' },
    vietnam: { header: 'ベトナムの即時銀行電信送金', country: 'ベトナム' },
    korea: { header: '韓国地方銀行振込', country: '韓国' },
    uganda: { header: 'ウガンダ国内銀行送金', country: 'ウガンダ' },
    rwanda: { header: 'ルワンダ国内銀行送金', country: 'ルワンダ' },
    zambia: { header: 'ザンビア国内銀行送金', country: 'ザンビア' },
    congo: { header: 'コンゴ国内銀行送金', country: 'コンゴ' },
    cameroon: { header: 'カメルーン国内銀行送金', country: 'カメルーン' },
    burundi: { header: 'ブルンジ国内銀行送金', country: 'ブルンジ' },
    kenya: { header: 'ケニア国内銀行送金', country: 'ケニア' },
    ghana: { header: 'ガーナ国内銀行送金', country: 'ガーナ' },
    tanzania: { header: 'タンザニア国内銀行送金', country: 'タンザニア' },
    creditOrDebit: {
      header: 'クレジットカード',
      worldcard: {
        note1:
          'EUR、GBP、およびUSDを用いたクレジットカードによる入金は、1回のトランザクションあたりの金額が最大で$20,000USDに制限されています。EUR、GBP、およびUSDを用いたクレジットカードによる入金は、1回のトランザクションあたりの金額が最大で$20,000USDまたは他通貨における相当額に制限されています。',
        note2: 'ユーザー1人あたりの異なるカードの枚数は3枚です。この制限を超える試行は受け付けられません。',
      },
      virtualPay: {
        note1:
          'EUR、GBP、AUD、SGD、NZD、HKD、JPY、USC、およびUSDを用いたクレジットカードによる入金は、1回のトランザクションあたりの金額が最大で10,000USDに制限されています。 ',
        note2:
          '24時間以内のカード1枚あたりの上限は、50,000USDまたは10トランザクションです。この制限を超える試行は受け付けられません。',
      },
      Sdpay: {
        note1:
          'EUR、GBP、およびUSDを用いたクレジットカードによる入金は、1回のトランザクションあたりの金額が最大で$2,000USDに制限されています。',
        note2: '1日あたりのトランザクションの上限は8回です。この制限を超える試行は受け付けられません。',
        note3:
          '初めて支払を行う場合、保留されることがある可能性があることにご注意ください。当社のチームが認証を完了するのをお待ちください。または、カスタマーサポートチームにお問い合わせいただくこともできます。',
      },
      maxisPay: {
        note1: 'USDを使用したクレジットカードのデポジットは、1回の取引あたり最大$ 5,000USDに制限されています。',
      },
    },
    net: {
      header: 'Neteller',
      note1: 'AUD、USD、EUR、CAD、GBPでのお支払いが可能です。',
      note2: '口座への入金は、MT4取引口座と同じ通貨でのみ可能です。',
      note3:
        '不正確な電子メールが使用された場合、Netellerは自動的にお客様の口座の基本通貨に資金を変換します。手数料がかかる場合があります。',
      note4: 'ご入金は、PU Prime口座と同じ名義である必要があります。',
    },
    vload: {
      header: 'VLOAD入金',
      inst1: '2.   お支払いにはUSDと、EUR、およびJPYをご利用いただけます。',
      inst2: `<li>3.   Vloadバウチャーを購入するにはクリック <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">こちら</a>.</li>`,
      form: {
        voucherpin: 'バウチャーPIN',
        voucherpinReq: 'バウチャーPINが必要です',
      },
    },
    bankWireAu: {
      header: '銀行電信送金：オーストラリア',
      instruction:
        '資金は、金融機関のオンラインバンキング施設を介して{platform}に送金できます。または、金融機関に連絡して{platform}への送金をリクエストすることもできます。',
      tip: '入金したい口座通貨を選択してください。取引口座の通貨と同じ通貨を選択してください',
      form: {
        bankDetails: '銀行詳細（AUD入金のみ）',
        refTip: 'ここにログイン番号を指定してください',
        afp: 'オーストラリアの高速支払い（国内AUD入金のみ）',
        payIdOrABNNum: 'PayID / ABN番号',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip: '銀行振込の参照欄に{platform}の取引口座番号を入力してください。',
      },
    },
    bitwallet: {
      header: 'Bitwallet',
      note1: 'お支払いはUSD、AUD、EUR、USC、JPYで承ります',
    },
    astropay: {
      desc: 'ASTROPAYでアカウントに資金を入金する方法',
      note1: '全ての通貨でのお支払いを承ります。',
      form: { header: 'ASTROPAYフォーム' },
    },
    bPay: {
      header: 'BPAY入金',
      option: 'BPayでアカウントに入金する方法（オーストラリアのみ）',
      inst1: '1. MT4 / MT5アカウントを選択して、BPay請求者コードとBPay参照番号を表示します',
      inst2:
        '2. 支払いの詳細、BPay請求者コード、およびBPay参照番号をインターネットバンキング機関に入力し、支払いを送信します',
      inst3: '3. このページに戻り、下記のBPayフォームに記入して、取引を迅速に処理できるようにします',
      form: {
        header: 'BPAYフォーム',
        billerCode: 'BPay請求者コード',
        refNum: 'BPay参照番号',
        receipt: 'BPAY振込領収書',
        reminderNotUploaded:
          '取引領収書をアップロードしていないようです。入金を処理するには、できるだけ早くレシートのコピーを{mailTo}にEメールで送信してください。',
        reminderProc: 'ご注意ください。領収書を受け取るまで、取引を処理できません。',
      },
    },
    b2b: {
      header: 'ブローカーからブローカーへ',
      option: '別のブローカーから{platform}に資金を転送する方法',
      inst1: '1. {download}をダウンロードして完了します',
      inst2:
        '2. 以下のオンラインフォームに記入し、記入済みのブローカーからブローカーへの転送フォームをアップロードします',
      inst3:
        '3. 転送を開始するために、記入済みのブローカーからブローカーへのフォームのコピーを以前のブローカーに送信してください。',

      b2bForm: 'ブローカーからブローカーへの転送フォーム',
      formValidation: { upload: 'ブローカーからブローカーへのフォームが必須です' },
    },
    cc: {
      header: 'クレジットカードまたはデビットカードの入金',
      remind:
        'プライマリメソッドを使用してアカウントに資金を入金する際に問題が発生した場合は、セカンダリクレジット/デビットメソッドを使用してください',
      field: {
        fundNow: 'クレジット/デビットカードで今すぐ資金を入金',
        newCard: '新しいカード',
        cardNum: 'クレジットカード番号',
        nameOnCard: 'カード上の名前',
        expDate: '有効期限',
        cvv: 'セキュリティコードCVV',
        bankCode: 'カード発行銀行',
        example: `シンプルカード`,
      },
      placeholder: { bankCode: 'カード発行銀行名を入力してください' },
      cvvTip: 'カードの裏面に表示 されている3桁',
      threeDomainSecQ: 'カードは3Dセキュアをサポートしていますか？',
      threeDomainSec:
        '3Dセキュア認証とは何ですか？<br /><strong> 3Dセキュア認証は、VisaおよびMastercardによって承認された不正防止方法です。<br /><br />カードが3Dセキュアをサポートしている場合、資金はすぐに処理されます。</strong>',
      cardUpload:
        '資金の入金に使用しているカードは、取引口座にリンクされていません。 確認のため、カードの表面の写真をアップロードしてください。 （真ん中の6桁を隠してください）',
      result: {
        success:
          'ありがとうございます。現在、カードの詳細を確認しています。確認後、24時間以内にアカウントに入金されます。',
        depositSuccess: '入金していただきありがとうございます。資金はまもなくアカウントに表示されます',
        error:
          'アカウントのセキュリティを保護するため、入金取引の最大試行回数に達し、一時的に入金が一時停止されました。 {email}までご連絡ください',
      },
      tipOnlyTaiwan: `お客様各位、クレジットカードでの引き出しは銀行の処理により遅れる場合があり、到着時間は各銀行によって異なりますのでご注意ください。 緊急の資金が必要な場合は、他の方法で入出金することをお勧めします。`,
      reminder: `<li>1. EUR、GBP、USDを使用したクレジットカードの入金は、1回の取引あたり最大$1,900 USDまたは同等の通貨に制限されています。</li>
      <li>2. 1日あたり最大8トランザクション。</li>
      <li>3. 初めてこの入金方法でご入金される場合は、セキュリティチェックしてから取引口座に反映致します。
      恐れ入りますが、反映されるまでしばらくお待ちください。
      何かご質問等ございましたら、お手数ですが、カスタマサポートにご連絡ください。</li>`,
    },
    fasa: {
      description: 'FasaPayでアカウントに資金を入金する方法',
      form: { header: 'FASAPAYフォーム' },
    },
    intSwift: {
      header: '銀行電信送金：国際SWIFT',
      desc:
        '資金は、金融機関のオンラインバンキング施設を介して{platform}に送金できます。または、金融機関に連絡して{platform}への送金をリクエストすることもできます。',
      desc2:
        '振込が完了したら、下のフォームに記入し、氏名、口座番号、入金金額が明確に示されている銀行振込領収書のコピーを添付して、取引を処理できるようにしてください。',
      alertSAandNGA:
        '米ドル以外の通貨をお持ちの場合は、送金前に米ドルに両替してください。 直接送金する場合、その時点で発生する為替レートの差額と手数料はお客様のご負担となります。',
      options: '資金を入金したい取引口座を選択してください',
      refInfo: '銀行振込の参照欄に{platform}の取引口座番号を入力してください。',
      bussDayInfo: '資金がアカウントに入金されるまで2〜5営業日かかります。',
      tip: '<b>振込領収書に氏名</b> 、<b>口座番号</b> 、 <b>入金金額</b>が明確に記載されていることを確認してください。',
    },
    mobile: {
      header: 'MobilePay',
      desc: 'MobilePayでアカウントに資金を入金する方法',
      inst1: '1.下のフォームに記入して‘提出する’をクリックしてください',
      inst2: '2. QRコードをスキャンして、モバイルバンキングアプリケーションを開きます',
      inst3: '3.モバイルデバイスのプロンプトに従って、入金を完了します',
      form: { payMethod: '支払方法：', rate: '為替レート（USD / CNY）：', amt: 'CNY:' },
      formValidation: { cnyAmt: 'CNY金額は{limit}を超えることはできません' },
    },
    crypto: {
      header: '暗号通貨預金',
      choose: 'ご希望の暗号通貨を選択してください',
      proceedQuestion: '<b>{method}</b> のデポジットを続行しますか？',
      note:
        '当サイトがBUSDTを介して出入金をできないということにご注意ください。アドレスと仮想通貨が当サイトの取り扱っているチェーンと通貨に一致していることをお確かめください。一致していない場合、その資金が失われることになります。',
      note2:
        'バイナンスUSドルやイーサリアムでの入出金はできませんので、アドレスと仮想通貨が、当社が取り扱うチェーンと通貨に一致していることを確認してください。それらが一致しない場合、資金を失う可能性があります。当社は、エラーによって生じたいかなる損害に対しての責任を負わないものとします。',
      inst2: '2.次に、{method}のウォレットアドレスを表示する新しいウィンドウが表示されます',
      inst3:
        '3.{method}のウォレットアドレスをコピーしてから、{method}ウォレットにログインし、希望する金額を{method}アドレスに転送します。',
      inst4: '注意：{method}の入金は、取引時に提供された為替レートでB2BinPayによって処理されます。',
      bitcoin: {
        desc: 'BITCOINでアカウントに資金を入金する方法',
      },
      usdt: {
        desc: 'USDT(OMNI)でアカウントに資金を入金する方法',
        inst1: '1.下のフォームに記入して‘提出する’をクリックしてください',
        inst2: '2.次に、USDT(OMNI)のウォレットアドレスを表示する新しいウィンドウが表示されます',
        inst3:
          '3.USDT(OMNI)のウォレットアドレスをコピーしてから、USDT(OMNI)ウォレットにログインし、希望する金額をUSDT(OMNI)アドレスに転送します。',
        inst4: '注意：USDT(OMNI)の入金は、取引時に提供された為替レートでB2BinPayによって処理されます。',
      },
      usdt_CPS: {
        desc: 'USDT({name})でアカウントに資金を入金する方法',
      },
      form: {
        header_bitcoin: 'BITCOINフォーム',
        header_usdt: 'USDT(OMNI)フォーム',
        header_usdt_CPS: 'USDT({name})フォーム',
      },
    },
    skrill: {
      header: 'SKRILL / MONEYBOOKERS入金',
      desc: 'Skrillでアカウントに入金する方法',
      note1: 'お支払いはUSD、EUR、GBP、CADで承ります。',
      note2: '口座への入金は、MT4取引口座と同じ通貨でのみ可能です。',
      note3:
        '不正確な電子メールが使用された場合、Skrillは自動的にお客様の口座の基本通貨に資金を変換します。手数料がかかる場合があります。',
      note4: 'ご入金は、PU Prime口座と同じ名義である必要があります。',
      form: { header: 'SKRILLフォーム', email: 'Skrill / Moneybookers Eメール' },
      reminder: `<li>注意：</li>
        <li>*口座への入金は、MT4取引口座と同じ通貨でのみ可能です。間違ったEメールが使用された場合、Skrillは自動的に資金をアカウントの基本通貨に変換します。手数料がかかる場合があります。</li>
        <li>*入金は{platform}アカウントと同じ名前である必要があります。</li>`,
    },
    sticPay: {
      header: 'STICPAY入金',
      desc: 'SticPayでアカウントに入金する方法',
      inst1: '1. 下のフォームに記入して‘提出する’をクリックしてください',
      inst2: '2. SticPayアカウントにログインします',
      inst3: '3. SticPayアカウントのプロンプトに従って、支払いを完了します',
      form: { header: 'SticPayフォーム', email: 'SticPay Eメール' },
    },
    unionPay: {
      header: 'UNION PAY入金',
      desc: 'UnionPayを使用してアカウントに資金を入金する方法',
      inst1: '1.下のフォームに記入して‘提出する’をクリックしてください',
      inst2: '2.銀行選択ページで銀行を選択し、オンライン銀行にログインします',
      inst3: '3.指示に従って、オンライン/モバイル銀行から支払いを行います',
      form: { header: 'UNION PAYフォーム' },
    },
    unionPayCN: {
      header: '中国Union Pay送金',
      desc: '中国Union Pay送金でアカウントに資金を入金する方法',
      inst1: 'お支払いの詳細ページが表示されます。',
      inst2: '入金時に会員番号の入力を求められることがありますが、会員番号には取引口座の番号を入力してください。',
      note1: '一回あたりの最低取引額は500 CNY、一回あたりの最高取引額は49,999 CNYです。',
      note2: 'なお、入金限度額は市場動向により随時更新される可能性があります。',
      note3: '送金先銀行情報は随時更新されますので、表示されていても写し取らないでください。',
      form: {
        header: '中国UNION PAYフォーム',
        holderName: 'UnionPayカード所有者名：',
        holderNameInfo: '上に入力する名前は、銀行のカード所有者の名前と同じであることを確認してください',
      },
    },
    paypal: {
      header: 'PayPal',
      desc: 'PayPalでアカウントに資金を入金する方法',
      inst1: '1.下のフォームに記入して ‘提出する’をクリックしてください',
      inst2: '2. PayPalアカウントにログインします',
      inst3: '3. プロンプトに従って、PayPalアカウントで支払いを完了します',
      inst4: '注意：第三者からの支払いは受け付けていません',
      form: { header: 'PAYPALlフォーム' },
    },
    cardpay: {
      desc: 'クレジットカードでアカウントに資金を入金する方法',
      inst1: '1.下のフォームに記入して‘提出する’をクリックしてください',
      inst2: '2.次に、カードの詳細を入力するための別のフォームにリダイレクトされます',
      inst3: '3.カードの詳細を入力したら、‘提出する’を押します',
      inst4: '4.クライアントポータルにリダイレクトされ、トランザクションが完了します',
      form: { header: 'クレジットカードフォーム' },
    },
    tinkbit: { inst5: '5. デポジットごとに最大$ 1500 USD' },
    perfectMoney: {
      inst1: '2.   Perfect Moneyアカウントにログインします',
      inst2: '3.   Perfect Moneyアカウントのプロンプトに従って、支払いを完了します',
    },
    indiaBankTransfer: {
      desc1: '送金が完了しましたら、以下のフォームに必要事項を記入して銀行送金の領収書のコピーとともにお送りください。',
      desc2: 'トランザクションを実行できるよう、お客様の氏名、口座番号、送金額がはっきりと写るようにしてください。',
      bussDayInfo:
        'お客様の口座に返金処理が行われるまで1～6時間を要する可能性がございますので、ご理解のほどよろしくお願いいたします。',
    },
  },
  withdraw: {
    title: '資金を出金する',
    default: {
      accountDetail: '口座情報',
      withdrawalAmount: '出金額',
      example: 'Credit / debitカードの例',
      alert:
        'AML法により、出金に使用する方法は、入金に使用した方法と同じでなければなりません。引き出し金額がアカウントの基本通貨の100単位未満の場合、銀行から手数料として20単位が請求されます。',
      how: 'どのようにあなたの取引口座に資金を入金しましたか？',
      success: '出金リクエストは成功しました。まもなく処理されます',
      selectCC: 'クレジットカードを選択',
      selectBA: '銀行口座を選択',
      anotherCC: 'クレジットカードを手動で入力してください',
      anotherBA: '銀行口座を追加',
      remember: '自分のアカウントを記憶',
      ccFail:
        'アカウントのセキュリティを保護するため、預金取引の最大試行回数に達し、一時的に引き出しが一時停止されました。{email}までご連絡ください。',
      checkPosFailed: 'ポジションの確認に失敗しました',
      fetchTradeAccFailed: '取引口座の取得に失敗しました',
      chooseAnother: `<p>このカードの最大引き出し額：0</p> 
      <p>別のカードまたは他の支払い方法を選択してください</p>`,
      notEnoughFunds: '入力された金額はこのカードの限度額を超過しています',
      failed: '引き出しに失敗しました。しばらくしてからもう一度やり直してください',
      unfortunate:
        '残念ながら、クレジットカードへの出金を処理することはできません。今後、出金は銀行振込で指定された銀行口座で処理されます。',
      noCardWarn: '出金に使用できるカードがありません。最初に出金の詳細でカードをリンクしてください。',
      equityStop:
        '残念ながら、お客様の出金は処理できません。アカウントの利用可能なマージンが100％のストップアウトレベルを下回る結果となるためです。',
      equityMargin:
        '残念ながら、お客様のアカウントは完全に制限されているため、現在、お引き出しを処理することができません。',
      equityContinue:
        '現在オープンのポジションがあることを検出しました。出金申請を提出した後にストップアウトになる可能性があります。確認してください',
      disclaimer: `<li>ポジションを開くときに撤退すると、マージン率に影響します。アカウントをショートポジションに保つか、マージンコールを回避するためにアカウントに十分な資金があることを確認してください。</li>
      <li>{platform}はいかなるサードパーティーへの支払も受け付けていません。取引口座から出金されるすべての資金は、名義が{platform}のクライアントポータルの口座と同じでなければなりません。</li>
      <li>初めて行われるクレジットカードや、デビットカード、イーウォレット、moneybookers、および銀行振込を用いた出金リクエストは、口座の所有者と銀行の情報が記載されている銀行利用明細を提供することによって、安全性とセキュリティを認証しなければなりません。</li>
      <li>パシフィックユニオンが、引き出し要求の処理後に当座預金口座がマイナスの残高になる可能性があると信じる合理的な理由がある場合、パシフィックユニオンは引き出し要求の処理を拒否する権利を留保します。</li>
      <li>取引口座が返金またはその他の進行中の調査の対象となる場合、PacificUnionは引き出し要求を拒否する権利を留保します。</li>
      <li>{platform}は、受け取り側の金融機関またはサードパーティの支払いプロバイダーが請求する可能性のある料金については責任を負いません。</li>`,
      YourPaymentGreaterThanAvailableBalance: 'お支払いが利用可能残高を超えています',
      blackList: `申し訳ありませんが現在お引き出しのリクエストを送信できません。さらなる情報は{supportEmail}へお問い合わせください。`,
      NetellerChargeAlert:
        'Neteller の引き出しは 1 回の取引につき 10,000 ドルに制限され、2% の手数料 (上限は 30 ドル) が適用されます。 ',
      agreePolicy: '私は{link}を読み、これに同意しました',
      withdrawPolicy: '出金ポリシー',
      addWithdrawalMethod: '出金方法を追加',
      addSecondaryMethod: '予備の方法を追加',
      withdrawNote:
        '決済にはクレジットカードが優先的に使用されます。クレジットカードの限度額に達してしまった場合は、出金方法を完了するため、別の方法をお伺いします。',
      submitYourWithdrawal: '出金リクエストを送信',
      remainingWithdraw: '出金額の残額は別のクレジットカードまたは出金方法に分割して処理されます。',
      onlyWithdrawalCcDesc: `合計が{ccAmount}のクレジットカードのみが全額引き落とされます。`,
    },
    fasa: {
      field: { accName: 'FasaPayアカウント名', accNum: 'FasaPayアカウント番号' },
      formValidation: { accNameReq: 'FasaPayアカウント名は必須です', accNumReq: 'FasaPayアカウント番号が必要です' },
      fasaPayChargeAlert: 'FasaPayは、引き出しごとに0.5％の取引手数料を請求します。',
    },
    neteller: { field: { email: 'Neteller Eメール' }, formValidation: { emailReq: 'Neteller Eメールが必須です' } },
    vload: {
      field: {
        email: 'Vloadに登録したメールアドレス',
      },
      formValidation: {
        emailReq: 'Vloadに登録したメールアドレスが必要です',
      },
    },
    bitcoin: {
      field: { address: 'Bitcoinウォレットアドレス' },
      formValidation: {
        addressReq: 'Bitcoinンウォレットアドレスが必須です',
        addressFormat: `あなたのウォレットアドレスは「1」または「3」、「bc1」で始まります。`,
        addressLength: 'ビットコインアドレスは２６～42文字の英数字からなっておりますので、もう一度お試しください。',
      },
    },
    eth: {
      field: { address: 'ETHウォレットアドレス' },
      formValidation: {
        addressReq: 'ETHンウォレットアドレスが必須です',
        addressFormat: 'ETHンウォレットアドレスが必須です',
        addressLength: 'ビットコインアドレスは２６～42文字の英数字からなっておりますので、もう一度お試しください。',
      },
    },
    usdc: {
      field: { address: 'USDCウォレットアドレス' },
      formValidation: {
        addressReq: 'USDCンウォレットアドレスが必須です',
        addressFormat: 'USDCンウォレットアドレスが必須です',
        addressLength: 'ビットコインアドレスは２６～42文字の英数字からなっておりますので、もう一度お試しください。',
      },
    },
    usdt: {
      field: { address: 'USDTウォレットアドレス' },
      formValidation: {
        addressReq: 'USDTンウォレットアドレスが必須です',
        trc20WalletFormatIncorrect: `あなたのウォレットアドレスは「T」で始まります。`,
        erc20WalletFormatIncorrect: `あなたのウォレットアドレスは「0x」で始まります。`,
      },
    },
    paypal: { field: { email: 'PayPal Eメール' }, formValidation: { emailReq: 'PayPal Eメールが必須です' } },
    skrill: {
      field: { email: 'Skrill Eメール' },
      formValidation: { emailReq: 'Skrill Eメールが必須です' },
      skrillChargeAlert: 'Skrillは、引き出しごとに1％の取引手数料を請求します。',
    },
    bitwallet: { field: { email: 'Bitwallet Eメール' }, formValidation: { emailReq: 'Bitwallet Eメールが必須です' } },
    astropay: {
      field: {
        accountNumber: 'アストロペイ口座',
      },
      formValidation: {
        accountNumberReq: 'アストロペイ口座が必要です。',
      },
    },
    sticpay: {
      field: {
        email: 'SticPay Eメール',
      },
      formValidation: {
        emailReq: 'SticPay Eメールが必須です',
      },
    },
    perfectMoney: {
      field: { email: 'Perfect Money Eメール' },
      formValidation: { emailReq: 'Perfect Money Eメールが必須です' },
    },
    transfer: {
      info:
        '{platform}は、<u>月に1回の出金</u>にかかる銀行手数料を払い戻します。 その月の追加の出金には、出金通貨の20単位の銀行手数料がかかります。',
      label: { upload: '銀行の明細書のコピー（過去3か月まで）をアップロードしてください' },
      formValidation: {
        upload: '銀行取引明細書をアップロードしてください',
        emailReq: 'Eメールアドレスは必須です',
      },
    },
    otherMethod: {
      withdrawalMethod: '引出し方法',
      manageWithdrawalDetails: '出金情報を管理',
      verificationCode:
        '認証コードが{email}へ送信されました。さらにサポートが必要な場合は、{supportEmail}へご連絡ください',
    },
  },
  transfer: {
    creditWarn:
      'アカウントにプロモーショナルクレジットが含まれているため、選択したアカウントからの送金を処理できません。',
    blackListWarn:
      'MAMアカウントから資金を送金すると、管理ポジションが清算される場合があります。この口座から資金を送金したい場合は、{mailTo}までご連絡ください。',
  },
  changePw: {
    header: 'パスワードを変更する',
    field: { currPw: '現在のパスワード', newPw: '新しいパスワード', confirmPw: '新しいパスワードを確認' },
    succ: 'パスワードが更新されました。これで、新しいパスワードを使用してクライアントポータルにアクセスできます。',
  },
  promotion: {
    clickToGet: 'ワンクリックで$ 50のゼロデポジットボーナスを獲得',
    apply: '今すぐ申し込む',
    start: '今すぐスタート！',
    tnc: `
    <li> 1.本キャンペーンは30日間有効です。</li>
    <li> 2.このアクティビティは、実際のアカウント（既存または登録済み）を持っているが、これまでPU Primeに資金を入金したことがないすべての顧客に適用されます。</li>
    <li> 3.このアクティビティは、フランス、イギリス、台湾、イタリア、カナダ、ベルギー、スイス、香港、ナイジェリア、オランダ、日本、ルクセンブルグ、マルタ、再会、ドイツ、マルティニーク、スペイン、ラトビア、ポーランド、コートジボワール、デンマーク、ブラジル、ニュージーランド 、ポルトガル、人の島、南アフリカ、トルコ、ルーマニア、グアドループ、オーストリア、アイルランド、フランスのギアナ、スワジランド、アルゼンチン、ウクライナ、ギリシャ、スウェーデン、モナコ、ハンガリー、ハイチ、フィンランド、ボリビア、チリ、コスタリカ、クロアチア、 チェコ共和国、エストニア、メキシコ、ノルウェー、フィリピン。</li>
    <li> 4.このアクティビティは、標準アカウントとイスラム標準アカウントにのみ適用されます。</li>
    <li> 5.同じIPアドレス/ ID /パスポートをお持ちのお客様は、ボーナスの申請は1回のみ可能です。顧客がPU Primeに持っている取引アカウントの数に関係なく、プロモーションは1つのアカウントにのみ適用されます。</li>
    <li> 6.このアクティビティへの参加の申し込みに成功すると、顧客は50米ドル（または同等の通貨）のクレジットを受け取り、デポジットの要件はありません。</li>
    <li> 7.ボーナスを引き出すことはできません。ただし、ボーナスで取引することで得られる利益の一部を引き出すことができます。</li>
    <li> 8.引き出しを申請する前に、すべてのオープンポジションを閉じる必要があります。引き出しを選択すると、ボーナスはアカウントから削除されます。</li>
    <li> 9. PU Primeは、いつでも条件を変更したり、ボーナスの発行を拒否したりする権利を留保します。</li>`,
    selectDesp: '※「ゼロデポジットボーナス」キャンペーンに参加したいアカウントをお選びください。',
    descp1: '自分で資金を預けずに取引体験をしてみませんか？',
    descp2: `50ドルのゼロデポジットボーナスを申請し、<br>投資リスクを負わずに利益を上げようとします`,
    header: 'プロモーションの利用規約',
    tnc2:
      '<li> 最小引き出し額は100米ドル（または同等の通貨）であり、最大引き出し額は200米ドル（または同等の通貨）です。 200米ドル（または同等の通貨）を超える残高は、申請後にクレジットと同時に差し引かれます。</li>',
    successful: '正常に送信されました',
    failed: '送信に失敗しました',
    referFriends: {
      intro: `次の3つの手順を実行して、{amount}を取得します`,
      step1: '最初の一歩',
      step1Descp: 'アカウントの開設に成功しました',
      step2: '第二段階',
      step2Descp: '入金成功',
      step3: '3番目のステップ',
      step3Descp: '取引量',
      totalEanringTitle: `総引き出し額 <br/>（USDまたは同等の通貨）`,
      totalEarningTip: '*報酬額は審査の対象となります。',
      redeem: 'エキス',
      intro2: '同時に、友達を推薦することもできます',
      shareLink: 'パーソナライズされた推奨リンクを共有する',
      shareLinkDescp: '「共有」をクリックして、独自の推奨リンクを取得し、さまざまなソーシャルメディアにコピーします',
      share: '共有',
      total: '償還の推奨総額',
      social: '主要なソーシャルメディアを通じてリンクを共有する',
      clients: 'クライアント',
      table: {
        referralsNo: '推奨数',
        name: '名前',
        country: '国',
        openAccount: 'アカウントの開設に成功しました',
        despositSuccess: '入金成功',
        volumeCompleted: '取引量',
      },
      totalRedeemableTitle: `引き出し総額 <br/>(米ドルまたは他の同等の通貨)`,
      totalRedeemableTip: '*報酬額は審査の対象となります。',
      referrer: 'リファラー',
      referral: '審判',
      dialog: {
        confirmQ: '収益を利用する',
        confirmQ2: '基本通貨に換算された合計金額は{amount} {currency}です。',
        redeem: '今すぐ撤回',
        agree: '* [今すぐ利用]をクリックすると、すべての{link}に同意したことになります。',
        overBonus:
          '私たちのシステムは、あなたが私たちの最大累積制限に達したことを検出しました。 詳細については、カスタマーサービス担当者にお問い合わせください。',
      },
    },
    depositBonus: {
      banner: '50%入金ボーナスをキャンペーン！<br>最大10,000ドルまで獲得！',
      depositBonus: 'ボーナスをもらって取引をより有利に',
      title:
        '証拠金を増やして取引数量を増やしてみたいですか？ それなら、今すぐ口座に入金して、50％のボーナスをもらいましょう！',
      fund: '口座に入金',
      tnc: '*利用規約{link}',
      link: 'を確認する',
      bonusHistory: '入金履歴',
      overBonus:
        '* 最大${totalLimit}ドルのボーナスクレジットを獲得しました。追加入金はボーナスクレジットの対象外となります。',
      opt: 'オプトインまたはオプトアウト',
      tick:
        'オプトアウトする場合はチェックボックスにチェックせず、オプトインする場合はチェックボックスにチェックにしてください。',
      saveChange: '変更内容を保存',
      depositDate: '入金日',
      credit: 'クレジット',
      optIn: '正常に参加できました',
      optOut: 'オプトアウトに成功',
      optInDescription: '下のボタンをクリックして、50％の入金ボーナスに登録してください。',
      optOutDescription: 'ボックスをクリックして、プロモーションに参加しません',
      activate: '有効化',
      signOut: 'サインアウト',
      banner1: 'あなただけの50％入金ボーナスを獲得しよう！',
      claimDescription: `<p>参加手順</p>
      <li>1.入金する前にプロモーションを有効化してください。 </li>
      <li>2.取引口座に入金する。 </li>
      <li>3.入金後ボーナスは自動的に付与されます。</li>
      <li>4.取引開始。</li>`,
      eligibleDescription: `<p>参加条件</p>
      <li>1.入金する前に、クライアントポータルで本プロモーションの有効化をしてください。 </li>
      <li>2.ボーナスは、スタンダード口座、イスラムスタンダード口座、Prime口座、イスラムPrime口座でのみご利用可能。 </li>
      <li>3.50％ボーナスは初回入金のみ対象、2回目以降はすべて20％のボーナスが付与されます。 </li>`,
      optInDialog: `おめでとうございます！50%入金ボーナスの有効化が完了しました。`,
      optOutDialog: `50%入金ボーナスの登録解除が完了しました。`,
    },
    cryptoCashback: {
      banner: {
        bannerContext: `
          <span class="title">貯金を2倍にして</span></br>
          <span class="desc">最高$10,000のキャッシュバックとスワップフィー無料の特典をゲットしよう</span>
        `,
        activityBannerContext: `
          <span class="title">2 倍の節約</span></br>
          <span class="desc">下降トレンドを短縮するか、ディップを購入しますか?</span></br>
          <span class="desc">PU Prime で暗号通貨を取引すると、最大 $10,000 のキャッシュバックを獲得できます!</span>
        `,
      },
      active: {
        title: '仮想通貨キャッシュバックを有効化',
        desc: 'この活動を有効化して参加するには、以下のボタンをクリックしてください',
      },
      deactive: {
        title: '仮想通貨キャッシュバックを無効化',
        desc: '仮想通貨キャッシュバックプロモーションを無効化して出金するには、以下のボタンをクリックしてください。',
      },
      button: {
        active: '有効化',
        deactive: '無効化',
        submit: '提出',
        fund: '口座に入金してください',
      },
      claimTitle: '請求方法',
      claimDescriptions: [
        '入金をする前にこのプロモーションを有効化してください。',
        '入金要件を満たす金額(≥ 500 USD)を取引口座に入金してください',
        'ロットあたりのキャッシュバックは入金した総額によって異なります(利用規約を参照)',
        '取引をお試しください。すべての取引から更に多くの価値を手に入れることができます！',
      ],
      eligibilityTitle: '資格',
      eligibleDescriptions: [
        'このプロモーションは入金をする前に有効化しなければなりません。',
        'このプロモーションはスタンダード口座とイスラム口座でしかご利用いただけません。対象となる取引口座を複数所有している場合でも、このプロモーションに参加するために選択できるのは1つの口座だけです',
        'このプロモーションの期間は、有効化を行った後に初めての入金をした時点から30日間です',
        'キャッシュバックは、ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAUを除く、すべての仮想通貨製品を取引したボリュームに基づきます',
        'キャッシュバックの総額の上限は、クライアント様1人あたり$10,000です',
      ],
      tnc: '*利用規約{link}',
      link: 'を確認する',
      message: {
        congratulate: 'おめでとうございます！',
        congratulateContext: '仮想通貨キャッシュバックのプロモーションの有効化が完了しました。',
        signedOutPromo: `確認ボタンをクリックすることによって、あなたはこのプロモーションからサインアウトすることになります。`,
        deactivePromo: `仮想通貨キャッシュバックのプロモーションの無効化が完了しました。`,
      },
      dialog: {
        selectAccountContext: `取引ボーナス活動に参加するために利用したい口座を選択してください`,
        tnc: '利用規約',
        tncContext: {
          tnc1: `1．このプロモーションを有効化した後に、対応するキャッシュバックを受け取るには、資金の入金と仮想通貨の取引を行う必要があります。`,
          tnc1desc: `*このプロモーションの期間内になされた新規の入金のみが対象となります。これには内部送金や、友人紹介ボーナス、およびキャッシュ調整は含まれません。`,
          tnc2: `2．ここで規定される基準を満たした、対象となるクライアント(新規および既存のクライアント様) は、以下の金額に等しいキャッシュバックを受け取ることができます：`,
          tnc3: `3．対象となる製品：ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAUを除く、すべての仮想通貨製品。`,
          tnc4: `4．有効な取引ボリューム：このプロモーションの期間内に新たにオープンまたはクローズされた注文。オープン時間が5分未満のポジションと、ロックされたポジション、およびヘッジされたポジションは、このプロモーションの対象とはなりません。`,
          tnc5: `5．プロモーション期間：最初の入金が完了してから30日間。`,
          tnc5desc: `*このプロモーションを有効化しても入金を行わなかった場合、このプロモーションは30日後に自動的に終了します。`,
          tnc6: `6．このオファーは他のオファーと併用することはできません。(「あなたもトレーダーになれますキャンペーン」と、友人紹介、および50%入金ボーナスを除く)`,
        },
        agreeTnc: `私は利用規約を読み同意します`,
      },
      field: {
        netDeposit: `純入金`,
        usddesc: `USD(または他通貨の相当額)`,
        cashback: `キャッシュバック`,
        standardLot: `標準ロット`,
        date: `日付`,
        depositAmount: `入金額`,
        withdrawAmount: `出金額`,
        netDepositAmount: `純入金額`,
        rebateLot: `リベート/ロット`,
        tradingDate: `取引日`,
        tradingLot: `取引ロット`,
        cashRebate: `キャッシュリベート`,
      },
      depositHistory: `入金履歴`,
      tradingRewardHistory: `取引報酬履歴`,
    },
    xmasMakeAWish: {
      banner: {
        bannerContext: `
          <div class="title">最大5,000ドルの賞品を獲得しましょう!</div>
          <div class="desc">勝てること間違いなしの抽選に参加しましょう。</div>
        `,
      },
      active: {
        title: 'ウィッシュプロモーションを有効にする',
        desc: '下のボタンをクリックして、 クリスマス・メイク・ア・ウィッシュ・プロモーションを有効にしてください。',
      },
      deactive: {
        title: 'クリスマス・メイク・ア・ウィッシュ・プロモーション',
        desc: '下のボタンをクリックして、クリスマス・メイク・ア・ウィッシュ・プロモーションを有効にしてください。',
      },
      button: {
        active: 'アクティベートする',
        deactive: 'ディアクティベート',
        submit: '送信',
        fund: '口座に入金する',
        enterLuckyDraw: 'ラッキードローに参加',
        redeem: '取得する',
        redeemed: '取得されました',
      },
      claimTitle: 'キャッシュバックの受け取り方',
      claimDescriptions: [
        '入金前にキャンペーンをアクティベートしましょう。​',
        'あなたが望むギフトの入金要件を満たすように取引口座に入金し、必要な取引回数を行います。(下記のギフト表を参照)',
        '資格を得ると、対応するティアのギフトの1つを獲得するチャンスが得られます。(下記のギフト表を参照)。',
        'ラッキー ドローに参加 ボタンをクリックして、ギフトの抽選に参加します。',
        '獲得したギフトは記録され、すべてのギフトはプロモーション終了後7日後に配布されます。',
      ],
      eligibilityTitle: '参加条件',
      eligibleDescriptions: [
        '対象となる口座の種類：このオファーは、MT4/MT5のスタンダード口座とイスラム・スタンダード口座にのみ適用されます。',
        '複数のスタンダード口座をお持ちのお客様は、このキャンペーンに参加する口座を1つだけご選択ください。',
        '対象国：中国本土を除くすべて',
        '入金と取引は、有効とみなされるためにプロモーション期間中に行われなければなりません。',
        '有効な取引量:外国為替、金、銀、原油のポジションは5分以上保持される必要があります。',
      ],
      moreDetail: '賞品の配布の詳細については、 {prize}。',
      prize: 'こちらの賞品リストをご覧ください',
      tnc: '*利用規約が適用されます。{link} をクリックして表示 ',
      link: 'ここ',
      luckyDraw: {
        description: [
          `ラッキードローボタンを押して、上記の賞品の1つ <span class="font-bold">を獲得し</span>てください!`,
          'あなたが探している贈り物ではありませんか? 入金と取引を続けて、あなたが望むティアの製品を当てましょう。',
        ],
        mainTitle: '現在のギフトティア:',
        commingSoon: {
          title: `願い事をしましょう`,
          content: `もうすぐ11月の半ばに抽選に参加できるようになります。`,
        },
        noChanceToDraw: {
          title: `今すぐ入金`,
          content: `ラッキー抽選に参加するには口座に$200以上を入金してください`,
        },
        endPromotion: {
          title: `ラッキー抽選が終了しました`,
          content: `ご参加いただきありがとうございました！2023年3月31日までにギフトを引き換えるようにしてください。`,
        },
      },
      currentDepositLots: `現在の入金 / 取引ロット`,
      transactionHistory: `トランザクション履歴`,
      transactionHistoryDesc: `*デポジット取引は、ラッキードローに入ることからデポジットされた金額とデポジット値です。`,
      giftRedemption: `ギフトの引き換え`,
      giftRedemptionDesc: [
        `物理的な贈り物の償還について再度ご連絡いたします。`,
        `*すべてのプライズは2023年2月2日から2023年3月2日までしか引き換えることはできません。`,
      ],
      field: {
        depositAmount: `入金金額`,
        lotsTraded: `取引されたロット`,
        date: `日付`,
        type: `種類`,
        deposit: `*入金`,
        tradingLots: `取引ロット`,
        prizeWon: `受賞歴`,
        creditBonus: `クレジットボーナス`,
        cashVoucher: `金券`,
        physicalRewards: `物理的な報酬`,
        depositTradingVolume: `入金/取引ロット`,
        gifts: `ギフト(米ドル)`,
        accountID: `アカウント ID`,
        current: `現在の`,
        luckyDrawDeposit: `入金`,
      },
      dialog: {
        selectAccountContext: `クレジットボーナスを受け取りたい口座を選択してください(MT4/MT5口座のみ)`,
        tnc: '利用規約',
        tncContext: {
          tnc1: `1. お客様は、まずクライアントポータル内でこのキャンペーンを正常に有効化してから、ギフトを受け取るために必要に応じて資金を入金して取引する必要があります。`,
          tnc2: `2.	有効な取引ボリューム通貨ペア、金、銀、原油スポット、原油先物の標準的なロット。注文の開始と終了は、キャンペーン期間中に行う必要があります。5分未満の未決済ポジション、ロックポジション、ヘッジポジションは、本キャンペーンの有効取引とはみなされません。`,
          tnc3: `3.	統計と注文量はMT4時間01/11/2022-31/01/2023から抽出されたもの出なくてはなりません。`,
          tnc4: `4.	商品が棚から出たり在庫切れになったりするなどの不可抗力によりギフトを購入できない場合、お客様はギフトの市場価格に相当する現金の受け取りを申請することができます。この価格は、米ドル価格(または同等の通貨)に基づいています。`,
          tnc5: `5.	このオファーは、他のオファーと組み合わせて使用することはできません。(100取引ボーナス、20%独占ボーナス、50%入金ボーナス、VN 20%入金ボーナス)。`,
        },
        agreeTnc: `私は、利用規約を読み、同意します。`,
        prizeList: `こちらの賞品リストをご覧ください`,
        luckyDrawTransaction: `ラッキードロー取引`,
        xmasGift: `クリスマスプレゼントおめでとうございます!`,
      },
      message: {
        congratulate: 'おめでとうございます！',
        congratulateContext: `クリスマスキャンペーンの有効化に成功しました。メイク・ア・ウィッシュ・プロモーションに参加しましょう`,
        signedOutPromo: `確認ボタンをクリックすると、キャンペーンからサインアウトされます！`,
        deactivePromo: `あなたはクリスマス・メイク・ア・ウィッシュ・プロモーションから正常にオプトアウトしました。`,
        redeemAmount: `金額はあなたのアカウントに入金されます!`,
      },
      tableContent: {
        luckyDraw: `ラッキードローに参加`,
        depositTrade: `デポジット/トレード`,
      },
    },
    firstTimeDeposit: {
      banner: {
        bannerContext: `
          <div class="title">初回入金時に<span class='bg_yellow'>現金</span>を獲得</div>
          <div class="desc">PU Primeで初回入金時に最大50米ドルの現金を獲得しましょう!</div>
        `,
      },
      active: {
        title: `FTDキャッシュバックをアクティベートしましょう`,
        desc: `以下のボタンをクリックし、アクティベートしてプロモーションに参加しましょう。`,
      },
      deactive: {
        title: `FTDキャッシュバックを無効にする`,
        desc: `以下のボタンをクリックして、への参加を取り下げることができます FTDキャッシュバック・プロモーション`,
      },
      button: {
        active: `アクティベートする`,
        deactive: `無効にする`,
        fund: `口座に入金する`,
        redeem: `バウチャーを引き換える`,
      },
      claimTitle: `キャッシュバックの受け取り方`,
      claimDescriptions: [
        `入金前にプロモーションをアクティベートしましょう。`,
        `キャッシュバックの入金条件を満たすように取引口座に入金しましょう。（200米ドルまたは同等価格の他通貨）。`,
        `キャッシュバック・バウチャーの金額は、初回入金額によって異なります（利用規約のキャッシュバック表をご参照ください）。`,
        `キャッシュバック・バウチャーをアクティベートするには、FX、金、銀、原油のいずれかを2ロット以上取引してください。`,
        `アクティベートされたバウチャーは、いつからでもご利用していただくことができます。この金額は、お客様の口座残高に反映されます。`,
      ],
      eligibilityTitle: `参加条件`,
      eligibleDescription1: `利用可能な口座タイプ：このオファーはMT4/MT5のスタンダード口座とイスラム標準口座に対して有効です。`,
      eligibleDescription2: `お客様が複数のスタンダード口座をお持ちの場合、このプロモーションに参加する一つのアカウントを選択してください。`,
      eligibleDescription3: `利用可能な国：中国本土を除くすべての国でご利用可能です。`,
      eligibleDescription4: `利用可能なお客様：IB/CPAを含む、入金履歴のない、新規または既存のお客様がご利用いただけます。`,
      eligibleDescription4Desc: `*IB/CPA個人取引口座はご利用いただけません。`,
      eligibleDescription5: `初回入金はプロモーション期間中に行われる必要があります。`,
      eligibleDescription6: `注文後5分以内に決済が行われた場合、プロモーションの対象とはなりません。`,
      tnc: `※利用規約が適用となります、ご覧いただくには{link}してください`,
      link: `こちらをクリック`,
      dialog: {
        selectAccountContext: `取引ボーナス活動に参加するために利用したい口座を選択してください`,
        tnc: `利用規約`,
        tncContext: {
          tnc1: `1. キャッシュバックを受け取るためには、このプロモーションをアクティベートした後、必要な資金入金と2標準ロットを取引する必要があります。`,
          tnc1desc: `*プロモーション期間中の新規入金のみ、プロモーション参加に必要な入金額の対象になります。内部振替、友人紹介ボーナス、キャッシュの利用は対象外となります。`,
          tnc2: `2.	プロモーション期間中に行われた初回入金のみ、キャッシュバックに必要な入金額としてカウントされます。`,
          tnc3: `3.	こちらに記載された基準を満たすお客様（新規および既存）は、以下の通り、キャッシュバックを受け取ることができます。：`,
          tnc4: `4.	対象商品：FX、金、銀、原油`,
          tnc5: `5.	有効な取引ボリューム：プロモーション期間中の新規・決済注文がカウントされます。5分未満の未決済ポジション、ポジションロック、ポジションをヘッジすることは、本プロモーションの有効取引とはみなされません。`,
        },
        agreeTnc: `私は、利用規約を読み、同意します。`,
        grossDeposit: `合計入金金額に対する`,
        grossDepositDesc: `または同等の別通貨`,
        cbVoucher: `キャッシュバック・バウチャー（米ドル）`,
        tableDesc: `お客様は、最大50ドルまたは相当額のキャッシュバウチャーを取得することができます。`,
      },
      voucher: {
        title: `FTD キャッシュバック`,
        accountID: `アカウント ID`,
        accumulateVolume: `取引量を貯める`,
        standardLots: `標準ロット`,
        desc: `2ロット以上のFX、金、銀、原油を取引し、アクティベートしましょう。`,
        clickForRedeem: `下のボタンをクリックして、あなたの口座残高に送りましょう。`,
        ftdLessThan200: `初回入金額が最低必要額を下回っています。`,
      },
      message: {
        congratulate: `おめでとうございます！`,
        congratulateContext: `アクティベートに成功しました。FTDキャッシュバックキャンペーン`,
        signedOutPromo: `確認ボタンをクリックすると、プロモーションからサインアウトされます！`,
        deactivePromo: `FTDキャッシュバック・プロモーション の参加取り下げが完了しました。`,
        successRedeem: `FTDキャッシュバックを正常に取得しました。`,
      },
    },
    notification: {
      ndb: {
        inst1: '大切なお客様へ',
        inst2: `口座開設ボーナスキャンペーンは<span>{date} (GMT+3)</span>に終了しますので、お知らせ致します。`,
        inst3:
          'キャンペーン終了後は、口座に付与されたクレジットは消えますのでご注意ください。また、キャンペーン期間中に入金や取引履歴がない場合は、キャンペーン終了後クレジットと口座残高もリセットされます。',
        inst4: `ご質問などございましたら、大変お手数ですが、チャット、メール: <span>{mail}</span>、または電話: <span>{phone}</span>でサポートまでご連絡ください。どうぞ今後とも宜しくお願い申し上げます。`,
        cantInerTransfer: 'NDBキャンペーンに参加しているため、転送リクエストを処理できません',
      },
      db: {
        inst1: '大切なお客様へ',
        inst2: `50％デポジットボーナスイベントは<span>{date} (GMT+3)</span>に終了することに注意してください。`,
        inst3:
          '本キャンペーンの規約に基づき、プロモーション終了後、入金歴のある口座については、付与されたクレジットが自動的に削除されます。入金歴や取引履歴のない口座について、キャンペーン終了後にクレジットと残高が自動的に削除されますので、ご注意ください。入金歴がない場合はキャンペーン終了前に収益分を出金してください。        ',
        inst4: `ご質問やサポートについて、ライブチャット Eメール: <span>{mail}</span> 電話 <span>{phone}</span> サポートチームまでご連絡ください。 `,
      },
    },
    editSuccess: '',
  },
  downloads: {
    mt4: 'METATRADER 4',
    mt4Desc: 'PC、Webブラウザー、スマートフォン、タブレット用のMetaTrader 4をダウンロード',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android/Tablet',
    web: 'WebTrader',
    mt5: 'METATRADER 5',
    mt5Desc: 'PC、Webブラウザー、スマートフォン、タブレット用のMetaTrader 5をダウンロード',
  },
  forgetPwReq: {
    tip: 'パスワードをリセットするには、あなたのアカウントのイーメールアドレスを入力してください。',
    formValidation: { emailReq: 'ログイン用Eメールアドレスが必須です' },
    succ:
      'パスワードをリセットする手順を記載したEメールを送信しました。<br><br>必ずメールの迷惑メールフォルダをご確認ください。',
  },
  loginClient: {
    header: 'クライアントポータル',
    failedMasseage: '再試行',
    existingClientPortalAccess:
      'クライアントポータルへの既存のアクセス権があることがわかりました。<br /><br />メールを使用してログインし、新しいアカウントを作成してください！',
    footer: `リスク警告：差額取引（CFD）取引は、お客様の資本に高いレベルのリスクをもたらし、損失をもたらす可能性がありますので、失う余裕のあるお金でのみ取引する必要があります。<br><br><br>
      パシフィックユニオンリミテッドは、ライセンス番号 SD050でセイシェル金融サービス庁によって承認および規制されています。<br>
      PU Prime LLC は、セントビンセントおよびグレナディーン諸島の金融サービス機構により、ライセンス番号271 LLC2020で認可および規制されています。<br>
      The entities above are duly authorized to operate under the PU Prime brand and trademark.<br>`,
    formValidation: { emailReq: 'Eメールアドレスが必要です' },
    invailidRafLink: `使用された紹介リンクは無効です。下記の新しいアカウントにサインイン。`,
  },
  myProfile: { changeDetails: '既存のプロファイルの詳細を更新したい場合は、{mailTo}までお問い合わせください。' },
  openAdditAcc: {
    chooseTradePlat: '取引プラットフォームを選択',
    chooseAccType: '口座タイプを選択',
    chooseCurrency: '口座の通貨を選択してください',
    notes: 'その他の注意事項',
    currError: '口座の通貨を選択する必要があります',
    platErr: '取引プラットフォームを選択する必要があります',
    accTypeErr: '口座の種類を選択する必要があります',
    succ:
      '<p>追加のライブアカウントを設定しています。ログインの詳細が記載されたEメールがすぐに届きます。ホームページからアプリケーションの現在のステータスを確認できます</p>',
    byTicking: `利用{tnc}を読み、同意しました。`,
    tnc: '規約',
    pamm: `PAMMアカウントは、既存のMoneyManager契約を結んでいる投資家専用です。 PAMMアカウントは、マネーマネージャー以外は取引できません。`,
    pammConfirm: 'このアカウントタイプを引き続き選択するかどうかを確認してください。',
  },
  openDemoAdditAcc: {
    header: 'デモアカウント設定',
    button: 'デモアカウントを開設する',
    liveButton: 'ライブ口座を開設する',
    succ: '追加のデモアカウントが設定され、ログインの詳細が記載されたEメールがすぐに送信されます。',
  },
  resetPassword: {
    header: 'パスワードをリセットする',
    succ: 'パスワードが更新されました。これで、新しいパスワードを使用してMT4 / MT5取引アカウントにアクセスできます。',
    succ2: 'パスワードが更新されました。',
    failed: 'トークンが期限切れです',
    emailNotFound: '残念ながらあなたのEメールアドレスは認識されませんでした',
  },
  fileUploader: {
    supportedFormat: 'サポートされているファイル形式：png、jpg、jpeg、bmp、pdf、doc、docx',
    maxSize: '最大アップロードファイルサイズ：{maxSize} MB',
    maxFileNum: '最大ファイル数：{maxFileNum}',
  },
  transcationHistory: {
    deposit: '入金する',
    withdraw: '出金する',
    desc: {
      incomplete:
        '入金プロセスを終了するか、完了しなかった場合、トランザクションのステータスは"未完了"と表示されます。このトランザクションは再開できないため、新しい預金を作成する必要があります。',
      success: '取引は成功しました。資金はまもなくアカウントに表示されます',
      proc: '取引を処理しています。{mailTo}にお問い合わせください',
      failed: '取引が失敗しました。詳細については、{mailTo}にお問い合わせください',
      rejected: '取引は却下されました。詳細については、{mailTo}にお問い合わせください',
    },
    withdrawDesc: {
      sub: '取引が送信されました。完了するまでお待ちください。',
      suc: '取引は成功しました。資金はまもなくアカウントに表示されます',
      proc: '取引を処理しています。{mailTo}にお問い合わせください',
      incomp: '取引を完了するには、追加の手順が必要です。詳細については、{mailTo}にお問い合わせください',
      cancel: '取引はキャンセルされました。詳細については、{mailTo}にお問い合わせください',
      fail: '取引が失敗しました。詳細については、{mailTo}にお問い合わせください',
      rej: '取引は却下されました。詳細については、{mailTo}にお問い合わせください',
    },
    cancelWithdraw: `出金依頼をキャンセルしますか?`,
    cancelError: `出金は現在処理中です。 そのため、キャンセルのお申し出はお受けできません。 詳細については、ライブ チャット機能を使用してご連絡ください。`,
    dialog: {
      appliedAmount: `申請金額`,
      applicationTime: `申請期間`,
      processedTime: `処理期間`,
      accountCurrency: `口座通貨`,
      creditDeducted: `クレジット控除額`,
      processedNote: `処理済みメモ`,
      bankName: `銀行名`,
      bankAccountNumber: `銀行口座番号`,
      abaSortCode: `ABA/ソートコード`,
      swiftCode: `スウィフトコード`,
      bankStatement: `銀行預金残高明細書`,
      bankAddress: `銀行の住所`,
      accountName: `口座の名義`,
      accountNumber: `口座番号`,
      cardholderName: `カードの名義`,
      cardNumber: `カード番号`,
      cardExpiryDate: `カードの有効期限`,
      walletAddress: `ウォレットアドレス`,
      ewalletEmailAddress: `E-walletのメールアドレス`,
    },
  },
  transferFunds: {
    default: {
      equityStop:
        '残念ながらアカウントの利用可能な証拠金がストップアウトレベルの100％を下回るため、送金を処理できません。',
      equityMargin:
        '残念ながら、お客様のアカウントは完全にヘッジされているため、現在、お客様の送金を処理することはできません。',
      equityContinue:
        '現在オープンポジションがあることを検出しました。送金申請を送信するとポジションをクローズする可能性があります。続行するかどうかを確認してください。',
      blackList: 'こちらの口座には口座間送金の制限がかけられています。詳しくは{supportEmail}までお問い合わせください。',
    },
    pleaseNote: 'ご注意ください：',
    suc: '資金は正常に送金されました',
    warn1:
      'あなたはマージンコールを引き起こさない口座残高を維持する責任があります。現在オープンポジションがある場合は、アカウントで必要なマージンを確保してください。同じ通貨で保持されているアカウントに送金された資金は即座に処理されます。',
    warn2: '通貨の異なるアカウント間で送金する場合は、{mailTo}にEメールを送信してください。',
    warn3: '読み取り専用アカウント間での資金移動はできませんのでご注意ください。',
  },
  transferHistory: {
    payStatus: { processing: '処理中', paid: '支払済み', unpaid: '未払い', rejected: '却下されました' },
  },
  identityProof: {
    home: {
      header: 'アカウントの申し込みが未完成です',
      content:
        '{name}様、アカウントの申し込みはほぼ完了しましたが、アカウントを完成させるには、追加の書類を提出する必要があります。',
    },
    shufti: {
      content: `提供された詳細がIDドキュメントと一致し、同じ言語であることを確認してください。`,
      countryOfDocument: `文書に示されている国`,
    },
    popup: {
      header:
        '{name}様、ライブアカウントを完成させる前に、確認のために次のドキュメントをアップロードする必要があります。',
      addressProofSpan: `<strong>アドレスを確認してください</strong><br />
      以下のいずれかの書類を提出してください<br />
      - ー最近の公共料金のコピー（6か月以内）<br />
      ー最近の銀行取引明細書のコピー （6か月以内）<br />
      <br />
      重要！文書の名前には、氏名と現在の住所を明確に示す必要があります。`,
      idProofSpan: `<strong>IDを確認</strong><br />
      以下のオプションの  いずれかを選択してください <br />
      - 有効なパスポートのコピー<br />
      - 有効な運転免許証のコピー（前面と背面）<br />
      - 国民IDカードのコピー<br />
      <br />
      重要！文書の名前はあなたのフルネームを明確に示さなければなりません。`,
    },
  },
  header: { secPortal: 'Secure<br />Clientエリア' },
  menu: {
    home: 'ホーム',
    accManagement: 'アカウント',
    liveAcc: 'ライブアカウント',
    openAdditAcc: '追加のアカウント',
    homeDemo: 'デモ口座',
    depositFund: '資金を入金する',
    withdrawFunds: '資金を出金する',
    transferBetweenAccs: '内部転送',
    payDetails: '支払詳細',
    funds: '資金',
    transactionHis: '取引履歴',
    tranHis: '転送履歴',
    download: 'ダウンロード',
    profile: 'プロフィール',
    myProfile: '私のプロフィール',
    cpPassword: 'パスワードを変更する',
    contactUs: 'お問い合わせ',
    register: '申し込みを続ける',
    tradingTools: 'プロトレーダーツール',
    MARKET_BUZZ: 'マーケットバズ',
    ECON_CALENDAR: '経済カレンダー',
    FEAT_FX: '注目のアイデア',
    FX_IDEAS: 'アナリストの見解',
    promotion: 'プロモーション',
    noDeposit: 'ゼロデポジットボーナス',
    referFriends: 'お友達紹介キャンペーン',
    depositBonus: '50%入金ボーナス',
    cryptoCashback: `暗号通貨キャッシュバック`,
    firstTimeDeposit: `FTD キャッシュバック`,
    tradingTool: '取引ツール',
  },
  contact: {
    desc: `{platform}の専門的な多言語カスタマーサポートチームは、お客様を支援し、比類のない取引体験の提供を支援する準備ができています。`,
    followUs: 'フォローする',
    tip: '{email}でオンボードしたクライアントの場合',
    liveChat: 'ライブチャット',
  },
  depositResultDialog: { msg: '入金は正常に完了しましたか' },
  dialog: {
    identityProof: {
      success: 'ありがとうございました。ドキュメントを正常に受け取り、現在確認のために処理しています。',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: '口座申請処理中',
      id: 'ID',
      poa: '住所',
      both: 'IDと住所',
      content:
        '現在、{doc}確認書類を確認中です。間もなく完了しますので少々お待ちくださいませ。{doc}確認が済んでいなくても入金して取引を開始することはできます。',
    },
  },
  register: {
    progressStatus: { title: '検証の進行状況', estimatedTimeTitle: '予定時刻', min: '分' },
    tabs: {
      personalDetails: '個人の詳細',
      moreAboutYou: 'あなたについての詳細',
      accountConfig: 'アカウント設定',
      confirmYourId: 'IDを確認',
      fundYourAcc: 'アカウントに資金を入金する',
    },
    btn: { next: '次', back: '戻る', fundnow: '今すぐ資金を入金', upload: 'アップロードする', done: '完了' },
    authority: {
      dialog1: 'クライアントポータルのこのセクションにアクセスするには、ライブアカウントが必要です。',
      dialog2:
        '今すぐライブアカウントを開設して、さまざまな取引ツールやプロモーションなどを含むクライアントポータルへのフルアクセスのロックを解除してください。',
      openLiveAcc: 'ライブ口座を開設する',
    },
    demo: {
      congrad: 'おめでとうございます！',
      title: '60日間のDEMO MT{platform} アカウントが有効になりました',
      title2: 'あなたのDEMOアカウントの詳細',
      li: { login: 'ログイン', srv: 'サーバー', expDate: '有効期限' },
      emailSent: '*ログインの詳細は、登録したEメールにも送信されました。',
      dl: 'MT {platform}取引プラットフォームをダウンロード',
      improveQ: '取引を改善したいですか？',
      improveWay:
        'ライブ口座をわずか数分で開設するだけで、さまざまな独占取引ツールやプロモーションのロックを解除できます。',
      proTrade: 'プロのマーケティングレポート',
      customerService: '24時間年中無休の1対1のカスタマーサービス',
      competitivePromotionals: '競争力のあるプロモーション',
      secure: '3Dセキュアトランザクション',
      smartTradeTool: 'スマートトレーダーツール',
      openLiveAcc: 'ライブ口座を開設する',
    },
    personalDetails: {
      page1: {
        pageTitle: '個人の詳細',
        userTitle: '称号',
        firstName: '名',
        lastName: '姓',
        email: 'Eメール',
        dob: '生年月日',
        idType: 'ID種類',
        referral: '誰かに紹介されましたか？（オプション）',
        middleName: 'ミドルネーム',
        nationality: '国籍',
        phoneNum: '電話番号',
        date: '日',
        month: '月',
        year: '年',
        placeOfBirth: '出生地',
        idNum: 'ID番号',
        passportNum: 'パスポート番号',
        driverLicenseNum: '運転免許証番号',
        titleDropdown: { mr: '様', mrs: '様', ms: '様', miss: '様', dr: '医師', prof: '教授' },
        idTypeDropdown: { passport: 'パスポート', idCard: '国民IDカード', driverLicense: '運転免許証' },
      },
      page2: {
        pageTitle: '本住居住所',
        mainResidency: '主な居住国',
        streetNumber: '街路番号',
        streetName: '通りの名前',
        address: '住所',
        provinceOrState: '都道府県',
        cityOrSuburb: '市/郊外',
        postcode: '郵便番号',
        usQuestion: 'あなたは税務上の米国籍/居住者ですか、それとも米国の関係（銀行口座など）がありますか？',
      },
    },
    moreAboutYou: {
      pageTitle1: '雇用と財務の詳細',
      pageTitle2: 'トレーディングの知識と経験',
      complianceExplain: '以下の質問は、AML / CTF要件に準拠するために収集されます。',
    },
    accountConfig: {
      pageTitle: 'アカウント設定',
      chooseTradingPlatform: '取引プラットフォームを選択',
      chooseAccType: 'アカウントの種類を選択',
      chooseAccCurrency: 'アカウント通貨を選択',
    },
    confirmYourId: {
      pageTitle: 'IDを確認',
      description:
        '規制対象のFXブローカーとして、当社は、あなたの身元を確認する必要があります。身分証明書と住所証明書の確認のために、次のいずれかのドキュメントをアップロードしてください。または、確認書類を{mailTo}にEメールで送信することもできます',
      poidTitle: '身元の証明',
      porTitle: '居住証明書',
      uploadDescription: 'ドキュメントタイプを選択し、適切にアップロードしてください',
      note: '重要：文書の名前には、氏名を明確に示す必要があります。',
      pending: { upload: 'アップロード保留中', verification: '確認保留中' },
      poidTypes: { photoId: '写真付きID', passport: 'パスポート', drivingLicence: '運転免許証' },
      porTypes: '<li>公共料金</li>\n      <li>銀行取引明細書</li>\n      <li>住民票</li>',
    },
    finishPage: {
      title: '{individualUserName}様、{platformName}のライブアカウントにお申し込みいただきありがとうございます！',
      contentDefault:
        'お申し込みを受け取り、ライブアカウント<b>{account}</b>を設定しました。 <br />\n     まもなく、ログインの詳細とアカウントを表示するためのリンクが記載されたEメールが<b>{email}</b> に届きます <br />',
      contentWithoutId3WithAccount:
        'ライブ取引口座は現在処理中です。まもなく詳細情報が記載されたEメールが届きます。<br />\n      ライブ口座処理を迅速に追跡するために、今すぐ口座に資金を入金し、優先的な開設を受け取ることができます。<br />',
      contentWithoutId3WithoutAccount:
        'ライブ取引口座は現在処理中です。まもなく詳細情報が記載されたEメールが届きます。<br />',
    },
  },
  autochartist: {
    1: {
      title: `マーケットスキャナー`,
      desc01: `世界初のマーケットスキャナーを使用すると、マーケットをスキャンして取引の機会を見つけることができます。`,
      button01: `ダウンロード中`,
    },
    2: {
      title: `市場レポート`,
      desc01: `Autochartistの市場レポートは、主要な金融センターが開設される前の市場取引のプレビューを提供します。`,
      button01: `市場レポートを見る`,
    },
    3: {
      title: `Autochartist Research Portal`,
      desc01: `利用可能なすべてのデータを同時に表示できる包括的なリサーチポータル。`,
      button01: `開始`,
      button02: `ユーザーガイド`,
      button03: `OTPアクセス`,
    },
    4: {
      title: `取引機会`,
      desc01: `すべてのAutochartist分析タイプの新しい設定に従って、取引機会の更新は15分ごとにチェックされます。`,
      button01: `取引機会を見る`,
    },
    5: {
      title: `Autochartistモバイルアプリ`,
      desc01: `取引プラットフォームの市場見積もりフォームに従って、プッシュ通知を受信し、高い確率で実行可能な取引機会を追跡します。`,
      desc02: `注意：`,
      desc03: `ユーザーの携帯電話にQRスキャナーが内蔵されている場合は、[QRコードでログイン]ボタンをクリックして通常のカメラからQRコードをスキャンするか、自動チャートモバイルアプリ内からQRコードをスキャンできます。`,
      desc04: `QRコードは10分間のみ有効です。その後、新しいQRコードを取得するには画像を更新する必要があります。`,
      desc05: `QRコードは1回の使用でのみ有効です。その後、新しいQRコードを取得するには画像を更新する必要があります。`,
      button01: `IOSをダウンロード`,
      button02: `Andriodをダウンロード`,
    },
    6: {
      title: `ニュース購読`,
      desc01: `ニュース通知を購読すると、Autochartistがサポートする電子メールが毎日届き、世界の外国為替市場の概要がわかります。パシフィックユニオンと手を組み、世界と取引しましょう。`,
      button01: `サブスクリプション`,
    },
    footer: `Autochartistは現在、世界で最も先進的な取引ツールの1つであり、チャートパターン、フィボナッチパターン、および主要な価格ポイントの形式で取引機会を自動的に識別するために使用できます。 Autochartistの独自のテクノロジーにより、検索エンジンは24時間体制で数万の金融商品を監視し、潜在的な機会を見つけることができます。 さらに、Autochartistは「ボラティリティ分析」機能も提供します。これにより、取引する製品のリスクとボラティリティをより適切に評価できます。`,
  },
  responseMsg: {
    410: 'パラーメータの検証に失敗しました',
    411: '不正なパスワード',
    420: 'ユーザーが存在しません',
    421: 'パスワードのリセットが拒否されました',
    490: '重複したカード情報を提出しないでください',
    500: 'エラーが発生しました。',
    501: 'アプリケーションが失敗しました。後でもう一度やり直してください。',
    505: 'ファイルのアップロードに失敗しました',
    520: 'ログインに失敗しました。もう一度やり直してください。',
    521: 'ユーザーは既にこのデバイスにログインしています！',
    522: 'もう一度ログインしてください',
    523: 'もう一度ログインしてください',
    524: '残念ながらあなたのEメールアドレスは認識されませんでした',
    525: 'ユーザーネームまたはパスワードが違います',
    527: 'ユーザーがIBではありません',
    528: 'ユーザーが存在しません',
    529: 'ユーザーネームまたはパスワードが違います',
    540: '取引口座が見つかりませんでした',
    541: 'リベートアカウントが見つかりません',
    542: '追加のアカウントを開くことは許可されていません',
    544: 'お客様は、身分証明書が認証されるまで追加のアカウントを申請することができません。',
    550: '口座番号が間違っています',
    551: '残高が不足しています',
    553: 'アカウントのクレジットにより、出金・送金できません。さらにサポートが必要な場合は、{email}にお問い合わせください',
    554: 'リクエストされた金額はゼロまたは空白です',
    564: '国名が決済チャネルと一致しません',
    565: '不正な銀行コード',
    566: 'お支払い方法が見つかりません',
    568: 'このアカウントは入金が制限されています。info@puprime.comまでお問い合わせください。',
    581: 'プロモーションへのオプトインに成功しました。資金を引き出すには、預金をする必要がありますのでご注意ください',
    582: 'あなたはイベントに参加しており、ポジションを持っているため、退会申請を提出することはできません。',
    584: '該当するリベートはありません',
    587: 'バランスが悪い場合はカスタマーサービスまでご連絡ください',
    590: '送金口座が見つかりません',
    591: '入金口座が見つかりません',
    593: 'お客様の口座には預金があるため、選択した口座からの資金送金手続きを行うことができませんでした。',
    594: 'この口座からの資金の送金は禁止されています',
    595: 'あなたは私たちの活動に参加しているので、あなたの転送要求は現在処理できません。',
    647: '認証コードが正しくありません',
    1001: 'あなたの口座は一時的にご利用いただけなくなっています。アカウントマネージャーまでお問い合わせください',
    session_timeout: 'セッションタイムアウト、ログインページにリダイレクトします。',
    vload: {
      4019: 'バウチャーのタイプまたは値が受け付けられませんでした',
      4071: '詐欺の疑いがあります',
      4072: 'バウチャーが使用されました',
      4073: 'バウチャーが有効ではありません',
      4074: 'バウチャーの有効期限が切れています',
      4075: 'バウチャーがみつかりません',
      4080: 'バウチャーの値が不十分です',
      4444: '無効なバウチャーが使用されました。通貨が一致しません。',
      5000: 'ストアのデータを利用できません',
    },
  },
  tradingTool: {
    mt4: {

    },
    pamm: {

    },
    copytrade: {

    }
  },
  activity: {
    myActivity: '',
    allActivity: '',
    activityContent: '',
    applyNow: '',
    activityStatus1: '',
    activityStatus2: '',
    activityStatus3: '',
    noActivity: '',
  }
}
