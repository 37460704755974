export default {
  en: 'English',
  zh: '中文',
  common: {
    liveChat: { desc: '<li><p>¿Necesita ayuda?</p><span>Chat en vivo</span></li>' },
    country: { international: 'Internacional', australia: 'Australia' },
    verification: { toSlide: 'Deslícese para completar el rompecabezas' },
    field: {
      accName: 'Nombre de la cuenta',
      accNum: 'Número de cuenta',
      imptNotes: 'Notas importantes',
      bankName: 'Nombre del banco',
      nameOnCard: 'Nombre en la tarjeta',
      cardholderName: `Nombre del tarjetahabiente`,
      first4Digits: '4 primeros dígitos de la tarjeta',
      last3Digits: '3 últiamos dígitos de la tarjeta',
      first6Digits: '6 primeros dígitos de la tarjeta',
      last4Digits: '4 últimos dígitos de la tarjeta',
      branchAddress: 'Dirección de la sucursal',
      bankBeneAccName: 'Nombre de la cuenta del beneficiario del banco',
      bankBeneAccNum: 'Número de cuenta del beneficiario del banco',
      bankHolderAddress: 'Dirección del titular de la cuenta',
      bankBeneAddress: 'Dirección del beneficiario',
      bsbNum: 'Número BSB',
      swift: 'Código SWIFT',
      swiftNonAUD: 'Código SWIFT (No cuentas AUD)',
      accName: 'Nombre de la cuenta',
      accNum: 'Número de cuenta',
      accNumTo: 'Número de cuenta',
      bankName: 'Nombre del banco',
      bankAddress: 'Dirección bancaria',
      bankBranch: 'Sucursal bancaria',
      bankCity: 'Ciudad del banco',
      bankProvince: 'Provincia del banco',
      bankBeneficiaryName: 'Nombre del beneficiario del banco',
      bankAccNum: 'Número de cuenta bancaria',
      address: 'Dirección',
      ifscCode: 'Código IFSC',
      sort: 'Código de sucursa',
      amt: 'Cantidad',
      amtUSD: 'Cantidad(USD)',
      availableAmount: 'Importe disponible',
      transitNumber: 'Número de tránsito',
      institutionNumber: 'Número de institución',
      bsb: 'BSB',
      name: 'Nombre',
      emailAdd: 'Dirección de email',
      phone: 'Número de teléfono',
      submitted: 'Enviado',
      processing: 'Procesando',
      cancelled: 'Cancelado',
      failed: 'Fallido',
      rejected: 'Rechazado',
      selectWithdrawCard: 'Seleccione su tarjeta para la retirada',
      payMethod: 'Método de pago:',
      notes: 'Notas',
      upload: 'Enviar',
      abaSortCode: 'Código de sucursal/ABA',
      country_region: 'País/Región:',
      currLeverage: 'Apalancamiento actual',
      accEquity: 'Capital de la cuenta',
      newLeverage: 'Apalancamiento deseado',
      currPw: 'Contraseña actual',
      newPw: 'Nueva contraseña',
      confirmPw: 'Confirmar nueva contraseña',
      loginEmail: 'Email de inicio de sesión',
      email: 'Correo electrónico',
      pw: 'Contraseña',
      accType: 'Tipo de cuenta',
      name: 'Nombre',
      emailAdd: 'Dirección de email',
      phone: 'Número de teléfono',
      countryCode: 'Country',
      dob: 'Fecha de nacimiento',
      nat: 'Nacionalidad',
      country: 'País de residencia',
      street: 'Número de la calle/Nombre de la calle',
      suburbOrCity: 'Barrio/Ciudad',
      provinceOrState: 'Provincia/Estado',
      postcode: 'Código postal',
      empStat: 'Situación Profesional',
      annIncome: 'Ingresos anuales',
      save: 'Inversión',
      source: 'Procedencia de los ingresos',
      industry: 'Industria',
      statusDef: 'DESCRIPCIÓN DE ESTADO',
      month: 'Mes',
      year: 'Año',
      select: 'Seleccionar',
      pending: `pendiente`,
      ccNum: 'Número de la tarjeta de crédito',
      ccExpireDate: 'Fecha de vencimiento',
      completed: 'Completado',
      incomplete: 'Incompleto',
      successful: 'Exitoso',
      submitted: 'Enviado',
      processing: 'Procesando',
      cancelled: 'Cancelado',
      failed: 'Fallido',
      rejected: 'Rechazado',
      expDate: 'Fecha de vencimiento',
      bankName: 'Nombre del banco',
      bankCardNumber: 'Número de la tarjeta bancaria',
      bankBranch: 'Sucursal bancaria',
      cardHolderName: 'Nombre del titular de la tarjeta',
      reservedMobileNumber: 'Número de teléfono móvil para el banco',
      nationalId: 'Documento nacional de identidad',
      cardPhoto: 'Foto de la tarjeta (anverso)',
      bankAccOrIBAN: 'Número de la cuenta bancaria/IBAN',
      selectWithdrawCard: 'Seleccione su tarjeta para la retirada',
      updateCardPhoto: 'Por favor, envíe la foto de su tarjeta (anverso)',
      actualAmount: 'cantidad real',
      resetBalTo: 'Restablecer saldo a',
      ipChangeWarning:
        'Reciba una alerta por correo electrónico cuando alguien acceda a su cuenta desde una ubicación distinta',
      enterVerificationCode: 'Introducir código de verificación',
      tips: '',
      pleaseInput: '',
      confirmClose: '',
      notBind: '',
      infoDetails: '',
      loginWarn: '',
      noDataNow: '',
      hasAccountAlready: '',
    },
    column: {
      date: 'FECHA',
      status: 'ESTADO',
      accNum: 'NÚMERO DE CUENTA',
      type: 'TIPO',
      server: 'SERVIDOR',
      comment: 'COMENTARIO',
      currency: 'DIVISA',
      equity: 'CAPITAL',
      leverage: 'APALANCAMIENTO',
      resetMT4PW: 'RESTABLECER CONTRASEÑA MT4',
      resetMT5PW: 'RESTABLECER CONTRASEÑA MT5',
      tradingAcc: 'CUENTA DE TRADING',
      method: 'MÉTODO',
      amt: 'CANTIDAD',
      procNote: 'NOTAS DE PROCESO',
      number: 'NÚMERO DE LA TARJETA',
      PLATFORM: 'PLATAFORMA',
      resetBal: 'RESTABLECER SALDO',
    },
    key: { from: 'DESDE', to: 'HASTA' },
    fund: {
      must: 'DEBE',
      infoHover: 'El nombre de su tarjeta {must} coincidir con el nombre de su cuenta de trading',
      ccUploadReminder:
        'Por favor, envíe una foto del anverso de su tarjeta para verificarla. (Cubra los 6 dígitos del medio)',
    },
    button: {
      goToIB: 'Ir al portal de IB',
      submit: 'Enviar',
      ibPortal: 'PORTAL DE IB',
      cancel: 'Cancelar',
      confirm: 'Confirmar',
      bkToHm: 'VOLVER A INICIO',
      smsLogin: 'INICIAR SESIÓN DESDE EL MÓVIL',
      logout: 'CERRAR SESIÓN',
      depositFunds: 'DEPOSITAR FONDOS',
      withdrawFunds: 'RETIRAR FONDOS',
      download: 'DESCARGAS',
      changeLeverage: 'CAMBIAR APALANCAMIENTO',
      changePw: 'CAMBIAR CONTRASEÑA',
      forgetPw: 'HE OLVIDADO MI CONTRASEÑA',
      forgotPw: '¿Ha olvidado su contraseña?',
      completeQuestionnaire: 'COMPLETAR CUESTIONARIO',
      identityProof: 'COMPLETAR SOLICITUD',
      upload: 'ENVIAR',
      export: 'EXPORTAR',
      ok: 'OK',
      hide: 'OCULTAR',
      showAll: 'MOSTRAR TODO',
      createLiveAccount: `Crear cuenta real`,
      redeem: `Canjear`,
      sendCode: 'Enviar código',
      resendCode: 'Reenviar codigo',
    },
    formValidation: {
      accReq: 'El número de cuenta es obligatorio',
      ccReq: 'El número de la tarjeta de crédito es obligatorio',
      cardReq: 'El número de la tarjeta es obligatorio',
      cardOnNameReq: 'El nombre en la tarjeta es obligatorio',
      cardholderNameReq: `Se requiere el nombre del titular de la tarjeta`,
      digits16: 'Por favor, introduzca 16 dígitos',
      digits3or4: 'Por favor, introduzca 3 o 4 dígitos',
      digitsFirst6: 'Por favor, introduzca los primeros 6 dígitos',
      digitsLast4: 'Por favor, introduzca los últimos 4 dígitos',
      digitsLast3: 'Por favor, introduzca los últimos 3 dígitos',
      mid6Digits: 'Por favor, introduzca los {number} dígitos del medio',
      expDateReq: 'La fecha de vencimiento es obligatoria',
      cvvReq: 'El código de seguridad CVV es obligatorio',
      file: 'Por favor, envíe un archivo',
      filePhoto: 'La foto de la tarjeta de crédito es obligatoria',
      filePhotoReq: 'La foto de la tarjeta es obligatoria',
      note256: 'El límite de extensión de la nota es 256 caracteres',
      noteMoreThan: 'El límite de extensión de la nota es {limit} caracteres',
      noteLessThan: 'El límite de extensión de la nota es {limit} caracteres',
      payMethodReq: 'El método de pago es obligatorio',
      expReq: 'La fecha de vencimiento es obligatoria',
      bankProvinceReq: 'La provincia del banco es obligatoria',
      bankAddressReq: 'La dirección bancaria es obligatoria',
      bankBranchReq: 'La sucursal bancaria es obligatoria',
      bankHolderAddressReq: 'La dirección del titular de cuenta es obligatoria',
      swiftReq: 'El código swift es obligatorio',
      accHolderAddress: 'La dirección del titular de cuenta es obligatoria',
      accNumIBANReq: 'El número de la cuenta bancaria/IBAN es obligatorio',
      accNameReq: 'El nombre de la cuenta bancaria es obligatorio',
      withdrawMethodReq: 'Por favor, seleccione un método de retirada',
      tnc: 'Por favor, acepte los Términos y Condiciones.',
      currPwReq: 'La contraseña actual es obligatoria',
      currPwWrong: 'La contraseña actual no es correcta',
      newPwReq: 'La nueva contraseña es obligatoria',
      IdenficalPw: 'La nueva contraseña no puede ser la misma que la anterior',
      confirmPwReq: 'Por favor, introduzca una nueva contraseña de nuevo',
      confirmNotMatch: 'Los dos datos no coinciden',
      pwReq: 'La contraseña es obligatoria',
      accCurrReq: 'Seleccione una moneda base para su cuenta',
      tradAccReq: 'Por favor, seleccione una cuenta de trading',
      answerReq: 'Por favor, seleccione',
      phoneNumberReq: 'El número de teléfono móvil para el banco es obligatorio',
      nationaIdReq: 'El documento nacional de identidad es obligatorio',
      numberReq: 'Por favor, introduzca un número',
      alphanumericReq: 'Por favor, introduzca un alfanumérico',
      idTypeReq: 'Por favor, seleccione un tipo de identificación',
      dobReq: 'Por favor, seleccione su fecha de nacimiento',
      pobReq: 'Por favor, seleccione su lugar de nacimiento',
      nationalityReq: 'Por favor, seleccione su nacionalidad',
      titleReq: 'Por favor, seleccione su título',
      firstNameReq: 'Por favor, introduzca su nombre',
      lastNameReq: 'Por favor, introduzca sus apellidos',
      emailReq: 'Por favor, introduzca su correo electrónico',
      addressReq: 'Por favor, introduzca su dirección',
      suburbReq: 'Por favor, introduzca su ciudad o barrio',
      stateReq: 'El estado o provincia son obligatorios',
      postcodeReq: 'Por favor, introduzca su código postal',
      countryReq: 'Por favor, seleccione su país de residencia',
      bankCodeReq: 'Por favor, introduzca el banco emisor de la tarjeta',
      dynamicReq: 'El campo {dynamic} es obligatorio',
      mobReq: 'El teléfono móvil es obligatorio',
      amtReq: 'La cantidad es obligatoria',
      fileReceipt: 'Por favor, envíe un recibo',
      amtLarger: 'La cantidad no puede ser nula y debe ser superior o igual a {minLimit} {currency}',
      amt0: 'La cantidad no puede ser 0',
      amtLess: 'La cantidad de su depósito no puede ser superior a {maxLimit} {currency}',
      emailFormat: 'Por favor, introduzca una dirección de email correcta',
      bankNameReq: 'El nombre del banco es obligatorio',
      bankCityReq: 'La ciudad del banco es obligatoria',
      bankAccNumReq: 'El número de la cuenta bancaria es obligatorio',
      ifscCodeReq: 'IFSC Code is required',
      bankBeneficiaryNameReq: 'El nombre del beneficiario del banco es obligatorio',
      bsbReq: 'El BSB es obligatorio',
      newPwFormat:
        'Su contraseña debe tener entre 8 y 16 caracteres y una mezcla de letras (a-z y A-Z), números (0-9) y caracteres especiales como !@#$%^&*.()',
      bankCardReq: 'Por favor, seleccione una tarjeta',
      masterCardJCBReq: `Utilice una Master Card / JCB para depositar`,
      masterCardVisaReq: `Utilice una Master Card / Visa para depositar`,
      masterCardAmexJCBReq: `Utilice una Master Card / AMEX / JCB para depositar`,
      resetBalAmt: 'Por favor, fije una cantidad entre 0 y 1.000.000',
      verCodeReq: 'El código de verificación es obligatorio',
      emailFormatWrong: 'Formato de correo electrónico incorrecto',
      passwordFormatWrong: 'Formato de contraseña incorrecto',
    },
    withdrawChannel: {
      banktransferbpaypolipay: 'Transferencia bancaria/BPay/PoliPay',
      banktransfer: 'Transferencia bancaria',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'Transferencia UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Tarjeta de crédito',
      bpay: 'Bpay',
      creditdebitcard: 'Tarjeta de crédito/débito',
      bankwiretransferinternational: 'Transferencia bancaria (Internacional)',
      banktransferaustralia: 'Transferencia bancaria (Australia)',
      banktransferinternational: 'Transferencia bancaria (Internacional)',
      banktransferchina: 'Transferencia bancaria (Internacional)',
      banktransferequals: 'Transferencia bancaria (International)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Transferencia de Broker a Broker',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Banca online (Tailândia)',
      internetbankingnigeria: 'Banca online (Nigéria)',
      internetbankingvietnam: 'Banca online (Vietname)',
      internetbankingmalaysia: 'Banca online (Malásia)',
      internetbankingindonesia: 'Banca online (Indonésia)',
      internetbankingkorea: 'Banca online (Corea)',
      internetbankingindia: 'Banca online (India)',
      internetbankingphilippine: 'Banca online (Philippine)',
      internetbankingsouthafrica: 'Banca online (Sudáfrica)',
      internetbankinguganda: 'Banca online (Uganda)',
      internetbankingrwanda: 'Banca online (Ruanda)',
      internetbankingzambia: 'Banca online (Zambia)',
      internetbankingcongo: 'Banca online (Congo)',
      internetbankingcameroon: 'Banca online (Camerún)',
      internetbankingburundi: 'Banca online (Burundi)',
      internetbankingkenya: 'Banca online (Kenia)',
      internetbankingghana: 'Banca online (Ghana)',
      internetbankingtanzania: 'Banca online (Tanzania)',
      internetbankingcanada: 'Banca online (Canadá)',
      skrillneteller: 'Skrill / Neteller',
      polipayment: 'Pagamento con Poli',
      bankwiretransferaustralia: 'Transferencia bancaria (Australia)',
      thailandinstantbankwiretransfer: 'Transferencia bancaria instantánea de Tailandia',
      nigeriainstantbankwiretransfer: 'Transferencia bancaria instantánea de Nigeria',
      vietnaminstantbankwiretransfer: 'Transferencia bancaria instantánea de Vietnam',
      malaysiainstantbankwiretransfer: 'Transferencia bancaria instantánea de Malasia',
      indonesiainstantbankwiretransfer: 'Transferencia bancaria instantánea de Indonesia',
      indiainstantbanktransfer: 'Transferencia bancaria instantánea de India',
      southkoreabanktransfer: 'Transferencia bancaria local de Corea del Sur',
      southafricainstantbankwiretransfer: 'Transferencia bancaria instantánea de Sudáfrica',
      philippinesinstantbankwiretransfer: 'Transferencia bancaria instantánea de Philippine',
      ugandabanktransfer: 'Transferencia bancaria local de Uganda',
      rwandabanktransfer: 'Transferencia bancaria local de Ruanda',
      zambiabanktransfer: 'Transferencia bancaria local de Zambia',
      congobanktransfer: 'Transferencia bancaria local del Congo',
      cameroonbanktransfer: 'Transferencia bancaria local de Camerún',
      burundibanktransfer: 'Transferencia bancaria local de Burundi',
      kenyabanktransfer: 'Transferencia bancaria local de Kenia',
      ghanabanktransfer: 'Transferencia bancaria local de Ghana',
      tanzaniabanktransfer: 'Transferencia bancaria local de Tanzania',
      indiabanktransfer: 'Transferencia bancaria da India',
      banktransferbpaypolipayaustralia: 'Transferencia bancaria/BPay/PoliPay (Australia)',
      banktransferbpaypolipayinternational: 'Transferencia bancaria/BPay/PoliPay (Internacional)',
      paypal: 'Paypal',
      cryptobitcoin: 'Criptodivisa-Bitcoin',
      cryptotetheromni: 'Criptodivisa-Tether(OMNI)',
      cryptotethererc20: 'Criptodivisa-Tether(ERC20)',
      cryptotethertrc20: 'Criptodivisa-Tether(TRC20)',
      cryptocurrencybitcoin: 'Criptodivisa-Bitcoin',
      cryptocurrencyusdt: 'Criptodivisa-USDT',
      cryptocurrencyeth: 'Criptodivisa-ETH',
      cryptocurrencyusdc: 'Criptodivisa-USDC(ERC-20)',
      cryptocurrencyusdcerc20: 'Criptodivisa-USDC(ERC-20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      E_WALLET: 'CARTERA ELECTRÓNICA',
      CRYPTOCURRENCY: 'CRIPTOMONEDA',
      LOCAL_TRANSFER: 'TRASLADO LOCAL',
      archiveThisCard: 'Por favor, archive esta tarjeta si ya no es válida',
      manageCreditCards: 'Gestionar tarjetas de crédito',
      update: 'Actualizar',
      archive: 'Archivar',
      requiredDocument: 'Documentos requeridos para archivar la tarjeta',
      officialLetter: 'Email/comunicado oficial del banco indicando que la tarjeta ya no es válida.',
      bankStatement: 'Extracto bancario con una transacción realizada con esa tarjeta',
      expiredCard: 'Tarjetas caducadas/no disponibles',
      confirmArchive: '¿Está seguro de que desea archivar esta tarjeta?',
      confirmUpdate:
        '¿Está seguro de que desea actualizar la fecha de caducidad de esta tarjeta? Nota: Puprime no será responsable de ningún fallo en la retirada debido a una fecha de caducidad proporcionada incorrectamente',
      pendingArchiveRequest:
        'Actualmente la solicitud de archivo de su tarjeta de crédito está pendiente de aprobación, por favor, espere a que el proceso se complete antes de efectuar una retirada o contacte con nuestro servicio de atención al cliente mediante chat en vivo para acelerar el proceso.',
    },
  },
  home: {
    pendingTooltip:
      'Una vez que su cuenta en vivo sea aprobada, recibirá un correo electrónico con sus detalles de inicio de sesión en MT{number} y podrá empezar a operar. Mientras tanto, puede hacer un depósito en su cuenta para recibir prioridad en la apertura de la cuenta.',
    leverage: {
      tnc:
        '<span>Términos y condiciones</span><p>Al enviar esta solicitud, acepto que tengo en cuenta que un apalancamiento alto conlleva un elevado riesgo para mi capital y que existe la posibilidad de que pueda sufrir una pérdida mayor y no limitada al capital que he depositado.<br><br>Acepto que el emisor de productos tiene derecho a revisar mis actividades de trading y ajustar los niveles de apalancamiento en mi cuenta de trading a su discreción y sin notificaciones ni advertencias previas.<br><br>Comprendo que al elegir un apalancamiento alto, puedo operar con un margen mayor pero potencialmente tener pérdidas significativas.</p>',
      tncConfirm: 'He leído y aceptado los términos y condiciones.',
      changeLeverageConfirm:
        'Su solicitud ha sido enviada y será procesada por nuestro equipo de apoyo al cliente en breve.',
      changeLeverageAutoComplete: 'Su solicitud para cambiar el apalancamiento fue completada.',
      changeLeverageReject: 'La solicitud para cambiar el apalancamiento ha sido rechazada',
      changeLeverageFail: 'La solicitud para cambiar el apalancamiento ha fallado. Por favor, inténtelo más tarde',
      alreadyApplied:
        'Ya ha solicitado cambiar el apalancamiento en su cuenta y su petición está siendo revisada por el equipo de atención al cliente de {platform} actualmente. Por favor, espere a que esta solicitud finalice antes de enviar otra.',
      changeLeverageDefault:
        'Se ha producido un error, por favor, contacte con $(supportEmail) para terminar su solicitud',
      reduceLeverageWarn:
        'Nota: La reducción de su apalancamiento puede resultar en el cerramiento forzado de las posiciones abiertas si su cuenta no tiene suficiente margen.',
      reduceLeverageConfirm: '¿Desea continuar?',
      autoRestore: `Como el capital de su cuenta supera los 20.000 USD, el apalancamiento máximo que puede utilizar es de 500:1. Puede cambiar el apalancamiento a 1000:1 cuando el capital de su cuenta sea inferior o igual a 20.000 USD.`,
    },
    pw: {
      error: 'Error al restablecer su contraseña. Por favor, inténtelo de nuevo más tarde',
      confirm:
        'Su contraseña ha sido actualizada. <br>Ahora puede utilizar su nueva contraseña para acceder a su cuenta de trading MT4/MT5.',
      email:
        '<p class=first_info>Acabamos de enviarle un correo electrónico con instrucciones para restablecer su contraseña.</p><p>Asegúrese de revisar la carpeta de spam de su email.</p>',
    },
    activate: {
      question: '¿Desea reactivar su cuenta en vivo?',
      confirm: 'Hemos recibido su solicitud de activación.<br>Su cuenta se activada en breve.',
    },
    accStatus: {
      rejected: 'Rechazado',
      processing: 'Procesando',
      active: 'Activa',
      inactive: 'Inactiva',
      activate: 'Activar',
      expired: 'Expirada',
    },
    pro1000: {
      desc1: `Las siguientes restricciones se aplican a las cuentas con apalancamiento de 1000:1:`,
      conditions: [
        `La cuenta seleccionada no es elegible para unirse a la promoción de crédito.`,
        `El sistema puede reducir automáticamente el apalancamiento a 1:500 si el capital de la cuenta seleccionada supera los 20.000 USD o su equivalente. Si hay una operación abierta, se enviará una notificación por correo electrónico antes del ajuste del apalancamiento.`,
        `Los límites del apalancamiento pueden variar en función de la normativa aplicable.`,
        `Tenga en cuenta que el apalancamiento de 1:1000 no está disponible para instrumentos financieros pertenecientes a criptodivisas, acciones, plata y materias primas. El margen para estos instrumentos se mantiene de conformidad con los requisitos de margen fijo de los instrumentos y no se ve afectado por el apalancamiento de 1:1000.`,
      ],
      desc2: `Al presentar esta solicitud, reconozco que soy consciente de que un apalancamiento elevado conlleva un gran riesgo para mi capital y que existe la posibilidad de que sufra una pérdida superior al capital que he depositado, aunque no limitada a éste.`,
      desc3: `Acepto que el emisor de los productos tiene derecho a revisar mis actividades de trading y a ajustar los niveles de apalancamiento de mi cuenta de trading a su discreción y sin previo aviso o notificación.`,
      desc4: `Entiendo que al elegir un apalancamiento alto, puedo operar con un margen mayor pero incurrir potencialmente en pérdidas significativas.`,
    },
  },
  paymentDetails: {
    subHeader: 'AÑADIR UN MÉTODO DE PAGO',
    payMethod: 'Método de pago',
    tip:
      '<p>Seleccione su método de pago preferido para futuros depósitos y retiradas desde el siguiente menú.</p><br />\n   <p><b>Tenga en cuenta que:</b> para garantizar que sus solicitudes de depósito y retirada se puedan procesar rápidamente, las regulaciones AML/CTF requieren que el nombre que aparece en el método de pago elegido sea:\n      <b> {individualUserName}</b>.</p>',
    result: {
      defaultSuccessMessage:
        'Gracias por enviar el método para añadir fondos para su cuenta de trading. Actualmente estamos procesando su solicitud y nos pondremos en contacto en breve.',
      unionPayCardSuccessMessage:
        'Los datos de la tarjeta Union Pay se enviaron correctamente, sus nuevos datos bancarios estarán disponibles una vez sean aprobados',
    },
    history: {
      header: 'MÉTODOS DE PAGO',
    },
    cc: {
      field: {
        ccNum: 'Número de la tarjeta de crédito',
        first6: 'Primeros seis',
        last4: 'Últimos cuatro',
        withrawlable: 'La cantidad máxima disponible para esta tarjeta es de {amount}.',
      },
      formValidation: {
        upload: 'La foto de la tarjeta de crédito es obligatoria',
      },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'Obteniendo el estado de su depósito... Quedan {countDown} segundos.',
      successMsg: 'Gracias por su depósito, sus fondos aparecerán en su cuenta en breve.',
      failureMsg: 'Su depósito no tuvo éxito. Por favor, vuelva a intentarlo.',
      message:
        'Gracias por añadir fondos en su cuenta. Su depósito será aprobado manualmente por el equipo de cuentas de {platform} en breve. Los fondos se depositarán en su cuenta una vez que sean aprobados',
      defaultException:
        'Para confirmar su depósito con {platform}, por favor contacte con el equipo de apoyo al cliente en {email}',
      rejectMsg: 'Rechazado',
    },
    failed: {
      countDownMsg: 'Obteniendo el estado de su depósito... Quedan {countDown} segundos.',
      rejectMsg: 'Rechazado',
    },
  },
  deposit: {
    alert: {
      depositBonus50: `Puede optar a recibir un bono por depósito del 50%. ¡Actívelo antes del depósito!`,
    },
    default: {
      deposit: 'Depositar',
      reference: 'Su referencia',
      sendReceipt: 'Enviar recibo',
      sendReceiptTip:
        'Cuando realice la transferencia, por favor, complete el siguiente formulario y adjunte una captura de pantalla, foto o copia escaneada del recibo bancario donde se muestre claramente su nombre completo, el número de cuenta y la cantidad de su depósito para que podamos procesar su transacción.',
      reminder: 'Por favor, espere de 1 a 2 días hábiles para que los fondos sean acreditados en su cuenta.',
      successMsg:
        'Los fondos aparecerán en su cuenta de trading una vez recibamos el depósito y verifiquemos el recibo de su transacción.',
      alt: 'También, puede enviar el recibo de su transacción por correo electrónico a {mailTo} lo antes posible.',
      reminderFundAppear: 'Gracias por su depósito, sus fondos aparecerán en su cuenta en breve.',
      failed: 'Depósito fallido, por favor inténtelo de nuevo más tarde.',
      fee: 'COMISIÓN',
      instant: 'INSTANTÁNEO',
      hour: 'HORA',
      hours: 'HORAS',
      businessDays: 'DÍAS LABORABLES',
      withinOneBusinessDays: 'EN EL PLAZO DE 1 DÍA LABORABLE',
      secondaryNGN:
        '(Puede utilizar este número de cuenta para esta transacción si prefiere utilizar la moneda de Nigeria Naira (NGN).)',
      disclaimer: `<li>All your payments to {platform} are confidential and are only used for deposit and withdrawal in your trading account of {platform}. All E-payments are processed by SSL (Secure Socket Layer) technology and encrypted to ensure the security.</li>
      <li>{platform} no acepta en ningún caso pagos de terceros. Todos los fondos depositados en su cuenta de trading deben tener el mismo nombre de titular que su cuenta del portal de cliente de {platform}.</li>
      <li>Client understands that submitting a deposit request does not constitute an acceptance of funds by {platform}. If your account is close to or in a margin call, please make sure you have other arrangements in place.</li>
      <li>{platform} will not be responsible for any delays or errors caused by payments systems.</li>
      <li>{platform} will not be responsible any fees that the sending financial institution or third-party payment providers may charge you for sending payments.</li>
      <li>Los clientes reconocen que se les puede solicitar información sobre el origen del depósito, el patrimonio de los clientes en general y cualquier otra información que {platform} considere necesaria para identificar el origen de los fondos al procesar una solicitud de depósito, y aceptan proporcionar toda la información que {platform} requiera. No proporcionar la información requerida puede provocar retrasos significativos en el procesamiento de sus solicitudes. {platform} no será responsable de los retrasos o pérdidas sufridas como resultado de dicho retraso.</li>`,
      channels: {
        creditOrDebit: 'Tarjeta de crédito/débito',
        i12BankTransfer: 'Transferencia bancaria internacional',
        unionPay: 'DEPÓSITO CON UNION PAY',
        unionPayCN: 'Transferencia da China Union Pay',
        thaiInstant: 'Transferencia bancaria instantánea de Tailandia',
        malayInstant: 'Transferencia bancaria instantánea de Malaysia',
        indonesiaInstant: 'Transferencia bancaria instantánea de Indonesia',
        philippineInstant: 'Transferencia bancaria instantánea de Philippine',
        indiaInstant: 'Transferencia bancaria instantánea de India',
      },
      rate: {
        rate: 'Tipo de cambio({oldCurrency}/{newCurrency}): ',
        result: {
          rateError: 'Error al obtener los tipos de {oldCurrency} a {newCurrency}.',
          channelError: 'Error al obtener el canal de pago.',
        },
      },
      cn: {
        rate: 'Tipo de cambio(USD/RMB): ',
        amt: 'RMB: ',
      },
      upgrade:
        'El canal de pago se encuentra actualmente en mantenimiento y solo está disponible para los usuarios que hayan depositado fondos con éxito a través de este canal anteriormente.',
      depositInstruction: 'Instrucciones de depósito',
      depositNote: 'Nota de depósito',
    },
    method: {
      desc: 'Cómo hacer un depósito en su cuenta con {method}',
      inst1: '{number}.   Complete el siguiente formulario y haga pulse en "Enviar"',
      inst2: `{number}.   Seleccione el banco en la página de selección de banco e inicie sesión en su banco online de {country}`,
      inst3: '{number}.   Siga las instrucciones y realice el pago a través de su banco online/móvil',
      inst4: '{number}.   Luego será redirigido a otro formulario para introducir los datos de su tarjeta',
      inst5: '{number}.   Después de introducir los datos de su tarjeta, pulse en "Enviar"',
      inst6: '{number}.   Será redirigido de nuevo al portal de cliente y su transacción se finalizará',
      inst7: `{number}.   Si su moneda no es {currency}, el banco puede cobrarle una tarifa de conversión de acuerdo con el tipo de cambio bancario actual.`,
      inst9: `{number}.   Un máximo de 1500 USD por depósito`,
      inst10: `{number}.   Aparecerá una página con los datos de pago`,
      inst11: '{number}.   Seleccione el banco en la página de selección de banco e inicie sesión en su banco online',
      inst12: '{number}.   Podemos aceptar el pago en CAD. Máximo 9.999 dólares canadienses por depósito',
      form: {
        header: 'Formulario de {method}',
      },
    },
    reminder: {
      inst1: 'Complete el siguiente formulario y haga pulse en "Enviar"',
      inst2: 'Acceda a su cuenta {method}',
      inst3: 'Siga las indicaciones de la cuenta {method} para completar su pago',
      inst4: 'Luego será redirigido a otro formulario para introducir los datos de su tarjeta',
      inst5: 'After entering your card details, press ‘Submit’',
      inst6: 'Será redirigido de nuevo al portal de cliente y su transacción se finalizará',
      inst7: 'Serás redirigido a una nueva página que muestra la dirección del billetera de {method}',
      inst8:
        'Copie la dirección del billetera y luego inicie sesión en su billetera personal {method} y transfiera la cantidad que desee a la dirección de {method}.',
      inst9: 'Escanee el código QR para abrir su aplicación bancaria móvil',
      inst10: 'Siga las indicaciones en su dispositivo móvil para hacer su depósito',
      inst11:
        'Por favor, asegúrese de pagar online a través de la página de pago redirigida para evitar cualquier pérdida de fondos.',
      inst12: 'Seleccione el banco en la página de selección de banco e inicie sesión en su banco online',
      inst13: 'Follow the instruction and make payment via your Online/Mobile Bank',
      inst14:
        'Cuando realice la transferencia, complete el siguiente formulario y adjunte una copia escaneada del recibo bancario donde se muestre claramente su nombre completo, el número de cuenta y la cantidad de su depósito para que podamos procesar su transacción.',
      inst15: `'Por favor, espere de 2 a 5 días hábiles para que los fondos se acrediten en su cuenta.'`,
      inst16: 'Por favor, espere 1 día laborable para que los fondos se abonen en su cuenta.',
      inst17:
        'Por favor, asegúrese de que su nombre completo, número de cuenta y cantidad depositada aparecen claramente para que podamos procesar su transacción.',
      inst18: 'Por favor, espere entre 1 y 6 horas para que los fondos se abonen en su cuenta.',
      note1:
        'Los depósitos de {method} están limitados a 25.000 USD por transacción y a un máximo de 75.000 USD al día.',
    },
    southAfrica: { header: 'Transferencia bancaria instantánea de Sudáfrica', country: 'Sudáfrica' },
    idn: { header: 'Transferencia bancaria instantánea de Indonesia', country: 'Indonesia' },
    ida: { header: 'Transferencia bancaria instantánea de India', country: 'India' },
    nigeria: { header: 'Transferencia bancaria instantánea de Nigeria', country: 'Nigeria' },
    thailand: { header: 'Transferencia bancaria instantánea de Tailandia', country: 'Tailandia' },
    malay: { header: 'Transferencia bancaria instantánea de Malasia', country: 'Malasia' },
    php: { header: 'Transferencia bancaria instantánea de Philippine', country: 'Philippine' },
    vietnam: { header: 'Transferencia bancaria instantánea de Vietnam', country: 'Vietnam' },
    korea: { header: 'Transferencia bancaria local de Corea del Sur' },
    uganda: { header: 'Transferencia bancaria local de Uganda', country: 'Uganda' },
    rwanda: { header: 'Transferencia bancaria local de Ruanda', country: 'Ruanda' },
    zambia: { header: 'Transferencia bancaria local de Zambia', country: 'Zambia' },
    congo: { header: 'Transferencia bancaria local del Congo', country: 'Congo' },
    cameroon: { header: 'Transferencia bancaria local de Camerún', country: 'Camerún' },
    burundi: { header: 'Transferencia bancaria local de Burundi', country: 'Burundi' },
    kenya: { header: 'Transferencia bancaria local de Kenia', country: 'Kenia' },
    ghana: { header: 'Transferencia bancaria local de Ghana', country: 'Ghana' },
    tanzania: { header: 'Transferencia bancaria local de Tanzania', country: 'Tanzania ' },
    creditOrDebit: {
      worldcard: {
        note1:
          'Los depósitos con tarjeta de crédito que utilicen EUR, GPB y USD están limitados a un importe máximo de 20.000 USD por transacción. Los depósitos con tarjeta de crédito que utilicen EUR, GPB y USD están limitados a un importe máximo de 20.000 USD o su equivalente por transacción.',
        note2:
          'El número de tarjetas diferentes por usuario es de 3. No se aceptará ningún intento de depósito que supere el límite.',
      },
      virtualPay: {
        note1:
          'Los depósitos con tarjeta de crédito que utilicen EUR, GBP, AUD, SGD, NZD, HKD, JPY, USC y USD están limitados a un máximo de 10.000 USD por transacción. ',
        note2:
          '50.000 USD o 10 transacciones como máximo por tarjeta en 24 horas. No se aceptará ningún intento de depósito que supere el límite.',
      },
      Sdpay: {
        note1:
          'Los depósitos con tarjeta de crédito que utilicen EUR, GBP y USD están limitados a un máximo de 2.000 USD o su equivalente por transacción.',
        note2: 'Máximo de 8 transacciones al día. No se aceptará ningún intento de depósito que supere el límite.',
        note3:
          'Tenga en cuenta que es posible que se aplique una retención a sus primeros pagos. Por favor, tenga paciencia mientras nuestro equipo verifica su pago, contacte con nuestro equipo de atención al cliente para obtener más ayuda.',
      },
      maxisPay: {
        note1: 'Los depósitos con tarjeta de crédito en USD están limitados a un máximo de 5.000 USD por transacción.',
      },
    },
    net: {
      header: 'Neteller',
      note1: 'Podemos aceptar pagos en AUD, USD, EUR, CAD y GBP',
      note2: 'Las cuentas sólo pueden financiarse en la misma divisa que su cuenta de trading de MT4.',
      note3:
        'Neteller convertirá automáticamente los fondos a la moneda base de su cuenta si se utiliza el email incorrecto. Pueden aplicarse comisiones.',
      note4: 'Los depósitos deben estar al mismo nombre que su cuenta de PU Prime.',
    },
    vload: {
      header: 'DEPÓSITO VLOAD',
      inst1: '2.   Aceptamos pagos en USD, EUR y JPY.',
      inst2: `<li>3.   Compre un cupón Vload, haga clic <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">aquí</a>.</li>`,
      form: {
        voucherpin: 'Pin del cupón',
        voucherpinReq: 'Se requiere PIN de bono',
      },
    },
    bankWireAu: {
      form: {
        form: {
          header: 'Transferencia bancaria (Australia)',
          instruction:
            'Los fondos se pueden transferir a {platform} a través del servicio de banca online de su institución financiera o contactando con su institución financiera y solicitando la transferencia a {platform}.',
          tip:
            'Seleccione la divisa de su cuenta en la que hará sus depósitos. Asegúrese de seleccionar la misma divisa que la divisa de su cuenta de trading',
          form: {
            bankDetails: 'Datos bancarios (solo depósito en AUD)',
            refTip: 'Por favor, especifique su número de usuario aquí',
            afp: ' Pago rápido australiano (solo depósito nacional en AUD)',
            payIdOrABNNum: 'Número PayID/ABN',
            payIdName: '{platformEntityName} Pty Ltd',
            bankTransferRefTip:
              'Incluya su número de cuenta de trading de {platform} en el campo de referencia de transferencia bancaria.',
          },
        },
      },
    },
    bPay: {
      header: 'DEPÓSITO CON BPAY',
      option: 'Cómo hacer un depósito en su cuenta con BPay (Australia solamente)',
      inst1: '1. Seleccione su cuenta MT4/MT5 para ver su código de acreedor y su número de referencia de BPay',
      inst2:
        '2. Introduzca los datos de pago, su código de acreedor y el número de referencia de BPay en su banco de internet y envíe su pago',
      inst3:
        '3. Vuelva a esta página y complete el siguiente formulario de BPay para asegurarse de que procesaremos su transacción rápidamente.',
      form: {
        header: 'FORMULARIO BPAY',
        billerCode: 'Código del acreedor de BPay',
        refNum: 'Número de referencia de BPayr',
        receipt: 'Recibo bancario de BPAY',
        reminderNotUploaded:
          'Nos hemos dado cuenta que no ha enviado su recibo de transacción. Para procesar su depósito, envíe una copia del recibo por correo electrónico a {mailTo} lo antes posible.',
        reminderProc:
          'Por favor, tenga en cuenta que: Su transacción NO SE PUEDE procesar hasta que recibamos su recibo.',
      },
    },
    b2b: {
      header: 'DE BROKER A BROKER',
      option: 'Cómo transferir fondos de otro broker a {platform}',
      inst1: '1. Descargar y completar el {download}',
      inst2: '2. Complete y envíe el siguiente formulario online de Transferencia de Broker a Broker',
      inst3:
        '3. Envíe una copia de su formulario de Broker a Broker completado a su broker anterior para iniciar la transferencia',
      b2bForm: 'Formulario de Broker a Broker',
      formValidation: {
        upload: 'El formulario de Broker a Broker es obligatorio',
      },
    },
    cc: {
      header: 'DEPÓSITO CON TARJETA DE CRÉDITO O DÉBITO',
      remind:
        'Si tiene alguna dificultad para hacer un depósito con su cuenta utilizando el método primario, utilice el método de crédito/débito secundario',
      field: {
        fundNow: 'Hacer un depósito con tarjeta de crédito/débito ahora',
        newCard: 'Nueva tarjeta',
        cardNum: 'Número de la tarjeta de crédito',
        nameOnCard: 'Nombre en la tarjeta',
        expDate: 'Fecha de vencimiento',
        cvv: 'Código de seguridad CVV',
        bankCode: 'Banco emisor de la tarjeta',
        example: `Tarjeta Sencilla`,
      },
      placeholder: {
        bankCode: 'Por favor, introduzca el nombre del banco emisor de la tarjeta',
      },
      cvvTip: 'Los 3 dígitos que aparecen en <br />el reverso de su tarjeta',
      threeDomainSecQ: '¿Su tarjeta es compatible con 3D Secure?',
      threeDomainSec:
        '¿Qué es la autenticación 3D Secure? <br /><strong>La autenticación 3D Secure es un método de prevención de fraude aprobado por Visa y Mastercard.<br /><br />Si su tarjeta es compatible con 3D Secure, sus fondos serán procesados de inmediato.</strong>',
      cardUpload:
        'La tarjeta que está utilizando para depositar fondos no se ha vinculado a su cuenta de trading. Por favor, envíe una foto del anverso de su tarjeta para verificarla. (Cubra los 6 dígitos del medio)',
      result: {
        success:
          'Gracias. Actualmente estamos verificando los datos de su tarjeta, y una vez que los fondos sean verificados aparecerán en su cuenta en un plazo de 24 horas.',
        depositSuccess: 'Gracias por su depósito, sus fondos aparecerán en su cuenta en breve.',
        error:
          'Para proteger la seguridad de su cuenta,<br> sus depósitos se han suspendido temporalmente ya que se ha alcanzado su límite de transacciones de depósito.<br> Por favor, contacte con {email}',
      },
      tipOnlyTaiwan: `Estimado cliente, tenga en cuenta que los retiros con tarjeta de crédito pueden retrasarse debido al procesamiento bancario, y la hora de llegada depende completamente de cada banco. Si tiene necesidades de fondos urgentes, se recomienda utilizar otros métodos para depositar / retirar.`,
      reminder: `<li>1. Los depósitos con tarjeta de crédito que utilizan EUR, GBP y USD están limitados a un máximo de $1,900 USD o monedas equivalentes por transacción.</li>
      <li>2. Máximo 8 transacciones por día.</li>
      <li>3. Tenga en cuenta que es posible que se aplique una retención en sus pagos por primera vez. Tenga paciencia mientras nuestro equipo verifica su pago, o puede comunicarse con nuestro equipo de atención al cliente para obtener más ayuda.</li>
      `,
    },
    fasa: {
      description: 'Cómo hacer un depósito en su cuenta con FasaPay',
      form: {
        header: 'FORMULARIO FASAPAY',
      },
    },
    intSwift: {
      header: 'TRANSFERENCIA BANCARIA: SWIFT INTERNACIONAL',
      desc:
        'Los fondos se pueden transferir a {platform} a través del servicio de banca online de su institución financiera o contactando con su institución financiera y solicitando la transferencia a {platform}.',
      desc2:
        'Cuando realice la transferencia, complete el siguiente formulario y adjunte una copia escaneada del recibo bancario donde se muestre claramente su nombre completo, el número de cuenta y la cantidad de su depósito para que podamos procesar su transacción.',
      alertSAandNGA:
        'Si tiene una moneda que no sea el dólar estadounidense, cambie la remesa a dólares estadounidenses antes de realizar la remesa. Si remite directamente, la diferencia de tipo de cambio y los gastos de gestión incurridos en ese momento correrán a cargo del cliente.',
      options: 'Por favor, seleccione la cuenta de trading con la que desea hacer el depósito',
      refInfo:
        'Incluya su número de cuenta de trading de {platform} en el campo de referencia de la transferencia bancaria.',
      bussDayInfo: 'Por favor, espere de 2 a 5 días hábiles para que los fondos se acrediten en su cuenta.',
      tip:
        'Asegúrese de que su recibo bancario muestre claramente su <b>nombre completo</b>, <b>el número de cuenta</b> y <b>la cantidad de su depósito</b>.',
    },
    bitwallet: {
      header: 'Bitwallet',
      note1: 'Aceptamos pagos en USD, AUD, EUR, USC y JPY',
    },
    astropay: {
      desc: 'Cómo hacer un depósito en su cuenta con ASTROPAY',
      note1: 'Aceptamos pagos en todas las divisas.',
      form: { header: 'FORMULARIO ASTROPAY' },
    },
    mobile: {
      header: 'MOBILE PAY',
      desc: 'Cómo hacer un depósito en su cuenta con Mobile Pay',
      inst1: '1. Complete el siguiente formulario y haga pulse en "Enviar"',
      inst2: '2. Escanee el código QR para abrir su aplicación bancaria móvil',
      inst3: '3. Siga las indicaciones en su dispositivo móvil para hacer su depósito',
      form: {
        payMethod: 'Método de pago:',
        rate: 'Tipo de cambio(USD/CNY):',
        amt: 'CNY:',
      },
      formValidation: {
        cnyAmt: 'La cantidad de CNY no puede ser superior a {limit} $',
      },
    },
    crypto: {
      header: 'DEPÓSITO CON CRIPTODIVISAS',
      choose: 'Por favor, elija su criptodivisa preferida',
      proceedQuestion: '¿Desea proceder con su depósito  <br /><b>{método}</b> ?',
      note:
        'Por favor, tenga en cuenta que no podemos depositar o retirar BUSDT. Por favor, asegúrese que la dirección y la criptodivisa coinciden con la cadena y divisa disponibles o podría resultar en una pérdida de fondos.',
      note2:
        'Por favor, tenga en cuenta que no podemos depositar ni retirar mediante BUSDT o ETH, asegúrese de que la dirección y la criptodivisa coinciden con la cadena y la moneda que aceptamos o puede perder los fondos. No seremos responsables de ninguna pérdida ocasionada por los errores.',
      remark:
        'Por favor, tenga en cuenta que no podemos depositar o retirar BUSDT. Por favor, asegúrese que la dirección y la criptodivisa coinciden con la cadena y divisa disponibles o podría resultar en una pérdida de fondos.',
      inst2: '2. Serás redirigido a una nueva página que muestra la dirección del billetera de {method}',
      inst3:
        '3. Copie la dirección del billetera y luego inicie sesión en su billetera personal {method} y transfiera la cantidad que desee a la dirección de {method}.',
      inst4:
        'Tenga en cuenta que: los depósitos de Bitcoin son procesados ​​por B2BinPay al tipo de cambio proporcionado en el momento de la transacción.',
      bitcoin: {
        inst1: '1. Complete el siguiente formulario y haga clic en "Enviar"',
        inst2: '2. Serás redirigido a una nueva página que muestra la dirección del billetera de bitcoin',
        inst3:
          '3. Copie la dirección del billetera y luego inicie sesión en su billetera personal Bitcoin y transfiera la cantidad que desee a la dirección de Bitcoin.',
        inst4:
          'Tenga en cuenta que: los depósitos de Bitcoin son procesados ​​por B2BinPay al tipo de cambio proporcionado en el momento de la transacción.',
      },
      usdt: {
        desc: 'Cómo hacer un depósito en su cuenta con TETHER(OMNI)',
        inst1: '1. Complete el siguiente formulario y haga pulse en "Enviar"',
        inst2: '2. Serás redirigido a una nueva página que muestra la dirección del billetera de Tether(OMNI)',
        inst3:
          '3. Copie la dirección de su billetera y luego inicie sesión en su billetera personal Tether(OMNI) y transfiera la cantidad que desee a la dirección de Tether(OMNI).',
        inst4:
          'Tenga en cuenta que: los depósitos de Tether(OMNI) son procesados ​​por B2BinPay al tipo de cambio será proporcionado en el momento de la transacción.',
      },
      usdt_CPS: {
        button: 'TETHER({name})',
        desc: 'Cómo hacer un depósito en su cuenta con TETHER({name})',
      },
      form: {
        header_bitcoin: 'FORMULARIO BITCOIN',
        header_usdt: 'FORMULARIO TETHER(OMNI)',
        header_usdt_CPS: 'FORMULARIO TETHER({name})',
      },
    },
    skrill: {
      header: 'DEPÓSITO CON SKRILL/MONEYBROOKERS',
      desc: 'Cómo hacer un depósito en su cuenta con Skrill',
      note1: 'Aceptamos pagos en USD, EUR, GBP y CAD',
      note2: 'Las cuentas sólo pueden financiarse en la misma divisa que su cuenta de trading de MT4.',
      note3:
        'Skrill convertirá automáticamente los fondos a la moneda base de su cuenta si se utiliza el email incorrecto. Pueden aplicarse comisiones.',
      note4: 'Los depósitos deben estar al mismo nombre que su cuenta de PU Prime.',
      form: {
        header: 'FORMULARIO SKRILL',
        email: 'Email de Skrill/Moneybrookers',
      },
      reminder: `<li>Tenga en cuenta que:</li>
      <li>* Las cuentas sólo pueden ser financiadas en la misma moneda que su cuenta de operaciones MT4. Skrill convertirá automáticamente los fondos a la moneda base de su cuenta si se utiliza el correo electrónico incorrecto. Se pueden aplicar tasas.</li>
      <li>* Los depósitos deben estar al mismo nombre que su cuenta de {platform}.</li>`,
    },
    sticPay: {
      header: 'STICPAY DEPOSIT',
      desc: 'Cómo hacer un depósito en su cuenta con STICPAY',
      inst1: '1. Complete el siguiente formulario y pulse "Enviar"',
      inst2: '2. Acceda a su cuenta SticPay',
      inst3: '3. Siga las indicaciones de la cuenta SticPay para completar su pago',
      form: {
        header: 'FORMULARIO STICPAY',
        email: 'Email de SticPay',
      },
    },
    unionPay: {
      header: 'UNION PAY DEPOSIT',
      desc: 'Cómo hacer un depósito en su cuenta con UnionPay',
      inst1: '1. Complete el siguiente formulario y haga clic en "Enviar"',
      inst2: '2. Seleccione el banco en la página de selección de banco e inicie sesión en su banco online',
      inst3: '3. Siga las instrucciones y realice el pago a través de su banco online/móvil',
      form: {
        header: 'FORMULARIO UNION PAY',
      },
    },
    unionPayCN: {
      header: 'Transferencia da China Union Pay',
      desc: 'Cómo hacer un depósito en su cuenta con China Union Pay',
      inst1: '1. Complete el siguiente formulario y haga clic en "Enviar"',
      inst2: '2. Aparecerá una página con los datos de pago',
      inst3: '3. Siga las instrucciones y realice el pago a través de su banco online/móvil',
      inst4:
        'Tenga en cuenta que: si se le pregunta un número de membresía, cuando haga el depósito, tendrá que introducir su número de cuenta de trading',
      inst1: 'Aparecerá una página de datos del pago',
      inst2:
        'Es posible que se le solicite su número de socio al realizar el ingreso. Por favor, introduzca el número de su cuenta de trading como número de socio.',
      note1:
        'El importe mínimo de la transacción por depósito es de 500 CNY y el importe máximo de la transacción por depósito es de 49.999 CNY.',
      note2:
        'Por favor, tenga en cuenta que el límite del importe del depósito puede actualizarse cada cierto tiempo en función del mercado.',
      note3:
        'Por favor, no copie los datos bancarios del beneficiario que aparecen, ya que pueden actualizarse cada cierto tiempo.',
      form: {
        header: 'FORMULARIO CHINA UNION PAY',
        holderName: 'Nombre del titular de la tarjeta UnionPay',
        holderNameInfo:
          'Asegúrese de que el nombre que introduzca arriba sea el mismo que el nombre del titular de su tarjeta bancaria',
      },
    },
    paypal: {
      header: 'PayPal',
      desc: 'Cómo hacer un depósito en su cuenta con PayPal',
      inst1: '1. Complete el siguiente formulario y haga clic en "Enviar"',
      inst2: '2. Acceda a su cuenta de PayPal',
      inst3: '3. Siga las indicaciones de la cuenta de PayPal para completar su pago',
      inst4: 'Tenga en cuenta que: no aceptamos pagos de terceros',
      form: {
        header: 'FORMULARIO DE PAYPAL',
      },
    },
    cardpay: {
      desc: 'Cómo hacer un depósito en su cuenta con tarjeta de crédito',
      inst1: '1. Complete el siguiente formulario y haga clic en "Enviar"',
      inst2: '2. Luego será redirigido a otro formulario para introducir los datos de su tarjeta',
      inst3: '3. Después de introducir los datos de su tarjeta, pulse en "Enviar"',
      inst4: '4. Será redirigido de nuevo al portal de cliente y su transacción se finalizará',
      form: {
        header: 'FORMULARIO PARA TARJETA DE CRÉDITO',
      },
    },
    tinkbit: { inst5: '5. Un máximo de 1500 USD por depósito' },
    perfectMoney: {
      inst1: '2.   Acceda a su cuenta Perfect Money',
      inst2: '3.   Siga las indicaciones de la cuenta Perfect Money para completar su pago',
    },
    indiaBankTransfer: {
      desc1:
        'En cuanto efectúe la transferencia, por favor, complete el siguiente formulario y adjunte una copia del recibo de la transferencia bancaria.',
      desc2:
        'Por favor, asegúrese de que su nombre completo, número de cuenta y cantidad depositada aparecen claramente para que podamos procesar su transacción.',
      bussDayInfo: 'Por favor, espere entre 1 y 6 horas para que los fondos se abonen en su cuenta.',
    },
    nigeriaBankTransfer: {
      bussDayInfo: 'Please allow 1-6 business days for the funds to be credited to your account.',
    },
  },
  restrictDeposit: {
    header: 'IMPORTANT NOTIFICATION',
    info: 'Unfortunately we are no longer accepting deposits from clients residing in Canada.',
  },
  withdraw: {
    title: 'RETIRAR FONDOS',
    default: {
      accountDetail: 'Datos de la cuenta',
      withdrawalAmount: 'Cantidad a retirar',
      example: 'Ejemplo de tarjeta de crédito/débito',
      alert:
        'Debido a las leyes de AML, el método que utilice para retirar debe ser el mismo que el utilizado para su depósito. Si la cantidad retirada es inferior a 100 unidades de la moneda base de su cuenta, el banco cobrará una comisión de 20 unidades por su manejo. ',
      how: '¿Cómo hacer un depósito en su cuenta de trading?',
      selectCC: 'Seleccione la tarjeta de crédito',
      selectBA: 'Seleccione la cuenta bancaria',
      anotherCC: 'Introduzca la tarjeta de crédito manualmente',
      anotherBA: 'Añadir cuenta bancaria',
      remember: 'Recuerda mi cuenta',
      success: 'Su solicitud de retirada se ha realizado con éxito y será procesada en breve.',
      ccFail:
        'Para proteger la seguridad de su cuenta, sus depósitos se han suspendido temporalmente ya que se ha alcanzado su límite de transacciones de depósito. Por favor, contacte con {email}',
      checkPosFailed: 'Error al comprobar las posiciones',
      fetchTradeAccFailed: 'Error al obtener la cuenta de trading',
      chooseAnother: `<p>Cantidad máxima de retiro para esta tarjeta: 0</p> 
      <p>Por favor, elija otra tarjeta o otra forma de pago</p>`,
      notEnoughFunds: 'El valor introducido supera el límite de esta tarjeta',
      cardExpird: 'Esta tarjeta ha caducado. Por favor, elija otra tarjeta u otra forma de pago.',
      unfortunate:
        'Desafortunadamente,  no podemos ma proscesar retiradas a tarjetas de crédito. En el futuro, las retiradas se procesarán a través de transferencia bancaria a su cuenta.',
      noCardWarn:
        'No tiene ninguna tarjeta disponible para realizar las retiradas, primero vincule su tarjeta en "Datos de retirada".',
      equityStop:
        'Desafortunadamente, su retirada no puede ser procesada porque si no el margen disponible de su cuenta estará por debajo del 100%',
      equityMargin:
        'Lamentablemente, actualmente no podemos procesar su retiro porque su cuenta está totalmente cubierta.',
      equityContinue:
        'Hemos detectado que actualmente tiene posiciones abiertas, puede haber un cierre de alguna de ellas después de enviar la solicitud de retirada, por favor, confirme',
      disclaimer: `<li>Retirar fondos mientras tiene posiciones abiertas afectará su margen. Asegúrese de cerrar las posiciones abiertas o asegúrese de dejar suficientes fondos en su cuenta para evitar una llamada de margen.</li>
      <li>{platform} no acepta en ningún caso pagos a terceros. Todos los fondos retirados desde su cuenta de trading deben tener el mismo nombre de titular que su cuenta del portal de cliente de {platform}</li>
      <li>Todas las solicitudes iniciales de retirada de fondos con tarjeta de crédito/débito, monedero electrónico, moneybookers y transferencias bancarias deben ser verificadas en cuanto a su seguridad mediante la presentación de un extracto bancario que incluya la información del titular de la cuenta y los datos bancarios.</li>
      <li>{platform} se reserva el derecho de negarse a procesar una solicitud de retiro si tiene motivos razonables para creer que la cuenta de operaciones puede convertirse en un saldo negativo después de que se procese la solicitud de retiro.</li>
      <li>{platform} se reserva el derecho de rechazar una solicitud de retiro si la cuenta comercial está sujeta a un contracargo o cualquier otra investigación en curso.</li>
      <li>{platform} no es responsable de ninguna tarifa que la institución financiera receptora o los proveedores de pagos externos puedan cobrarle por recibir los pagos.</li>`,
      YourPaymentGreaterThanAvailableBalance: 'Su pago es superior al saldo disponible',
      blackList: `Desafortunadamente actualmente no puede solicitar una retirada. Por favor, póngase en contacto con {supportEmail} para más información`,
      NetellerChargeAlert:
        'Los retiros de Neteller están limitados a $ 10,000 por transacción y se aplica una tarifa del 2% (con un límite de $ 30).',
      agreePolicy: 'He leído y aceptado la{link}',
      withdrawPolicy: 'política de retiradas',
      addWithdrawalMethod: 'Añada un método de retirada',
      addSecondaryMethod: 'Añada un método secundario',
      withdrawNote:
        'Por favor, tenga en cuenta que procesaremos su retirada principalmente mediante tarjeta de crédito. Si la cantidad de su tarjeta de crédito ha sido utilizada, le solicitaremos que elija otro método para finalizar su solicitud de retirada.',
      submitYourWithdrawal: 'Envíe su retirada',
      remainingWithdraw:
        'La cantidad restante a retirar se aplicará a otra tarjeta de crédito u otro método de retirada.',
      onlyWithdrawalCcDesc: `Solo una cantidad de {ccAmount} serán completamente retirados a la tarjeta de crédito.`,
    },
    fasa: {
      field: { accName: 'Nombre de cuenta de FasaPay', accNum: 'Número de cuenta de FasaPay' },
      formValidation: {
        accNameReq: 'El nombre de la cuenta de FasaPay es obligatorio',
        accNumReq: 'El número de la cuenta de FasaPay es obligatorio',
      },
      fasaPayChargeAlert: 'FasaPay cobra una tarifa de transacción del 0,5% por cada retiro.',
    },
    neteller: {
      field: { email: 'Email de Neteller' },
      formValidation: { emailReq: 'El email de Neteller es obligatorio' },
    },
    vload: {
      field: { email: 'Email registrado de Vload' },
      formValidation: { emailReq: 'Se requiere email de Vload registrado' },
    },
    bitcoin: {
      field: { address: 'Dirección de la billetera de Bitcoin' },
      formValidation: {
        addressReq: 'La dirección de la cartera de Bitcoin es obligatoria',
        addressFormat: `La dirección de tu monedero debe empezar por "1", "3" o "bc1"`,
        addressLength: 'La dirección de la cartera de Bitcoin debe tener entre 26 y 42 caracteres',
      },
    },
    eth: {
      field: { address: 'Dirección de la billetera de ETH' },
      formValidation: {
        addressReq: 'La dirección de la cartera de ETH es obligatoria',
        addressFormat: 'La dirección de la cartera de ETH es obligatoria',
        addressLength: 'La dirección de la cartera de ETH debe tener entre 26 y 42 caracteres',
      },
    },
    usdc: {
      field: { address: 'Dirección de la billetera de USDC' },
      formValidation: {
        addressReq: 'La dirección de la cartera de USDC es obligatoria',
        addressFormat: 'La dirección de la cartera de USDC es obligatoria',
        addressLength: 'La dirección de la cartera de USDC debe tener entre 26 y 42 caracteres',
      },
    },
    usdt: {
      field: { address: 'Dirección de la cartera de USDT' },
      formValidation: {
        addressReq: 'La dirección de la cartera de USDT es obligatoria',
        trc20WalletFormatIncorrect: `La dirección de tu monedero debe empezar por "T"`,
        erc20WalletFormatIncorrect: `La dirección de tu monedero debe empezar por "0x"`,
      },
    },
    bitwallet: {
      field: {
        email: 'Email de Bitwallet',
      },
      formValidation: {
        emailReq: 'El email de Bitwallet es obligatorio',
      },
    },
    astropay: {
      field: {
        accountNumber: 'Cuenta de Astropay',
      },
      formValidation: {
        accountNumberReq: 'Se requiere cuenta de Astropay',
      },
    },
    paypal: {
      field: {
        email: 'Email de PayPal',
      },
      formValidation: {
        emailReq: 'El email de PayPal es obligatorio',
      },
    },
    skrill: {
      field: { email: 'Email de Skrill' },
      formValidation: { emailReq: 'El email de Skrill es obligatorio' },
      skrillChargeAlert: 'Skrill cobra una tarifa de transacción del 1% por cada retiro.',
    },
    sticpay: {
      field: { email: 'Email de SticPay' },
      formValidation: { emailReq: 'El email de SticPay es obligatorio' },
    },
    perfectMoney: {
      field: { email: 'Email de Perfect Money' },
      formValidation: { emailReq: 'El email de Perfect Money es obligatorio' },
    },
    transfer: {
      info:
        '{platform} reembolsará la comisión bancaria cobrada por <u>UNA</u> retirada al mes. Las retiradas adicionales durante el mes tendrán en una comisión bancaria de 20 unidades de su divisa de retirada.',
      label: {
        upload:
          'Por favor, envíe una copia de su extracto bancario (actualizado con una antigüedad no superior a 3 meses)',
      },
      formValidation: {
        upload: 'Por favor, envíe un extracto bancario',
        emailReq: 'correo electronico es requerido',
      },
    },
    otherMethod: {
      withdrawalMethod: 'Método de retirada',
      manageWithdrawalDetails: 'Gestionar datos de retiradas',
      verificationCode:
        'El código de verificación ha sido enviado a {email}. Por favor, contacte con {supportEmail} si necesita más ayuda',
    },
  },
  transfer: {
    creditWarn:
      'No podemos procesar la transferencia de sus fondos desde la cuenta seleccionada porque su cuenta contiene crédito promocional.',
    blackListWarn:
      'La transferencia de sus fondos desde su cuenta MAM puede dar lugar a la liquidación de posiciones gestionadas. Si desea transferir sus fondos desde esta cuenta, por favor, contacte con {mailTo}.',
  },
  changePw: {
    header: 'CAMBIAR CONTRASEÑA',
    field: {
      currPw: 'Contraseña actual',
      newPw: 'Nueva contraseña',
      confirmPw: 'Confirmar nueva contraseña',
    },
    succ:
      'Su contraseña ha sido actualizada. Ahora puede utilizar su nueva contraseña para acceder al "Portal de cliente".',
  },
  promotion: {
    clickToGet: 'Simplemente haga clic y obtenga su bono de $ 50',
    apply: 'Aplica ya',
    start: 'Empezar ahora',
    tnc: `
    <li> 1. Esta oferta está disponible por 30 días.</li>
    <li> 2. Esta oferta está disponible para todos los clientes (existentes y nuevos) que tienen una cuenta real pero que nunca antes hicieron un depósito.</li>
    <li> 3. Esta oferta está disponible para nuevos clientes en las siguientes áreas: Francia, Reino Unido, Taiwán, Italia, Canadá, Bélgica, Suiza, Hong Kong, Nigeria, Países Bajos, Japón, Luxemburgo, Malta, Reunión, Alemania, Martinica, España, Letonia, Polonia, Costa de Marfil, Dinamarca, Brasil, Nueva Zelanda , Portugal, Isla de Man, Sudáfrica, Turquía, Rumania, Guadalupe, Austria, Irlanda, Guayana Francesa, Suazilandia, Argentina, Ucrania, Grecia, Suecia, Mónaco, Hungría, Haití, Finlandia, Bolivia, Chile, Costa Rica, Croacia, República Checa, Estonia, México, Noruega, Filipinas.</li>
    <li> 4. Esta oferta es elegible solo en cuentas estándar y cuentas estándar islámicas.</li>
    <li> 5. Está prohibido obtener bonos y / o fondos de bonificación comercial EN DOS O MÁS CUENTAS con la misma dirección IP / ID / pasaporte. Independientemente del número de cuentas comerciales que un Cliente pueda tener con PU Prime, la promoción es aplicable solo para una cuenta.</li>
    <li> 6. Los clientes recibirán créditos de 50 USD (o su equivalente) después de aceptar esta oferta con éxito y no es necesario realizar un depósito.</li>
    <li> 7. Los montos del crédito no se pueden retirar. Sin embargo, aún puede retirar parte de las ganancias obtenidas de sus operaciones.</li>
    <li> 8. Debe cerrar todas las posiciones abiertas antes de solicitar el retiro. Una vez que opte por retirar, el bono se deducirá de la cuenta.</li>
    <li> 9. PU Prime se reserva el derecho de cambiar las condiciones o negarse a otorgar un bono en cualquier momento.</li>`,
    selectDesp: '* Seleccione la cuenta en la que le gustaría unirse a la actividad de bonificación sin depósito.',
    descp1: '¿Quiere empezar a operar sin comprometer una inversión desde el principio?',
    descp2: `Aplique su bono de $ 50 para comenzar <br>a obtener ganancias sin arriesgar sus fondos en absoluto.`,
    header: 'TÉRMINOS Y CONDICIONES DE LA PROMOCIÓN',
    tnc2:
      '<li> La cantidad mínima que puede retirar es 100 USD(o equivalente), y la cantidad máxima para retirar es 200 USD(o equivalente). Cualquier beneficio obtenido además de esta cantidad limitada se eliminará cuando procesemos su solicitud de retiro.</li> ',
    successful: 'Enviado satisfactoriamente',
    failed: 'Envío fallido',
    referFriends: {
      intro: `Complete los siguientes 3 pasos para obtener {amount}`,
      step1: 'primer paso',
      step1Descp: 'Abrió una cuenta con éxito',
      step2: 'Segundo paso',
      step2Descp: 'Depósito exitoso',
      step3: 'tercer paso',
      step3Descp: 'Volumen de la oferta',
      totalEanringTitle: `Monto total de retiro <br/>(USD o moneda equivalente)`,
      totalEarningTip: '* El monto de la recompensa está sujeto a revisión.',
      redeem: 'extraer',
      intro2: 'Al mismo tiempo, también puedes recomendar a tus amigos',
      shareLink: 'Comparte tu enlace de recomendación personalizado',
      shareLinkDescp:
        'Haga clic en "Compartir" para obtener un enlace de recomendación único y copiarlo en varias redes sociales.',
      share: 'Compartir',
      total: 'Total de referencias canjeadas',
      social: 'Comparta su enlace a través de las principales redes sociales',
      clients: 'cliente',
      table: {
        referralsNo: 'Número recomendado',
        name: 'Nombre',
        country: 'país',
        openAccount: 'Abrió una cuenta con éxito',
        despositSuccess: 'Depósito exitoso',
        volumeCompleted: 'Volumen de la oferta',
      },
      totalRedeemableTitle: `Total redeemable earnings  <br/>(USD u otra moneda equivalente)`,
      totalRedeemableTip: '* El monto de la recompensa está sujeto a revisión.',
      referrer: 'Referente',
      referral: 'Árbitro',
      dialog: {
        confirmQ: 'Canjee sus ganancias',
        confirmQ2: 'El monto total convertido a su moneda base es {amount} {currency}.',
        redeem: 'Retirar ahora',
        agree: '* Al hacer clic en canjear ahora, acepta todos los {link}.',
        overBonus:
          'Nuestro sistema ha detectado que has alcanzado nuestra cantidad máxima. Para reclamos, comuníquese con nuestro servicio de atención al cliente.',
      },
    },
    depositBonus: {
      banner: '¡RECLAMA TU BONO POR DEPÓSITO DEL 50%!<br>   OBTENGA HASTA $ 10,000',
      depositBonus: 'RECLAMA TU BONIFICACIÓN Y AUMENTA TU COMERCIO',
      title:
        '¿Quiere aumentar el margen de su cuenta y aumentar sus volúmenes de operaciones? ¡Entonces ingrese fondos en su cuenta hoy y reclame el bono del 50% de nuestra parte!',
      fund: 'Deposite fondos en su cuenta',
      tnc: '* Se aplican términos y condiciones, {link} ',
      link: 'haga clic aquí para ver ',
      bonusHistory: 'HISTORIA ADICIONAL',
      overBonus:
        '* Ha alcanzado el máximo de ${totalLimit} en créditos de bono. Los depósitos adicionales no optarán a créditos de bono.',
      opt: 'CONFIRMACIÓN DE OPT-IN Y OPT-OUT',
      tick: 'Desmarque la casilla si desea optar por no participar o marque la casilla si desea participar.',
      saveChange: 'Guardar cambios',
      depositDate: 'Fecha de deposito',
      credit: 'Crédito',
      optIn: 'Acepte participar con éxito',
      optOut: 'Exclusión exitosa',
      optInDescription: 'Haga clic en el botón de abajo para optar por el bono por depósito del 50%.',
      optOutDescription: 'Haga clic en el cuadro para optar por la recompensa.',
      activate: 'Activar',
      signOut: 'desconectar',
      banner1: '¡RECLAMA TU BONO POR DEPÓSITO DEL 50%!',
      claimDescription: `<p>Cómo reclamar</p>
      <li>1. Activar antes de depositar. </li>
      <li>2. Financiar su cuenta de comercio para calificar para la recompensa. </li>
      <li>3. La recompensa se financiará a su cuenta automáticamente. </li>
      <li>4. Disfruta de tu trading. </li>`,
      eligibleDescription: `<p>Elegibilidad</p>
      <li>1. La recompensa debe ser activada antes de depositar. </li>
      <li>2. La recompensa solo está disponible para la cuenta estándar, la cuenta estándar islámica, la cuenta principal, la cuenta primordial islámica. </li>
      <li>3. Solo puede solicitar la bono de crédito del 50% de la relación una vez, y todos los depósitos posteriores solo se calculan de acuerdo con la proporción del 20%. </li>`,
      optInDialog: `¡Felicidades! Ha activado con éxito el bono por depósito del 50%.`,
      optOutDialog: `Ha optado por no recibir el bono por depósito del 50%.`,
    },
    cryptoCashback: {
      banner: {
        bannerContext: `
          <span class="title">Duplique sus ahorros</span></br>
          <span class="desc">Obtenga hasta 10.000 dólares en reembolsos y CERO comisiones de swap</span>
        `,
        activityBannerContext: `
          <span class="title">Doble sus ahorros</span></br>
          <span class="desc">¿Va en corto con la tendencia bajista o aprovecha para comprar con la caída?</span></br>
          <span class="desc">Consiga un reembolso de hasta 10.000 $ cuando opere con criptodivisas en PU Prime!</span>
        `,
      },
      active: {
        title: 'Activar reembolso de criptodivisas',
        desc: 'por favor, haga clic en el siguiente botón para activar y participar en la actividad',
      },
      deactive: {
        title: `Desactivar el reembolso de criptomonedas`,
        desc: `Haga clic en el botón a continuación para desactivar y retirarse de la promoción de reembolso de criptomonedas.`,
      },
      button: {
        active: 'Activar',
        deactive: 'Desactivar',
        submit: 'Enviar',
        fund: 'Deposite fondos en su cuenta',
      },
      claimTitle: 'CÓMO RECLAMARLA',
      claimDescriptions: [
        `Active la promoción antes de hacer un depósito.`,
        `Añada fondos en su cuenta de trading para cumplir con los requisitos de depósito （≥ 500 USD).`,
        'El reembolso por lote dependerá de la cantidad total neta del depósito (ver términos y condiciones).',
        '¡Disfrute del trading y obtenga más valor con cada operación!',
      ],
      eligibilityTitle: 'ELIGIBILIDAD',
      eligibleDescriptions: [
        `Esta promoción debe activarse antes de hacer un depósito.`,
        `Esta promoción solo está disponible para cuentas estándar y cuentas estándar islámicas. Si un cliente tiene varias cuentas de trading elegibles, solo puede seleccionarse UNA cuenta elegible para participar en esta promoción.`,
        `Esta promoción dura 30 días a partir del primer depósito exitoso tras la activación.`,
        `El reembolso se basa en el volumen negociado en todos los productos de criptodivisas exceptuando los siguientes: ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU.`,
        `El reembolso total tiene un límite máximo de 10.000 $ por cliente.`,
      ],
      tnc: '* Se aplican términos y condiciones, {link} ',
      link: 'haga clic aquí para ver ',
      message: {
        congratulate: `¡Enhorabuena!`,
        congratulateContext: `Ha activado con éxito la promoción de reembolso de criptodivisas.`,
        signedOutPromo: `Al hacer clic en el botón Confirmar, se dará de baja de la promoción.`,
        deactivePromo: `Ha desactivado con éxito la promoción de reembolso de criptodivisas.`,
      },
      dialog: {
        selectAccountContext: `Por favor, seleccione la cuenta con la que desea unirse a la actividad del bono de trading`,
        tnc: `Términos y condiciones`,
        tncContext: {
          tnc1: `1. Después de activar esta promoción, también debe depositar fondos y operar con criptodivisas tal y como se requiere para recibir el reembolso correspondiente.`,
          tnc1desc: `* Solo los depósitos nuevos efectuados durante el plazo de la promoción son válidos para la promoción. Esto excluye transferencias internas, bonos por referir a un amigo y ajustes en efectivo.`,
          tnc2: `2. Los clientes elegibles (nuevos y ya existentes) que cumplan con los criterios establecidos en la presente pueden recibir un reembolso equivalente a:`,
          tnc3: `3. Los productos elegibles: todos los productos de criptodivisas a excepción de ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU.`,
          tnc4: `4. El volumen negociado en efectivo: las nuevas órdenes abiertas y cerradas durante el plazo de la promoción. Las posiciones abiertas durante menos de 5 minutos, las posiciones bloqueadas y las posiciones de cobertura no cuentan como operaciones válidas para esta promoción.`,
          tnc5: `5. Duración de la promoción: 30 días a partir de que los participantes efectúen el primer depósito exitoso.`,
          tnc5desc: `* Si el cliente activa la promoción y no ha hecho ningún depósito, la promoción finalizará también automáticamente transcurridos 30 días.`,
          tnc6: `6. Esta oferta no puede utilizarse junto con ninguna otra oferta. （Excepto la campaña “Usted también puede ser un operador”, Referir a un amigo y el bono por depósito del 50%)`,
        },
        agreeTnc: `He leído y acepto los términos y condiciones`,
      },
      field: {
        netDeposit: `Depósito neto`,
        usddesc: `USD (o su equivalente en divisas)`,
        cashback: `Reembolso`,
        standardLot: `lote estándar`,
        date: `Fecha`,
        depositAmount: `Monto del depósito`,
        withdrawAmount: `Monto de la retirada`,
        netDepositAmount: `Cantidad neta del depósito`,
        rebateLot: `Reembolso / Lote`,
        tradingDate: `Fecha de trading`,
        tradingLot: `Lote de trading`,
        cashRebate: `Reembolso en efectivo`,
      },
      depositHistory: `HISTORIAL DE DEPÓSITOS`,
      tradingRewardHistory: `HISTORIAL DE RECOMPENSAS DE TRADING`,
    },
    xmasMakeAWish: {
      banner: {
        bannerContext: `
          <div class="title">¡Gane premios valorados en hasta 5.000 $!</div>
          <div class="desc">En nuestro sorteo de la suerte con premio seguro</div>
        `,
      },
      active: {
        title: 'ACTIVAR LA PROMOCIÓN PIDA UN DESEO',
        desc: 'Por favor, haga clic en el siguiente botón para activar la promoción de Navidad Pida un deseo',
      },
      deactive: {
        title: 'Promoción de Navidad Pida un deseo',
        desc: 'Por favor, haga clic en el siguiente botón para desactivar la promoción de Navidad Pida un deseo',
      },
      button: {
        active: 'Activar',
        deactive: 'Desactivar',
        submit: 'Enviar',
        fund: 'Añada fondos a su cuenta',
        enterLuckyDraw: 'Participar en el Sorteo de la suerte',
        redeem: 'Canjear',
        redeemed: 'Canjeado',
      },
      claimTitle: 'CÓMO RECLAMAR',
      claimDescriptions: [
        'Active la promoción antes de hacer un depósito.​​',
        'Añada fondos en su cuenta de trading para cumplir con los requisitos de depósito del regalo que desee, y después efectúe el número de operaciones requeridas correspondiente. (Consulte la siguiente tabla de regalos).',
        'Si es eligible, tendrá la oportunidad de ganar uno de los regalos en su correspondiente nivel. (Consulte la siguiente tabla de regalos).',
        'Haga clic en el botón Participar en el sorteo de la suerte para participar por su regalo.',
        'Los regalos ganados se registrarán y se distribuirán 7 días después del fin de la promoción.',
      ],
      eligibilityTitle: 'ELIGIBILIDAD',
      eligibleDescriptions: [
        'Tipos de cuenta que pueden participar: Esta oferta SOLO está disponible para cuentas estándar y cuentas estándar islámicas, ambas en MT4/MT5.',
        'Si un cliente tiene varias cuentas estándar, solo puede seleccionar una cuenta estándar para participar en esta promoción.',
        'Países participantes: Todos excepto China continental',
        'Los depósitos y las operaciones deben efectuarse durante el plazo de la promoción para ser considerados válidos.',
        'Volumen de trading válido: Posiciones en Forex, oro, plata y petróleo crudo mantenidas durante más de 5 minutos.',
      ],
      moreDetail: 'Para conocer más detalles sobre la distribución de premios, consulte la {prize}.',
      prize: 'lista de premios aquí',
      tnc: '*Se aplican los términos y condiciones. Haga clic {link} para ver',
      link: 'aquí',
      luckyDraw: {
        description: [
          `¡Pulse el botón del Sorteo de la suerte para ganar <span class="font-bold">uno</span> de los anteriores premios!`,
          '¿No son los regalos que busca? Continúe haciendo depósitos y operando para llegar al nivel deseado.',
        ],
        mainTitle: 'Su nivel de regalos actual:',
        commingSoon: {
          title: `Pida un deseo`,
          content: `Próximamente, a mediados de noviembre, para participar en el sorteo.`,
        },
        noChanceToDraw: {
          title: `Depositar ahora`,
          content: `Deposite fondos en su cuenta por un valor mínimo de 200 $ para participar en el sorteo`,
        },
        endPromotion: {
          title: `El sorteo de la suerte ha terminado`,
          content: `¡Gracias por su participación! Asegúrese de canjear sus regalos antes del 31 de marzo de 2023.`,
        },
      },
      currentDepositLots: `Depósito actual / Lotes negociados`,
      transactionHistory: `HISTORIAL DE TRANSACCIONES`,
      transactionHistoryDesc: `*La transacción de depósito es la cantidad depositada y el valor del depósito deducido al participar en el sorteo de la suerte.`,
      giftRedemption: `CANJE DE REGALOS`,
      giftRedemptionDesc: [
        `Nos pondremos de nuevo en contacto usted para el canje del regalo físico.`,
        `*Los premios solo pueden canjearse del 2 de febrero de 2023 al 2 de marzo de 2023.`,
      ],
      field: {
        depositAmount: `Cantidad del depósito`,
        lotsTraded: `Lotes negociados`,
        date: `Fecha`,
        type: `Tipo`,
        deposit: `*Depósito`,
        tradingLots: `Lotes de trading`,
        prizeWon: `Premio(s) ganado(s)`,
        creditBonus: `Bono de crédito`,
        cashVoucher: `Vale en efectivo`,
        physicalRewards: `Recompensas físicas`,
        depositTradingVolume: `Depósito / Lotes de trading`,
        gifts: `Regalos (USD)`,
        accountID: `ID de cuenta*`,
        current: `Actual`,
        luckyDrawDeposit: `Depósito`,
      },
      dialog: {
        selectAccountContext: `Por favor, seleccione la cuenta en la que desea recibir su bono de crédito (solo cuenta MT4/MT5)`,
        tnc: 'TÉRMINOS Y CONDICIONES',
        tncContext: {
          tnc1: `1. Primero los clientes deben activar con éxito esta promoción en su portal de cliente, y después depositar fondos y operar tal y como se requiere para recibir los regalos correspondientes.`,
          tnc2: `2.	Volumen de trading efectivo: Lotes estándar de pares de divisas, oro, plata, spot de petróleo crudo y futuros de petróleo crudo. Las órdenes deben abrirse y cerrarse durante el plazo de la promoción. Las posiciones abiertas durante menos de 5 minutos, las posiciones bloqueadas y las posiciones de cobertura no cuentan como operaciones válidas para esta promoción.`,
          tnc3: `3.	El volumen de órdenes y las estadísticas se extraen del horario de MT4 del 01/11/2022 al 31/01/2023.`,
          tnc4: `4.	Si el regalo no puede adquirirse debido a causas de fuerza mayor como que el producto no esté disponible o esté agotado, el cliente puede solicitar recibir el equivalente en efectivo del precio de mercado de dicho regalo. Este precio se basa en el precio del dólar estadounidense (o su equivalente en otra divisa).`,
          tnc5: `5.	Esta oferta no puede utilizarse junto con ninguna otra oferta. （bono de trading de 100，bono exclusivo del 20%, bono por depósito del 50%，bono por depósito del 20% VN).`,
        },
        agreeTnc: `He leído y acepto los términos y condiciones`,
        prizeList: `lista de premios`,
        luckyDrawTransaction: `TRANSACCIÓN DEL SORTEO DE LA SUERTE`,
        xmasGift: `¡ENHORABUENA POR SU REGALO DE NAVIDAD!`,
      },
      message: {
        congratulate: '¡Enhorabuena!​',
        congratulateContext: `Ha activado con éxito la promoción Promoción Pida un deseo`,
        signedOutPromo: `¡Al hacer clic en el botón Confirmar, se dará de baja de la promoción!`,
        deactivePromo: `Se inscrito con éxito en la promoción de Navidad Pida un deseo.`,
        redeemAmount: `¡La cantidad se abonará en su cuenta!`,
      },
      tableContent: {
        luckyDraw: `Sorteo de la suerte`,
        depositTrade: `Depositar / Operar`,
      },
    },
    firstTimeDeposit: {
      banner: {
        bannerContext: `
          <div class="title">Consiga <span class='bg_yellow'>Efectivo al instante</span> con su primer depósito</div>
          <div class="desc">¡Consiga hasta 50 $ en efectivo al hacer su primer depósito con PU Prime!</div>
        `,
      },
      active: {
        title: `Active SU reembolso FTD`,
        desc: `Por favor, haga clic en el siguiente botón para activar y participar en la promoción.`,
      },
      deactive: {
        title: `Desactive SU reembolso FTD`,
        desc: `Por favor, haga clic en el siguiente botón para desactivar y retirarse de la promoción de reembolso FTD.`,
      },
      button: {
        active: `Activar`,
        deactive: `Desactivar`,
        fund: `Añada fondos en su cuenta`,
        redeem: `Canjear`,
      },
      claimTitle: `CÓMO RECLAMAR`,
      claimDescriptions: [
        `Active la promoción antes de hacer un depósito.​`,
        `Añada fondos en su cuenta de trading para cumplir con los requisitos de depósito requeridos para el reembolso que desee (≥ 200 USD o su equivalente en otra divisa)​.`,
        `El valor del vale de reembolso dependerá de la cantidad de su primer depósito (ver tabla de reembolsos en los términos y condiciones).`,
        `Para activar su vale de reembolso, opere con 2 o más lotes de FX, oro, plata o petróleo.`,
        `Ahora puede canjear su vale activado. Esta cantidad se reflejará en el saldo de su cuenta.`,
      ],
      eligibilityTitle: `ELIGIBILIDAD`,
      eligibleDescription1: `Tipos de cuenta que pueden participar: Esta oferta SOLO está disponible para cuentas estándar y cuentas estándar islámicas, ambas en MT4/MT5.`,
      eligibleDescription2: `Si un cliente tiene varias cuentas estándar, solo puede seleccionar una cuenta estándar para participar en esta promoción.`,
      eligibleDescription3: `Países que pueden participar: Todos excepto China continental`,
      eligibleDescription4: `Clientes que pueden participar: Clientes nuevos y ya existentes que no tengan ningún historial de depósitos, incluyendo clientes bajo IBs/CPAs.`,
      eligibleDescription4Desc: `*Las cuentas de trading personales de IBs/CPAs no pueden participar`,
      eligibleDescription5: `El primer depósito debe efectuarse durante el plazo de la promoción.`,
      eligibleDescription6: `Las órdenes abiertas durante menos de 5 minutos no se consideran válidas para la promoción.`,
      tnc: `*Se aplican los términos y condiciones, haga {link} para verlos`,
      link: `clic aquí`,
      dialog: {
        selectAccountContext: `Por favor, seleccione la cuenta con la que desea unirse a la actividad del bono de trading`,
        tnc: `Términos y condiciones`,
        tncContext: {
          tnc1: `1. Tras activar esta promoción, debe depositar fondos y operar con 2 lotes estándar tal y como se requiere para recibir el reembolso correspondiente.`,
          tnc1desc: `*Solo los nuevos depósitos efectuados durante el plazo de la promoción son válidos para la misma. Esto excluye transferencias internas, bonos por referir a amigos y ajustes de efectivo.`,
          tnc2: `2.	Solo el primer depósito efectuado durante este periodo contará para calcular el reembolso.`,
          tnc3: `3.	Los clientes elegibles (tantos clientes nuevos como ya existentes) que cumplan con los criterios detallados en la presente pueden recibir el reembolso, equivalente a:`,
          tnc4: `4.	Productos elegibles: Forex, oro, plata, petróleo.`,
          tnc5: `5.	Volumen de trading efectivo: Nuevas órdenes abiertas y cerradas durante el plazo de la promoción. Las posiciones abiertas durante menos de 5 minutos, las posiciones bloqueadas y las posiciones de cobertura no cuentan como operaciones válidas para esta promoción.`,
        },
        agreeTnc: `He leído y acepto los términos y condiciones`,
        grossDeposit: `Depósito en USD bruto`,
        grossDepositDesc: `o su equivalente en otra divisa`,
        cbVoucher: `Vale de reembolso`,
        tableDesc: `* Los clientes pueden canjear un vale en efectivo de hasta 50 $ (o su equivalente).`,
      },
      voucher: {
        title: `Reembolso FTD`,
        accountID: `ID de cuenta*`,
        accumulateVolume: `Volumen de trading acumulado`,
        standardLots: `Lotes estándar`,
        desc: `Opere con 2 o más lotes de FX, oro, plata o petróleo para activarlo`,
        clickForRedeem: `Haga clic en el siguiente botón para enviarlo al saldo de su cuenta.`,
        ftdLessThan200: `Su depósito inicial es inferior a la cantidad mínima requerida.`,
      },
      message: {
        congratulate: `¡Enhorabuena!​`,
        congratulateContext: `Ha activado con éxito la promoción de reembolso FTD.`,
        signedOutPromo: `¡Al hacer clic en el botón Confirmar, se dará de baja de la promoción!`,
        deactivePromo: `Ha desactivado con éxito la promoción de reembolso FTD.`,
        successRedeem: `Ha canjeado con éxito su reembolso de por primer depósito.`,
      },
    },
    notification: {
      ndb: {
        inst1: 'Estimado cliente valioso,',
        inst2: `Le recordamos que la promoción de bonificación sin depósito finalizará <span>{date} (GMT+3)</span> .`,
        inst3:
          'De acuerdo con los Términos y Condiciones de la Promoción, tenga en cuenta que la bonificación en su cuenta depositada se eliminará automáticamente una vez que finalice la promoción. Para aquellas cuentas sin depósito o registro de transacciones, el bono y el saldo también se eliminarán automáticamente una vez que finalice la promoción.',
        inst4: `Si tiene alguna pregunta o necesita ayuda, comuníquese con nuestro equipo de soporte a través del chat en vivo, correo electrónico: <span>{mail}</span>, o llame al <span>{phone}</span>.`,
        cantInerTransfer:
          'Dado que participa en nuestra campaña NDB, no podemos procesar su solicitud de transferencia',
      },
      db: {
        inst1: 'Estimado cliente valioso,',
        inst2: `Nos gustaría recordarle que la promoción del bono de depósito del 50% finalizará el <span>{date} (GMT+3)</span> .`,
        inst3:
          'De acuerdo con los Términos y Condiciones de la Promoción, tenga en cuenta que la bonificación en su cuenta depositada se eliminará automáticamente una vez que finalice la promoción. Para aquellas cuentas sin depósito o sin registro de transacciones, el bono y el saldo se eliminarán automáticamente una vez que finalice la promoción.',
        inst4: `Si tiene alguna pregunta o necesita ayuda, comuníquese con nuestro equipo de soporte a través del chat en vivo, correo electrónico: <span>{mail}</span> o al teléfono <span>{phone}</span>.`,
      },
    },
    editSuccess: '',
  },
  downloads: {
    mt4: 'METATRADER 4',
    mt4Desc: 'Download MetaTrader 4 For PC, web browsers, smartphones, and tablets',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android/Tablet',
    web: 'WebTrader',
    mt5: 'METATRADER 5',
    mt5Desc: 'Download MetaTrader 5 For PC, web browsers, smartphones, and tablets',
  },
  forgetPwReq: {
    headerMobile: 'INTRODUZCA SU NÚMERO DE TELÉFONO DE ACCESO',
    labelEmail: 'Email',
    labelMobile: 'Número de teléfono',
    loginMobile: 'Número de teléfono de acceso',
    loginSMSCode: 'Código SMS',
    loginSmsCodeExpires: 'El código SMS expira en 5 minutos',
    getCode: 'Envía el código SMS',
    failedCode: 'No se puede enviar el código SMS a su teléfono',
    sentAlready: 'enviado',
    expired: 'El código del SMS ha expirado',
    tip: 'Introduce la dirección de correo electrónico de tu cuenta para restablecer tu contraseña.',
    formValidation: {
      emailReq: 'El email de acceso es obligatorio',
      mobileReq: 'Login mobile is required',
      smsCodeReq: 'El código sms es obligatorio',
    },
    succ:
      'Acabamos de enviarle un correo electrónico con instrucciones para restablecer su contraseña.<br><br>Asegúrese de revisar la carpeta de spam de su email.',
  },
  loginClient: {
    header: 'PORTAL DE CLIENTE',
    failedMasseage: 'vuelva a intentarlo',
    existingClientPortalAccess:
      'Vemos que ya tiene acceso al "Portal de cliente".<br /><br />¡Inicie sesión con su correo electrónico para abrir una cuenta nueva!',
    footer:
      'Advertencia sobre el riesgo: Los Contratos por Diferencia (CFD) implican un alto nivel de riesgo para su capital y pueden dar lugar a pérdidas, sólo debe operar con dinero que pueda permitirse perder. Operar CFDs puede no ser adecuado para todos los inversores, por favor asegúrese de entender completamente los riesgos implicados y tome las medidas adecuadas para gestionarlos.<br><br>\n {platformEntityName} está autorizado y regulado por La Autoridad de Servicios Financieros de Seychelles Licencia No. SD050.<br>\n PU Prime LLC está autorizado y regulado por La Autoridad de Servicios Financieros de Seychelles Licencia No. 271 LLC 2020.<br>The entities above are duly authorized to operate under the PU Prime brand and trademark.<br>',
    formValidation: { emailReq: 'La dirección de email es obligatoria' },
    invailidRafLink: `El enlace de referidos no es válido. Registre una cuenta nueva a continuación.`,
  },
  myProfile: {
    changeDetails: 'Si desea actualizar los datos de su perfil, no dude en ponerse en contacto con {mailTo}',
  },
  openAdditAcc: {
    chooseTradePlat: 'ELIJA UNA PLATAFORMA DE TRADING',
    chooseAccType: 'ELIJA A UN TIP DE CUENTA',
    chooseCurrency: 'ELIJA UNA DIVISA DE CUENTA',
    chooseLeverage: 'Elija el apalancamiento',
    chooseBalance: 'SALDO DE LA CUENTA',
    notes: 'NOTAS ADICIONALES',
    currError: 'Seleccione una moneda base para su cuenta',
    platErr: 'Debe seleccaionar una plataforma de trading',
    accTypeErr: 'Debe seleccionar un tipo de cuenta',
    leverageErr: 'Debe seleccionar un apalancamiento',
    balanceErr: 'Debe seleccionar un saldo',
    succ:
      '<p>Su cuenta real adicional esta siendo creada y pronto recibirá un correo electrónico con sus detalles de acceso.</p><p>Puede ver el estado actual de su solicitud desde la página de inicio.</p>',
    byTicking: 'Al marcar esta casilla {tnc}',
    pamm: `Las cuentas PAMM son solo para inversores con un acuerdo de administrador de dinero existente. Las cuentas PAMM no pueden ser negociadas por nadie excepto por el administrador de dinero.`,
    pammConfirm: 'Confirme si desea continuar seleccionando este tipo de cuenta.',
  },
  openDemoAdditAcc: {
    header: 'Configuración de cuenta demo',
    button: 'abrir cuenta demo',
    liveButton: 'abrir cuenta en vivo',
    succ: `Su cuenta demo se ha configurado y recibirá un correo electrónico con sus detalles de inicio de sesión en breve.`,
  },
  resetPassword: {
    header: 'RESTABLECER CONTRASEÑA',
    succ:
      'Su contraseña ha sido actualizada. Ahora puede utilizar su nueva contraseña para acceder a su cuenta de trading MT4/MT5.',
    succ2: 'Su contraseña ha sido actualizada.',
    failed: 'El token ha expirado',
    emailNotFound: 'Desafortunadamente, su dirección de correo electrónico no fue reconocida',
  },
  fileUploader: {
    supportedFormat: 'Tipos de archivo compatibles: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Tamaño máximo de archivo subido: {maxSize} MB',
    maxFileNum: 'Número máximo de archivos: {maxFileNum}',
  },
  transcationHistory: {
    deposit: 'DEPOSITAR',
    withdraw: 'RETIRAR',
    desc: {
      incomplete:
        'Si finaliza o no completa el proceso de depósito, el estado de la transacción se mostrará como "incompleto". Tendrá que crear un nuevo depósito ya que esta transacción no se puede reanudar.',
      success:
        'Su transacción se ha realizado con éxito Sus fondos deberían aparecer en su cuenta a la mayor brevedad posible',
      proc: 'Su transacción está siendo procesada. Por favor, contacte con {mailTo}',
      failed: 'Error en su transacción. Por favor, póngase en contacto con {mailTo} para más información',
      rejected: 'Su transacción ha sido rechazada. Por favor, póngase en contacto con {mailTo} para más información',
    },
    withdrawDesc: {
      sub: 'Su transacción ha sido enviada. Por favor, espere para completar.',
      suc:
        'Su transacción se ha realizado con éxito Sus fondos deberían aparecer en su cuenta a la mayor brevedad posible',
      proc: 'Su transacción está siendo procesada. Por favor, contacte con {mailTo}',
      incomp:
        'Su transacción requiere pasos adicionales para ser finalizada. Por favor, póngase en contacto con {mailTo} para más información',
      cancel: 'Su transacción ha sido cancelada. Por favor, póngase en contacto con {mailTo} para más información',
      fail: 'Error en su transacción. Por favor, póngase en contacto con {mailTo} para más información',
      rej: 'Su transacción ha sido rechazada. Por favor, póngase en contacto con {mailTo} para más información',
    },
    cancelWithdraw: `Le gustaría cancelar la solicitud de retiro?`,
    cancelError: `Su retiro se está procesando en este momento. Por lo tanto, no podemos aceptar su solicitud de cancelación. Para obtener más información, puede comunicarse con nosotros mediante la función de chat en vivo.`,
    dialog: {
      appliedAmount: `Cantidad aplicada`,
      applicationTime: `Hora de solicitud`,
      processedTime: `Tiempo de procesamiento`,
      accountCurrency: `Moneda de la cuenta`,
      creditDeducted: `Crédito deducido`,
      processedNote: `Nota procesada`,
      bankName: `Nombre del banco`,
      bankAccountNumber: `Número de cuenta bancaria`,
      abaSortCode: `Código ABA/Sort`,
      swiftCode: `Código SWIFT`,
      bankStatement: `Extracto bancario`,
      bankAddress: `Dirección del banco`,
      accountName: `Nombre de la cuenta`,
      accountNumber: `Número de cuenta`,
      cardholderName: `Nombre del titular de la tarjeta`,
      cardNumber: `Número de tarjeta`,
      cardExpiryDate: `Fecha de caducidad de la tarjeta`,
      walletAddress: `Dirección del monedero`,
      ewalletEmailAddress: `Dirección de email del monedero electrónico`,
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'Desafortunadamente, su transferencia no puede ser procesada porque si no el margen disponible de su cuenta estará por debajo del 100%',
      equityMargin:
        'Desafortunadamente, actualmente no podemos procesar su transferencia porque su cuenta tiene cobertura total.',
      equityContinue:
        'Hemos detectado que actualmente tiene posiciones abiertas, puede haber un cierre de alguna de ellas después de enviar la solicitud de transferencia. Por favor, confirme si desea proceder.',
      blackList:
        'Esta cuenta ha sido restringida por hacer una transferencia entre cuentas, por favor, póngase en contacto en {supportEmail}',
    },
    pleaseNote: 'Por favor, tenga en cuenta que:',
    suc: 'Sus fondos han sido transferidos con éxito',
    warn1:
      'Usted es responsable de que el saldo de su cuenta no provoque una llamada de margen. Si tiene posiciones abiertas, asegúrese de mantener el margen requerido en su cuenta. Los fondos transferidos a cuentas que tienen la misma divisa se procesan instantáneamente.',
    warn2: 'Si transfiere fondos entre cuentas que tienen diferentes divisas, envíe un correo electrónico a {mailTo}.',
    warn3: 'Tenga en cuenta que los fondos no se pueden transferir entre cuentas de solo lectura.',
  },
  transferHistory: {
    payStatus: { processing: 'Procesando', rejected: 'Rechazado', paid: 'Pagado', unpaid: 'No pagado' },
  },
  identityProof: {
    home: {
      header: 'SOLICITUD DE CUENTA NO COMPLETADA',
      content:
        'Hola {name}, la solicitud de su cuenta está casi completada, sin embargo, debe enviar documentación adicional para terminar.',
    },
    shufti: {
      content: `Asegúrese de que los detalles proporcionados coincidan con la documentación de su identificación y estén en el mismo idioma.`,
      countryOfDocument: `País como se muestra en el documento`,
    },
    popup: {
      addressProofSpan:
        '<strong>\n     <b>Confirmar dirección</b>\n      </strong>\n      <br />\n      <I>\n        Envíe <b>uno</b> de los siguientes documentos\n        <br /><br />\n      </I>\n      -  Copia de una factura de gastos reciente </br>(Con una validez de hasta 6 meses)\n      <br />\n      <b class="or">or</b><br />\n      - Copia de un extracto bancario reciente </br>(Con una validez de hasta 6 meses)\n      <br />\n      <br />\n      <b>¡IMPORTANTE!</b> El documento DEBE mostrar claramente su nombre completo y dirección actual.\n      <br />',
      idProofSpan:
        '<strong>\n      <b><b>Confirmar identidad</b></b>\n      </strong>\n      <br />\n      <I>Seleccione\n        <b>una</b> de las siguientes opciones\n        <br />\n        <br />\n      </I>\n      - Copia de pasaporte válido<br />\n\n      - Copia de permiso de conducir válido\n      <br />\n      (Anverso y reverso)\n      <br />\n     o</b><br />\n      - Copia del documento nacional de identidad\n      <br />\n      <br />\n      <b>¡IMPORTANTE!</b> El documento DEBE mostrar claramente su nombre completo<br />',
    },
  },
  header: { secPortal: 'Zona de cliente<br />segura' },
  menu: {
    home: 'INICIO',
    accManagement: 'CUENTAS',
    liveAcc: 'CUENTAS EN VIVO',
    openAdditAcc: 'CUENTAS ADICIONALES',
    homeDemo: 'CUENTAS DEMO',
    depositFund: 'DEPOSITAR FONDOS',
    withdrawFunds: 'RETIRAR FONDOS',
    transferBetweenAccs: 'TRANSFERIR FONDOS',
    payDetails: 'DATOS DEL PAGO',
    funds: 'FONDOS',
    transactionHis: 'HISTORIAL DE TRANSACCIONES',
    tranHis: 'TRANSFERENCIAS',
    download: 'DESCARGAS',
    profile: 'PERFIL',
    myProfile: 'MI PERFIL',
    cpPassword: 'CAMBIA LA CONTRASEÑA',
    contactUs: 'Contacto',
    register: 'CONTINUAR LA APLICACIÓN',
    marketWidgets: 'Aplicaciones de Trade',
    MARKET_BUZZ: 'MARKET BUZZ',
    ECON_CALENDAR: 'CALENDARIO ECONÓMICO',
    FEAT_FX: 'IDEAS DESTACADAS',
    FX_IDEAS: 'OPINIONES DE LOS ANALISTAS',
    promotion: 'PROMOCIÓN',
    noDeposit: 'SIN BONIFICACIÓN DE DEPÓSITO',
    referFriends: 'REFERIR A UN AMIGO',
    depositBonus: '50% Bono por depósito',
    cryptoCashback: 'Reembolso de Criptodivisas',
    firstTimeDeposit: `Reembolso FTD`,
    tradingTool: 'Herramientas de Trading',
  },
  contact: {
    click: 'Pulse',
    assist: ' CONTACTE CON NOSOTROS',
    desc:
      'El equipo profesional y multilingüe de atención al cliente de {platform} está listo para ayudarle y proporcionarle una experiencia de trading incomparable.',
    followUs: 'Síguenos',
    tip: 'Para clientes que accedieron a través de {email}',
    liveChat: 'Chat en vivo',
  },
  depositResultDialog: { msg: '¿Ha completado su depósito correctamente?' },
  dialog: {
    identityProof: {
      success: 'Gracias. Hemos recibido sus documentos y los estamos procesando para su verificación.',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: 'PROCESANDO SOLICITUD DE CUENTA',
      id: 'La identificación es',
      poa: 'La dirección es',
      both: 'La identificación y la prueba de dirección son',
      content:
        'Su prueba de {doc} está siendo procesada y se completará en breve. Para una mayor rapidez en el proceso de su solicitud de cuenta,  puede financiar su cuenta ahora y recibir una apertura prioritaria.',
    },
  },
  register: {
    progressStatus: {
      title: 'Proceso de verificación',
      estimatedTimeTitle: `Tiempo estimado para completar:`,
      min: 'Minutos',
    },
    tabs: {
      personalDetails: 'Datos personales',
      moreAboutYou: 'Más sobre usted',
      accountConfig: 'Configuración de cuenta',
      confirmYourId: 'Confirme su identidad',
      fundYourAcc: 'Financie su cuenta',
    },
    btn: { next: 'SIGUIENTE', back: 'ANTERIOR', fundnow: 'AÑADIR FONDOS AHORA', upload: 'Enviar', done: 'HECHO' },
    authority: {
      dialog1: 'Debe tener una cuenta en vivo para acceder al portal de cliente.',
      dialog2: `Abra una cuenta real ahora para desbloquear el acceso completo al portal del cliente, que incluye una variedad de herramientas comerciales, promociones y más.`,
      openLiveAcc: 'abrir cuenta en vivo',
    },
    demo: {
      congrad: '¡Felicitacion!!',
      title: 'Su cuenta DEMO MT{platform} de 60 días está activa ahora',
      title2: 'Datos de su cuenta DEMO',
      li: {
        login: 'Usuario: ',
        password: 'Contraseña: ',
        srv: 'Servidor:',
        expDate: 'Fecha de vencimiento: ',
      },
      emailSent: '* Los datos de inicio de sesión se han enviado a su correo electrónico también.',
      dl: 'Descargue su plataforma de trading MT{platform}',
      improveQ: '¿Desea mejorar su trading?',
      improveWay:
        'Simplemente, abra una cuenta en vivo en solo unos minutos para conseguir una variedad de promociones y herramientas de trading exclusivas.',
      proTrade: 'Herramientas Pro Trader',
      depositBonus: ' Bono por depósito del 20%',
      smartTradeTool: 'Herramientas Smart Trader',
      openLiveAcc: 'abrir cuenta en vivo',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Datos personales',
        userTitle: 'Título',
        firstName: 'Nombre',
        lastName: 'Apellidos',
        email: 'E-mail',
        dob: 'Fecha de nacimiento',
        idType: 'Tipo de identificación',
        referral: '¿Ha sido recomendado por alguien? (opcional)',
        middleName: 'Segundo nombre',
        nationality: 'Nacionalidad',
        phoneNum: 'Número de teléfono',
        date: 'Día',
        month: 'MontMesh',
        year: 'Año',
        placeOfBirth: 'Lugar de nacimiento',
        idNum: 'Número de identificación',
        passportNum: 'Número del pasaporte',
        driverLicenseNum: 'Número del permiso de conducir',
        titleDropdown: {
          mr: 'Sr',
          mrs: 'Sra',
          ms: 'Srto',
          miss: 'Srta',
          dr: 'Dr',
          prof: 'Prof',
        },
        idTypeDropdown: {
          passport: 'Pasaporte',
          idCard: 'Documento nacional de identidad',
          driverLicense: 'Permiso de conducir',
        },
      },
      page2: {
        pageTitle: 'DIRECCIÓN PRINCIPAL',
        mainResidency: 'País principal de residencia',
        streetNumber: 'Número de la calle',
        streetName: 'Nombre de la calle',
        address: 'Dirección',
        provinceOrState: 'Provincia/Estado',
        cityOrSuburb: 'Ciudad/Barrio',
        postcode: 'Código postal',
        usQuestion: `¿Es usted ciudadano estadounidense/residente en EE. UU. a efectos fiscales o tiene vínculos con los EE. UU. (como una cuenta bancaria, etc.)`,
      },
    },
    moreAboutYou: {
      pageTitle1: 'EMPLEO Y DATOS FINANCIEROS',
      pageTitle2: 'Experiencia y conocimiento de trading',
      complianceExplain: 'Las siguientes preguntas se recopilan para cumplir con los requisitos AML/CTF.',
    },
    accountConfig: {
      pageTitle: 'Configuración de cuenta',
      chooseTradingPlatform: 'Elija una plataforma de trading',
      chooseAccType: 'Elija un tipo de cuenta',
      chooseAccCurrency: 'Elija una divisa de cuenta',
    },
    confirmYourId: {
      pageTitle: 'Confirme su identidad',
      description: `Como broker de forex regulado, debemos verificar su identidad. Envíe uno de los siguientes documentos para su verificación de identidad y dirección. También, puede enviar sus documentos de verificación por correo electrónico a {mailTo}`,
      poidTitle: 'Prueba de identidad',
      porTitle: 'Prueba de dirección',
      uploadDescription: 'Elija un tipo de documento y envíelo',
      note: 'IMPORTANTE: El documento DEBE mostrar claramente su nombre completo.',
      pending: {
        upload: 'Pendiente de enviar',
        verification: 'Pendiente de verificar',
      },
      poidTypes: {
        photoId: 'Documento de identidad con foto',
        passport: 'Pasaporte',
        drivingLicence: 'Permiso de conducir',
      },
      porTypes: `<li>Factura de gastos</li>
      <li>Extracto bancario</li>
      <li>Certificado de residencia</li>`,
    },
    finishPage: {
      title: `{individualUserName}, ¡gracias por solicitar una cuenta en vivo en {platformName}!`,
      contentDefault: `Hemos recibido su solicitud y configurado su cuenta en vivo<b>{account}</b>. <br />
      En breve, recibirá un correo electrónico en <b>{email}</b> con sus datos de inicio de sesión y un enlace para ver el estado de su cuenta. <br />`,
      contentWithoutId3WithAccount: `Su cuenta en vivo de trading está siendo procesada y recibirá un correo electrónico con más información en breve.<br />
      Para agilizar el procesamiento de su cuenta real, puede financiar su cuenta ahora y recibir una apertura prioritaria.<br />`,
      contentWithoutId3WithoutAccount: `Su cuenta en vivo de trading está siendo procesada y recibirá un correo electrónico con más información en breve.<br />`,
    },
  },
  autochartist: {
    1: {
      title: `Escáner de mercado`,
      desc01: `El primer Market Scanner del mundo que le permite escanear su lista de seguimiento de mercado en busca de oportunidades comerciales.`,
      button01: `Descargar ahora`,
    },
    2: {
      title: `Informe del Mercado`,
      desc01: `Los informes de mercado de Autochartist le brindan una instantánea del panorama técnico de los mercados antes de la apertura de los principales centros financieros.`,
      button01: `Ver informes de mercado`,
    },
    3: {
      title: `Portal de investigación de Autochartist`,
      desc01: `Un portal de investigación completamente completo que le permite encontrar todos los datos disponibles en un solo lugar.`,
      button01: `Lanzamiento`,
      button02: `Guía del usuario`,
      button03: `Acceso OTP`,
    },
    4: {
      title: `Oportunidades comerciales`,
      desc01: `Verifique las actualizaciones sobre oportunidades comerciales cada 15 minutos con nuevas configuraciones basadas en todos los tipos de análisis de Autochartist.`,
      button01: `Ver oportunidades comerciales`,
    },
    5: {
      title: `Aplicación móvil Autochartist`,
      desc01: `Reciba notificaciones automáticas y realice un seguimiento de las oportunidades comerciales filtradas de alta probabilidad según su lista de seguimiento dentro de la plataforma comercial.`,
      desc02: `Notas:`,
      desc03: `El código QR se puede escanear desde la cámara normal, siempre que el teléfono del usuario tenga un escáner QR incorporado, o desde dentro de la aplicación móvil autochartist, haciendo clic en el botón "Iniciar sesión con código QR".`,
      desc04: `El código QR es válido solo por 10 minutos, después de eso, la imagen debe actualizarse para un nuevo código QR.`,
      desc05: `El código QR es válido para un solo uso, después de eso, la imagen debe actualizarse para obtener un nuevo código QR.`,
      button01: `Descarga para IOS`,
      button02: `Descarga para Andriod`,
    },
    6: {
      title: `Boletín de suscripción`,
      desc01: `Al suscribirse a nuestro boletín, recibirá correos electrónicos diarios impulsados ​​por Autochartist para brindarle una instantánea de los mercados de divisas globales. Comercio con la unión pacífica, comercio con el mundo.`,
      button01: `Suscribir`,
    },
    footer: `Autochartist es la herramienta más avanzada del mundo para la identificación automática de oportunidades comerciales en forma de patrones de gráficos, patrones de Fibonacci y nivel clave. La propiedad de Autochartist permite a sus motores de búsqueda monitorear miles de instrumentos financieros las 24 horas del día y descubrir oportunidades comerciales a medida que ocurren. Además, Autochartist también ofrece análisis de volatilidad como una función que le dará una mejor evaluación del riesgo y la volatilidad de los instrumentos que opera.`,
  },
  responseMsg: {
    410: 'Error de validación del parámetro',
    411: 'La contraseña no es correcta',
    420: 'El usuario no existe',
    421: 'Restablecimiento de contraseña rechazado',
    490: 'No envíe información de tarjetas duplicadas',
    500: 'Ha ocurrido un error.',
    501: 'Error en su solicitud Por favor, inténtelo de nuevo más tarde.',
    505: 'Error al enviar el archivo',
    520: 'Error al iniciar sesión. Por favor, vuelva a intentarlo.',
    521: '¡Un usuario ya ha iniciado sesión en este dispositivo!',
    522: 'Por favor, inicie sesión de nuevo',
    523: 'Por favor, inicie sesión de nuevo',
    524: 'Desafortunadamente, su dirección de correo electrónico no fue reconocida',
    525: 'El nombre de usuario y la contraseña no son correctos',
    527: 'El usuario no es un IB',
    528: 'El usuario no existe',
    529: 'El nombre de usuario y la contraseña no son correctos',
    540: 'No se pudo encontrar su cuenta de trading',
    541: 'No se pudo encontrar la cuenta de reembolso',
    542: 'No se le permite abrir cuentas adicionales',
    544: 'No puede solicitar una cuenta adicional hasta que se verifique su prueba de identidad',
    550: 'El número de cuenta no es correcto',
    551: 'No tiene suficiente saldo',
    553: 'No puede retirar/transferir debido al saldo de su cuenta. Por favor, póngase en contacto con {email} para más información',
    554: 'La cantidad solicitada es cero o nula',
    562: 'No fue posible procesar su pago, por favor, inténtelo de nuevo. Si el problema persiste, por favor contáctenos por chat, o por correo electrónico.',
    564: 'El país no coincide con el medio de pago',
    565: 'El código bancario no es correcto',
    566: 'Método de pago no encontrado',
    568: 'Se ha deshabilitado esta cuenta para realizar depósitos, por favor, contacte con info@puprime.com',
    581: 'Elegiste con éxito el ascenso. Por favor, tenga en cuenta que debe hacer un depósito para poder retirar los fondos.',
    582: 'Como se encuentra en el período de promoción y tiene puestos vacantes, no pudimos procesar su solicitud de retiro.',
    584: 'No tiene ninguna comisíon aplicable',
    587: 'Saldo insuficiente, póngase en contacto con el servicio de atención al cliente.',
    590: 'No se pudo encontrar la cuenta de su transferencia saliente',
    591: 'No se pudo encontrar la cuenta de su transferencia entrante',
    593: 'No podemos procesar la transferencia de sus fondos desde la cuenta seleccionada porque su cuenta contiene crédito.',
    594: 'No se permiten transferencias de fondos desde esta cuenta',
    595: 'Dado que participa en nuestras actividades, su solicitud de transferencia no se puede procesar en este momento.',
    647: 'El código de verificación es incorrecto',
    1001: 'Su cuenta no está disponible temporalmente. Por favor, póngase en contacto con su gestor de cuen',
    session_timeout: 'La sesión há expirado, redirigiendo a la página de inicio de sesión.',
    vload: {
      4019: 'Valor o tipo de cupón no aceptado',
      4071: 'Sospecha de fraude',
      4072: 'Se ha utilizado el cupón',
      4073: 'El cupón no está activo',
      4074: 'El cupón ha caducado',
      4075: 'No se ha encontrado el cupón',
      4080: 'El cupón no tiene suficiente valor',
      4444: 'Cupón no valido utilizado. Incompatibilidad de divisa',
      5000: 'La información de la tienda no está disponible',
    },
  },
  tradingTool: {
    mt4: {

    },
    pamm: {

    },
    copytrade: {

    }
  },
  activity: {
    myActivity: '',
    allActivity: '',
    activityContent: '',
    applyNow: '',
    activityStatus1: '',
    activityStatus2: '',
    activityStatus3: '',
    noActivity: '',
  }
}
