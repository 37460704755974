export default {
  en: 'English',
  common: {
    country: { international: 'Quốc Tế', australia: 'Úc' },
    liveChat: {},
    verification: {
      toSlide: 'Trượt để hoàn thành câu đố',
    },
    field: {
      nameOnCard: 'Tên Trên Thẻ',
      cardholderName: `Tên chủ thẻ`,
      first4Digits: '4 chữ số đầu tiên trên thẻ',
      last3Digits: '3 chữ số cuối trên thẻ',
      first6Digits: '6 chữ số đầu tiên trên thẻ',
      last4Digits: '4 chữ số cuối cùng trên thẻ',
      bankName: 'Tên Ngân Hàng',
      bankAddress: 'Địa Chỉ Ngân Hàng',
      branchAddress: 'Địa Chỉ Chi Nhánh',
      bankBranch: 'Chi Nhánh Ngân Hàng',
      bankCity: 'Tỉnh',
      bankProvince: 'Thành Phố',
      bankBeneficiaryName: 'Tên Người Thụ Hưởng',
      bankBeneAccName: 'Tên Tài Khoản Ngân Hàng Thụ Hưởng',
      bankBeneAccNum: 'Số Tài Khoản Ngân Hàng Thụ Hưởng',
      bankBeneAddress: 'Địa Chỉ Người Thụ Hưởng',
      bankAccNum: 'Số Tài Khoản Ngân Hàng',
      bankAccName: 'Tên Tài Khoản Ngân Hàng',
      bankHolderAddress: 'Địa Chỉ Chủ Tài Khoản',
      address: 'Địa Chỉ ',
      accName: 'Tên Tài Khoản',
      accNum: 'Số Tài Khoản',
      accNumTo: 'Số Tài Khoản',
      bsbNum: 'Số BSB',
      swift: 'Mã SWIFT',
      swiftNonAUD: 'Mã SWIFT (Không phải tài khoản AUD)',
      amt: 'Số Tiền',
      amtUSD: 'Số Tiền (USD)',
      availableAmount: 'Số tiền có sẵn',
      transitNumber: 'Số quá cảnh',
      institutionNumber: 'Số tổ chức',
      imptNotes: 'Ghi Chú Quan Trọng',
      yes: 'Có',
      no: 'Không',
      payMethod: 'Phương Thức Thanh Toán',
      expDate: 'Ngày hết hạn',
      bsb: 'BSB',
      abaSortCode: 'ABA/Sort Code:',
      country_region: 'Quốc gia / khu vực',
      currLeverage: 'Đòn bẩy hiện tại',
      accEquity: 'Vốn trong Tài Khoản',
      newLeverage: 'Đòn bẩy mong muốn',
      currPw: 'Mật khẩu hiện tại',
      newPw: 'Mật Khẩu Mới',
      confirmPw: 'Xác nhận mật khẩu mới',
      loginEmail: 'Email Đăng nhập ',
      email: 'Email',
      pw: 'Mật Khẩu',
      accType: 'Loại tài khoản',
      name: 'Tên',
      emailAdd: 'Địa chỉ email',
      phone: 'Số điện thoại',
      dob: 'Ngày sinh',
      nat: 'Quốc tịch',
      country: 'Quốc gia cư trú',
      street: 'Số đường / Tên đường',
      suburbOrCity: 'Ngoại ô / thành phố',
      provinceOrState: 'Tỉnh / Bang',
      postcode: 'Mã bưu điện',
      empStat: 'Tình trạng việc làm',
      annIncome: 'Thu nhập hàng năm',
      save: 'Đầu tư',
      source: 'Nguồn thu nhập',
      industry: 'Ngành',
      statusDef: 'TÌNH TRẠNG',
      month: 'Tháng',
      year: 'Năm',
      select: 'Chọn',
      completed: 'Đã hoàn thành',
      incomplete: 'Chưa hoàn thành',
      submitted: 'Đã Gửi',
      successful: 'Thành Công',
      processing: 'Đang Xử Lý',
      cancelled: 'Đã Hủy',
      failed: 'Lỗi',
      rejected: 'Từ Chối',
      upload: 'Tải Lên',
      pending: `chưa giải quyết`,
      ccNum: 'Số thẻ tín dụng',
      ccExpireDate: 'Ngày hết hạn',
      bankCardNumber: 'Số thẻ ngân hàng',
      cardHolderName: 'Tên chủ thẻ',
      reservedMobileNumber: 'Số điện thoại di động cho ngân hàng',
      nationalId: 'Chứng minh nhân dân',
      cardPhoto: 'Ảnh thẻ (Mặt trước)',
      notes: 'Ghi chú',
      bankAccOrIBAN: 'Số tài khoản ngân hàng / IBAN',
      selectWithdrawCard: 'Chọn Thẻ rút tiền của bạn',
      updateCardPhoto: 'vui lòng tải lên ảnh thẻ của bạn (Mặt trước)',
      actualAmount: 'số tiền thực tế',
      resetBalTo: 'Đặt lại số dư tới',
      ipChangeWarning: 'Nhận email cảnh báo khi bất kỳ ai đăng nhập vào tài khoản của bạn từ một vị trí khác',
      enterVerificationCode: 'Nhập mã xác minh',
      tips: '',
      pleaseInput: '',
      confirmClose: '',
      notBind: '',
      infoDetails: '',
      loginWarn: '',
      noDataNow: '',
      hasAccountAlready: '',
    },
    column: {
      date: 'NGÀY',
      type: 'LOẠI',
      server: 'NGƯỜI PHỤC VỤ',
      status: 'TÌNH TRẠNG',
      comment: 'BÌNH LUẬN',
      accNum: 'SỐ TÀI KHOẢN',
      currency: 'TIỀN TỆ',
      equity: 'VỐN',
      leverage: 'ĐÒN BẨY',
      resetMT4PW: 'ĐẶT LẠI MẬT KHẨU MT4',
      resetMT5PW: 'ĐẶT LẠI MẬT KHẨU MT5',
      tradingAcc: 'TÀI KHOẢN GIAO DỊCH',
      method: 'PHƯƠNG THỨC',
      amt: 'SỐ TIỀN',
      procNote: 'GHI CHÚ',
      number: 'SỐ THẺ',
      PLATFORM: 'NỀN TẢNG',
      resetBal: 'ĐẶT LẠI SỐ DƯ',
    },
    key: { from: 'TỪ', to: 'ĐẾN' },
    fund: {
      must: 'BẮT BUỘC',
      infoHover: 'Tên trên thẻ của bạn {phải} khớp với tên trên tài khoản giao dịch của bạn',
      ccUploadReminder: 'Vui lòng tải lên hình ảnh mặt trước thẻ của bạn để xác minh. (Vui lòng che 6 chữ số ở giữa)',
    },
    button: {
      bkToHm: 'Quay lại trang chủ',
      smsLogin: 'ĐĂNG NHẬP TRÊN ĐIỆN THOẠI',
      goToIB: 'ĐẾN cổng IB',
      logout: 'ĐĂNG XUẤT',
      submit: 'Gửi',
      selectFile: 'Chọn tập tin',
      depositFunds: 'NẠP TIỀN',
      withdrawFunds: 'RÚT TIỀN',
      ibPortal: 'CỔNG IB',
      download: 'TẢI XUỐNG',
      changeLeverage: 'THAY ĐỔI ĐÒN BẨY',
      cancel: 'Hủy bỏ',
      changePw: 'ĐỔI MẬT KHẨU',
      forgetPw: 'QUÊN MẬT KHẨU',
      confirm: 'Xác nhận',
      login: 'ĐĂNG NHẬP',
      forgotPw: 'Quên mật khẩu?',
      completeQuestionnaire: 'HOÀN THÀNH BẢNG CÂU HỎI',
      identityProof: 'HOÀN THÀNH ĐĂNG KÝ',
      upload: 'TẢI LÊN',
      export: 'XUẤT',
      ok: 'OK',
      hide: 'ẨN BỚT',
      showAll: 'HIỂN THỊ TẤT CẢ',
      createLiveAccount: `MỞ TÀI KHOẢN THẬT`,
      redeem: `Quy đổi`,
      sendCode: 'Gửi mã code',
      resendCode: 'Mã gửi lại',
    },
    formValidation: {
      accReq: 'Số tài khoản là bắt buộc',
      amtReq: 'Số tiền là bắt buộc',
      ccReq: 'Số thẻ tín dụng là bắt buộc',
      cardReq: 'Số thẻ là bắt buộc',
      cardOnNameReq: 'Tên trên thẻ là bắt buộc',
      cardholderNameReq: `Tên chủ thẻ bắt buộc`,
      digits16: 'Vui lòng nhập 16 chữ số',
      digits3or4: 'Vui lòng nhập 3 hoặc 4 chữ số',
      digitsFirst6: 'Vui lòng nhập 6 chữ số đầu tiên',
      digitsLast4: 'Vui lòng nhập 4 chữ số cuối',
      digitsLast3: 'Vui lòng nhập 3 chữ số cuối',
      mid6Digits: 'Vui lòng nhập chữ số {số} ở giữa',
      expDateReq: 'Ngày hết hạn là bắt buộc',
      cvvReq: 'Mã bảo mật CVV là bắt buộc',
      file: 'Vui lòng tải lên một tập tin',
      filePhoto: 'Ảnh thẻ tín dụng là bắt buộc',
      fileReceipt: 'Vui lòng tải lên một biên lai',
      filePhotoReq: 'Ảnh thẻ là bắt buộc',
      amtLarger: 'Số tiền không thể để trống và phải lớn hơn hoặc bằng {minLimit} {currency}',
      amt0: 'Số tiền không thể là 0',
      amtLess: 'Số tiền ký gửi của bạn không thể nhiều hơn {maxLimit} {currency}',
      note256: 'Lưu ý giới hạn độ dài là 256 ký tự',
      noteMoreThan: 'Lưu ý giới hạn độ dài là {giới hạn} ký tự',
      noteLessThan: 'Lưu ý giới hạn độ dài là {giới hạn} ký tự',
      emailFormat: 'Vui lòng nhập địa chỉ email chính xác',
      payMethodReq: 'Phương thức thanh toán là bắt buộc',
      expReq: 'Ngày hết hạn là bắt buộc',
      bankNameReq: 'Tên ngân hàng là bắt buộc',
      bankCityReq: 'Thành phố là bắt buộc',
      bankProvinceReq: 'Tỉnh là bắt buộc',
      bankAddressReq: 'Địa chỉ ngân hàng là bắt buộc',
      bankBranchReq: 'Chi nhánh ngân hàng là bắt buộc',
      bankAccNumReq: 'Số tài khoản ngân hàng là bắt buộc',
      bankBeneficiaryNameReq: 'Tên người thụ hưởng ngân hàng là bắt buộc',
      bankHolderAddressReq: 'Địa chỉ chủ tài khoản là bắt buộc',
      swiftReq: 'Mã Swift là bắt buộc',
      accHolderAddress: 'Địa chỉ chủ tài khoản là bắt buộc',
      bsbReq: 'BSB là bắt buộc',
      accNumIBANReq: 'Số tài khoản ngân hàng / IBAN là bắt buộc',
      accNameReq: 'Tên tài khoản ngân hàng là bắt buộc',
      withdrawMethodReq: 'Vui lòng chọn phương thức rút tiền',
      tnc: 'Vui lòng đồng ý với các Điều khoản và Điều kiện.',
      currPwReq: 'Mật khẩu hiện tại là bắt buộc',
      currPwWrong: 'Mật khẩu hiện tại không chính xác',
      newPwReq: 'Mật khẩu mới là bắt buộc',
      newPwFormat:
        'Mật khẩu của bạn phải có từ 8-16 ký tự và bao gồm các chữ cái (a-z và A-Z), các số (0-9), và các ký tự đặc biệt như !@#$%^&*.()',
      IdenficalPw: 'Mật khẩu mới không thể giống với mật khẩu cũ',
      confirmPwReq: 'Vui lòng nhập lại mật khẩu mới',
      confirmNotMatch: 'Hai đầu vào không khớp nhau',
      pwReq: 'Mật khẩu là bắt buộc',
      accCurrReq: 'Bạn phải chọn một loại tiền tệ cho tài khoản',
      tradAccReq: 'Vui lòng chọn một tài khoản giao dịch',
      answerReq: 'Vui lòng chọn',
      bankCardReq: 'Vui lòng chọn một thẻ',
      phoneNumberReq: 'Số điện thoại di động cho ngân hàng là bắt buộc',
      nationaIdReq: 'Số chứng minh nhân dân là bắt buộc',
      numberReq: 'Vui lòng nhập số',
      alphanumericReq: 'Vui lòng nhập chữ và số',
      idTypeReq: 'Vui lòng chọn Loại ID',
      dobReq: 'Vui lòng chọn ngày sinh của bạn',
      pobReq: 'Vui lòng chọn nơi sinh của bạn',
      nationalityReq: 'Vui lòng chọn quốc tịch của bạn',
      titleReq: 'Vui lòng chọn cách xưng hô',
      firstNameReq: 'Vui lòng nhập tên của bạn',
      lastNameReq: 'Vui lòng nhập họ của bạn',
      emailReq: 'Hãy điền địa chỉ email của bạn',
      addressReq: 'Vui lòng nhập địa chỉ của bạn',
      suburbReq: 'Vui lòng nhập thành phố hoặc vùng ngoại ô',
      stateReq: 'Tiểu bang hoặc tỉnh là bắt buộc',
      postcodeReq: 'Vui lòng nhập mã bưu điện của bạn',
      countryReq: 'Vui lòng chọn quốc gia cư trú của bạn',
      masterCardReq: 'Vui lòng sử dụng Thẻ Master để nạp tiền',
      masterCardJCBReq: `Vui lòng sử dụng thẻ Master Card / JCB để gửi tiền`,
      masterCardVisaReq: `Vui lòng sử dụng thẻ Master Card / Visa để gửi tiền`,
      masterCardAmexJCBReq: `Vui lòng sử dụng thẻ Master Card / AMEX / JCB để gửi tiền`,
      bankCodeReq: 'Vui lòng nhập Ngân hàng phát hành thẻ',
      dynamicReq: '{dynamic} là bắt buộc',
      mobReq: 'số điện thoại là bắt buộc',
      resetBalAmt: 'Vui lòng đặt số tiền từ 0 đến 1.000.000',
      verCodeReq: 'Mã xác minh bắt buộc',
      emailFormatWrong: 'Định dạng email không chính xác',
      passwordFormatWrong: 'Định dạng mật khẩu không chính xác',
    },
    // for WITHDRAW FUNDS
    withdrawChannel: {
      banktransferbpaypolipay: 'Chuyển khoản ngân hàng / BPay / PoliPay',
      banktransfer: 'Chuyển khoản ngân hàng',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'Chuyển khoản UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Thẻ tín dụng',
      bpay: 'Bpay',
      creditdebitcard: 'Thẻ tín dụng / thẻ ghi nợ',
      bankwiretransferinternational: 'Chuyển khoản ngân hàng (quốc tế)',
      banktransferaustralia: 'Chuyển khoản ngân hàng (Úc)',
      banktransferinternational: 'Chuyển khoản ngân hàng (quốc tế)',
      banktransferchina: 'Chuyển khoản ngân hàng (quốc tế)',
      banktransferequals: 'Chuyển khoản ngân hàng (quốc tế)',
      mobilepay: 'Thanh toán di động',
      brokertobrokertransfer: 'Chuyển từ nhà môi giới khác',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Ngân Hàng Điện Tử (Thái Lan)',
      internetbankingnigeria: 'Ngân Hàng Điện Tử (Nigeria)',
      internetbankingvietnam: 'Ngân Hàng Điện Tử (Việt Nam)',
      internetbankingmalaysia: 'Ngân Hàng Điện Tử (Malaysia)',
      internetbankingindonesia: 'Ngân Hàng Điện Tử (Indonesia)',
      internetbankingkorea: 'Ngân Hàng Điện Tử (Korea)',
      internetbankingphilippine: 'Ngân Hàng Điện Tử (Philippines)',
      internetbankingsouthafrica: 'Ngân Hàng Điện Tử (Nam Phi)',
      internetbankinguganda: 'Ngân Hàng Điện Tử (Uganda)',
      internetbankingrwanda: 'Ngân Hàng Điện Tử (Rwanda)',
      internetbankingzambia: 'Ngân Hàng Điện Tử (Zambia)',
      internetbankingcongo: 'Ngân Hàng Điện Tử (Congo)',
      internetbankingcameroon: 'Ngân Hàng Điện Tử (Cameroon)',
      internetbankingburundi: 'Ngân Hàng Điện Tử (Burundi)',
      internetbankingkenya: 'Ngân Hàng Điện Tử (Kenya)',
      internetbankingghana: 'Ngân Hàng Điện Tử (Ghana)',
      internetbankingtanzania: 'Ngân Hàng Điện Tử (Tanzania)',
      internetbankingcanada: 'Ngân Hàng Điện Tử (Canada)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Chuyển khoản ngân hàng Việt Nam',
      nigeriabanktransfer: 'Chuyển khoản ngân hàng Nigeria',
      polipayment: 'Thanh toán Poli',
      bankwiretransferaustralia: 'Chuyển khoản ngân hàng (Úc)',
      thailandinstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Thái Lan',
      nigeriainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Nigeria',
      vietnaminstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Việt Nam',
      malaysiainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Malaysia',
      indonesiainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Indonesia',
      southkoreabanktransfer: 'Chuyển khoản Ngân hàng Nội địa Hàn Quốc',
      southafricainstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Nam Phi',
      philippinesinstantbankwiretransfer: 'Chuyển khoản nhanh ngân hàng Philippines',
      ugandabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Uganda',
      rwandabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Rwanda',
      zambiabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Zambia',
      congobanktransfer: 'Chuyển khoản Ngân hàng Địa phương Congo',
      cameroonbanktransfer: 'Chuyển khoản Ngân hàng Địa phương Cameroon',
      burundibanktransfer: 'Chuyển khoản Ngân hàng Địa phương Burundi',
      kenyabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Kenya',
      ghanabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Ghana',
      tanzaniabanktransfer: 'Chuyển khoản Ngân hàng Địa phương Tanzania',
      indiabanktransfer: 'Chuyển khoản ngân hàng Ấn Độ ',
      banktransferbpaypolipayaustralia: 'Chuyển khoản ngân hàng / BPay / PoliPay (Úc)',
      banktransferbpaypolipayinternational: 'Chuyển khoản ngân hàng / BPay / PoliPay (Quốc tế)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Chuyển khoản Ngân hàng Nội địa Canada',
      cryptobitcoin: 'Tiền điện tử-Bitcoin',
      cryptotetheromni: 'Tiền điện tử-Tether(OMNI)',
      cryptotethererc20: 'Tiền điện tử-Tether(ERC20)',
      cryptotethertrc20: 'Tiền điện tử-Tether(TRC20)',
      cryptocurrencybitcoin: 'Tiền điện tử-Bitcoin',
      cryptocurrencyusdt: 'Tiền điện tử-USDT',
      cryptocurrencyeth: 'Tiền điện tử-ETH',
      cryptocurrencyusdc: 'Tiền điện tử-USDC(ERC-20)',
      cryptocurrencyusdcerc20: 'Tiền điện tử-USDC(ERC-20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'TIỀN TỆ',
      LOCAL_TRANSFER: 'CHUYỂN KHOẢN',
      archiveThisCard: 'Vui lòng lưu trữ thẻ này nếu nó không còn giá trị hoặc không được sử dụng ',
      manageCreditCards: 'Quản lý thẻ tín dụng',
      update: 'Cập Nhật',
      archive: 'Lưu Trữ',
      requiredDocument: 'Các tài liệu cần thiết để lưu trữ thẻ ',
      officialLetter: 'Thư / email chính thức của ngân hàng cho biết thẻ không còn khả dụng ',
      bankStatement: 'Sao kê Ngân hàng với giao dịch được thực hiện bằng thẻ này ',
      expiredCard: 'Thẻ hết hạn / không khả dụng ',
      confirmArchive: 'Bạn có chắc chắn muốn lưu trữ thẻ này không? ',
      confirmUpdate:
        'Bạn có chắc chắn muốn cập nhật ngày hết hạn của thẻ này không? Lưu ý: Puprime sẽ không chịu trách nhiệm nếu việc rút tiền không thành công do ngày hết hạn được cung cấp không chính xác',
      pendingArchiveRequest:
        'Yêu cầu lưu trữ thẻ tín dụng của bạn hiện đang chờ phê duyệt, vui lòng đợi cho đến khi quá trình hoàn tất trước khi thực hiện rút tiền hoặc liên hệ với dịch vụ khách hàng của chúng tôi qua trò chuyện trực tiếp để tiến hành quy trình.',
    },
  },
  home: {
    pendingTooltip:
      'Khi tài khoản thực của bạn được chấp thuận, bạn sẽ nhận được email chứa thông tin đăng nhập MT{number} để bạn có thể bắt đầu giao dịch! Trong khi đó, bạn có thể nạp tiền vào tài khoản của mình để được ưu tiên xác nhận sớm.',
    leverage: {
      tnc:
        '<p>Bằng cách gửi yêu cầu này, tôi thừa nhận rằng tôi biết rằng đòn bẩy cao mang lại rủi ro lớn cho vốn của tôi và có khả năng tôi có thể chịu lỗ lớn hơn và không bị giới hạn với số vốn tôi đã ký gửi. <br> <br> Tôi đồng ý rằng nhà phát hành sản phẩm có quyền xem xét các hoạt động giao dịch của tôi và điều chỉnh mức đòn bẩy trên tài khoản giao dịch của tôi theo quyết định của mình và không có bất kỳ cảnh báo hoặc thông báo trước nào. <br> < br> Tôi hiểu rằng bằng cách chọn đòn bẩy cao, tôi có thể giao dịch với biên độ lớn hơn nhưng có khả năng chịu tổn thất đáng kể. </ p>',
      changeLeverageConfirm: 'Yêu cầu của bạn đã được gửi và sẽ được nhóm hỗ trợ của chúng tôi xử lý sớm.',
      changeLeverageAutoComplete: 'Yêu cầu thay đổi đòn bẩy của bạn đã hoàn thành',
      changeLeverageReject: 'Yêu cầu thay đổi đòn bẩy đã bị từ chối',
      changeLeverageFail: 'Yêu cầu thay đổi đòn bẩy không thành công, vui lòng thử lại sau',
      changeLeverageDefault: 'Đã xảy ra lỗi, vui lòng liên hệ với {supportEmail} để hoàn tất đơn đăng ký của bạn',
      alreadyApplied:
        'Bạn đã yêu cầu thay đổi đòn bẩy trên tài khoản của mình và hiện đang được nhóm hỗ trợ {platform} xem xét. Vui lòng đợi yêu cầu này được hoàn thành trước khi gửi yêu cầu khác.',
      reduceLeverageWarn: `Xin lưu ý: Việc giảm đòn bẩy của bạn có thể dẫn đến việc buộc phải đóng các vị thế đang mở nếu tài khoản của bạn không đủ ký quỹ.`,
      reduceLeverageConfirm: `Bạn có muốn tiếp tục?`,
      autoRestore: `Tài khoản đã chọn có vốn chủ sở hữu lớn hơn 20,000 USD, đòn bẩy tối đa bạn có thể dùng là 1:500. Bạn có thể thay đổi đòn bẩy lên đến 1:1000 khi vốn chủ sở hữu tài khoản của bạn nhỏ hơn 20,000 USD`,
    },
    pw: {
      error: 'Không thể thiết lập lại mật khẩu của bạn. Vui lòng thử lại sau',
      confirm:
        'Mật khẩu của bạn đã được cập nhật. <br> Bây giờ bạn có thể sử dụng mật khẩu mới của mình để truy cập tài khoản giao dịch MT4 / MT5.',
      email:
        '<p class = first_info "> Chúng tôi vừa gửi cho bạn một email có hướng dẫn để đặt lại mật khẩu của bạn. </ p> <p> Vui lòng đảm bảo bạn kiểm tra thư mục thư rác của bạn. </ p>',
    },
    activate: {
      question: 'Bạn có muốn kích hoạt lại tài khoản thật của bạn?',
      confirm: 'Chúng tôi đã nhận được yêu cầu kích hoạt của bạn. <br> Tài khoản của bạn sẽ sớm được kích hoạt.',
    },
    accStatus: {
      active: 'Hoạt động',
      rejected: 'Từ Chối',
      inactive: 'Không Hoạt Động',
      processing: 'Đang Xử Lý',
      activate: 'Kích hoạt',
      expired: 'Đã hết hạn',
    },
    pro1000: {
      desc1: `Các hạn chế sau áp dụng cho tài khoản có đòn bẩy 1:1000:`,
      conditions: [
        `Tài khoản đã chọn không đủ điều kiện để tham gia chương trình liên quan đến tín dụng.`,
        `Đòn bẩy có thể được hệ thống tự động hạ xuống 1:500 nếu số dư tài khoản được chọn vượt quá 20,000 USD hoặc tương đương. Nếu có giao dịch mở, thông báo qua email sẽ được gửi trước khi điều chỉnh đòn bẩy.`,
        `Giới hạn đòn bẩy có thể thay đổi theo các quy định hiện hành`,
        `Xin lưu ý rằng Đòn bẩy 1:1000 không khả dụng cho các công cụ tài chính thuộc Tiền điện tử, Cổ phiếu, Bạc và Hàng hóa. Tiền ký quỹ cho các công cụ này được giữ theo yêu cầu ký quỹ cố định của công cụ và không bị ảnh hưởng bởi đòn bẩy 1: 1000.`,
      ],
      desc2: `Bằng cách gửi yêu cầu này, tôi thừa nhận rằng tôi biết rằng đòn bẩy cao mang lại rủi ro lớn cho vốn của tôi và có khả năng tôi có thể chịu khoản lỗ lớn hơn và không giới hạn ở số vốn tôi đã nạp.`,
      desc3: `Tôi đồng ý rằng nhà phát hành sản phẩm có quyền xem xét các hoạt động giao dịch của tôi và điều chỉnh mức đòn bẩy trên tài khoản giao dịch của tôi theo quyết định của họ mà không có bất kỳ cảnh báo hoặc thông báo trước nào.`,
      desc4: `Tôi hiểu rằng bằng cách chọn đòn bẩy cao, tôi có thể giao dịch với số tiền ký quỹ lớn hơn nhưng có thể có khả năng chịu tổn thất đáng kể.`,
    },
  },
  paymentDetails: {
    subHeader: 'THÊM MỘT PHƯƠNG THỨC THANH TOÁN',
    payMethod: 'Phương thức thanh toán',
    tip:
      '<p> Chọn phương thức thanh toán ưa thích của bạn cho các khoản tiền gửi và rút tiền trong tương lai từ menu bên dưới. </ p> <br />\n    <p> <b> Xin lưu ý: </ b> Để đảm bảo yêu cầu gửi tiền và rút tiền của bạn có thể được xử lý kịp thời, các quy định của AML / CTF yêu cầu tên hiển thị trên phương thức thanh toán bạn đã chọn là\n      <b> {individualUserName}</b>.</p>',
    result: {
      defaultSuccessMessage:
        'Cảm ơn bạn đã gửi phương thức nạp tiền cho tài khoản giao dịch của bạn. Chúng tôi hiện đang xử lý yêu cầu của bạn và sẽ liên lạc ngay.',
      unionPayCardSuccessMessage:
        'Chi tiết Thẻ thanh toán Union được gửi thành công, chi tiết ngân hàng mới của bạn sẽ có sẵn khi được phê duyệt',
    },
    history: { header: 'PHƯƠNG THỨC THANH TOÁN' },
    cc: {
      field: {
        ccNum: 'Số thẻ tín dụng',
        first6: 'Sáu ký tự đầu tiên',
        last4: 'Bốn kỳ tự cuối cùng',
        withrawlable: 'Số tiền rút tối đa hiện có cho thẻ này là {amount}.',
      },
      formValidation: { upload: 'Ảnh thẻ tín dụng là bắt buộc' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'Đang xử lý tiền gửi của bạn ... {CountDown} giây còn lại.',
      successMsg: 'Cảm ơn bạn đã nạp tiền, tiền của bạn sẽ sớm xuất hiện trong tài khoản của bạn.',
      rejectMsg: 'Từ Chối',
      failureMsg: 'Tiền gửi của bạn không thành công. Vui lòng thử lại.',
      message:
        'Cảm ơn đã nạp tiền cho tài khoản của bạn. Khoản tiền gửi của bạn sẽ sớm được nhóm tài khoản {platform} xem xét. Tiền sẽ được gửi vào tài khoản của bạn sau khi được phê duyệt',
      defaultException:
        'Để xác nhận khoản tiền gửi của bạn với {platform}, vui lòng liên hệ với bộ phận hỗ trợ {email}',
    },
    failed: { countDownMsg: 'Đang xử lý tiền gửi của bạn ... {CountDown} giây còn lại.', rejectMsg: 'Từ Chối' },
  },
  deposit: {
    alert: {
      depositBonus50: `Bạn đủ điều kiện nhận Thưởng nạp tiền 50%. Kích hoạt trước khi nạp tiền!`,
    },
    default: {
      deposit: 'Nạp Tiền',
      reference: 'Tham khảo của bạn',
      sendReceipt: 'Gửi hóa đơn',
      sendReceiptTip:
        'Khi bạn đã thực hiện chuyển khoản, vui lòng điền vào mẫu dưới đây và đính kèm ảnh chụp màn hình, ảnh hoặc bản sao hóa đơn chuyển khoản ngân hàng hiển thị rõ tên đầy đủ, số tài khoản và số tiền gửi của bạn để chúng tôi có thể xử lý giao dịch của bạn.',
      reminder: 'Vui lòng cho phép 1-2 ngày làm việc để tiền được ghi nhận vào tài khoản của bạn.',
      successMsg:
        'Tiền sẽ xuất hiện trong tài khoản giao dịch của bạn sau khi chúng tôi nhận được khoản tiền nạp và xác minh biên lai giao dịch của bạn.',
      alt: 'Ngoài ra, bạn có thể gửi email biên nhận giao dịch của mình tới {mailTo} một cách thuận tiện sớm nhất.',
      reminderFundAppear:
        'Tiền của bạn sẽ xuất hiện trong tài khoản của bạn ngay khi chúng tôi nhận được tiền từ nhà môi giới trước đó của bạn.',
      failed: 'Gửi tiền thất bại, vui lòng thử lại sau.',
      fee: 'PHÍ',
      instant: 'NGAY LẬP TỨC',
      hour: 'giờ',
      hours: 'giờ',
      businessDays: 'NGÀY LÀM VIỆC',
      withinOneBusinessDays: 'EN EL PLAZO DE 1 DÍA LABORABLE',
      secondaryNGN:
        '(Bạn có thể dùng tài khoản này cho giao dịch này nếu bạn thích dùng đồng tiền Nigeria Naira (NGN) hơn.)',
      disclaimer: `<li>Tất cả các khoản thanh toán của bạn cho {platform} đều được bảo mật và chỉ được sử dụng để gửi và rút tiền trong tài khoản giao dịch của {platform}. Tất cả các khoản thanh toán điện tử được xử lý bằng công nghệ SSL (Lớp cổng bảo mật) và được mã hóa để đảm bảo an ninh. </ Li>
      <li>{platform} hoàn toàn không chấp nhận thanh toán từ bất kỳ bên thứ ba nào. Tất cả số tiền nạp vào tài khoản giao dịch của bạn phải có cùng tên với tài khoản cổng thông tin khách hàng {platform} của bạn.</ li>
      <li>Khách hàng hiểu rằng việc gửi yêu cầu gửi tiền không cấu thành sự chấp nhận tiền của {platform}. Nếu tài khoản của bạn sắp hoặc đang trong margin call, vui lòng đảm bảo rằng bạn đã có các sắp xếp khác. </ Li>
      <li>{platform} sẽ không chịu trách nhiệm cho bất kỳ sự chậm trễ hoặc lỗi nào do hệ thống thanh toán gây ra. </ li>
      <li>{platform} sẽ không chịu trách nhiệm cho bất kỳ khoản phí nào mà tổ chức tài chính gửi hoặc nhà cung cấp thanh toán bên thứ ba có thể tính phí cho bạn khi gửi thanh toán. </ li>
      <li>Khách hàng thừa nhận rằng họ có thể được yêu cầu cung cấp thông tin về (các) nguồn tiền nạp, tài sản của Khách hàng nói chung và bất kỳ thông tin nào khác mà {platform} cho là cần thiết để xác định nguồn tiền khi xử lý yêu cầu nạp tiền và đồng ý cung cấp đầy đủ thông tin mà {platform} yêu cầu. Việc không cung cấp thông tin được yêu cầu có thể dẫn đến sự chậm trễ đáng kể trong quá trình xử lý yêu cầu của bạn. {platform} sẽ không chịu trách nhiệm cho bất kỳ sự chậm trễ hoặc tổn thất nào do sự chậm trễ đó gây ra.</li>`,
      channels: {
        creditOrDebit: 'Thẻ tín dụng / thẻ ghi nợ',
        i12BankTransfer: 'Chuyển khoản ngân hàng quốc tế',
        unionPay: 'Nạp Tiền Union Pay',
        unionPayCN: 'Chuyển khoản China Union Pay',
        vietInstant: 'Chuyển khoản nhanh ngân hàng Việt Nam',
        thaiInstant: 'Chuyển khoản nhanh ngân hàng Thái Lan',
        nigeriaInstant: 'Chuyển khoản nhanh ngân hàng Nigeria',
        malayInstant: 'Chuyển khoản nhanh ngân hàng Malaysia',
        indonesiaInstant: 'Chuyển khoản nhanh ngân hàng Indonesia',
        philippineInstant: 'Chuyển khoản nhanh ngân hàng Philippines',
        mobilePay: 'Thanh toán di động',
      },
      rate: {
        rate: 'Tỉ giá ({oldCurrency} / {newCurrency}):',
        result: {
          rateError: 'Lấy tỷ giá {oldCurrency} đến {newCurrency} không thành công.',
          channelError: 'Tìm nạp kênh thanh toán không thành công.',
        },
      },
      cn: { rate: 'Tỉ giá (USD / RMB):', amt: 'RMB: ' },
      upgrade:
        'Kênh thanh toán hiện đang được bảo trì và chỉ dành cho những người dùng đã gửi tiền thành công qua kênh này trước đó.',
      depositInstruction: 'Hướng dẫn nạp tiền',
      depositNote: 'Lưu ý nạp tiền',
    },
    method: {
      desc: 'Cách nạp tiền vào tài khoản của bạn với {method}',
      inst1: '{number}.   Hoàn thành mẫu dưới đây và nhấn ‘Gửi',
      inst2: `{number}.   Chọn ngân hàng trong trang chọn ngân hàng và đăng nhập vào ngân hàng trực tuyến {country} của bạn`,
      inst3: '{number}.   Làm theo hướng dẫn và tiến hành thanh toán bằng ngân hàng di dộng/ trực tuyến của bạn',
      inst4: '{number}.   Sau đó, bạn sẽ được chuyển hướng đến một mẫu đơn khác để nhập chi tiết thẻ của bạn',
      inst5: '{number}.   Sau khi nhập chi tiết thẻ của bạn, bấm Gửi',
      inst6: '{number}.   Bạn sẽ được chuyển hướng trở lại cổng khách hàng và giao dịch của bạn sẽ được hoàn tất',
      inst7: `{number}.   Nếu đơn vị tiền tệ của bạn không phải là {currency}, ngân hàng có thể tính phí chuyển đổi cho bạn theo tỷ giá hối đoái hiện hành của ngân hàng.`,
      inst8: `{number}.   Các khoản tiền gửi bằng Thẻ Tín dụng bằng EUR, GBP, AUD, SGD, NZD, HKD, JPY và USD được giới hạn ở mức tối đa là $ 5.000USD cho mỗi giao dịch. Tiền gửi bằng Thẻ tín dụng bằng CAD được chấp nhận lên đến $ 6,500CAD cho mỗi giao dịch`,
      inst9: `{number}.   Tối đa $ 1500 USD mỗi lần nạp`,
      inst10: `{number}.   Trang chi tiết thanh toán sẽ bật lên`,
      inst11: `{number}.   Chọn ngân hàng trong trang chọn ngân hàng và đăng nhập vào ngân hàng trực tuyến của bạn`,
      inst12: `{number}.   Chúng tôi có thể chấp nhận thanh toán bằng CAD. Tối đa $9,999 CAD mỗi lần nạp`,
      form: {
        header: 'Mẫu đơn {method}',
      },
    },
    reminder: {
      inst1: 'Hoàn thành mẫu dưới đây và nhấn ‘Gửi',
      inst2: 'Đăng nhập vào tài khoản {method} của bạn',
      inst3: 'Thực hiện theo các lời nhắc trong tài khoản {method} để hoàn tất thanh toán của bạn',
      inst4: 'Sau đó, bạn sẽ được chuyển hướng đến một mẫu đơn khác để nhập chi tiết thẻ của bạn',
      inst5: `Sau khi nhập chi tiết thẻ của bạn, bấm Gửi'`,
      inst6: 'Bạn sẽ được chuyển hướng trở lại cổng khách hàng và giao dịch của bạn sẽ được hoàn tất',
      inst7: 'Bạn sẽ được chuyển đến một cửa sổ mới hiển thị địa chỉ ví {method}',
      inst8:
        'Sao chép địa chỉ ví {method} sau đó đăng nhập vào tài khoản ví {method} của bạn và thực hiện chuyển số tiền mong muốn sang địa chỉ {method}',
      inst9: 'Quét mã QR để mở ứng dụng ngân hàng di động của bạn',
      inst10: 'Thực hiện theo các hướng dẫn trên thiết bị di động của bạn để hoàn tất khoản tiền nạp của bạn',
      inst11:
        'Vui lòng chắc chắn để thanh toán trực tuyến qua trang thanh toán chuyển hướng để tránh thất thoát tiền của bạn.',
      inst12: 'Chọn ngân hàng trong trang chọn ngân hàng và đăng nhập vào ngân hàng trực tuyến của bạn',
      inst13: 'Làm theo hướng dẫn và tiến hành thanh toán bằng ngân hàng di dộng/ trực tuyến của bạn',
      inst14:
        'Ngay khi bạn đã hoàn thành chuyển khoản, vui lòng hoàn thành mẫu bên dưới và đính kèm bản copy biên lai chuyển tiền thể hiện đầy đủ họ tên, số tài khoản, số tiền nạp để chúng tôi có thể xử lý khoản tiền nạp của bạn',
      inst15: 'Vui lòng chờ 2-5 ngày làm việc để tiền nạp được cộng vào tài khoản của bạn',
      inst16: 'Vui lòng chờ đợi 1 ngày làm việc để tiền được cộng vào tài khoản của bạn.',
      inst17:
        'Vui lòng đảm bảo bản sao hiển thị rõ ràng họ tên, số tài khoản và số tiền gửi để chúng tôi có thể xử lý giao dịch của bạn.',
      inst18: 'Please allow 1-6 hours for the funds to be credited to your account.',
      note1:
        'Các khoản nạp tiền {method} được giới hạn ở mức 25,000 USD mỗi giao dịch và tối đa là 75,000 USD mỗi ngày',
    },
    southAfrica: { header: 'Chuyển khoản nhanh ngân hàng Nam Phi', country: 'Nam Phi' },
    idn: { header: 'Chuyển khoản nhanh ngân hàng Indonesia', country: 'Indonesia' },
    ida: { header: 'Chuyển khoản nhanh ngân hàng Ấn độ', country: 'Ấn độ' },
    nigeria: { header: 'Chuyển khoản nhanh ngân hàng Nigeria', country: 'Nigeria' },
    thailand: { header: 'Chuyển khoản nhanh ngân hàng Thái Lan', country: 'Thái Lan' },
    malay: { header: 'Chuyển khoản nhanh ngân hàng Malaysia', country: 'Malaysia' },
    php: { header: 'Chuyển khoản nhanh ngân hàng Philippines', country: 'Philippines' },
    vietnam: { header: 'Chuyển khoản nhanh ngân hàng Việt Nam', country: 'Việt Nam' },
    korea: { header: 'Chuyển khoản nhanh ngân hàng Korea', country: 'Hàn Quốc' },
    uganda: { header: 'Chuyển khoản Ngân hàng Địa phương Uganda', country: 'Uganda' },
    rwanda: { header: 'Chuyển khoản Ngân hàng Địa phương Rwanda', country: 'Rwanda' },
    zambia: { header: 'Chuyển khoản Ngân hàng Địa phương Zambia', country: 'Zambia' },
    congo: { header: 'Chuyển khoản Ngân hàng Địa phương Congo', country: 'Congo' },
    cameroon: { header: 'Chuyển khoản Ngân hàng Địa phương Cameroon', country: 'Cameroon' },
    burundi: { header: 'Chuyển khoản Ngân hàng Địa phương Burundi', country: 'Burundi' },
    kenya: { header: 'Chuyển khoản Ngân hàng Địa phương Kenya', country: 'Kenya' },
    ghana: { header: 'Chuyển khoản Ngân hàng Địa phương Ghana', country: 'Ghana' },
    tanzania: { header: 'Chuyển khoản Ngân hàng Địa phương Tanzania', country: 'Tanzania' },
    creditOrDebit: {
      header: 'Thẻ tín dụng',
      worldcard: {
        note1:
          'Tiền nạp bằng Thẻ tín dụng sử dụng EUR, GPB và USD được giới hạn ở số tiền tối đa là 20,000USD cho mỗi giao dịch. Tiền nạp bằng Thẻ tín dụng bằng EUR, GPB và USD được giới hạn ở số tiền tối đa là 20,000USD hoặc tiền tệ tương đương cho mỗi giao dịch',
        note2:
          ' Số lượng thẻ khác nhau cho mỗi người dùng là 3. Mọi nỗ lực nạp tiền vượt quá giới hạn sẽ không được chấp nhận.',
      },
      virtualPay: {
        note1:
          'Tiền nạp bằng Thẻ tín dụng sử dụng EUR, GBP, AUD, SGD, NZD, HKD, JPY, USC và USD được giới hạn ở mức tối đa 10,000 USD cho mỗi giao dịch.',
        note2:
          'Tối đa 50,000 USD hoặc 10 giao dịch trên mỗi thẻ trong vòng 24 giờ. Mọi nỗ lực nạp tiền vượt quá giới hạn sẽ không được chấp nhận.',
      },
      Sdpay: {
        note1:
          'Tiền nạp bằng Thẻ tín dụng sử dụng EUR, GBP và USD được giới hạn ở mức tối đa 2,000 USD hoặc tiền tệ tương đương cho mỗi giao dịch.',
        note2: 'Tối đa 8 giao dịch mỗi ngày. Mọi nỗ lực nạp tiền vượt quá giới hạn sẽ không được chấp nhận.',
        note3:
          'Xin lưu ý rằng có thể có khoản tạm giữ áp dụng cho các khoản thanh toán lần đầu của bạn. Vui lòng kiên nhẫn trong khi đội ngũ của chúng tôi đang xác minh khoản thanh toán của bạn hoặc bạn có thể liên hệ với đội ngũ hỗ trợ khách hàng của chúng tôi để được hỗ trợ thêm.',
      },
      maxisPay: {
        note1: 'Tiền nạp bằng thẻ tín dụng sử dụng USD bị giới hạn số tiền tối đa là $ 5.000USD cho mỗi giao dịch',
      },
    },
    net: {
      header: 'Neteller',
      note1: 'Chúng tôi có thể chấp nhận thanh toán bằng AUD, EUR, CAD và GBP',
      note2: 'Tài khoản có thể chỉ được nạp tiền cùng với loại tiền tệ như tài khoản giao dịch MT4 của bạn.',
      note3:
        'Neteller sẽ tự động chuyển tiền sang loại tiền tệ cơ bản của tài khoản của bạn nếu địa chỉ email không đúng. Phí có thể áp dụng. ',
      note4: 'Tiền nạp phải giống tên như tài khoản giao dịch PU Prime.',
    },
    vload: {
      header: 'NẠP VLOAD',
      inst1: '2.   Chúng tôi có thể chấp nhận thanh toán bằng USD, EUR và JPY.',
      inst2: `<li>3.   Mua voucher Vload, nhấn vào <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">đây</a>.</li>`,
      form: {
        voucherpin: 'Ghim phiếu thưởng',
        voucherpinReq: 'Cần phải có PIN của phiếu thưởng',
      },
    },
    bankWireAu: {
      header: 'CHUYỂN KHOẢN NGÂN HÀNG: AUSTRALIA',
      instruction:
        'Tiền có thể được chuyển đến {platform} thông qua tổ chức tài chính của bạn, cơ sở ngân hàng trực tuyến, hoặc bằng cách liên hệ với tổ chức tài chính của bạn và yêu cầu chuyển đến {platform}.',
      tip:
        'Chọn loại tiền tệ tài khoản bạn muốn gửi vào. Vui lòng đảm bảo bạn chọn cùng loại tiền tệ với loại tiền tệ trong tài khoản giao dịch của bạn',
      form: {
        bankDetails: 'Chi tiết ngân hàng (Chỉ gửi tiền bằng AUD)',
        refTip: 'Vui lòng chỉ định Số Đăng nhập của bạn tại đây',
        afp: 'Thanh toán nhanh Úc (CHỈ nạp tiền AUD trong nước)',
        payIdOrABNNum: 'Số PayID / ABN',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip:
          'Vui lòng bao gồm số tài khoản giao dịch {platform} của bạn trong phần tham chiếu chuyển khoản ngân hàng.',
      },
    },
    bitwallet: {
      header: 'Bitwallet',
      note1: 'Chúng tôi chấp nhận thanh toán bằng USD, AUD, EUR và JPY',
    },
    astropay: {
      desc: 'Cách nạp tiền vào tài khoản của bạn với ASTROPAY',
      note1: 'Chúng tôi chấp nhận thanh toán bằng tất cả các đồng tiền.',
      form: { header: 'MẪU ASTROPAY' },
    },
    bPay: {
      header: 'NẠP TIỀN BPAY',
      option: 'Cách nạp tiền vào tài khoản của bạn với BPay (chỉ ở Úc)',
      inst1: '1. Chọn tài khoản MT4 / MT5 của bạn để xem Mã hóa đơn BPay và Số tham chiếu BPay của bạn',
      inst2:
        '2. Nhập chi tiết thanh toán, Mã hóa đơn BPay và Số tham chiếu BPay của bạn vào cơ sở ngân hàng internet và gửi thanh toán của bạn',
      inst3:
        '3. Quay trở lại trang này và hoàn thành biểu mẫu BPay bên dưới để đảm bảo chúng tôi có thể xử lý kịp thời giao dịch của bạn',
      form: {
        header: 'MẪU ĐƠN BPAY',
        billerCode: 'Mã hóa đơn BPay',
        refNum: 'Số tham chiếu của BPay',
        receipt: 'Biên lai chuyển tiền BPAY',
        reminderNotUploaded:
          'Chúng tôi đã nhận thấy rằng bạn chưa tải lên biên lai giao dịch. Để xử lý khoản tiền gửi của bạn, vui lòng gửi email một bản sao hóa đơn cho {mailTo} một cách sớm nhất có thể.',
        reminderProc:
          'Xin lưu ý. Giao dịch của bạn KHÔNG THỂ được xử lý cho đến khi chúng tôi nhận được biên nhận của bạn.',
      },
    },
    b2b: {
      header: 'CHUYỂN TIỀN TỪ BROKER KHÁC ',
      option: 'Cách chuyển tiền từ một nhà môi giới khác sang {platform}',
      inst1: '1. Tải xuống và hoàn thành {download}',
      inst2: '2. Hoàn thành Biểu mẫu chuyển khoản Môi giới đến Môi giới trực tuyến bên dưới và tải lên.',
      inst3:
        '3. Gửi một bản sao Biểu mẫu chuyển khoản Môi giới đến Môi giới cho sàn Forex cũ để thực hiện chuyển khoản',
      b2bForm: 'BIỂU MẪU MÔI GIỚI ĐẾN MÔI GIỚI',
      formValidation: { upload: 'BIỂU MẪU MÔI GIỚI ĐẾN MÔI GIỚI LÀ BẮT BUỘC' },
    },
    cc: {
      header: 'NẠP TIỀN QUA THẺ TÍN DỤNG/THẺ GHI NỢ ',
      remind:
        'Nếu bạn gặp bất kỳ khó khăn nào khi tài trợ cho tài khoản của mình bằng phương thức Chính, vui lòng sử dụng phương thức Tín dụng / Ghi nợ phụ',
      field: {
        fundNow: 'Nạp tiền bằng thẻ tín dụng / thẻ ghi nợ',
        newCard: 'Thẻ mới',
        cardNum: 'Số thẻ tín dụng',
        nameOnCard: 'Tên Trên Thẻ',
        expDate: 'Ngày hết hạn',
        cvv: 'Mã bảo mật CVV',
        bankCode: 'Ngân hàng phát hành thẻ',
        example: `Thẻ đơn giản`,
      },
      placeholder: { bankCode: 'Vui lòng nhập tên ngân hàng phát hành thẻ của bạn' },
      cvvTip: '3 chữ số được hiển thị trên mặt sau thẻ của bạn',
      threeDomainSecQ: 'Thẻ của bạn có hỗ trợ 3D-Secure không?',
      threeDomainSec:
        'Xác thực bảo mật 3D là gì? <br /> <strong> Xác thực bảo mật 3D là phương thức phòng chống gian lận được công nhận bởi Visa và Mastercard. <br /> <br /> Nếu thẻ của bạn hỗ trợ Bảo mật 3D, tiền của bạn sẽ được xử lý ngay lập tức.',
      cardUpload:
        'Thẻ bạn đang sử dụng để nạp tiền đã không được liên kết với tài khoản giao dịch của bạn. Vui lòng tải lên một hình ảnh mặt trước của thẻ của bạn để xác minh. (Vui lòng bao gồm 6 chữ số ở giữa)',
      result: {
        success:
          'Cảm ơn bạn. Chúng tôi hiện đang xác minh chi tiết thẻ của bạn và một khi tiền được xác minh sẽ xuất hiện trong tài khoản của bạn trong vòng 24 giờ.',
        depositSuccess: 'Cảm ơn bạn đã nạp tiền, tiền của bạn sẽ sớm xuất hiện trong tài khoản của bạn',
        error:
          'Để bảo vệ tính bảo mật của tài khoản của bạn, bạn đã nạp lỗi quá nhiều lần và hệ thống tạm thời đóng chức năng nạp tiền vào tài khỏan. Vui lòng liên hệ với {email}',
      },
      tipOnlyTaiwan: `Xin lưu ý rằng việc rút tiền qua thẻ tín dụng có thể bị chậm trễ do ngân hàng xử lý và thời gian đến hoàn toàn phụ thuộc vào từng ngân hàng. Nếu bạn cần tiền gấp, bạn nên sử dụng các phương thức khác để nạp / rút tiền.`,
      reminder: `<li>1. Các khoản tiền gửi bằng Thẻ Tín dụng bằng EUR, GBP và USD được giới hạn ở mức tối đa là $1,900 USD hoặc các đơn vị tiền tệ tương đương cho mỗi giao dịch.</li>
      <li>2. Tối đa 8 giao dịch mỗi ngày.</li>
      <li>3. Xin lưu ý rằng có thể có một khoản giữ lại áp dụng cho các khoản thanh toán lần đầu tiên của bạn. Hãy kiên nhẫn trong khi nhóm của chúng tôi đang xác minh khoản thanh toán của bạn hoặc bạn có thể liên hệ với nhóm hỗ trợ khách hàng của chúng tôi để được hỗ trợ thêm.</li>`,
    },
    fasa: {
      description: 'Cách nạp tiền vào tài khoản của bạn với FasaPay',
      form: { header: 'MẪU FASAPAY' },
    },
    intSwift: {
      header: 'Chuyển khoản ngân hàng: Điện SWIFT QUỐC TẾ',
      desc:
        'Tiền có thể được chuyển đến {platform} thông qua tổ chức tài chính của bạn, cơ sở ngân hàng trực tuyến, hoặc bằng cách liên hệ với tổ chức tài chính của bạn và yêu cầu chuyển đến {platform}.',
      desc2:
        'Ngay khi bạn đã hoàn thành chuyển khoản, vui lòng hoàn thành mẫu bên dưới và đính kèm bản copy biên lai chuyển tiền thể hiện đầy đủ họ tên, số tài khoản, số tiền nạp để chúng tôi có thể xử lý khoản tiền nạp của bạn',
      alertSAandNGA:
        'Nếu bạn giữ một đơn vị tiền tệ không phải là đô la Mỹ, vui lòng đổi số tiền chuyển sang đô la Mỹ trước khi chuyển tiền. Nếu bạn chuyển trực tiếp, chênh lệch tỷ giá hối đoái và phí xử lý phát sinh tại thời điểm đó sẽ do khách hàng chịu.',
      options: 'Vui lòng chọn tài khoản giao dịch bạn muốn nạp tiền',
      refInfo:
        'Vui lòng bao gồm số tài khoản giao dịch {platform} của bạn trong phần tham chiếu chuyển khoản ngân hàng.',
      bussDayInfo: 'Vui lòng chờ 2-5 ngày làm việc để tiền nạp được cộng vào tài khoản của bạn',
      tip:
        'Vui lòng đảm bảo rằng biên lai chuyển tiền của bạn thể hiện rõ ràng <b> Họ tên </b>, <b> số tài khoản </b> và <b> số tiền nạp </b> của bạn',
    },
    mobile: {
      header: 'THANH TOÁN DI ĐỘNG',
      desc: 'Cách nạp tiền vào tài khoản của bạn với Thanh Toán Di Động',
      inst1: '1. Hoàn thành mẫu dưới đây và nhấn ‘Gửi',
      inst2: '2. Quét mã QR để mở ứng dụng ngân hàng di động của bạn',
      inst3: '3. Thực hiện theo các hướng dẫn trên thiết bị di động của bạn để hoàn tất khoản tiền nạp của bạn',
      form: { payMethod: 'Phương thức thanh toán:', rate: 'Tỉ giá (USD / CNY):', amt: 'CNY:' },
      formValidation: { cnyAmt: 'Số tiền CNY không thể nhiều hơn $ {limit}' },
    },
    crypto: {
      choose: 'Vui lòng chọn loại tiền điện tử ưa thích của bạn',
      proceedQuestion: 'Bạn có muốn tiếp tục với khoản tiền gửi <b>{method}</b> của mình không?',
      note:
        'Xin lưu ý rằng chúng tôi không thể nạp hoặc rút tiền qua BUSDT, vui lòng đảm bảo địa chỉ và Tiền điện tử khớp với chuỗi và đơn vị tiền tệ mà chúng tôi chấp nhận, nếu không bạn có thể bị mất tiền.',
      note2:
        'Vui lòng chú ý rằng chúng tôi không thể nạp hoặc rút tiền qua BUSDT hoặc ETH, vui lòng đảm bảo địa chỉ và tiền kỹ thuật số khớp với chuỗi và loại tiền tệ mà chúng tôi chấp nhận hoặc bạn có thể bị lạc mất tiền. Chúng tôi không chịu trách nhiệm cho bất kỳ tổn thất phát sinh do những lỗi như này.',
      remark:
        'Xin lưu ý rằng chúng tôi không thể nạp hoặc rút tiền qua BUSDT, vui lòng đảm bảo địa chỉ và Tiền điện tử khớp với chuỗi và đơn vị tiền tệ mà chúng tôi chấp nhận, nếu không bạn có thể bị mất tiền.',
      inst2: '2. Bạn sẽ được chuyển đến một cửa sổ mới hiển thị địa chỉ ví {method}',
      inst3:
        '3. Sao chép địa chỉ ví {method} sau đó đăng nhập vào tài khoản ví {method} của bạn và thực hiện chuyển số tiền mong muốn sang địa chỉ {method}',
      inst4: `Ghi chú: Các khoản tiền nạp bằng BITCOIN được xử lý bởi các nhà cung cấp dịch vụ thanh toán với tỷ giá được cung cấp tại thời điểm giao dịch.`,
      bitcoin: {
        desc: 'Cách nạp tiền vào tài khoản bằng BITCOIN',
      },
      usdt: {
        desc: 'Cách nạp tiền vào tài khoản bằng USDT(OMNI)',
        inst1: '1. Điền vào mẫu bên dưới và click ‘Nộp’',
        inst2: '2. Bạn sẽ được chuyển đến một cửa sổ mới hiển thị địa chỉ ví USDT(OMNI)',
        inst3:
          '3. Sao chép địa chỉ ví USDT(OMNI) sau đó đăng nhập vào tài khoản ví USDT(OMNI) của bạn và thực hiện chuyển số tiền mong muốn sang địa chỉ USDT(OMNI)',
        inst4: `Ghi chú: Các khoản tiền nạp bằng USDT(OMNI) được xử lý bởi các nhà cung cấp dịch vụ thanh toán với tỷ giá được cung cấp tại thời điểm giao dịch.`,
      },
      usdt_CPS: {
        desc: 'Cách nạp tiền vào tài khoản bằng USDT({name})',
      },
      form: {
        header_bitcoin: 'MẪU ĐƠN BITCOIN',
        header_usdt: 'MẪU ĐƠN USDT(OMNI)',
        header_usdt_CPS: 'MẪU ĐƠN USDT({name})',
      },
    },
    skrill: {
      header: 'NẠP TIỀN SKRILL/MONEYBOOKERS',
      desc: 'Cách nạp tiền vào tài khoản của bạn với Skrill',
      note1: 'Chúng tôi chấp nhận thanh toán bằng đồng USD, EUR, GBP và CAD',
      note2: 'Tài khoản có thể chỉ được nạp tiền cùng với loại tiền tệ như tài khoản giao dịch MT4 của bạn.',
      note3:
        'Skrill sẽ tự động chuyển đổi tiền sang loại tiền tê cơ bản của tài khoản của bạn nếu địa chỉ email bị sai. Phí có thể tính.',
      note4: 'Tiền nạp phải giống tên như tài khoản giao dịch PU Prime.',
      form: { header: 'MẪU ĐƠN SKRILL', email: 'Skrill/Moneybookers Email' },
      reminder:
        '<li>Xin lưu ý:</li><li> * Tài khoản chỉ có thể được nạp bằng cùng loại tiền với tài khoản giao dịch MT4 của bạn. Skrill sẽ tự động chuyển đổi tiền sang tài khoản cơ sở của bạn nếu sử dụng email không chính xác. Phí có thể áp dụng. </ Li> <li>* Tiền nạp phải cùng tên trên tài khoản {platform} của bạn</li>',
    },
    unionPay: {
      header: ' NẠP TIỀN QUA UNION PAY',
      desc: 'Cách tìm tài khoản của bạn với UnionPay',
      inst1: '1. Hoàn thành mẫu dưới đây và nhấn ‘Gửi',
      inst2: '2. Chọn ngân hàng trong trang chọn ngân hàng và đăng nhập vào ngân hàng trực tuyến của bạn',
      inst3: '3. Làm theo hướng dẫn và tiến hành thanh toán bằng ngân hàng di dộng/ trực tuyến của bạn',
      form: { header: 'MẪU ĐƠN UNION PAY' },
    },
    unionPayCN: {
      header: 'Chuyển khoản China Union Pay',
      desc: 'Cách nạp tiền vào tài khoản của bạn với Chuyển khoản thanh toán của China Union',
      inst1: 'Trang chi tiết A Payment sẽ được bật lên',
      inst2:
        'Bạn có thể được yêu cầu về số thành viên khi nạp tiền, vui lòng nhập vào số tài khoản giao dịch của bạn như số thành viên.',
      note1: 'Số tiền giao dịch tối thiểu mỗi lệnh nạp là 500 CNY và số tiền tối đa cho mỗi lệnh nạp là 49,999 CNY. ',
      note2: 'Vui lòng chú ý rằng giới hạn số tiền nạp có thể cập nhất theo thời gian phụ thuộc vào thị trường. ',
      note3:
        'Vui lòng không sao chép chi tiết thụ hưởng ngân hàng được hiển thị vì nó có thể được cập nhật theo thời gian.',
      form: {
        header: 'MẪU ĐƠN CHINA UNION PAY',
        holderName: 'Tên chủ thẻ UnionPay:',
        holderNameInfo: 'Vui lòng đảm bảo tên bạn nhập ở trên phải giống với tên chủ thẻ ngân hàng của bạn',
      },
    },
    paypal: {
      header: 'PayPal',
      desc: 'Cách nạp tiền vào tài khoản của bạn với PayPal',
      inst1: '1. Hoàn thành mẫu dưới đây và nhấn ‘Gửi',
      inst2: '2. Đăng nhập vào tài khoản PayPal của bạn',
      inst3: '3. Thực hiện theo các lời nhắc và trong tài khoản PayPal để hoàn tất thanh toán của bạn',
      inst4: 'Xin lưu ý: Chúng tôi không thể chấp nhận thanh toán từ bên thứ ba',
      form: { header: 'MẪU ĐƠN PAYPAL' },
    },
    cardpay: {
      desc: 'Cách nạp tiền vào tài khoản của bạn bằng Thẻ tín dụng',
      inst1: '1. Hoàn thành mẫu dưới đây và nhấn ‘Gửi',
      inst2: '2. Sau đó, bạn sẽ được chuyển hướng đến một mẫu đơn khác để nhập chi tiết thẻ của bạn',
      inst3: "3. Sau khi nhập chi tiết thẻ của bạn, bấm Gửi'",
      inst4: '4. Bạn sẽ được chuyển hướng trở lại cổng khách hàng và giao dịch của bạn sẽ được hoàn tất',
      form: { header: 'MẪU ĐƠN THẺ TÍN DỤNG' },
    },
    tinkbit: { inst5: '5. Tối đa $ 1500 USD mỗi lần nạp' },
    perfectMoney: {
      inst1: '2.   Đăng nhập vào tài khoản Perfect Money của bạn',
      inst2: '3.   Thực hiện theo các lời nhắc trong tài khoản Perfect Money để hoàn tất thanh toán của bạn',
    },
    indiaBankTransfer: {
      desc1:
        'Khi bạn đã thực hiện chuyển khoản, vui lòng hoàn thành biểu mẫu bên dưới và đính kèm bản sao biên lai chuyển khoản ngân hàng.',
      desc2:
        'Vui lòng đảm bảo bản sao hiển thị rõ ràng họ tên, số tài khoản và số tiền gửi để chúng tôi có thể xử lý giao dịch của bạn.',
      bussDayInfo: 'Vui lòng đợi 1-6 giờ để tiền được ghi có vào tài khoản của bạn. ',
    },
  },
  withdraw: {
    title: 'Rút tiền',
    default: {
      accountDetail: 'Thông tin tài khoản',
      withdrawalAmount: 'Số tiền rút',
      example: 'Credit/Debit Thí dụ',
      alert:
        'Do luật AML, phương thức bạn sử dụng để rút phải giống với phương thức được sử dụng để gửi tiền của bạn. Nếu số tiền rút ít hơn 100 đơn vị tiền tệ cơ sở trong tài khoản của bạn, bạn sẽ bị ngân hàng tính 20 đơn vị phí xử lý.',
      how: 'Bạn đã nạp tiền cho tài khoản giao dịch của mình như thế nào ?',
      success: 'Yêu cầu rút tiền của bạn đã thành công và sẽ sớm được xử lý',
      selectCC: 'Chọn thẻ tín dụng',
      selectBA: 'Chọn tài khoản ngân hàng',
      anotherCC: 'Nhập thẻ tín dụng bằng cách thủ công',
      anotherBA: 'Thêm tài khoản ngân hàng',
      remember: 'Ghi nhớ tài khoản của tôi',
      ccFail:
        'Bạn đã thử tối đa số lần nạp tiền không thành công và chức năng rút tiền sẽ tạm thời bị khóa để bảo vệ tính bảo mật của tài khoản của bạn. Vui lòng liên hệ với {email}.',
      checkPosFailed: 'Kiểm tra lệnh không thành công',
      fetchTradeAccFailed: 'Tìm nạp tài khoản giao dịch không thành công',
      chooseAnother: `<p>số tiền rút tối đa cho thẻ này: 0</p> 
      <p>Vui lòng chọn thẻ hoặc phương thức thanh toán khác</p>`,
      notEnoughFunds: 'Số tiền nhập vượt quá giới hạn thẻ',
      failed: 'Rút tiền thất bại. Vui lòng thử lại sau',
      unfortunate:
        'Thật không may, chúng tôi không còn có thể xử lý rút tiền vào thẻ tín dụng. Trong tương lai, việc rút tiền sẽ được xử lý thông qua chuyển khoản ngân hàng vào tài khoản ngân hàng được chỉ định của bạn.',
      noCardWarn: 'Bạn không có thẻ nào để rút tiền, vui lòng liên kết thẻ của bạn trong Chi tiết rút tiền trước.',
      equityStop:
        'Thật không may, việc rút tiền của bạn không thể được xử lý vì điều đó sẽ dẫn đến tài khoản của bạn có mức ký quỹ khả dụng thấp hơn mức dừng 100%',
      equityMargin:
        'Rất tiếc, chúng tôi hiện không thể xử lý lệnh rút tiền của bạn vì các lệnh giao dịch của bạn đang đuợc hedging toàn bộ.',
      equityContinue:
        'Chúng tôi đã phát hiện ra bạn hiện đang có các lệnh đang mở, bạn có thể gặp phải tình trạng tự đóng lệnh sau khi gửi đơn xin rút tiền, vui lòng xác nhận.',
      disclaimer: `<li>Rút tiền trong khi bạn có các vị thế mở sẽ ảnh hưởng đến số tiền ký quỹ của bạn. Vui lòng đảm bảo bạn đóng các vị thế mở hoặc đảm bảo bạn để lại đủ tiền trong tài khoản của mình để tránh bị ký quỹ.</li>
        <li>{platform} hoàn toàn không chấp nhận thanh toán cho bất kỳ bên thứ ba nào. Tất cả số tiền được rút từ tài khoản giao dịch của bạn phải có cùng tên với tài khoản cổng thông tin khách hàng {platform} của bạn.</li>
        <li>Tất cả các yêu cầu rút tiền ban đầu về thẻ tín dụng/thẻ ghi nợ, ví điện tử, moneybookers và chuyển khoản ngân hàng phải được xác minh về độ an toàn và bảo mật bằng cách cung cấp bảng sao kê ngân hàng, bao gồm thông tin chủ tài khoản và chi tiết ngân hàng.</li>
        <li>{platform} có quyền từ chối xử lý yêu cầu rút tiền nếu có cơ sở hợp lý để tin rằng tài khoản giao dịch có thể chuyển thành số dư âm sau khi yêu cầu rút tiền được xử lý.</li>
        <li>{platform} có quyền từ chối yêu cầu rút tiền nếu tài khoản giao dịch bị bồi hoàn hoặc bất kỳ cuộc điều tra nào khác đang diễn ra.</li>
        <li>{platform} không chịu trách nhiệm về bất kỳ khoản phí nào mà tổ chức tài chính nhận tiền hoặc các nhà cung cấp dịch vụ thanh toán bên thứ ba có thể tính phí bạn khi nhận thanh toán.</li>`,
      YourPaymentGreaterThanAvailableBalance: 'Khoản thanh toán của bạn lớn hơn số dư khả dụng',
      blackList: `Rất tiếc, bạn hiện không thể gửi yêu cầu rút tiền. Vui lòng liên hệ {supportEmail} để biết thêm chi tiết.`,
      NetellerChargeAlert:
        'Rút tiền qua Neteller được giới hạn ở mức 10.000 đô la cho mỗi giao dịch và áp dụng phí 2% (giới hạn ở mức 30 đô la).',
      agreePolicy: 'Tôi đã đọc và đồng ý với{link}',
      withdrawPolicy: 'chính sách rút tiền',
      addWithdrawalMethod: 'Thêm phương thức rút tiền',
      addSecondaryMethod: 'Thêm phương thức phụ',
      withdrawNote:
        'Xin lưu ý rằng chúng tôi sẽ xử lý việc rút tiền của bạn chủ yếu thông qua phương thức thẻ tín dụng. Nếu số tiền thẻ tín dụng của bạn đã đến giới hạn, chúng tôi sẽ yêu cầu bạn chọn một phương thức khác để hoàn tất yêu cầu rút tiền của mình.',
      submitYourWithdrawal: 'Nộp yêu cầu rút tiền',
      remainingWithdraw: 'Số tiền rút còn lại sẽ được chia sang thẻ tín dụng hoặc phương thức rút tiền khác.',
      onlyWithdrawalCcDesc: `Chỉ số tiền {ccAmount} trong thẻ tín dụng mới được rút toàn bộ.`,
    },
    fasa: {
      field: { accName: 'Tên tài khoản FasaPay', accNum: 'Số tài khoản FasaPay' },
      formValidation: {
        accNameReq: 'Tên tài khoản FasaPay là bắt buộc',
        accNumReq: 'Số tài khoản FasaPay là bắt buộc',
      },
      fasaPayChargeAlert: 'FasaPay tính phí giao dịch 0,5% cho mỗi lần rút tiền.',
    },
    neteller: { field: { email: 'Neteller Email' }, formValidation: { emailReq: 'Email Neteller là bắt buộc' } },
    vload: {
      field: { email: 'Vload Email đã đăng ký' },
      formValidation: { emailReq: 'Cần có Email đã đăng ký Vload' },
    },
    bitcoin: {
      field: { address: 'Địa chỉ ví Bitcoin' },
      formValidation: {
        addressReq: 'Địa chỉ ví Bitcoin là bắt buộc',
        addressFormat: `Địa chỉ ví của bạn phải bắt đầu bằng '1' hoặc '3' hoặc 'bc1'`,
        addressLength: 'Địa chỉ ví Bitcoin phải chứa từ 26-42 ký tự',
      },
    },
    eth: {
      field: { address: 'Địa chỉ ví ETH' },
      formValidation: {
        addressReq: 'Địa chỉ ví ETH là bắt buộc',
        addressFormat: 'Địa chỉ ví ETH là bắt buộc',
        addressLength: 'Địa chỉ ví ETH phải chứa từ 26-42 ký tự',
      },
    },
    usdc: {
      field: { address: 'Địa chỉ ví USDC' },
      formValidation: {
        addressReq: 'Địa chỉ ví USDC là bắt buộc',
        addressFormat: 'Địa chỉ ví USDC là bắt buộc',
        addressLength: 'Địa chỉ ví USDC phải chứa từ 26-42 ký tự',
      },
    },
    usdt: {
      field: { address: 'Địa chỉ ví USDT' },
      formValidation: {
        addressReq: 'Địa chỉ ví USDT là bắt buộc',
        trc20WalletFormatIncorrect: `Địa chỉ ví của bạn phải bắt đầu bằng 'T'`,
        erc20WalletFormatIncorrect: `Địa chỉ ví của bạn phải bắt đầu bằng '0x'`,
      },
    },
    paypal: { field: { email: 'Paypal Email' }, formValidation: { emailReq: 'Email Paypal là bắt buộc' } },
    skrill: {
      field: { email: 'Skrill Email' },
      formValidation: { emailReq: 'Email Skrill là bắt buộc' },
      skrillChargeAlert: 'Skrill tính phí giao dịch 1% cho mỗi lần rút tiền.',
    },
    sticpay: {
      field: {
        email: 'SticPay Email',
      },
      formValidation: {
        emailReq: 'Email SticPay là bắt buộc',
      },
    },
    bitwallet: {
      field: {
        email: 'Bitwallet Email',
      },
      formValidation: {
        emailReq: 'Email Bitwallet là bắt buộc',
      },
    },
    astropay: {
      field: {
        accountNumber: 'Tài khoản Astropay',
      },
      formValidation: {
        accountNumberReq: 'Tài khoản Astropay là bắt buộc',
      },
    },
    perfectMoney: {
      field: { email: 'Perfect Money Email' },
      formValidation: { emailReq: 'Email Perfect Money là bắt buộc' },
    },
    transfer: {
      info:
        '{platform} sẽ hoàn trả phí ngân hàng được tính cho rút tiền <u> ONE </ u> mỗi tháng. Rút tiền bổ sung trong tháng sẽ phải chịu một khoản phí ngân hàng là 20 đơn vị tiền tệ rút tiền của bạn.',
      label: { upload: 'Vui lòng tải lên bản sao bảng sao kê ngân hàng của bạn (trong vòng 3 tháng)' },
      formValidation: {
        upload: 'Vui lòng tải lên một sao kê ngân hàng',
        emailReq: 'Email là bắt buộc',
      },
    },
    otherMethod: {
      withdrawalMethod: 'Phương thức rút tiền',
      manageWithdrawalDetails: 'Quản lý thông tin rút tiền',
      verificationCode: 'Mã xác minh được gửi đến {email}. Vui lòng liên hệ {supportEmail} nếu bạn cần hỗ trợ thêm',
    },
  },
  transfer: {
    creditWarn:
      'Chúng tôi không thể xử lý chuyển tiền của bạn từ tài khoản đã chọn do tài khoản của bạn có chứa tín dụng khuyến mại.',
    blackListWarn:
      'Chuyển tiền từ tài khoản MAM của bạn có thể dẫn đến việc thanh khoản các lệnh được quản lý. Vui lòng liên hệ với {mailTo} nếu bạn muốn chuyển tiền từ tài khoản này.',
  },
  changePw: {
    header: 'ĐỔI MẬT KHẨU',
    field: { currPw: 'Mật khẩu hiện tại', newPw: 'Mật Khẩu Mới', confirmPw: 'Xác nhận mật khẩu mới' },
    succ:
      'Mật khẩu của bạn đã được cập nhật. Bây giờ bạn có thể sử dụng mật khẩu mới của mình để truy cập Cổng thông tin khách hàng.',
  },
  promotion: {
    clickToGet: 'Chỉ cần nhấp và nhận $ 50 Tiền thưởng của bạn',
    apply: 'Áp dụng ngay bây giờ',
    start: 'Bắt đầu Ngay bây giờ',
    tnc: `
    <li> 1. Chương trình này có hiệu lực trong 30 ngày.</li>
    <li> 2. Ưu đãi này dành cho tất cả khách hàng (Hiện tại & Mới) có tài khoản trực tiếp nhưng chưa bao giờ gửi tiền trước đó.</li>
    <li> 3. Ưu đãi này dành cho khách hàng mới ở các khu vực sau: Pháp, Vương quốc Anh, Đài Loan, Ý, Canada, Bỉ, Thụy Sĩ, HongKong, Nigeria, Hà Lan, Nhật Bản, Luxembourg, Malta, Reunion, Đức, Martinique, Tây Ban Nha, Latvia, Ba Lan , Côte d'Ivoire, Đan Mạch, Brazil, New Zealand, Bồ Đào Nha, Đảo Man, Nam Phi, Thổ Nhĩ Kỳ, Romania, Guadeloupe, Áo, Ireland, Guiana thuộc Pháp, Swaziland, Argentina, Ukraine, Hy Lạp, Thụy Điển, Monaco, Hungary, Haiti , Phần Lan, Bolivia, Chile, Costa Rica, Croatia, Cộng hòa Séc, Estonia, Mexico, Na Uy, Philipine.</li>
    <li> 4. Ưu đãi này chỉ đủ điều kiện trên Tài khoản Chuẩn và Tài khoản Chuẩn Hồi giáo.</li>
    <li> 5. Nghiêm cấm nhận tiền thưởng và / hoặc giao dịch tiền thưởng TRÊN HAI TÀI KHOẢN HOẶC NHIỀU TÀI KHOẢN có cùng địa chỉ IP / ID / hộ chiếu. Bất kể số lượng tài khoản giao dịch mà Khách hàng có thể nắm giữ với PU Prime, chương trình khuyến mãi chỉ áp dụng cho một tài khoản.</li>
    <li> 6. Khách hàng sẽ nhận được khoản tín dụng 50 USD (hoặc tương đương) sau khi chọn tham gia ưu đãi này thành công và không yêu cầu đặt cọc.</li>
    <li> 7. Số tiền tín dụng không thể được rút ra. Tuy nhiên, bạn vẫn có thể rút một phần lợi nhuận kiếm được từ giao dịch của mình.</li>
    <li> 8. Bạn phải đóng tất cả các vị thế đang mở trước khi đăng ký rút tiền. Khi bạn chọn rút tiền, tiền thưởng sẽ được trừ vào tài khoản.</li>
    <li> 9. PU Prime có quyền thay đổi các điều kiện hoặc từ chối cấp tiền thưởng bất kỳ lúc nào.</li>`,
    selectDesp: '* Vui lòng chọn tài khoản mà bạn muốn tham gia hoạt động Tiền thưởng Không cần Nạp tiền.',
    descp1: 'Bạn có muốn bắt đầu giao dịch mà không cần cam kết đầu tư ngay từ đầu không?',
    descp2: `Áp dụng khoản tiền thưởng $ 50 của bạn để bắt đầu kiếm <br>lợi nhuận mà không phải chịu bất kỳ rủi ro nào về số tiền của bạn.`,
    header: 'ĐIỀU KHOẢN VÀ ĐIỀU KIỆN KHUYẾN MÃI',
    tnc2:
      '<li> Số tiền tối thiểu bạn có thể rút là 100 USD （hoặc số tiền tương đương） và số tiền tối đa để rút là 200 USD （hoặc số tiền tương đương）. Mọi lợi nhuận thu được từ số tiền giới hạn này sẽ bị xóa khi chúng tôi xử lý yêu cầu rút tiền của bạn.</li>',
    successful: 'gửi thành công',
    failed: 'Gửi không thành công',
    referFriends: {
      intro: `Hoàn thành 3 bước sau để nhận {amount}`,
      step1: 'bước đầu tiên',
      step1Descp: 'Đã mở tài khoản thành công',
      step2: 'Bước thứ hai',
      step2Descp: 'Gửi tiền thành công',
      step3: 'bước thứ ba',
      step3Descp: 'Khối lượng giao dịch',
      totalEanringTitle: `Tổng số tiền rút <br/>(USD hoặc tiền tệ tương đương)`,
      totalEarningTip: '* Số tiền thưởng có thể được xem xét.',
      redeem: 'trích xuất',
      intro2: 'Đồng thời, bạn cũng có thể giới thiệu bạn bè của mình',
      shareLink: 'Chia sẻ liên kết đề xuất được cá nhân hóa của bạn',
      shareLinkDescp:
        'Nhấp vào "Chia sẻ" để nhận liên kết đề xuất duy nhất và sao chép nó vào các phương tiện truyền thông xã hội khác nhau',
      share: 'Chia sẻ',
      total: 'Tổng số lượt giới thiệu đã đổi',
      social: 'Chia sẻ liên kết của bạn thông qua các phương tiện truyền thông xã hội lớn',
      clients: 'khách hàng',
      table: {
        referralsNo: 'Số đề xuất',
        name: 'Tên',
        country: 'Quốc gia',
        openAccount: 'Đã mở tài khoản thành công',
        despositSuccess: 'Gửi tiền thành công',
        volumeCompleted: 'Khối lượng giao dịch',
      },
      totalRedeemableTitle: `Tổng số tiền rút <br/>(USD hoặc tiền tệ tương đương khác)`,
      totalRedeemableTip: '* Số tiền thưởng có thể được xem xét.',
      referrer: 'Người giới thiệu',
      referral: 'Trọng tài',
      dialog: {
        confirmQ: 'Đổi thu nhập của bạn',
        confirmQ2: 'Tổng số tiền được quy đổi thành đơn vị tiền tệ cơ sở của bạn là {money} {currency}.',
        redeem: 'REDEEM NOW',
        agree: '* Bằng cách nhấp vào đổi ngay bây giờ, bạn đồng ý với tất cả {link}.',
        overBonus:
          'Hệ thống của chúng tôi đã phát hiện ra rằng bạn đã đạt đến số tiền tối đa của chúng tôi. Đối với yêu cầu bồi thường, vui lòng liên hệ với bộ phận hỗ trợ khách hàng của chúng tôi.',
      },
    },
    depositBonus: {
      banner: 'YÊU CẦU THƯỞNG TÍN DỤNG 50%! NHẬN LÊN ĐẾN $10,000',
      depositBonus: 'YÊU CẦU THƯỞNG CỦA BẠN & TĂNG CƯỜNG GIAO DỊCH CỦA BẠN',
      title:
        'Bạn muốn tăng ký quỹ tài khoản và tăng khối lượng giao dịch của mình? Sau đó, nạp tiền vào tài khoản của bạn ngay hôm nay và nhận phần thưởng 50% từ chúng tôi!',
      fund: 'Nạp tiền vào tài khoản của bạn',
      tnc: '* Điều khoản và điều kiện áp dụng, {link}',
      link: 'bấm vào đây để xem',
      bonusHistory: 'LỊCH SỬ TIỀN THƯỞNG',
      overBonus:
        '* Bạn đã đạt số tiền thưởng tín dụng tối đa là ${totalLimit} Các khoản tiền nạp bổ sung không đủ điều kiện cho các khoản tiền thưởng tín dụng.',
      opt: 'XÁC NHẬN OPT-IN & OPT- OUT ',
      tick: 'Vui lòng không đánh dấu tick nếu bạn muốn chọn opt-out hoặc đánh dấu tick nếu bạn muốn chọn opt-in. ',
      saveChange: 'Lưu thay đổi',
      depositDate: 'Ngày nạp tiền',
      credit: 'Tín dụng',
      optIn: 'Tham gia thành công',
      optOut: 'Chọn không tham gia thành công',
      optInDescription: 'Vui lòng nhấp vào nút bên dưới để chọn nhận phần tiền thưởng nạp tiền 50%.',
      optOutDescription: 'Nhấp vào ô trống để từ chối phần thưởng.',
      activate: 'Kích hoạt',
      signOut: 'Đăng xuất',
      banner1: 'YÊU CẦU 50% TIỀN THƯỞNG NẠP TIỀN CỦA BẠN!',
      claimDescription: `<p>Làm thế nào để yêu cầu</p>
      <li>1. Kích hoạt trước khi nạp tiền. </li>
      <li>2. Nạp vào tài khoản giao dịch của bạn để đủ điều kiện nhận phần thưởng. </li>
      <li>3. Phần thưởng sẽ tự động cộng vào tài khoản của bạn.</li>
      <li>4. Tận hưởng giao dịch của bạn. </li>`,
      eligibleDescription: `<p>Đủ điều kiện</p>
      <li>1. Phần thưởng phải được kích hoạt trước khi bạn nạp tiền. </li>
      <li>2. Phần thưởng chỉ có sẵn cho tài khoản Standard, tài khoản Standard Hồi giáo, tài khoản Prime, tài khoản Prime Hồi giáo</li>
      <li>3. Bạn chỉ có thể đăng ký tiền thưởng nạp tiền tỷ lệ 50% một lần và tất cả các khoản tiền nạp tiếp theo chỉ được tính theo tỷ lệ 20%. </li>`,
      optInDialog: `Xin chúc mừng! Bạn đã kích hoạt thành công phần tiền thưởng nạp tiền 50%.`,
      optOutDialog: `Bạn đã chọn không tham gia thành công phần tiền thưởng nạp tiền 50%.`,
    },
    deposit20Bonus: {
      fund: 'Nạp tiền vào tài khoản',
      tnc: '* Điều khoản và điều kiện áp dụng, {link}',
      link: 'bấm vào đây để xem',
      bonusHistory: 'Lịch sử nạp tiền',
      overBonus:
        '* Bạn đã đạt số tiền thưởng tín dụng tối đa là ${totalLimit} Các khoản tiền nạp bổ sung không đủ điều kiện cho các khoản tiền thưởng tín dụng.',
      opt: 'XÁC NHẬN OPT-IN & OPT- OUT ',
      depositDate: 'Ngày nạp tiền',
      credit: 'Tín dụng',
      depositAmt: 'Số tiền',
      optIn: 'Tham gia thành công',
      optOut: 'Chọn không tham gia thành công',
      optInDescription: 'Vui lòng nhấp vào nút bên dưới để tham gia vào khuyến mãi 20% Thưởng Nạp tiền',
      optOutDescription: 'Nhấp vào ô trống để từ chối phần thưởng.',
      activate: 'Kích hoạt',
      signOut: 'Đăng xuất',
      banner1: 'YÊU CẦU 20% TIỀN THƯỞNG KHI NẠP TIỀN',
      claimDescription: `<p>Làm thế nào để yêu cầu</p>
      <li>1. Kích hoạt trước khi nạp tiền. </li>
      <li>2. Nạp vào tài khoản giao dịch của bạn để đủ điều kiện nhận phần thưởng. </li>
      <li>3. Bất kỳ khoản tiền thưởng sẽ được ghi có vào tài khoản một cách tự động.</li>
      <li>4. Tận hưởng giao dịch của bạn! </li>`,
      eligibleDescription: `<p>ĐỦ ĐIỀU KIỆN</p>
      <li>1.	Khuyến mãi phải được kích hoạt trước khi bạn nạp tiền.</li>
      <li>2.	Khuyến mãi này CHỈ dành cho khách hàng tại Việt Nam.</li>
      <li>3.	Tiền thưởng CHỈ áp dụng cho Tài khoản Standard, Tài khoản Standard Hồi giáo, Tài khoản Prime và Tài khoản Prime Hồi giáo.</li>
      <li>4.	Chỉ những khoản tiền nạp mới, được thực hiện trong thời gian khuyến mãi, mới đủ điều kiện nhận được 20% Thưởng Nạp tiền. Điều này không bao gồm chuyển tiền giữa các tài khoản.</li>
      <li>5.	Tổng tín dụng được giới hạn ở mức $ 10.000 cho mỗi khách hàng.</li>`,
      optInDialog: `Chúc mừng!{br}Bạn đã kích hoạt thành công thưởng nạp tiền 20% dành cho Việt Nam. `,
      optOutDialog: `Bạn đã chọn không tham gia nhận tiền thưởng 20% trên tiền nạp dành cho Việt Nam.`,
      agreeTnc: `
      <li> 1. Khuyến mãi này có giá trị trong một năm kể từ khi bạn tham gia.</li>
      <li> 2. Khi chương trình khuyến mãi hết hạn, PU Prime sẽ thiết lập lại bất kỳ (các) tiền thưởng còn lại nào được theo chương trình khuyến mãi này về 0 mà không cần thông báo thêm cho khách hàng, do đó khách hàng nên đảm bảo rằng họ có đủ tiền ký quỹ trong tài khoản của mình để ngăn các lệnh giao dịch bị thanh lý.</li>
      <li> 3. “THƯỞNG NẠP TIỀN 20% tại Việt Nam” CHỈ dành cho Tài khoản Standard, Tài khoản Standard Hồi giáo, Tài khoản Prime, Tài khoản Prime Hồi giáo.</li>
      <li> 4. Để yêu cầu tiền thưởng 20% của bạn, vui lòng nhấp vào nút "Kích hoạt" trên trang này để kích hoạt chương trình khuyến mãi trước khi thực hiện bất kỳ khoản tiền nạp nào.</li>
      <li> 5. Đối với khoản tiền gửi của bạn, chúng tôi sẽ ghi có 20% số tiền đã gửi làm tiền thưởng vào tài khoản của bạn, tối đa là $ 10000USD (hoặc tương đương) tiền thưởng tín dụng.</li>
      <li> 6. “THƯỞNG NẠP TIỀN 20%  dành cho Việt Nam” áp dụng cho tất cả các khách hàng mở Tài khoản thật (không phải demo) với PU Prime theo các quy tắc đặt ra ở đây và tiền thưởng đã cấp chỉ có thể được sử dụng cho mục đích giao dịch. Bất kỳ khoản lợi nhuận nào tạo ra đều có thể được rút bất kỳ lúc nào, tuy nhiên, bất kỳ lần rút tiền nào cũng sẽ dẫn đến mất đi phần tiền thưởng của bạn theo tỷ lệ. Chuyển tiền giữa các tài khoản giao dịch MT4 của bạn cũng được coi là rút tiền.</li>
      <li> 7. Không cho phép chuyển tiền nội bộ giữa các tài khoản giao dịch đã quy đổi “THƯỞNG NẠP TIỀN 20% dành cho Việt Nam". </li>`,
      headerText: 'Điều khoản và điều kiện',
      tradingAcc: 'Tài khoản giao dịch',
      banner: {
        title:
          'Chỉ dành cho khách hàng Việt Nam:</br> Nhận <span class="bg_yellow">20%</span> thưởng </br>khi nạp tiền',
      },
    },
    cryptoCashback: {
      banner: {
        bannerContext: `
          <span class="title">Nhân đôi số tiền tiết kiệm của bạn</span></br>
          <span class="desc">Nhận hoàn tiền lên đến $10,000 và KHÔNG phí qua đêm</span>
        `,
        activityBannerContext: `
          <span class="title">Nhân đôi số tiền tiết kiệm của bạn</span></br>
          <span class="desc">Rút ngắn xu hướng giảm hay mua giảm?</span></br>
          <span class="desc">Nhận tiền hoàn lại lên đến 10.000 đô la khi giao dịch tiền điện tử với PU Prime!</span>
        `,
      },
      active: {
        title: `Kích hoạt chương trình Hoàn tiền cho Tiền điện tử`,
        desc: `Vui lòng nhấp vào phần bên dưới để kích hoạt và tham gia chương trình.`,
      },
      deactive: {
        title: `Hủy kích hoạt chương trình Hoàn tiền cho Tiền điện tử`,
        desc: `Vui lòng nhấp vào mục bên dưới để hủy kích hoạt và rút khỏi Khuyến mãi Hoàn tiền cho Tiền điện tử`,
      },
      button: {
        active: `Kích hoạt`,
        deactive: `Hủy kích hoạt`,
        submit: `Gửi`,
        fund: `Nạp tiền vào tài khoản`,
      },
      claimTitle: `LÀM THẾ NÀO ĐỂ YÊU CẦU `,
      claimDescriptions: [
        `Kích hoạt chương trình khuyến mãi trước khi nạp tiền.`,
        `Hoàn tiền vào tài khoản giao dịch của bạn và đáp ứng các yêu cầu ký quỹ （≥ 500 USD)`,
        `Hoàn tiền cho mỗi lot sẽ phụ thuộc vào tổng số tiền nạp ròng của bạn (xem T & C).`,
        `Thỏa sức giao dịch và nhận được nhiều giá trị hơn cho mỗi giao dịch!`,
      ],
      eligibilityTitle: `ĐỦ ĐIỀU KIỆN`,
      eligibleDescriptions: [
        `Khuyến mãi này phải được kích hoạt trước khi bạn nạp tiền.`,
        `Khuyến mãi này chỉ có sẵn cho Tài khoản Standard và Tài khoản Standard Hồi giáo. Nếu khách hàng có nhiều tài khoản giao dịch đủ điều kiện, chỉ có thể chọn MỘT tài khoản đủ điều kiện để tham gia chương trình khuyến mãi này`,
        `Khuyến mãi này kéo dài 30 ngày kể từ thời điểm bạn nạp tiền thành công đầu tiên sau khi kích hoạt`,
        `Hoàn tiền dựa trên khối lượng giao dịch trên tất cả các sản phẩm tiền điện tử ngoại trừ các sản phẩm sau: ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU`,
        `Tổng số tiền hoàn lại được giới hạn ở mức $10,000 cho mỗi khách hàng`,
      ],
      tnc: '* Điều khoản và điều kiện áp dụng, {link}',
      link: 'bấm vào đây để xem',
      message: {
        congratulate: `Xin chúc mừng!`,
        congratulateContext: `Bạn đã kích hoạt thành công chương trình khuyến mãi Hoàn tiền bằng Tiền điện tử.`,
        signedOutPromo: `Bằng cách nhấn vào mục Xác nhận, bạn sẽ đăng xuất khỏi chương trình Khuyến mãi.`,
        deactivePromo: `Bạn đã hủy kích hoạt thành công chương trình Hoàn tiền bằng tiền điện tử.`,
      },
      dialog: {
        selectAccountContext: `Vui lòng chọn tài khoản mà bạn muốn tham gia chương trình Khuyến mãi này.`,
        tnc: `Điều kiện và điều khoản`,
        tncContext: {
          tnc1: `1.Sau khi bạn kích hoạt chương trình khuyến mãi này, bạn cũng phải nạp tiền và giao dịch tiền điện tử theo yêu cầu để nhận được khoản tiền hoàn lại tương ứng.`,
          tnc1desc: `* Chỉ những khoản tiền nạp mới được thực hiện trong thời gian khuyến mãi mới có giá trị. Điều này không bao gồm chuyển khoản nội bộ, tiền thưởng của chương trình giới thiệu bạn bè và các điều chỉnh tiền mặt khác.`,
          tnc2: `Khách hàng Đủ điều kiện (cả khách hàng mới và khách hàng hiện tại), đáp ứng các tiêu chí nêu ở đây có thể nhận được tiền hoàn lại, tương đương:`,
          tnc3: `3. Các sản phẩm đủ điều kiện: Tất cả các sản phẩm tiền điện tử ngoại trừ ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU.`,
          tnc4: `4. Khối lượng giao dịch hiệu quả: Chỉ các lệnh giao dịch được mở và đóng trong thời gian khuyến mãi. Các vị trí lệnh mở dưới 5 phút, các vị trí bị khóa (locked positions) và các vị trí lệnh được bảo vệ rủi ro (hedged positions) không được tính là các giao dịch hợp lệ cho Khuyến mãi này.`,
          tnc5: `5. Thời gian khuyến mãi: 30 ngày sau khi khách hàng thực hiện lần nạp tiền đầu tiên thành công.`,
          tnc5desc: `* Nếu khách hàng kích hoạt chương trình khuyến mãi mà chưa nạp tiền thì chương trình khuyến mãi cũng sẽ tự động kết thúc sau 30 ngày.`,
          tnc6: `6. Ưu đãi này không được sử dụng đồng thời với bất kỳ ưu đãi nào khác. (Ngoại trừ chương trình “Bạn cũng có thể trở thành nhà giao dịch”, "Giới thiệu Bạn bè" và "50% Thưởng nạp tiền".)`,
        },
        agreeTnc: `Tôi đã đọc và chấp nhận Điều khoản và Điều kiện.`,
      },
      field: {
        netDeposit: `Tiền nạp ròng`,
        usddesc: `(USD hoặc tiền tệ tương đương)`,
        cashback: `Hoàn tiền`,
        standardLot: `Lot Standard`,
        date: `Ngày`,
        depositAmount: `Số tiền nạp`,
        withdrawAmount: `Số tiền rút`,
        netDepositAmount: `Số tiền nạp ròng`,
        rebateLot: `Rebate/Lot`,
        tradingDate: `Ngày giao dịch`,
        tradingLot: `Lot giao dịch`,
        cashRebate: `Hoàn tiền mặt`,
      },
      depositHistory: `LỊCH SỬ NẠP TIỀN`,
      tradingRewardHistory: `LỊCH SỬ THƯỞNG`,
    },
    xmasMakeAWish: {
      banner: {
        bannerContext: `
          <div class="title">Giành giải thưởng trị giá lên đến $5,000!</div>
          <div class="desc">Tại buổi rút thăm may mắn chắc chắn trúng thưởng của chúng tôi</div>
        `,
      },
      active: {
        title: 'KÍCH HOẠT CHƯƠNG TRÌNH ƯU ĐÃI ĐIỀU ƯỚC GIÁNG SINH',
        desc: 'Vui lòng nhấp vào nút bên dưới để kích hoạt chương trình ưu đãi Điều ước Giáng Sinh',
      },
      deactive: {
        title: 'Chương trình Ưu đãi Điều ước Giáng Sinh',
        desc: 'Vui lòng nhấp vào nút bên dưới để hủy Chương trình Ưu đãi Điều ước Giáng Sinh',
      },
      button: {
        active: 'Kích hoạt',
        deactive: 'Hủy',
        submit: 'Gửi',
        fund: 'Nạp tiền vào tài khoản của bạn',
        enterLuckyDraw: 'Rút thăm may mắn',
        redeemed: 'Đã đổi',
      },
      claimTitle: 'CÁCH NHẬN ƯU ĐÃI',
      claimDescriptions: [
        'Kích hoạt ưu đãi trước khi nạp tiền.​',
        'Nạp tiền vào tài khoản giao dịch của bạn để đáp ứng các yêu cầu cho món quà bạn muốn, sau đó thực hiện số lượng giao dịch tương ứng được yêu cầu. (xem bảng quà tặng bên dưới).',
        'Khi đủ điều kiện, bạn sẽ có cơ hội giành được một trong các quà tặng tương ứng. (xem bảng quà tặng bên dưới).',
        'Bấm vào nút Rút thăm may mắn để nhận quà của bạn.',
        'Quà tặng giành được sẽ được ghi nhận, và tất cả quà tặng sẽ được gửi tới sau 7 ngày kể từ ngày kết thúc chương trình ưu đãi.',
      ],
      eligibilityTitle: 'ĐIỀU KIỆN',
      eligibleDescriptions: [
        'Các loại tài khoản đủ điều kiện: Ưu đãi này CHỈ dành cho tài khoản Tiêu chuẩn và Tài khoản tiêu chuẩn Hồi giáo, cả trong MT4/MT5.',
        'Nếu khách hàng có nhiều Tài khoản Tiêu chuẩn, họ chỉ có thể chọn một để tham gia vào chương trình ưu đãi này.',
        'Các quốc gia đủ điều kiện: Tất cả ngoại trừ Trung Quốc đại lục',
        'Nạp tiền và giao dịch phải được thực hiện trong thời gian ưu đãi mới được coi là hợp lệ.',
        'Khối lượng giao dịch hợp lệ: Các vị thế Forex, Vàng, Bạc và Dầu Thô được giữ trong hơn 5 phút.',
      ],
      moreDetail: 'Để biết thêm chi tiết về việc phân phối giải thưởng, xem {prize}.',
      prize: 'danh sách giải thưởng tại đây',
      tnc: '*Áp dụng các Điều khoản và điều kiện. Nhấp {link} để xem',
      link: 'vào đây',
      luckyDraw: {
        description: [
          `Nhấn nút Rút thăm may mắn để giành <span class="font-bold">một</span> trong những giải thưởng trên!`,
          `Không phải món quà bạn đang tìm kiếm? Tiếp tục nạp tiền và giao dịch để đạt được mức phần thưởng bạn muốn.`,
        ],
        mainTitle: 'Bậc quà tặng hiện tại của bạn:',
        commingSoon: {
          title: `Thực hiện một mơ ước`,
          content: `Sắp diễn ra giữa tháng 11 để bốc thăm`,
        },
        noChanceToDraw: {
          title: `Nạp tiền ngay`,
          content: `Nạp tiền vào tài khoản với tối thiểu $200 để tham gia bốc thăm may mắn`,
        },
        endPromotion: {
          title: `Bốc thăm may mắn kết thúc`,
          content: `Cảm ơn bạn đã tham gia! Chắc chắn rằng bạn đổi qua của bạn vào ngày 31/3/2023`,
        },
      },
      currentDepositLots: `Số tiền nạp / Lô được giao dịch hiện tại`,
      transactionHistory: `LỊCH SỬ GIAO DỊCH`,
      transactionHistoryDesc: `*Giao dịch nạp tiền là số tiền đã nạp và giá trị số tiền nạp được trừ khi tham gia Rút thăm trúng thưởng.`,
      giftRedemption: `ĐỔI QUÀ`,
      giftRedemptionDesc: [
        `Chúng tôi sẽ liên hệ lại với bạn để đổi (các) món quà là hiện vật.`,
        `*Tất cả các giải thưởng có thể được đổi từ 2/2/2023 đến 2/3/2023`,
      ],
      field: {
        depositAmount: `Số tiền nạp`,
        lotsTraded: `Lô đã giao dịch`,
        date: `Ngày`,
        type: `Loại hình`,
        deposit: `*Nạp tiền`,
        tradingLots: `Lô giao dịch`,
        prizeWon: `Giải thưởng giành được`,
        creditBonus: `Tiền thưởng tín dụng`,
        cashVoucher: `Voucher tiền mặt`,
        physicalRewards: `Phần thưởng hiện vật`,
        depositTradingVolume: `Nạp tiền / Lô giao dịch`,
        gifts: `Quà tặng (USD)`,
        accountID: `ID tài khoản`,
        current: `Hiện tại`,
        luckyDrawDeposit: `Nạp tiền`,
      },
      dialog: {
        selectAccountContext: `Vui lòng chọn tài khoản mà bạn muốn nhận tiền thưởng tín dụng của mình (chỉ dành cho tài khoản MT4/MT5)`,
        tnc: 'ĐIỀU KHOẢN VÀ ĐIỀU KIỆN',
        tncContext: {
          tnc1: `1. Trước tiên, khách hàng cần kích hoạt thành công chương trình ưu đãi này trong cổng khách hàng của mình, sau đó nạp tiền và giao dịch theo yêu cầu để nhận được phần quà tương ứng.`,
          tnc2: `2.	Khối lượng giao dịch hiệu quả: Lô tiêu chuẩn của các cặp tiền tệ, vàng, bạc, hợp đồng dầu thô giao ngay, và hợp đồng dầu thô tương lai. Các lệnh phải được mở và đóng trong thời gian khuyến mãi. Các vị thế mở trong ít hơn 5 phút, bị khóa và vị thế bảo hiểm không được tính là giao dịch hợp lệ cho ưu đãi này.`,
          tnc3: `3.	Số liệu và khối lượng đặt lệnh được rút ra từ MT4 tại thời điểm 01/11/2022-31/01/2023.`,
          tnc4: `4.	Nếu không thể đổi quà do các yếu tố bất khả kháng như sản phẩm không còn trên kệ hoặc hết hàng, khách hàng có thể đăng ký nhận tiền mặt tương đương với giá thị trường của món quà đó. Giá này được dựa trên giá đô la Mỹ (hoặc đơn vị tiền tệ tương đương).`,
          tnc5: `5.	Ưu đãi này không được sử dụng đồng thời với bất kỳ ưu đãi nào khác. （ thưởng giao dịch 100 , thưởng độc quyền 20%, thưởng 50% nạp tiền , thưởng nạp tiền VN 20%).`,
        },
        agreeTnc: `Tôi đã đọc và đồng ý với các Điều khoản và Điều kiện`,
        prizeList: `danh sách giải thưởng`,
        luckyDrawTransaction: `GIAO DỊCH RÚT THĂM MAY MẮN`,
        xmasGift: `XIN CHÚC MỪNG VỚI QUÀ TẶNG GIÁNG SINH CỦA BẠN!`,
      },
      message: {
        congratulate: 'Xin chúc mừng!',
        congratulateContext: `Bạn đã kích hoạt ưu đãi thành công Chương trình Ưu đãi Điều ước Giáng Sinh`,
        signedOutPromo: `Bằng cách nhấp vào nút Xác nhận, bạn sẽ thoát khỏi chương trình ưu đãi!`,
        deactivePromo: `Bạn đã chọn không tham gia Chương trình Ưu đãi Điều ước Giáng Sinh.`,
        redeemAmount: `Số tiền sẽ được ghi có vào tài khoản của bạn!`,
      },
      tableContent: {
        luckyDraw: `Rút thăm may mắn`,
        depositTrade: `Nạp tiền / Giao dịch`,
      },
    },
    firstTimeDeposit: {
      banner: {
        bannerContext: `
          <div class="title">Nhận <span class='bg_yellow'>Tiền mặt tức thì</span> trên khoản tiền nạp đầu tiên của bạn</div>
          <div class="desc">Nhận lên đến US$50 tiền mặt khi bạn thực hiện khoản tiền nạp đầu tiên với PU Prime!</div>
        `,
      },
      active: {
        title: `Kích hoạt hoàn tiền FTD CỦA BẠN`,
        desc: `Vui lòng nhấp vào nút bên dưới để kích hoạt và tham gia hoạt động này.`,
      },
      deactive: {
        title: `Hủy kích hoạt hoàn tiền FTD CỦA BẠN`,
        desc: `Vui lòng nhấp vào nút bên dưới để hủy kích hoạt và rút khỏi Khuyến mãi hoàn tiền FTD`,
      },
      button: {
        active: `Kích hoạt`,
        deactive: `Hủy kích hoạt`,
        fund: ` Nạp tiền vào tài khoản`,
      },
      claimTitle: `CÁCH YÊU CẦU`,
      claimDescriptions: [
        `Kích hoạt khuyến mãi trước khi nạp tiền.`,
        `Nạp tiền vào tài khoản giao dịch để đạt yêu cầu nạp tiền và nhận mức hoàn lại cashback mà bạn muốn (≥ 200 USD hoặc tiền tệ tương đương)​.`,
        `Voucher Tiền mặt sẽ phụ thuộc vào số tiền nạp đầu tiên ( xem bảng hoàn lại cashback trong T&C).`,
        `Kích hoạt voucher hoàn tiền, giao dịch tối thiểu 2 lot FX, vàng, bạc và dầu.`,
        `Voucher kích hoạt có thể quy đổi. Số tiền sẽ được phản ánh trong mục số dư tài khoản.`,
      ],
      eligibilityTitle: `ĐIỀU KIỆN`,
      eligibleDescription1: `Loại tài khoản đủ điều kiện: Chương trình này CHỈ mở cho tài khoản Standard và Tài khoản Standard Hồi giáo, trên cả MT4/MT5.`,
      eligibleDescription2: `Nếu một khách hàng có nhiều loại tài khoản Standard, họ có thể chỉ lựa chọn một Tài khoản Standard để tham gia khuyến mãi.`,
      eligibleDescription3: `Quốc gia đủ điều kiện: Tất cả ngoại trừ Trung Quốc đại lục.`,
      eligibleDescription4: `Khách hàng đủ điều kiện: Khách hàng mới, khách hàng hiện tại mà không có lịch sử nạp tiền, bao gồm các khách hàng dưới IB/CPA.`,
      eligibleDescription4Desc: `*Tài khoản giao dịch cá nhân của IB/CPA không đủ điều kiện`,
      eligibleDescription5: `Tiền nạp đầu tiên phải được thực hiện trong giai đoạn khuyến mãi.`,
      eligibleDescription6: `Các lệnh giữ ít hơn 5 phút không có hiệu lực cho khuyến mãi này.`,
      tnc: `* Áp dụng các Điều khoản và điều kiện, {link} để xem`,
      link: `bấm vào đây`,
      dialog: {
        selectAccountContext: `Vui lòng chọn tài khoản mà bạn muốn tham gia chương trình Khuyến mãi này.`,
        tnc: `Điều khoản và điều kiện`,
        tncContext: {
          tnc1: `1. Sau khi kích hoạt khuyến mãi, bạn cũng phải nạp tiền và giao dịch 2 lot standard như yêu cầu để nhận tiền hoàn lại tương ứng.`,
          tnc1desc: `* Chỉ tiền nạp mới được thực hiện trong giai đoạn khuyến mãi có hiệu lực đối với chương trình khuyến mãi này. Nó không bao gồm chuyển khoản nội bộ, thưởng giới thiệu một người bạn, và các điều chỉnh tiền mặt khác.`,
          tnc2: `2.	Chỉ tiền nạp đầu tiên được thực hiện trong giai đoạn này sẽ được tính cho số tiền hoàn lại cashback.`,
          tnc3: `3.	Khách hàng đủ điều kiện (cả khách hàng mới và khách hàng hiện tại), đáp ứng các tiêu chí được nêu ở đây đều có thể nhận được tiền hoàn lại, tương đương với:`,
          tnc4: `4.	Sản phẩm đủ điều kiện: Ngoại hối, Vàng, Bạc, dầu.`,
          tnc5: `5.	Khối lượng giao dịch thực tế: Là các lệnh mới được mở và đóng trong thời gian khuyến mãi. Các lệnh mở ít hơn 5 phút, các vị trí bị khóa (locked position) và các vị trí được phòng hộ (hedged position) không được tính là giao dịch hợp lệ cho chương trình khuyến mãi này.`,
        },
        agreeTnc: `Tôi đã đọc và đồng ý với các Điều khoản và Điều kiện`,
        grossDeposit: `Gross Deposit USD`,
        grossDepositDesc: `hoặc số tiền tương đương`,
        cbVoucher: `Cashback Voucher`,
        tableDesc: `* Khách hàng có thể đổi voucher tiền mặt lên tới $50 (hoặc tương đương).`,
      },
      voucher: {
        title: `Hoàn tiền FTD`,
        accountID: `ID tài khoản`,
        accumulateVolume: `Tích lũy khối lượng giao dịch`,
        standardLots: `Lô tiêu chuẩn`,
        desc: `Giao dịch tối thiểu 2 lot FX, vàng, bạc, và dầu để kích hoạt nó`,
        clickForRedeem: `Nhấp vào nút bên dưới để gửi tiền hoàn lại vào số dư tài khoản của bạn.`,
        ftdLessThan200: `Khoản tiền nạp ban đầu của bạn ít hơn số tiền tối thiểu yêu cầu.`,
      },
      message: {
        congratulate: `Chúc mừng!`,
        congratulateContext: `Bạn đã thành công kích hoạt khuyến mãi FTD Cashback.`,
        signedOutPromo: `Bằng cách nhấp vào nút Xác nhận, bạn sẽ được đăng ký khuyến mãi!`,
        deactivePromo: `Bạn đã hủy thành công Khuyến mãi Hoàn tiền FTD.`,
        successRedeem: `Bạn đã đổi thành công FTD Cashback của mình`,
      },
    },
    notification: {
      ndb: {
        inst1: 'Kính gửi Quý khách hàng,',
        inst2: `Chúng tôi vui lòng nhắc bạn rằng chương trình khuyến mãi Tiền thưởng Không cần Nạp tiền sẽ kết thúc vào ngày <span>{date} (GMT+3)</span> .`,
        inst3:
          'Theo Điều khoản và Điều kiện của Khuyến mãi, xin lưu ý rằng tiền thưởng trên tài khoản tiền gửi của bạn sẽ tự động bị xóa sau khi chương trình khuyến mãi kết thúc. Đối với những người không có tài khoản tiền gửi hoặc tài khoản ghi giao dịch, tiền thưởng và số dư cũng sẽ tự động bị xóa sau khi chương trình khuyến mãi kết thúc.',
        inst4: `Nếu bạn có bất kỳ câu hỏi hoặc yêu cầu hỗ trợ nào, vui lòng liên hệ với nhóm hỗ trợ của chúng tôi qua Trò chuyện trực tiếp, email: <span>{mail}</span> hoặc điện thoại <span>{phone}</span>`,
        cantInerTransfer:
          'Vì bạn đang tham gia chiến dịch NDB của chúng tôi, chúng tôi không thể xử lý yêu cầu chuyển của bạn',
      },
      db: {
        inst1: 'Kính gửi Quý khách hàng,',
        inst2: `Chúng tôi muốn nhắc bạn rằng chương trình khuyến mãi tiền thưởng 50% tiền gửi sẽ kết thúc vào ngày <span>{date} (GMT+3)</span> .`,
        inst3:
          'Theo Điều khoản và Điều kiện của Khuyến mãi, xin lưu ý rằng tiền thưởng trên tài khoản ký quỹ của bạn sẽ tự động bị xóa sau khi chương trình khuyến mãi kết thúc. Đối với những tài khoản không có nạp tiền hoặc tài khoản không có lịch sử giao dịch, tiền thưởng và số dư cũng sẽ tự động bị xóa sau khi chương trình khuyến mãi kết thúc.',
        inst4: `Nếu bạn có bất kỳ câu hỏi hoặc yêu cầu hỗ trợ nào, vui lòng liên hệ với đội ngũ hỗ trợ của chúng tôi qua Live Chat, email: <span>{mail}</span> hoặc điện thoại <span>{phone}</span>.`,
      },
    },
    editSuccess: '',
  },
  downloads: {
    mt4: 'METATRADER 4',
    mt4Desc: 'Tải xuống MetaTrader 4 cho PC, Mac, trình duyệt web, điện thoại thông minh và máy tính bảng',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android/Máy Tính Bảng',
    web: 'WebTrader',
    mt5: 'METATRADER 5',
    mt5Desc: 'Tải xuống MetaTrader 5 cho PC, Mac, trình duyệt web, điện thoại thông minh và máy tính bảng',
  },
  forgetPwReq: {
    tip: 'Nhập địa chỉ email tài khoản của bạn để đặt lại mật khẩu.',
    formValidation: { emailReq: 'Email đăng nhập  là bắt buộc' },
    succ:
      'Chúng tôi vừa gửi cho bạn một email có hướng dẫn để đặt lại mật khẩu của bạn. <br><br> Vui lòng kiểm tra cả thư mục thư rác của bạn.',
  },
  loginClient: {
    header: 'CỔNG KHÁCH HÀNG',
    failedMasseage: 'Thử lại',
    existingClientPortalAccess:
      'Chúng tôi thấy rằng bạn đã có quyền truy cập Cổng thông tin khách hàng hiện tại. <br /> <br /> Đăng nhập bằng email của bạn để mở tài khoản mới!',
    footer: `Cảnh báo rủi ro: Hợp đồng giao dịch chênh lệch (CFD) mang lại rủi ro cao cho vốn của bạn và có thể dẫn đến thua lỗ, bạn chỉ nên giao dịch bằng tiền bạn có thể đủ khả năng để mất. Giao dịch CFD có thể không phù hợp với tất cả các nhà đầu tư, vui lòng đảm bảo rằng bạn hiểu đầy đủ các rủi ro liên quan và thực hiện các biện pháp phù hợp để quản lý nó.<br><br><br>
      {platformEntityName} được ủy quyền và quản lý bởi Cơ quan Dịch vụ Tài chính của Seychelles với Giấy phép số SD050.<br>
      PU Prime LLC được ủy quyền và quản lý bởi Cơ quan Dịch vụ Tài chính của St. Vincent và Grenadines với Giấy phép số 271 LLC 2020.<br>
      The entities above are duly authorized to operate under the PU Prime brand and trademark.<br>`,
    formValidation: { emailReq: 'Địa chỉ e-mail là bắt buộc' },
    invailidRafLink: `Liên kết giới thiệu được sử dụng không hợp lệ. Đăng ký một tài khoản mới dưới đây.`,
  },
  myProfile: {
    changeDetails: 'Nếu bạn muốn cập nhật chi tiết hồ sơ hiện tại của mình, đừng ngần ngại liên hệ với {mailTo}',
  },
  openAdditAcc: {
    chooseTradePlat: 'CHỌN MỘT NỀN TẢNG GIAO DỊCH',
    chooseAccType: 'CHỌN MỘT LOẠI TÀI KHOẢN',
    chooseCurrency: 'CHỌN MỘT TÀI KHOẢN TÀI KHOẢN',
    notes: 'THÔNG BÁO BỔ SUNG',
    currError: 'Bạn phải chọn một loại tiền tệ cho tài khoản',
    platErr: 'Bạn phải chọn một nền tảng giao dịch',
    accTypeErr: 'Bạn phải chọn loại tài khoản',
    succ:
      '<p> Yêu cầu tạo thêm tài khoản của bạn đang được thiết lập và bạn sẽ sớm nhận được email có thông tin đăng nhập. Bạn có thể xem trạng thái hiện tại của tài khoản bổ sung từ trang chủ Cổng Khách Hàng. </ p>',
    byTicking: `Tôi đã đọc và đồng ý với các {tnc}`,
    tnc: 'điều khoản và điều kiện',
    pamm: `Tài khoản PAMM chỉ dành cho các nhà đầu tư có thỏa thuận Người quản lý tiền hiện có. Tài khoản PAMM không thể được giao dịch bởi bất kỳ ai ngoại trừ Người quản lý tiền.`,
    pammConfirm: `Vui lòng xác nhận xem có tiếp tục chọn loại tài khoản này hay không.`,
  },
  openDemoAdditAcc: {
    header: 'Cấu hình tài khoản demo',
    button: 'mở tài khoản demo',
    liveButton: 'mở tài khoản thực',
    succ: 'Tài khoản demo bổ sung của bạn đã được thiết lập và bạn sẽ sớm nhận được email có thông tin đăng nhập.',
  },
  resetPassword: {
    header: 'ĐẶT LẠI MẬT KHẨU',
    succ:
      'Mật khẩu của bạn đã được cập nhật. Bây giờ bạn có thể sử dụng mật khẩu mới của mình để truy cập tài khoản giao dịch MT4 / MT5.',
    succ2: 'Mật khẩu của bạn đã được cập nhật.',
    failed: 'Token đã hết hạn',
    emailNotFound: 'Thật không may, địa chỉ email của bạn không tìm thấy.',
  },
  fileUploader: {
    supportedFormat: 'Các loại tệp được hỗ trợ: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Kích thước tệp tải lên tối đa: {maxSize} MB',
    maxFileNum: 'Số tệp tối đa: {maxFileNum}',
  },
  transcationHistory: {
    deposit: 'NẠP TIỀN',
    withdraw: 'RÚT TIỀN',
    desc: {
      incomplete:
        'Nếu bạn chấm dứt hoặc không hoàn thành quá trình nạp tiền, trạng thái giao dịch sẽ hiển thị dưới dạng không hoàn thành. Bạn sẽ được yêu cầu tạo một khoản tiền nạp mới vì giao dịch này không thể được tiếp tục.',
      success: 'Giao dịch của bạn đã thành công. Tiền nạp sẽ sớm hiển thị trong tài khoản của bạn',
      proc: 'Giao dịch của bạn đã thành công. Tiền nạp của  sẽ sớm xuất hiện trong tài khoản của bạn',
      failed: 'Giao dịch của bạn đã thất bại. Vui lòng liên hệ với {mailTo} để biết thêm thông tin',
      rejected: 'Giao dịch của bạn đã bị từ chối. Vui lòng liên hệ với {mailTo} để biết thêm thông tin',
    },
    withdrawDesc: {
      sub: 'Giao dịch của bạn đã được gửi. Xin vui lòng chờ để hoàn thành.',
      suc: 'Giao dịch của bạn đã thành công. Tiền nạp sẽ sớm hiển thị trong tài khoản của bạn',
      proc: 'Giao dịch của bạn đã thành công. Tiền nạp của  sẽ sớm xuất hiện trong tài khoản của bạn',
      incomp:
        'Giao dịch của bạn cần thêm các bước bổ sung để hoàn tất. Vui lòng liên hệ với {mailTo} để biết thêm thông tin',
      cancel: 'Giao dịch của bạn đã bị hủy. Vui lòng liên hệ với {mailTo} để biết thêm thông tin',
      fail: 'Giao dịch của bạn đã thất bại. Vui lòng liên hệ với {mailTo} để biết thêm thông tin',
      rej: 'Giao dịch của bạn đã bị từ chối. Vui lòng liên hệ với {mailTo} để biết thêm thông tin',
    },
    cancelWithdraw: `Bạn có muốn Hủy Yêu cầu Rút tiền không?`,
    cancelError: `Việc rút tiền của bạn đang được xử lý vào lúc này. Vì vậy, chúng tôi không thể chấp nhận yêu cầu hủy của bạn. Để biết thêm thông tin, bạn có thể liên hệ với chúng tôi bằng cách sử dụng tính năng trò chuyện trực tiếp.`,
    dialog: {
      appliedAmount: `Số tiền đã áp dụng`,
      applicationTime: `Thời gian nộp đơn`,
      processedTime: `Thời gian xử lý`,
      accountCurrency: `Tiền tệ tài khoản`,
      creditDeducted: `Khấu trừ tín dụng`,
      processedNote: `Ghi chú đã xử lý`,
      bankName: `Tên ngân hàng`,
      bankAccountNumber: `Số tài khoản ngân hàng`,
      abaSortCode: `ABA/ Sort Code`,
      swiftCode: `Mã Swift`,
      bankStatement: `Sao kê ngân hàng`,
      bankAddress: `Địa chỉ ngân hàng`,
      accountName: `Tên tài khoản`,
      accountNumber: `Số tài khoản`,
      cardholderName: `Tên chủ thẻ`,
      cardNumber: `Số thẻ`,
      cardExpiryDate: `Ngày hết hạn`,
      walletAddress: `Địa chỉ ví`,
      ewalletEmailAddress: `Địa chỉ email của ví điện tử`,
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'Rất tiếc, quá trình chuyển tiền không thể thực hiện được vì hoạt động này sẽ khiến ký quỹ giảm dưới mức dừng giao dịch 100%',
      equityMargin:
        'Rất tiếc, chúng tôi không thể thực hiện chuyển tiền do tài khoản của bạn đã được bảo đảm rủi ro toàn bộ.',
      equityContinue:
        'Chúng tôi phát hiện các vị thế mở trong tài khoản của bạn, bạn có thể sẽ phải dừng giao dịch sau khi đăng ký chuyển tiền. Vui lòng xác nhận rằng bạn muốn tiếp tục. ',
      blackList:
        'Tài khoản này đã bị hạn chế thực hiện chuyển khoản giữa các tài khoản, vui lòng liên hệ với {supportEmail}',
    },
    pleaseNote: 'Xin lưu ý:',
    suc: 'Tiền của bạn đã được chuyển thành công',
    warn1:
      'Bạn chịu trách nhiệm duy trì số dư tài khoản để không kích hoạt lệnh gọi ký quỹ. Nếu bạn đang có bất kỳ lệnh mở, vui lòng đảm bảo bạn duy trì mức ký quỹ bắt buộc trong tài khoản của mình. Tiền được chuyển đến các tài khoản được giữ bằng cùng loại tiền sẽ được xử lý ngay lập tức.',
    warn2: 'Nếu chuyển tiền giữa các tài khoản được giữ bằng các loại tiền tệ khác nhau, hãy gửi email {mailTo}.',
    warn3: 'Xin lưu ý rằng tiền không thể được chuyển giữa các tài khoản read-only.',
  },
  transferHistory: {
    payStatus: { processing: 'Đang Xử Lý', paid: 'Đã thanh toán', unpaid: 'Chưa thanh toán', rejected: 'Từ Chối' },
  },
  identityProof: {
    home: {
      header: 'ĐƠN ĐĂNG KÝ TÀI KHOẢN CHƯA HOÀN THÀNH',
      content:
        'Xin chào {name}, ứng dụng tài khoản của bạn đã gần hoàn tất, tuy nhiên bạn vẫn cần gửi thêm tài liệu để hoàn thiện tài khoản của mình.',
    },
    shufti: {
      content: `Vui lòng đảm bảo các chi tiết được cung cấp khớp với tài liệu ID của bạn và bằng cùng một ngôn ngữ.`,
      countryOfDocument: `Quốc gia như được hiển thị trên tài liệu`,
    },
    popup: {
      header:
        'Xin chào {name}, trước khi tài khoản thực của bạn có thể được hoàn tất, bạn được yêu cầu tải lên tài liệu sau để xác minh.',
      addressProofSpan: `<strong>Xác nhận địa chỉ</strong><br />
        Gửi một trong các tài liệu bên dưới<br />
        - Bản sao hóa đơn tiện ích gần đây (không quá 6 tháng)<br />
        - Bản sao sao kê ngân hàng gần đây (không quá 6 tháng)<br />
        <br />
        QUAN TRỌNG! Tên trên tài liệu PHẢI hiển thị rõ tên đầy đủ và địa chỉ hiện tại của bạn.`,
      idProofSpan: `<strong>Xác nhận ID</strong><br />
        Chọn một trong số các tùy chọn bên dưới<br />
        - Bản sao hộ chiếu hợp lệ <br />
        - Bản sao bằng lái xe hợp lệ(Trước và sau)<br />
        Bản sao chứng minh thư nhân dân<br />
        <br />
        QUAN TRỌNG! Tên trên tài liệu PHẢI hiển thị rõ tên đầy đủ của bạn.`,
    },
    reason: 'Your documentation was unable to be verified,<br> Reason: {reason}. Please try again.',
  },
  header: { secPortal: 'Bảo mật <br /> Khu vực khách hàng' },
  menu: {
    home: 'TRANG CHỦ',
    accManagement: 'TÀI KHOẢN',
    liveAcc: 'TÀI KHOẢN THỰC',
    openAdditAcc: 'TÀI KHOẢN BỔ SUNG',
    homeDemo: 'TÀI KHOẢN DEMO',
    depositFund: 'NẠP TIỀN',
    withdrawFunds: 'RÚT TIỀN',
    transferBetweenAccs: 'CHUYỂN QUỸ',
    payDetails: 'CHI TIẾT THANH TOÁN',
    funds: 'TIỀN VỐN',
    transactionHis: 'LỊCH SỬ GIAO DỊCH',
    tranHis: 'LỊCH SỬ CHUYỂN TIỀN',
    download: 'TẢI XUỐNG',
    profile: 'HỒ SƠ',
    myProfile: 'HỒ SƠ CỦA TÔI',
    cpPassword: 'ĐỔI MẬT KHẨU',
    contactUs: 'LIÊN HỆ CHÚNG TÔI',
    register: 'Tiếp tục ứng dụng',
    tradingTools: 'CÔNG CỤ GIAO DỊCH',
    MARKET_BUZZ: 'BÁO HIỆU THỊ TRƯỜNG',
    ECON_CALENDAR: 'LỊCH KINH TẾ',
    FEAT_FX: 'Ý TƯỞNG ĐẶC SẮC',
    FX_IDEAS: 'GÓC PHÂN TÍCH',
    promotion: 'sự thăng tiến',
    noDeposit: 'TIỀN THƯỞNG KHÔNG ĐẶT CỌC',
    referFriends: 'GIỚI THIỆU BẠN BÈ',
    depositBonus: '50% Tiền thưởng nạp tiền',
    cryptoCashback: `Hoàn tiền tiền điện tử`,
    firstTimeDeposit: 'Hoàn tiền FTD',
    tradingTool: 'Công cụ giao dịch',
  },
  contact: {
    desc:
      'Nhóm hỗ trợ khách hàng đa ngôn ngữ chuyên nghiệp của {platform} sẵn sàng hỗ trợ bạn và giúp cung cấp cho bạn trải nghiệm giao dịch không thể so sánh được.',
    followUs: 'theo chúng tôi',
    tip: 'Đối với những khách hàng đã tham gia qua {email}',
    liveChat: 'Trò chuyện trực tiếp',
  },
  depositResultDialog: { msg: 'Bạn đã hoàn thành việc nạp tiền của mình chưa' },
  dialog: {
    identityProof: {
      success: 'Cảm ơn bạn. Chúng tôi đã nhận thành công tài liệu của bạn và hiện đang xử lý chúng để xác minh.',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: 'ĐƠN ĐĂNG KÝ TÀI KHOẢN CỦA BẠN ĐANG ĐƯỢC XỬ LÝ',
      id: 'ID là',
      poa: 'Địa chỉ là',
      both: 'ID và Bằng chứng địa chỉ là',
      content:
        'Tài liệu xác minh {doc} của bạn hiện đang được xử lý và sẽ được hoàn tất trong thời gian ngắn. Để đẩy quá trình xử lý tài khoản giao dịch của bạn, bạn có thể nạp tiền cho tài khoản của mình ngay bây giờ và nhận ưu tiên mở.',
    },
  },
  register: {
    progressStatus: { title: 'Tiến trình xác minh', estimatedTimeTitle: 'thời gian dự kiến', min: 'phút' },
    tabs: {
      personalDetails: 'Thông tin cá nhân',
      moreAboutYou: 'Thêm về bạn',
      accountConfig: 'Cấu hình tài khoản',
      confirmYourId: 'Xác nhận ID của bạn',
      fundYourAcc: 'Nạp tiền vào tài khoản của bạn',
    },
    btn: { next: 'TIẾP', back: 'QUAY LẠI', fundnow: 'NẠP NGAY', upload: 'Tải Lên', done: 'ĐÃ XONG' },
    authority: {
      dialog1: 'Bạn phải có một tài khoản thực để truy cập phần này của cổng thông tin khách hàng.',
      dialog2:
        'Mở một tài khoản thực ngay bây giờ để mở khóa toàn quyền truy cập vào cổng khách hàng của bạn, bao gồm một loạt các công cụ giao dịch, chương trình khuyến mãi và nhiều hơn nữa.',
      openLiveAcc: 'mở tài khoản thực',
    },
    demo: {
      congrad: 'Xin chúc mừng!',
      title: 'Tài khoản DEMO MT{platform} 60 ngày của bạn hiện đang hoạt động',
      title2: 'Chi tiết tài khoản DEMO của bạn',
      li: { login: 'Đăng nhập', srv: 'Server', expDate: 'Ngày hết hạn' },
      emailSent: '* Chi tiết đăng nhập cũng đã được gửi đến email đã đăng ký của bạn.',
      dl: 'Tải xuống nền tảng giao dịch MT {platform} của bạn',
      improveQ: 'Bạn có muốn cải thiện cách giao dịch của mình ?',
      improveWay:
        'Đơn giản, hãy mở một tài khoản thực chỉ trong vài phút để mở khóa một loạt các công cụ giao dịch và khuyến mãi độc quyền.',
      proTrade: 'Báo cáo marketing',
      customerService: 'Dịch vụ chăm sóc khách hàng trực tuyến 24/7',
      competitivePromotionals: 'Khuyến mãi cạnh tranh',
      secure: 'Giao dịch an tòan 3D',
      openLiveAcc: 'mở tài khoản thực',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Thông tin cá nhân',
        userTitle: 'Chức danh',
        firstName: 'Tên',
        lastName: 'Họ',
        email: 'E-mail',
        dob: 'Ngày sinh',
        idType: 'Loại giấy tờ chứng minh',
        referral: 'Bạn có được ai giới thiệu ? (không bắt buộc)',
        middleName: 'Tên đệm',
        nationality: 'Quốc tịch',
        phoneNum: 'Số điện thoại',
        date: 'Ngày',
        month: 'Tháng',
        year: 'Năm',
        placeOfBirth: 'Nơi Sinh',
        idNum: 'Số Chứng Minh Nhân Dân',
        passportNum: 'Số Hộ Chiếu',
        driverLicenseNum: 'Mã số Bằng Lái',
        titleDropdown: { mr: 'Mr', mrs: 'Mrs', ms: 'Ms', miss: 'Miss', dr: 'Dr', prof: 'Prof' },
        idTypeDropdown: { passport: 'Hộ Chiếu', idCard: 'Chứng minh nhân dân', driverLicense: 'Bằng Lái Xe' },
      },
      page2: {
        pageTitle: 'ĐỊA CHỈ THƯỜNG TRÚ',
        mainResidency: 'Quốc gia thường trú',
        streetNumber: 'Số đường',
        streetName: 'Tên đường',
        address: 'Địa Chỉ ',
        provinceOrState: 'Tỉnh / bang',
        cityOrSuburb: 'Thành phố / ngoại ô',
        postcode: 'Mã bưu điện',
        usQuestion:
          'Bạn có phải là Công dân / Thường trú tại Hoa Kỳ vì mục đích thuế hay bạn có bất kỳ liên kết / quan hệ nào của Hoa Kỳ không (ví dụ: Tài khoản ngân hàng, v.v.)',
      },
    },
    moreAboutYou: {
      pageTitle1: 'CHI TIẾT LÀM VIỆC VÀ TÀI CHÍNH',
      pageTitle2: 'Kiến thức và kinh nghiệm giao dịch',
      complianceExplain: 'Các câu hỏi sau đây được thu thập để tuân thủ các yêu cầu AML / CTF.',
    },
    accountConfig: {
      pageTitle: 'Cấu hình tài khoản',
      chooseTradingPlatform: 'Chọn Nền Tảng giao dịch',
      chooseAccType: 'Chọn loại tài khoản',
      chooseAccCurrency: 'Chọn loại tiền tệ cho tài khoản',
    },
    confirmYourId: {
      pageTitle: 'Xác nhận ID của bạn',
      description:
        'Là một nhà môi giới ngoại hối được cấp phép, chúng tôi được yêu cầu xác minh danh tính của bạn. Vui lòng tải lên một trong các tài liệu sau đây để xác minh ID và bằng chứng Địa chỉ của bạn. Ngoài ra, bạn có thể gửi email tài liệu xác minh của mình tới {mailTo}',
      poidTitle: 'Bằng chứng nhận dạng',
      porTitle: 'Bằng chứng xác minh cư trú',
      uploadDescription: 'Chọn một loại tài liệu và tải lên',
      note: 'QUAN TRỌNG: Tên trên tài liệu PHẢI hiển thị rõ ràng tên đầy đủ của bạn.',
      pending: { upload: 'Đang chờ tải lên', verification: 'Đang chờ xác minh' },
      poidTypes: { photoId: 'Ảnh Chứng Minh Nhân Dân', passport: 'Hộ Chiếu', drivingLicence: 'Bằng Lái Xe' },
      porTypes:
        '\n        <li> Hóa đơn tiện ích </ li>\n       <li> Sao kê ngân hàng </ li>\n      <li> Giấy chứng nhận cư trú </ li>\n      ',
    },
    finishPage: {
      title: '{individualUserName}, cảm ơn bạn đã đăng ký tài khoản trực tuyến {platformName}!',
      contentDefault:
        'Chúng tôi đã nhận được đơn đăng ký của bạn và thiết lập tài khoản trực tiếp của bạn <b> {account} </ b>. <br />\n      Trong giây lát, bạn sẽ nhận được email tại <b> {email} </ b> có chứa thông tin đăng nhập và liên kết để xem trạng thái tài khoản của bạn. <br />',
      contentWithoutId3WithAccount:
        'Tài khoản giao dịch của bạn hiện đang được xử lý và bạn sẽ sớm nhận được email chứa thông tin thêm. <br /><br />\n      Để nhanh chóng theo dõi quá trình xử lý tài khoản giao dịch, bạn có thể nạp tiền cho tài khoản của mình ngay bây giờ và nhận ưu tiên xác nhận.<br />',
      contentWithoutId3WithoutAccount:
        'Tài khoản giao dịch của bạn hiện đang được xử lý và bạn sẽ sớm nhận được email chứa thông tin. <br />',
    },
  },
  autochartist: {
    1: {
      title: `Máy quét thị trường`,
      desc01: `Máy quét thị trường đầu tiên trên thế giới cho phép bạn quét danh sách các sản phẩm của mình để tìm các cơ hội giao dịch.`,
      button01: `Tải ngay`,
    },
    2: {
      title: `Báo cáo thị trường`,
      desc01: `Báo cáo thị trường của Autochartist cung cấp cho bạn một cái nhìn tổng thể về Phân tích kỹ thuật của thị trường trước giờ mở cửa của các trung tâm tài chính lớn.`,
      button01: `Xem Báo cáo Thị trường`,
    },
    3: {
      title: `Cổng nghiên cứu Autochartist`,
      desc01: `Một cổng thông tin nghiên cứu toàn diện đầy đủ cho phép bạn tìm thấy tất cả các dữ liệu ở một nơi.`,
      button01: `Phóng`,
      button02: `Hướng dẫn sử dụng`,
      button03: `Quyền truy cập OTP`,
    },
    4: {
      title: `Cơ hội giao dịch`,
      desc01: `Cập nhật các cơ hội giao dịch với phương pháp mới dựa trên tất cả các loại phân tích của Autochartist, 15 phút một lần `,
      button01: `Xem cơ hội giao dịch`,
    },
    5: {
      title: `Ứng dụng di động Autochartist`,
      desc01: `Nhận thông báo và theo dõi các cơ hội giao dịch xác suất cao được chọn lọc dựa trên danh sách theo dõi của bạn trong nền tảng giao dịch.`,
      desc02: `Ghi chú:`,
      desc03: `Mã QR có thể được quét từ máy ảnh bình thường, miễn là điện thoại của người dùng có tích hợp trình quét QR hoặc từ bên trong ứng dụng di động autochartist, bằng cách nhấp vào nút “Đăng nhập bằng Mã QR”.`,
      desc04: `Mã QR chỉ có hiệu lực trong 10 phút, sau đó hình ảnh phải được làm mới để có mã QR mới.`,
      desc05: `Mã QR chỉ có giá trị sử dụng một lần, sau đó hình ảnh phải được làm mới để có mã QR mới.`,
      button01: `Tải xuống cho IOS`,
      button02: `Tải xuống cho Andriod`,
    },
    6: {
      title: `Đăng ký bản tin`,
      desc01: `Bằng cách đăng ký nhận bản tin của chúng tôi, bạn sẽ nhận được email hàng ngày do Autochartist cung cấp để cung cấp cho bạn thông tin nhanh về thị trường ngoại hối toàn cầu. Giao dịch với PU Prime, Giao dịch toàn cầu.`,
      button01: `Đăng ký`,
    },
    footer: `Autochartist là công cụ tiên tiến nhất trên thế giới để xác định tự động các cơ hội giao dịch dưới dạng Mẫu biểu đồ, mẫu Fibonacci và Cấp độ chính. Quyền sở hữu độc quyền của Autochartist cho phép các công cụ tìm kiếm của họ giám sát hàng nghìn công cụ tài chính 24 giờ mỗi ngày để tìm ra các cơ hội giao dịch khi chúng xảy ra. Ngoài ra, Autochartist cũng cung cấp Phân tích biến động như một tính năng sẽ giúp bạn đánh giá tốt hơn về rủi ro và biến động trên các công cụ mà bạn giao dịch.`,
  },
  responseMsg: {
    410: 'Xác thực thông số không thành công',
    411: 'Mật khẩu không đúng',
    420: 'Người dùng không tồn tại',
    421: 'Đặt lại mật khẩu bị từ chối',
    490: 'Vui lòng không gửi thông tin thẻ trùng lặp',
    500: 'Một lỗi đã xảy ra.',
    501: 'Đăng ký thất bại. Vui lòng thử lại sau.',
    505: 'Tải lên tệp không thành công',
    520: 'Đăng nhập thất bại. Vui lòng thử lại.',
    521: 'Một người dùng đã đăng nhập vào thiết bị này!',
    522: 'Xin vui lòng đăng nhập lại',
    523: 'Xin vui lòng đăng nhập lại',
    524: 'Thật không may, địa chỉ email của bạn không tìm thấy.',
    525: 'Tên đăng nhập hoặc mật khẩu không chính xác',
    527: 'Người dùng không phải là một IB',
    528: 'Người dùng không tồn tại',
    529: 'Tên đăng nhập hoặc mật khẩu không chính xác',
    540: 'Không thể tìm thấy tài khoản giao dịch của bạn ',
    541: 'Không thể tìm thấy tài khoản nhận hoa hồng',
    542: 'Bạn không được phép mở thêm tài khoản',
    544: 'Bạn không được phép đăng ký thêm tài khoản cho đến khi Bằng chứng ID của bạn được xác minh',
    550: 'Số tài khoản không chính xác',
    551: 'Số dư của bạn không đủ',
    553: 'Bạn không thể rút tiền do khoản tín dụng trong tài khoản của bạn. Vui lòng liên hệ với {email} để được trợ giúp thêm',
    554: 'Số lượng yêu cầu là 0 hoặc trống',
    564: 'Quốc gia không khớp với kênh thanh toán',
    565: 'Mã ngân hàng không chính xác',
    566: 'Không tìm thấy phương thức thanh toán',
    568: 'Tài khoản này đã bị cấm nạp tiền, vui lòng liên hệ info@puprime.com',
    581: 'Bạn đã chọn tham gia chương trình khuyến mãi thành công. Xin lưu ý rằng bạn phải đặt cọc để rút tiền.',
    582: 'Bạn đang tham gia sự kiện và có các chức vụ nên không thể gửi đơn rút tiền.',
    584: 'Bạn không có bất kỳ khoản hoa hồng nào',
    587: 'Số dư không đủ vui lòng liên hệ với dịch vụ khách hàng.',
    590: 'Không thể tìm thấy tài khoản chuyển khoản đi',
    591: 'Không thể tìm thấy tài khoản chuyển khoản vào',
    593: 'Chúng tôi không thể xử lý yêu cầu chuyển tiền của bạn từ tài khoản đã chọn do tài khoản của bạn đang chứa tín dụng.',
    594: 'Chuyển tiền từ tài khoản này không được cho phép',
    595: 'Vì bạn đang tham gia vào các hoạt động của chúng tôi, nên không thể xử lý yêu cầu chuyển tiền của bạn vào lúc này.',
    647: 'Mã xác minh không đúng',
    1001: 'Tài khoản của bạn tạm thời không khả dụng, vui lòng liên hệ với người quản lý tài khoản của bạn',
    session_timeout: 'Qúa thời gian chờ, chuyển hướng đến trang đăng nhập.',
    vload: {
      4019: 'Loại hoặc giá trị Voucher không được chấp nhận',
      4071: 'Nghi ngờ gian lận',
      4072: 'Voucher được sử dụng',
      4073: 'Voucher không hoạt động',
      4074: 'Voucher đã hết hạn',
      4075: 'Không tìm thấy voucher',
      4080: 'Voucher không đủ giá trị',
      4444: 'Voucher không hợp lệ đã được sử dụng. Loại tiền tệ không khớp.',
      5000: 'Dữ liệu cửa hàng không khả dụng',
    },
  },
  tradingTool: {
    mt4: {

    },
    pamm: {

    },
    copytrade: {

    }
  },
  activity: {
    myActivity: '',
    allActivity: '',
    activityContent: '',
    applyNow: '',
    activityStatus1: '',
    activityStatus2: '',
    activityStatus3: '',
    noActivity: '',
  }
}
