const { countryCodeEnum } = require('@/constants/country')
const {
  REFER_A_FRIEND_PATHNAME,
  DEPOSIT_BONUS_PATHNAME,
  TRADING_BONUS_PATHNAME,
  NO_DEPOSIT_BONUS_PATHNAME,
  CASHBACK_PATHNAME,
  DEPOSIT_20_BONUS_PATHNAME,
  XMAS_MAKE_A_WISH_PATHNAME,
  MILLION_DOLLAR_CASH_POOL_PATHNAME,
  FIRST_TIME_DEPOSIT_PATHNAME,
} = require('@/constants/route')
const { WHITELIST } = require('@/constants/internationalBankTransfer')

module.exports = {
  info: {
    url: 'www.puprime.com',
    map:
      'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4616.687864043498!2d55.45538001942663!3d-4.619136458242956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x22e028533cfa53ad%3A0x48d516d8499b9550!2sOrion%20Building!5e0!3m2!1sen!2sau!4v1592795342692!5m2!1sen!2sau',
    address: `<span>Suite C, Orion Mall, Palm Street, Victoria, Mahe, Seychelles</span>`,
    phone: '+248 4671 948',
    email: 'info@puprime.com',
    accountEmail: 'info@puprime.com',
    blacklistEmail: 'op@puprime.com',
  },
  menus: [
    {
      name: 'menu.home',
      path: '/home',
      icon: 'menu_icon_home',
    },
    {
      name: 'menu.accManagement',
      path: '/accountManagement',
      icon: 'menu_icon_account',
      children: [
        {
          name: 'menu.liveAcc',
          path: '/liveAccount',
        },
        // {
        //   optional: true,
        //   name: 'menu.openAdditAcc',
        //   path: '/openLiveAccount',
        // },
        {
          name: 'menu.homeDemo',
          path: '/homeDemo',
        },
      ],
    },
    {
      name: 'menu.funds',
      path: '/funds',
      icon: 'menu_icon_funds',
      children: [
        {
          checkBlacklist: true,
          name: 'menu.depositFund',
          path: '/depositFunds',
        },
        {
          checkBlacklist: true,
          name: 'menu.withdrawFunds',
          path: '/withdrawFunds',
        },
        {
          checkBlacklist: true,
          name: 'menu.transferBetweenAccs',
          path: '/transferFunds',
        },
        {
          name: 'menu.payDetails',
          path: '/paymentDetails',
        },
        {
          name: 'menu.transactionHis',
          path: '/transactionHistory',
        },
      ],
    },
    {
      name: 'menu.tradingTool',
      path: '/tradingTool',
      icon: 'menu_icon_tool',
    },
    {
      name: 'menu.promotion',
      path: '/activity',
      icon: 'menu_icon_promotion',
    },
    // {
    //   name: 'menu.promotion',
    //   path: REFER_A_FRIEND_PATHNAME,
    //   icon: 'menu_icon_promotion',
    //   children: [
    //     {
    //       name: 'menu.referFriends',
    //       path: REFER_A_FRIEND_PATHNAME,
    //     },
    //     {
    //       name: 'menu.depositBonus',
    //       path: DEPOSIT_BONUS_PATHNAME,
    //     },
    //     {
    //       name: 'menu.deposit20Bonus',
    //       path: DEPOSIT_20_BONUS_PATHNAME,
    //     },
    //     {
    //       name: 'menu.tradingBonus',
    //       path: TRADING_BONUS_PATHNAME,
    //     },
    //     {
    //       name: 'menu.cryptoCashback',
    //       path: CASHBACK_PATHNAME,
    //     },
    //     {
    //       name: 'menu.xmasMakeAWish',
    //       path: XMAS_MAKE_A_WISH_PATHNAME,
    //     },
    //     {
    //       name: 'menu.noDeposit',
    //       path: NO_DEPOSIT_BONUS_PATHNAME,
    //     },
    //     {
    //       name: 'menu.millionDollarCashPool',
    //       path: MILLION_DOLLAR_CASH_POOL_PATHNAME,
    //     },
    //     {
    //       name: 'menu.firstTimeDeposit',
    //       path: FIRST_TIME_DEPOSIT_PATHNAME,
    //     },
    //   ],
    // },
    {
      name: 'menu.contactUs',
      path: '/contactUs',
      icon: 'menu_icon_contact',
    },
    {
      name: 'menu.register',
      path: '/register',
      icon: 'menu_icon_register',
    },
  ],
  tradingPlatform: {
    mt4: true,
    mt5: true,
  },
  restrictDepositFundsByCountries: [],
  restrictOpenAdditionAccountCountries: [],
  openLiveAccount: {
    choosePlatform: [
      {
        value: 'mt4',
        label: '4',
      },
      {
        value: 'mt5',
        label: '5',
      },
    ],
    chooseLiveType: [
      {
        value: 'standardSTP',
        label: 'Standard',
      },
      {
        value: 'rawECN',
        label: 'Prime',
      },
      {
        value: 'swapFreeStandardSTP',
        label: 'Islamic-Standard',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'swapFreeRawECN',
        label: 'Islamic-Prime',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'STANDARD_STP_CENT',
        label: 'Cent',
        restrictCountries: [
          1, //China
        ],
      },
      {
        value: 'ISLAMIC_STP_CENT',
        label: 'Islamic-Cent',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'PAMM',
        label: 'PAMM',
      },
      {
        value: 'pro1000',
        label: 'Pro Standard',
      },
      {
        value: 'RAW_ECN_CENT',
        label: 'Prime Cent',
        restrictCountries: [
          1, //China
        ],
      },
      {
        value: 'ISLAMIC_ECN_CENT',
        label: 'Islamic Prime Cent',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'ECN',
        label: 'ECN',
      },
    ],
    mt4RegisterChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard',
      },
      {
        value: 'rawECN',
        label: 'Prime',
      },
      {
        value: 'swapFreeStandardSTP',
        label: 'Islamic-Standard',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'swapFreeRawECN',
        label: 'Islamic-Prime',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'STANDARD_STP_CENT',
        label: 'Cent',
        restrictCountries: [
          1, //China
        ],
      },
      {
        value: 'ISLAMIC_STP_CENT',
        label: 'Islamic-Cent',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'pro1000',
        label: 'Pro Standard',
      },
      {
        value: 'RAW_ECN_CENT',
        label: 'Prime Cent',
        restrictCountries: [
          1, //China
        ],
      },
      {
        value: 'ISLAMIC_ECN_CENT',
        label: 'Islamic Prime Cent',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'ECN',
        label: 'ECN',
      },
    ],
    mt4DemoChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard',
      },
      {
        value: 'rawECN',
        label: 'Prime',
      },
      {
        value: 'ECN',
        label: 'ECN',
      },
    ],
    mt5ChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard',
      },
      {
        value: 'rawECN',
        label: 'Prime',
      },
      {
        value: 'swapFreeStandardSTP',
        label: 'Islamic-Standard',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'swapFreeRawECN',
        label: 'Islamic-Prime',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'PAMM',
        label: 'PAMM',
      },
      {
        value: 'STANDARD_STP_CENT',
        label: 'Cent',
        restrictCountries: [
          1, //China
        ],
      },
      {
        value: 'ISLAMIC_STP_CENT',
        label: 'Islamic-Cent',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'pro1000',
        label: 'Pro Standard',
      },
      {
        value: 'RAW_ECN_CENT',
        label: 'Prime Cent',
        restrictCountries: [
          1, //China
        ],
      },
      {
        value: 'ISLAMIC_ECN_CENT',
        label: 'Islamic Prime Cent',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'ECN',
        label: 'MT5 ECN',
      },
    ],
    mt5RegisterChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard',
      },
      {
        value: 'rawECN',
        label: 'Prime',
      },
      {
        value: 'swapFreeStandardSTP',
        label: 'Islamic-Standard',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'swapFreeRawECN',
        label: 'Islamic-Prime',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'STANDARD_STP_CENT',
        label: 'Cent',
        restrictCountries: [
          1, //China
        ],
      },
      {
        value: 'ISLAMIC_STP_CENT',
        label: 'Islamic-Cent',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'pro1000',
        label: 'Pro Standard',
      },
      {
        value: 'RAW_ECN_CENT',
        label: 'Prime Cent',
        restrictCountries: [
          1, //China
        ],
      },
      {
        value: 'ISLAMIC_ECN_CENT',
        label: 'Islamic Prime Cent',
        onlyCountries: [
          6305, //Turkey
          3416, //Egypt
          4735, //Kuwait
          6845, //India
          5015, //Malaysia
          6877, //Indonesia
          3382, //United Arab Emirates
          6025, //Saudi Arabia
        ],
      },
      {
        value: 'ECN',
        label: 'MT5 ECN',
      },
    ],
    mt5DemoChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard',
      },
      {
        value: 'rawECN',
        label: 'Prime',
      },
      {
        value: 'ECN',
        label: 'MT5 ECN',
      },
    ],
    chooseCurrency: [
      {
        img: require('@/assets/images/flag/us_flag.png'),
        value: 'USD',
        currency: 'USD',
      },
      {
        img: require('@/assets/images/flag/uk_flag.png'),
        value: 'GBP',
        currency: 'GBP',
      },
      {
        img: require('@/assets/images/flag/ca_flag.png'),
        value: 'CAD',
        currency: 'CAD',
      },
      {
        img: require('@/assets/images/flag/au_flag.png'),
        value: 'AUD',
        currency: 'AUD',
      },
      {
        img: require('@/assets/images/flag/eu_flag.png'),
        value: 'EUR',
        currency: 'EUR',
      },
      {
        img: require('@/assets/images/flag/sg_flag.png'),
        value: 'SGD',
        currency: 'SGD',
      },
      {
        img: require('@/assets/images/flag/nz_flag.png'),
        value: 'NZD',
        currency: 'NZD',
      },
      {
        img: require('@/assets/images/flag/hk_flag.png'),
        value: 'HKD',
        currency: 'HKD',
      },
      {
        img: require('@/assets/images/flag/jp_flag.png'),
        value: 'JPY',
        currency: 'JPY',
      },
    ],
    chooseCurrencyByType: {
      STANDARD_STP_CENT: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USC',
          currency: 'USC',
        },
      ],
      ISLAMIC_STP_CENT: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USC',
          currency: 'USC',
        },
      ],
      RAW_ECN_CENT: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USC',
          currency: 'USC',
        },
      ],
      ISLAMIC_ECN_CENT: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USC',
          currency: 'USC',
        },
      ],
    },
    chooseLeverage: [100, 200, 300, 400, 500],
    chooseBalance: [
      { value: 2000, name: '$2000' },
      { value: 5000, name: '$5k' },
      { value: 10000, name: '$10k' },
      { value: 20000, name: '$20k' },
      { value: 50000, name: '$50k' },
      { value: 100000, name: '$100k' },
    ],
    getAgreeCondition: 'openAdditAcc.disclaimer',
  },
  metaTraderLinks(url) {
    return {
      mt4: {
        METATRADER4_windows: `https://download.mql5.com/cdn/web/pu.prime.pty/mt4/puprime4setup.exe`,
        METATRADER4_iPhone: `https://download.mql5.com/cdn/mobile/mt4/ios?server=PUPrime-Demo,PUPrime-Live,PUPrime-Live%202,PUPrime-Live%203`,
        METATRADER4_iOS: `https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.dmg`,
        METATRADER4_Android: `https://download.mql5.com/cdn/mobile/mt4/android?server=PUPrime-Demo,PUPrime-Live,PUPrime-Live%202,PUPrime-Live%203`,
        METATRADER4_WebTrader: `https://webtrader.puprime.com/`,
      },
      mt5: {
        METATRADER5_windows: `https://download.mql5.com/cdn/web/pu.prime.pty/mt5/puprime5setup.exe`,
        METATRADER5_iPhone: `https://download.mql5.com/cdn/mobile/mt5/ios?server=PUPrime-Demo,PUPrime-Live`,
        METATRADER5_iOS: `https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg`,
        METATRADER5_Android: `https://download.mql5.com/cdn/mobile/mt5/android?server=PUPrime-Demo,PUPrime-Live`,
        METATRADER5_WebTrader: `https://webtrader.puprime.com/`,
      },
    }
  },
  bankInfoDetails: {
    bank_name: { show: true, value: 'DBS Bank' },
    currency: ['EUR', 'USD', 'GBP', 'USC'],
    branch_address: {
      AUD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      USD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      USC: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      EUR: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      GBP: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      JPY: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      CAD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      SGD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      NZD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      HKD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
    },
    branch_name: {
      AUD: { show: false, value: '' },
      USD: { show: false, value: '' },
      USC: { show: false, value: '' },
      EUR: { show: false, value: '' },
      GBP: { show: false, value: '' },
      JPY: { show: false, value: '' },
      CAD: { show: false, value: '' },
      SGD: { show: false, value: '' },
      NZD: { show: false, value: '' },
      HKD: { show: false, value: '' },
    },
    account_name: { show: true, value: "WTSG PACIFIC UNION - CUSTOMERS'ACCOUNT" },
    account_number: {
      USD: { show: true, value: '0720381505' },
      USC: { show: true, value: '0720381505' },
      EUR: { show: true, value: '0720381505' },
      GBP: { show: true, value: '0720381505' },
      CAD: { show: true, value: '0720381505' },
      SGD: { show: true, value: '0720381505' },
    },
    beneficiary_address: { show: true, value: 'Suite C, Orion Mall, Palm Street, Victoria, Mahe, Seychelles' },
    bsb_number: {
      AUD: { show: true, value: '034-234' },
      USD: { show: true, value: '034-702' },
      USC: { show: true, value: '034-702' },
      EUR: { show: true, value: '034-705' },
      GBP: { show: true, value: '034-703' },
      JPY: { show: true, value: '034-702' },
      CAD: { show: true, value: '034-702' },
      SGD: { show: true, value: '034-702' },
      NZD: { show: true, value: '034-702' },
      HKD: { show: true, value: '034-702' },
    },
    swift_code: { show: true, value: 'DBSSSGSG' },
  },
  withdrawMethods: {
    1: 'Credit/Debit Card',
    2: {
      1: 'Bank Transfer (Australia)',
      2: 'Bank Transfer (International)',
      3: 'Bank Transfer (China)',
    },
    3: 'Skrill / Neteller',
    4: 'Union Pay',
    5: 'Internet Banking (Thailand)',
    6: 'Internet Banking (Malaysia)',
    7: 'FasaPay',
    8: 'Internet Banking (Vietnam)',
    9: 'Internet Banking (Nigeria)',
    24: 'Internet Banking (India)',
    31: 'Skrill',
    32: 'Neteller',
    33: 'Paypal',
    34: 'Cryptocurrency-Bitcoin',
    35: 'Internet Banking (Korea)',
    36: 'Cryptocurrency-USDT',
    37: 'Bitwallet',
    38: 'SticPay',
    39: 'Internet Banking (Indonesia)',
    40: 'Internet Banking (Philippine)',
    41: 'astropay',
    42: 'Internet Banking (South Africa)',
    44: 'Vload',
    50: 'Internet Banking (Uganda)',
    51: 'Internet Banking (Rwanda)',
    52: 'Internet Banking (Zambia)',
    53: 'Internet Banking (Congo)',
    54: 'Internet Banking (Cameroon)',
    55: 'Internet Banking (Burundi)',
    56: 'Internet Banking (Kenya)',
    57: 'Internet Banking (Ghana)',
    58: 'Internet Banking (Tanzania)',
    65: 'Local Depositor',
    80: 'Cryptocurrency-ETH',
    81: 'Cryptocurrency-USDC(ERC20)',
  },
  banner(url) {
    return [
      {
        img: require('@/assets/images/banner/new02.png'),
        info: {
          title: 'reliable broker',
          tip: 'serving 120,000+ customers globaly',
        },
      },
      {
        img: 'no_deposit_bonus/',
        bannerUrl: NO_DEPOSIT_BONUS_PATHNAME,
        promotionType: 'NO_DEPOSIT_BONUS',
        info: {
          multiLang: true,
        },
      },
      {
        img: 'refer_friend/',
        bannerUrl: REFER_A_FRIEND_PATHNAME,
        promotionType: 'REFER_A_FRIEND_3',
        info: {
          multiLang: true,
        },
      },
      {
        img: '50_credit_bonus/',
        bannerUrl: DEPOSIT_BONUS_PATHNAME,
        promotionType: 'DEPOSIT_BONUS_2',
        promotionId: 6,
        info: {
          multiLang: true,
        },
      },
      {
        img: 'deposit20Bonus/',
        rightSideImg: require('@/assets/images/banner/deposit20Bonus/box.png'),
        bannerUrl: DEPOSIT_20_BONUS_PATHNAME,
        promotionType: 'DEPOSIT_BONUS_2',
        extraClass: 'deposit20bns',
        bannerVersion: 'v1',
        promotionId: 32,
        title: 'promotion.deposit20Bonus.banner.title',
        ltr: true,
        info: {
          multiLang: true,
        },
      },
      {
        img: require('@/assets/images/banner/trading_bonus/bg.png'),
        rightSideImg: require('@/assets/images/banner/trading_bonus/box.png'),
        bannerUrl: TRADING_BONUS_PATHNAME,
        promotionType: 'TRADING_BONUS',
        extraClass: 'tradingBonus',
        bannerVersion: 'v1',
        promotionId: 11,
        title: 'promotion.tradingBonus.banner.title',
        info: {
          multiLang: false,
        },
      },
      {
        img: require('@/assets/images/banner/crypto_cashback_bonus/bg.png'),
        imgSmall: require('@/assets/images/banner/crypto_cashback_bonus/bg_sm.png'),
        imgSmallAr: require('@/assets/images/banner/crypto_cashback_bonus/bg_sm_ar.png'),
        rightSideImg: require('@/assets/images/banner/crypto_cashback_bonus/box.png'),
        bannerUrl: CASHBACK_PATHNAME,
        promotionType: 'CASHBACK',
        extraClass: 'cashback',
        bannerVersion: 'v1',
        promotionId: 31,
        title: 'promotion.cryptoCashback.banner.bannerContext',
        info: {
          multiLang: false,
          secondBanner: {
            img: require('@/assets/images/banner/crypto_cashback_bonus/activity_page/banner_bg.png'),
            imgSmall: require('@/assets/images/banner/crypto_cashback_bonus/activity_page/banner_bg_sm.png'),
            imgLarge: require('@/assets/images/banner/crypto_cashback_bonus/activity_page/banner_bg_lg.png'),
            title: 'promotion.cryptoCashback.banner.activityBannerContext',
            extraClass: 'cashback-act',
            bannerVersion: 'v1',
          },
        },
      },
      {
        img: require('@/assets/images/banner/xmas_make_a_wish/banner_bg.png'),
        imgSmall: require('@/assets/images/banner/xmas_make_a_wish/banner_bg_sm.png'),
        imgLarge: require('@/assets/images/banner/xmas_make_a_wish/banner_bg_lg.png'),
        bannerUrl: XMAS_MAKE_A_WISH_PATHNAME,
        promotionType: 'LUCKY_DRAW',
        extraClass: 'xmasMakeAWish',
        bannerVersion: 'v1',
        promotionId: 34,
        title: 'promotion.xmasMakeAWish.banner.bannerContext',
        info: {
          multiLang: false,
        },
      },
      {
        img: require('@/assets/images/banner/millon_dollar_cash_pool/bg.png'),
        imgSmall: require('@/assets/images/banner/millon_dollar_cash_pool/bg_sm.png'),
        backgroundPosition: 'right',
        leftSideImg: {
          img: require('@/assets/images/banner/millon_dollar_cash_pool/promo_img.png'),
          imgSmall: require('@/assets/images/banner/millon_dollar_cash_pool/promo_img_sm.png'),
          breakpoint: 601,
        },
        bannerUrl: MILLION_DOLLAR_CASH_POOL_PATHNAME,
        promotionType: 'MILLION_DOLLAR_CASH_POOL',
        promotionName: 'millionDollarCashPool',
        bannerVersion: 'v2',
        promotionId: 1000,
        openResize: true,
        title: 'promotion.millionDollarCashPool.banner.bannerContext',
        info: {
          multiLang: false,
        },
      },
      {
        img: require('@/assets/images/banner/first_time_deposit/banner_bg.png'),
        imgLarge: require('@/assets/images/banner/first_time_deposit/banner_bg_large.png'),
        imgMid: require('@/assets/images/banner/first_time_deposit/banner_bg_mid.png'),
        imgSmall: require('@/assets/images/banner/first_time_deposit/banner_bg_small.png'),
        rightSideImg: {
          img: require('@/assets/images/banner/first_time_deposit/person.png'),
          imgSmall: require('@/assets/images/banner/first_time_deposit/person_small.png'),
          breakpoint: 735,
        },
        bannerUrl: FIRST_TIME_DEPOSIT_PATHNAME,
        promotionType: 'FIRST_TIME_DEPOSIT',
        promotionName: 'firstTimeDeposit',
        bannerVersion: 'v2',
        promotionId: 36,
        openResize: true,
        title: 'promotion.firstTimeDeposit.banner.bannerContext',
        info: {
          multiLang: false,
        },
      },
    ]
  },
  internationalBankTransfer: {
    DEPOSIT: {
      ALLOW_ALL: false,
      WHITELIST: WHITELIST,
    },
  },
  restrictCreditCardCountryList: ['3589'], //Pakistan
  webtradeCode: `new MetaTraderWebTerminal( "webterminal", {
    version: 4,
    servers: ["IVMarkets-Demo","IVMarkets-Live"],
    server: "IVMarkets-Live",
    demoAllServers: true,
    utmSource: "www.ivmarkets.com",
    startMode: "",
    language: "en",
    colorScheme: "black_on_white"
} );`,
}
