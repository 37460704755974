import {
  DEPOSIT_BONUS_PATHNAME,
  NO_DEPOSIT_BONUS_PATHNAME,
  REFER_A_FRIEND_PATHNAME,
  TRADING_BONUS_PATHNAME,
  CASHBACK_PATHNAME,
  DEPOSIT_20_BONUS_PATHNAME,
  XMAS_MAKE_A_WISH_PATHNAME,
  MILLION_DOLLAR_CASH_POOL_PATHNAME,
  FIRST_TIME_DEPOSIT_PATHNAME,
} from '@/constants/route'
import Vue from 'vue'
import VueRouter from 'vue-router'
import identity from './identity'
import store from '@/store/index'
import { PROMOTION_TYPE } from '@/constants/promotion'

Vue.use(VueRouter)

function canParticipate(CAMPAIGN_TYPE) {
  //https://stackoverflow.com/questions/8217419/how-to-determine-if-javascript-array-contains-an-object-with-an-attribute-that-e
  //https://stackoverflow.com/questions/42603909/accessing-vuex-state-when-defining-vue-router-routes
  return (
    store.state.promotion.eligibleCampaigns.filter(campaign => {
      return campaign.campaignType === CAMPAIGN_TYPE
    }).length > 0
  )
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/shared/Layout'),
      children: [
        {
          path: '/',
          name: '/',
          meta: {
            noShowLoading: true,
          },
        },
        {
          path: '/logout',
          name: 'logout',
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
            restrictBanner: true,
          },
        },
        {
          path: '/register',
          name: 'register',
          component: () => import('@/views/register/Register'),
          meta: {
            restrictBanner: true,
          },
        },
        {
          path: '/demo',
          name: 'demo',
          component: () => import('@/views/register/Demo'),
          meta: {
            restrictBanner: true,
          },
        },
        {
          path: '/authority',
          name: 'authority',
          component: () => import('@/views/register/Authority'),
        },
        {
          path: '/registerFinish',
          name: 'registerFinish',
          component: () => import('@/views/register/RegisterFinish'),
        },
        {
          path: '/home',
          name: 'home',
          // component: () => import('@/views/Home'),
          component: () => import('@/views/home/Home'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/homeDemo',
          name: 'homeDemo',
          component: () => import('@/views/home/HomeDemo'),
        },
        {
          path: '/liveAccount',
          name: 'liveAcc',
          component: () => import('@/views/home/Home'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/openLiveAccount',
          name: 'openLiveAccount',
          component: () => import('@/views/OpenLiveAccount'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/openDemoAccount',
          name: 'openDemoAccount',
          component: () => import('@/views/OpenDemoAccount'),
        },
        {
          path: '/depositFunds',
          name: 'depositFund',
          component: () => import(`@/views/deposit/DepositFunds`),
          meta: {
            authority: true,
          },
        },
        // temporary for testing
        {
          path: '/depositFundsCps',
          name: 'depositFunds',
          component: () => import(`@/views/deposit/DepositFundsCps`),
          meta: {
            authority: true,
          },
        },
        {
          path: '/withdrawFunds',
          name: 'withdrawFunds',
          component: () => import('@/views/withdraw/WithdrawFunds'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/transferFunds',
          name: 'transferBetweenAccs',
          component: () => import('@/views/TransferFunds'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/transactionHistory',
          name: 'transactionHis',
          component: () => import('@/views/TransactionHistory'),
          meta: {
            authority: true,
          },
        },
        {
          path: NO_DEPOSIT_BONUS_PATHNAME,
          name: 'noDeposit',
          component: () => import('@/views/promotion/NoDepositBonus'),
          meta: {
            restrictBanner: true,
          },
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.NO_DEPOSIT_BONUS)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: REFER_A_FRIEND_PATHNAME,
          name: 'referFriends',
          component: () => import('@/views/promotion/ReferFriends'),
          meta: {
            restrictBanner: true,
          },
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.REFER_A_FRIEND)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: DEPOSIT_BONUS_PATHNAME,
          name: 'depositBonus',
          component: () => import('@/views/promotion/DepositBonus'),
          meta: {
            restrictBanner: true,
          },
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.DEPOSIT_BONUS)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: DEPOSIT_20_BONUS_PATHNAME,
          name: 'deposit20Bonus',
          component: () => import('@/views/promotion/DepositBonus'),
          meta: {
            restrictBanner: true,
          },
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.DEPOSIT_BONUS)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: TRADING_BONUS_PATHNAME,
          name: 'tradingBonus',
          component: () => import('@/views/promotion/TradingBonus'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.TRADING_BONUS)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: CASHBACK_PATHNAME,
          name: 'cryptoCashback',
          component: () => import('@/views/promotion/CryptoCashback'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.CASHBACK)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: XMAS_MAKE_A_WISH_PATHNAME,
          name: 'xmasMakeAWish',
          component: () => import('@/views/promotion/XmasMakeAWish'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.XMASMAKEAWISH)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: MILLION_DOLLAR_CASH_POOL_PATHNAME,
          name: 'millionDollarCashPool',
          component: () => import('@/views/promotion/MillionDollarCashPool'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.MILLION_DOLLAR_CASH_POOL)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: FIRST_TIME_DEPOSIT_PATHNAME,
          name: 'firstTimeDeposit',
          component: () => import('@/views/promotion/FirstTimeDeposit'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.FIRST_TIME_DEPOSIT)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: '/downloads',
          name: 'downloads',
          component: () => import('@/views/Downloads'),
        },
        {
          path: '/myProfile',
          name: 'myProfile',
          component: () => import('@/views/MyProfile'),
        },
        {
          path: '/changePassword',
          name: 'cpPassword',
          component: () => import('@/views/ChangePassword'),
        },
        {
          path: '/contactUs',
          name: 'contactUs',
          component: () => import(`@/views/ContactUs`),
        },
        {
          path: '/paymentDetails',
          name: 'payDetails',
          component: () => import('@/views/bankCardMange/index.vue'),
          meta: { authority: true },
        },
        {
          path: '/autochartist',
          name: 'autochartist',
          component: () => import('@/views/Autochartist'),
        },
        // pages of thirdParty
        {
          path: '/fasapay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/neteller/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/neteller/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paysafe_neteller/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/skrill/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/skrill/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paytrust/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paywize/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/bitwallet/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/kr_df/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paytrust/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/zotapay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paytoday/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/unionpay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/cps/thirdPartyDepositResult',
          name: 'cpsThirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/tinkbit/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/creditcard/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/apg/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/sd/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/crypto/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/crypto/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/cryptousdt/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/cryptousdt/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/stic/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/stic/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/xpay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/trustly/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
            restrictApi: true,
            noShowLoading: true,
          },
        },
        {
          path: '/trustly/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
            restrictApi: true,
            noShowLoading: true,
          },
        },
        {
          path: '/astropay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/astropay/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/solid/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/thirdPartyDepositResult/:id',
          name: 'thirdPartyResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/thirdPartyDepositFailed/:id',
          name: 'thirdPartyFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        // pages of deposit
        {
          path: '/deposit/cps/:name',
          name: 'cps',
          component: () => import('@/views/deposit/cps/Index'),
        },
        {
          path: '/deposit/cpsIndex/:name',
          name: 'cpsIndex',
          component: () => import('@/views/deposit/cps/IndexCps'),
        },
        {
          path: '/deposit/apg',
          name: 'apgDeposit',
          component: () => import('@/views/deposit/CreditOrDebit/ApgPay'),
        },
        {
          path: '/deposit/sdPay',
          name: 'sdPayDeposit',
          component: () => import('@/views/deposit/CreditOrDebit/Sdpayment'),
        },
        {
          path: '/deposit/virtualPay',
          name: 'virtualPayDeposit',
          component: () => import('@/views/deposit/CreditOrDebit/VirtualPay'),
        },
        {
          path: '/deposit/solidpayment',
          name: 'solidpayment',
          component: () => import('@/views/deposit/CreditOrDebit/Solidpayment'),
        },
        {
          path: '/deposit/worldcard',
          name: 'worldcardDeposit',
          component: () => import('@/views/deposit/CreditOrDebit/Worldcard'),
        },
        // {
        //   path: '/deposit/maxispay',
        //   name: 'maxisPayDeposit',
        //   component: () => import('@/views/deposit/CreditOrDebit/Maxispayment'),
        // },
        {
          path: '/deposit/bitwalletDeposit',
          name: 'bitwalletDeposit',
          component: () => import('@/views/deposit/BitwalletDeposit'),
        },
        {
          path: '/deposit/dragonPhoenixDeposit',
          name: 'dragonPhoenixDeposit',
          component: () => import('@/views/deposit/DragonPhoenixDeposit'),
        },
        {
          path: '/deposit/ebuyDeposit',
          name: 'ebuyDeposit',
          component: () => import('@/views/deposit/ebuyDeposit'),
        },
        {
          path: '/deposit/fasaPay',
          name: 'fasaPay',
          component: () => import('@/views/deposit/FasaPay'),
        },
        {
          path: '/deposit/internationalSwift',
          name: 'internationalSwift',
          component: () => import('@/views/deposit/international/InternationalSwift'),
        },
        {
          path: '/deposit/internationalEqualsMoney',
          name: 'internationalEqualsMoney',
          component: () => import('@/views/deposit/international/EqualsMoney'),
        },
        {
          path: '/deposit/canadaLocalBankDeposit',
          name: 'canadaLocalBank',
          component: () => import('@/views/deposit/CanadaLocalBankDeposit'),
        },
        {
          path: '/deposit/mobilePay',
          name: 'mobilePay',
          component: () => import('@/views/deposit/MobilePay'),
        },
        {
          path: '/deposit/sticPay',
          name: 'sticPay',
          component: () => import('@/views/deposit/SticPay'),
        },
        {
          path: '/deposit/skrillPay',
          name: 'skrillPay',
          component: () => import('@/views/deposit/SkrillPay'),
        },
        {
          path: '/deposit/unionPay',
          name: 'unionPay',
          component: () => import('@/views/deposit/UnionPay'),
        },
        {
          path: '/deposit/unionPayChina',
          name: 'unionPayChina',
          component: () => import('@/views/deposit/UnionPayChina'),
        },
        {
          path: '/deposit/thailandZotaPay/:id',
          name: 'thailandZotaPay',
          component: () => import('@/views/deposit/ThailandZotapay'),
        },
        {
          path: '/deposit/thailandXpay',
          name: 'thailandXpay',
          component: () => import('@/views/deposit/thailand/Xpay'),
        },
        // {
        //   path: '/deposit/nigeriaZotapay',
        //   name: 'nigeriaZotapay',
        //   component: () => import('@/views/deposit/NigeriaZotapay'),
        // },
        {
          path: '/deposit/Paystack',
          name: 'paystack',
          component: () => import('@/views/deposit/Paystack'),
        },
        {
          path: '/deposit/vietnamXpay',
          name: 'vietnamXpay',
          component: () => import(`@/views/deposit/vietnam/Xpay`),
        },
        {
          path: '/deposit/vietnamZotapay',
          name: 'vietnamZotapay',
          component: () => import(`@/views/deposit/vietnam/Zotapay`),
        },
        // {
        //   path: '/deposit/malaysiaPaytrust',
        //   name: 'malaysiaPaytrust',
        //   component: () => import(`@/views/deposit/malaysia/PayTrust`),
        // },
        {
          path: '/deposit/malaysiaZotapay',
          name: 'malaysiaZotapay',
          component: () => import(`@/views/deposit/malaysia/Zotapay`),
        },
        {
          path: '/deposit/malaysiaXpay',
          name: 'malaysiaXpay',
          component: () => import(`@/views/deposit/malaysia/Xpay`),
        },
        {
          path: '/deposit/philipinessPayment',
          name: 'philipinessPayment',
          component: () => import(`@/views/deposit/PhilipinessPayment`),
        },
        {
          path: '/deposit/indonesiaBankwire',
          name: 'indonesiaBankwire',
          component: () => import(`@/views/deposit/indonesia/Bankwire`),
        },
        {
          path: '/deposit/indonesiaXpay',
          name: 'indonesiaXpay',
          component: () => import(`@/views/deposit/indonesia/Xpay`),
        },
        {
          path: '/deposit/indonesiaZotapay',
          name: 'indonesiaZotapay',
          component: () => import(`@/views/deposit/indonesia/Zotapay`),
        },
        {
          path: '/deposit/indiaZotapay',
          name: 'indiaZotapay',
          component: () => import('@/views/deposit/india/Zotapay'),
        },
        // {
        //   path: '/deposit/bitcoin',
        //   name: 'bitcoin',
        //   component: () => import('@/views/deposit/crypto/Bitcoin'),
        // },
        // {
        //   path: '/deposit/usdt/:id',
        //   name: 'usdt',
        //   component: () => import('@/views/deposit/crypto/USDT'),
        // },
        // {
        //   path: '/deposit/bitcoinCPS',
        //   name: 'bitcoinCPS',
        //   component: () => import('@/views/deposit/crypto/Bitcoin_CPS'),
        // },
        // {
        //   path: '/deposit/OmniCPS',
        //   name: 'omniCPS',
        //   component: () => import('@/views/deposit/crypto/Omni_CPS'),
        // },
        // {
        //   path: '/deposit/usdt_cps',
        //   name: 'usdt_cps',
        //   component: () => import('@/views/deposit/crypto/USDT_CPS'),
        // },
        // {
        //   path: '/deposit/trc20_cps',
        //   name: 'trc20_cps',
        //   component: () => import('@/views/deposit/crypto/TRC20_CPS'),
        // },
        // {
        //   path: '/deposit/astropayDeposit',
        //   name: 'astropayDeposit',
        //   component: () => import('@/views/deposit/AstropayDeposit'),
        // },
        {
          path: '/deposit/southAfricaPayment',
          name: 'southAfricaPayment',
          component: () => import('@/views/deposit/SouthAfricaPayment'),
        },
        {
          path: '/deposit/neteller',
          name: 'neteller',
          component: () => import('@/views/deposit/NetellerDeposit'),
        },
        {
          path: '/deposit/AfricaZotapay',
          name: 'africaZotapay',
          component: () => import('@/views/deposit/AfricaZotapay'),
        },
        // {
        //   path: '/deposit/PerfectMoney',
        //   name: 'perfectMoney',
        //   component: () => import('@/views/deposit/PerfectMoney'),
        // },
        {
          path: '/deposit/IndiaBankTransfer',
          name: 'indiaBankTransfer',
          component: () => import('@/views/deposit/india/IndiaBankTransfer'),
        },
        {
          path: '/deposit/NigeriaBankTransfer',
          name: 'nigeriaBankTransfer',
          component: () => import('@/views/deposit/nigeria/NigeriaBankTransfer'),
        },
        {
          path: '/deposit/localdepositor',
          name: 'localdepositor',
          component: () => import('@/views/deposit/LocalDepositor'),
        },
        {
          path: '/deposit/iDeal',
          name: 'iDeal',
          component: () => import('@/views/deposit/iDeal'),
        },
        {
          path: '/tradingTool',
          name: 'tradingTool',
          component: () => import('@/views/tradingTool/index'),
        },
        {
          path: '/activity',
          name: 'promotion',
          component: () => import('@/views/activity/index'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/shared/LoginLayout'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(`@/views/LoginClient`),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
        {
          path: '/login/to_login',
          name: 'to_login',
          component: () => import(`@/views/LoginClient`),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
        {
          path: '/forgetPassword',
          name: 'forgetPassword',
          component: () => import(`@/views/ForgetPasswordReq`),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/resetProfilePassword',
          name: 'resetProfilePassword',
          component: () => import(`@/views/ResetProfilePassword`),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/resetPassword',
          name: 'resetPassword',
          component: () => import('@/views/ResetPassword'),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/forceChangePassword',
          name: 'forceChangePassword',
          component: () => import('@/views/ForceChangePassword'),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/error',
          name: 'error',
          component: () => import('@/views/register/Error'),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
      ],
    },
    {
      path: '',
      component: () => import('@/views/shared/Layout'),
      children: [
        {
          name: 'component-demo',
          path: '/component-demo',
          component: () => import('@/views/ComponentDemo.vue'),
        },
      ],
    },
    {
      path: '',
      component: () => import('@/views/shared/Layout'),
      children: [
        {
          name: '404',
          path: '*',
          component: () => import('@/views/404'),
        },
      ],
    },
  ],
})

export default router
identity.authorize()
