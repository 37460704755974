// 進行同步執行
import Vue from 'vue'

export default {
  setUserName(state, value) {
    Vue.ls.set('setUserName', value)
    state.userName = value
  },
  setUserType(state, value) {
    state.userType = value
  },
  setLoginStatus(state, value) {
    if (value == '') {
      Vue.ls.set('setUserName', '')
      state.userName = ''
      state.individualUserName = ''
      state.userType = ''
      state.userTypeEnum = ''
      state.pendingQuestionnaireCompletion = false
      state.openAccountEnabled = false
      state.countryCode = ''
      state.accessToken = ''
      state.email = ''
      state.url = ''
      state.requireIDProof = false
      state.requireAddressProof = false
      state.pendingAddressProofApproval = false
      state.pendingIDProofApproval = false
      state.isChangedLangAtLandingPage = false
      state.registerStep = 1
      state.isDemo = false
      state.tradingToolUrl = {}
      state.idPass = false
      state.regulator = null
      state.keepUploadID = false
      state.keepUploadPOA = false
      state.failedSecondTime = false
      state.employmentFinanceAnswers = []
      state.tradingAnswers = []
      state.autochartisToken = {}
      state.autochartisExpiryTime = ''
      state.emailMasked = ''
    }
    state.loginStatus = value
  },
  setUserTypeEnum(state, value) {
    state.userTypeEnum = value
  },
  setQuestionnaire(state, value) {
    state.pendingQuestionnaireCompletion = value
  },
  setOpenAccountAction(state, value) {
    state.openAccountEnabled = value
  },
  setIndividualName(state, value) {
    state.individualUserName = value
  },
  setCountryCode(state, value) {
    state.countryCode = value
  },
  setAccessToken(state, value) {
    state.accessToken = value
  },
  setIbUrl(state, value) {
    state.url = value
  },
  setLang(state, value) {
    state.lang = value
  },
  setRequireAddressProof(state, value) {
    state.requireAddressProof = value
  },
  setRequireIDProof(state, value) {
    state.requireIDProof = value
  },
  setPendingAddressProofApproval(state, value) {
    state.pendingAddressProofApproval = value
  },
  setPendingIDProofApproval(state, value) {
    state.pendingIDProofApproval = value
  },
  setIsChangedLangAtLandingPage(state, value) {
    state.isChangedLangAtLandingPage = value
  },
  setRegisterStep(state, value) {
    state.registerStep = value
  },
  setDemo(state, value) {
    state.isDemo = value
  },
  setTradingToolUrl(state, value) {
    state.tradingToolUrl = value
  },
  setEmail(state, value) {
    state.email = value
  },
  setIdPass(state, value) {
    state.idPass = value
  },
  setRegulator(state, value) {
    state.regulator = value
  },
  setKeepUploadID(state, value) {
    state.keepUploadID = value
  },
  setKeepUploadPOA(state, value) {
    state.keepUploadPOA = value
  },
  setFailedSecondTime(state, value) {
    state.failedSecondTime = value
  },
  getEmploymentFinanceAnswers(state, value) {
    state.employmentFinanceAnswers = value
  },
  getTradingAnswers(state, value) {
    state.tradingAnswers = value
  },
  setAutochartistToken(state, value) {
    state.autochartisToken = value
  },
  setAutochartisExpiryTime(state, value) {
    state.autochartisExpiryTime = value
  },
  setAutoNotifyStatus(state, value) {
    state.autoNotifyStatus = value
  },
  setEmailMasked(state, value) {
    state.emailMasked = value
  },
  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      if (key != 'uuid' && key != 'lang') state[key] = ''
    })
  },
}
