export default {
  en: 'English',
  zh: '中文',
  fr: 'Français',
  common: {
    liveChat: {
      desc: `Besoin d'aide ? Chat en direct`,
    },
    country: { international: 'International', australia: 'Australie' },
    verification: {
      toSlide: 'Faites glisser pour compléter le puzzle',
    },
    field: {
      nameOnCard: 'Nom figurant sur la carte',
      cardholderName: `Nom du titulaire de la carte`,
      first4Digits: '4 premiers chiffres sur la carte',
      last3Digits: '3 derniers chiffres sur la carte',
      first6Digits: '6 premiers chiffres sur la carte',
      last4Digits: '4 derniers chiffres sur la carte',
      bankName: 'Nom de la banque',
      bankAddress: 'Adresse de la banque',
      branchAddress: 'Adresse de la succursale',
      bankBranch: 'Succursale de la banque',
      bankCity: 'Ville de la banque',
      bankProvince: 'Province de la banque',
      bankBeneficiaryName: 'Nom du bénéficiaire à la banque',
      bankBeneAccName: 'Nom du compte du bénéficiaire à la banque',
      bankBeneAccNum: 'Numéro du compte du bénéficiaire à la banque',
      bankBeneAddress: 'Adresse du bénéficiaire',
      bankAccNum: 'Numéro du compte bancaire',
      bankAccName: 'Nom du compte bancaire',
      bankHolderAddress: 'Adresse du titulaire du compte',
      address: 'Adresse',
      accName: 'Nom du compte',
      accNum: 'Numéro du compte',
      accNumTo: 'Numéro du compte',
      bsbNum: 'Numéro BSB',
      swift: 'Code SWIFT',
      swiftNonAUD: 'Code SWIFT (Comptes non-AUD)',
      amt: 'Montant',
      amtUSD: 'Montant(USD)',
      availableAmount: 'Montant disponible',
      transitNumber: 'Numéro de transit',
      institutionNumber: `Numéro de l'établissement`,
      imptNotes: 'Notes importantes',
      yes: 'Oui',
      no: 'Non',
      payMethod: 'Méthode de paiement:',
      expDate: `Date d'expiration`,
      bsb: 'BSB',
      abaSortCode: 'Code ABA/guichet:',
      country_region: 'Pays / Région',
      currLeverage: 'Levier actuel',
      accEquity: 'Capitaux propres du compte',
      newLeverage: 'Levier souhaité',
      currPw: 'Mot de passe actuel',
      newPw: 'Nouveau mot de passe',
      confirmPw: 'Confirmer le nouveau mot de passe',
      loginEmail: 'E-mail de connexion',
      email: 'E-mail',
      pw: 'Mot de passe',
      accType: 'Type de compte',
      name: 'Nom',
      emailAdd: 'Adresse e-mail',
      phone: 'Numéro de téléphone',
      dob: 'Date de naissance',
      nat: 'Nationalité',
      country: 'Pays de résidence',
      street: 'Numéro/Nom de la rue',
      suburbOrCity: 'Banlieue/Ville',
      provinceOrState: 'Province/État',
      postcode: 'Code postal',
      empStat: 'Statut professionnel',
      annIncome: 'Revenu annuel',
      save: 'Investissement',
      source: 'Source de revenus',
      industry: 'Industrie',
      statusDef: 'DÉFINITION DES STATUTS',
      month: 'Mois',
      year: 'Année',
      select: 'Sélectionner',
      completed: 'Complet',
      incomplete: 'Incomplet',
      submitted: 'Soumis',
      successful: 'Réussi',
      processing: 'En cours',
      cancelled: 'Annulé',
      failed: 'Échec',
      rejected: 'Rejeté',
      upload: 'Télécharger',
      pending: `en attendant`,
      ccNum: 'Numéro de carte de crédit',
      ccExpireDate: `Date d'expiration`,
      notes: 'Notes',
      bankAccOrIBAN: 'Numéro du compte bancaire/IBAN',
      actualAmount: 'montant actuel',
      resetBalTo: 'Réinitialiser le solde à',
      ipChangeWarning:
        'Vous recevrez un e-mail d’alerte si une personne se connecte à votre compte depuis une localisation différente.',
      enterVerificationCode: 'Saisissez le code de vérification',
      tips: '',
      pleaseInput: '',
      confirmClose: '',
      notBind: '',
      infoDetails: '',
      loginWarn: '',
      noDataNow: '',
      hasAccountAlready: '',
    },
    column: {
      date: 'DATE',
      type: 'TYPE',
      server: 'SERVEUR',
      status: 'ÉTAT',
      comment: 'COMMENTAIRE',
      accNum: 'NUMÉRO DU COMPTE',
      currency: 'DEVISE',
      equity: 'CAPITAUX PROPRES',
      leverage: 'LEVIER',
      resetMT4PW: 'RÉINITIALISER LE MOT DE PASSE MT4',
      resetMT5PW: 'RÉINITIALISER LE MOT DE PASSE MT5',
      tradingAcc: 'COMPTE DE TRADING',
      method: 'MÉTHODE',
      amt: 'MONTANT',
      procNote: 'NOTES SUR LE TRAITEMENT',
      number: 'NUMÉRO DE LA CARTE',
      PLATFORM: 'PLATE-FORME',
      resetBal: 'RÉINITIALISER LE SOLDE',
    },
    key: {
      from: 'DE',
      to: 'À',
    },
    fund: {
      must: 'DOIT',
      infoHover: 'Le nom indiqué sur votre carte {must} correspondre au nom associé à votre compte de trading',
      ccUploadReminder:
        'Veuillez télécharger une photo du recto de votre carte aux fins de vérification. (Veillez à couvrir les 6 chiffres centraux)',
    },
    button: {
      bkToHm: `Retour à la page d'accueil`,
      goToIB: `ACCÉDER au portail IB`,
      logout: 'DÉCONNEXION',
      submit: 'Soumettre',
      selectFile: 'Sélectionner un fichier',
      depositFunds: 'DÉPOSER DES FONDS',
      withdrawFunds: 'RETIRER DES FONDS',
      ibPortal: `PORTAIL IB`,
      download: 'TÉLÉCHARGEMENTS',
      changeLeverage: `CHANGER L'EFFET DE LEVIER`,
      cancel: 'Annuler',
      changePw: 'CHANGER DE MOT DE PASSE',
      forgetPw: 'MOT DE PASSE OUBLIÉ',
      confirm: 'Confirmer',
      login: 'CONNEXION',
      forgotPw: 'Mot de passe oublié?',
      completeQuestionnaire: 'REMPLIR LE QUESTIONNAIRE',
      identityProof: 'REMPLIR LA DEMANDE',
      upload: 'TÉLÉCHARGER',
      export: 'EXPORTER',
      ok: 'OK',
      hide: 'MASQUER',
      showAll: 'TOUT AFFICHER',
      createLiveAccount: `Ouvrir un compte réel`,
      redeem: `Échanger`,
      sendCode: 'Envoyer le code',
      resendCode: 'Renvoyer le code',
    },
    formValidation: {
      accReq: 'Le numéro de compte est requis',
      amtReq: 'Le montant est requis',
      ccReq: 'Le numéro de la carte de crédit est requis',
      cardReq: 'Le numéro de carte est requis',
      filePhoto: 'La photo de la carte de crédit est requise',
      fileReceipt: 'Veuillez télécharger un reçu',
      filePhotoReq: 'La photo de la carte est requise',
      noteMoreThan: 'Remarque: la limite de longueur est de {limit} caractères',
      noteLessThan: 'Remarque: la limite de longueur est de {limit} caractères',
      bankCardReq: 'Veuillez sélectionner une carte',
      phoneNumberReq: 'Le numéro de téléphone portable pour la banque est requis',
      nationaIdReq: "Une pièce d'identité nationale est requise",
      numberReq: 'Veuillez entrer le numéro',
      alphanumericReq: 'Veuillez saisir alphanumérique',
      idTypeReq: "Veuillez choisir un type d'identification",
      dobReq: 'Veuillez choisir votre date de naissance',
      pobReq: 'Veuillez choisir votre lieu de naissance',
      nationalityReq: 'Veuillez choisir votre nationalité',
      titleReq: 'Veuillez choisir votre titre',
      firstNameReq: "Entrez votre prénom s'il vous plait",
      lastNameReq: 'Veuillez entrer votre nom de famille',
      emailReq: 'Veuillez saisir votre adresse e-mail',
      addressReq: 'Veuillez entrer votre adresse',
      suburbReq: 'Veuillez entrer votre ville ou banlieue',
      stateReq: 'État ou province requis',
      postcodeReq: 'Veuillez saisir votre code postal',
      countryReq: 'Veuillez sélectionner votre pays de résidence',
      bankCodeReq: 'Veuillez saisir la banque émettrice de la carte',
      dynamicReq: '{dynamic} est requis',
      mobReq: 'Veuillez entrer numéro de téléphone',
      cardOnNameReq: 'Le nom indiqué sur la carte est requis',
      cardholderNameReq: `Le nom du titulaire de la carte est obligatoire`,
      digits16: 'Veuillez entrer 16 chiffres',
      digits3or4: 'Veuillez entrer 3 ou 4 chiffres',
      digitsFirst6: 'Veuillez entrer les 6 premiers chiffres',
      digitsLast4: 'Veuillez entrer les 4 derniers chiffres',
      digitsLast3: 'Veuillez entrer les 3 derniers chiffres',
      mid6Digits: 'Veuillez entrer les {number} chiffres centraux',
      expDateReq: `La date d'expiration est requise`,
      cvvReq: 'Le code de sécurité CVV est requis',
      file: 'Veuillez télécharger un fichier',
      amtLarger: 'Le montant ne peut pas être nul et doit être supérieur ou égal à {minLimit} {currency}',
      amt0: 'Le montant ne peut pas être égal à 0',
      amtLess: 'Le montant de votre dépôt ne peut pas être supérieur à {maxLimit} {currency}',
      note256: 'Notez que la longueur maximum est de 256 caractères',
      emailFormat: 'Veuillez entrer une adresse e-mail correcte',
      payMethodReq: 'La méthode de paiement est requise',
      expReq: `La date d'expiration est requise`,
      bankNameReq: 'Le nom de la banque est requis',
      bankCityReq: 'La ville de la banque est requise',
      bankProvinceReq: 'La province de la banque est requise',
      bankAddressReq: `L'adresse de la banque est requise`,
      bankBranchReq: 'La succursale de la banque est requise',
      bankAccNumReq: 'Le numéro du compte bancaire est requis',
      bankBeneficiaryNameReq: 'Le nom du bénéficiaire à la banque est requis',
      bankHolderAddressReq: `L'adresse du titulaire du compte est requise`,
      swiftReq: 'Le code Swift est requis',
      accHolderAddress: `L'adresse du titulaire du compte est requise`,
      bsbReq: 'Le BSB est requis',
      accNumIBANReq: 'Le numéro du compte bancaire/IBAN est requis',
      accNameReq: 'Le nom associé au compte bancaire est requis',
      withdrawMethodReq: 'Veuillez sélectionner une méthode de retrait',
      tnc: 'Veuillez accepter les Conditions générales.',
      currPwReq: 'Le mot de passe actuel est requis',
      currPwWrong: 'Le mot de passe actuel est incorrect',
      newPwReq: 'Un nouveau mot de passe est requis',
      newPwFormat:
        'Votre mot de passe doit contenir entre 8 et 16 caractères et une combinaison de lettres (a-z et A-Z), de chiffres (0-9) et de caractères spéciaux tels que !@#$%^&*.()',
      IdenficalPw: 'Le nouveau mot de passe doit être différent du précédent',
      confirmPwReq: 'Veuillez entrer le nouveau mot de passe à nouveau',
      confirmNotMatch: 'Les deux mots de passe ne correspondent pas',
      pwReq: 'Le mot de passe est requis',
      accCurrReq: 'Vous devez sélectionner une devise pour le compte',
      tradAccReq: 'Veuillez sélectionner un compte de trading',
      answerReq: 'Veuillez sélectionner',
      masterCardJCBReq: 'Veuillez utiliser une Master Card / JCB pour déposer',
      masterCardVisaReq: 'Veuillez utiliser une Master Card / Visa pour déposer',
      masterCardAmexJCBReq: `Veuillez utiliser une Master Card / AMEX / JCB pour déposer`,
      resetBalAmt: 'Veuillez fixer le montant entre 0 et 1 000 000',
      verCodeReq: 'Le code de vérification est obligatoire',
      emailFormatWrong: 'Format d’e-mail non correct',
      passwordFormatWrong: 'Format de mot de passe non correct',
    },
    // for WITHDRAW FUNDS
    withdrawChannel: {
      banktransferbpaypolipay: 'Virement bancaire/BPay/POLiPay',
      banktransfer: 'Virement bancaire',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'Virement UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Carte de crédit',
      bpay: 'Bpay',
      creditdebitcard: 'Carte de crédit/débit',
      bankwiretransferinternational: 'Virement bancaire (International)',
      banktransferaustralia: 'Virement bancaire (Australie)',
      banktransferinternational: 'Virement bancaire (International)',
      banktransferchina: 'Virement bancaire (International)',
      banktransferequals: 'Virement bancaire (International)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Virement de courtier à courtier',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Banque virtuelle (Thaïlande)',
      internetbankingnigeria: 'Banque virtuelle (Nigeria)',
      internetbankingvietnam: 'Banque virtuelle (Vietnam)',
      internetbankingmalaysia: 'Banque virtuelle (Malaisie)',
      internetbankingindonesia: 'Banque virtuelle (Indonésie)',
      internetbankingkorea: 'Banque virtuelle (Corée du Sud)',
      internetbankingindia: 'Banque virtuelle (Inde)',
      internetbankingphilippine: 'Banque virtuelle (Philippines)',
      internetbankingsouthafrica: 'Banque virtuelle (Afrique du Sud)',
      internetbankinguganda: 'Banque virtuelle (Ouganda)',
      internetbankingrwanda: 'Banque virtuelle (Rwanda)',
      internetbankingzambia: 'Banque virtuelle (Zambie)',
      internetbankingcongo: 'Banque virtuelle (Congolais)',
      internetbankingcameroon: 'Banque virtuelle (Cameroun)',
      internetbankingburundi: 'Banque virtuelle (Burundi)',
      internetbankingkenya: 'Banque virtuelle (Kenya)',
      internetbankingghana: 'Banque virtuelle (Ghana)',
      internetbankingtanzania: 'Banque virtuelle (Tanzanie)',
      internetbankingcanada: 'Banque virtuelle (Canada)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Virement bancaire Vietnam',
      nigeriabanktransfer: 'Virement bancaire Nigeria',
      polipayment: 'Paiement POLi',
      bankwiretransferaustralia: 'Virement bancaire (Australie)',
      thailandinstantbankwiretransfer: 'Virement bancaire express Thaïlande',
      nigeriainstantbankwiretransfer: 'Virement bancaire express Nigérian',
      vietnaminstantbankwiretransfer: 'Virement bancaire express vietnamien',
      malaysiainstantbankwiretransfer: 'Virement bancaire express malaisien',
      indonesiainstantbankwiretransfer: 'Virement bancaire express Indonesia',
      southkoreabanktransfer: 'Virement bancaire local en Corée du Sud',
      southafricainstantbankwiretransfer: 'Virement bancaire express Afrique du Sud',
      philippinesinstantbankwiretransfer: 'Virement bancaire instantané aux Philippines',
      ugandabanktransfer: 'Virement bancaire local en Ouganda',
      rwandabanktransfer: 'Virement bancaire local au Rwanda',
      zambiabanktransfer: 'Virement bancaire local en Zambie',
      congobanktransfer: 'Virement bancaire local au Congo',
      cameroonbanktransfer: 'Virement bancaire local au Cameroun',
      burundibanktransfer: 'Virement bancaire local au Burundi',
      kenyabanktransfer: 'Virement bancaire local au Kenya',
      ghanabanktransfer: 'Virement bancaire local au Ghana',
      tanzaniabanktransfer: 'Virement bancaire local en Tanzanie',
      indiabanktransfer: 'Virement bancaire Inde',
      banktransferbpaypolipayaustralia: 'Virement bancaire/BPay/POLiPay (Australie)',
      banktransferbpaypolipayinternational: 'Virement bancaire/BPay/POLiPay (International)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Virement bancaire local canadien',
      cryptobitcoin: 'Crypto-monnaie-Bitcoin',
      cryptotetheromni: 'Crypto-monnaie-Tether(OMNI)',
      cryptotethererc20: 'Crypto-monnaie-Tether(ERC20)',
      cryptotethertrc20: 'Crypto-monnaie-Tether(TRC20)',
      cryptocurrencybitcoin: 'Crypto-monnaie-Bitcoin',
      cryptocurrencyusdt: 'Crypto-monnaie-USDT',
      cryptocurrencyeth: 'Crypto-monnaie-ETH',
      cryptocurrencyusdc: 'Crypto-monnaie-USDC(ERC-20)',
      cryptocurrencyusdcerc20: 'Crypto-monnaie-USDC(ERC-20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      E_WALLET: 'PORTEFEUILLE ÉLECTRONIQUE',
      CRYPTOCURRENCY: 'CRYPTOCURRENCY',
      LOCAL_TRANSFER: 'TRANSFERT LOCAL',
      archiveThisCard: "Veuillez archiver cette carte si elle n'est plus valide ou utilisée",
      manageCreditCards: 'Gérer les cartes de crédit',
      update: 'Actualiser',
      archive: 'Archiver',
      requiredDocument: "Documents nécessaires à l'archivage de la carte",
      officialLetter: "Lettre / e-mail officiel de la banque indiquant que la carte n'est plus disponible",
      bankStatement: 'Relevé bancaire avec les transactions effectuées avec cette carte',
      expiredCard: 'Cartes expirées / indisponibles',
      confirmArchive: 'Êtes-vous sûr de vouloir archiver cette carte ?',
      confirmUpdate:
        "Êtes-vous sûr de vouloir actualiser la date d'expiration de cette carte ? Remarque : Puprime ne sera pas tenu responsable de l'échec du retrait en raison de la date d'expiration incorrecte que vous avez fournie.",
      pendingArchiveRequest:
        "Votre demande d'archivage de la carte de crédit est actuellement en attente d'approbation. Veuillez patienter jusqu'à la fin du processus avant d'effectuer un retrait, ou contactez notre service client via le chat pour accélérer le processus.",
    },
  },
  home: {
    leverage: {
      tnc: `<p>En soumettant la présente demande, je reconnais avoir été informé qu'un haut effet de levier comporte une large part de risque pour mon capital et qu'il est possible que je subisse une perte supérieure et non limitée au capital que j'ai déposé.<br><br>J'accepte le droit de l'émetteur des produits à vérifier mes activités de trading et à ajuster les niveaux de levier sur mon compte de trading, à sa discrétion et sans avertissement ou notification préalable.<br><br>Je comprends qu'en choisissant un haut effet de levier, je peux trader avec une plus grande marge, mais en subissant potentiellement des pertes plus importantes.</p>`,
      changeLeverageConfirm: `Votre demande a été soumise et sera traitée dans les meilleurs délais par notre équipe d'assistance.`,
      changeLeverageAutoComplete: `Votre demande de changement de levier est terminée`,
      changeLeverageReject: `La demande de modification de l'effet de levier a été rejetée`,
      changeLeverageFail: `Leverage change request is failed, please try later`,
      changeLeverageDefault: `Une erreur s'est produite, veuillez contacter {supportEmail} pour finaliser votre candidature`,
      alreadyApplied: `Vous avez déjà demandé à changer le levier sur votre compte et cette demande est actuellement étudiée par l'équipe d'assistance de {platform}. Veuillez attendre la finalisation de cette demande avant d'en soumettre une nouvelle.`,
      reduceLeverageWarn: `Avertissement : réduire votre effet de levier peut entraîner la fermeture forcée de vos positions ouvertes si votre compte ne disose pas d'une marge suffisante. `,
      reduceLeverageConfirm: `Voulez-vous continuer?`,
      autoRestore: `Les fonds propres de votre compte dépassant 20 000 USD, l’effet de levier maximal que vous pouvez utiliser est de 500:1. Vous pouvez modifier l’effet de levier à 1 000:1 lorsque les fonds propres de votre compte sont inférieurs ou égaux à 20 000 USD.`,
    },
    pw: {
      error: 'Échec de réinitialisation de votre mot de passe. Veuillez réessayer plus tard',
      confirm:
        'Votre mot de passe a été mis à jour.<br>Vous pouvez maintenant utiliser votre nouveau mot de passe pour accéder à votre compte de trading MT4/MT5.',
      email:
        '<p class=""first_info"">Nous venons de vous envoyer un e-mail contenant toutes les instructions nécessaires pour réinitialiser votre mot de passe.</p><p>Vérifiez le dossier de courrier indésirable de votre messagerie.</p>',
    },
    activate: {
      question: 'Voulez-vous réactiver votre compte en direct ?',
      confirm: `Nous avons bien reçu votre demande d'activation.<br>Votre compte sera activé dans les meilleurs délais.`,
    },
    accStatus: {
      active: 'Actif',
      rejected: 'Rejeté',
      inactive: 'Inactif',
      processing: 'En cours',
      activate: 'Activer',
    },
    pro1000: {
      desc1: `Les restrictions suivantes s’appliquent aux comptes avec un effet de levier de 1 000:1 :`,
      conditions: [
        `Le compte sélectionné n’est pas éligible à la promotion associée au crédit.`,
        `L’effet de levier peut être réduit automatiquement à 1:500 par le système si les fonds propres du compte sélectionné dépassent 20 000 USD ou l’équivalent. En cas de trade ouvert, une notification par e-mail sera envoyée avant l’ajustement de l’effet de levier.`,
        `Les limites de l’effet de levier peuvent varier selon les réglementations applicables.`,
        `Veuillez noter que l’effet de levier 1 000:1 n’est pas disponible pour les instruments financiers relatifs aux crypto-monnaies, aux actions, à l’argent (métal) et aux matières premières. La marge de ces instruments est maintenue conformément aux exigences de marge fixe des instruments et n’est pas affectée par l’effet de levier de 1 000:1.`,
      ],
      desc2: `En soumettant cette demande, je reconnais que je suis conscient qu’un effet de levier élevé comporte un risque important pour mon capital et qu’il est possible que je subisse une perte supérieure et non limitée au capital que j’ai déposé.`,
      desc3: `J’accepte que l’émetteur du produit soit autorisé à examiner mes activités de trading et à ajuster les niveaux d’effet de levier de mon compte de trading à sa discrétion et sans aucun avertissement ni notification préalable.`,
      desc4: `Je comprends qu’en choisissant un effet de levier élevé, je peux trader avec une marge plus importante, tout en subissant potentiellement des pertes importantes.`,
    },
  },
  paymentDetails: {
    subHeader: 'AJOUTER UNE MÉTHODE DE PAIEMENT',
    payMethod: 'Méthode de paiement',
    tip: `<p>Sélectionnez votre méthode de paiement favorite pour les dépôts et retraits futurs à partir du menu ci-dessous.</p><br />
    <p><b>Remarque :</b> pour garantir le traitement rapide de vos demandes de dépôt et de retrait, la réglementation AML/CTF (Lutte contre le blanchiment d'argent/Lutte contre le financement du terrorisme) exige que le nom indiqué pour la méthode de paiement choisie soit
    <b> {individualUserName}</b>.</p>`,
    history: {
      header: 'MÉTHODES DE PAIEMENT',
    },
    cc: {
      field: {
        ccNum: 'Numéro de carte de crédit',
        first6: 'Les six premiers',
        last4: 'Les quatre derniers',
        withrawlable: 'Le retrait maximal disponible pour cette carte est {amount}.',
      },
      formValidation: {
        upload: 'Photo de la carte de crédit requise',
      },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'Récupération du statut de votre dépôt... {countDown} secondes restantes.',
      successMsg: 'Merci pour votre dépôt. Vos fonds apparaîtront sur votre compte dans les meilleurs délais.',
      rejectMsg: 'Rejeté',
      failureMsg: 'Votre dépôt a échoué. Veuillez réessayer.',
      message: `Merci d'avoir approvisionné votre compte. Votre dépôt sera approuvé manuellement par l'équipe en charge des comptes de {platform} dans les meilleurs délais. Les fonds seront déposés sur votre compte après réception de l'approbation`,
      defaultException: `Pour confirmer votre dépôt auprès de {platform}, veuillez contacter l'assistance {email}`,
    },
    failed: {
      countDownMsg: 'Récupération du statut de votre dépôt... {countDown} secondes restantes.',
      rejectMsg: 'Rejeté',
    },
  },
  deposit: {
    alert: {
      depositBonus50: `Vous êtes éligible pour bénéficier d’un bonus de dépôt de 50 %. Activez-le avant d’effectuer votre dépôt !`,
    },
    default: {
      deposit: 'Dépôt',
      reference: 'Vos références',
      sendReceipt: 'Envoyer un reçu',
      sendReceiptTip: `Une fois le virement effectué, veuillez compléter le formulaire ci-dessous et joindre une capture d'écran, une photo ou une copie numérisée du reçu du virement bancaire montrant clairement votre nom complet, le numéro de votre compte et le montant du dépôt afin de nous permettre de traiter votre transaction.`,
      reminder: 'Comptez un délai de 1 à 2 jours ouvrables pour que les fonds soient crédités sur votre compte',
      successMsg:
        'Les fonds apparaîtront sur votre compte de trading lorsque nous aurons reçu le dépôt et vérifié votre reçu de transaction.',
      alt: `Vous pouvez également envoyer par e-mail votre reçu de transaction à l'adresse {mailTo} dans les meilleurs délais`,
      reminderFundAppear:
        'Vos fonds apparaîtront sur votre compte dès que nous aurons reçu les fonds de votre précédent courtier.',
      failed: 'Échec du dépôt. Veuillez réessayer plus tard.',
      fee: 'FRAIS',
      instant: 'EXPRESS',
      hour: 'HEURE',
      hours: 'HEURES',
      businessDays: 'JOURS OUVRABLES',
      withinOneBusinessDays: 'SOUS 1 JOUR OUVRABLE',
      secondaryNGN:
        '(Vous pouvez utiliser ce numéro de compte pour cette transaction si vous préférez utiliser la devise naira nigérian (NGN).)',
      disclaimer: `<li>Tous les paiements que vous effectuez chez {platform} sont confidentiels et ne sont utilisés que pour effectuer et retirer des fonds de votre compte de trading chez {platform}. Les paiements électroniques sont traités à l'aide de la technologie SSL (Secure Socket Layer) et cryptés pour garantir la sécurité.</li>
      <li>{platform} n’accepte strictement aucun paiement en provenance d’un tiers. Tous les fonds déposés sur votre compte de trading doivent l’être sous le même nom que celui de votre compte sur le portail client {platform}.</li>
      <li>Le client comprend que la soumission d'une demande de dépôt ne constitue aucunement l'acceptation des fonds par {platform}. Si votre compte est proche d'un appel de marge ou a déclenché l'appel de marge, veuillez vous assurer d'avoir prévu d'autres arrangements.</li>
      <li>{platform} ne sera pas responsable des éventuels délais ou erreurs dus aux systèmes de paiements.</li>
      <li>{platform} ne sera pas responsable des éventuels frais que l'établissement financier émetteur ou les fournisseurs de services de paiement tiers peuvent vous facturer pour l'envoi des paiements.</li>
      <li>Les clients reconnaissent qu'il peut leur être demandé de fournir des informations sur la/les source(s) du/des dépôt(s), le patrimoine des clients en général et toute autre information que {platform} juge nécessaire pour identifier la source des fonds lors du traitement d’une demande de dépôt, et acceptent de fournir toutes les informations requises par {platform}. Le fait de ne pas fournir les informations requises peut entraîner des retards significatifs dans le traitement de vos demandes. {platform} ne sera pas responsable des retards ou des pertes subies en raison de ces retards.</li>`,
      channels: {
        creditOrDebit: 'Carte de crédit/débit',
        i12BankTransfer: 'Virement bancaire international',
        unionPay: 'Dépôt Union Pay',
        unionPayCN: 'Virement Union Pay Chine',
        vietInstant: 'Virement bancaire express Vietnam',
        thaiInstant: 'Virement bancaire express Thaïlande',
        nigeriaInstant: 'Virement bancaire express Nigéria',
        malayInstant: 'Virement bancaire express Malaisie',
        indonesiaInstant: 'Virement bancaire express Indonésie',
        philippineInstant: 'Virement bancaire express Philippines',
      },
      rate: {
        rate: 'Taux de change ({oldCurrency}/{newCurrency}): ',
        result: {
          rateError: 'Échec de récupération du taux de change {oldCurrency}/{newCurrency}',
          channelError: 'Échec de récupération du canal de paiement.',
        },
      },
      cn: {
        rate: 'Taux de change (USD/RMB): ',
        amt: 'RMB: ',
      },
      upgrade:
        "Le canal de paiement est actuellement en cours de maintenance et n'est disponible que pour les utilisateurs qui ont déjà déposé des fonds via ce canal.",
      depositInstruction: 'Instructions pour le dépôt',
      depositNote: 'Remarque sur le dépôt',
    },
    method: {
      desc: 'Comment approvisionner votre compte par {method}',
      inst1: '{number}.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2: `{number}.   Sélectionnez la banque sur la page de sélection de banque et connectez-vous à votre banque en ligne de {country}`,
      inst3: `{number}.   Suivez les instructions et effectuez votre paiement via votre service bancaire en ligne/mobile`,
      inst4: '{number}.   Vous serez ensuite redirigé vers un autre formulaire pour saisir les détails de votre carte',
      inst5: '{number}.   Après avoir entré les détails de votre carte, appuyez sur «Soumettre»',
      inst6: '{number}.   Vous serez redirigé vers le portail client et votre transaction sera finalisées',
      inst7: `{number}.   Si votre devise n'est pas l'{currency}, la banque peut vous facturer des frais de conversion en fonction du taux de change bancaire en vigueur.`,
      inst8: `{number}.   Les dépôts par carte de crédit en EUR, GBP, AUD, SGD, NZD, HKD, JPY et USD sont limités à un maximum de 5 000 USD par transaction. Les dépôts par carte de crédit libellés en CAD sont acceptés jusqu'à 6500 $ CAD par transaction`,
      inst9: `{number}.   1500 $ USD maximum par dépôt`,
      inst10: `{number}.   Une page de détails du paiement s'affiche`,
      inst11: `{number}.   Sélectionnez la banque sur la page de sélection de banque et connectez-vous à votre banque en ligne`,
      inst12: `{number}.   Nous pouvons accepter les paiements en CAD. Maximum 9,999 $ CAN par dépôt`,
      form: {
        header: 'Formulaire de {method}',
      },
    },
    reminder: {
      inst1: 'Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2: 'Connectez-vous à votre compte {method}',
      inst3: 'Suivez les instructions affichées via votre compte {method} pour effectuer votre paiement',
      inst4: 'Vous serez ensuite redirigé vers un autre formulaire pour saisir les détails de votre carte',
      inst5: 'Après avoir entré les détails de votre carte, appuyez sur «Soumettre»',
      inst6: 'Vous serez redirigé vers le portail client et votre transaction sera finalisées',
      inst7: 'Vous serez alors dirigé vers une nouvelle fenêtre affichant une adresse de portefeuille {method}',
      inst8: `Copiez l'adresse du portefeuille {method}, puis connectez-vous à votre portefeuille {method} personnel et transférez le montant souhaité à l'adresse {method}.`,
      inst9: 'Numérisez le code QR pour ouvrir votre application de services bancaires mobiles',
      inst10: 'Suivez les instructions affichées sur votre appareil mobile pour effectuer votre dépôt',
      inst11:
        'Assurez-vous de payer en ligne après redirection vers la page de paiement afin d’éviter toute perte de fonds.',
      inst12: 'Sélectionnez la banque sur la page de sélection de banque et connectez-vous à votre banque en ligne',
      inst13: 'Suivez les instructions et effectuez votre paiement via votre service bancaire en ligne/mobile',
      inst14:
        'Une fois le virement effectué, veuillez compléter le formulaire ci-dessous et joindre une copie du reçu du virement bancaire montrant clairement votre nom complet, le numéro de votre compte et le montant du dépôt afin de nous permettre de traiter votre transaction.',
      inst15: `Comptez un délai de 2 à 5 jours ouvrables pour que les fonds soient crédités sur votre compte`,
      inst16: 'Prévoyez 1 jour ouvrable pour que les fonds soient crédités sur votre compte.',
      inst17:
        'Assurez-vous que votre nom complet, votre numéro de compte et le montant du dépôt sont clairement visibles afin que nous puissions traiter votre transaction.',
      inst18: 'Prévoyez de 1 à 6 heures pour que les fonds soient crédités sur votre compte.',
      note1:
        'Les dépôts par {method} sont limités à 25 000 USD par transaction et à un maximum de 75 000 USD par jour.',
    },
    southAfrica: { header: 'Virement bancaire express Afrique du Sud', country: 'Afrique du Sud' },
    idn: { header: 'Transfert instantané de Bank Indonesia', country: 'indonésienne' },
    ida: { header: 'Virement bancaire express Inde', country: 'Inde' },
    nigeria: { header: 'Virement bancaire express Nigéria', country: 'Nigéria' },
    thailand: { header: 'Virement bancaire express Thaïlande', country: 'Thaïlande' },
    malay: { header: 'Virement bancaire express Malaisie', country: 'Malaisie' },
    php: { header: 'Virement bancaire instantané aux Philippines', country: 'Philippines' },
    vietnam: { header: 'Virement bancaire express Vietnam', country: 'Vietnam' },
    korea: { header: 'Virement bancaire local en Corée du Sud' },
    uganda: { header: 'Virement bancaire local en Ouganda', country: 'Ouganda' },
    rwanda: { header: 'Virement bancaire local au Rwanda', country: 'Rwanda' },
    zambia: { header: 'Virement bancaire local en Zambie', country: 'Zambie' },
    congo: { header: 'Virement bancaire local au Congo', country: 'Congolais' },
    cameroon: { header: 'Virement bancaire local au Cameroun', country: 'Cameroun' },
    burundi: { header: 'Virement bancaire local au Burundi', country: 'Burundi' },
    kenya: { header: 'Virement bancaire local au Kenya', country: 'Kenya' },
    ghana: { header: 'Virement bancaire local au Ghana', country: 'Ghana' },
    tanzania: { header: 'Virement bancaire local en Tanzanie', country: 'Tanzanie' },
    creditOrDebit: {
      header: 'Credit Card',
      worldcard: {
        note1:
          'Les dépôts par carte de crédit en EUR, GPB et USD sont limités à un montant maximal de 20 000 USD par transaction. Les dépôts par carte de crédit en EUR, GPB et USD sont limités à un montant maximal de 20 000 USD ou l’équivalent par transaction.',
        note2:
          'Le nombre de cartes différentes par utilisateur est de 3. Toute tentative de dépôt au-delà de la limite ne sera pas acceptée.',
      },
      virtualPay: {
        note1:
          'Les dépôts par carte de crédit en EUR, GBP, AUD, SGD, NZD, HKD, JPY, USC et USD sont limités à un maximum de 10 000 USD par transaction. ',
        note2:
          '50 000 USD ou 10 transactions au maximum par carte dans un délai de 24 heures. Toute tentative de dépôt au-delà de la limite ne sera pas acceptée.',
      },
      Sdpay: {
        note1:
          'Les dépôts par carte de crédit en EUR, GBP, AUD, SGD, NZD, HKD, JPY, USC et USD sont limités à un maximum de 2 000 USD ou l’équivalent en devise par transaction. ',
        note2:
          '8 transactions au maximum par jour. Toute tentative de dépôt au-delà de la limite ne sera pas acceptée.',
        note3:
          'Veuillez noter qu’un temps d’attente peut être appliqué à vos premiers paiements. Vous devez patienter pendant que notre équipe vérifie votre paiement. Sinon, vous pouvez contacter notre équipe d’assistance clientèle pour une assistance supplémentaire.',
      },
      maxisPay: {
        note1: 'Les dépôts avec une carte de crédit en USD sont limités à un maximum de 5 000 USD par transaction.',
      },
    },
    net: {
      header: 'Neteller',
      note1: 'Nous acceptons les paiements en AUD, USD, EUR, CAD et GBP.',
      note2: 'Les comptes ne peuvent être approvisionnés qu’avec la même devise que votre compte de trading MT4.',
      note3:
        'Neteller convertira automatiquement les fonds dans la devise de base de votre compte si l’adresse e-mail utilisée est incorrecte. Des frais peuvent s’appliquer.',
      note4: 'Les dépôts doivent être effectués sous le même nom que votre compte PU Prime.',
    },
    vload: {
      header: 'DÉPÔT VLOAD',
      inst1: '2.   Nous acceptons les paiements en USD, EUR et JPY.',
      inst2: `<li>3.   Pour acheter un coupon Vload, cliquez <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">ici</a>.</li>`,
      form: {
        voucherpin: 'Pin du coupon',
        voucherpinReq: `Un PIN provenant d'un coupon est obligatoire`,
      },
    },
    bankWireAu: {
      header: 'AUSTRALIA,VIREMENT BANCAIRE : AUSTRALIE',
      instruction:
        'Les fonds peuvent être transférés à {platform} via le service bancaire en ligne de notre établissement financier ou en prenant contact avec votre établissement financier et en demandant le virement vers {platform}.',
      tip:
        'Sélectionnez la devise du compte dans laquelle vous souhaitez effectuer le dépôt. Veillez à sélectionner la même devise que celle configurée pour votre compte de trading',
      form: {
        bankDetails: 'Informations bancaires (Dépôt AUD uniquement)',
        refTip: 'Veuillez spécifier votre numéro de connexion ici',
        afp: 'Paiement rapide australien (Dépôt AUD national UNIQUEMENT)',
        payIdOrABNNum: 'Numéro PayID/ABN',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip:
          'Veuillez spécifier le numéro de votre compte de trading {platform} dans le champ de référence du virement bancaire.',
      },
    },
    bitwallet: {
      header: 'Bitwallet',
      note1: 'Nous acceptons les paiements en USD, AUD, EUR, USC et JPY.',
    },
    astropay: {
      desc: 'Comment approvisionner votre compte avec ASTROPAY',
      note1: 'Nous acceptons les paiements dans toutes les devises.',
      form: { header: 'FORMULAIRE ASTROPAY' },
    },
    bPay: {
      header: 'DÉPÔT BPay',
      option: 'Comment approvisionner votre compte avec BPay (Australie uniquement)',
      inst1:
        '1.   Sélectionnez votre compte MT4/MT5 pour afficher votre code de facturation et votre numéro de référence BPay',
      inst2:
        '2.   Entrez les détails du paiement, votre code de facturation et votre numéro de référence BPay dans votre service bancaire en ligne et soumettez votre paiement',
      inst3:
        '3.   Revenez sur cette page et remplissez le formulaire BPay ci-dessous pour nous permettre de traiter rapidement votre transaction',
      form: {
        header: 'FORMULAIRE BPAY',
        billerCode: 'Code de facturation BPay',
        refNum: 'Numéro de référence BPay',
        receipt: 'Reçu de virement BPay',
        reminderNotUploaded: `Nous avons constaté que vous n'avez pas téléchargé le reçu de votre transaction. Pour nous permettre de traiter votre dépôt, veuillez envoyer par e-mail une copie du reçu à {mailTo} dans les meilleurs délais.`,
        reminderProc: `Remarque. Votre transaction NE POURRA PAS être traitée tant que nous n'aurons pas réceptionné votre reçu.`,
      },
    },
    b2b: {
      header: 'DE COURTIER À COURTIER',
      option: `Comment virer des fonds d'un autre courtier vers {platform}`,
      inst1: '1.   Téléchargez et remplissez le {download}',
      inst2:
        '2.   Remplissez le formulaire en ligne ci-dessous et téléchargez votre formulaire de virement de courtier à courtier rempli',
      inst3:
        '3.   Envoyez une copie de votre formulaire de virement de courtier à courtier rempli à votre précédent courtier pour initier le virement',
      b2bForm: 'Formulaire de virement de courtier à courtier',
      formValidation: {
        upload: 'Le formulaire de virement de courtier à courtier est requis',
      },
    },
    cc: {
      header: 'DÉPÔT PAR CARTE DE CRÉDIT OU DE DÉBIT',
      remind: `Si vous rencontrez des difficultés pour financer votre compte à l'aide de la méthode principale, veuillez utiliser la méthode de crédit / débit secondaire`,
      field: {
        fundNow: 'Approvisionner maintenant par carte de crédit/débit',
        newCard: 'Nouvelle carte',
        cardNum: 'Numéro de carte de crédit',
        nameOnCard: 'Nom figurant sur la carte',
        expDate: `Date d'expiration`,
        cvv: 'Code de sécurité CVV',
        example: `Carte simple`,
      },
      cvvTip: 'Les 3 chiffres inscrits au dos de votre carte',
      threeDomainSecQ: 'Votre carte prend-elle en charge le dispositif 3D-Secure ?',
      threeDomainSec: `En quoi consiste l'authentification 3D-Secure ? <br /><strong>L'authentification 3D-Secure est une méthode de lutte contre la fraude reconnue par Visa et Mastercard.<br /><br />Si votre carte prend en charge le dispositif 3D-Secure, vos fonds seront traités immédiatement.</strong>`,
      cardUpload: `La carte que vous utilisez pour effectuer votre dépôt de fonds n'a pas été associée à votre compte de trading. Veuillez télécharger une photo du recto de votre carte aux fins de vérification. (Veillez à couvrir les 6 chiffres centraux)`,
      result: {
        success:
          'Merci. Nous vérifions actuellement les informations de votre carte. Une fois la vérification terminée, les fonds devraient apparaître sur votre compte dans un délai de 24 heures.',
        depositSuccess:
          'Nous vous remercions pour votre dépôt. Vos fonds apparaîtront très prochainement sur votre compte',
        error:
          'Afin de protéger la sécurité de votre compte,<br> vous avez atteint le maximum de tentatives de dépôt et suspendu temporairement vos dépôts.<br> Veuillez contacter {email}',
      },
      tipOnlyTaiwan: `Cher client, sachez que les retraits par carte de crédit peuvent être retardés en raison du traitement bancaire et que l'heure d'arrivée dépend entièrement de chaque banque. Si vous avez des besoins de fonds urgents, il est recommandé d'utiliser d'autres méthodes de dépôt / retrait.`,
      reminder: `<li>1. Les dépôts par carte de crédit en EUR, GBP et USD sont limités à un maximum de 1,900 USD ou devises équivalentes par transaction.</li>
      <li>2. Maximum 8 transactions par jour.</li>
      <li>3. Veuillez noter qu'une suspension peut s'appliquer à vos premiers paiements. Veuillez être patient pendant que notre équipe vérifie votre paiement, ou vous pouvez contacter notre équipe d'assistance à la clientèle pour obtenir de l'aide.</li>`,
    },
    fasa: {
      description: 'Comment approvisionner votre compte avec FasaPay',
      form: {
        header: 'FORMULAIRE FASAPAY',
      },
    },
    intSwift: {
      header: 'VIREMENT BANCAIRE : SWIFT INTERNATIONAL',
      desc:
        'Les fonds peuvent être transférés à {platform} via le service bancaire en ligne de notre établissement financier ou en prenant contact avec votre établissement financier et en demandant le virement vers {platform}',
      desc2:
        'Une fois le virement effectué, veuillez compléter le formulaire ci-dessous et joindre une copie du reçu du virement bancaire montrant clairement votre nom complet, le numéro de votre compte et le montant du dépôt afin de nous permettre de traiter votre transaction.',
      alertSAandNGA:
        "Si vous détenez une devise autre que le dollar américain, veuillez échanger le versement en dollars américains avant d'effectuer le versement. Si vous versez directement, la différence de taux de change et les frais de traitement encourus à ce moment-là seront à la charge du client.",
      options: 'Veuillez sélectionner le compte de trading que vous souhaitez approvisionner',
      refInfo:
        'Veuillez spécifier le numéro de votre compte de trading {platform} dans le champ de référence du virement bancaire.',
      bussDayInfo: `Comptez un délai de 2 à 5 jours ouvrables pour que les fonds soient crédités sur votre compte`,
      tip:
        'Veuillez vous assurer que votre reçu de virement indique clairement votre <b>nom complet</b>, <b>numéro de compte</b> et <b>montant du dépôt</b>.',
    },
    mobile: {
      header: 'MOBILE PAY',
      desc: 'Comment approvisionner votre compte avec Mobile Pay',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2: '2.   Numérisez le code QR pour ouvrir votre application de services bancaires mobiles',
      inst3: '3.   Suivez les instructions affichées sur votre appareil mobile pour effectuer votre dépôt',
      form: {
        payMethod: 'Méthode de paiement:',
        rate: 'Taux de change (USD/CNY):',
        amt: 'CNY:',
      },
      formValidation: {
        cnyAmt: 'Le montant en CNY ne peut pas être supérieur à {limit} $',
      },
    },
    crypto: {
      header: 'DÉPÔT DE CRYPTOCURRENCE',
      choose: 'Veuillez choisir votre crypto-monnaie préférée',
      proceedQuestion: 'Souhaitez-vous procéder à votre dépôt <br /><b>{method}</b>?  ',
      note:
        "Veuillez noter que nous ne sommes pas en mesure d'effectuer des dépôts ou des retraits via BUSDT. Assurez-vous que l'adresse et la crypto-monnaie correspondent à la chaîne et à la devise que nous acceptons, sinon vous pourriez perdre les fonds.",
      note2:
        'Veuillez noter que nous ne sommes pas en mesure d’accepter les dépôts et les retraits en BUSDT ou ETH. Assurez-vous que l’adresse et la crypto-monnaie correspondent à la chaîne et à la devise que nous acceptons. Sinon, vous risquez de perdre les fonds. Nous ne sommes pas responsables des pertes subies du fait de ces erreurs.',
      remark: `Veuillez noter que nous ne sommes pas en mesure d'effectuer des dépôts ou des retraits via BUSDT. Assurez-vous que l'adresse et la crypto-monnaie correspondent à la chaîne et à la devise que nous acceptons, sinon vous pourriez perdre les fonds.`,
      inst2: '2.   Vous serez alors dirigé vers une nouvelle fenêtre affichant une adresse de portefeuille {method}',
      inst3:
        "3.   Copiez l'adresse du portefeuille {method}, puis connectez-vous à votre portefeuille {method} personnel et transférez le montant souhaité à l'adresse {method}.",
      inst4: `Veuillez noter: les dépôts BITCOIN sont traités par B2BinPay au taux de change fourni au moment de la transaction.`,
      bitcoin: {
        desc: 'Comment approvisionner votre compte avec BITCOIN',
      },
      usdt: {
        desc: 'Comment approvisionner votre compte avec USDT(OMNI)',
        inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
        inst2:
          '2.   Vous serez alors dirigé vers une nouvelle fenêtre affichant une adresse de portefeuille USDT(OMNI)',
        inst3:
          "3.   Copiez l'adresse du portefeuille USDT(OMNI), puis connectez-vous à votre portefeuille USDT(OMNI) personnel et transférez le montant souhaité à l'adresse USDT(OMNI).",
        inst4: `Veuillez noter: les dépôts USDT(OMNI) sont traités par B2BinPay au taux de change fourni au moment de la transaction.`,
      },
      usdt_CPS: {
        desc: 'Comment approvisionner votre compte avec USDT({name})',
      },
      form: {
        header_bitcoin: 'FORMULAIRE BITCOIN',
        header_usdt: 'FORMULAIRE USDT(OMNI)',
        header_usdt_CPS: 'FORMULAIRE USDT({name})',
      },
    },
    skrill: {
      header: 'DÉPÔT SKRILL/MONEYBOOKERS',
      desc: 'Comment approvisionner votre compte avec Skrill',
      note1: 'Nous acceptons les paiements en USD, EUR, GBP et CAD.',
      note2: 'Les comptes ne peuvent être approvisionnés qu’avec la même devise que votre compte de trading MT4.',
      note3:
        'Skrill convertira automatiquement les fonds dans la devise de base de votre compte si l’adresse e-mail utilisée est incorrecte. Des frais peuvent s’appliquer.',
      note4: 'Les dépôts doivent être effectués sous le même nom que votre compte PU Prime.',
      form: {
        header: 'FORMULAIRE SKRILL',
        email: 'Skrill/Moneybookers Email',
      },
      reminder: `<li>Remarque :</li>
      <li>* Les comptes ne peuvent être approvisionnés que dans la même devise que celle associée à votre compte de trading MT4. Skrill convertira automatiquement les fonds dans la devise de base de votre compte si une adresse e-mail incorrecte est utilisée. Des frais peuvent s'appliquer.</li>
      <li>* Les dépôts doivent être effectués sous le même nom que celui associé à votre compte {platform}.</li>`,
    },
    sticPay: {
      header: 'DÉPÔT STICPAY',
      desc: 'Comment approvisionner votre compte avec SticPay',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2: '2.   Connectez-vous à votre compte SticPay',
      inst3: '3.   Suivez les instructions affichées via votre compte SticPay pour effectuer votre paiement',
      form: {
        header: 'FORMULAIRE SticPay',
        email: 'SticPay Email',
      },
    },
    unionPay: {
      header: 'DÉPÔT UNION PAY',
      desc: 'Comment approvisionner votre compte avec UnionPay',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2: '2.   Sélectionnez la banque sur la page de sélection de banque et connectez-vous à votre banque en ligne',
      inst3: '3.   Suivez les instructions et effectuez votre paiement via votre service bancaire en ligne/mobile',
      form: {
        header: 'FORMULAIRE UNION PAY',
      },
    },
    unionPayCN: {
      header: 'Virement Union Pay Chine',
      desc: 'Comment approvisionner votre compte par un virement Union Pay Chine',
      inst1: 'Une fenêtre contextuelle s’affichera avec les détails du paiement',
      inst2: `Il est possible qu’il vous soit demander un numéro de membre lors du dépôt. Saisissez votre numéro de compte de trading comme numéro de membre.`,
      note1:
        'Le montant minimal de transaction par dépôt est de 500 CNY et le montant maximal de transaction par dépôt est de 49 999 CNY.',
      note2:
        'Veuillez noter que la limite du montant du dépôt peut être actualisée de temps à autre en fonction du marché.',
      note3:
        'Ne recopiez pas les coordonnées de la banque du bénéficiaire qui sont indiquées, car elles peuvent être actualisées de temps à autre.',
      form: {
        header: 'FORMULAIRE UNION PAY CHINE',
        holderName: 'Nom du titulaire de la carte UnionPay:',
        holderNameInfo:
          'Assurez-vous que le nom que vous entrez ci-dessus correspond au nom du titulaire de la carte bancaire',
      },
    },
    paypal: {
      header: 'Paypal',
      desc: 'Comment approvisionner votre compte par PayPal',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2: '2.   Connectez-vous à votre compte PayPal',
      inst3: '3.   Suivez les instructions affichées via votre compte PayPal pour effectuer votre paiement',
      inst4: 'Remarque : Nous ne pouvons pas accepter les paiements de tiers',
      form: {
        header: 'FORMULAIRE PAYPAL',
      },
    },
    cardpay: {
      desc: 'Comment approvisionner votre compte par Credit Card',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur «Soumettre»',
      inst2: '2.   Vous serez ensuite redirigé vers un autre formulaire pour saisir les détails de votre carte',
      inst3: '3.   Après avoir entré les détails de votre carte, appuyez sur «Soumettre»',
      inst4: '4.   Vous serez redirigé vers le portail client et votre transaction sera finalisées',
      form: {
        header: 'FORMULAIRE CREDIT CARD',
      },
    },
    tinkbit: { inst5: '5.   1500 $ USD maximum par dépôt' },
    perfectMoney: {
      inst1: '2.   Connectez-vous à votre compte Perfect Money',
      inst2: '3.   Suivez les instructions affichées via votre compte Perfect Money pour effectuer votre paiement',
    },
    indiaBankTransfer: {
      desc1:
        'Une fois le virement effectué, complétez le formulaire ci-dessous et joignez une copie du reçu du virement bancaire.',
      desc2:
        'Assurez-vous que votre nom complet, votre numéro de compte et le montant du dépôt sont clairement visibles afin que nous puissions traiter votre transaction.',
      bussDayInfo: 'Prévoyez de 1 à 6 heures pour que les fonds soient crédités sur votre compte.',
    },
  },
  withdraw: {
    title: 'Retirer des fonds',
    default: {
      accountDetail: 'Détails du compte',
      withdrawalAmount: 'Montant du retrait',
      alert:
        'En raison des lois AML, la méthode que vous utilisez pour retirer doit être la même que la méthode utilisée pour votre dépôt. Si le montant du retrait est inférieur à 100 unités de la devise de base de votre compte, des frais de traitement vous seront facturés 20 unités par la banque.',
      how: 'Comment avez-vous approvisionné votre compte de trading ?',
      success: 'Votre demande de retrait a bien été effectuée et sera traitée dans les meilleurs délais',
      selectCC: 'Sélectionner une carte de crédit',
      selectBA: 'Select Bank Account',
      anotherCC: 'Saisissez manuellement la carte de crédit',
      anotherBA: 'Ajouter un compte en banque',
      remember: 'Se souvenir de mon compte',
      ccFail:
        'Afin de protéger la sécurité de votre compte, vous avez atteint le maximum de tentatives de dépôt et suspendu temporairement vos retraits. Veuillez contacter {email}',
      checkPosFailed: `Échec de vérification de la position`,
      fetchTradeAccFailed: 'Échec de récupération du compte de trading',
      chooseAnother: `<p>Montant maximal de retrait pour cette carte : 0</p> 
      <p>Veuillez choisir une autre carte ou un autre mode de paiement</p>`,
      notEnoughFunds: 'Le montant saisi dépasse la limite de cette carte',
      failed: 'Échec du retrait. Veuillez réessayer plus tard',
      equityStop: `Malheureusement, votre retrait ne peut pas être traité, car la marge disponible de votre compte est entrain de tomber en dessous du niveau d'arrêt, « stop out », de 100%.`,
      equityMargin:
        'Malheureusement, nous ne pouvons actuellement pas traiter votre retrait parce que votre compte est entièrement couvert.',
      equityContinue:
        'Nous avons détecté que vous avez actuellement des positions ouvertes. Vous pourriez faire face à un Stop out après avoir soumis votre demande de retrait. Veuillez confirmer si vous souhaitez continuer.',
      unfortunate:
        'Malheureusement, nous ne sommes plus en mesure de traiter les retraits par cartes bancaires. Par la suite, les retraits seront traités par virement bancaire sur le compte bancaire que vous avez désigné',
      disclaimer: `<li>Retirer des fonds alors que vous avez des positions ouvertes affectera votre marge. Veuillez vous assurer de clôturer les positions ouvertes ou de laisser suffisamment de fonds sur votre compte pour éviter un appel de marge.</li>
      <li>{platform} n’accepte strictement aucun paiement à destination d’un tiers. Tous les fonds retirés de votre compte de trading doivent l’être sous le même nom que celui de votre compte sur le portail client {platform}.</li>
      <li>Toutes les demandes initiales de retrait par carte de crédit/débit, porte-monnaie électronique, Moneybookers et virement bancaire doivent être vérifiées pour des raisons de sécurité par la fourniture d’un relevé bancaire indiquant les informations sur le titulaire du compte et les coordonnées bancaires.</li>
      <li>{platform} se réserve le droit de refuser de traiter une demande de retrait si elle a des motifs raisonnables de croire que le compte de trading peut se transformer en solde négatif après le traitement de la demande de retrait.</li>
      <li>{platform} se réserve le droit de refuser une demande de retrait si le compte de trading fait l'objet d'une rétrofacturation ou de toute autre enquête en cours.</li>
      <li>{platform} n'est pas responsable des frais que l'institution financière destinataire ou les fournisseurs de paiement tiers peuvent vous facturer pour la réception des paiements.</li>`,
      YourPaymentGreaterThanAvailableBalance: 'Votre paiement est supérieur au solde disponible',
      blackList: `Malheureusement, vous n'êtes actuellement pas en mesure de soumettre une demande de retrait. Contactez-nous à {supportEmail} pour plus d'informations`,
      NetellerChargeAlert:
        "Les retraits Neteller sont limités à 10 000 $ par transaction et des frais de 2 % (plafonnés à 30 $) s'appliquent.",
      agreePolicy: "J'ai lu et j'accepte la{link}",
      withdrawPolicy: 'Politique de retrait',
      addWithdrawalMethod: 'Ajouter une méthode de retrait',
      addSecondaryMethod: 'Ajouter une méthode secondaire',
      withdrawNote:
        'Veuillez noter que nous traiterons votre retrait préférentiellement par carte de crédit. Si le montant de votre carte de crédit est épuisé, nous vous demanderons de choisir une autre méthode afin de finaliser votre demande de retrait.',
      submitYourWithdrawal: 'Soumettre votre demande de retrait',
      remainingWithdraw:
        'Le montant du retrait restant sera réparti sur une autre carte de crédit ou une autre méthode de retrait.',
      onlyWithdrawalCcDesc: `Seul le montant de {ccAmount} sera prélevé sur la carte de crédit.`,
    },
    fasa: {
      field: {
        accName: 'Nom du compte FasaPay',
        accNum: 'Numéro du compte FasaPay',
      },
      formValidation: {
        accNameReq: 'Le nom associé au compte FasaPay est requis',
        accNumReq: 'Le numéro du compte FasaPay est requis',
      },
      fasaPayChargeAlert: 'FasaPay facture des frais de transaction de 0,5% pour chaque retrait.',
    },
    bitwallet: {
      field: {
        email: 'E-mail Bitwallet',
      },
      formValidation: {
        emailReq: 'Une adresse e-mail Bitwallet est requise',
      },
    },
    astropay: {
      field: {
        accountNumber: 'Compte Astropay',
      },
      formValidation: {
        accountNumberReq: 'Un compte Astropay est requis',
      },
    },
    neteller: {
      field: {
        email: 'E-mail Neteller',
      },
      formValidation: {
        emailReq: 'Une adresse e-mail Neteller est requise',
      },
    },
    vload: {
      field: {
        email: 'E-mail Vload enregistré',
      },
      formValidation: {
        emailReq: 'Un e-mail inscrit sur Vload est obligatoire',
      },
    },
    bitcoin: {
      field: {
        address: 'Adresse du portefeuille Bitcoin',
      },
      formValidation: {
        addressReq: "L'adresse du portefeuille Bitcoin est requise",
        addressFormat: `L’adresse de votre portefeuille doit commencer par ’1’, ’3’ ou ’bc1’.`,
        addressLength: "L'adresse du portefeuille en Bitcoin doit comporter entre 26 et 42 caractères",
      },
    },
    eth: {
      field: {
        address: 'Adresse du portefeuille ETH',
      },
      formValidation: {
        addressReq: "L'adresse du portefeuille ETH est requise",
        addressFormat: "L'adresse du portefeuille ETH est requise",
        addressLength: "L'adresse du portefeuille en ETH doit comporter entre 26 et 42 caractères",
      },
    },
    usdc: {
      field: {
        address: 'Adresse du portefeuille USDC',
      },
      formValidation: {
        addressReq: "L'adresse du portefeuille USDC est requise",
        addressFormat: "L'adresse du portefeuille USDC est requise",
        addressLength: "L'adresse du portefeuille en USDC doit comporter entre 26 et 42 caractères",
      },
    },
    usdt: {
      field: {
        address: 'Adresse du portefeuille USDT',
      },
      formValidation: {
        addressReq: "L'adresse du portefeuille USDT est requise",
        trc20WalletFormatIncorrect: `L’adresse de votre portefeuille doit commencer par ’T’.`,
        erc20WalletFormatIncorrect: `L’adresse de votre portefeuille doit commencer par ’0x’.`,
      },
    },
    paypal: {
      field: {
        email: 'E-mail Paypal',
      },
      formValidation: {
        emailReq: 'Une adresse e-mail Paypal est requise',
      },
    },
    skrill: {
      field: {
        email: 'E-mail Skrill',
      },
      formValidation: {
        emailReq: 'Une adresse e-mail Skrill est requise',
      },
      skrillChargeAlert: 'Skrill facture des frais de transaction de 1% pour chaque retrait.',
    },
    sticpay: {
      field: {
        email: 'E-mail SticPay',
      },
      formValidation: {
        emailReq: 'Une adresse e-mail SticPay est requise',
      },
    },
    perfectMoney: {
      field: {
        email: 'E-mail Perfect Money',
      },
      formValidation: {
        emailReq: 'Une adresse e-mail Perfect Money est requise',
      },
    },
    transfer: {
      info:
        '{platform} will reimburse the bank fee charged for <u>ONE</u> withdrawal per month. Additional withdrawals within the month will incur a bank fee of 20 units of your withdrawal currency.',
      label: {
        upload: `Veuillez télécharger une capture d'écran, une photo ou une copie numérisée de votre relevé bancaire (couvrant les 3 derniers mois)`,
      },
      formValidation: {
        upload: 'Veuillez télécharger un relevé bancaire',
        emailReq: `L'email est requis`,
      },
    },
    otherMethod: {
      withdrawalMethod: 'Méthode de retrait',
      manageWithdrawalDetails: 'Gérer les détails du retrait',
      verificationCode:
        'Le code de vérification a été envoyé à {email}. Contactez-nous l’adresse à {supportEmail} si vous avez besoin d’une assistance supplémentaire',
    },
  },
  transfer: {
    creditWarn:
      'Nous ne sommes pas en mesure de traiter votre transfert de fonds à partir du compte sélectionné car votre compte fait apparaître un crédit promotionnel.',
    blackListWarn: `Le transfert de fonds à partir de votre compte MAM peut entraîner la liquidation des positions gérées. Veuillez contacter {mailTo} pour transférer des fonds à partir de ce compte`,
  },
  changePw: {
    header: 'CHANGER DE MOT DE PASSE',
    field: {
      currPw: 'Mot de passe actuel',
      newPw: 'Nouveau mot de passe',
      confirmPw: 'Confirmer le nouveau mot de passe',
    },
    succ:
      'Votre mot de passe a été mis à jour. Vous pouvez maintenant utiliser votre nouveau mot de passe pour accéder au portail client.',
  },
  promotion: {
    clickToGet: 'Cliquez simplement et obtenez votre bonus de 50 $',
    apply: 'Appliquer maintenant',
    start: 'Commencez maintenant',
    tnc: `
    <li> 1. Cette offre est valable 30 jours.</li>
    <li> 2. Cette offre est disponible pour tous les clients (existants et nouveaux) qui ont un compte réel mais qui n'ont jamais effectué de dépôt auparavant.</li>
    <li> 3. Cette offre est disponible pour les nouveaux clients dans les domaines suivants: France, Royaume-Uni, Taïwan, Italie, Canada, Belgique, Suisse, Hong Kong, Nigéria, Pays-Bas, Japon, Luxembourg, Malte, Réunion, Allemagne, Martinique, Espagne, Lettonie, Pologne, Côte d'Ivoire, Danemark, Brésil, Nouvelle-Zélande , Portugal, Ile de Man, Afrique du Sud, Turquie, Roumanie, Guadeloupe, Autriche, Irlande, Guyane française, Swaziland, Argentine, Ukraine, Grèce, Suède, Monaco, Hongrie, Haïti, Finlande, Bolivie, Chili, Costa Rica, Croatie, République tchèque, Estonie, Mexique, Norvège, Philippines.</li>
    <li> 4. Cette offre est éligible sur les comptes standard et les comptes standard islamiques uniquement.</li>
    <li> 5. Obtenir des bonus et / ou échanger des fonds de bonus SUR DEUX COMPTES OU PLUS avec la même adresse IP / ID / passeport est interdit. Quel que soit le nombre de comptes de trading qu'un client peut détenir avec PU Prime, la promotion est applicable pour un seul compte.</li>
    <li> 6. Les clients recevront des crédits de 50 USD (ou équivalent) après avoir accepté cette offre avec succès et il n'est pas nécessaire de faire un dépôt.</li>
    <li> 7. Les montants du crédit ne peuvent pas être retirés. Cependant, vous pouvez toujours retirer une partie des bénéfices de votre trading.</li>
    <li> 8. Vous devez fermer toutes les positions ouvertes avant de faire une demande de retrait. Une fois que vous avez choisi de retirer, le bonus sera déduit du compte.</li>
    <li> 9. PU Prime se réserve le droit de modifier les conditions ou de refuser d'accorder un bonus à tout moment.</li>`,
    selectDesp: `* Veuillez sélectionner le compte dans lequel vous souhaitez rejoindre l'activité Bonus sans dépôt.`,
    descp1: 'Voulez-vous commencer à trader sans engager un investissement dès le début?',
    descp2: `Appliquez votre bonus de 50 $ pour commencer<br> à faire des bénéfices sans risquer aucun de vos fonds.`,
    header: 'CONDITIONS GÉNÉRALES DE LA PROMOTION',
    tnc2:
      '<li> Le montant minimum que vous pouvez retirer est de 100 USD(ou équivalent), et le montant maximum à retirer est de 200 USD(ou équivalent). Tous les bénéfices réalisés en plus de ce montant plafonné seront supprimés lorsque nous traiterons votre demande de retrait.</li>',
    successful: 'Soumis avec succès',
    failed: 'La soumission a échoué',
    referFriends: {
      intro: `Suivez les 3 étapes suivantes pour obtenir {amount}`,
      step1: 'premier pas',
      step1Descp: "Ouverture d'un compte avec succès",
      step2: 'Deuxième étape',
      step2Descp: 'Dépôt réussi',
      step3: 'troisième étape',
      step3Descp: 'Volume de transactions',
      totalEanringTitle: `Montant total du retrait <br/>(USD ou devise équivalente)`,
      totalEarningTip: '* Le montant de la récompense est sujet à révision.',
      redeem: 'extrait',
      intro2: 'En même temps, vous pouvez également recommander vos amis',
      shareLink: 'Partagez votre lien de recommandation personnalisé',
      shareLinkDescp:
        'Cliquez sur "Partager" pour obtenir un lien de recommandation unique et copiez-le sur divers médias sociaux',
      share: 'Partager',
      total: 'Total des filleuls échangés',
      social: 'Partagez votre lien via les principaux médias sociaux',
      clients: 'client',
      table: {
        referralsNo: 'Numéro recommandé',
        name: 'Nom',
        country: 'pays',
        openAccount: "Ouverture d'un compte avec succès",
        despositSuccess: 'Dépôt réussi',
        volumeCompleted: 'Volume de transactions',
      },
      totalRedeemableTitle: `Montant total du retrait <br/>(USD ou autre devise équivalente)`,
      totalRedeemableTip: '* Le montant de la récompense est sujet à révision.',
      referrer: 'Référent',
      referral: 'Arbitre',
      dialog: {
        confirmQ: 'Échangez vos revenus',
        confirmQ2: 'Le montant total converti dans votre devise de base est de {amount} {currency}.',
        redeem: 'Retirer maintenant',
        agree: '* En cliquant sur Utiliser maintenant, vous acceptez tous les {link}.',
        overBonus:
          'Notre système a détecté que vous avez atteint notre montant maximum. Pour les réclamations, veuillez contacter notre service client.',
      },
    },
    depositBonus: {
      banner: "RÉCLAMEZ VOTRE BONUS DE DÉPÔT DE 50 % !<br>   OBTENEZ JUSQU'À 10 000 $",
      depositBonus: 'RÉCLAMEZ VOTRE BONUS ET BOOSTEZ VOTRE TRADING',
      title:
        "Vous voulez augmenter la marge de votre compte et augmenter vos volumes de trading ? Alors approvisionnez votre compte aujourd'hui et réclamez le bonus de 50 % de notre part !",
      fund: 'Financez votre compte',
      tnc: "*Les termes et conditions s'appliquent, {link}",
      link: 'cliquez ici pour voir',
      bonusHistory: 'HISTORIQUE DES BONUS',
      overBonus:
        "* Vous avez atteint le maximum de ${totalLimit} en crédits bonus. Vous ne bénéficierez d'aucun crédit bonus supplémentaire avec les prochains dépôts.",
      opt: "CONFIRMATION D'OPT-IN & OPT-OUT",
      tick:
        'Veuillez décocher la case si vous souhaitez vous désinscrire ou cocher la case si vous souhaitez vous inscrire.',
      saveChange: 'Sauvegarder les modifications',
      depositDate: 'Date de dépôt',
      credit: 'Crédit',
      optIn: 'Inscription réussie',
      optOut: 'Désinscription réussie',
      optInDescription: 'Veuillez cliquer sur le bouton ci-dessous pour opter pour le bonus de dépôt de 50 %.',
      optOutDescription: 'Cliquez sur la case pour sortir de la récompense.',
      activate: 'Activer',
      signOut: 'Déconnexion',
      banner1: 'RÉCLAMEZ VOTRE BONUS DE DÉPÔT DE 50 % !',
      claimDescription: `<p>Comment réclamer</p>
      <li>1. Activez avant de déposer. </li>
      <li>2. Financez votre compte de trading pour vous qualifier pour la récompense. </li>
      <li>3. La récompense financera automatiquement à votre compte. </li>
      <li>4. Profitez de votre trading. </li>`,
      eligibleDescription: `<p>Admissibilité</p>
      <li>1. La récompense doit être activée avant de déposer. </li>
      <li>2. La récompense n'est disponible que pour le compte standard, le compte standard islamique, le compte Prime, le compte Premier islamique. </li>
      <li>3. Vous ne pouvez demander que le bonus de dépôt de ratio de 50% une fois, et tous les dépôts ultérieurs ne sont calculés que conformément au ratio de 20%. </li>`,
      optInDialog: `Toutes nos félicitations! Vous avez activé avec succès le bonus de dépôt de 50%.`,
      optOutDialog: `Vous avez opté avec succès pour le bonus de dépôt de 50 %.`,
    },
    cryptoCashback: {
      banner: {
        bannerContext: `
          <span class="title">Doublez vos économies</span></br>
          <span class="desc">Obtenez jusqu'à 10 000 $ de cashback et zéro frais de swap.</span>
        `,
        activityBannerContext: `
          <span class="title">Doublez vos économies</span></br>
          <span class="desc">Vendre sur la tendance baissière ou acheter le creux de la vague ?</span></br>
          <span class="desc">Recevez jusqu'à 10 000 $ de cashback lorsque vous échangez des crypto-monnaies avec PU Prime!</span>
        `,
      },
      active: {
        title: 'Activer le cashback sur les crypto-monnaies',
        desc: 'veuillez cliquer sur le bouton ci-dessous pour activer et participer à cette promotion',
      },
      deactive: {
        title: `Désactiver la promotion Cashback Cryptocurrency`,
        desc: `Veuillez cliquer sur le bouton ci-dessous pour désactiver et vous retirez de la promotion Cashback Cryptocurrency.`,
      },
      button: {
        active: 'Activer',
        deactive: 'Désactiver',
        submit: 'Soumettre',
        fund: 'Financez votre compte',
      },
      claimTitle: 'COMMENT RÉCLAMER',
      claimDescriptions: [
        `Activez la promotion avant d'effectuer un dépôt.`,
        `Alimentez votre compte de trading pour répondre aux exigences sur le dépôt（≥ 500 USD).`,
        'Le cashback par lot dépendra du montant total net de votre dépôt (voir les CGU).',
        'Profitez du trading et obtenez plus de valeur de vos trades!',
      ],
      eligibilityTitle: 'ELIGIBILITE',
      eligibleDescriptions: [
        `Cette promotion doit être activée avant que vous n'effectuiez un dépôt.`,
        `Cette promotion est uniquement disponible pour les comptes standard et les comptes standard islamiques. Si un client possède plusieurs comptes de trading éligibles, seul UN compte éligible peut être sélectionné pour participer à cette promotion.`,
        `Cette promotion est effective 30 jours à compter de votre premier dépôt fait après l'activation.`,
        `le cashback est basé sur le volume tradé sur tous les produits de crypto-monnaies à l'exception des suivants : ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU.`,
        `le cashback total est plafonné à 10 000 $ par client.`,
      ],
      tnc: "*Les termes et conditions s'appliquent, {link}",
      link: 'cliquez ici pour voir',
      message: {
        congratulate: `Félicitations!`,
        congratulateContext: `Vous avez activé avec succès la promotion Cashback Cryptocurrency.`,
        signedOutPromo: `En cliquant sur le bouton Confirmer, vous serez déconnecté de la promotion.`,
        deactivePromo: `Vous avez réussi à vous retirer de la promotion Cashback en crypto-monnaies.`,
      },
      dialog: {
        selectAccountContext: `Veuillez sélectionner le compte sur lequel vous souhaitez participer à l'activité de bonus de trading`,
        tnc: `Conditions générales d'utilisation`,
        tncContext: {
          tnc1: `1. Après avoir activé cette promotion, vous devez également déposer des fonds et des cryptocurrency de trading comme requis pour recevoir le cashback correspondant.`,
          tnc1desc: `* Seuls les nouveaux dépôts effectués pendant la période de promotion sont valables pour la promotion. Cela exclut les transferts internes, les bonus de parrainage d'amis et les ajustements en espèces.`,
          tnc2: `2. Les clients éligibles (nouveaux et existants), qui répondent aux critères énoncés dans le présent document, peuvent recevoir un cashback égal à:`,
          tnc3: `3. Les produits éligibles : Tous les produits de crypto-monnaies sauf ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU.`,
          tnc4: `4. Le volume effectif des transactions : Les ordres nouvellement ouverts et fermés pendant la période de promotion. Les positions ouvertes depuis moins de 5 minutes, les positions verrouillées et les positions couvertes ne comptent pas comme des transactions valides pour cette promotion.`,
          tnc5: `5. Durée de la promotion : 30 jours après que les participants aient effectué le premier dépôt réussi.`,
          tnc5desc: `* Si le client active la promotion et n'a pas effectué de dépôt, la promotion prendra également fin automatiquement après 30 jours.`,
          tnc6: `6. Cette offre ne peut pas être utilisée en conjonction avec une autre offre. （Excepté la campagne "You Can Be a trader too",Parrainer un ami et 50% de bonus sur dépôt)`,
        },
        agreeTnc: `J'ai lu et j'accepte les termes et conditions.`,
      },
      field: {
        netDeposit: `sur le dépôt net`,
        usddesc: `USD (ou devise équivalente)`,
        cashback: `Cashback`,
        standardLot: `lot standard`,
        date: `Date`,
        depositAmount: `Montant du dépôt`,
        withdrawAmount: `Montant du retrait`,
        netDepositAmount: `Montant net du dépôt`,
        rebateLot: `Remise / Lot`,
        tradingDate: `Date de transaction`,
        tradingLot: `Lot de trade`,
        cashRebate: `Remise en espèces`,
      },
      depositHistory: `HISTORIQUE DES DÉPÔTS`,
      tradingRewardHistory: `HISTORIQUE DES RÉCOMPENSES DE TRADING`,
    },
    xmasMakeAWish: {
      banner: {
        bannerContext: `
          <div class="title">Gagnez des prix d’une valeur jusqu’à 5.000 $ !</div>
          <div class="desc">à notre tirage au sort avec l’assurance d’un gain</div>
        `,
      },
      active: {
        title: 'ACTIVEZ LA PROMO « FAITES UN VŒU »',
        desc: 'Cliquez sur le bouton ci-dessous pour activer la promotion Faites un vœu à Noël',
      },
      deactive: {
        title: 'de la promotion Faites un vœu à NOËL',
        desc: 'Cliquez sur le bouton ci-dessous pour désactiver la promotion Faites un vœu à Noël',
      },
      button: {
        active: 'Activer',
        deactive: 'Désactiver',
        submit: 'Soumettre',
        fund: 'Approvisionner votre compte',
        enterLuckyDraw: 'Participer au tirage au sort',
        redeem: 'Échanger',
        redeemed: 'Échangé',
      },
      claimTitle: 'COMMENT RÉCLAMER',
      claimDescriptions: [
        'Activez la promotion avant d’effectuer un dépôt.​​',
        'Approvisionnez votre compte de trading pour satisfaire aux exigences de dépôt pour le cadeau souhaité, puis effectuez le nombre correspondant de trades requis (voir le tableau des cadeaux ci-dessous).',
        'Si vous êtes éligible, vous aurez une chance de gagner l’un des cadeaux correspondant à votre niveau (voir le tableau des cadeaux ci-dessous).',
        'Cliquez sur le bouton Participer au tirage de la chance pour obtenir votre cadeau.',
        'Les cadeaux gagnés seront enregistrés et tous les cadeaux seront distribués 7 jours après la fin de la promotion.',
      ],
      eligibilityTitle: 'ÉLIGIBILITÉ',
      eligibleDescriptions: [
        'Types de compte éligibles : Cette offre est UNIQUEMENT ouverte aux comptes standards et aux comptes standards islamiques, tous deux dans MT4 / MT5.',
        'Si un client possède plusieurs comptes standards, il ne peut sélectionner qu’un seul compte standard avec lequel participer à cette promotion.',
        'Pays éligibles : Tous, sauf la Chine continentale',
        'Les dépôts et les trades doivent être effectués pendant la période de promotion pour être considérés comme valides.',
        'Volume de trading valide : Positions sur le Forex, l’or, l’argent et le pétrole maintenues ouvertes pendant plus de 5 minutes.',
      ],
      moreDetail: 'Pour plus de détails sur la distribution des prix, consultez la {prize}.',
      prize: 'liste des prix ici',
      tnc: '* Les Conditions générales s’appliquent. Cliquez {link} pour consulter.',
      link: 'ici',
      luckyDraw: {
        description: [
          `Appuyez sur le bouton Tirage au sort pour gagner <span class="font-bold">l’un</span> des prix ci-dessus !`,
          'Ce n’est pas le cadeau que vous fait envie ? Continuez à effectuer des dépôts et à trader pour atteindre le niveau souhaité.',
        ],
        mainTitle: 'Votre niveau actuel pour le cadeau:',
        commingSoon: {
          title: `Faire un vœu`,
          content: `Bientôt à la mi-novembre pour participer au tirage au sort.`,
        },
        noChanceToDraw: {
          title: `Déposer`,
          content: `Approvisionnez votre compte avec un dépôt min. de 200 $ pour participer au tirage au sort`,
        },
        endPromotion: {
          title: `Le tirage au sort est terminé`,
          content: `Merci pour votre participation ! Assurez-vous d’avoir échangé vos cadeaux avant le 31 mars 2023.`,
        },
      },
      currentDepositLots: `CURRENT DEPOSIT / LOTS TRADED`,
      transactionHistory: `HISTORIQUE DES TRANSACTIONS`,
      transactionHistoryDesc: `*La transaction de dépôt est le montant déposé et la valeur du dépôt déduite de la participation au tirage au sort.`,
      giftRedemption: `ÉCHANGER LE CADEAU`,
      giftRedemptionDesc: [
        `Nous vous recontacterons pour l’échange du ou des cadeaux physiques.`,
        `* Tous les prix ne peuvent être échangés que du 2 février 2023 au 2 mars 2023.`,
      ],
      field: {
        depositAmount: `Montant du dépôt`,
        lotsTraded: `Lots tradés`,
        date: `Date`,
        type: `Type`,
        deposit: `*Dépôt`,
        tradingLots: `Lots de trading`,
        prizeWon: `Prix gagné(s)`,
        creditBonus: `Bonus de crédit`,
        cashVoucher: `Bon en espèces`,
        physicalRewards: `Prix physiques`,
        depositTradingVolume: `Dépôt / Lots de trading`,
        gifts: `Cadeaux (USD)`,
        accountID: `Identifiant du compte`,
        current: `Actuel`,
        luckyDrawDeposit: `Dépôt`,
      },
      dialog: {
        selectAccountContext: `Sélectionnez le compte sur lequel vous souhaitez recevoir votre bonus de crédit (compte MT4 / MT5 uniquement)`,
        tnc: 'CONDITIONS GÉNÉRALES',
        tncContext: {
          tnc1: `1. Les clients doivent d’abord activer avec succès cette promotion dans leur portail client, puis déposer des fonds et effectuer des trades comme requis pour recevoir les cadeaux correspondants.`,
          tnc2: `2.	Volume de trading effectif : Lots standards de paires de devises, d’or, d’argent, de pétrole au comptant et de contrats à terme sur le pétrole. Les ordres doivent être ouverts et clôturés pendant la période de la promotion. Les positions ouvertes depuis moins de 5 minutes, les positions verrouillées et les positions couvertes ne sont pas comptabilisées comme des trades valides pour cette promotion.`,
          tnc3: `3.	Les statistiques et le volume des ordres sont extraits de MT4 du 01/11/2022 au 31/01/2023.`,
          tnc4: `4.	Si le cadeau ne peut pas être acquis en raison d’un cas de force majeure tel qu’un produit épuisé ou en rupture de stock, le client peut demander à recevoir l’équivalent du prix en espèces sur le marché du cadeau. Ce prix est basé sur le prix en dollars américains (ou une devise équivalente).`,
          tnc5: `5.	Cette offre ne peut être combinée à aucune autre offre. (100 bonus de trading, 20 % de bonus exclusif, 50 % de bonus de dépôt, VN 20 % de bonus de dépôt).`,
        },
        agreeTnc: `J’ai lu et j’accepte les Conditions générales`,
        prizeList: `liste des prix ici`,
        luckyDrawTransaction: `TRANSACTION AU TIRAGE AU SORT`,
        xmasGift: `FÉLICITATIONS POUR VOTRE CADEAU DE NOËL !`,
      },
      message: {
        congratulate: 'Félicitations !',
        congratulateContext: `Vous avez activé avec succès la promotion Faites un vœu à Noël`,
        signedOutPromo: `En cliquant sur le bouton Confirmer, vous serez désinscrit de la promotion !`,
        deactivePromo: `Vous vous êtes désinscrit avec succès de la promotion Faites un vœu à Noël.`,
        redeemAmount: `Le montant va être crédité sur votre compte !`,
      },
      tableContent: {
        luckyDraw: `Tirage au sort`,
        depositTrade: `Dépôt / Trade`,
      },
    },
    firstTimeDeposit: {
      banner: {
        bannerContext: `
          <div class="title">Profitez de <span class='bg_yellow'>cash instantané</span> avec votre premier dépôt</div>
          <div class="desc">Profitez de jusqu’à 50 USD en espèces lorsque vous effectuez votre premier dépôt chez PU Prime !</div>
        `,
      },
      active: {
        title: `Activez VOTRE cashback FTD`,
        desc: `Cliquez sur le bouton ci-dessous pour activer l’activité et y participer.`,
      },
      deactive: {
        title: `Désactiver VOTRE cashback FTD`,
        desc: `Cliquez sur le bouton ci-dessous pour désactiver et vous retirer de la promotion Cashback FTD.`,
      },
      button: {
        active: `Activer`,
        deactive: `Désactiver`,
        fund: `Approvisionner votre compte`,
        redeem: `Échanger`,
      },
      claimTitle: `COMMENT RÉCLAMER`,
      claimDescriptions: [
        `Activez la promotion avant d’effectuer un dépôt.​`,
        `Approvisionnez votre compte de trading pour satisfaire aux exigences de dépôt relatives au cashback que vous souhaitez (≥ 200 USD ou l’équivalent en devise).`,
        `La valeur du coupon de cashback dépendra du montant de votre premier dépôt (voir le tableau de cashback dans les Conditions générales).`,
        `Pour activer votre coupon de cashback, tradez ≥2 lots de FX, d’or, d’argent ou de pétrole.`,
        `Votre coupon activé peut désormais être utilisé. Ce montant se reflétera dans le solde de votre compte.`,
      ],
      eligibilityTitle: `ÉLIGIBILITÉ`,
      eligibleDescription1: `Types de compte éligibles : Cette offre est UNIQUEMENT ouverte aux comptes standard et aux comptes standard islamiques, sur MT4/MT5.`,
      eligibleDescription2: `Si un client possède plusieurs comptes standard, il ne peut sélectionner qu’un seul compte avec lequel participer à cette promotion.`,
      eligibleDescription3: `Pays éligibles : Tous, sauf la Chine continentale`,
      eligibleDescription4: `Clients éligibles : Les clients nouveaux et existants qui n’ont aucun historique de dépôt, y compris les clients IB / CPA.`,
      eligibleDescription4Desc: `* Les comptes de trading personnels IB/CPA ne sont pas éligibles.`,
      eligibleDescription5: `Le premier dépôt doit être effectué pendant la période de promotion.`,
      eligibleDescription6: `Les ordres détenus moins de 5 minutes ne sont pas considérés comme valables pour la promotion.`,
      tnc: `* Les Conditions générales s'appliquent. {link} pour les consulter.`,
      link: ` Cliquez ici`,
      dialog: {
        selectAccountContext: `Veuillez sélectionner le compte sur lequel vous souhaitez participer à l'activité de bonus de trading`,
        tnc: `Conditions générales`,
        tncContext: {
          tnc1: `1. Après avoir activé cette promotion, vous devez également déposer des fonds et trader 2 lots standard tel que requis pour recevoir le cashback correspondant.`,
          tnc1desc: `*Seuls les nouveaux dépôts effectués pendant la période de la promotion sont valables pour la promotion. Cela exclut les transferts internes, les bonus de parrainage et les ajustements de trésorerie.`,
          tnc2: `2.	Seul le premier dépôt effectué pendant cette période sera pris en compte dans le calcul du cashback.`,
          tnc3: `3.	Les clients éligibles (nouveaux et existants) qui satisfont aux critères énoncés ici peuvent bénéficier d’un cashback égal à :`,
          tnc4: `4.	Les produits éligibles : Forex, or, argent, pétrole.`,
          tnc5: `5.	Volume de trading effectif : Les nouveaux ordres ouverts et clôturés pendant la période de la promotion. Les positions ouvertes pendant moins de 5 minutes, les positions verrouillées et les positions couvertes ne sont pas comptabilisées comme des trades valides pour cette promotion.`,
        },
        agreeTnc: `J’ai lu et j’accepte les Conditions générales.`,
        grossDeposit: `Dépôt brut en USD`,
        grossDepositDesc: `ou l’équivalent en devise`,
        cbVoucher: `Coupon de cashback`,
        tableDesc: `* Les clients peuvent échanger un coupon en espèces d’une valeur maximale de 50 $ (ou son équivalent).`,
      },
      voucher: {
        title: `Cashback FTD`,
        accountID: `Identifiant du compte`,
        accumulateVolume: `Accumulez un volume de trading`,
        standardLots: `Lots standards`,
        desc: `Tradez au moins 2 lots FX, d’or, d’argent ou de pétrole pour l’activer.`,
        clickForRedeem: `Cliquez sur le bouton ci-dessous pour l’ajouter au solde de votre compte.`,
        ftdLessThan200: `Votre dépôt initial est inférieur au montant minimal requis.`,
      },
      message: {
        congratulate: `Félicitations !`,
        congratulateContext: ``,
        signedOutPromo: `En cliquant sur le bouton Confirmer, vous serez désinscrit de la promotion !`,
        deactivePromo: `Vous êtes désinscrit de la promotion Cashback FTD.`,
        successRedeem: `Vous avez échangé avec succès votre cashback FTD.`,
      },
    },
    notification: {
      ndb: {
        inst1: 'Cher client estimé,',
        inst2: `Nous vous rappelons que la promotion Bonus sans dépôt se terminera le <span>{date} (GMT+3)</span> .`,
        inst3:
          'Selon les termes et conditions de la promotion, veuillez noter que le bonus sur votre compte déposé sera automatiquement supprimé une fois la promotion terminée. Pour les comptes sans dépôt ou sans enregistrement de transaction, le bonus et le solde seront automatiquement supprimés une fois la promotion terminée.',
        inst4: `Si vous avez des questions ou avez besoin d'aide, veuillez contacter notre équipe d'assistance via le chat en direct, par e-mail<span>{mail}</span>, ou par téléphone au <span>{phone}</span>.`,
        cantInerTransfer:
          'Puisque vous participez à notre campagne NDB, nous ne sommes pas en mesure de traiter votre demande de transfert',
      },
      db: {
        inst1: 'Cher client estimé,',
        inst2: `Nous vous rappelons que la promotion du bonus de dépôt de 50% prendra fin le <span>{date} (GMT+3)</span> .`,
        inst3:
          'Selon les termes et conditions de la promotion, veuillez noter que le bonus sur votre compte déposé sera automatiquement supprimé une fois la promotion terminée. Pour les comptes sans dépôt ou sans enregistrement de transaction, le bonus et le solde seront automatiquement supprimés une fois la promotion terminée.',
        inst4: `Si vous avez des questions ou avez besoin d'aide, veuillez contacter notre équipe d'assistance via le chat en direct, par e-mail : <span>{mail}</span>, ou par téléphone au <span>{phone}</span>.`,
      },
    },
    editSuccess: '',
  },
  downloads: {
    mt4: 'METATRADER 4',
    mt4Desc: 'Téléchargez MetaTrader 4 pour PC, Mac, navigateurs Internet, smartphones et tablettes',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android/Tablette',
    web: 'WebTrader',
    mt5: 'METATRADER 5',
    mt5Desc: 'Téléchargez MetaTrader 5 pour PC, Mac, navigateurs Internet, smartphones et tablettes',
  },
  forgetPwReq: {
    tip: `Saisissez l'adresse e-mail de votre compte pour réinitialiser votre mot de passe.`,
    formValidation: {
      emailReq: `L'adresse e-mail de connexion est requise`,
    },
    succ:
      'Nous venons de vous envoyer un e-mail contenant toutes les instructions nécessaires pour réinitialiser votre mot de passe.<br><br>Vérifiez le dossier de courrier indésirable de votre messagerie.',
  },
  loginClient: {
    header: 'PORTAIL CLIENT',
    failedMasseage: 'Réessayer',
    existingClientPortalAccess:
      "Nous constatons que vous disposez déjà d'un accès au portail client.<br /><br />Connectez-vous en utilisant votre e-mail pour ouvrir un nouveau compte.",
    footer: `Avertissement sur les risques: les contrats de différence (CFD) comportent un niveau de risque élevé pour votre capital et peuvent entraîner des pertes, vous ne devez négocier qu'avec de l'argent que vous pouvez vous permettre de perdre. Le trading de CFD peut ne pas convenir à tous les investisseurs, veuillez vous assurer de bien comprendre les risques impliqués et de prendre les mesures appropriées pour le gérer.<br><br><br>
      {platformEntityName} est autorisée et réglementée par la Financial Services Authority of Seychelles avec la licence No. SD050.<br>
      PU Prime LLC est autorisée et réglementée par la Financial Services Authority de Saint-Vincent-et-les Grenadines avec la licence n ° 271 LLC 2020.<br>
      The entities above are duly authorized to operate under the PU Prime brand and trademark.<br>`,
    formValidation: { emailReq: 'Une adresse e-mail est requise' },
    invailidRafLink: `Le lien de parrainage utilisé n’est pas valide. Inscrivez-vous ci-dessous pour ouvrir un compte.`,
  },
  myProfile: {
    changeDetails: `Si vous souhaitez mettre à jour les détails de votre profil existant, n'hésitez pas à contacter {mailTo}`,
  },
  openAdditAcc: {
    chooseTradePlat: 'CHOISIR UNE PLATE-FORME DE TRADING',
    chooseAccType: 'CHOISIR UN TYPE DE COMPTE',
    chooseCurrency: 'CHOISIR UNE DEVISE POUR LE COMPTE',
    currError: 'Vous devez sélectionner une devise pour le compte',
    platErr: 'Vous devez sélectionner une plate-forme de trading',
    accTypeErr: 'Vous devez sélectionner un type de compte',
    succ: `<p>Votre compte en direct supplémentaire est en cours de configuration et vous recevrez un e-mail contenant vos informations de connexion très bientôt. Vous pouvez afficher le statut actuel de votre demande à partir de la page d'accueil.</p>`,
    byTicking: `J'ai lu et j'accepte les {tnc}.`,
    tnc: 'termes et conditions',
    pamm: `Les comptes PAMM sont réservés aux investisseurs disposant d'un accord de gestionnaire de fonds existant. Les comptes PAMM ne peuvent être échangés par personne, à l'exception du gestionnaire de fonds.`,
    pammConfirm: `Veuillez confirmer si vous souhaitez continuer à sélectionner ce type de compte.`,
  },
  resetPassword: {
    header: 'RÉINITIALISER LE MOT DE PASSE',
    succ:
      'Votre mot de passe a été mis à jour. Vous pouvez maintenant utiliser votre nouveau mot de passe pour accéder à votre compte de trading MT4/MT5.',
    succ2: 'Votre mot de passe a été mis à jour.',
    failed: 'Le jeton a expiré',
    emailNotFound: `Malheureusement votre adresse e-mail n'a pas été reconnue`,
  },
  fileUploader: {
    supportedFormat: 'Types de fichier pris en charge : bmp, jpg, gif, jpeg, png, tiff, doc, pdf',
    maxSize: 'Taille maximale du fichier de téléchargement : {maxSize} Mo',
    maxFileNum: 'Nombre maximum de fichiers : {maxFileNum}',
  },
  transcationHistory: {
    deposit: 'DÉPÔT',
    withdraw: 'RETRAIT',
    desc: {
      incomplete: `Si vous mettez fin au processus de dépôt ou décidez de ne pas l'exécuter, le statut « incomplet » s'affichera pour la transaction. Vous devrez effectuer un nouveau dépôt car cette transaction ne peut pas être reprise.`,
      success:
        'Votre transaction a bien été effectuée. Vos fonds devraient apparaître très prochainement sur votre compte',
      proc: 'Votre transaction est en cours de traitement. Veuillez contacter {mailTo}',
      failed: `Votre transaction a échoué. Pour plus d'informations, veuillez contacter {mailTo}`,
      rejected: `Votre transaction a été refusée. Pour plus d'informations, veuillez contacter {mailTo}`,
    },
    withdrawDesc: {
      sub: `Veuillez patienter jusqu'à la fin de l'exécution du processus`,
      suc: `Votre transaction a bien été effectuée. Vos fonds devraient apparaître très prochainement sur votre compte`,
      proc: 'Votre transaction est en cours de traitement. Veuillez contacter {mailTo}',
      incomp: `Des étapes supplémentaires sont nécessaires pour finaliser votre transaction. Pour plus d'informations, veuillez contacter {mailTo}`,
      cancel: `Votre transaction a été annulée. Pour plus d'informations, veuillez contacter {mailTo}`,
      fail: `Votre transaction a échoué. Pour plus d'informations, veuillez contacter {mailTo}`,
      rej: `Votre transaction a été refusée. Pour plus d'informations, veuillez contacter {mailTo}`,
    },
    cancelWithdraw: `Souhaitez-vous annuler la demande de retrait ?`,
    cancelError: `Votre retrait est en cours de traitement en ce moment. Par conséquent, nous ne pouvons pas accepter votre demande d'annulation. Pour plus d'informations, vous pouvez nous joindre en utilisant la fonction de chat en direct.`,
    dialog: {
      appliedAmount: `Montant appliqué`,
      applicationTime: `Heure de la demande`,
      processedTime: `Heure du traitement`,
      accountCurrency: `Devise du compte`,
      creditDeducted: `Crédit déduit`,
      processedNote: `Commentaire de traitement`,
      bankName: `Nom de la banque`,
      bankAccountNumber: `Numéro du compte bancaire`,
      abaSortCode: `Code SORT / ABA`,
      swiftCode: `Code Swift`,
      bankStatement: `Relevé bancaire`,
      bankAddress: `Adresse de la banque`,
      accountName: `Nom du compte`,
      accountNumber: `Numéro du compte`,
      cardholderName: `Nom du titulaire de la carte`,
      cardNumber: `Numéro de la carte`,
      cardExpiryDate: `Date d’expiration de la carte`,
      walletAddress: `Adresse du portefeuille`,
      ewalletEmailAddress: `Adresse e-mail du portefeuille électronique`,
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'Malheureusement, votre transfert ne peut pas être traité car cela se traduira dans votre compte par une marge disponible tombant sous le niveau Stop out de 100 %.',
      equityMargin:
        'Malheureusement, nous ne sommes pas en mesure de traiter actuellement votre transfert du fait de la couverture complète de votre compte.',
      equityContinue:
        'Nous avons détecté que vous avez actuellement des positions ouvertes. Vous pourriez faire face à un Stop out après avoir soumis votre demande de transfert. Veuillez confirmer si vous souhaitez continuer.',
      blackList: `Ce compte ne comporte pas d'autorisation pour effectuer un transfert entre comptes. Contactez-nous à {supportEmail}`,
    },
    pleaseNote: 'Remarque:',
    suc: 'Vos fonds ont bien été transférés',
    warn1: `Il vous incombe de maintenir un solde de compte qui ne déclenchera pas d'appel de marge. Si vous possédez des positions ouvertes, veillez à maintenir la marge requise sur votre compte. Les fonds transférés sur des comptes tenus dans la même devise sont traités instantanément`,
    warn2:
      'Si vous transférez des fonds entre des comptes tenus dans des devises différentes, envoyez un e-mail à {mailTo}.',
    warn3: `Notez qu'il n'est pas possible de transférer des fonds entre des comptes en lecture seule`,
  },
  transferHistory: {
    payStatus: {
      processing: 'En cours',
      paid: 'Payé',
      unpaid: 'Non payé',
      rejected: 'Rejeté',
    },
  },
  identityProof: {
    home: {
      header: 'DEMANDE DE COMPTE INCOMPLÈTE',
      content:
        'Bonjour {name}, votre demande de compte est presque terminée, mais vous devez toujours soumettre des documents supplémentaires pour finaliser votre compte.",',
    },
    shufti: {
      content: `Veuillez vous assurer que les détails fournis correspondent à votre documentation d'identité et sont dans la même langue.`,
      countryOfDocument: `Pays comme indiqué sur le document`,
    },
    popup: {
      header: `Bonjour {name}, avant que votre compte live ne soit finalisé, vous devez télécharger le document suivant pour vérification.`,
      addressProofSpan: `<strong>Confirmer l'adresse</strong><br />
        Soumettez un des documents ci-dessous<br />
        - Copie d'une facture de services publics récente (de moins de 6 mois)<br />
        - Copie d'un relevé bancaire récent (de moins de 6 mois)<br />
        <br />
        IMPORTANT!　Le nom indiqué sur le document DOIT correspondre exactement à votre nom et à votre adresse actuelle.`,
      idProofSpan: `<strong>Confirmer l'identité</strong><br />
        Choisissez une des options ci-dessous<br />
        - Copie de passeport valide<br />
        - Copie de permis de conduire valide (recto verso)<br />
        - Copie de carte d'identité nationale<br />
        <br />
        IMPORTANT! Le nom indiqué sur le document DOIT correspondre exactement à votre nom.`,
    },
  },
  header: {
    secPortal: 'Zone<br />client sécurisée',
  },
  menu: {
    home: 'ACCUEIL',
    accManagement: 'GESTION DES COMPTES',
    liveAcc: 'COMPTES EN DIRECT',
    openAdditAcc: `COMPTES SUPPLÉMENTAIRES`,
    depositFund: 'DÉPOSER DES FONDS',
    withdrawFunds: 'RETIRER DES FONDS',
    transferBetweenAccs: 'TRANSFERT DE FONDS',
    payDetails: 'DÉTAILS DU PAIEMENT',
    funds: 'FONDS',
    transactionHis: 'HISTORIQUE DES TRANSACTIONS',
    tranHis: 'TRANSFERTS',
    download: 'TÉLÉCHARGEMENTS',
    profile: 'PROFIL',
    myProfile: 'MON PROFIL',
    cpPassword: 'CHANGER LE MOT DE PASSE',
    contactUs: 'NOUS CONTACTER',
    register: 'Poursuivre la demande',
    tradingTools: 'PRO TRADER TOOLS',
    MARKET_BUZZ: 'MARKET BUZZ',
    ECON_CALENDAR: 'ECONOMIC CALENDAR',
    FEAT_FX: 'FEATURED IDEAS',
    FX_IDEAS: 'ANALYST VIEWS',
    promotion: 'PROMOTION',
    noDeposit: 'PAS DE BONUS DE DÉPÔT',
    referFriends: 'RÉFÉREZ UN AMI',
    depositBonus: 'Bonus de dépôt de 50%',
    cryptoCashback: 'Cashback Cryptocurrencie',
    firstTimeDeposit: `Cashback FTD`,
    tradingTool: 'Outils de trading',
  },
  contact: {
    desc: `L'équipe multilingue mondiale de PU Prime est là pour vous aider et vous offrir une expérience de trading inégalée.`,
    followUs: 'Suivez nous',
    tip: 'Pour les clients qui ont intégré via {email}',
    liveChat: 'Chat en direct',
  },
  depositResultDialog: {
    msg: 'Avez-vous bien effectué votre dépôt ?',
  },
  dialog: {
    identityProof: {
      success:
        'Merci. Nous avons bien reçu vos documents et procédons actuellement à leur traitement aux fins de vérification.',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: 'TRAITEMENT DES DEMANDES DE COMPTE',
      id: "pièce d'identité est",
      poa: 'justificatif de domicile est',
      both: "pièce d'identité et justificatif de domicile sont",
      single: 'sera vérifié',
      both2: 'seront vérifiés',
      content:
        'Votre preuve de {doc} en cours de traitement et {doc2} dès que possible. Vous pouvez approvisionner votre compte dès maintenant et accélérer le procès de vérification.  Vous recevrez une confirmation par email dès que vos documents seront traités',
    },
  },
  register: {
    progressStatus: {
      title: 'Avancée de la procédure de vérification',
      estimatedTimeTitle: `temps estimé`,
      min: 'Minutes',
    },
    tabs: {
      personalDetails: 'Informations personnelles',
      moreAboutYou: "Plus d'informations vous concernant",
      accountConfig: 'Configuration du compte',
      confirmYourId: 'Confirmer votre identité',
      fundYourAcc: 'Approvisionner votre compte',
    },
    btn: {
      next: 'SUIVANT',
      back: 'RETOUR',
      fundnow: 'APPROVISIONNER MAINTENANT',
      upload: 'Télécharger',
      done: 'terminé',
    },
    demo: {
      proTrade: 'Rapports marketing professionnels',
      customerService: 'Service client personnalisé 24h / 24 et 7j / 7',
      competitivePromotionals: 'Promotions compétitives',
      secure: 'Transactions sécurisées 3D',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Informations personnelles',
        userTitle: 'Titre',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Adresse e-mail',
        dob: 'Date de naissance',
        idType: "Type de document d'identité",
        referral: "Avez-vous été recommandé par quelqu'un ?  (optionnelle)",
        middleName: 'Deuxième prénom',
        nationality: 'Nationalité',
        phoneNum: 'Numéro de téléphone',
        date: 'Date de naissance',
        month: 'Mois',
        year: 'Année',
        placeOfBirth: 'Lieu de naissance',
        idNum: "Numéro d'identification",
        passportNum: 'Passeport',
        driverLicenseNum: 'Permis de conduire',
        titleDropdown: {
          mr: 'Mr',
          mrs: 'Mrs',
          ms: 'Ms',
          miss: 'Miss',
          dr: 'Dr',
          prof: 'Prof',
        },
        idTypeDropdown: {
          passport: 'Passeport',
          idCard: "Carte d'identité nationale",
          driverLicense: 'Permis de conduire',
        },
      },
      page2: {
        pageTitle: 'ADRESSE DE DOMICILE PRINCIPAL',
        mainResidency: 'Pays de résidence',
        streetNumber: 'Numéro de rue',
        streetName: 'Nom de rue',
        address: 'Adresse',
        provinceOrState: 'Province / État',
        cityOrSuburb: 'Ville / Quartier',
        postcode: 'Code postal',
        usQuestion: `Êtes-vous un citoyen des États-Unis/ Résidez-vous aux États-Unis pour des raisons fiscales ou avez-vous des liens/relations avec les États-Unis (ex. : compte bancaire, etc.)`,
      },
    },
    moreAboutYou: {
      pageTitle1: 'EMPLOI ET DÉTAILS FINANCIERS',
      pageTitle2: 'Connaissances et expérience en matière de trading',
      complianceExplain:
        "Les questions suivantes sont posées dans le cadre des exigences requises en matière de lutte contre le blanchiment d'argent et le financement du terrorisme.",
    },
    accountConfig: {
      pageTitle: 'Configuration du compte',
      chooseTradingPlatform: 'Choisir la plateforme de trading',
      chooseAccType: 'Choisir le type de compte',
      chooseAccCurrency: 'Choisir la devise du compte',
    },
    confirmYourId: {
      pageTitle: 'Confirmer votre identité',
      description: `En tant que broker de forex régulé, nous devons vérifier votre identité. Veuillez donc télécharger un document d'identité ci-dessous ou nous l'envoyer à l'adresse {mailTo}`,
      poidTitle: "Pièce d'identité",
      porTitle: 'Justificatif de domicile',
      uploadDescription: 'Choisissez un type de document et téléchargez le document correspondant',
      note: 'IMPORTANT: Le nom sur le document DOIT clairement indiquer votre nom complet.',
      pending: {
        upload: 'Téléchargement en cours',
        verification: 'Vérification en cours',
      },
      poidTypes: {
        photoId: "Carte d'identité avec photo",
        passport: 'Passeport',
        drivingLicence: 'Permis de conduire',
      },
      porTypes: `<li>Facture de service public</li>
      <li>Relevé bancaire</li>
      <li>Certificat de résidence</li>`,
    },
    finishPage: {
      title: `{individualUserName}, Merci d'avoir lancé la procédure de création d'un compte {platformName} en direct !`,
      contentDefault: `Nous avons bien reçu votre formulaire d'inscription et paramétré votre compte en direct.  <b>{account}</b>. <br />
      Vous recevrez sous peu un e-mail <b>{email}</b> contenant vos informations de connexion et un lien qui vous permettra de consulter le statut de votre compte. <br />`,
      contentWithoutId3WithAccount: `Votre compte de trading en direct est en cours de traitement et vous recevrez sous peu un e-mail contenant de plus amples informations.<br />
      Pour accélérer le traitement de votre compte en direct, vous pouvez financer votre compte maintenant et recevoir une ouverture prioritaire..<br />`,
      contentWithoutId3WithoutAccount: `Votre compte de trading en direct est en cours de traitement et vous recevrez sous peu un e-mail contenant de plus amples informations.<br />`,
    },
  },
  autochartist: {
    1: {
      title: `Scanner de marché`,
      desc01: `Le premier Market Scanner au monde qui vous permet de scanner votre liste de surveillance du marché pour les opportunités de trading.`,
      button01: `Télécharger maintenant`,
    },
    2: {
      title: `Rapport de marché`,
      desc01: `Les rapports de marché Autochartist vous donnent un aperçu des perspectives techniques des marchés avant l'ouverture des principales places financières.`,
      button01: `Voir les rapports de marché`,
    },
    3: {
      title: `Portail de recherche Autochartist`,
      desc01: `Un portail de recherche complet qui vous permet de trouver toutes les données disponibles en un seul endroit.`,
      button01: `Lancer`,
      button02: `Mode d'emploi`,
      button03: `Accès OTP`,
    },
    4: {
      title: `Opportunités de trading`,
      desc01: `Vérifiez les mises à jour sur les opportunités de trading toutes les 15 minutes avec de nouvelles configurations basées sur tous les types d'analyse d'Autochartist.`,
      button01: `Voir les opportunités de trading`,
    },
    5: {
      title: `Application mobile Autochartist`,
      desc01: `Recevez des notifications push et suivez les opportunités de trading filtrées à haute probabilité en fonction de votre liste de surveillance au sein de la plateforme de trading.`,
      desc02: `Remarques:`,
      desc03: `Le code QR peut être scanné à partir de l'appareil photo normal, à condition que le téléphone de l'utilisateur dispose d'un scanner QR intégré, ou à partir de l'application mobile autochartist, en cliquant sur le bouton «Connexion à l'aide du code QR».`,
      desc04: `Le code QR n'est valide que pendant 10 minutes, après quoi l'image doit être actualisée pour un nouveau code QR.`,
      desc05: `Le code QR est valide pour un usage unique uniquement, après quoi l'image doit être actualisée pour un nouveau code QR.`,
      button01: `Télécharger pour IOS`,
      button02: `Télécharger pour Andriod`,
    },
    6: {
      title: `Inscription à la Newsletter`,
      desc01: `En vous inscrivant à notre newsletter, vous recevrez quotidiennement des e-mails alimentés par Autochartist pour vous donner un aperçu des marchés forex mondiaux. Commerce avec union pacifique, commerce avec le monde.`,
      button01: `S'abonner`,
    },
    footer: `Autochartist est l’outil le plus avancé au monde pour l’identification automatique des opportunités commerciales sous la forme de modèles de graphiques, de modèles de Fibonacci et de niveaux clés. La propriété exclusive d’Autochartist permet à ses moteurs de recherche de surveiller des milliers d’instruments financiers 24 heures sur 24 et de découvrir les opportunités commerciales au fur et à mesure qu’elles se présentent. En outre, Autochartist propose également l'analyse de la volatilité en tant que fonctionnalité qui vous donnera une meilleure évaluation du risque et de la volatilité sur les instruments que vous négociez.`,
  },
  responseMsg: {
    //4xx warning
    410: 'Échec de la validation du paramètre',
    411: 'Mot de passe incorrect',
    420: `L'utilisateur n'existe pas`,
    421: 'Réinitialisation du mot de passe refusée',
    490: 'Ne soumettez pas les informations de votre carte en double',
    //5xx error
    500: `Une erreur s'est produite.`,
    501: 'Échec de la demande. Veuillez réessayer plus tard.',
    505: 'Échec du téléchargement du fichier',
    520: 'Échec de connexion. Veuillez réessayer.',
    521: 'Un utilisateur est déjà connecté sur cet appareil !',
    522: 'Veuillez vous reconnecter',
    523: 'Veuillez vous reconnecter',
    524: `Malheureusement votre adresse e-mail n'a pas été reconnue`,
    525: `Nom d'utilisateur ou mot de passe incorrect`,
    527: `L'utilisateur n'est pas un apporteur d'affaires`,
    528: `L'utilisateur n'existe pas`,
    529: `Nom d'utilisateur ou mot de passe incorrect`,
    540: `Votre compte de trading est introuvable`,
    541: 'Compte de bonus introuvable',
    542: `Vous n'êtes pas autorisé à ouvrir un compte supplémentaire`,
    544: `Vous n'êtes pas autorisé à demander un compte supplémentaire tant que votre pièce d'identité n'a pas été vérifiée`,
    550: 'Numéro de compte incorrect',
    551: 'Votre solde est insuffisant',
    553: `Vous ne pouvez pas retirer / transférer en raison du crédit sur votre compte. Veuillez contacter {email} pour obtenir de l'aide`,
    554: 'La somme demandée est égale à zéro ou est nulle',
    564: `Le pays ne correspond pas au canal de paiement`,
    565: 'Code de banque incorrect',
    566: 'Méthode de paiement introuvable',
    568: 'Ce compte est restreint pour effectuer un dépôt. Contactez-nous à info@puprime.com',
    581: 'Vous avez bien choisi la promotion. Veuillez noter que vous devez effectuer un dépôt pour retirer des fonds.',
    582: "Vous participez à l'événement et avez des postes, vous ne pouvez donc pas soumettre de demande de retrait.",
    584: `Vous n'avez aucune bonus applicable`,
    587: 'Solde insuffisant, veuillez contacter le service client',
    590: 'Compte du transfert sortant introuvable',
    591: 'Compte du transfert entrant introuvable',
    593: `Nous ne sommes pas en mesure de traiter votre transfert de fonds depuis le compte sélectionné car votre compte contient du crédit.`,
    594: `Le transfert de fonds depuis ce compte n'est pas autorisé`,
    595: 'Comme vous participez à nos activités, votre demande de transfert ne peut pas être traitée pour le moment.',
    647: 'Le code de vérification est incorrect',
    1001: 'Votre compte est temporairement indisponible. Veuillez contacter votre gestionnaire de compte',
    session_timeout: 'Expiration de la session. Redirection vers la page de connexion.',
    vload: {
      4019: `Le type de coupon ou la valeur n'est pas accepté`,
      4071: 'Suspicion de fraude',
      4072: 'Le coupon est utilisé',
      4073: `Le coupon n'est pas actif`,
      4074: 'Le coupon est expiré',
      4075: `Le coupon n'a pas été trouvé`,
      4080: 'La valeur du coupon est insuffisante',
      4444: 'Coupon utilisé non valide. Non-concordance des devises.',
      5000: 'Les données de la boutique sont indisponibles',
    },
  },
  tradingTool: {
    mt4: {

    },
    pamm: {

    },
    copytrade: {

    }
  },
  activity: {
    myActivity: '',
    allActivity: '',
    activityContent: '',
    applyNow: '',
    activityStatus1: '',
    activityStatus2: '',
    activityStatus3: '',
    noActivity: '',
  }
}
