<template>
  <el-dialog :visible.sync="innerVisible" v-bind="getBindProps" v-on="getOnEvents" :before-close="onCancel">
    <template #title v-if="$slots.title || getBindProps.title">
      <template v-if="!$slots.title">
        <DialogHeader :helpMessage="helpMessage" :title="getBindProps.title"></DialogHeader>
      </template>
      <template v-else>
        <slot name="title"></slot>
      </template>
    </template>

    <div class="dialog_body">
      <slot></slot>
    </div>
    <template #footer>
      <div class="dialog_footer" v-if="showFooter">
        <DialogFooter
          v-bind="getBindProps"
          :showFooterCancel="showFooterCancel"
          :showFooterConfirm="showFooterConfirm"
          :disabledFooterConfirm="disabledFooterConfirm"
          :disabledFooterCancel="disabledFooterCancel"
          :confirmButtonTestId="confirmButtonTestId"
          :cancelButtonTestId="cancelButtonTestId"
          :confirmButtonText="confirmButtonText"
          :cancelButtonText="cancelButtonText"
          @cancel="onCancel"
          @confirm="onConfirm"
        >
          <template #footer>
            <slot name="footer"></slot>
          </template>

          <template #insertFooter>
            <slot name="insertFooter"></slot>
          </template>
          <template #centerFooter>
            <slot name="centerFooter"></slot>
          </template>
          <template #appendFooter>
            <slot name="appendFooter"></slot>
          </template>
        </DialogFooter>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import DialogHeader from './components/DialogHeader.vue'
import DialogFooter from './components/DialogFooter.vue'
import isMobile from '@/mixins/isMobile'

export default {
  name: 'SDialog',
  mixins: [isMobile],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'default',
      validator: value => ['default', 'simple'].includes(value),
    },
    // 弹窗提示文案
    helpMessage: {
      type: String,
      default: '',
    },
    // 是否显示footer
    showFooter: {
      type: Boolean,
      default: true,
    },
    // 是否显示footer确定按钮
    showFooterConfirm: {
      type: Boolean,
      default: true,
    },
    // 是否显示footer取消按钮
    showFooterCancel: {
      type: Boolean,
      default: true,
    },
    // 是否禁用footer确定按钮
    disabledFooterConfirm: {
      type: Boolean,
      default: false,
    },
    // 是否禁用footer取消按钮
    disabledFooterCancel: {
      type: Boolean,
      default: false,
    },
    // 是否关闭footer取消按钮的默认行为(关闭弹窗)
    stopFooterCancel: {
      type: Boolean,
      default: false,
    },
    // confirm按钮的文案
    confirmButtonText: {
      type: String,
      default() {
        return this.$t('common.button.confirm')
      },
    },
    // confirm按钮的data-testid
    confirmButtonTestId: {
      type: String,
    },
    // cancel按钮的文案
    cancelButtonText: {
      type: String,
      default() {
        return this.$t('common.button.cancel')
      },
    },
    // cancel按钮的data-testid
    cancelButtonTestId: {
      type: String,
    },
    // 关闭弹窗时是否需要二次确认弹窗
    closeConfirm: {
      type: Boolean,
      default: false,
    },
    // 关闭弹窗时二次确认弹窗的文案
    closeConfirmText: {
      type: String,
      default() {
        return '确定关闭吗？'
      },
    },
  },
  components: {
    DialogFooter,
    DialogHeader,
  },
  computed: {
    getBindProps() {
      const opt = {
        'append-to-body': true,
        'close-on-press-escape': false,
        'close-on-click-modal': false,
        width: '480px',
        ...this.$attrs,
        'custom-class': this.getCustomClass,
      }
      // 移动端dialog宽度100%
      if (this.isMobile) {
        // opt.fullscreen = true;
        opt.width = '90%'
      }
      return opt
    },
    getOnEvents() {
      return {
        ...this.$listeners,
      }
    },
    getCustomClass() {
      let defaultClass = 'new_dialog_custom_class'
      if (this.$attrs['custom-class']) {
        defaultClass = `${defaultClass} ${this.$attrs['custom-class']}`
      }
      if (this.showFooter) {
        defaultClass = `${defaultClass} has_footer`
      }
      if (!this.$attrs.title && !this.$slots.title) {
        defaultClass = `${defaultClass} no_header`
      }
      if (this.mode === 'simple') {
        defaultClass = `${defaultClass} mode_simple`
      }
      return defaultClass
    },
    innerVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      },
    },
  },
  mounted() {},
  methods: {
    async onCancel() {
      if (this.closeConfirm) {
        const res = await this.closeFunc()
        if (res) {
          this.$emit('cancel')
        }
        this.innerVisible = !res
        return
      } else {
        this.$emit('cancel')
        if (!this.stopFooterCancel) {
          this.innerVisible = false
        }
      }
    },
    onConfirm() {
      this.$emit('confirm')
    },
    closeFunc() {
      return new Promise(resolve => {
        this.$msgbox({
          title: this.$t('common.field.tips'),
          message: this.closeConfirmText,
          showCancelButton: true,
          confirmButtonText: this.$t('common.button.confirm'),
          cancelButtonText: this.$t('common.button.cancel'),
          beforeClose: (action, _, done) => {
            if (action === 'confirm') {
              done()
              resolve(true)
            } else {
              done()
              resolve(false)
            }
          },
        })
      })
    },
  },
}
</script>

<style lang="scss">
.new_dialog_custom_class {
  border-radius: 16px;
  box-shadow: 0px 8px 20px rgba(14, 18, 54, 0.2);
  overflow: hidden;

  &.no_header {
    .el-dialog__header {
      border-bottom: none;
    }
  }

  .el-dialog__header {
    padding: 16px 24px;
    font-size: 16px;
    line-height: 24px;
    color: $text-title;
    font-weight: 600;
    border-bottom: 1px solid $border-color-base;
    margin: 0;

    .header_content {
      position: relative;
      @include rtl-sass-prop(padding-left, padding-right, 12px);

      &::before {
        content: '';
        position: absolute;
        top: 4px;
        @include rtl-sass-prop(left, right, 0);
        width: 4px;
        height: 16px;
        border-radius: 4px;
        background-color: $primary;
      }
    }

    .el-dialog__close-btn {
      color: $black;
    }
  }

  .el-dialog__body {
    padding: 0 0 65px 0;
    box-sizing: border-box;
  }

  .dialog_body {
    padding: 24px 40px;
    max-height: 60vh;
    overflow: auto;
    font-size: 14px;

    @include screen-mobile {
      padding: 24px;
    }
  }

  .el-dialog__footer {
    position: absolute;
    bottom: 0;
    @include rtl-sass-prop(right, left, 0);
    padding: 16px 24px;
    width: 100%;
    border-top: 1px solid $border-color-base;
    background-color: $white;

    .footer_content {
      display: flex;
      justify-content: flex-end;
    }

    .el-button + .el-button {
      @include rtl-sass-prop(margin-left, margin-right, 24px);
    }
  }

  &.mode_simple {
    .el-dialog__body {
      padding: 0 0 72px 0;
      box-sizing: border-box;

      @include screen-mobile {
        padding: 0 0 56px 0;
      }
    }

    .dialog_body {
      padding: 40px 40px 20px 40px;
      max-height: none;

      @include screen-mobile {
        padding: 24px;
      }
    }

    .el-dialog__footer {
      border-top: none;
      padding: 20px 24px 40px;
      font-size: 12px;

      @include screen-mobile {
        padding: 0 24px 24px;
      }

      .footer_content {
        display: flex;
        justify-content: center;

        .el-button + .el-button {
          @include rtl-sass-prop(margin-left, margin-right, 16px);
        }
      }
    }
  }
}
</style>
