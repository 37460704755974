export default {
  en: 'Englisch',
  zh: '中文',
  fr: 'Französisch',
  common: {
    liveChat: { desc: 'Brauchen Sie Hilfe? Live-Chat' },
    country: { international: 'International', australia: 'Australien' },
    verification: { toSlide: 'Schieben, um das Rätsel zu vervollständigen' },
    field: {
      nameOnCard: 'Name auf Karte',
      cardholderName: `Name des Karteninhabers`,
      first4Digits: 'Erste 4 Ziffern auf der Karte',
      last3Digits: 'Letzte 3 Ziffern auf der Karte',
      first6Digits: 'Erste 6 Ziffern auf der Karte',
      last4Digits: 'Letzte 4 Ziffern auf der Karte',
      bankName: 'Bankname',
      bankAddress: 'Bankadresse',
      branchAddress: 'Filialadresse',
      bankBranch: 'Bankfiliale',
      bankCity: 'Bank Stadt',
      bankProvince: 'Bank Provinz',
      bankBeneficiaryName: 'Bank Begünstigter Name',
      bankBeneAccName: 'Bank Begünstigter Kontoname',
      bankBeneAccNum: 'Bank Begünstigter Kontonummer',
      bankBeneAddress: 'Adresse des Begünstigten',
      bankAccNum: 'Kontonummer der Bank',
      bankAccName: 'Name des Bankkontos',
      bankHolderAddress: 'Adresse des Kontoinhabers',
      address: 'Adresse',
      accName: 'Name des Kontos',
      accNum: 'Kontonummer',
      accNumTo: 'Kontonummer',
      bsbNum: 'BSB-Nummer',
      swift: 'SWIFT-Code',
      ifscCode: 'IFSC-Code',
      swiftNonAUD: 'SWIFT-Code (Nicht-AUD-Konten)',
      amt: 'Betrag',
      amtUSD: 'Betrag (USD)',
      availableAmount: 'Verfügbarer Betrag',
      transitNumber: 'Transitnummer',
      institutionNumber: 'Institutionsnummer',
      imptNotes: 'Wichtige Hinweise',
      yes: 'Ja',
      no: 'Nein',
      payMethod: 'Zahlungsmethode:',
      expDate: 'Ablaufdatum',
      bsb: 'BSB',
      abaSortCode: 'ABA/Sort-Code:',
      country_region: 'Land/Region',
      currLeverage: 'Aktuelle Hebelwirkung',
      accEquity: 'Konto-Eigenkapital',
      newLeverage: 'Gewünschte Hebelwirkung',
      currPw: 'Aktuelles Passwort',
      newPw: 'Neues Passwort',
      confirmPw: 'Bestätigen Sie das neue Passwort',
      loginEmail: 'E-Mail Anmeldung',
      email: 'E-Mail',
      pw: 'Passwort',
      accType: 'Konto-Typ',
      name: 'Name',
      emailAdd: 'E-Mail-Adresse',
      phone: 'Telefonnummer',
      dob: 'Geburtsdatum',
      nat: 'Nationalität',
      country: 'Land des Wohnsitzes',
      street: 'Straßennummer/Straßenname',
      suburbOrCity: 'Vorort/Stadt',
      provinceOrState: 'Bundesland/Bundesstaat',
      postcode: 'Postleitzahl',
      empStat: 'Beschäftigungsstatus',
      annIncome: 'Jährliches Einkommen',
      save: 'Investition',
      source: 'Quelle des Einkommens',
      industry: 'Branche',
      statusDef: 'STATUS-DEFINITIONEN',
      month: 'Monat',
      year: 'Jahr',
      select: 'Wählen',
      completed: 'Abgeschlossen',
      incomplete: 'Unvollständig',
      submitted: 'Abgesendet',
      successful: 'Erfolgreich',
      processing: 'Bearbeitung',
      cancelled: 'Abgebrochen',
      failed: 'Gescheitert',
      rejected: 'Abgelehnt',
      upload: 'Hochladen',
      pending: `steht aus`,
      ccNum: 'Kreditkartennummer',
      ccExpireDate: 'Ablaufdatum',
      bankCardNumber: 'Bankkartennummer',
      cardHolderName: 'Name des Karteninhabers',
      reservedMobileNumber: 'Mobiltelefonnummer für die Bank',
      nationalId: 'Nationale ID-Karte',
      cardPhoto: 'Kartenfoto (Vorderseite)',
      notes: 'Anmerkungen',
      bankAccOrIBAN: 'Bankkontonummer/IBAN',
      selectWithdrawCard: 'Wählen Sie Ihre Auszahlungskarte',
      updateCardPhoto: 'Bitte laden Sie Ihr Kartenfoto (Vorderseite) hoch',
      actualAmount: 'Tatsächlicher Betrag',
      resetBalTo: 'Guthaben zurücksetzen auf',
      ipChangeWarning:
        'Erhalten Sie eine E-Mail, wenn sich jemand von einem anderen Standort aus in Ihr Konto einloggt',
      enterVerificationCode: 'Verifizierungscode eingeben',
      tips: '',
      pleaseInput: '',
      confirmClose: '',
      notBind: '',
      infoDetails: '',
      loginWarn: '',
      noDataNow: '',
      hasAccountAlready: '',
    },
    column: {
      date: 'DATUM',
      type: 'TYP',
      server: 'SERVER',
      status: 'STATUS',
      comment: 'KOMMENTAR',
      accNum: 'KONTONUMMER',
      currency: 'WÄHRUNG',
      equity: 'EIGENKAPITAL',
      leverage: 'HEBELWIRKUNG',
      resetMT4PW: 'MT4 PASSWORT ZURÜCKSETZEN',
      resetMT5PW: 'MT5 PASSWORT ZURÜCKSETZEN',
      tradingAcc: 'TRADING-KONTO',
      method: 'METHODE',
      amt: 'BETRAG',
      procNote: 'VERFAHRENSHINWEISE',
      number: 'KARTENNUMMER',
      PLATFORM: 'PLATTFORM',
      resetBal: 'GUTHABEN ZURÜCKSETZEN',
    },
    key: { from: 'VON', to: 'BIS' },
    fund: {
      must: 'MUSS',
      infoHover: 'Der Name auf Ihrer Karte {muss} mit dem Namen auf Ihrem Trading-Konto übereinstimmen',
      ccUploadReminder:
        'Bitte laden Sie ein Foto der Vorderseite Ihrer Karte zur Verifizierung hoch. (Bitte decken Sie die mittleren 6 Ziffern ab)',
    },
    button: {
      bkToHm: 'Zurück zur Startseite',
      smsLogin: 'MOBILE ANMELDUNG',
      goToIB: 'Zum IB-Portal wechseln',
      logout: 'ABMELDEN',
      submit: 'Absenden',
      selectFile: 'Datei auswählen',
      depositFunds: 'GELDER EINZAHLEN',
      withdrawFunds: 'GELDER AUSZAHLEN',
      ibPortal: 'IB-PORTAL',
      download: 'DOWNLOADS',
      changeLeverage: 'HEBELWIRKUNG ÄNDERN',
      cancel: 'Abbruch',
      changePw: 'PASSWORT ÄNDERN',
      forgetPw: 'PASSWORT VERGESSEN',
      confirm: 'Bestätigen',
      login: 'ANMELDEN',
      forgotPw: 'Passwort vergessen?',
      completeQuestionnaire: 'FRAGEBOGEN AUSFÜLLEN',
      identityProof: 'ANTRAG AUSFÜLLEN',
      upload: 'Hochladen',
      export: 'EXPORTIEREN',
      ok: 'OK',
      hide: 'EINBLENDEN',
      showAll: 'ALLES ANZEIGEN',
      createLiveAccount: `Live-Konto erstellen`,
      redeem: `Einlösen`,
      sendCode: 'Code senden',
      resendCode: 'Code erneut senden',
    },
    formValidation: {
      accReq: 'Kontonummer ist erforderlich',
      amtReq: 'Betrag ist erforderlich',
      ccReq: 'Kreditkartennummer ist erforderlich',
      cardReq: 'Kartennummer ist erforderlich',
      cardOnNameReq: 'Name auf der Karte ist erforderlich',
      cardholderNameReq: `Der Name des Karteninhabers ist erforderlich`,
      digits16: 'Bitte 16 Ziffern eingeben',
      digits3or4: 'Bitte geben Sie 3 oder 4 Ziffern ein',
      digitsFirst6: 'Bitte geben Sie die ersten 6 Ziffern ein',
      digitsLast4: 'Bitte geben Sie die letzten 4 Ziffern ein',
      digitsLast3: 'Bitte geben Sie die letzten 3 Ziffern ein',
      mid6Digits: 'Bitte mittlere {Zahl} Ziffern eingeben',
      expDateReq: 'Verfallsdatum ist erforderlich',
      cvvReq: 'Sicherheitscode CVV ist erforderlich',
      file: 'Bitte laden Sie eine Datei hoch',
      filePhoto: 'Kreditkartenfoto ist erforderlich',
      fileReceipt: 'Bitte laden Sie einen Beleg hoch',
      filePhotoReq: 'Kartenfoto ist erforderlich',
      amtLarger: 'Betrag kann nicht null sein und muss größer oder gleich {minLimit} {currency} sein',
      amt0: 'Betrag kann nicht 0 sein',
      amtLess: 'Der Betrag Ihrer Einzahlung kann nicht höher sein als {maxLimit} {Währung}',
      note256: 'Das Limit für die Textlänge beträgt 256 Zeichen',
      noteMoreThan: 'Das Limit für die Textlänge beträgt {limit} Zeichen',
      noteLessThan: 'Das Limit für die Textlänge beträgt {limit} Zeichen',
      emailFormat: 'Bitte korrekte E-Mail-Adresse eingeben',
      payMethodReq: 'Zahlungsmethode ist erforderlich',
      expReq: 'Verfallsdatum ist erforderlich',
      bankNameReq: 'Bankname ist erforderlich',
      bankCityReq: 'Bank Stadt ist erforderlich',
      bankProvinceReq: 'Bank Provinz ist erforderlich',
      bankAddressReq: 'Adresse der Bank ist erforderlich',
      bankBranchReq: 'Bankfiliale ist erforderlich',
      bankAccNumReq: 'Bankkontonummer ist erforderlich',
      ifscCodeReq: 'IFSC-Code ist erforderlich',
      bankBeneficiaryNameReq: 'Bank Begünstigter Name ist erforderlich',
      bankHolderAddressReq: 'Adresse des Kontoinhabers ist erforderlich',
      swiftReq: 'Swift-Code ist erforderlich',
      accHolderAddress: 'Adresse des Kontoinhabers ist erforderlich',
      bsbReq: 'BSB ist erforderlich',
      accNumIBANReq: 'Bankkontonummer/IBAN ist erforderlich',
      accNameReq: 'Bankkontoname ist erforderlich',
      withdrawMethodReq: 'Bitte wählen Sie eine Auszahlungsmethode',
      tnc: 'Bitte stimmen Sie den Allgemeinen Geschäftsbedingungen zu.',
      currPwReq: 'Aktuelles Passwort ist erforderlich',
      currPwWrong: 'Das aktuelle Passwort ist falsch',
      newPwReq: 'Neues Passwort ist erforderlich',
      newPwFormat:
        'Ihr Passwort muss zwischen 8 und 16 Zeichen lang und eine Kombination aus Buchstaben (a-z und A-Z), Zahlen (0-9) und Sonderzeichen wie !@#$%^&*.() sein',
      IdenficalPw: 'Das neue Passwort kann nicht mit dem alten identisch sein',
      confirmPwReq: 'Bitte geben Sie das neue Passwort erneut ein',
      confirmNotMatch: 'Zwei Eingaben stimmen nicht überein',
      pwReq: 'Passwort ist erforderlich',
      accCurrReq: 'Sie müssen eine Kontowährung auswählen',
      tradAccReq: 'Bitte wählen Sie ein Trading-Konto',
      answerReq: 'Bitte wählen Sie',
      bankCardReq: 'Bitte wählen Sie eine Karte aus',
      phoneNumberReq: 'Mobiltelefonnummer für Bank ist erforderlich',
      nationaIdReq: 'Nationaler Ausweis ist erforderlich',
      numberReq: 'Bitte Nummer eingeben',
      alphanumericReq: 'Bitte alphanumerisch eingeben',
      idTypeReq: 'Bitte wählen Sie einen Ausweis-Typ aus',
      dobReq: 'Bitte wählen Sie Ihr Geburtsdatum',
      pobReq: 'Bitte wählen Sie Ihren Geburtsort',
      nationalityReq: 'Bitte wählen Sie Ihre Nationalität',
      titleReq: 'Bitte wählen Sie Ihren Titel',
      firstNameReq: 'Bitte geben Sie Ihren Vornamen ein',
      lastNameReq: 'Bitte geben Sie Ihren Nachnamen ein',
      emailReq: 'Bitte geben Sie Ihre E-Mail Adresse ein',
      addressReq: 'Bitte geben Sie Ihre Adresse ein',
      suburbReq: 'Bitte geben Sie Ihre Stadt oder Ihren Vorort ein',
      stateReq: 'Bundesland oder Provinz ist erforderlich',
      postcodeReq: 'Bitte geben Sie Ihre Postleitzahl ein',
      countryReq: 'Bitte wählen Sie ihr Wohnsitzland',
      masterCardReq: 'Bitte benutzen Sie eine MasterCard zur Einzahlung',
      masterCardJCBReq: 'Bitte verwenden Sie MasterCard/JCB zur Einzahlung',
      masterCardVisaReq: 'Bitte verwenden Sie MasterCard/Visa zur Einzahlung',
      masterCardAmexJCBReq: 'Bitte verwenden Sie zur Einzahlung MasterCard/AMEX/JCB',
      bankCodeReq: 'Bitte geben Sie die kartenausgebende Bank ein',
      dynamicReq: '{dynamic} ist erforderlich',
      mobReq: 'Mobil ist erforderlich',
      resetBalAmt: 'Bitte stellen Sie einen Betrag zwischen 0 und 1.000.000 ein',
      verCodeReq: 'Verifizierungscode ist erforderlich',
      emailFormatWrong: 'Inkorrektes E-Mailformat',
      passwordFormatWrong: 'Inkorrektes Passwortformat',
    },
    withdrawChannel: {
      banktransferbpaypolipay: 'Banküberweisung/BPay/PoliPay',
      banktransfer: 'Banküberweisung',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'UnionPay Überweisung',
      fasapay: 'FasaPay',
      creditcard: 'Kreditkarte',
      bpay: 'Bpay',
      creditdebitcard: 'Kredit-/Debitkarte',
      bankwiretransferinternational: 'Banküberweisung (International)',
      banktransferaustralia: 'Banküberweisung (Australien)',
      banktransferinternational: 'Banküberweisung (International)',
      banktransferchina: 'Banküberweisung (International)',
      banktransferequals: 'Banküberweisung (International)',
      mobilepay: 'Mobiles Bezahlen',
      brokertobrokertransfer: 'Broker-zu-Broker-Überweisung',
      skrillmoneybookers: 'Skrill/Moneybookers',
      internetbankingthailand: 'Internet-Banking (Thailand)',
      internetbankingnigeria: 'Internet-Banking (Nigeria)',
      internetbankingvietnam: 'Internet-Banking (Vietnam)',
      internetbankingmalaysia: 'Internet-Banking (Malaysia)',
      internetbankingindonesia: 'Internet-Banking (Indonesia)',
      internetbankingkorea: 'Internet-Banking (Korea)',
      internetbankingindia: 'Internet-Banking (Indien)',
      internetbankingphilippine: 'Internet-Banking (Philippinen)',
      internetbankingsouthafrica: 'Internet-Banking (Südafrika)',
      internetbankinguganda: 'Internet-Banking (Uganda)',
      internetbankingrwanda: 'Internet-Banking (Ruanda)',
      internetbankingzambia: 'Internet-Banking (Sambia)',
      internetbankingcongo: 'Internet-Banking (Kongo)',
      internetbankingcameroon: 'Internet-Banking (Kamerun)',
      internetbankingburundi: 'Internet-Banking (Burundi)',
      internetbankingkenya: 'Internet-Banking (Kenia)',
      internetbankingghana: 'Internet-Banking (Ghana)',
      internetbankingtanzania: 'Internet-Banking (Tansania)',
      internetbankingcanada: 'Internet-Banking (Kanada)',
      skrillneteller: 'Skrill/Neteller',
      vietnambanktransfer: 'Vietnam Banküberweisung',
      nigeriabanktransfer: 'Nigeria Banküberweisung',
      polipayment: 'Poli-Zahlung',
      bankwiretransferaustralia: 'Banküberweisung (Australien)',
      thailandinstantbankwiretransfer: 'Thailändische Sofortüberweisung ',
      nigeriainstantbankwiretransfer: 'Nigerianische Sofortüberweisung',
      vietnaminstantbankwiretransfer: 'Vietnamesische Sofortüberweisung',
      malaysiainstantbankwiretransfer: 'Malaysia Sofortige Banküberweisung',
      indonesiainstantbankwiretransfer: 'Indonesia Sofortüberweisung',
      indiainstantbanktransfer: 'Indien Sofortige Banküberweisung',
      southkoreabanktransfer: 'Südkorea lokale Banküberweisung',
      southafricainstantbankwiretransfer: 'Südafrikanische Instant BankÜberweisung',
      philippinesinstantbankwiretransfer: 'Philippinische Sofortige Banküberweisung',
      ugandabanktransfer: 'Uganda lokale Banküberweisung',
      rwandabanktransfer: 'Ruanda lokale Banküberweisung',
      zambiabanktransfer: 'Sambia lokale Banküberweisung',
      congobanktransfer: 'Kongo lokale Banküberweisung',
      cameroonbanktransfer: 'Kamerun lokale Banküberweisung',
      burundibanktransfer: 'Burundi lokale Banküberweisung',
      kenyabanktransfer: 'Kenia lokale Banküberweisung',
      ghanabanktransfer: 'Ghana lokale Banküberweisung',
      tanzaniabanktransfer: 'Tansania lokale Banküberweisung',
      indiabanktransfer: 'Indien Banküberweisung',
      banktransferbpaypolipayaustralia: 'Banküberweisung/BPay/PoliPay (Australien)',
      banktransferbpaypolipayinternational: 'Banküberweisung/BPay/PoliPay (International)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Kanadische lokale Banküberweisung',
      cryptobitcoin: 'Krypto-Bitcoin',
      cryptotetheromni: 'Krypto-Tether (OMNI)',
      cryptotethererc20: 'Krypto-Tether (ERC20)',
      cryptocurrencybitcoin: 'Kryptowährung-Bitcoin',
      cryptocurrencyusdt: 'Kryptowährung-USDT',
      cryptocurrencyeth: 'Kryptowährung-ETH',
      cryptocurrencyusdc: 'Kryptowährung-USDC(ERC-20)',
      cryptocurrencyusdcerc20: 'Kryptowährung-USDC(ERC-20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      E_WALLET: 'E-WALLET',
      CRYPTOCURRENCY: 'KRYPTOWÄHRUNG',
      LOCAL_TRANSFER: 'LOKALER TRANSFER',
      archiveThisCard: 'Bitte archivieren Sie diese Karte, wenn sie nicht mehr gültig oder in Gebrauch ist',
      manageCreditCards: 'Kreditkarten verwalten',
      update: 'Aktualisieren',
      archive: 'Archivieren',
      requiredDocument: 'Für die Archivierung der Karte erforderliche Dokumente',
      officialLetter: 'Offizielles Schreiben/E-Mail der Bank, dass die Karte nicht länger verfügbar ist',
      bankStatement: 'Kontoauszug mit den mit dieser Karte getätigten Transaktionen',
      expiredCard: 'Abgelaufene/nicht verfügbare Karten',
      confirmArchive: 'Sind Sie sicher, dass Sie diese Karte archivieren möchten?',
      confirmUpdate:
        'Sind Sie sicher, dass Sie das Ablaufdatum dieser Karte aktualisieren möchten? Hinweis: Puprime ist nicht dafür verantwortlich, wenn die Auszahlung aufgrund eines falsch angegebenen Ablaufdatums scheitert.',
      pendingArchiveRequest:
        'Ihr Antrag auf Kreditkartenarchivierung wurde noch nicht genehmigt. Bitte warten Sie, bis der Prozess abgeschlossen ist, bevor Sie etwas abheben. Oder kontaktieren Sie unseren Kunden-Support per Live-Chat, um den Prozess zu beschleunigen.',
    },
  },
  home: {
    pendingTooltip:
      'Sobald Ihr Live-Konto genehmigt ist, erhalten Sie eine E-Mail mit Ihren MT{number}-Login-Daten, damit Sie mit dem Traden beginnen können! In der Zwischenzeit können Sie auf Ihr Konto einzahlen, um eine bevorzugte Kontoeröffnung zu erhalten.',
    leverage: {
      tnc:
        '<p>Mit dem Absenden dieses Antrags erkenne ich an, dass ich mir bewusst bin, dass eine hohe Hebelwirkung ein großes Risiko für mein Kapital birgt und die Möglichkeit besteht, dass ich einen Verlust erleide, der größer als das von mir eingezahlte Kapital ist und nicht auf dieses begrenzt ist.<br><br>Ich stimme zu, dass der Produktanbieter berechtigt ist, meine Trading-Aktivitäten zu überprüfen und die Hebelwirkung auf meinem Trading-Konto nach eigenem Ermessen und ohne vorherige Warnungen oder Benachrichtigungen anzupassen.<br><br>Ich verstehe, dass ich durch die Wahl einer hohen Hebelwirkung mit einer größeren Margin traden kann, aber möglicherweise erhebliche Verluste erleide.</p>',
      changeLeverageConfirm:
        'Ihre Anfrage wurde abgeschickt und wird in Kürze von unserem Kundendienst-Team bearbeitet.',
      changeLeverageAutoComplete: 'Ihre Anfrage zur Änderung der Hebelwirkung ist abgeschlossen',
      changeLeverageReject: 'Die Anfrage zur Änderung der Hebelwirkung wurde abgelehnt',
      changeLeverageFail:
        'Die Anfrage zur Änderung der Hebelwirkung ist fehlgeschlagen, bitte versuchen Sie es später erneut',
      changeLeverageDefault:
        'Es ist ein Fehler aufgetreten, bitte kontaktieren Sie {supportEmail}, um Ihren Antrag abzuschließen',
      alreadyApplied:
        'Sie haben bereits eine Änderung der Hebelwirkung für Ihr Konto beantragt, die derzeit vom Kundendienst-Team der {platform} geprüft wird. Bitte warten Sie, bis dieser Antrag abgeschlossen ist, bevor Sie einen weiteren Antrag einreichen.',
      reduceLeverageWarn:
        'Bitte beachten Sie: Die Verringerung Ihrer Hebelwirkung kann zur erzwungenen Schließung offener Positionen führen, wenn Ihr Konto nicht über eine ausreichende Margin verfügt.',
      reduceLeverageConfirm: 'Möchten Sie fortfahren?',
      autoRestore: `Da Ihr Kontokapital USD 20.000 übersteigt, beträgt der maximale Hebel, den Sie nutzen können, 500:1. Sie können die Hebelwirkung auf 1.000:1 ändern, wenn Ihr Kontokapital kleiner oder gleich USD 20.000 ist.`,
    },
    pw: {
      error: 'Sie konnten Ihr Passwort nicht zurücksetzen. Bitte versuchen Sie es später erneut',
      confirm:
        'Ihr Passwort wurde aktualisiert.<br>Sie können nun Ihr neues Passwort verwenden, um auf Ihr MT4/MT5 Trading-Konto zuzugreifen.',
      email:
        '<p class="first_info">Wir haben Ihnen soeben eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts gesendet.</p><p>Bitte überprüfen Sie auch den Spam-Ordner Ihrer E-Mail.</p>',
    },
    activate: {
      question: 'Möchten Sie Ihr Live-Konto erneut aktivieren?',
      confirm: 'Wir haben Ihre Aktivierungsanfrage erhalten.<br>Ihr Konto wird in Kürze aktiviert.',
    },
    accStatus: {
      active: 'Aktiv',
      rejected: 'Abgelehnt',
      inactive: 'Inaktiv',
      processing: 'Bearbeitung',
      activate: 'Aktivieren',
      expired: 'Abgelaufen',
    },
    pro1000: {
      desc1: `Die folgenden Einschränkungen gelten für Konten mit einem Hebel von 1.000:1:`,
      conditions: [
        `Das ausgewählte Konto ist nicht zur Teilnahme an Gutschriften bezogenen Promotionen berechtigt.`,
        `Die Hebelwirkung kann vom System automatisch auf 1:500 reduziert werden, wenn das gewählte Kontokapital USD 20.000 oder den entsprechenden Gegenwert in anderer Währung übersteigt. Wenn es einen offenen Trade gibt, wird vor der Anpassung des Hebels eine E-Mail-Benachrichtigung verschickt.`,
        `Die Obergrenzen für die Hebelwirkung können je nach den geltenden Regulierungen variieren.`,
        `Bitte beachten Sie, dass der Hebel von 1:1.000 nicht für Finanzinstrumente verfügbar ist, die zu Kryptowährungen, Aktien, Silber und Rohstoffen gehören. Die Margin für diese Instrumente wird in Übereinstimmung mit den festen Marginanforderungen der Instrumente gehalten und ist nicht betroffen von der 1: 1.000 Hebelwirkung.`,
      ],
      desc2: `Mit dem Absenden dieses Antrags erkenne ich an, dass ich mir bewusst bin, dass eine hohe Hebelwirkung ein großes Risiko für mein Kapital darstellt und die Möglichkeit besteht, dass ich einen Verlust erleide, der größer als das von mir eingezahlte Kapital ist und nicht auf dieses begrenzt ist.`,
      desc3: `Ich stimme zu, dass der Emittent der Produkte berechtigt ist, meine Tradingaktivitäten zu überprüfen und die Hebelwirkung auf meinem Handelskonto nach eigenem Ermessen und ohne vorherige Warnung oder Benachrichtigung anzupassen.`,
      desc4: `Ich verstehe, dass ich durch die Wahl eines hohen Hebels mit einer größeren Margin traden, aber möglicherweise auch erhebliche Verluste erleiden kann.`,
    },
  },
  paymentDetails: {
    subHeader: 'EINE ZAHLUNGSMETHODE HINZUFÜGEN',
    payMethod: 'Zahlungsmethode',
    tip: `<p>Wählen Sie Ihre bevorzugte Zahlungsmethode für zukünftige Ein- und Auszahlungen aus dem untenstehenden Menü.</p><br />
      <p><b>Bitte beachten Sie:</b> Um sicherzustellen, dass Ihre Ein- und Auszahlungsanträge zeitnah bearbeitet werden können, ist es aufgrund der AML/CTF-Bestimmungen erforderlich, dass der Name auf der von Ihnen gewählten Zahlungsmethode lautet
      <b>{individualUserName}</b>.</p>`,
    result: {
      defaultSuccessMessage:
        'Vielen Dank, dass Sie die Zahlungsmethode für Ihr Trading-Konto eingereicht haben. Wir bearbeiten derzeit Ihre Anfrage und werden uns in Kürze mit Ihnen in Verbindung setzen.',
      unionPayCardSuccessMessage:
        'Union Pay Card Details erfolgreich übermittelt, Ihre neue Bankverbindung wird verfügbar sein, sobald sie genehmigt wurde',
    },
    history: { header: 'ZAHLUNGSMETHODEN' },
    cc: {
      field: {
        ccNum: 'Kreditkartennummer',
        first6: 'Erste Sechs',
        last4: 'Letzte Vier',
        withrawlable:
          'Der maximal verfügbare Auszahlungsbetrag für diese Karte ist {amount}. Um Geldbeträge abzuheben, die das verfügbare Limit überschreiten, wählen Sie bitte eine andere Karte oder Auszahlungsmethode.',
      },
      formValidation: { upload: 'Kreditkartenfoto ist erforderlich' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'Abrufen Ihres Einzahlungsstatus... {countDown} Sekunden übrig.',
      successMsg: 'Vielen Dank für Ihre Einzahlung, Ihr Guthaben wird in Kürze auf Ihrem Konto erscheinen.',
      rejectMsg: 'Abgelehnt',
      failureMsg: 'Ihre Einzahlung war nicht erfolgreich. Bitte versuchen Sie es erneut.',
      message:
        'Vielen Dank für die Einzahlung auf Ihr Konto. Ihre Einzahlung wird in Kürze manuell vom Kontoteam der {platform} genehmigt. Das Geld wird auf Ihr Konto eingezahlt, sobald es genehmigt wurde.',
      defaultException:
        'Ihre Transaktion wird derzeit bearbeitet. Um Ihre Einzahlung bei {platform} zu bestätigen, kontaktieren Sie bitte {email}.',
    },
    failed: {
      countDownMsg: 'Abrufen Ihres Einzahlungsstatus... {countDown} Sekunden übrig.',
      rejectMsg: 'Abgelehnt',
    },
  },
  deposit: {
    alert: {
      depositBonus50: `Sie sind berechtigt, einen 50 % Einzahlungsbonus zu erhalten. Aktivieren Sie ihn vor einer Einzahlung!`,
    },
    default: {
      deposit: 'Einzahlung',
      reference: 'Ihre Referenz',
      sendReceipt: 'Beleg senden',
      sendReceiptTip:
        'Sobald Sie die Überweisung durchgeführt haben, füllen Sie bitte das untenstehende Formular aus und fügen Sie einen Screenshot, ein Foto oder eine gescannte Kopie des Überweisungsbelegs bei, auf dem Ihr vollständiger Name, Ihre Kontonummer und der Einzahlungsbetrag deutlich zu erkennen sind, damit wir Ihre Transaktion bearbeiten können.',
      reminder: 'Bitte warten Sie 1-2 Werktage, bis das Geld auf Ihrem Konto gutgeschrieben wird.',
      successMsg:
        'Das Geld wird auf Ihrem Trading-Konto erscheinen, sobald wir die Einzahlung erhalten und Ihren Transaktionsbeleg verifiziert haben.',
      alt: 'Alternativ können Sie Ihren Transaktionsbeleg auch per E-Mail an {mailTo} senden, wenn Sie es wünschen.',
      reminderFundAppear:
        'Ihre Gelder werden auf Ihrem Konto erscheinen, sobald wir die Gelder von Ihrem bisherigen Broker erhalten.',
      failed: 'Einzahlung fehlgeschlagen, bitte versuchen Sie es später erneut.',
      fee: 'GEBÜHR',
      instant: 'SOFORT',
      hour: 'STUNDE',
      hours: 'STUNDEN',
      businessDays: 'WERKTAGE',
      withinOneBusinessDays: 'INNERHALB VON 1 WERKTAG',
      secondaryNGN:
        '(Sie können diese Kontonummer für diese Transaktion verwenden, wenn Sie stattdessen die nigerianische Naira (NGN)-Währung bevorzugen.)',
      disclaimer: `<li>Alle Ihre Zahlungen an {platform} sind vertraulich und werden nur für Ein- und Auszahlungen auf und von Ihrem Trading-Konto von {platform} verwendet. Alle E-Zahlungen werden durch SSL (Secure Socket Layer)-Technologie verarbeitet und verschlüsselt, um die Sicherheit zu gewährleisten.</li>
        <li>{platform} akzeptiert grundsätzlich keine Zahlungen von Dritten. Alle Einzahlungen auf Ihr Handelskonto müssen auf den gleichen Namen lauten wie Ihr {platform}-Kundenportal-Konto.</li>
        <li>Der Kunde versteht, dass das Einreichen eines Einzahlungsantrags keine Annahme von Geldern durch {platform} darstellt. Wenn Ihr Konto kurz vor oder in einem Margin Call ist, stellen Sie bitte sicher, dass Sie andere Vorkehrungen getroffen haben.</li>
        <li>{platform} ist nicht verantwortlich für Verzögerungen oder Fehler, die durch Zahlungssysteme verursacht werden.</li>
        <li>{platform} ist nicht verantwortlich für Gebühren, die das sendende Finanzinstitut oder dritte Zahlungsanbieter Ihnen für das Senden von Zahlungen in Rechnung stellen können.</li>
        <li>Die Kunden erkennen an, dass sie aufgefordert werden können, Informationen über die Quelle(n) der Einzahlung(en), das Vermögen der Kunden im Allgemeinen und alle anderen Informationen, die {platform} für notwendig erachtet, um die Quelle der Gelder bei der Bearbeitung eines Einzahlungsantrags zu identifizieren, zur Verfügung zu stellen und erklären sich damit einverstanden, alle von {platform} geforderten Informationen zur Verfügung zu stellen. Wenn Sie die erforderlichen Angaben nicht machen, kann dies zu erheblichen Verzögerungen bei der Bearbeitung Ihrer Anträge führen. {platform} haftet nicht für Verspätungen oder Verluste, die sich aus dieser Verzögerung ergeben.</li>`,
      channels: {
        creditOrDebit: 'Kredit-/Debitkarte',
        i12BankTransfer: 'Internationale Banküberweisung',
        sticPay: 'SticPay',
        unionPay: 'Union Pay Einzahlung',
        unionPayCN: 'China Union Pay Überweisung',
        skrill: 'Skrill/Moneybookers',
        vietInstant: 'Vietnam Sofortige Banküberweisung',
        thaiInstant: 'Thailändische Sofortüberweisung ',
        nigeriaInstant: 'Nigeria Sofortüberweisung',
        malayInstant: 'Malaysia Sofortüberweisung',
        indonesiaInstant: 'Indonesia Sofortüberweisung',
        philippineInstant: 'Philippine Sofortüberweisung',
        mobilePay: 'Mobiles Bezahlen',
        indiaInstant: 'Indien Sofortige Banküberweisung',
      },
      rate: {
        rate: 'Umrechnungskurse({oldCurrency}/{newCurrency}):',
        result: {
          rateError: 'Kursabruf {oldCurrency} zu {newCurrency} fehlgeschlagen.',
          channelError: 'Abrufen des Zahlungskanals fehlgeschlagen.',
        },
      },
      cn: { rate: 'Umrechnungskurse (USD/RMB): ', amt: 'RMB: ' },
      upgrade:
        'Der Zahlungskanal wird derzeit gewartet und steht nur Benutzern zur Verfügung, die zuvor erfolgreich Geld über diesen Kanal eingezahlt haben.',
      depositInstruction: 'Anleitung zur Einzahlung',
      depositNote: 'Einzahlungshinweis',
    },
    method: {
      desc: `Wie Sie Ihr Konto mit {method} aufladen`,
      inst1: `{number}.   Füllen Sie das untenstehende Formular aus und drücken Sie auf 'Absenden'.`,
      inst2: `{number}.   Wählen Sie die Bank auf der Bankauswahlseite aus und melden Sie sich bei Ihrer {country} Online-Bank an.`,
      inst3: `{number}.   Folgen Sie den Anweisungen und tätigen Sie die Einzahlung über Ihre Online/Mobile Bank.`,
      inst4: `{number}.   Sie werden dann zu einem weiteren Formular weitergeleitet, um Ihre Kartendaten einzugeben.`,
      inst5: `{number}.   Nachdem Sie Ihre Kartendaten eingegeben haben, drücken Sie auf 'Senden'.`,
      inst6: `{number}.    Sie werden zurück zum Kundenportal weitergeleitet und Ihre Transaktion wird abgeschlossen.`,
      inst7:
        '{number}.   Wenn Ihre Währung nicht {currency} ist, kann die Bank Ihnen eine Umrechnungsgebühr gemäß dem aktuellen Bankwechselkurs berechnen.',
      inst8: `{number}.   Kreditkarteneinzahlungen in EUR, GBP, AUD, SGD, NZD, HKD, JPY und USD sind auf einen Höchstbetrag von $ 5.000 USD pro Transaktion begrenzt. Kreditkarteneinzahlungen in CAD werden bis zu $ 6.500 CAD pro Transaktion akzeptiert.`,
      inst9: `{number}.   Maximal $ 1.500 USD pro Einzahlung`,
      inst10: `{number}.   Eine Seite mit Zahlungsdetails wird angezeigt.`,
      inst11: `{number}.   Wählen Sie die Bank auf der Bankauswahlseite aus und melden Sie sich bei Ihrer Online-Bank an.`,
      inst12: `{number}.   Wir können Zahlungen in CAD akzeptieren. Maximal $ 9.999 CAD pro Einzahlung.`,
      form: { header: `{method} Formular` },
    },
    reminder: {
      inst1: `Füllen Sie das untenstehende Formular aus und drücken Sie auf 'Absenden'.`,
      inst2: 'Melden Sie sich bei Ihrem {method}-Konto an.',
      inst3: 'Folgen Sie den Anweisungen im {method}-Konto, um Ihre Zahlung abzuschließen.',
      inst4: 'Sie werden dann zu einem weiteren Formular weitergeleitet, um Ihre Kartendaten einzugeben.',
      inst5: 'Nachdem Sie Ihre Kartendaten eingegeben haben, drücken Sie auf "Senden".',
      inst6: 'Sie werden zurück zum Kundenportal weitergeleitet und Ihre Transaktion wird abgeschlossen.',
      inst7: 'Sie werden dann zu einem neuen Fenster weitergeleitet, das eine {method}-Wallet-Adresse anzeigt.',
      inst8:
        'Kopieren Sie die {method}-Wallet-Adresse, loggen Sie sich dann in Ihre persönliche {method}-Wallet ein und überweisen Sie den gewünschten Betrag an die {method}-Adresse.',
      inst9: 'Scannen Sie den QR-Code, um Ihre Mobile-Banking-Anwendung zu öffnen.',
      inst10: 'Folgen Sie den Anweisungen auf Ihrem mobilen Gerät, um Ihre Einzahlung abzuschließen.',
      inst11:
        'Bitte stellen Sie sicher, dass Sie online über die weitergeleitete Zahlungsseite bezahlen, um einen Verlust von Geldern zu vermeiden.',
      inst12: 'Wählen Sie die Bank auf der Bankauswahlseite und melden Sie sich bei Ihrer Online-Bank an.',
      inst13: 'Folgen Sie den Anweisungen und tätigen Sie die Zahlung über Ihre Online/Mobile Banking.',
      inst14:
        'Sobald Sie die Überweisung getätigt haben, füllen Sie bitte das untenstehende Formular aus und fügen Sie eine Kopie des Überweisungsbelegs bei, aus dem Ihr vollständiger Name, Ihre Kontonummer und der Einzahlungsbetrag hervorgehen, damit wir Ihre Transaktion bearbeiten können.',
      inst15: 'Bitte warten Sie 2-5 Werktage, bis das Geld auf Ihrem Konto gutgeschrieben wird.',
      inst16: 'Bitte rechnen Sie mit 1 Werktag, bis das Geld auf Ihrem Konto gutgeschrieben wird.',
      inst17:
        'Bitte stellen Sie sicher, dass Ihr vollständiger Name, Ihre Kontonummer und der Einzahlungsbetrag deutlich zu sehen sind, damit wir Ihre Transaktion bearbeiten können.',
      inst18: 'Es kann 1 bis 6 Stunden dauern, bis der Betrag Ihrem Konto gutgeschrieben wird.',
      note1: '{method} Einzahlungen sind auf USD 25.000 pro Transaktion und maximal USD 75.000 pro Tag begrenzt',
    },
    southAfrica: { header: 'Südafrikanische Instant BankÜberweisung', country: 'Südafrikanische' },
    idn: { header: 'Indonesia Sofortüberweisung', country: 'Indonesia' },
    ida: { header: 'Indien Sofortige Banküberweisung', country: 'Indien' },
    nigeria: { header: 'Nigeria Sofortüberweisung', country: 'Nigeria' },
    thailand: { header: 'Thailändische Sofortüberweisung', country: 'Thailand' },
    malay: { header: 'Malaysia Sofortüberweisung', country: 'Malaysia' },
    php: { header: 'Philippinische Sofortige Banküberweisung', country: 'Philippinische' },
    vietnam: { header: 'Vietnam Sofortüberweisung', country: 'Vietnam' },
    korea: { header: 'Südkorea lokale Banküberweisung', country: 'Korea' },
    uganda: { header: 'Uganda lokale Banküberweisung', country: 'Uganda' },
    rwanda: { header: 'Ruanda lokale Banküberweisung', country: 'Ruanda' },
    zambia: { header: 'Sambia lokale Banküberweisung', country: 'Sambia' },
    congo: { header: 'Kongo lokale Banküberweisung', country: 'Kongo' },
    cameroon: { header: 'Kamerun lokale Banküberweisung', country: 'Kamerun' },
    burundi: { header: 'Burundi lokale Banküberweisung', country: 'Burundi' },
    kenya: { header: 'Kenia lokale Banküberweisung', country: 'Kenia' },
    ghana: { header: 'Ghana lokale Banküberweisung', country: 'Ghana' },
    tanzania: { header: 'Tansania lokale Banküberweisung', country: 'Tansania' },
    canada: {
      header: 'Interac E-Transfer',
      note1: 'Wir akzeptieren nur Zahlungen in CAD. Maximal CAD 9.999 pro Transaktion',
    },
    creditOrDebit: {
      header: 'Kreditkarte',
      worldcard: {
        note1:
          'Einzahlungen per Kreditkarte in EUR, GPB und USD sind auf einen Höchstbetrag von USD 20.000 pro Transaktion begrenzt. Einzahlungen per Kreditkarte in EUR, GPB und USD sind auf einen Höchstbetrag von USD 20.000 oder den entsprechenden Gegenwert pro Transaktion begrenzt.',
        note2:
          'Die Anzahl der verschiedenen Karten pro Benutzer beträgt 3. Alle Einzahlungsversuche, die über dieses Limit hinausgehen, werden nicht akzeptiert.',
      },
      virtualPay: {
        note1:
          'Einzahlungen per Kreditkarte in EUR, GBP, AUD, SGD, NZD, HKD, JPY, USC und USD sind auf einen Höchstbetrag von USD 10.000 pro Transaktion begrenzt. ',
        note2:
          'USD 50.000 oder maximal 10 Transaktionen pro Karte innerhalb von 24 Stunden. Alle Einzahlungsversuche, die über dieses Limit hinausgehen, werden nicht akzeptiert.',
      },
      Sdpay: {
        note1:
          'Einzahlungen per Kreditkarte in EUR, GBP und USD sind auf einen Höchstbetrag von USD 2.000 oder entsprechende Währungen pro Transaktion begrenzt. ',
        note2:
          'Maximal 8 Transaktionen pro Tag. Alle Einzahlungsversuche, die über dieses Limit hinausgehen, werden nicht akzeptiert.',
        note3:
          'Bitte beachten Sie, dass für Ihre erstmaligen Zahlungen eine Sperrfrist gelten kann. Bitte haben Sie etwas Geduld, während unser Team Ihre Zahlung verifiziert. Sie können sich auch an unseren Kundensupport wenden, um weitere Unterstützung zu erhalten.',
      },
      maxisPay: {
        note1:
          'Einzahlungen per Kreditkarte in USD sind auf einen Höchstbetrag von USD 5.000 pro Transaktion begrenzt.',
      },
    },
    net: {
      header: 'Neteller',
      note1: 'Wir können Zahlungen in AUD, USD, EUR, CAD und GBP akzeptieren',
      note2: 'Konten können nur in der gleichen Währung wie Ihr MT4-Handelskonto finanziert werden.',
      note3:
        'Neteller konvertiert Geldbeträge automatisch in die Basiswährung Ihres Kontos, wenn die falsche E-Mail-Adresse verwendet wird. Es können Gebühren anfallen.',
      note4: 'Einzahlungen müssen auf denselben Namen wie Ihr PU Prime-Konto lauten.',
    },
    vload: {
      header: 'VLOAD-EINZAHLUNG',
      inst1: '2.   Wir können Zahlungen in USD, EUR und JPY akzeptieren.',
      inst2: `<li>3.   Kaufen Sie einen Vload-Gutschein, klicken Sie, click <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">hier</a>.</li>`,
      form: {
        voucherpin: 'Gutschein-Pin',
        voucherpinReq: 'Gutschein-PIN ist erforderlich',
      },
    },
    bankWireAu: {
      header: 'BANKÜBERWEISUNG: AUSTRALIEN',
      instruction:
        'Gelder können über die Online-Banking-Einrichtung Ihres Finanzinstituts an {platform} überwiesen werden, oder alternativ, indem Sie sich an Ihr Finanzinstitut wenden und die Überweisung an {platform} beantragen.',
      tip:
        'Wählen Sie die Kontowährung, in der Sie einzahlen möchten. Bitte stellen Sie sicher, dass Sie die gleiche Währung wie die Währung Ihres Trading-Kontos wählen.',
      form: {
        bankDetails: 'Bankdaten (nur AUD-Einzahlung)',
        refTip: 'Bitte geben Sie hier Ihre Anmeldenummer an',
        afp: 'Australische Schnelleinzahlung (NUR AUD-Einzahlung im Inland)',
        payIdOrABNNum: 'PayID/ABN-Nummer',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip:
          'Bitte geben Sie Ihre {platform} Trading-Kontonummer in das Referenzfeld für die Banküberweisung ein.',
      },
    },
    bPay: {
      header: 'BPAY EINZAHLUNG',
      option: 'So zahlen Sie mit BPay auf Ihr Konto ein (nur in Australien)',
      inst1:
        '1.   Wählen Sie Ihr MT4/MT5-Konto, um Ihren BPay-Rechnungssteller-Code und Ihre BPay-Referenznummer anzuzeigen.',
      inst2:
        '2.   Geben Sie die Zahlungsdetails, Ihren BPay-Rechnungssteller-Code und Ihre BPay-Referenznummer in Ihr Internet-Banking ein und senden Sie die Zahlung ab.',
      inst3:
        '3.   Kehren Sie auf diese Seite zurück und füllen Sie das folgende BPay-Formular aus, damit wir Ihre Transaktion umgehend bearbeiten können.',
      form: {
        header: 'BPAY-FORMULAR',
        billerCode: 'BPay-Rechnungssteller-Code',
        refNum: 'BPay-Referenznummer',
        receipt: 'BPAY-Überweisungsbeleg',
        reminderNotUploaded:
          'Wir haben festgestellt, dass Sie Ihren Transaktionsbeleg nicht hochgeladen haben. Um Ihre Einzahlung bearbeiten zu können, senden Sie bitte schnellstmöglich eine Kopie des Belegs per E-Mail an {mailTo}.',
        reminderProc:
          'Bitte beachten Sie: Ihre Transaktion kann NICHT bearbeitet werden, bevor wir Ihren Beleg erhalten haben.',
      },
    },
    b2b: {
      header: 'VON BROKER ZU BROKER',
      option: 'So überweisen Sie Gelder von einem anderen Broker zu {platform}',
      inst1: '1.   Laden und vervollständigen Sie den {download}.',
      inst2:
        '2.   Füllen Sie das Online-Formular unten aus und laden Sie das ausgefüllte Formular für die Überweisung von Broker zu Broker hoch.',
      inst3:
        '3.   Senden Sie eine Kopie des ausgefüllten Formulars von Broker zu Broker an Ihren bisherigen Broker, um die Überweisung zu veranlassen.',
      b2bForm: 'Formular für die Überweisung von Broker zu Broker',
      formValidation: {
        upload: 'Das Broker-zu-Broker-Formular ist erforderlich.',
      },
    },
    cc: {
      header: 'EINZAHLUNG PER KREDIT- ODER DEBITKARTE',
      remind:
        'Sollten Sie Schwierigkeiten bei der Einzahlung auf Ihr Konto mit der primären Methode haben, verwenden Sie bitte die sekundäre Kredit-/Debitmethode.',
      field: {
        fundNow: 'Jetzt per Kredit-/Debitkarte einzahlen',
        newCard: 'Neue Karte',
        cardNum: 'Kreditkartennummer',
        nameOnCard: 'Name auf Karte',
        expDate: 'Gültigkeitsdatum',
        cvv: 'Sicherheitscode CVV',
        bankCode: 'Kartenausstellende Bank',
        example: `Einfache Karte`,
      },
      placeholder: {
        bankCode: 'Bitte geben Sie den Namen Ihrer kartenausgebenden Bank ein.',
      },
      cvvTip: 'Die 3 Ziffern, die auf der Rückseite Ihrer Karte angezeigt werden.',
      threeDomainSecQ: 'Unterstützt Ihre Karte 3D-Secure?',
      threeDomainSec:
        'Was ist die 3D-Secure-Authentifizierung? <br /><strong>Die 3D-Secure-Authentifizierung ist eine von Visa und Mastercard anerkannte Methode zur Betrugsprävention.<br /><br />Wenn Ihre Karte 3D-Secure unterstützt, werden Ihre Einzahlungen sofort bearbeitet.</strong>',
      cardUpload:
        'Die Karte, die Sie zur Einzahlung verwenden, wurde nicht mit Ihrem Trading-Konto verknüpft. Bitte laden Sie ein Foto der Vorderseite Ihrer Karte zur Verifizierung hoch. (Bitte decken Sie die mittleren 6 Ziffern ab.)',
      result: {
        success:
          'Wir danken Ihnen. Wir sind gerade dabei, Ihre Kartendaten zu verifizieren. Nach der Verifizierung sollte das Geld innerhalb von 24 Stunden auf Ihrem Konto erscheinen.',
        depositSuccess: 'Vielen Dank für Ihre Einzahlung, Ihr Guthaben wird in Kürze auf Ihrem Konto erscheinen.',
        error:
          'Um die Sicherheit Ihres Kontos zu schützen,<br> haben wir  Ihre Einzahlungen vorübergehend ausgesetzt, da Sie die maximale Anzahl versuchter Einzahlungstransaktionen erreicht haben.<br> Bitte kontaktieren Sie {email}',
      },
      tipOnlyTaiwan: `Sehr geehrter Kunde, bitte beachten Sie, dass sich Abhebungen per Kreditkarte aufgrund der Bankabwicklung verzögern können und die Ankunftszeit vollständig von jeder Bank abhängt. Wenn Sie dringend Geld benötigen, wird empfohlen, andere Methoden zum Ein- und Auszahlen zu verwenden.`,
      reminder: `<li>1. Kreditkarteneinzahlungen mit EUR, GBP und USD sind auf maximal 1,900 USD oder gleichwertige Währungen pro Transaktion begrenzt.</li>
      <li>2. Maximal 8 Transaktionen pro Tag.</li>
      <li>3. Bitte beachten Sie, dass Ihre erstmaligen Zahlungen möglicherweise zurückgestellt werden. Bitte haben Sie etwas Geduld, während unser Team Ihre Zahlung überprüft, oder wenden Sie sich an unser Kundensupport-Team, um weitere Unterstützung zu erhalten.</li>`,
    },
    fasa: {
      description: 'Wie Sie Ihr Konto mit FasaPay aufladen',
      form: { header: 'FASAPAY FORMULAR' },
    },
    intSwift: {
      header: 'BANKÜBERWEISUNG: INTERNATIONALE SWIFT',
      desc:
        'Gelder können über die Online-Banking-Einrichtung Ihres Finanzinstituts an {platform} überwiesen werden, oder alternativ, indem Sie sich an Ihr Finanzinstitut wenden und die Überweisung an {platform} beantragen.',
      desc2:
        'Sobald Sie die Überweisung getätigt haben, füllen Sie bitte das untenstehende Formular aus und fügen Sie eine Kopie des Überweisungsbelegs bei, aus dem Ihr vollständiger Name, Ihre Kontonummer und der Einzahlungsbetrag hervorgehen, damit wir Ihre Transaktion bearbeiten können.',
      alertSAandNGA:
        'Wenn Sie eine andere Währung als US-Dollar besitzen, tauschen Sie die Überweisung bitte in US-Dollar um, bevor Sie die Überweisung vornehmen. Bei Direktüberweisung trägt der Kunde die Kursdifferenz und die zu diesem Zeitpunkt anfallenden Bearbeitungsgebühren.',
      options: 'Bitte wählen Sie das Trading-Konto, auf das Sie einzahlen möchten.',
      refInfo: 'Bitte geben Sie Ihre {platform} Trading-Kontonummer in das Referenzfeld für die Banküberweisung ein.',
      bussDayInfo: 'Bitte warten Sie 2-5 Werktage, bis das Geld auf Ihrem Konto gutgeschrieben wird.',
      tip:
        'Bitte vergewissern Sie sich, dass auf Ihrem Überweisungsbeleg deutlich Ihr <b>voller Name</b>, Ihre <b>Kontonummer</b> und Ihr <b>Einzahlungsbetrag</b> angegeben sind.',
    },
    bitwallet: {
      header: 'Bitwallet',
      note1: 'Wir akzeptieren Zahlungen in USD, AUD, EUR, USC und JPY',
    },
    astropay: {
      desc: 'Wie Sie Ihr Konto mit ASTROPAY aufladen',
      note1: 'Wir akzeptieren Zahlungen in allen Währungen.',
      form: { header: 'ASTROPAY-FORMULAR' },
    },
    mobile: {
      header: 'Mobiles Bezahlen',
      desc: 'Wie Sie Ihr Konto mit Mobile Pay aufladen',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2: '2.   Scannen Sie den QR-Code, um Ihre Mobile-Banking-Anwendung zu öffnen.',
      inst3: '3.   Folgen Sie den Anweisungen auf Ihrem mobilen Gerät, um Ihre Einzahlung abzuschließen.',
      form: {
        payMethod: 'Zahlungsmethode:',
        rate: 'Umrechnungskurse (USD/CNY):',
        amt: 'CNY:',
      },
      formValidation: {
        cnyAmt: 'CNY Betrag kann nicht höher sein als $ {limit}.',
      },
    },
    crypto: {
      header: 'Kryptowährung Einzahlung',
      choose: 'Bitte wählen Sie Ihre bevorzugte Kryptowährung',
      proceedQuestion: 'Möchten Sie mit Ihrer <br /><b>{method}</b> Einzahlung fortfahren?',
      note:
        'Bitte beachten Sie, dass wir keine Einzahlungen oder Auszahlungen über BUSDT vornehmen können. Bitte stellen Sie sicher, dass die Adresse und die Kryptowährung mit der von uns akzeptierten Chain und Währung übereinstimmen, sonst könnten Sie die Gelder verlieren.',
      note2:
        'Bitte beachten Sie, dass wir keine Einzahlungen oder Auszahlungen über BUSDT oder ETH vornehmen können. Bitte vergewissern Sie sich, dass die Adresse und die Kryptowährung mit der von uns akzeptierten Chain und Währung übereinstimmen, da Sie sonst Ihr Geld verlieren könnten. Wir haften nicht für Schäden, die durch etwaige Fehler entstehen.',
      remark:
        'Bitte beachten Sie, dass wir keine Einzahlungen oder Auszahlungen über BUSDT vornehmen können. Bitte stellen Sie sicher, dass die Adresse und die Kryptowährung mit der von uns akzeptierten Chain und Währung übereinstimmen, sonst könnten Sie die Gelder verlieren.',
      inst2: '2.    Sie werden dann zu einem neuen Fenster weitergeleitet, das eine {method}-Wallet-Adresse anzeigt.',
      inst3:
        '3.    Kopieren Sie die {method}-Wallet-Adresse, loggen Sie sich dann in Ihre persönliche {method}-Wallet ein und überweisen Sie den gewünschten Betrag an die {method}-Adresse.',
      inst4:
        'Bitte beachten Sie: BITCOIN-Einzahlungen werden von B2BinPay zu dem zum Zeitpunkt der Transaktion angegebenen Wechselkurs verarbeitet.',
      bitcoin: {
        button: 'BITCOIN',
        desc: 'Wie Sie Ihr Konto mit BITCOIN aufladen',
      },
      usdt: {
        button: 'USDT (OMNI)',
        desc: 'Wie Sie Ihr Konto mit USDT (OMNI) aufladen ',
        inst1: '1.    Füllen Sie das untenstehende Formular aus und drücken Sie auf "Senden".',
        inst2: `2.    Sie werden dann zu einem neuen Fenster weitergeleitet, das eine USDT (OMNI)-Wallet-Adresse anzeigt.`,
        inst3: `3.    Kopieren Sie die USDT (OMNI)-Adresse und loggen Sie sich dann in Ihre persönliche USDT (OMNI)-Wallet ein und überweisen Sie den gewünschten Betrag an die USDT (OMNI)-Adresse.`,
        inst4: `Bitte beachten Sie: USDT (OMNI)-Einzahlungen werden von B2BinPay zu dem zum Zeitpunkt der Transaktion angegebenen Umrechnungskurs bearbeitet.`,
      },
      usdt_CPS: {
        button: 'USDT(ERC20)',
        desc: 'Wie Sie Ihr Konto mit USDT (ERC20) aufladen',
      },
      form: {
        header_bitcoin: 'BITCOIN-FORMULAR',
        header_usdt: 'USDT (OMNI)-FORMULAR',
        header_usdt_CPS: 'USDT (ERC20) FORMULAR',
      },
    },
    skrill: {
      header: 'SKRILL/MONEYBOOKERS EINZAHLUNG',
      desc: 'Wie Sie Ihr Konto mit Skrill aufladen',
      note1: 'Wir akzeptieren Zahlungen in USD, EUR, GBP und CAD',
      note2: 'Konten können nur in der gleichen Währung wie Ihr MT4-Handelskonto finanziert werden.',
      note3:
        'Skrill konvertiert Geldbeträge automatisch in die Basiswährung Ihres Kontos, wenn die falsche E-Mail-Adresse verwendet wird. Es können Gebühren anfallen.',
      note4: 'Einzahlungen müssen auf denselben Namen wie Ihr PU Prime-Konto lauten.',
      form: { header: 'SKRILL-FORMULAR', email: 'Skrill/Moneybookers E-Mail' },
      reminder: `<li>Bitte beachten Sie:</li>
      <li>* Auf Konten kann nur in der gleichen Währung wie Ihr MT4-Trading-Konto eingezahlt werden. Skrill konvertiert Gelder automatisch in die Basiswährung Ihres Kontos, wenn die falsche E-Mail verwendet wird. Es können Gebühren anfallen.</li>
      <li>* Einzahlungen müssen auf denselben Namen wie Ihr {platform}-Konto lauten.</li>`,
    },
    sticPay: {
      header: 'STICPAY-EINZAHLUNG',
      desc: 'So zahlen Sie mit STICPAY auf Ihr Konto ein',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2: '2.   Melden Sie sich bei Ihrem SticPay-Konto an.',
      inst3: '3.   Folgen Sie den Anweisungen im SticPay-Konto, um Ihre Zahlung abzuschließen.',
      form: { header: 'STICPAY-FORMULAR', email: 'SticPay E-Mail' },
      reminder: `<li>Bitte beachten Sie:</li>
      <li>* Auf Konten kann nur in der gleichen Währung wie Ihr MT4-Trading-Konto eingezahlt werden. SticPay konvertiert Gelder automatisch in die Basiswährung Ihres Kontos, wenn die falsche E-Mail verwendet wird. Es können Gebühren anfallen.</li>
      <li>* Einzahlungen müssen auf denselben Namen wie Ihr {platform}-Konto lauten.</li>`,
    },
    unionPay: {
      header: 'Union Pay Einzahlung',
      desc: 'Wie Sie Ihr Konto bei UnionPay finden',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2: '2.   Wählen Sie die Bank auf der Bankauswahlseite und melden Sie sich bei Ihrer Online-Bank an.',
      inst3: '3.   Folgen Sie den Anweisungen und tätigen Sie die Zahlung über Ihre Online/Mobile Banking.',
      form: { header: 'UNION PAY FORMULAR' },
    },
    unionPayCN: {
      header: 'China Union Pay Überweisung',
      desc: 'Wie Sie Ihr Konto mit China Union Pay Transfer aufladen können',
      inst1: 'Eine Seite mit den Zahlungsdetails wird angezeigt',
      inst2:
        'Möglicherweise werden Sie bei der Einzahlung nach Ihrer Mitgliedsnummer gefragt. Bitte geben Sie die Nummer Ihres Handelskontos als Mitgliedsnummer ein',
      note1:
        'Der Mindesttransaktionsbetrag pro Einzahlung beträgt CNY 500 und der maximale Transaktionsbetrag pro Einzahlung CNY 49.999.',
      note2:
        'Bitte beachten Sie, dass das Limit für eine Einzahlung je nach Marktlage von Zeit zu Zeit aktualisiert werden kann.',
      note3:
        'Kopieren Sie bitte nicht dauerhaft die angezeigte Bankverbindung des Begünstigten, da diese von Zeit zu Zeit aktualisiert werden kann.',
      form: {
        header: 'CHINA UNION PAY FORMULAR',
        holderName: 'Name des UnionPay-Karteninhabers:',
        holderNameInfo:
          'Bitte stellen Sie sicher, dass der Name, den Sie oben eingeben, mit dem Namen des Karteninhabers Ihrer Bank übereinstimmt',
      },
    },
    paypal: {
      header: 'Paypal',
      desc: 'Wie Sie Ihr Konto mit PayPal aufladen',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2: '2.   Melden Sie sich bei Ihrem PayPal-Konto an.',
      inst3: '3.   Folgen Sie den Anweisungen im PayPal-Konto, um Ihre Zahlung abzuschließen.',
      inst4: 'Bitte beachten Sie: Wir können keine Zahlungen von einer dritten Partei akzeptieren.',
      form: { header: 'PAYPAL-FORMULAR' },
    },
    cardpay: {
      desc: 'Wie Sie auf Ihr Konto mit Kreditkarte einzahlen',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2: '2.   Sie werden dann zu einem weiteren Formular weitergeleitet, um Ihre Kartendaten einzugeben.',
      inst3: '3.   Nachdem Sie Ihre Kartendaten eingegeben haben, drücken Sie auf "Senden".',
      inst4: '4.   Sie werden zurück zum Kundenportal weitergeleitet und Ihre Transaktion wird abgeschlossen.',
      form: { header: 'KREDITKARTENFORMULAR' },
    },
    tinkbit: { inst5: '5.   Maximal $ 1.500 USD pro Einzahlung' },
    perfectMoney: {
      inst1: '2.   Melden sie sich bei ihrem Perfect Money-Konto an.',
      inst2: '3.   Folgen sie den aufforderungen in ihrem Perfect Money-Konto, um ihre zahlung abzuschließen.',
    },
    indiaBankTransfer: {
      desc1:
        'Sobald Sie die Überweisung getätigt haben, füllen Sie bitte das untenstehende Formular aus und fügen Sie eine Kopie des Überweisungsbelegs bei.',
      desc2:
        'Bitte stellen Sie sicher, dass Ihr vollständiger Name, Ihre Kontonummer und der Einzahlungsbetrag deutlich zu sehen sind, damit wir Ihre Transaktion bearbeiten können.',
      bussDayInfo: 'Es kann 1 bis 6 Stunden dauern, bis der Betrag Ihrem Konto gutgeschrieben wird.',
    },
  },
  restrictDeposit: {
    header: 'WICHTIGER HINWEIS',
    info: 'Leider nehmen wir keine Einzahlungen von Kunden mit Wohnsitz in Kanada mehr an.',
  },
  withdraw: {
    title: 'Gelder auszahlen',
    default: {
      accountDetail: 'Kontodetails',
      withdrawalAmount: 'Auszahlungsbetrag',
      example: 'Beispiel einer Kredit-/Debitkarte',
      alert:
        'Aufgrund von AML-Gesetzen muss die Methode, die Sie zur Auszahlung verwenden, dieselbe sein wie die, die Sie für Ihre Einzahlung verwendet haben. Wenn der Auszahlungsbetrag weniger als 100 Einheiten der Basiswährung Ihres Kontos beträgt, wird Ihnen von der Bank eine Bearbeitungsgebühr von 20 Einheiten berechnet.',
      how: 'Wie haben Sie Ihr Trading-Konto finanziert？',
      selectCC: 'Kreditkarte auswählen',
      selectBA: 'Bankkonto auswählen',
      anotherCC: 'Kreditkarte manuell eingeben',
      anotherBA: 'Bankkonto hinzufügen',
      remember: 'Mein Konto merken',
      success: 'Ihre Auszahlungsanforderung war erfolgreich und wird in Kürze bearbeitet.',
      ccFail:
        'Um die Sicherheit Ihres Kontos zu schützen, haben wir Ihre Auszahlungen wurden vorübergehend ausgesetzt, da Sie die maximale Anzahl versuchter Einzahlungstransaktionen erreicht haben. Bitte kontaktieren Sie {email}.',
      checkPosFailed: 'Position prüfen fehlgeschlagen',
      fetchTradeAccFailed: 'Abrufen des Trading-Kontos fehlgeschlagen',
      chooseAnother: `<p>Maximaler Auszahlungsbetrag für diese Karte: 0</p>
      <p>Bitte wählen Sie eine andere Karte oder eine andere Zahlungsmethode.</p>`,
      notEnoughFunds: 'Der eingegebene Betrag überschreitet das Limit für diese Karte.',
      failed: 'Auszahlung fehlgeschlagen. Bitte versuchen Sie es später erneut.',
      unfortunate:
        'Leider sind wir nicht mehr in der Lage, Auszahlungen auf Kreditkarten zu verarbeiten. In Zukunft werden Auszahlungen per Banküberweisung auf das von Ihnen angegebene Bankkonto abgewickelt.',
      noCardWarn:
        'Sie haben keine verfügbare Karte für eine Auszahlung, bitte verknüpfen Sie Ihre Karte zuerst in den Auszahlungsdetails.',
      equityStop:
        'Leider kann Ihre Auszahlung nicht bearbeitet werden, da sie dazu führt, dass die verfügbare Margin Ihres Kontos unter die Stop-Out-Grenze von 100% fällt.',
      equityMargin:
        'Leider können wir Ihre Auszahlung derzeit nicht bearbeiten, da Ihr Konto vollständig abgesichert ist.',
      equityContinue:
        'Wir haben festgestellt, dass Sie derzeit offene Positionen haben. Es kann sein, dass Sie nach dem Einreichen des Auszahlungsantrags einen Stop-Out erleben, bitte bestätigen Sie dies.',
      disclaimer: `<li>Wenn Sie Gelder auszahlen, während Sie offene Positionen haben, wird Ihre Margin beeinträchtigt. Bitte stellen Sie sicher, dass Sie offene Positionen schließen oder genügend Gelder auf Ihrem Konto belassen, um einen Margin Call zu vermeiden.</li>
        <li>{platform} akzeptiert grundsätzlich keine Zahlungen an Dritte. Alle von Ihrem Handelskonto ausgezahlten Gelder müssen auf denselben Namen lauten wie Ihr {platform}-Kundenportal-Konto.</li>
        <li>Alle erstmaligen Anträge auf Auszahlungen per Kredit-/Debitkarte, E-Wallet, Moneybookers und Banküberweisung müssen aus Sicherheitsgründen durch die Vorlage eines Kontoauszugs verifiziert werden, der Angaben zum Kontoinhaber und zur Bankverbindung enthält.</li>
        <li>{platform} behält sich das Recht vor, die Bearbeitung eines Auszahlungsantrags zu verweigern, wenn sie berechtigte Gründe zu der Annahme hat, dass das Trading-Konto nach der Bearbeitung des Auszahlungsantrags einen negativen Kontostand aufweisen könnte.</li>
        <li>{platform} behält sich das Recht vor, einen Auszahlungsantrag abzulehnen, wenn das Trading-Konto Gegenstand einer Rückbuchung oder einer anderen laufenden Untersuchung ist.</li>
        <li>{platform} ist nicht verantwortlich für Gebühren, die das empfangende Finanzinstitut oder dritte Zahlungsanbieter Ihnen für den Empfang von Zahlungen in Rechnung stellen können.</li>`,
      YourPaymentGreaterThanAvailableBalance: 'Ihre Zahlung ist größer als das verfügbare Guthaben.',
      blackList: `Leider können Sie derzeit keine Auszahlungsanfrage stellen. Bitte kontaktieren Sie {supportEmail} für weitere Informationen`,
      NetellerChargeAlert:
        'Neteller-Auszahlungen sind auf 10.000 US-Dollar pro Transaktion begrenzt und es fällt eine Gebühr von 2 % (maximal 30 US-Dollar) an.',
      agreePolicy: 'Ich habe die{link}gelesen und stimme ihr zu',
      withdrawPolicy: 'Auszahlungsrichtlinie',
      addWithdrawalMethod: 'Fügen Sie eine Auszahlungsmethode hinzu',
      addSecondaryMethod: 'Fügen Sie eine zweite Methode hinzu',
      withdrawNote:
        'Bitte beachten Sie, dass wir Ihre Auszahlung in erster Linie per Kreditkarte abwickeln werden. Wenn der Betrag auf Ihrer Kreditkarte aufgebraucht ist, bitten wir Sie, eine andere Methode zu wählen, um Ihren Auszahlungsantrag abzuschließen.',
      submitYourWithdrawal: 'Auszahlung einreichen',
      remainingWithdraw:
        'Der verbleibende Auszahlungsbetrag wird auf eine andere Kreditkarte oder Auszahlungsmethode aufgeteilt.',
      onlyWithdrawalCcDesc: `Nur der Kreditkartenbetrag von {ccAmount} wird vollständig ausgezahlt.`,
    },
    fasa: {
      field: { accName: 'FasaPay Kontoname', accNum: 'FasaPay Kontonummer' },
      formValidation: {
        accNameReq: 'FasaPay Kontoname ist erforderlich',
        accNumReq: 'FasaPay Kontonummer ist erforderlich',
      },
      fasaPayChargeAlert: 'FasaPay berechnet für jede Auszahlung eine Transaktionsgebühr von 0.5%.',
    },
    neteller: {
      field: { email: 'Neteller E-Mail' },
      formValidation: { emailReq: 'Neteller E-Mail ist erforderlich' },
    },
    vload: {
      field: {
        email: 'Registrierte Vload E-Mail-Adresse',
      },
      formValidation: {
        emailReq: 'Registrierte Vload-E-Mail-Adresse ist erforderlich',
      },
    },
    bitcoin: {
      field: { address: 'Bitcoin-Wallet-Adresse' },
      formValidation: {
        addressReq: 'Bitcoin-Wallet-Adresse ist erforderlich',
        addressFormat: `Ihre Wallet-Adresse sollte mit „1“ oder „3“ oder „bc1“ beginnen`,
        addressLength: 'Bitcoin-Wallet-Adresse muss zwischen 26 und 42 Zeichen betragen',
      },
    },
    eth: {
      field: { address: 'ETH-Wallet-Adresse' },
      formValidation: {
        addressReq: 'ETH-Wallet-Adresse ist erforderlich',
        addressFormat: 'ETH-Wallet-Adresse ist erforderlich',
        addressLength: 'ETH-Wallet-Adresse muss zwischen 26 und 42 Zeichen betragen',
      },
    },
    usdc: {
      field: { address: 'USDC-Wallet-Adresse' },
      formValidation: {
        addressReq: 'USDC-Wallet-Adresse ist erforderlich',
        addressFormat: 'USDC-Wallet-Adresse ist erforderlich',
        addressLength: 'USDC-Wallet-Adresse muss zwischen 26 und 42 Zeichen betragen',
      },
    },
    usdt: {
      field: { address: 'USDT-Wallet-Adresse' },
      formValidation: {
        addressReq: 'USDT-Wallet-Adresse ist erforderlich',
        trc20WalletFormatIncorrect: `Ihre Wallet-Adresse sollte mit „T1“ beginnen`,
        erc20WalletFormatIncorrect: `Ihre Wallet-Adresse sollte mit „0x“ beginnen`,
      },
    },
    bitwallet: {
      field: { email: 'Bitwallet E-Mail' },
      formValidation: { emailReq: 'Bitwallet E-Mail ist erforderlich' },
    },
    astropay: {
      field: { accountNumber: 'Astropay-Konto' },
      formValidation: { accountNumberReq: 'Astropay-Konto ist erforderlich' },
    },
    paypal: {
      field: { email: 'Paypal-E-Mail' },
      formValidation: { emailReq: 'Paypal E-Mail ist erforderlich' },
    },
    skrill: {
      field: { email: 'Skrill-E-Mail' },
      formValidation: { emailReq: 'Skrill E-Mail ist erforderlich' },
      skrillChargeAlert: 'Skrill erhebt eine Transaktionsgebühr von 1% für jede Auszahlung.',
    },
    sticpay: {
      field: { email: 'SticPay E-Mail' },
      formValidation: { emailReq: 'SticPay E-Mail ist erforderlich' },
    },
    perfectMoney: {
      field: { email: 'Perfect Money E-Mail' },
      formValidation: { emailReq: 'Perfect Money E-Mail ist erforderlich' },
    },
    transfer: {
      info:
        '{platform} wird die Bankgebühr für <u>EINE</u> Auszahlung pro Monat zurückerstatten. Für weitere Auszahlungen innerhalb des Monats fällt eine Bankgebühr von 20 Einheiten Ihrer Auszahlungswährung an.',
      label: {
        upload: 'Bitte laden Sie eine Kopie Ihres Kontoauszugs hoch (aktuell für die letzten 3 Monate).',
      },
      formValidation: {
        upload: 'Bitte laden Sie einen Kontoauszug hoch.',
        emailReq: 'E-Mail ist erforderlich',
      },
    },
    otherMethod: {
      withdrawalMethod: 'Auszahlungsmethoden',
      manageWithdrawalDetails: 'Auszahlungsdetails verwalten',
      verificationCode:
        'Der Verifizierungscode wurde an {email} gesendet. Bitte kontaktieren Sie {supportEmail}, wenn Sie weitere Hilfe benötigen',
    },
  },
  transfer: {
    creditWarn:
      'Wir können Ihren Geldtransfer von dem ausgewählten Konto nicht bearbeiten, da Ihr Konto ein Promotionsguthaben enthält.',
    blackListWarn:
      'Ein Geldtransfer von Ihrem MAM-Konto kann zur Liquidation der verwalteten Positionen führen. Bitte kontaktieren Sie {mailTo}, wenn Sie Gelder von diesem Konto überweisen möchten.',
  },
  changePw: {
    header: 'PASSWORT ÄNDERN',
    field: {
      currPw: 'Aktuelles Passwort',
      newPw: 'Neues Passwort',
      confirmPw: 'Bestätigen Sie das neue Passwort',
    },
    succ:
      'Ihr Passwort wurde aktualisiert. Sie können nun Ihr neues Passwort für den Zugang zum Kundenportal verwenden.',
  },
  promotion: {
    clickToGet: 'Klicken Sie einfach und erhalten Sie Ihren $ 50 Bonus',
    apply: 'JETZT BEANTRAGEN',
    start: 'Jetzt anfangen',
    tnc: `<li> 1. Dieses Angebot gilt für 30 Tage.</li>
    <li> 2. Dieses Angebot gilt für alle Kunden (bestehende & neue), die ein Live-Konto haben, aber noch nie eine Einzahlung getätigt haben.</li>
    <li> 3. Dieses Angebot gilt für Neukunden in den folgenden Gebieten: Frankreich, Vereinigtes Königreich, Taiwan, Italien, Kanada, Belgien, Schweiz, Hongkong, Nigeria, Niederlande, Japan, Luxemburg, Malta, Réunion, Deutschland, Martinique, Spanien, Lettland , Polen, Elfenbeinküste, Dänemark, Brasilien, Neuseeland, Portugal, Insel Man, Südafrika, Türkei, Rumänien, Guadeloupe, Österreich, Irland, Französisch-Guayana, Swasiland, Argentinien, Ukraine, Griechenland, Schweden, Monaco, Ungarn , Haiti, Finnland, Bolivien, Chile, Costa Rica, Kroatien, Tschechische Republik, Estland, Mexiko, Norwegen, Philippinen.</li>
    <li> 4. Dieses Angebot gilt nur für Standardkonten und islamische Standardkonten。</li>
    <li> 5. Es ist verboten, den Bonus zu erhalten und/oder Bonusgelder auf ZWEI ODER MEHR KONTEN mit derselben IP-Adresse/ID/demselben Reisepass zu traden. Unabhängig von der Anzahl der Handelskonten, die ein Kunde bei PU Prime führen kann, gilt die Aktion nur für ein Konto.</li>
    <li> 6. Kunden erhalten USD 50 Guthaben (oder den Gegenwert in anderer Währung), nachdem sie sich erfolgreich für dieses Angebot angemeldet haben, und es ist nicht erforderlich, eine Einzahlung vorzunehmen.</li>
    <li> 7. Die Guthabenbeträge können nicht abgehoben werden. Sie können sich jedoch trotzdem noch einen Teil des Gewinns, den Sie durch das Trading erzielt haben, auszahlen lassen.</li>
    <li> 8. Sie müssen alle offenen Positionen schließen, bevor Sie eine Auszahlung beantragen. Sobald Sie sich für eine Auszahlung entscheiden, wird der Bonus vom Konto abgezogen;</li>
    <li> 9. PU Prime behält sich das Recht vor, die Bedingungen zu ändern oder die Gewährung eines Bonus jederzeit zu verweigern.</li>`,
    selectDesp: '* Bitte wählen Sie das Konto, mit dem Sie an der No Deposit Bonus-Aktion teilnehmen möchten.',
    descp1: 'Möchten Sie mit dem Trading beginnen, ohne von Anfang an eine Investition zu tätigen?',
    descp2:
      'Nutzen Sie Ihren $ 50-Bonus, um einen Gewinn<br> zu erzielen, ohne überhaupt etwas von Ihrem Geld zu riskieren.',
    tnc2:
      '<li> Der Mindestbetrag, den Sie abheben können, beträgt USD 100 (oder den Gegenwert in anderer Währung), und der Höchstbetrag, den Sie abheben können, beträgt USD 200 (oder den Gegenwert in anderer Währung). Alle Gewinne, die über diesen Höchstbetrag hinaus erzielt werden, werden entfernt, wenn wir Ihren Auszahlungsantrag bearbeiten.</li>',
    header: 'PROMOTIONBEDINGUNGEN',
    successful: 'Übermittlung erfolgreich',
    failed: 'Übermittlung fehlgeschlagen',
    referFriends: {
      intro: `Führen Sie die folgenden 3 Schritte aus, um {amount} zu erhalten`,
      step1: 'erster Schritt',
      step1Descp: 'Konto erfolgreich eröffnet',
      step2: 'Zweiter Schritt',
      step2Descp: 'Erfolgreiche Einzahlung',
      step3: 'dritter Schritt',
      step3Descp: 'Geschäftsvolumen',
      totalEanringTitle: `Gesamtauszahlungsbetrag <br/>(USD oder gleichwertige Währung)`,
      totalEarningTip: '* Der Belohnungsbetrag unterliegt der Überprüfung.',
      redeem: 'Extrakt',
      intro2: 'Gleichzeitig können Sie auch Ihre Freunde empfehlen',
      shareLink: 'Teilen Sie Ihren persönlichen Empfehlungslink mit',
      shareLinkDescp:
        'Klicken Sie auf "Teilen", um einen eindeutigen Empfehlungslink zu erhalten und ihn in verschiedene soziale Medien zu kopieren',
      share: 'Teilen',
      total: 'Insgesamt eingelöste Empfehlungen',
      social: 'Teilen Sie Ihren Link über wichtige soziale Medien',
      clients: 'KLIENT',
      table: {
        referralsNo: 'Empfohlene Nummer',
        name: 'Name',
        country: 'Land',
        openAccount: 'Konto erfolgreich eröffnet',
        despositSuccess: 'Erfolgreiche Einzahlung',
        volumeCompleted: 'Geschäftsvolumen',
      },
      totalRedeemableTitle: `Gesamtbetrag der Auszahlung  <br/>(USD oder gleichwertige Währung)`,
      totalRedeemableTip: '* Der Belohnungsbetrag unterliegt der Überprüfung.',
      referrer: 'Referrer',
      referral: 'Schiedsrichter',
      dialog: {
        confirmQ: 'Lösen Sie Ihre Einnahmen ein',
        confirmQ2: 'Der in Ihre Basiswährung umgerechnete Gesamtbetrag ist {amount} {currency}.',
        redeem: 'JETZT ZURÜCKZIEHEN',
        agree: '* Wenn Sie auf Jetzt einlösen klicken, stimmen Sie allen {link} zu.',
        overBonus:
          'Unser System hat festgestellt, dass Sie unseren Höchstbetrag erreicht haben. Für Ansprüche wenden Sie sich bitte an unseren Kundensupport.',
      },
    },
    depositBonus: {
      banner: 'FORDERN SIE IHREN 50% EINZAHLUNGSBONUS EIN!<br>  ERHALTEN SIE BIS ZU 10.000 $',
      depositBonus: 'FORDERN SIE IHREN BONUS EIN UND STEIGERN SIE IHREN TRADING',
      title:
        'Möchten Sie Ihre Kontomarge erhöhen und Ihr Handelsvolumen steigern? Dann finanzieren Sie Ihr Konto noch heute und fordern Sie den 50% Bonus von uns an!',
      fund: 'Auf dein Konto einzahlen',
      tnc: '*Es gelten die Allgemeinen Geschäftsbedingungen, {link}, um sie anzuzeigen  ',
      link: 'klicken Sie hier',
      bonusHistory: 'BONUS-GESCHICHTE',
      overBonus:
        '* Sie haben das Maximum von ${totalLimit} Bonusguthaben erreicht. Zusätzliche Einzahlungen qualifizieren sich nicht mehr für Bonusgutschriften.',
      opt: 'OPT-IN & OPT-OUT BESTÄTIGUNG',
      tick:
        'Bitte deaktivieren Sie das Kontrollkästchen, wenn Sie sich abmelden möchten, oder aktivieren Sie das Kontrollkästchen, wenn Sie sich abmelden möchten.',
      saveChange: 'Änderungen speichern',
      depositDate: 'Einzahlungsdatum',
      credit: 'Anerkennung',
      optIn: 'Anmeldung erfolgreich',
      optOut: 'Abmeldung erfolgreich',
      optInDescription:
        'Bitte klicken Sie auf die Schaltfläche unten, um sich für den 50% Einzahlungsbonus zu entscheiden.',
      optOutDescription: 'Klicken Sie auf das Feld, um die Belohnung auszuordnen.',
      activate: 'aktivieren Sie',
      signOut: 'Ausloggen',
      banner1: 'FORDERN SIE IHREN 50% EINZAHLUNGSBONUS EIN!',
      claimDescription: `<p>Wie behaupten</p>
      <li>1. Aktivieren Sie, bevor Sie einleiten. </li>
      <li>2. Finden Sie Ihr Handelskonto, um sich für die Belohnung zu qualifizieren. </li>
      <li>3. Belohnung wird automatisch auf Ihr Konto finanziert. </li>
      <li>4. Genießen Sie Ihren Handel. </li>`,
      eligibleDescription: `<p>Berechtigung</p>
      <li>1. Die Belohnung muss vor Ihrer Einzahlung aktiviert werden. </li>
      <li>2. Die Belohnung ist nur für Standardkonto, islamisches Standardkonto, Prime Account, islamisches Prime-Konto verfügbar. </li>
      <li>3. Sie können den Einzahlungsbonus von 50% nur einmal beantragen, und alle nachfolgenden Ablagerungen werden nur gemäß dem Verhältnis von 20% berechnet. </li>`,
      optInDialog: `Glückwünsche! Sie haben den 50% Einzahlungsbonus erfolgreich aktiviert.`,
      optOutDialog: `Sie haben sich erfolgreich für den 50% Einzahlungsbonus entschieden.`,
    },
    cryptoCashback: {
      banner: {
        bannerContext: `
          <span class="title">Verdoppeln Sie Ihre Ersparnisse</span></br>
          <span class="desc">Erhalten Sie bis zu USD 10.000 Cashback und NULL Swapgebühren</span>
        `,
        activityBannerContext: `
          <span class="title">Verdoppeln Sie Ihre Ersparnisse</span></br>
          <span class="desc">Den Abwärtstrend shorten oder den Dip kaufen?</span></br>
          <span class="desc">Erhalten Sie bis zu 10.000 $ Cashback, wenn Sie Kryptowährung mit PU Prime handeln!</span>
        `,
      },
      active: {
        title: 'Kryptowährungs-Cashback aktivieren',
        desc: 'Bitte klicken Sie auf die Schaltfläche unten, um die Promotion zu aktivieren und daran teilzunehmen',
      },
      deactive: {
        title: `Kryptowährung Cashback deaktivieren`,
        desc: `Bitte klicken Sie auf die Schaltfläche unten, um die Cashback-Aktion für Kryptowährungen zu deaktivieren und abzuheben.`,
      },
      button: {
        active: 'Aktivieren',
        deactive: 'Deaktivieren',
        submit: 'Absenden',
        fund: 'Auf dein Konto einzahlen',
      },
      claimTitle: 'INANSPRUCHNAHME',
      claimDescriptions: [
        `Aktivieren Sie die Promotion, bevor Sie eine Einzahlung vornehmen.`,
        `Zahlen Sie auf Ihr Handelskonto ein, um die Einzahlungsanforderungen zu erfüllen (≥ USD 500).`,
        'Der Cashback pro Lot hängt von Ihrem gesamten Nettoeinzahlungsbetrag ab (siehe Promotionsbedingungen).',
        'Genießen Sie das Trading und erhalten Sie mehr Wert mit jedem Trade!',
      ],
      eligibilityTitle: 'TEILNAHMEBERECHTIGUNG',
      eligibleDescriptions: [
        `Diese Promotion muss aktiviert werden, bevor Sie eine Einzahlung vornehmen.`,
        `Diese Promotion ist nur für Standardkonten und islamische Standardkonten verfügbar. Wenn ein Kunde mehrere in Frage kommende Handelskonten hat, kann nur EIN in Frage kommendes Konto zur Teilnahme an dieser Promotion ausgewählt werden.`,
        `Diese Promotion läuft für 30 Tage ab dem Zeitpunkt Ihrer ersten erfolgreichen Einzahlung nach der Aktivierung.`,
        `Der Cashback basiert auf dem gehandelten Volumen aller Kryptowährungsprodukte mit Ausnahme der folgenden: ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU.`,
        `Der gesamte Cashback ist auf $ 10.000 pro Kunde begrenzt.`,
      ],
      tnc: '*Es gelten die Allgemeinen Geschäftsbedingungen, {link}, um sie anzuzeigen  ',
      link: 'klicken Sie hier',
      message: {
        congratulate: `Herzlichen Glückwunsch!`,
        congratulateContext: `Sie haben die Kryptowährungs-Cashback-Promotion erfolgreich aktiviert.`,
        signedOutPromo: `Wenn Sie auf die Schaltfläche „Bestätigen“ klicken, werden Sie von der Promotion abgemeldet.`,
        deactivePromo: `Sie haben sich erfolgreich von der Kryptowährungs-Cashback-Promotion abgemeldet.`,
      },
      dialog: {
        selectAccountContext: `Bitte wählen Sie das Konto aus, mit dem Sie an der Tradingbonusaktivität teilnehmen möchten`,
        tnc: `Promotionsbedingungen`,
        tncContext: {
          tnc1: `1. Nachdem Sie diese Promotion aktiviert haben, müssen Sie auch Geld einzahlen und mit Kryptowährungen traden, um den entsprechenden Cashback zu erhalten.`,
          tnc1desc: `* Nur neue Einzahlungen während des Aktionszeitraums sind für die Promotion gültig. Davon ausgenommen sind interne Überweisungen, Freundschaftswerbungsprämien und Bargeldanpassungen.`,
          tnc2: `2. Berechtigte Kunden (sowohl Neu- als auch Bestandskunden), die die hierin festgelegten Kriterien erfüllen, können Cashbacks erhalten in Höhe von:`,
          tnc3: `3. Die zugelassenen Produkte: Alle Kryptowährungsprodukte außer ALGUSD, MTCUSD, XRPUSD, BCHUSD, XLMUSD, EOSUSD, BTCBCH, BTCETH, BTCLTC, BTCXAU, ETHBCH, ETHLTC, ETHXAU.`,
          tnc4: `4. Das effektive Tradingvolumen: Die neu eröffneten und geschlossenen Orders während des Promotionszeitraums. Positionen, die weniger als 5 Minuten offen sind, gesperrte Positionen und abgesicherte Positionen zählen nicht als gültige Trades für diese Promotion.`,
          tnc5: `5. Dauer der Promotion: 30 Tage nach der ersten erfolgreichen Einzahlung durch den Teilnehmer.`,
          tnc5desc: `* Wenn der Kunde die Promotion aktiviert und keine Einzahlung vorgenommen hat, endet die Promotion ebenfalls automatisch nach 30 Tagen.`,
          tnc6: `6. Dieses Angebot kann nicht in Verbindung mit einem anderen Angebot genutzt werden. (Ausgenommen Kampagne „Auch Sie können ein Trader sein", Freundschaftswerbung und 50 % Einzahlungsbonus.)`,
        },
        agreeTnc: `Ich habe die Promotionsbedingungen gelesen und stimme ihnen zu.`,
      },
      field: {
        netDeposit: `Nettoeinzahlung`,
        usddesc: `USD (oder Währungsäquivalent)`,
        cashback: `Cashback`,
        standardLot: `Standard-Lot`,
        date: `Datum`,
        depositAmount: `Einzahlungsbetrag`,
        withdrawAmount: `Auszahlungsbetrag`,
        netDepositAmount: `Nettoeinzahlungsbetrag`,
        rebateLot: `Rabatt/Lot`,
        tradingDate: `Datum des Trades`,
        tradingLot: `Getradete Lot(s)`,
        cashRebate: `Bargeld-Rabatt`,
      },
      depositHistory: `EINZAHLUNGSHISTORIE`,
      tradingRewardHistory: `HISTORIE DER TRADINGBELOHNUNGEN`,
    },
    xmasMakeAWish: {
      banner: {
        bannerContext: `
          <div class="title">Gewinnen Sie Preise im Wert von bis zu $ 5.000!</div>
          <div class="desc">Bei Verlosung mit Gewinngarantie</div>
        `,
      },
      active: {
        title: 'AKTION „WÜNSCH DIR WAS“ AKTIVIEREN',
        desc: 'Bitte klicken Sie auf die Schaltfläche unten, um die Weihnachtsaktion „Wünsch dir was“ zu aktivieren',
      },
      deactive: {
        title: 'Weihnachtsaktion „Wünsch dir was“',
        desc: 'Bitte klicken Sie auf die Schaltfläche unten, um die Weihnachtsaktion „Wünsch dir was“ zu deaktivieren',
      },
      button: {
        active: 'Aktivieren',
        deactive: 'Deaktivieren',
        submit: 'Absenden',
        fund: 'Zahlen Sie Geld auf Ihr Konto ein',
        enterLuckyDraw: 'An der Glücksziehung teilnehmen',
        redeem: 'Einlösen',
        redeemed: 'Eingelöst',
      },
      claimTitle: 'SO SIND SIE DABEI',
      claimDescriptions: [
        'Aktivieren Sie die Aktion, bevor Sie eine Einzahlung vornehmen.​',
        'Zahlen Sie Geld auf Ihr Handelskonto ein, um die Einzahlungsanforderungen für das von Ihnen gewünschte Geschenk zu erfüllen, und führen Sie dann die entsprechende Anzahl von Trades aus (siehe Geschenketabelle unten).',
        'Wenn Sie teilnahmeberechtigt sind, haben Sie die Chance, eines der Geschenke in Ihrer entsprechenden Stufe zu gewinnen (siehe Geschenketabelle unten).',
        'Klicken Sie auf die Schaltfläche „An der Glücksziehung teilnehmen“, um für Ihr Geschenk zu würfeln.',
        'Die gewonnenen Geschenke werden aufgezeichnet, und alle Geschenke werden 7 Tage nach Ende der Aktion verteilt.',
      ],
      eligibilityTitle: 'TEILNAHMEBERECHTIGUNG',
      eligibleDescriptions: [
        'Teilnahmeberechtigte Kontotypen: Dieses Angebot gilt NUR für Standardkonten und islamische Standardkonten, beide auf MT4/MT5.',
        'Wenn ein Kunde mehrere Standardkonten hat, kann er nur ein Standardkonto auswählen, mit dem er an dieser Aktion teilnimmt.',
        'Teilnahmeberechtigte Länder: Alle außer Festland China',
        'Einzahlungen und Transaktionen müssen während des Aktionszeitraums getätigt werden, um gültig zu sein.',
        'Gültiges Tradingvolumen: Forex-, Gold-, Silber- und Rohöl-Positionen, die länger als 5 Minuten gehalten wurden.',
      ],
      moreDetail: 'Weitere Einzelheiten über die Preisvergabe finden Sie in der {prize}.',
      prize: 'Liste der Preise hier',
      tnc: '*Es gelten die Allgemeinen Geschäftsbedingungen. Klicken Sie {link}, für die Anzeige von',
      link: 'hier',
      luckyDraw: {
        description: [
          `Drücken Sie die Schaltfläche „Glücksziehung“, um <span class="font-bold">einen</span> der oben genannten Preise zu gewinnen!`,
          'Nicht die Geschenke, nach denen Sie suchen? Fahren Sie mit den Einzahlungen und dem Trading fort, um die von Ihnen gewünschte Stufe zu erreichen.',
        ],
        mainTitle: 'Ihre aktuelle Geschenkestufe:',
        commingSoon: {
          title: `Wünschen Sie sich etwas`,
          content: `Nehmen Sie demnächst an der Ziehung Mitte November teil.`,
        },
        noChanceToDraw: {
          title: `Jetzt einzahlen`,
          content: `Zahlen Sie mindestens $ 200 auf Ihr Konto ein, um an der Glücksziehung teilzunehmen.`,
        },
        endPromotion: {
          title: `Die Glücksziehung ist beendet`,
          content: `Vielen Dank für Ihre Teilnahme! Stellen Sie sicher, dass Sie Ihre Geschenke bis zum 31. März 2023 eingelöst haben.`,
        },
      },
      currentDepositLots: `Aktuelle Einzahlung/Getradete Lots`,
      transactionHistory: `TRANSAKTIONSHISTORIE`,
      transactionHistoryDesc: `*Die Einzahlungstransaktion ist der eingezahlte Betrag und der Einzahlungswert, der für die Teilnahme an der Glücksziehung abgezogen wird.`,
      giftRedemption: `GESCHENKEINLÖSUNG`,
      giftRedemptionDesc: [
        `Wir werden Sie für die Einlösung des/der physischen Geschenke(s) erneut kontaktieren.`,
        `*Alle Preise können nur vom 02. Februar 2023 bis zum 02. März 2023 eingelöst werden.`,
      ],
      field: {
        depositAmount: `Einzahlungsbetrag`,
        lotsTraded: `Getradete Lots`,
        date: `Datum`,
        type: `Typ`,
        deposit: `*Einzahlung`,
        tradingLots: `Trading-Lots`,
        prizeWon: `Gewonnene(r) Preis(e)`,
        creditBonus: `Gutschriftsbonus`,
        cashVoucher: `Bargeldgutschein`,
        physicalRewards: `Physische Belohnungen`,
        depositTradingVolume: `Einzahlung / Trading-Lots`,
        gifts: `Geschenke (USD)`,
        accountID: `Konto-ID`,
        current: `Aktuell`,
        luckyDrawDeposit: `Einzahlung`,
      },
      dialog: {
        selectAccountContext: `Bitte wählen Sie das Konto aus, auf dem Sie Ihren Gutschriftsbonus erhalten möchten (nur MT4/MT5-Konto)`,
        tnc: 'ALLGEMEINE GESCHÄFTSBEDINGUNGEN',
        tncContext: {
          tnc1: `1. Kunden müssen diese Aktion zunächst erfolgreich in ihrem Kundenportal aktivieren, dann Geld einzahlen und wie erforderlich traden, um die entsprechenden Geschenke zu erhalten.`,
          tnc2: `2.	Effektives Tradingvolumen: Standard-Lots für Währungspaare, Gold, Silber, Rohöl-Spot und Rohöl-Futures. Die Orders müssen während des Aktionszeitraums eröffnet und geschlossen werden. Positionen, die weniger als 5 Minuten offen sind, gesperrte Positionen und abgesicherte Positionen zählen nicht als gültige Trades für diese Promotion.`,
          tnc3: `3.	Die Statistiken und das Ordervolumen stammen aus dem MT4-Zeitraum 01.11.2022 bis 31.01.2023.`,
          tnc4: `4.	Wenn das Geschenk aufgrund höherer Gewalt nicht gekauft werden kann, z.B. weil das Produkt nicht mehr erhältlich oder nicht mehr vorrätig ist, kann der Kunde beantragen, den Marktpreis des Geschenks in bar zu erhalten. Dieser Preis basiert auf dem US-Dollar-Preis (oder dem entsprechenden Währungsäquivalent).`,
          tnc5: `5.	Dieses Angebot kann nicht in Verbindung mit einem anderen Angebot genutzt werden. （100 Tradingbonus，20 % exklusiver Bonus, 50 % Einzahlungsbonus，VN 20 % Einzahlungsbonus).`,
        },
        agreeTnc: `Ich habe die Allgemeinen Geschäftsbedingungen gelesen und akzeptiere diese`,
        prizeList: `Liste der Preise`,
        luckyDrawTransaction: `GLÜCKSZIEHUNGSTRANSAKTION`,
        xmasGift: `HERZLICHEN GLÜCKWUNSCH ZU IHREM WEIHNACHTSGESCHENK!`,
      },
      message: {
        congratulate: 'Herzlichen Glückwunsch!',
        congratulateContext: `Sie haben die Weihnachts-aktion „Wünsch dir was“ erfolgreich aktiviert`,
        signedOutPromo: `Wenn Sie auf die Schaltfläche „Bestätigen“ klicken, werden Sie von der Aktion abgemeldet!`,
        deactivePromo: `Sie haben sich erfolgreich von der Weihnachtsaktion „Wünsch dir was“ abgemeldet.`,
        redeemAmount: `Der Betrag wird Ihnen auf Ihrem Konto gutgeschrieben!`,
      },
      tableContent: {
        luckyDraw: `Glücksziehung`,
        depositTrade: `Einzahlung/Trade`,
      },
    },
    firstTimeDeposit: {
      banner: {
        bannerContext: `
          <div class="title">Erhalten Sie <span class='bg_yellow'>Sofortbargeld</span> auf Ihre erste Einzahlung</div>
          <div class="desc">Erhalten Sie bis zu USD 50 in bar, wenn Sie Ihre erste Einzahlung bei PU Prime tätigen!</div>
        `,
      },
      active: {
        title: `Aktivieren Sie IHREN FTD Cashback`,
        desc: `Bitte klicken Sie auf die Schaltfläche unten, um die Promotion zu aktivieren und an ihr teilzunehmen.`,
      },
      deactive: {
        title: `Deaktivieren Sie IHREN FTD Cashback`,
        desc: `Bitte klicken Sie auf die untenstehende Schaltfläche, um die FTD Cashback-Promotion zu deaktivieren und nicht teilzunehmen.`,
      },
      button: {
        active: `Aktivieren`,
        deactive: `Deaktivieren`,
        fund: `Zahlen Sie Geld auf Ihr Konto ein`,
        redeem: `Einlösen`,
      },
      claimTitle: `SO SIND SIE DABEI`,
      claimDescriptions: [
        `Aktivieren Sie die Promotion, bevor Sie eine Einzahlung vornehmen.`,
        `Zahlen Sie Geld auf Ihr Handelskonto ein, um die Einzahlungsanforderungen für den gewünschten Cashback zu erfüllen (≥ 200 USD oder das entsprechende Währungsequivalent).`,
        `Der Wert des Cashback-Gutscheins hängt von der Höhe Ihrer ersten Einzahlung ab (siehe Cashback-Tabelle in den Promotionsbedingungen).`,
        `Traden Sie ≥ 2 Lots Devisen, Gold, Silber oder Öl, um Ihren Cashback-Gutschein zu aktivieren.`,
        `Ihr aktivierter Gutschein kann nun eingelöst werden. Dieser Betrag wird in Ihrem Kontostand ausgewiesen.`,
      ],
      eligibilityTitle: `TEILNAHMEBERECHTIGUNG`,
      eligibleDescription1: `Teilnahmeberechtigte Kontotypen: Dieses Angebot gilt NUR für Standardkonten und islamische Standardkonten, beide auf MT4/MT5.`,
      eligibleDescription2: `Wenn ein Kunde mehrere Standardkonten hat, kann er nur ein Standardkonto auswählen, mit dem er an dieser Promotion teilnimmt.`,
      eligibleDescription3: `Teilnahmeberechtigte Länder: Alle außer Festland-China`,
      eligibleDescription4: `Teilnahmeberechtigte Kunden: Neue und bestehende Kunden, die keine Einlagenhistorie haben, einschließlich Kunden, die unter IBs/CPAs fallen.`,
      eligibleDescription4Desc: `*Persönliche Handelskonten von IB/CPAs sind nicht teilnahmeberechtigt.`,
      eligibleDescription5: `Die erste Einzahlung muss während des Promotionszeitraums getätigt werden.`,
      eligibleDescription6: `Orders, die weniger als 5 Minuten gehalten werden, gelten für die Promotion nicht als gültig.`,
      tnc: `*Es gelten die AGB, {link} zur Ansicht`,
      link: `hier klicken`,
      dialog: {
        selectAccountContext: `Bitte wählen Sie das Konto aus, mit dem Sie an der Tradingbonusaktivität teilnehmen möchten`,
        tnc: `Promotionsbedingungen`,
        tncContext: {
          tnc1: `1. Nachdem Sie diese Aktion aktiviert haben, müssen Sie außerdem Geld einzahlen und 2 Standard-Lots traden, um den entsprechenden Cashback zu erhalten.`,
          tnc1desc: `*Nur neue Einzahlungen, die während des Promotionszeitraums getätigt werden, sind für diese Aktion gültig. Interne Überweisungen, Freundschaftswerbeprämien und Bargeldanpassungen sind davon ausgenommen.`,
          tnc2: `2.	Nur die erste Einzahlung, die während dieses Zeitraums getätigt wird, wird für die Berechnung des Cashbacks berücksichtigt.`,
          tnc3: `3.	Teilnahmeberechtigte Kunden (sowohl Neu- als auch Bestandskunden), die die hier genannten Kriterien erfüllen, können einen Cashback erhalten in Höhe von:`,
          tnc4: `4.	Die teilnahmeberechtigten Produkte: Forex, Gold, Silber, Öl.`,
          tnc5: `5.	Das effektive Tradingvolumen: Die neu eröffneten und geschlossenen Orders während des Promotionszeitraums. Positionen, die weniger als 5 Minuten offen sind, gesperrte Positionen und abgesicherte Positionen zählen nicht als gültige Trades für diese Promotion.`,
        },
        agreeTnc: `Ich habe die Promotionsbedingungen gelesen und akzeptiere sie`,
        grossDeposit: `Bruttoeinzahlung USD`,
        grossDepositDesc: `oder entsprechendes Währungsäquivalent`,
        cbVoucher: `Cashback-Gutschein`,
        tableDesc: `* Kunden können einen Bargeldgutschein im Wert von bis zu $ 50 (oder dem entsprechenden Gegenwert in anderer Währung) einlösen.`,
      },
      voucher: {
        title: `FTD Cashback`,
        accountID: `Konto-ID`,
        accumulateVolume: `Tradingvolumen akkumulieren`,
        standardLots: `Standard-Lots`,
        desc: `Traden Sie ≥ 2 Lots Devisen, Gold, Silber oder Öl, um ihn zu aktivieren`,
        clickForRedeem: `Klicken Sie auf die Schaltfläche unten, um ihn an Ihren Kontostand zu senden.`,
        ftdLessThan200: `Ihre erste Einzahlung ist geringer als der erforderliche Mindestbetrag.`,
      },
      message: {
        congratulate: `Herzlichen Glückwunsch!`,
        congratulateContext: `Sie haben die FTD Cashback-Promotion erfolgreich aktiviert.`,
        signedOutPromo: `Wenn Sie auf die Schaltfläche „Bestätigen“ klicken, werden Sie von der Aktion abgemeldet!`,
        deactivePromo: `Sie haben die FTD Cashback-Promotion erfolgreich deaktiviert.`,
        successRedeem: `Sie haben Ihren FTD-Cashback erfolgreich eingelöst.`,
      },
    },
    notification: {
      ndb: {
        inst1: 'Sehr geehrter Kunde,',
        inst2: `Wir erinnern Sie freundlich daran, dass die Bonusaktion ohne Einzahlung am <span>{date} (GMT+3)</span> endet.`,
        inst3:
          'Bitte beachten Sie, dass gemäß den Allgemeinen Geschäftsbedingungen der Aktion der Bonus von Ihrem eingezahlten Konto nach Beendigung der Aktion automatisch entfernt wird. Bei Konten ohne Einzahlung oder Transaktionsaufzeichnungen werden der Bonus und das Guthaben ebenfalls automatisch entfernt, sobald die Aktion beendet ist.',
        inst4: `Wenn Sie Fragen haben oder Hilfe benötigen, wenden Sie sich bitte per Live-Chat, E-Mail: <span>{mail}</span>, oder Telefon <span>{phone}</span> an unser Support-Team.`,
        cantInerTransfer:
          'Da Sie an unserer NDB-Kampagne teilnehmen, können wir Ihre Überweisungsanfrage nicht bearbeiten',
      },
      db: {
        inst1: 'Sehr geehrter Kunde,',
        inst2: `Wir möchten Sie daran erinnern, dass die 50% Einzahlungsbonus-Aktion am <span>{date} (GMT+3)</span> endet.`,
        inst3:
          'Bitte beachten Sie, dass gemäß den Allgemeinen Geschäftsbedingungen der Aktion der Bonus von Ihrem eingezahlten Konto nach Beendigung der Aktion automatisch entfernt wird. Bei Konten ohne Einzahlung oder Transaktionsaufzeichnungen werden der Bonus und das Guthaben ebenfalls automatisch entfernt, sobald die Aktion beendet ist.',
        inst4: `Wenn Sie Fragen haben oder Hilfe benötigen, wenden Sie sich bitte per Live-Chat, E-Mail: <span>{mail}</span> oder Telefon <span>{phone}</span> an unser Support-Team.`,
      },
    },
    editSuccess: '',
  },
  downloads: {
    mt4: 'METATRADER 4',
    mt4Desc: 'Laden Sie MetaTrader 4 für PC, Mac, Webbrowser, Smartphones und Tablets herunter.',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android/Tablet',
    web: 'WebTrader',
    mt5: 'METATRADER 5',
    mt5Desc: 'Laden Sie MetaTrader 5 für PC, Mac, Webbrowser, Smartphones und Tablets herunter.',
  },
  forgetPwReq: {
    tip: 'Geben Sie die E-Mail-Adresse Ihres Kontos ein, um Ihr Passwort zurückzusetzen.',
    formValidation: { emailReq: 'E-Mail für Anmeldung ist erforderlich' },
    succ:
      'Wir haben Ihnen soeben eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts gesendet.<br>Bitte überprüfen Sie<br>auch den Spam-Ordner Ihrer E-Mail.',
  },
  loginClient: {
    header: 'KUNDENPORTAL',
    failedMasseage: 'Versuchen Sie es erneut',
    existingClientPortalAccess:
      'Wie wir sehen, haben Sie bereits einen Zugang zum Kundenportal. Melden Sie sich mit Ihrer E-Mail an, um ein neues Konto zu eröffnen!',
    footer: `Risikowarnung: Das Traden mit Differenzkontrakten (CFDs) birgt ein hohes Risiko für Ihr Kapital und kann zu Verlusten führen. Sie sollten nur mit Geld handeln, das zu verlieren Sie sich leisten können. Das Traden mit CFDs ist möglicherweise nicht für alle Anleger geeignet. Bitte stellen Sie sicher, dass Sie die damit verbundenen Risiken vollständig verstehen und geeignete Maßnahmen ergreifen, um diese zu bewältigen.<br><br><br>
    {platformEntityName} ist autorisiert und reguliert durch die Financial Services Authority of Seychelles mit der Lizenz Nr. SD050.<br>
    PU Prime LLC ist autorisiert und reguliert durch die Financial Services Authority von St. Vincent und den Grenadinen mit der Lizenz Nr. 271 LLC 2020.<br>
    The entities above are duly authorized to operate under the PU Prime brand and trademark.<br>`,
    formValidation: { emailReq: 'E-Mail-Adresse ist erforderlich' },
    invailidRafLink: `Der verwendete Empfehlungslink ist ungültig. Registrieren Sie sich unten für ein neues Konto.`,
  },
  myProfile: {
    changeDetails:
      'Wenn Sie Ihre bestehenden Profildaten aktualisieren möchten, zögern Sie nicht, {mailTo} zu kontaktieren.',
  },
  openAdditAcc: {
    chooseTradePlat: 'WÄHLEN SIE EINE TRADING-PLATTFORM',
    chooseAccType: 'WÄHLEN SIE EINEN KONTOTYP',
    chooseCurrency: 'WÄHLEN SIE EINE KONTOWÄHRUNG',
    chooseLeverage: 'WÄHLEN SIE DIE HEBELWIRKUNG',
    chooseBalance: 'KONTOSTAND',
    notes: 'ZUSÄTZLICHE HINWEISE',
    currError: 'Sie müssen eine Kontowährung auswählen',
    platErr: 'Sie müssen eine Trading-Plattform auswählen',
    accTypeErr: 'Sie müssen einen Kontotyp wählen',
    leverageErr: 'Sie müssen eine Hebelwirkung wählen',
    balanceErr: 'Sie müssen einen Kontostand wählen',
    succ:
      '<p>Ihr zusätzliches Live-Konto wird gerade eingerichtet und Sie werden in Kürze eine E-Mail mit Ihren Zugangsdaten erhalten.</p> Sie können den aktuellen Status Ihres Antrags auf der Startseite einsehen.',
    byTicking: 'Ich habe die {tnc} gelesen und akzeptiere diese.',
    tnc: 'Geschäftsbedingungen',
    pamm:
      'PAMM-Konten sind nur für Anleger mit einer bestehenden Money Manager-Vereinbarung. PAMM-Konten können von niemandem außer dem Money Manager gehandelt werden',
    pammConfirm: 'Bitte bestätigen Sie, ob Sie diesen Kontotyp weiter auswählen möchten.',
    disclaimer: `<li>Ich bestätige, dass ich die {platform} <a href=https://{regulatorUrl}/company/legal/legal-document/clientserviceagreement/">Kundendienstvereinbarung</a>, die als Teil dieses Antrags online bereitgestellt wird, gelesen und verstanden habe und akzeptiere.</li>
    <li>Ich bestätige außerdem, dass ich die {platform} <a href=https://{regulatorUrl}/company/legal/legal-document/websitetermsandconditions/">Geschäftsbedingungen der Webseite</a>, <a href="https://{regulatorUrl}/company/legal/legal-document/moneylaunderingpreventionpolicy/">Richtlinie zur Geldwäscheprävention</a>, <a href="https://{regulatorUrl}/company/legal/legal-document/riskdisclosure/">Risikoaufklärung</a> und <a href="https://{regulatorUrl}/company/legal/legal-document/privacypolicy/">Datenschutzrichtlinie</a> gelesen und verstanden habe und damit einverstanden bin, daran gebunden zu sein. 
    <li>Ich verstehe, dass {platform} mir keine Anlageberatung zu Transaktionen anbietet.</li>
    <li>Ich verstehe, dass persönliche Informationen, die als Teil dieses Antrags eingereicht werden, verwendet werden, um meine Identität mit einem Authentifizierungsdienst eines Dritten zu verifizieren.</li>
    <li>Ich bestätige, dass die von mir zur Verfügung gestellten und in dieses Formular eingetragenen Informationen korrekt sind und nehme zur Kenntnis, dass ich verpflichtet bin, {platform} im Falle von Änderungen dieser Informationen unverzüglich zu informieren.</li>
    <li>Ich bestätige, dass ich in Bezug auf alle Angelegenheiten im Zusammenhang mit dieser Kundenbeziehung in meinem eigenen Namen, wie in diesem Antrag angegeben, und nicht im Namen eines Dritten gehandelt habe. Dementsprechend sind alle Gelder, die auf dem Konto bei {platform} eingezahlt und getradet werden sollen, meine eigenen Gelder.</li>
    <li>Ich bestätige, dass der von mir gewählte Einzahlungsbetrag meine gesamten finanziellen Verhältnisse berücksichtigt und von mir unter diesen Umständen als verantwortungsvoll angesehen wird. Ich verstehe die innewohnenden Risiken des Tradings von CFDs und dass ich aufgrund der riskanten Natur des Produkts mehr als meine Einzahlung verlieren kann.</li>`,
  },
  openDemoAdditAcc: {
    header: 'Demo-Konto Konfiguration',
    button: 'Demo-Konto eröffnen',
    liveButton: 'Live-Konto eröffnen',
    succ:
      'Ihr zusätzliches Demo-Konto wurde eingerichtet und Sie erhalten in Kürze eine E-Mail mit Ihren Zugangsdaten.',
  },
  resetPassword: {
    header: 'PASSWORT ZURÜCKSETZEN',
    succ:
      'Ihr Passwort wurde aktualisiert. Sie können nun Ihr neues Passwort verwenden, um auf Ihr MT4/MT5-Tradingkonto zuzugreifen.',
    succ2: 'Ihr Passwort wurde aktualisiert.',
    failed: 'Token ist abgelaufen',
    emailNotFound: 'Leider wurde Ihre E-Mail-Adresse nicht erkannt',
  },
  fileUploader: {
    supportedFormat: 'Unterstützte Dateitypen: png, jpg, jpeg, bmp, pdf, doc, docx',
    maxSize: 'Maximale Dateigröße beim Hochladen: {maxSize}MB',
    maxFileNum: 'Maximale Dateianzahl: {maxFileNum}',
  },
  transcationHistory: {
    deposit: 'EINZAHLUNGEN',
    withdraw: 'AUSZAHLUNGEN',
    desc: {
      incomplete:
        'Wenn Sie den Einzahlungsvorgang abbrechen oder nicht abschließen können, wird der Transaktionsstatus als "unvollständig" angezeigt. Sie müssen dann eine neue Einzahlung erstellen, da diese Transaktion nicht wieder aufgenommen werden kann.',
      success: 'Ihre Transaktion war erfolgreich. Ihr Guthaben sollte in Kürze auf Ihrem Konto erscheinen.',
      proc: 'Ihre Transaktion wird gerade bearbeitet. Bitte kontaktieren Sie {mailTo}.',
      failed: 'Ihre Transaktion ist fehlgeschlagen. Bitte kontaktieren Sie {mailTo} für weitere Informationen.',
      rejected: 'Ihre Transaktion wurde abgelehnt. Bitte kontaktieren Sie {mailTo} für weitere Informationen.',
    },
    withdrawDesc: {
      sub: 'Ihre Transaktion wurde abgeschickt. Bitte warten Sie bis zum Abschluss.',
      suc: 'Ihre Transaktion war erfolgreich. Ihr Guthaben sollte in Kürze auf Ihrem Konto erscheinen.',
      proc: 'Ihre Transaktion wird gerade bearbeitet. Bitte kontaktieren Sie {mailTo}.',
      incomp:
        'Ihre Transaktion erfordert zusätzliche Schritte, um abgeschlossen zu werden. Bitte kontaktieren Sie {mailTo} für weitere Informationen.',
      cancel: 'Ihre Transaktion wurde storniert. Bitte kontaktieren Sie {mailTo} für weitere Informationen.',
      fail: 'Ihre Transaktion ist fehlgeschlagen. Bitte kontaktieren Sie {mailTo} für weitere Informationen.',
      rej: 'Ihre Transaktion wurde abgelehnt. Bitte kontaktieren Sie {mailTo} für weitere Informationen.',
    },
    cancelWithdraw: `Möchten Sie die Auszahlungsanfrage stornieren?`,
    cancelError: `Ihre Auszahlung wird gerade bearbeitet. Daher können wir Ihre Stornierungsanfrage nicht annehmen. Für weitere Informationen können Sie uns über die Live-Chat-Funktion erreichen.`,
    dialog: {
      appliedAmount: `Beantragter Betrag`,
      applicationTime: `Zeit der Antragstellung`,
      processedTime: `Zeit der Verarbeitung`,
      accountCurrency: `Kontowährung`,
      creditDeducted: `Abgezogene Gutschrift`,
      processedNote: `Hinweis zur Verarbeitung`,
      bankName: `Name der Bank`,
      bankAccountNumber: `Bankkontonummer`,
      abaSortCode: `ABA/Sort-Code`,
      swiftCode: `Swift-Code`,
      bankStatement: `Kontoauszug`,
      bankAddress: `Adresse der Bank`,
      accountName: `Name des Kontos`,
      accountNumber: `Kontonummer`,
      cardholderName: `Name des Karteninhabers`,
      cardNumber: `Kartennummer`,
      cardExpiryDate: `Ablaufdatum der Karte`,
      walletAddress: `Wallet-Adresse`,
      ewalletEmailAddress: `E-Mail-Adresse der E-Wallet`,
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'Leider kann Ihr Transfer nicht bearbeitet werden, da er dazu führt, dass die verfügbare Margin Ihres Kontos unter das Stop-Out-Niveau von 100 % fällt.',
      equityMargin:
        'Leider können wir Ihren Transfer derzeit nicht bearbeiten, da Ihr Konto durch Hedges vollständig abgesichert ist.',
      equityContinue:
        'Wir haben festgestellt, dass Sie derzeit offene Positionen haben. Es kann sein, dass Sie nach dem Einreichen Ihres Transferantrags einen Stop-Out erleben. Bitte bestätigen Sie, wenn Sie fortfahren möchten.',
      blackList: 'Dieses Konto wurde für Transfers zwischen Konten gesperrt. Bitte kontaktieren Sie {supportEmail}',
    },
    pleaseNote: 'Bitte beachten:',
    suc: 'Ihre Gelder wurden erfolgreich überwiesen.',
    warn1:
      'Sie sind dafür verantwortlich, einen Kontostand beizubehalten, der keinen Margin Call auslöst. Wenn Sie aktuelle offene Positionen haben, stellen Sie bitte sicher, dass Sie die erforderliche Margin auf Ihrem Konto beibehalten. Überweisungen von Geldern auf Konten, die in der gleichen Währung geführt werden, werden sofort bearbeitet.',
    warn2:
      'Wenn Sie Gelder zwischen Konten, die in unterschiedlichen Währungen geführt werden, überweisen möchten, senden Sie eine E-Mail an {mailTo}.',
    warn3: 'Bitte beachten Sie, dass Gelder nicht zwischen schreibgeschützten Konten übertragen werden können.',
  },
  transferHistory: {
    payStatus: {
      processing: 'Bearbeitung',
      paid: 'Bezahlt',
      unpaid: 'Unbezahlt',
      rejected: 'Abgelehnt',
    },
  },
  identityProof: {
    home: {
      header: 'KONTOANTRAG UNVOLLSTÄNDIG',
      content:
        'Hallo {name}, Ihr Kontoantrag ist fast vollständig, aber Sie müssen noch zusätzliche Unterlagen einreichen, um Ihr Konto abzuschließen.',
    },
    shufti: {
      content: `Bitte stellen Sie sicher, dass die angegebenen Daten mit Ihren Ausweisdokumenten übereinstimmen und in der gleichen Sprache sind.`,
      countryOfDocument: `Land wie auf dem Dokument angegeben`,
    },
    popup: {
      header:
        'Hallo {name}, bevor Ihr Live-Konto fertiggestellt werden kann, müssen Sie das folgende Dokument zur Verifizierung hochladen.',
      addressProofSpan: `<strong>Bestätigung der Adresse</strong><br />
      Reichen Sie eines der folgenden Dokumente ein<br /><br />
      - Kopie einer aktuellen Nebenkostenabrechnung (nicht älter als 6 Monate)<br />
      - Kopie eines aktuellen Kontoauszugs (nicht älter als 6 Monate)<br />
      <br />
       WICHTIG! Der Name auf dem Dokument MUSS deutlich Ihren vollen Namen und Ihre aktuelle Adresse zeigen.
      `,
      idProofSpan: `<strong>Ausweis bestätigen</strong><br />
      Wählen Sie eine der folgenden Optionen<br /><br />
      - Kopie eines gültigen Reisepasses<br />
      - Kopie des gültigen Führerscheins<br />
      - Kopie des nationalen Personalausweises (Vorder- und Rückseite)<br />
      <br />
      WICHTIG! Der Name auf dem Dokument MUSS deutlich Ihren vollen Namen zeigen.
      `,
    },
    reasonType: {
      0: 'Die Vorderseite des Dokuments fehlt',
      1: 'Die Rückseite des Dokuments fehlt',
      2: 'Das Dokument ist unklar',
      3: 'Kontoeröffnung Name stimmt nicht überein',
      4: 'Das Dokument ist abgelaufen',
      5: 'Das Dokument ist nicht qualifiziert',
      6: 'Das Dokument ist nicht integriert',
      8: 'Das Dokument ist nicht integriert',
    },
    reason: 'Ihre Unterlagen konnten nicht verifiziert werden,<br> Grund: {reason}. Bitte versuchen Sie es erneut.',
  },
  header: { secPortal: 'Sicherer<br />Kundenbereich' },
  menu: {
    home: 'STARTSEITE',
    accManagement: 'KONTEN',
    liveAcc: 'LIVE-KONTEN',
    openAdditAcc: 'WEITERE KONTEN ERÖFFNEN',
    homeDemo: 'DEMO-KONTEN',
    depositFund: 'GELDER EINZAHLEN',
    withdrawFunds: 'GELDER AUSZAHLEN',
    transferBetweenAccs: 'GELDER ÜBERTRAGEN',
    payDetails: 'ZAHLUNGSDETAILS',
    funds: 'GELDER',
    transactionHis: 'TRANSAKTIONSHISTORIE',
    tranHis: 'ÜBERWEISUNGEN',
    download: 'DOWNLOADS',
    profile: 'PROFIL',
    myProfile: 'MEIN PROFIL',
    cpPassword: 'PASSWORT ÄNDERN',
    contactUs: 'KONTAKT',
    register: 'ANTRAG FORTSETZEN',
    tradingTools: 'PRO-TRADER-WERKZEUGE',
    MARKET_BUZZ: 'MARKET BUZZ',
    ECON_CALENDAR: 'WIRTSCHAFTSKALENDER',
    FEAT_FX: 'VORGESTELLTE IDEEN',
    FX_IDEAS: 'ANALYSTISCHE ANSICHTEN',
    autochartist: 'AUTOCHARTIST',
    promotion: 'PROMOTIONEN',
    noDeposit: 'NO DEPOSIT BONUS',
    referFriends: 'EMPFEHLEN SIE EINEN FREUND',
    depositBonus: '50% Einzahlungsbonus',
    cryptoCashback: 'Kryptowährung Cashback',
    firstTimeDeposit: `FTD Cashback`,
    tradingTool: 'Handelsinstrumente',
  },
  contact: {
    click: 'Klicken Sie jetzt',
    assist: ' für Unterstützung',
    desc:
      'Das professionelle, mehrsprachige Kundensupport-Team von {platform} ist bereit, Ihnen zu helfen und Ihnen eine unvergleichliche Trading-Erfahrung zu bieten.',
    followUs: 'FOLGEN SIE UNS',
    tip: 'Für Kunden, die sich über {email} angemeldet haben',
    liveChat: 'Live-Chat',
  },
  depositResultDialog: {
    msg: 'Haben Sie Ihre Einzahlung erfolgreich abgeschlossen',
  },
  dialog: {
    identityProof: {
      success:
        'Vielen Dank! Wir haben Ihre Unterlagen erfolgreich erhalten und bearbeiten diese derzeit für die Verifizierung.',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: 'KONTOANTRAGSBEARBEITUNG',
      id: 'Ausweis ist',
      poa: 'Adresse ist',
      both: 'Ausweis und Adressnachweis sind',
      content:
        'Ihr Nachweis von {doc} wird derzeit bearbeitet und in Kürze fertiggestellt. Um die Bearbeitung Ihres Live-Kontos zu beschleunigen und eine bevorzugte Eröffnung zu erhalten, können Sie jetzt auf Ihr Konto einzahlen.',
    },
  },
  register: {
    progressStatus: {
      title: 'Fortschritt der Verifizierung',
      estimatedTimeTitle: 'geschätzte Zeit',
      min: 'Minuten',
    },
    tabs: {
      personalDetails: 'Persönliche Details',
      moreAboutYou: 'Mehr über Sie',
      accountConfig: 'Konto-Konfiguration',
      confirmYourId: 'Bestätigen Sie Ihre Identität',
      fundYourAcc: 'Auf Ihr Konto einzahlen',
    },
    btn: {
      next: 'WEITER',
      back: 'ZURÜCK',
      fundnow: 'JETZT EINZAHLEN',
      upload: 'Hochladen',
      done: 'FERTIG',
    },
    authority: {
      dialog1: 'Sie müssen ein Live-Konto haben, um auf diesen Bereich des Kundenportals zugreifen zu können.',
      dialog2:
        'Eröffnen Sie jetzt ein Live-Konto, um den vollen Zugang zu Ihrem Kundenportal freizuschalten, das eine Reihe von Trading-Werkzeugen, Promotionen und vieles mehr enthält.',
      openLiveAcc: 'Live-Konto eröffnen',
    },
    demo: {
      congrad: 'Herzlichen Glückwunsch!',
      title: 'Ihr 60-tägiges MT {platform} DEMO-Konto ist jetzt aktiv',
      title2: 'Details zu Ihrem DEMO-Konto',
      li: { login: 'ANMELDEN', srv: 'Server', expDate: 'Ablaufdatum' },
      emailSent: '* Die Anmeldedaten wurden auch an Ihre registrierte E-Mail gesendet.',
      dl: 'Laden Sie Ihre MT{platform} Trading-Plattform herunter',
      improveQ: 'Möchten Sie Ihr Trading verbessern?',
      improveWay:
        'Eröffnen Sie einfach ein Live-Konto in nur wenigen Minuten, um eine Reihe von exklusiven Trading-Werkzeugen und Promotionen freizuschalten.',
      proTrade: 'Professionelle Marketingberichte',
      customerService: '24/7 Eins-zu-Eins-Kundendienst',
      competitivePromotionals: 'Wettbewerbsfähige Promotions',
      secure: 'Sichere 3D-Transaktionen',
      openLiveAcc: 'Live-Konto eröffnen',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Persönliche Details',
        userTitle: 'Titel',
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail',
        dob: 'Geburtsdatum',
        idType: 'Identifikations-Typ',
        referral: 'Wurden Sie von jemandem empfohlen? (optional)',
        middleName: 'Mittlerer Name',
        nationality: 'Nationalität',
        phoneNum: 'Telefonnummer',
        date: 'Tag',
        month: 'Monat',
        year: 'Jahr',
        placeOfBirth: 'Geburtsort',
        idNum: 'Identifikationsnummer',
        passportNum: 'Reisepass-Nummer',
        driverLicenseNum: 'Führerscheinnummer',
        titleDropdown: {
          mr: 'Herr',
          mrs: 'Frau',
          ms: 'Fräulein',
          miss: 'Fräulein',
          dr: 'Dr.',
          prof: 'Prof.',
        },
        idTypeDropdown: {
          passport: 'Reisepass',
          idCard: 'Nationale ID-Karte',
          driverLicense: 'Führerschein',
        },
      },
      page2: {
        pageTitle: 'HAUPTWOHNSITZADRESSE',
        mainResidency: 'Hauptwohnsitzland',
        streetNumber: 'Hausnummer',
        streetName: 'Straßenname',
        address: 'Adresse',
        provinceOrState: 'Provinz / Bundesland',
        cityOrSuburb: 'Stadt / Vorort',
        postcode: 'Postleitzahl',
        usQuestion:
          'Sind Sie aus steuerlichen Gründen US-Bürger oder haben Sie irgendwelche Verbindungen zu den USA (z.B. Bankkonto, etc.)?',
      },
    },
    moreAboutYou: {
      pageTitle1: 'BESCHÄFTIGUNG UND FINANZIELLE ANGABEN',
      pageTitle2: 'Trading-Kenntnisse und Erfahrungen',
      complianceExplain: 'Die folgenden Fragen werden zur Einhaltung der AML/CTF-Anforderungen erhoben.',
    },
    accountConfig: {
      pageTitle: 'Konto-Konfiguration',
      chooseTradingPlatform: 'Trading-Plattform wählen',
      chooseAccType: 'Kontotyp wählen',
      chooseAccCurrency: 'Kontowährung wählen',
    },
    confirmYourId: {
      pageTitle: 'Bestätigen Sie Ihre Identität',
      description:
        'Als regulierter Forex-Broker sind wir verpflichtet, Ihre Identität zu verifizieren. Bitte laden Sie eines der folgenden Dokumente zur Verifizierung Ihres Identitäts- und Adressnachweises hoch. Alternativ können Sie Ihre Verifizierungsdokumente auch per E-Mail an {mailTo} senden',
      poidTitle: 'Identitätsnachweis',
      porTitle: 'Wohnsitznachweis',
      uploadDescription: 'Wählen Sie einen Dokumententyp und laden Sie ihn hoch',
      note: 'WICHTIG: Der Name auf dem Dokument MUSS deutlich Ihren vollen Namen zeigen.',
      pending: {
        upload: 'Ausstehender Upload',
        verification: 'Ausstehende Verifizierung',
      },
      poidTypes: {
        photoId: 'Lichtbildausweis',
        passport: 'Reisepass',
        drivingLicence: 'Führerschein',
      },
      porTypes: `<li>Nebenkostenabrechnung</li>
      <li>Kontoauszug</li>
      <li>Wohnsitzbescheinigung</li>
      `,
    },
    finishPage: {
      title: '{individualUserName}, vielen Dank, dass Sie ein Live-Konto bei {platformName} beantragt haben!',
      contentDefault: `Wir haben Ihren Antrag erhalten und Ihr Live-Konto <b>{Konto}</b> eingerichtet. <br />
      In Kürze erhalten Sie eine E-Mail an <b>{email}</b> mit Ihren Anmeldedaten und einem Link, um den Status Ihres Kontos einzusehen. <br />`,
      contentWithoutId3WithAccount: `Ihr Live-Trading-Konto wird derzeit bearbeitet und Sie werden in Kürze eine E-Mail mit weiteren Informationen erhalten.<br />
      Um die Bearbeitung Ihres Live-Kontos zu beschleunigen, können Sie jetzt auf Ihr Konto einzahlen und erhalten eine bevorzugte Eröffnung.<br />`,
      contentWithoutId3WithoutAccount:
        'Ihr Live-Trading-Konto wird derzeit bearbeitet und Sie werden in Kürze eine E-Mail mit weiteren Informationen erhalten.<br />',
    },
  },
  autochartist: {
    1: {
      title: `Marktscanner`,
      desc01: `Der weltweit erste Marktscanner, mit dem Sie Ihre Marktbeobachtungsliste nach Handelsmöglichkeiten durchsuchen können.`,
      button01: `Jetzt downloaden`,
    },
    2: {
      title: `Marktbericht`,
      desc01: `Autochartist Market Reports bieten Ihnen eine Momentaufnahme der technischen Aussichten der Märkte vor der Öffnung der großen Finanzzentren.`,
      button01: `Marktberichte anzeigen`,
    },
    3: {
      title: `Autochartist-Forschungsportal`,
      desc01: `Ein umfassendes Rechercheportal, mit dem Sie alle verfügbaren Daten an einem Ort finden.`,
      button01: `Starten`,
      button02: `Benutzerhandbuch`,
      button03: `OTP-Zugriff`,
    },
    4: {
      title: `Handelsmöglichkeiten`,
      desc01: `Überprüfen Sie alle 15 Minuten Updates zu Handelsmöglichkeiten mit neuen Setups, die auf allen Analysetypen von Autochartist basieren.`,
      button01: `Handelsmöglichkeiten anzeigen`,
    },
    5: {
      title: `Autochartist-Mobile-App`,
      desc01: `Erhalten Sie Push-Benachrichtigungen und verfolgen Sie mit hoher Wahrscheinlichkeit gefilterte Handelsmöglichkeiten basierend auf Ihrer Watchlist innerhalb der Handelsplattform.`,
      desc02: `Anmerkungen:`,
      desc03: `Der QR-Code kann von der normalen Kamera gescannt werden, sofern das Telefon des Benutzers über einen eingebauten QR-Scanner verfügt, oder von der mobilen Autochartist-Anwendung aus, indem Sie auf die Schaltfläche "Anmelden mit QR-Code" klicken.`,
      desc04: `Der QR-Code ist nur 10 Minuten gültig, danach muss das Bild für einen neuen QR-Code aktualisiert werden.`,
      desc05: `Der QR-Code ist nur für den einmaligen Gebrauch gültig, danach muss das Bild für einen neuen QR-Code aktualisiert werden.`,
      button01: `Für iOS herunterladen`,
      button02: `Download für Andriod`,
    },
    6: {
      title: `Newsletter-Abonnement`,
      desc01: `Wenn Sie unseren Newsletter abonnieren, erhalten Sie täglich E-Mails von Autochartist, um Ihnen einen Überblick über die globalen Devisenmärkte zu geben. Handel mit der pazifischen Union, Handel mit der Welt.`,
      button01: `Abonnieren`,
    },
    footer: `Autochartist ist das weltweit fortschrittlichste Tool zur automatischen Identifizierung von Handelsmöglichkeiten in Form von Chartmustern, Fibonacci-Mustern und Key Level. Das proprietäre System von Autochartist ermöglicht es seinen Suchmaschinen, Tausende von Finanzinstrumenten 24 Stunden am Tag zu überwachen und Handelsmöglichkeiten aufzudecken, sobald sie auftreten. Darüber hinaus bietet Autochartist auch die Volatilitätsanalyse als Funktion, mit der Sie das Risiko und die Volatilität der von Ihnen gehandelten Instrumente besser einschätzen können.`,
  },
  responseMsg: {
    410: 'Parametervalidierung fehlgeschlagen',
    411: 'Falsches Passwort',
    420: 'Benutzer existiert nicht',
    421: 'Passwortrücksetzung verweigert',
    490: 'Bitte geben Sie keine doppelten Karteninformationen ein',
    500: 'Es ist ein Fehler aufgetreten.',
    501: 'Antrag fehlgeschlagen. Bitte versuchen Sie es später erneut.',
    505: 'Datei-Upload fehlgeschlagen',
    520: 'Anmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.',
    521: 'Es ist bereits ein Benutzer auf diesem Gerät angemeldet!',
    522: 'Bitte melden Sie sich erneut an',
    523: 'Bitte melden Sie sich erneut an',
    524: 'Leider wurde Ihre E-Mail-Adresse nicht erkannt',
    525: 'Falscher Benutzername oder falsches Passwort',
    527: 'Benutzer ist kein IB',
    528: 'Benutzer existiert nicht',
    529: 'Falscher Benutzername oder falsches Passwort',
    540: 'Ihr Trading-Konto konnte nicht gefunden werden',
    541: 'Kann Rabatt-Konto nicht finden',
    542: 'Sie dürfen kein zusätzliches Konto eröffnen',
    544: 'Sie können kein weiteres Konto beantragen, bis Ihr Identitätsnachweis verifiziert wurde',
    550: 'Falsche Kontonummer',
    551: 'Ihr Guthaben ist nicht ausreichend',
    553: 'Sie können aufgrund des Guthabens auf Ihrem Konto nicht auszahlen/überweisen. Bitte kontaktieren Sie {email} für weitere Hilfe.',
    554: 'Der angeforderte Betrag ist Null oder nicht gültig',
    562: 'Wir konnten Ihre Zahlung nicht verarbeiten, bitte versuchen Sie es erneut. Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte per Live-Chat oder E-Mail {email}',
    564: 'Land stimmt nicht mit dem Zahlungskanal überein',
    565: 'Falsche Bankleitzahl',
    566: 'Zahlungsmethode nicht gefunden',
    568: 'Auf dieses Konto kann keine Einzahlung vorgenommen werden, bitte wenden Sie sich an info@puprime.com',
    581: 'Sie haben sich erfolgreich für die Promotion angemeldet. Bitte beachten Sie, dass Sie eine Einzahlung vornehmen müssen, um Geld auszahlen zu können.',
    582: 'Da Sie an der Kampagne teilnehmen und offene Positionen haben, können wir Ihren Auszahlungsantrag nicht bearbeiten.',
    584: 'Sie haben keine anwendbaren Rabatte',
    587: 'Unzureichendes Guthaben wenden Sie sich bitte an den Kundendienst',
    590: 'Transfer-Out-Konto nicht gefunden',
    591: 'Kann Einbuchungskonto nicht finden',
    593: 'Wir können Ihren Geldtransfer von dem ausgewählten Konto nicht bearbeiten, da Ihr Konto Guthaben enthält.',
    594: 'Geldtransfer von diesem Konto ist nicht erlaubt',
    595: 'Da Sie an unserer Kampagne teilnehmen, können wir Ihre Überweisungsanfrage nicht bearbeiten',
    647: 'Verifizierungscode ist nicht korrekt',
    1001: 'Ihr Konto steht vorübergehend nicht zur Verfügung, bitte kontaktieren Sie Ihren Account Manage',
    session_timeout: 'Sitzungszeitüberschreitung, Weiterleitung zur Anmeldeseite.',
    vload: {
      4019: 'Gutscheintyp oder Wert wird nicht akzeptiert',
      4071: 'Betrugsverdacht',
      4072: 'Gutschein in Verwendung',
      4073: 'Gutschein ist nicht aktiv',
      4074: 'Gutschein ist abgelaufen',
      4075: 'Gutschein wird nicht gefunden',
      4080: 'Gutschein hat zu wenig Wert',
      4444: 'Ungültiger Gutschein verwendet. Währung stimmt nicht überein.',
      5000: 'Store-Daten sind nicht verfügbar',
    },
  },
  tradingTool: {
    mt4: {

    },
    pamm: {

    },
    copytrade: {

    }
  },
  activity: {
    myActivity: '',
    allActivity: '',
    activityContent: '',
    applyNow: '',
    activityStatus1: '',
    activityStatus2: '',
    activityStatus3: '',
    noActivity: '',
  }
}
