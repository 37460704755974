export default {
  common: {
    liveChat: { desc: 'تحتاج مساعدة ؟ دردشة مباشرة' },
    country: { international: 'دولي', australia: 'استراليا' },
    verification: { toSlide: 'قم بالتمرير لإكمال الصورة' },
    field: {
      nameOnCard: 'الاسم على البطاقة',
      cardholderName: `اسم حامل البطاقة`,
      first4Digits: 'أول 4 أرقام على البطاقة',
      last3Digits: 'آخر 3 أرقام على البطاقة',
      first6Digits: 'أول 6 أرقام على البطاقة',
      last4Digits: 'آخر 4 أرقام على البطاقة',
      bankName: 'اسم البنك',
      bankAddress: 'عنوان البنك',
      branchAddress: 'عنوان الفرع',
      bankBranch: 'فرع بنك',
      bankCity: 'مدينة البنك',
      bankProvince: 'مقاطعة البنك',
      bankBeneficiaryName: 'اسم البنك المستفيد',
      bankBeneAccName: 'اسم حساب المستفيد المصرفي',
      bankBeneAccNum: 'رقم حساب المستفيد المصرفي',
      bankBeneAddress: 'عنوان المستفيد',
      bankAccNum: 'رقم الحساب المصرفي',
      bankAccName: 'اسم الحساب المصرفي',
      bankHolderAddress: 'عنوان صاحب الحساب',
      address: 'العنوان',
      accName: 'اسم الحساب  ',
      accNum: 'رقم الحساب',
      accNumTo: 'رقم الحساب',
      bsbNum: 'رقم الـ BSB',
      swift: 'رقم الـ SWIFT',
      ifscCode: 'كود الـ IFSC',
      swiftNonAUD: 'رمز الـ SWIFT (غير حسابات AUD)',
      amt: 'الكمية',
      amtUSD: 'الكمية (بالدولار الامريكي)',
      availableAmount: 'المبلغ المتاح',
      transitNumber: 'رقم العبور',
      institutionNumber: 'رقم المؤسسة',
      imptNotes: 'ملاحظات هامة',
      yes: 'نعم',
      no: 'لا',
      payMethod: 'طرق الدفع :',
      expDate: 'تاريخ الانتهاء',
      bsb: 'BSB',
      abaSortCode: 'ABA / كود الفرز:',
      country_region: 'البلد / المنطقة',
      currLeverage: 'الرافعة المالية الحالية',
      accEquity: 'سيولة الحساب',
      newLeverage: 'الرافعة المالية المطلوبة',
      currPw: 'كلمة المرور الحالية',
      newPw: 'كلمة المرور الجديدة',
      confirmPw: 'تأكيد كلمة المرور الجديدة',
      loginEmail: 'ايميل الدخول',
      email: 'الايميل',
      pw: 'كلمة المرور  ',
      accType: 'نوع الحساب',
      name: 'الاسم  ',
      emailAdd: 'البريد الالكتروني',
      phone: 'رقم الهاتف',
      dob: 'تاريخ الميلاد',
      nat: 'الجنسية',
      country: 'بلد الاقامة',
      street: 'رقم الشارع / اسم الشارع',
      suburbOrCity: 'الضاحية / المدينة',
      provinceOrState: 'المقاطعة / الولاية',
      postcode: 'الرمز البريدي',
      empStat: 'الحالة الوظيفية',
      annIncome: 'الدخل السنوي',
      save: 'استثمار',
      source: 'مصدر الدخل',
      industry: 'المجال',
      statusDef: 'تعريفات الوضع',
      month: 'الشهر',
      year: 'السنة',
      select: 'اختار',
      completed: 'اكتمل',
      incomplete: 'غير مكتمل',
      submitted: 'تم ارساله',
      successful: 'ناجح',
      processing: 'متابعة',
      cancelled: 'تم الغاءه',
      failed: 'فشلت',
      rejected: 'تم الرفض',
      upload: 'تحميل',
      pending: `ريثما`,
      ccNum: 'رقم بطاقه الائتمان',
      ccExpireDate: 'تاريخ الانتهاء',
      bankCardNumber: 'رقم البطاقة المصرفية',
      cardHolderName: 'إسم صاحب البطاقة',
      reservedMobileNumber: 'رقم الهاتف المحمول للبنك',
      nationalId: 'بطاقة الهوية الوطنية',
      cardPhoto: 'صورة البطاقة (أمامية)',
      notes: 'ملاحظات',
      bankAccOrIBAN: 'رقم الحساب المصرفي / IBAN',
      selectWithdrawCard: 'حدد بطاقة السحب الخاصة بك',
      updateCardPhoto: 'يرجى تحميل صورة بطاقتك (أمامي)',
      actualAmount: 'الكمية الفعلية',
      resetBalTo: 'إعادة تعيين الرصيد إلى',
      ipChangeWarning: 'احصل على رسالة بريد إلكتروني تنبيهية عندما يسجل أي شخص الدخول إلى حسابك من موقع مختلف',
      enterVerificationCode: 'أدخل رمز التحقق',
      alias: 'كليك المستعار',
      tips: '',
      pleaseInput: '',
      confirmClose: '',
      notBind: '',
      infoDetails: '',
      loginWarn: '',
      noDataNow: '',
      hasAccountAlready: '',
    },
    column: {
      date: 'التاريخ',
      type: 'النوع',
      server: 'السيرفر',
      status: 'الحالة  ',
      comment: 'التعليق',
      accNum: 'رقم الحساب',
      currency: 'العملة',
      equity: 'مبلغ الحساب',
      leverage: 'الرافعة',
      resetMT4PW: 'إعادة تعيين كلمة مرور MT4',
      resetMT5PW: 'إعادة تعيين كلمة مرور MT5',
      tradingAcc: 'حساب التداول',
      method: 'طريقة',
      amt: 'الكمية',
      procNote: 'ملاحظات المتابعة',
      number: 'رقم البطاقة',
      PLATFORM: 'المنصة',
      resetBal: 'إعادة تعيين الرصيد',
    },
    key: { from: 'من', to: 'الى' },
    fund: {
      must: 'يجب',
      infoHover: 'الاسم الموجود على بطاقتك {must} ان يتطابق مع الاسم الموجود في حساب التداول الخاص بك',
      ccUploadReminder: 'يرجى تحميل صورة للجزء الأمامي من بطاقتك للتحقق. (يُرجى تغطية الأرقام الستة الوسطى)',
    },
    button: {
      bkToHm: 'العودة إلى الصفحة الرئيسية',
      smsLogin: 'تسجيل الدخول برقم الهاتف',
      goToIB: 'التبديل الى بوابة الـ IB',
      logout: 'تسجيل الخروج',
      submit: 'ارسال',
      selectFile: 'اختيار ملف',
      depositFunds: 'ايداع الاموال',
      withdrawFunds: 'سحب الاموال',
      ibPortal: 'بوابة الـ IB',
      download: 'التنزيلات ',
      changeLeverage: 'تغيير الرافعة',
      cancel: 'الغاء',
      changePw: 'تغيير كلمة المرور',
      forgetPw: 'نسيت كلمة المرور',
      confirm: 'تاكيد',
      login: 'تسجيل الدخول',
      forgotPw: 'هل نسيت كلمة السر؟',
      completeQuestionnaire: 'اكمل الاستبيان',
      identityProof: 'اكمل الطلب',
      upload: 'تحميل',
      hide: 'إخفاء',
      showAll: 'إظهار الكل',
      export: 'تصدير',
      ok: 'موافق',
      createLiveAccount: `إنشاء حساب حقيقي`,
      redeem: `مقايضة`,
      sendCode: 'إرسال الرمز',
      resendCode: 'أعد إرسال الرمز',
    },
    formValidation: {
      accReq: 'رقم الحساب مطلوب',
      amtReq: 'المبلغ مطلوب',
      ccReq: 'رقم بطاقة الائتمان مطلوب',
      cardReq: 'رقم البطاقة مطلوب',
      cardOnNameReq: 'الاسم الموجود على البطاقة مطلوب',
      cardholderNameReq: `اسم حامل البطاقة مطلوب`,
      digits16: 'الرجاء إدخال الـ 16 رقم',
      digits3or4: 'الرجاء إدخال 3 أو 4 أرقام',
      digitsFirst6: 'الرجاء إدخال أول 6 أرقام',
      digitsLast4: 'الرجاء إدخال آخر 4 أرقام',
      digitsLast3: 'الرجاء إدخال آخر 3 أرقام',
      mid6Digits: 'الرجاء إدخال {number} رقم الاوسط',
      expDateReq: 'تاريخ الانتهاء مطلوب',
      cvvReq: 'مطلوب كود الحماية الـ CVV',
      file: 'الرجاء تحميل ملف',
      filePhoto: 'مطلوب صورة بطاقة الائتمان',
      fileReceipt: 'الرجاء تحميل إيصال',
      filePhotoReq: 'صورة البطاقة مطلوبة',
      amtLarger: 'لا يمكن أن يكون المبلغ فارغًا ويجب أن يكون أكبر من أو يساوي {minLimit} {currency}',
      amt0: 'المبلغ لايمكن أن يكون 0',
      amtLess: 'لا يمكن أن يتجاوز مبلغ الإيداع {maxLimit} {currency}',
      note256: 'الحد الأقصى لطول الملاحظة 256 حرفًا',
      noteMoreThan: 'الحد الأقصى لطول الملاحظة هو {limit} من الأحرف',
      noteLessThan: 'الحد الأقصى لطول الملاحظة هو {limit} من الأحرف',
      emailFormat: 'الرجاء إدخال عنوان البريد الإلكتروني الصحيح',
      payMethodReq: 'طريقة الدفع مطلوبة',
      expReq: 'تاريخ انتهاء البطاقة مطلوب',
      bankNameReq: 'اسم البنك مطلوب',
      bankCityReq: 'مطلوب اسم مدينة البنك',
      bankProvinceReq: ' اسم مقاطعة البنك مطلوبة',
      bankAddressReq: 'عنوان البنك مطلوب',
      bankBranchReq: 'فرع البنك مطلوب',
      bankAccNumReq: 'رقم الحساب المصرفي مطلوب',
      ifscCodeReq: 'مطلوب رمز الـ IFSC',
      bankBeneficiaryNameReq: 'اسم بنك المستفيد مطلوب',
      bankHolderAddressReq: 'عنوان صاحب الحساب مطلوب',
      swiftReq: 'كود السويفت (swift) مطلوب',
      accHolderAddress: 'مطلوب عنوان صاحب الحساب',
      bsbReq: 'مطلوب BSB',
      accNumIBANReq: 'رقم الحساب المصرفي / رقم الحساب المصرفي الدولي (IBAN) مطلوب',
      accNameReq: 'اسم الحساب المصرفي مطلوب',
      withdrawMethodReq: 'الرجاء تحديد طريقة السحب',
      tnc: 'الرجاء الموافقة على الشروط والأحكام.',
      currPwReq: 'كلمة المرور الحالية مطلوبة',
      currPwWrong: 'كلمة المرور الحالية غير صحيحة',
      newPwReq: 'مطلوب كلمة مرور جديدة',
      newPwFormat:
        'يجب أن تكون كلمة مرورك من 8 إلى 16 حرفًا وتتكون من مزيج من الحروف (a-z وA-Z) والأرقام (0-9) والرموز الخاصة مثل !@#$%^&*.()',
      IdenficalPw: 'لا يمكن أن تكون كلمة المرور الجديدة هي نفسها القديمة',
      confirmPwReq: 'الرجاء إدخال كلمة المرور الجديدة مرة أخرى',
      confirmNotMatch: 'لا يتطابق المدخلين',
      pwReq: 'كلمة المرور مطلوبة',
      accCurrReq: 'يجب عليك تحديد عملة الحساب',
      tradAccReq: 'الرجاء تحديد حساب تداول',
      answerReq: 'الرجاء التحديد',
      bankCardReq: 'الرجاء تحديد بطاقة',
      phoneNumberReq: 'رقم الهاتف المحمول للبنك مطلوب',
      nationaIdReq: 'الرقم القومي مطلوب',
      numberReq: 'الرجاء إدخال الرقم',
      alphanumericReq: 'الرجاء إدخال أبجدي رقمي',
      idTypeReq: 'الرجاء اختيار نوع الهوية',
      dobReq: 'الرجاء اختيار تاريخ ميلادك',
      pobReq: 'الرجاء اختيار مكان ميلادك',
      nationalityReq: 'الرجاء اختيار جنسيتك',
      titleReq: 'الرجاء اختيار المسمى الوظيفي الخاص بك',
      firstNameReq: 'يرجى ادخال الاسم الاول',
      lastNameReq: 'الرجاء إدخال اسمك الأخير',
      emailReq: 'الرجاء إدخال عنوان البريد الإلكتروني الخاص بك',
      addressReq: 'الرجاء إدخال عنوانك',
      suburbReq: 'الرجاء إدخال مدينتك أو ضاحيتك',
      stateReq: 'الولاية أو المقاطعة مطلوب',
      postcodeReq: 'الرجاء إدخال الرمز البريدي الخاص بك',
      countryReq: 'الرجاء تحديد بلد إقامتك',
      masterCardReq: 'الرجاء استخدام بطاقة Master Card للإيداع',
      masterCardJCBReq: 'يرجى استخدام Master Card / JCB للإيداع',
      masterCardVisaReq: 'يرجى استخدام Master Card / Visa للإيداع',
      masterCardAmexJCBReq: 'يرجى استخدام ماستر كارت / AMEX / JCB للإيداع',
      bankCodeReq: 'الرجاء إدخال البنك الذي أصدر البطاقة',
      dynamicReq: '{dynamic} مطلوب',
      mobReq: 'رقم الجوال مطلوب',
      resetBalAmt: 'يرجى تعيين الرقم بين 0 و1،000،000',
      verCodeReq: 'رمز التحقق مطلوب',
      emailFormatWrong: 'البريد الإلكتروني غير صحيح',
      passwordFormatWrong: 'كلمة المرور غير صحيحة',
    },
    withdrawChannel: {
      banktransferbpaypolipay: 'تحويل بنكي /BPay/PoliPay',
      banktransfer: 'حوالة بنكية',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'Union pay',
      unionpaytransfer: 'تحويل Union pay',
      fasapay: 'FasaPay',
      creditcard: 'بطاقة ائتمان',
      bpay: 'Bpay',
      creditdebitcard: 'بطاقة ائتمان /  كريديت',
      bankwiretransferinternational: 'التحويل البنكي (دولي)',
      banktransferaustralia: 'التحويل بنكي (أستراليا)',
      banktransferinternational: 'تحويل بنكي (دولي)',
      banktransferchina: 'تحويل بنكي (دولي)',
      banktransferequals: 'تحويل بنكي (دولي)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'وسيط لتحويل وسيط',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'الخدمات المصرفية عبر الإنترنت (تايلاند)',
      internetbankingnigeria: 'الخدمات المصرفية عبر الإنترنت (نيجيريا)',
      internetbankingvietnam: 'الخدمات المصرفية عبر الإنترنت (فيتنام)',
      internetbankingmalaysia: 'الخدمات المصرفية عبر الإنترنت (ماليزيا)',
      internetbankingindonesia: 'الخدمات المصرفية عبر الإنترنت (إندونيسيا)',
      internetbankingkorea: 'الخدمات المصرفية عبر الإنترنت (كوريا)',
      internetbankingindia: 'الخدمات المصرفية عبر الإنترنت (الهند)',
      internetbankingphilippine: 'الخدمات المصرفية عبر الإنترنت (الفلبين)',
      internetbankingsouthafrica: 'الخدمات المصرفية عبر الإنترنت (جنوب إفريقيا)',
      internetbankinguganda: '(اوغانا) الخدمات المصرفية عبر الإنترنت',
      internetbankingrwanda: '(رواندا) الخدمات المصرفية عبر الإنترنت',
      internetbankingzambia: '(زامبيا) الخدمات المصرفية عبر الإنترنت',
      internetbankingcongo: '(كونغو) الخدمات المصرفية عبر الإنترنت',
      internetbankingcameroon: '(كاميرون) الخدمات المصرفية عبر الإنترنت',
      internetbankingburundi: '(بوروندي) الخدمات المصرفية عبر الإنترنت',
      internetbankingkenya: '(كينيا) الخدمات المصرفية عبر الإنترنت',
      internetbankingghana: '(غانا) الخدمات المصرفية عبر الإنترنت',
      internetbankingtanzania: '(تانزانيا) الخدمات المصرفية عبر الإنترنت',
      internetbankingcanada: '(كندا) الخدمات المصرفية عبر الإنترنت',
      skrillneteller: 'skrill / Neteller',
      vietnambanktransfer: 'فيتنام التحويل المصرفي',
      nigeriabanktransfer: 'نيجيريا التحويل المصرفي',
      polipayment: 'الدفع بالـ poli',
      bankwiretransferaustralia: 'التحويل البنكي (أستراليا)',
      thailandinstantbankwiretransfer: 'تايلاند التحويل البنكي الفوري',
      nigeriainstantbankwiretransfer: 'التحويل البنكي الفوري النيجيري',
      vietnaminstantbankwiretransfer: 'التحويل البنكي الفوري الفيتنامي',
      malaysiainstantbankwiretransfer: 'التحويل المصرفي الماليزي الفوري',
      indonesiainstantbankwiretransfer: ' التحويل المصرفي الفوري إندونيسيا',
      indiainstantbanktransfer: 'التحويل المصرفي الفوري الهند',
      southkoreabanktransfer: 'التحويل المصرفي المحلي لكوريا الجنوبية',
      southafricainstantbankwiretransfer: 'التحويل البنكي الفوري جنوب أفريقيا',
      philippinesinstantbankwiretransfer: 'التحويل المصرفي الفوري الفلبين',
      ugandabanktransfer: 'تحويل بنك محلي  اوغاندا',
      rwandabanktransfer: 'تحويل بنك محلي رواندا',
      zambiabanktransfer: 'تحويل بنك محلي زامبيا',
      congobanktransfer: 'تحويل بنك محلي الكونغو',
      cameroonbanktransfer: 'تحويل بنك محلي الكاميرون',
      burundibanktransfer: 'تحويل بنك محلي بوروندي',
      kenyabanktransfer: 'تحويل بنك محلي كينيا',
      ghanabanktransfer: 'تحويل بنك محلي غانا',
      tanzaniabanktransfer: 'تحويل بنك محلي تانزانيا',
      indiabanktransfer: 'التحويل المصرفي الهند',
      banktransferbpaypolipayaustralia: 'التحويل المصرفي / BPay / PoliPay (أستراليا)',
      banktransferbpaypolipayinternational: 'التحويل المصرفي / BPay / PoliPay (دوليًا)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'التحويل المصرفي الكندي المحلي',
      cryptobitcoin: 'بيتكوين - كريبتو',
      cryptotetheromni: 'العملة الرقمية Tether(OMNI)',
      cryptotethererc20: 'العملة الرقمية Tether(ERC20)',
      cryptotethertrc20: 'العملة الرقمية Tether(TRC20)',
      cryptocurrencybitcoin: 'بيتكوين - كريبتو',
      cryptocurrencyusdt: 'عملة رقمية - USDT',
      cryptocurrencyeth: 'عملة رقمية - ETH',
      cryptocurrencyusdc: 'عملة رقمية - USDC(ERC-20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      astropay: 'Astropay',
      vload: 'Vload',
      E_WALLET: 'المحفظة الإلكترونية',
      CRYPTOCURRENCY: 'عملة مشفرة',
      LOCAL_TRANSFER: 'النقل المحلي',
      archiveThisCard: 'الرجاء أرشفة هذه البطاقة إذا لم تعد صالحة أو قيد الاستخدام',
      manageCreditCards: 'إدارة بطاقات الائتمان',
      update: 'تحديث',
      archive: 'أرشيف',
      requiredDocument: 'المستندات المطلوبة لأرشفة البطاقة',
      officialLetter: 'رسالة مصرفية رسمية/بريد إلكتروني تنص على أن البطاقة لم تعد متاحة',
      bankStatement: 'كشف حساب بنكي مع معاملة تمت باستخدام هذه البطاقة',
      expiredCard: 'البطاقات منتهية الصلاحية / غير المتوفرة',
      confirmArchive: 'هل أنت متأكد أنك تريد أرشفة هذه البطاقة؟',
      confirmUpdate:
        'هل أنت متأكد أنك من تحديث تاريخ انتهاء صلاحية هذه البطاقة؟ ملاحظة: لن تكون Puprime مسؤولة  إذا فشل السحب بسبب عدم صحة تاريخ إنتهاء الصلاحية الذي قدمته',
      pendingArchiveRequest:
        'طلب أرشفة بطاقتك الائتمانية مُعلق قيد الاعتماد في الوقت الراهن، يُرجى الانتظار حتى اكتمال العملية قبل إجراء السحب، أو التواصل مع خدمة عملائنا عبر الدردشة المباشرة لتعجيل العملية.',
    },
  },
  home: {
    pendingTooltip:
      'بمجرد الموافقة على حسابك الحقيقي ، ستتلقى بريدًا إلكترونيًا يحتوي على تفاصيل تسجيل الدخول إلى MT{number} حتى تتمكن من بدء التداول! في غضون ذلك ، يمكنك تمويل حسابك لتلقي الاولوية في فتح الحساب.',
    leverage: {
      tnc:
        '<p> من خلال تقديم هذا الطلب ، أقر بأنني أدرك أن الرافعة المالية العالية تنطوي على قدر كبير من المخاطرة برأسمالي وهناك احتمال أن أتحمل خسارة أكبر من رأس المال الذي أودعته ولا يقتصر عليه. <br> <br> أوافق على أنه يحق لجهة إصدار المنتجات مراجعة أنشطة التداول الخاصة بي وتعديل مستويات الرافعة المالية في حساب التداول الخاص بي وفقًا لتقديرها وبدون أي تحذيرات أو إخطارات مسبقة. <br> <br> أفهم ذلك باختيار رافعة مالية عالية ، يمكنني التداول بهامش أكبر ولكن من المحتمل أن أتحمل خسائر كبيرة. </ p>',
      changeLeverageConfirm: 'تم إرسال طلبك وسيتولى فريق الدعم معالجته قريبًا.',
      changeLeverageAutoComplete: 'تم الانتهاء من طلب تغيير الرافعة المالية الخاصة بك',
      changeLeverageReject: 'تم رفض طلب تغيير الرافعة المالية',
      changeLeverageFail: 'فشل طلب تغيير الرافعة المالية ، يرجى المحاولة لاحقًا',
      changeLeverageDefault: 'حدث خطأ ، يرجى الاتصال بـ {supportEmail} لاكمال طلبك',
      alreadyApplied:
        'لقد طلبت بالفعل تغيير الرافعة المالية في حسابك وهي حاليًا قيد المراجعة بواسطة فريق دعم {platform}. الرجاء الانتظار حتى يتم الانتهاء من هذا الطلب قبل إرسال طلب آخر.',
      reduceLeverageWarn:
        'يرجى ملاحظة: قد يؤدي تقليل الرافعة المالية إلى الإغلاق الإجباري للصفقات المفتوحة إذا كانت سيولة حسابك غير كافية',
      reduceLeverageConfirm: 'هل ترغب في الاستمرار؟',
      autoRestore: `نظرًا لأن رصيد حسابك تجاوز 20000 دولار أمريكي ، فإن الحد الأقصى للرافعة المالية التي يحق لك استخدامها هو 500:1. يمكنك تغيير الرافعة المالية إلى 1000:1 عندما يكون رصيد حسابك أصغر من أو يساوي 20،000 دولار أمريكي`,
    },
    pw: {
      error: 'فشل في إعادة تعيين كلمة المرور الخاصة بك. الرجاء معاودة المحاولة في وقت لاحق',
      confirm:
        'تم تحديث كلمة المرور الخاصة بك. <br> يمكنك الآن استخدام كلمة المرور الجديدة للوصول إلى حساب التداول MT4 / MT5 الخاص بك.',
      email: `<p class="first_info">لقد أرسلنا إليك للتو بريدًا إلكترونيًا يحتوي على إرشادات لإعادة تعيين كلمة المرور. </p><p> يرجى التأكد من فحص مجلد الرسائل غير المرغوب فيها في بريدك الإلكتروني. </p>`,
    },
    activate: {
      question: 'هل ترغب في إعادة تفعيل حسابك الحقيقي؟',
      confirm: 'لقد تلقينا طلب التنشيط الخاص بك. <br> سيتم تنشيط حسابك قريبًا.',
    },
    accStatus: {
      active: 'نشط',
      rejected: 'تم الرفض',
      inactive: 'غير نشط',
      processing: 'متابعة',
      activate: 'تنشيط',
      expired: 'منتهية',
    },
    pro1000: {
      desc1: `تنطبق القيود التالية على الحساب برافعة مالية 1000:1`,
      conditions: [
        `لحساب المحدد غير مؤهل للانضمام إلى العرض الترويجي المرتبط بالرصيد.`,
        `قد يتم تخفيض الرافعة المالية إلى 1: 500 بواسطة النظام تلقائيًا إذا تجاوز رصيد الحساب المحدد 20.000 دولار أمريكي أو ما يعادله. إذا كان هناك تداول مفتوح ، فسيتم إرسال إشعار بالبريد الإلكتروني قبل تعديل الرافعة المالية.`,
        `قد تختلف حدود الرافعة المالية وفقًا للوائح المعمول بها`,
        `يرجى ملاحظة أن الرافعة المالية 1000:1 غير متاحة للأدوات المالية التي تنتمي إلى العملات المشفرة والأسهم والفضة والسلع. يتم الاحتفاظ بهامش هذه الأدوات وفقًا لمتطلبات الهامش الثابت للأداة ولا يتأثر بـ 1: 1000 رافعة مالية.`,
      ],
      desc2: `من خلال تقديم هذا الطلب ، أقر بأنني أدرك أن الرافعة المالية العالية تنطوي على قدر كبير من المخاطر على مالي الخاص وهناك احتمال أن أتحمل خسارة أكبر من رأس المال الذي أودعته ولا يقتصر عليه.`,
      desc3: `أوافق على أنه يحق لجهة إصدار المنتجات مراجعة أنشطة التداول الخاصة بي وتعديل مستويات الرافعة المالية في حساب التداول الخاص بي وفقًا لتقديرها وبدون أي تحذيرات أو إخطارات مسبقة.`,
      desc4: `أفهم أنه من خلال اختيار الرافعة المالية العالية ، يمكنني التداول بهامش أكبر ولكن من المحتمل أن أتحمل خسائر كبيرة.`,
    },
  },
  paymentDetails: {
    subHeader: 'اضافة طريقة دفع',
    payMethod: 'طريقة الدفع او السداد',
    tip: `<p>حدد طريقة الدفع المفضلة لديك لعمليات الإيداع والسحب المستقبلية من القائمة أدناه.</p><br />
    <p><b>الرجاء ملاحظة ما يلي:</b> لضمان إمكانية معالجة طلبات الإيداع والسحب الخاصة بك على الفور ، تتطلب لوائح مكافحة غسل الأموال / مكافحة الإرهاب أن يكون الاسم الظاهر في طريقة الدفع التي اخترتها هو
    <b>{individualUserName}</b>.</p>`,
    result: {
      defaultSuccessMessage:
        'نشكرك على تقديم طريقة التمويل لحساب التداول الخاص بك. نقوم حاليًا بمعالجة طلبك وسنتواصل معك قريبًا.',
      unionPayCardSuccessMessage:
        'تم إرسال تفاصيل بطاقة Union Pay بنجاح ، وستتوفر التفاصيل المصرفية الجديدة بمجرد الموافقة عليها',
    },
    history: { header: 'طرق الدفع' },
    cc: {
      field: {
        ccNum: 'رقم بطاقه الائتمان',
        first6: 'اول ستة',
        last4: 'اخر اربعة',
        withrawlable:
          'الحد الأقصى للسحب المتاح لهذه البطاقة هو {amount}. لسحب الأموال التي تتجاوز الحد المتاح ، يرجى اختيار بطاقة أخرى أو طريقة سحب أخرى.',
      },
      formValidation: { upload: 'مطلوب صورة بطاقة الائتمان' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'إحضار حالة الإيداع الخاصة بك ... يتبقى {countDown} ثانية.',
      successMsg: 'شكرًا لك على إيداعك ، ستظهر أموالك في حسابك قريبًا.',
      rejectMsg: 'تم الرفض',
      failureMsg: 'كان إيداعك غير ناجح. حاول مرة اخرى.',
      message:
        'شكرا لك على تمويل حسابك. سيتم الموافقة على إيداعك يدويًا بواسطة فريق حسابات {platform} قريبًا. سيتم إيداع الأموال في حسابك بمجرد الموافقة عليه',
      defaultException: 'معاملتك قيد المعالجة حاليًا ، لتأكيد إيداعك مع {platform} ، يرجى الاتصال بـ {email}',
    },
    failed: { countDownMsg: 'إحضار حالة الإيداع الخاصة بك ... يتبقى {countDown} ثانية.', rejectMsg: 'تم الرفض' },
  },
  deposit: {
    alert: {
      depositBonus50: `أنت مؤهل للحصول على مكافأة إيداع بنسبة 50٪. قم بالتفعيل قبل الإيداع!`,
    },
    default: {
      deposit: 'ايداع ',
      reference: 'اشخاص دعوتهم',
      sendReceipt: 'إرسال الإيصال',
      sendReceiptTip:
        'بمجرد إجراء التحويل ، يرجى إكمال الاستمارة أدناه وإرفاق لقطة شاشة أو صورة أو نسخة ممسوحة ضوئيًا من إيصال التحويل المصرفي الذي يظهر بوضوح اسمك الكامل ورقم حسابك ومبلغ الإيداع حتى نتمكن من معالجة معاملتك.',
      reminder: 'يُرجى الانتظار من يوم إلى يومين عمل حتى يتم إيداع الأموال في حسابك.',
      successMsg: 'ستظهر الأموال في حساب التداول الخاص بك بمجرد استلام الإيداع والتحقق من إيصال المعاملة الخاصة بك.',
      alt: 'بدلاً من ذلك ، يمكنك إرسال إيصال المعاملة بالبريد الإلكتروني إلى {mailTo} في أقرب وقت يناسبك.',
      reminderFundAppear: 'ستظهر أموالك في حسابك بمجرد استلامنا الأموال من وسيطك السابق.',
      failed: 'فشل الإيداع ، يرجى المحاولة مرة أخرى في وقت لاحق.',
      fee: 'العمولة',
      instant: 'فوري',
      hour: 'ساعة',
      hours: 'ساعات',
      businessDays: 'ايام عمل',
      withinOneBusinessDays: 'خلال 1 يوم عمل',
      secondaryNGN: '(يمكنك استخدام رقم الحساب للمعاملة هذه واذا تفضل يمكن إستخدام عملة  النيرة النيجيرية كبديل .)',
      disclaimer: `<li>جميع مدفوعاتك إلى {platform} سرية وتستخدم فقط للإيداع والسحب في حساب التداول الخاص بك لـ {platform}. تتم معالجة جميع المدفوعات الإلكترونية بواسطة تقنية SSL (Secure Socket Layer) وتشفيرها لضمان الأمان.</li>
      <li>لا يقبل {platform} مطلقا المدفوعات من أي طرف ثالث يجب أن تكون جميع الأموال المودعة في حساب التداول الخاص بك تحت نفس اسم حساب بوابة عميل {platform} الخاص بك.</li>
      <li>يدرك العميل أن تقديم طلب إيداع لا يعني قبول {platform} للأموال. إذا كان حسابك قريبًا من استدعاء الهامش أو فيه ، فالرجاء التأكد من وجود ترتيبات أخرى.</li>
      <li>لن تكون {platform} مسؤولة عن أي تأخير أو أخطاء ناتجة عن أنظمة الدفع.</li>
      <li>لن تكون {platform} مسؤولة عن أي رسوم قد تفرضها عليك المؤسسة المالية المرسلة أو موفرو الدفع التابعون لجهات خارجية مقابل إرسال المدفوعات.</li>
      <li>يقر العملاء بعلمهم بأنه قد يُطلب منهم تقديم معلومات عن مصدر (مصادر) الإيداع (الودائع) ، وثروة العملاء بشكل عام وأي معلومات أخرى يراها {platform} ضرورية لتحديد مصدر الأموال عند معالجة طلب الإيداع والموافقة لتوفير جميع المعلومات المطلوبة من قبل {platform}. قد يؤدي عدم تقديم المعلومات المطلوبة إلى تأخير كبير في معالجة طلباتك. لن تكون {platform} مسؤولة عن أي تأخير أو خسائر يتحملها العميل نتيجة لهذا التأخير.</li>`,
      channels: {
        creditOrDebit: 'بطاقة ائتمان /  كريديت',
        i12BankTransfer: 'تحويل بنكي دولي',
        sticPay: 'SticPay',
        unionPay: 'ايداع union pay',
        unionPayCN: 'تحويل union pay الصين',
        skrill: 'Skrill/Moneybookers',
        vietInstant: 'التحويل البنكي الفوري من بنك فيتنام',
        thaiInstant: 'تايلاند التحويل البنكي الفوري',
        nigeriaInstant: 'نيجيريا التحويل البنكي الفوري',
        malayInstant: 'التحويل المصرفي الفوري في ماليزيا',
        indonesiaInstant: ' التحويل المصرفي الفوري إندونيسيا',
        philippineInstant: 'التحويل المصرفي الفوري الفلبين',
        mobilePay: 'Mobile Pay',
        canada: '®Interac',
        indiaInstant: 'التحويل المصرفي الفوري الهند',
      },
      rate: {
        rate: 'أسعار التحويل ({oldCurrency}/{newCurrency}):',
        result: { rateError: 'فشل إحضار {oldCurrency} إلى سعر {newCurrency}.', channelError: 'فشل إحضار قناة الدفع.' },
      },
      cn: { rate: 'سعر الصرف (دولار أمريكي / يوان صيني):', amt: 'يوان صيني' },
      upgrade:
        'قناة الدفع قيد الصيانة الآن وغير متاحة إلا للمستخدمين الذين قد أودعوا أموال بنجاح من خلال هذه القناة من قبل.',
      depositInstruction: 'تعليمات الإيداع',
      depositNote: 'ملاحظات الإيداع',
    },
    method: {
      desc: 'كيفية تمويل حسابك مع {method}',
      inst1: '{number}.   أكمل الاستمارة أدناه واضغط على "إرسال"',
      inst2: '{number}.   حدد البنك في صفحة اختيار البنك وقم بتسجيل الدخول إلى {country} عبر الإنترنت',
      inst3: '{number}.   اتبع التعليمات وقم بالدفع عن طريق البنك الخاص بك على الإنترنت / عبر الهاتف المتحرك',
      inst4: '{number}.   ستتم إعادة توجيهك بعد ذلك إلى استمارة آخر لإدخال تفاصيل بطاقتك',
      inst5: '{number}.   بعد إدخال تفاصيل بطاقتك ، اضغط على "إرسال"',
      inst6: '{number}.   ستتم إعادة توجيهك مرة أخرى إلى بوابة العميل وسيتم إنهاء معاملتك',
      inst7:
        '{number}.   إذا كانت عملتك ليست {currency} ، فقد يفرض عليك البنك رسوم تحويل وفقًا لسعر الصرف المصرفي الحالي',
      inst8: `{number}.   إيداعات بطاقات الائتمان باستخدام EUR ، GBP ، AUD ، SGD ، NZD ، HKD ، JPY ، USD محدودة بحد أقصى 5000 دولار أمريكي لكل معاملة. يتم قبول ودائع بطاقات الائتمان المقومة بالدولار الكندي حتى 6500 دولار كندي لكل معاملة`,
      inst9: '{number}.   حد أقصى 1500 دولار أمريكي لكل إيداع',
      inst10: '{number}.   ستظهر صفحة تفاصيل الدفع',
      inst11: '{number}.   حدد البنك في صفحة اختيار البنك وقم بتسجيل الدخول إلى البنك الذي تتعامل معه عبر الإنترنت',
      inst12: '{number}.   يمكننا ان نقبل الدفع في CAD. الحد الأقصى 9999 دولار كندي لكل إيداع',
      form: { header: '{method} استمارة' },
    },
    reminder: {
      inst1: 'أكمل الاستمارة أدناه واضغط على "إرسال"',
      inst2: 'قم بتسجيل الدخول إلى حساب {method} الخاص بك',
      inst3: 'اتبع التعليمات الموجودة في حساب {method} لإتمام الدفع',
      inst4: 'ستتم إعادة توجيهك بعد ذلك إلى استمارة آخر لإدخال تفاصيل بطاقتك',
      inst5: 'بعد إدخال تفاصيل بطاقتك ، اضغط على "إرسال"',
      inst6: 'ستتم إعادة توجيهك مرة أخرى إلى بوابة العميل وسيتم إنهاء معاملتك',
      inst7: '2. سيتم توجيهك بعد ذلك إلى نافذة جديدة تعرض عنوان محفظة {method}',
      inst8:
        'انسخ عنوان محفظة {method} ثم قم بتسجيل الدخول إلى محفظة {method} الشخصية الخاصة بك وقم بتحويل المبلغ الذي تريده إلى عنوان {method}.',
      inst9: 'امسح رمز الاستجابة السريعة لفتح تطبيق الخدمات المصرفية عبر الهاتف المحمول',
      inst10: 'اتبع التعليمات الموجودة على جهازك المحمول لإكمال الإيداع الخاص بك',
      inst11: 'يرجى التأكد من الدفع عبر الإنترنت من خلال صفحة الدفع المعاد توجيهها لتجنب أي خسارة في الأموال.',
      inst12: 'حدد البنك في صفحة اختيار البنك وقم بتسجيل الدخول إلى البنك الذي تتعامل معه عبر الإنترنت',
      inst13: 'اتبع التعليمات وقم بالدفع عن طريق البنك الخاص بك على الإنترنت / عبر الهاتف المتحرك',
      inst14:
        'بمجرد إجراء التحويل ، يرجى إكمال الاستمارة أدناه وإرفاق نسخة من إيصال التحويل المصرفي الذي يظهر بوضوح اسمك بالكامل ورقم حسابك ومبلغ الإيداع حتى نتمكن من معالجة معاملتك.',
      inst15: 'يُرجى الانتظار من 2 إلى 5 أيام عمل حتى يتم إيداع الأموال في حسابك.',
      inst16: 'يرجى السماح بيوم عمل واحد حتى يتم إيداع الأموال في حسابك.',
      inst17: 'يرجى التأكد من أنه يظهر اسمك بالكامل ورقم حسابك ومبلغ الإيداع بشكل واضح حتى نتمكن من معالجة تحويلك.',
      inst18: 'يرجى السماح بفترة تتراوح بين من 1-6 ساعات حتى تضاف الأموال إلى حسابك.',
      note1: 'إيداعات {method} محدودة بمبلغ 25000 دولار أمريكي لكل معاملة وبحد أقصى 75000 دولار أمريكي في اليوم',
    },
    southAfrica: { header: ' التحويل البنكي الفوري جنوب أفريقيا', country: 'جنوب إفريقيا' },
    idn: { header: ' التحويل المصرفي الفوري إندونيسيا', country: 'اندونيسيا' },
    ida: { header: 'التحويل المصرفي الفوري الهند', country: 'الهند' },
    nigeria: { header: 'نيجيريا التحويل البنكي الفوري', country: 'نيجيريا' },
    thailand: { header: 'تايلاند التحويل البنكي الفوري', country: 'تايلاند' },
    malay: { header: 'التحويل المصرفي الفوري في ماليزيا', country: 'ماليزيا' },
    php: { header: 'التحويل المصرفي الفوري الفلبين', country: 'الفيليبين' },
    vietnam: { header: 'التحويل البنكي الفوري من بنك فيتنام', country: 'فييتنام' },
    korea: { header: 'التحويل المصرفي المحلي لكوريا الجنوبية' },
    uganda: { header: 'تحويل بنك محلي  اوغاندا', country: 'اوغانا' },
    rwanda: { header: 'تحويل بنك محلي رواندا', country: 'رواندا' },
    zambia: { header: 'تحويل بنك محلي زامبيا', country: 'زامبيا' },
    congo: { header: 'تحويل بنك محلي الكونغو', country: 'كونغو' },
    cameroon: { header: 'تحويل بنك محلي الكاميرون', country: 'كاميرون' },
    burundi: { header: 'تحويل بنك محلي بوروندي', country: 'بوروندي' },
    kenya: { header: 'تحويل بنك محلي كينيا', country: 'كينيا' },
    ghana: { header: 'تحويل بنك محلي غانا', country: 'غانا' },
    tanzania: { header: 'تحويل بنك محلي تانزانيا', country: 'تانزانيا' },
    canada: { header: 'Interac E-تحويل', note1: 'نحن نقبل الدفع بعملة CAD فقط. بحد أقصى 9999 دولار كندي لكل معاملة' },
    creditOrDebit: {
      header: 'بطاقة ائتمان',
      worldcard: {
        note1:
          'إيداعات بطاقات الائتمان باستخدام اليورو والجنيه البريطاني والدولار الأمريكي محدودة بحد أقصى قدره 20,000 دولار أمريكي لكل معاملة. إيداعات بطاقات الائتمان باستخدام اليورو والجنيه الاسترليني والدولار الأمريكي بحد أقصى 20,000 دولار أمريكي أو ما يعادله لكل معاملة.',
        note2: 'عدد البطاقات المتنوعة المسموح بها لكل مستخدم هو 3. لن يتم قبول أي محاولة إيداع تتجاوز الحد المسموح به.',
      },
      virtualPay: {
        note1:
          'إيداعات بطاقات الائتمان باستخدام EUR ، GBP ، AUD ، SGD ، NZD ، HKD ، JPY ، USC و USD محدودة بحد أقصى 10,000 دولار أمريكي لكل معاملة.',
        note2:
          '50,000 دولار أمريكي أو 10 معاملات كحد أقصى لكل بطاقة خلال 24 ساعة. لن يتم قبول أي محاولة إيداع تتجاوز الحد المسموح به.',
      },
      Sdpay: {
        note1:
          'إيداعات بطاقات الائتمان باستخدام اليورو والجنيه الإسترليني والدولار الأمريكي محدودة بحد أقصى 2000 دولار أمريكي أو ما يعادلها من العملات لكل معاملة.',
        note2: '8 معاملات كحد أقصى في اليوم. لن يتم قبول أي محاولة إيداع تتجاوز الحد المسموح به.',
        note3:
          'يرجى ملاحظة أنه قد يتم ربما تعليق مدفوعاتك لأول مرة. يُرجى التحلي بالصبر أثناء قيام فريقنا بالتحقق من إيداعك ، أو يمكنك الاتصال بفريق دعم العملاء للحصول على مزيد من المساعدة.',
      },
      maxisPay: {
        note1: 'إيداعات بطاقة الإئتمان بإستعمال USD, و محدودة بمبلغ 5000 دولار أمريكي (USD) كحد أقصى لكل معاملة.',
      },
    },
    net: {
      header: 'Neteller',
      note1: 'يمكننا قبول الدفع بالدولار الأسترالي والدولار الأمريكي واليورو والدولار الكندي والجنيه الإسترليني',
      note2: 'لا يمكن تمويل الحسابات إلا بنفس عملة حساب تداول MT4 الخاص بك.',
      note3:
        'سيقوم Neteller تلقائيًا بتحويل الأموال إلى العملة الأساسية لحسابك إذا تم استخدام البريد الإلكتروني غير الصحيح. قد يتم تطبيق رسوم.',
      note4: 'يجب أن تكون الإيداعات بنفس اسم حساب PU Prime الخاص بك.',
    },
    vload: {
      header: 'VLOAD',
      inst1: '2.   يمكننا قبول الدفع بالدولار واليورو والين الياباني.',
      inst2: `<li>3.   اشتر قسيمة Vload، أنقر <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">هنا</a>.</li>`,
      form: {
        voucherpin: 'رقم التعريف الشخصي للقسيمة',
        voucherpinReq: 'رقم التعريف الشخصي مطلوب',
      },
    },
    bankWireAu: {
      header: 'تحويل بنكي لاستراليا',
      instruction:
        'يمكن تحويل الأموال إلى {platform} عبر التسهيلات المصرفية عبر الإنترنت لمؤسستك المالية ، أو بدلاً من ذلك عن طريق الاتصال بمؤسستك المالية وطلب التحويل إلى {platform}.',
      tip: 'حدد عملة الحساب التي ترغب في الإيداع بها. يرجى التأكد من تحديد العملة نفسها مثل عملة حساب التداول الخاص بك',
      form: {
        bankDetails: 'تفاصيل البنك (إيداع بالدولار الأسترالي فقط)',
        refTip: 'يرجى تحديد رقم تسجيل الدخول الخاص بك هنا',
        afp: 'الدفع السريع الأسترالي (الإيداع المحلي بالدولار الأسترالي فقط)',
        payIdOrABNNum: 'رقم PayID / ABN',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip: 'يرجى تضمين رقم حساب التداول الخاص بك على {platform} في الحقل المرجعي للتحويل المصرفي.',
      },
    },
    bPay: {
      header: 'ايداع BPAY ',
      option: 'كيفية تمويل حسابك مع BPay (أستراليا فقط)',
      inst1: '1. حدد حساب MT4 / MT5 الخاص بك لعرض رمز فاتورة BPay والرقم المرجعي لـ BPay',
      inst2:
        '2. أدخل تفاصيل الدفع ورمز BPay Biller الخاص بك والرقم المرجعي لـ BPay في مرفق الخدمات المصرفية عبر الإنترنت الخاص بك وأرسل دفعتك',
      inst3: '3. ارجع إلى هذه الصفحة وأكمل استمارة BPay أدناه للتأكد من أنه يمكننا معالجة معاملتك على الفور',
      form: {
        header: 'استمارة BPAY  ',
        billerCode: 'كود BPAY',
        refNum: 'رقم الدعوة BPAY',
        receipt: 'وصل تحويل BPAY',
        reminderNotUploaded:
          'لقد لاحظنا أنك لم تقم بتحميل إيصال المعاملة الخاصة بك. من أجل معالجة الإيداع الخاص بك ، يرجى إرسال نسخة من الإيصال بالبريد الإلكتروني إلى {mailTo} في أقرب وقت ممكن.',
        reminderProc: 'يرجى الملاحظة. لا يمكن معالجة معاملتك حتى نتلقى إيصالك.',
      },
    },
    b2b: {
      header: ' وسيط لوسيط',
      option: 'كيفية تحويل الأموال من وسيط آخر إلى {platform}',
      inst1: '1. قم بتنزيل وإكمال {download}',
      inst2: '2. أكمل الاستمارة الموجود على الإنترنت أدناه وقم بتحميل استمارة تحويل الوسيط المكتمل الخاص بك إلى الوسيط',
      inst3: '3. أرسل نسخة من استمارة الوسيط إلى الوسيط المكتمل إلى وسيطك السابق لبدء التحويل',
      b2bForm: 'استمارة تحويل وسيط إلى وسيط',
      formValidation: { upload: 'مطلوب من وسيط إلى وسيط' },
    },
    cc: {
      header: 'ايداع بطاقة الائتمان أو الخصم',
      remind:
        'إذا واجهت أي صعوبة في تمويل حسابك باستخدام الطريقة الأساسية ، فيرجى استخدام طريقة SecondaryCredit / Debit',
      field: {
        fundNow: 'قم بالتمويل الآن عن طريق بطاقة الائتمان / الخصم',
        newCard: 'بطاقة جديدة',
        cardNum: 'رقم بطاقه الائتمان',
        nameOnCard: 'الاسم على البطاقة',
        expDate: 'تاريخ إنتهاء الصلاحية',
        cvv: 'كود الحماية CVV',
        bankCode: 'بنك يصدر بطاقات إئتمانية',
        example: `بطاقة بسيطة`,
      },
      placeholder: { bankCode: 'الرجاء إدخال اسم المصرف الذي أصدر بطاقتك' },
      cvvTip: 'الأرقام الثلاثة المعروضة على ظهر بطاقتك',
      threeDomainSecQ: 'هل بطاقتك تدعم 3D-Secure؟',
      threeDomainSec:
        'ما هي مصادقة 3D Secure؟ <br /> <strong> المصادقة ثلاثية الأبعاد الآمنة هي طريقة لمنع الاحتيال معترف بها من قبل Visa و Mastercard. <br /> <br /> إذا كانت بطاقتك تدعم 3D Secure ، فستتم معالجة أموالك على الفور. </ strong>',
      cardUpload:
        'لم يتم ربط البطاقة التي تستخدمها لإيداع الأموال بحساب التداول الخاص بك. يرجى تحميل صورة للجزء الأمامي من بطاقتك للتحقق. (يُرجى تغطية الأرقام الستة الوسطى)',
      result: {
        success:
          'شكرا لك. نحن نتحقق حاليًا من تفاصيل بطاقتك ، وبمجرد ظهور الأموال التي تم التحقق منها في حسابك في غضون 24 ساعة.',
        depositSuccess: 'شكرًا لك على إيداعك ، ستظهر أموالك في حسابك قريبًا',
        error:
          'من أجل حماية أمان حسابك ، <br> لقد وصلت إلى الحد الأقصى من عدد محاولات الإيداع وقمت بتعليق الودائع مؤقتًا. <br> يرجى الاتصال بـ {email}',
      },
      tipOnlyTaiwan: `عميلنا العزيز، يرجى العلم بأن السحوبات من خلال بطاقة الائتمان قد تتأخر بسبب معالجة البنك، ويعتمد وقت الوصول بالكامل على البنك. إذا كانت لديك احتياجات ضرورية للأموال، يوصى باستخدام طريقة أخرى للإيداع/السحب.
      `,
      reminder: `<li>1.  إيداعات بطاقة الائتمان باستخدام اليورو والجنيه الإسترليني والدولار الأمريكي محدودة بحد أقصى يبلغ 1،900 دولار أمريكي أو عملة مكافئة لكل معاملة.</li>
      <li>2. عدد المعاملات 8 بحد أقصى في اليوم.</li>
      <li>3. يرجى ملاحظة أنه قد يكون هناك تعليق يطبق على مدفوعاتك للمرة الأولى. يرجى التحلي بالصبر حتى يتحقق فريقنا من دفعك، أو يجوز أن تتواصل مع فريقنا لدعم العملاء لمزيد من المساعدة.</li>`,
    },
    fasa: {
      description: 'كيفية تمويل حسابك مع FasaPay',
      form: { header: 'استمارة FASAPAY' },
    },
    intSwift: {
      header: 'تحويل بنكي مباشر : رمز التحويل الدول',
      desc:
        'يمكن تحويل الأموال إلى {platform} عبر التسهيلات المصرفية عبر الإنترنت لمؤسستك المالية ، أو بدلاً من ذلك عن طريق الاتصال بمؤسستك المالية وطلب التحويل إلى {platform}.',
      desc2:
        'بمجرد إجراء التحويل ، يرجى إكمال الاستمارة أدناه وإرفاق نسخة من إيصال التحويل المصرفي الذي يظهر بوضوح اسمك بالكامل ورقم حسابك ومبلغ الإيداع حتى نتمكن من معالجة معاملتك.',
      alertSAandNGA:
        'إذا كانت لديك عملة أخرى غير الدولار الأمريكي، يرجى استبدال الدولار الأمريكي بالحوالة قبل إجراء التحويل. إذا حولت مباشرة، سيتحمل العميل فرق سعر الصرف ورسوم المعاملة المتكبدة في ذلك الوقت.',
      options: 'يرجى تحديد حساب التداول الذي ترغب في تمويله',
      refInfo: 'يرجى تضمين رقم حساب التداول الخاص بك على {platform} في الحقل المرجعي للتحويل المصرفي.',
      bussDayInfo: 'يُرجى الانتظار من 2 إلى 5 أيام عمل حتى يتم إيداع الأموال في حسابك.',
      tip:
        'الرجاء التأكد من أن إيصال التحويل يظهر بوضوح <b> الاسم الكامل </ b> و <b> رقم الحساب </ b> و <b> مبلغ الإيداع </ b>.',
    },
    bitwallet: {
      header: 'Bitwallet',
      note1: 'نقبل الدفع بالدولار الأمريكي والدولار الأسترالي واليورو وUSD و الين الياباني',
    },
    astropay: {
      desc: 'كيف تمول حسابك مع ASTROPAY',
      note1: 'نحن نقبل الدفع بكل العملات.',
      form: { header: 'استروباي استمارة' },
    },
    mobile: {
      header: 'MOBILE PAY',
      desc: 'كيفية تمويل حسابك باستخدام Mobile Pay',
      inst1: '1. أكمل الاستمارة أدناه واضغط على "إرسال"',
      inst2: '2. امسح رمز الاستجابة السريعة لفتح تطبيق الخدمات المصرفية عبر الهاتف المحمول',
      inst3: '3. اتبع التعليمات الموجودة على جهازك المحمول لإكمال الإيداع الخاص بك',
      form: { payMethod: 'طرق الدفع :', rate: 'سعر الصرف (دولار أمريكي / يوان صيني):', amt: 'CNY:' },
      formValidation: { cnyAmt: 'لا يمكن أن يكون مبلغ اليوان الصيني أكثر من $ {limit}' },
    },
    crypto: {
      header: 'الايداع بالعملات الرقمية',
      choose: 'يرجى اختيار العملة الرقمية المفضلة لديك',
      note:
        'يرجى ملاحظة أننا غير قادرين على الإيداع والسحب من خلال BUSDT، يرجى التأكد من أن العنوان والعملة المشفرة يطابقان السلسلة والعملة التي نقبلها، وإلا فقد تخسر المال.',
      note2:
        'يرجى ملاحظة أننا غير قادرين على الإيداع أو السحب عبر BUSDT أو ETH ، يرجى التأكد من تطابق العنوان والعملة المشفرة مع السلسلة والعملة التي نقبلها وإلا فقد تفقد المال. لن نكون مسؤولين عن أي خسارة تتكبدها.',
      proceedQuestion: 'هل ترغب في متابعة <br /> <b> {method} </b> الإيداع الخاص بك؟',
      remark:
        'يرجى ملاحظة أننا غير قادرين على الإيداع والسحب من خلال BUSDT، يرجى التأكد من أن العنوان والعملة المشفرة يطابقان السلسلة والعملة التي نقبلها، وإلا فقد تخسر المال.',
      inst2: '2.    سيتم توجيهك بعد ذلك إلى نافذة جديدة تعرض عنوان محفظة {method}',
      inst3:
        '3.    انسخ عنوان محفظة {method} ثم قم بتسجيل الدخول إلى محفظة {method} الشخصية الخاصة بك وقم بتحويل المبلغ الذي تريده إلى عنوان {method}.',
      inst4: 'يرجى ملاحظة ما يلي: تتم معالجة ودائع BITCOIN بواسطة B2BinPay بسعر الصرف المقدم في وقت المعاملة.',
      bitcoin: {
        button: 'بيتكوين  ',
        desc: 'كيف تمول حسابك مع BITCOIN',
      },
      usdt: {
        button: 'USDT(OMNI)',
        desc: 'كيفية تمويل حسابك مع USDT (OMNI)',
        inst1: '1. أكمل الاستمارة أدناه واضغط على "إرسال"',
        inst2: '2. سيتم توجيهك بعد ذلك إلى نافذة جديدة تعرض عنوان محفظة USDT (OMNI)',
        inst3:
          '3. انسخ عنوان USDT (OMNI) ثم سجل الدخول إلى محفظتك الشخصية USDT (OMNI) وقم بتحويل المبلغ الذي تريده إلى عنوان USDT (OMNI).',
        inst4: 'يرجى ملاحظة ما يلي: تتم معالجة ودائع USDT (OMNI) بواسطة B2BinPay بسعر الصرف المقدم في وقت المعاملة.',
      },
      usdt_CPS: {
        button: 'USDT({name})',
        desc: 'كيفية تمويل حسابك مع USDT ({name})',
      },
      form: { header_bitcoin: 'استمارة بيتكوين', header_usdt: 'USDT(OMNI) من', header_usdt_CPS: 'USDT({name}) من' },
    },
    skrill: {
      header: 'إيداع skrill / موني بوكرز',
      desc: 'كيف تمول حسابك مع Skrill',
      note1: 'نقبل الدفع بالدولار الأمريكي واليورو والجنيه الإسترليني والدولار الكندي',
      note2: 'لا يمكن تمويل الحسابات إلا بنفس عملة حساب تداول MT4 الخاص بك.',
      note3:
        'سيقوم Skrill تلقائيًا بتحويل الأموال إلى العملة الأساسية لحسابك إذا تم استخدام البريد الإلكتروني غير الصحيح. قد يتم تطبيق رسوم.',
      note4: 'يجب أن تكون الإيداعات بنفس اسم حساب PU Prime الخاص بك.',
      form: { header: 'استمارة SKRILL', email: 'بريد إلكتروني Skrill / Moneybookers' },
      reminder: `<li>الرجاء ملاحظة:</li>
      <li>* لا يمكن تمويل الحسابات إلا بنفس عملة حساب تداول MT4 الخاص بك.</li>
      <li>* يجب أن تكون الإيداعات بنفس اسم حساب {platform} الخاص بك. </li>`,
    },
    sticPay: {
      header: 'إيداع STICPAY',
      desc: 'كيف تمول حسابك مع STICPAY',
      inst1: '1. أكمل الاستمارة أدناه واضغط على "إرسال"',
      inst2: '2. قم بتسجيل الدخول إلى حساب SticPay الخاص بك',
      inst3: '3. اتبع التعليمات الموجودة في حساب SticPay لإتمام الدفع',
      form: { header: 'SticPay استمارة', email: 'SticPay ايميل' },
    },
    unionPay: {
      header: 'ايداع union pay',
      desc: 'كيف تجد حسابك مع union pay',
      inst1: '1. أكمل الاستمارة أدناه واضغط على "إرسال"',
      inst2: '2. حدد البنك في صفحة اختيار البنك وقم بتسجيل الدخول إلى البنك الذي تتعامل معه عبر الإنترنت',
      inst3: '3. اتبع التعليمات وقم بالدفع عن طريق بنك الإنترنت / الهاتف المتحرك الخاص بك',
      form: { header: 'استمارة union pay' },
    },
    unionPayCN: {
      header: 'تحويل union pay الصين',
      desc: 'كيفية تمويل حسابك مع تحويل union pay الصين',
      inst1: 'ستظهر صفحة تفاصيل الدفع',
      inst2: 'قد يُطلب منك رقم العضوية أثناء الإيداع ، يرجى إدخال رقم حساب التداول الخاص بك كرقم العضوية',
      note1:
        'الحد الأدنى لمبلغ المعاملة لكل إيداع هو 500 يوان صيني والحد الأقصى لمبلغ المعاملة لكل إيداع هو 49999 يوان صيني.',
      note2: 'يرجى ملاحظة أنه قد يتم تحديث حد مبلغ الإيداع من وقت لآخر حسب السوق.',
      note3: 'يرجى عدم نسخ تفاصيل المستفيد بالبنك المعروضة حيث قد يتم تحديثها من وقت لآخر.',
      form: {
        header: 'استمارة UNION PAY الصين ',
        holderName: 'اسم حاملي بطاقة union pay:',
        holderNameInfo: 'يرجى التأكد من أن الاسم الذي أدخلته أعلاه يجب أن يكون هو نفسه اسم حاملي البطاقة المصرفية',
      },
    },
    paypal: {
      header: 'PayPal',
      desc: 'كيفية تمويل حسابك مع paypal',
      inst1: '1. أكمل الاستمارة أدناه واضغط على "إرسال"',
      inst2: '2. قم بتسجيل الدخول إلى حساب paypal الخاص بك',
      inst3: '3. اتبع التعليمات وفي حساب paypal لإتمام الدفع',
      inst4: 'يرجى ملاحظة: لا يمكننا قبول مدفوعات من طرف ثالث',
      form: { header: 'استمارة paypal' },
    },
    cardpay: {
      desc: 'كيف تمول حسابك ببطاقة الائتمان',
      inst1: '1. أكمل الاستمارة أدناه واضغط على "إرسال"',
      inst2: '2. ستتم إعادة توجيهك بعد ذلك إلى استمارة آخر لإدخال تفاصيل بطاقتك',
      inst3: '3. بعد إدخال تفاصيل بطاقتك ، اضغط على "إرسال"',
      inst4: '4. ستتم إعادة توجيهك مرة أخرى إلى بوابة العميل وسيتم إنهاء معاملتك',
      form: { header: 'استمارة بطاقة الائتمان' },
    },
    tinkbit: { inst5: '5. حد أقصى 1500 دولار أمريكي لكل إيداع' },
    perfectMoney: {
      inst1: '2. قم بتسجيل الدخول إلى حساب Perfect Money الخاص بك',
      inst2: '3. اتبع التعليمات الموجودة في حساب Perfect Money لإتمام الدفع الخاص بك',
    },
    indiaBankTransfer: {
      desc1: 'بمجرد قيامك بالتحويل، يرجى إكمال النموذج التالي وإرفاق نسخة من إيصال التحويل البنكي.',
      desc2: 'يرجى التأكد من أنه يظهر اسمك بالكامل ورقم حسابك ومبلغ الإيداع بشكل واضح حتى نتمكن من معالجة تحويلك.',
      bussDayInfo: 'يرجى السماح بفترة تتراوح بين من 1-6 ساعات حتى تضاف الأموال إلى حسابك.',
    },
  },
  restrictDeposit: { header: 'إشعار هام', info: 'للأسف ، لم نعد نقبل الودائع من العملاء المقيمين في كندا.' },
  withdraw: {
    title: 'سحب الاموال',
    default: {
      accountDetail: 'تفاصيل الحساب',
      withdrawalAmount: 'مبلغ السحب',
      example: 'مثال على بطاقة الائتمان / الخصم',
      alert:
        'نظرًا لقوانين مكافحة غسل الأموال ، يجب أن تكون الطريقة التي تستخدمها للسحب هي نفس الطريقة المستخدمة في الإيداع الخاص بك. إذا كان مبلغ السحب أقل من 100 وحدة من العملة الأساسية لحسابك ، فسيتم تحميلك 20 وحدة مقابل رسوم المناولة من قبل البنك.',
      how: 'كيف مولت حساب التداول الخاص بك？',
      selectCC: 'حدد بطاقة الائتمان',
      selectBA: 'حدد حساب مصرفي',
      anotherCC: 'أدخل بطاقة الائتمان يدويًا',
      anotherBA: 'إضافة حساب مصرفي',
      remember: 'تذكر حسابي',
      success: 'كان طلب السحب الخاص بك ناجحًا وستتم معالجته قريبًا',
      ccFail:
        'من أجل حماية أمان حسابك ، فقد وصلت إلى الحد الأقصى من عدد محاولات الإيداع وقمت بتعليق عمليات السحب الخاصة بك مؤقتًا. يرجى الاتصال بـ {email}.',
      checkPosFailed: 'فشل التحقق من المركز',
      fetchTradeAccFailed: 'فشل إحضار حساب التداول',
      chooseAnother: `<p> الحد الأقصى لمبلغ السحب لهذه البطاقة: 0 </p>
      <p> يُرجى اختيار بطاقة أخرى أو طريقة دفع أخرى </ p> `,
      notEnoughFunds: 'المبلغ الذي تم إدخاله يتجاوز الحد الأقصى لهذه البطاقة',
      failed: 'الرجاء معاودة المحاولة في وقت لاحق',
      unfortunate:
        'للأسف ، لم نعد قادرين على معالجة عمليات السحب إلى بطاقات الائتمان. من الآن فصاعدًا ، ستتم معالجة عمليات السحب عبر التحويل المصرفي إلى حسابك المصرفي المحدد.',
      noCardWarn: 'ليس لديك أي بطاقة متاحة للسحب ، يرجى ربط بطاقتك في تفاصيل السحب أولاً.',
      equityStop:
        'للأسف ، لا يمكن معالجة عملية السحب الخاصة بك لأنها ستؤدي إلى انخفاض الهامش المتاح لحسابك إلى ما دون مستوى الإيقاف البالغ 100٪',
      equityMargin: 'للأسف ، لا يمكننا حاليًا معالجة عملية السحب الخاصة بك لأن حسابك محمي بالكامل.',
      equityContinue: 'لقد اكتشفنا أن لديك حاليًا مراكز مفتوحة ، وقد تواجه التوقف بعد تقديم طلب السحب ، يرجى التأكيد',
      YourPaymentGreaterThanAvailableBalance: 'دفعتك أكبر من الرصيد المتاح',
      blackList: 'للأسف لا يمكنك حاليًا تقديم طلب سحب. يرجى الاتصال بـ {supportEmail} للحصول على مزيد من المعلومات',
      NetellerChargeAlert:
        'سحوبات Neteller محدودة بحد يبلغ 10،000 دولار في المعاملة، ويطبق رسم بنسبة 2% (بحد أقصى 30 دولار).',
      agreePolicy: '{link}لقد قرأت وأوافق على',
      withdrawPolicy: 'سياسة السحب',
      addWithdrawalMethod: 'أضف طريقة سحب',
      addSecondaryMethod: 'أضف طريقة ثانوية',
      withdrawNote:
        'يرجى ملاحظة أننا سنعالج سحبك عبر طريقة بطاقة الائتمان بشكل أساسي. إذا استُنفِذ مبلغ بطاقتك الائتمانية، سنطلب منك اختيار طريقة أخرى لإتمام طلب سحبك.',
      submitYourWithdrawal: 'إرسال سحبك',
      remainingWithdraw: 'سيُقسّم مبلغ السحب المتبقي إلى بطاقة ائتمانية أخرى أو طريقة سحب أخرى.',
      onlyWithdrawalCcDesc: `{ccAmount}لن يُسحب بالكامل من البطاقة الائتمانية إلا مبلغ`,
    },
    fasa: {
      field: { accName: 'اسم حساب FasaPay', accNum: 'رقم حساب FasaPay' },
      formValidation: { accNameReq: 'اسم حساب FasaPay مطلوب', accNumReq: 'رقم حساب FasaPay مطلوب' },
      fasaPayChargeAlert: 'تفرض FasaPay رسم معاملة 0.5% على كل سحب.',
    },
    neteller: { field: { email: ' Neteller ايميل' }, formValidation: { emailReq: 'مطلوب  Neteller ايميل' } },
    vload: {
      field: { email: 'البريد الإلكتروني المسجل لدى Vload' },
      formValidation: { emailReq: 'البريد الإلكتروني المسجل لدى Vload مطلوب' },
    },
    bitcoin: {
      field: { address: 'عنوان محفظة بيتكوين' },
      formValidation: {
        addressReq: 'عنوان محفظة بيتكوين مطلوب',
        addressFormat: `يجب أن يبدأ عنوان محفظتك بالرقم "1" أو "3" أو "bc1"`,
        addressLength: 'يجب أن يكون عنوان محفظة البيتكوين (Bitcoin) بين 42 و 35 رمزاً.',
      },
    },
    eth: {
      field: { address: 'عنوان محفظة بيتكوين' },
      formValidation: {
        addressReq: 'عنوان محفظة بيتكوين مطلوب',
        addressFormat: `يجب أن يبدأ عنوان محفظتك بالرقم "1" أو "3" أو "bc1"`,
        addressLength: 'يجب أن يكون عنوان محفظة البيتكوين (ETH) بين 42 و 35 رمزاً.',
      },
    },
    usdc: {
      field: { address: 'عنوان محفظة بيتكوين' },
      formValidation: {
        addressReq: 'عنوان محفظة بيتكوين مطلوب',
        addressFormat: `يجب أن يبدأ عنوان محفظتك بالرقم "1" أو "3" أو "bc1"`,
        addressLength: 'يجب أن يكون عنوان محفظة البيتكوين (USDC) بين 42 و 35 رمزاً.',
      },
    },
    usdt: {
      field: {
        address: 'عنوان محفظة USDT ',
      },
      formValidation: {
        addressReq: 'مطلوب عنوان محفظة USDT ',
        trc20WalletFormatIncorrect: `يجب أن يبدأ عنوان محفظتك بحرف "T"`,
        erc20WalletFormatIncorrect: `يجب أن يبدأ عنوان محفظتك ب "0x"`,
      },
    },
    bitwallet: { field: { email: ' ايميل Bitwallet' }, formValidation: { emailReq: 'ايميل Bitwallet مطلوب' } },
    astropay: {
      field: { accountNumber: 'حساب Astropay  ' },
      formValidation: { accountNumberReq: 'حساب Astropay مطلوب ' },
    },
    paypal: { field: { email: ' ايميل paypal' }, formValidation: { emailReq: 'ايميل paypal مطلوب' } },
    skrill: {
      field: { email: 'ايميل Skrill' },
      formValidation: { emailReq: 'ايميل Skrill مطلوب' },
      skrillChargeAlert: 'تفرض Skrill رسم معاملة 1% على كل سحب.',
    },
    sticpay: { field: { email: 'SticPay ايميل' }, formValidation: { emailReq: 'ايميل SticPay مطلوب' } },
    perfectMoney: {
      field: { email: 'ايميل Perfect Money' },
      formValidation: { emailReq: 'ايميل Perfect Money مطلوب' },
    },
    transfer: {
      info:
        'ستسدد {platform} الرسوم المصرفية المفروضة على عملية سحب <u> واحدة </ u> شهريًا. عمليات السحب الإضافية خلال الشهر ستتحمل رسومًا بنكية قدرها 20 وحدة من عملة السحب الخاصة بك.',
      label: {
        upload: 'يرجى تحميل نسخة من كشف حسابك المصرفي (الحالي إلى آخر 3 أشهر).',
      },
      formValidation: {
        upload: 'يرجى تحميل كشف حساب بنكي',
        emailReq: 'ادخل الايميل',
      },
    },
    otherMethod: {
      withdrawalMethod: 'طريقة السحب',
      manageWithdrawalDetails: 'إدارة تفاصيل السحب',
      verificationCode:
        'لقد أرسل رمز التحقق إلى {email}. يرجى التواصل عبر البريد الإلكتروني {supportEmail} إذا كنت بحاجة إلى مزيد من المساعدة',
    },
  },
  transfer: {
    creditWarn: 'لا يمكننا معالجة تحويل أموالك من الحساب المحدد بسبب احتواء حسابك على ائتمان ترويجي.',
    blackListWarn:
      'يمكن أن يؤدي تحويل الأموال من حساب MAM الخاص بك إلى تصفية المراكز المدارة. يرجى الاتصال بـ {mailTo} إذا كنت ترغب في تحويل الأموال من هذا الحساب.',
  },
  changePw: {
    header: 'تغيير كلمة المرور',
    field: { currPw: 'كلمة المرور الحالية', newPw: 'كلمة المرور الجديدة', confirmPw: 'تأكيد كلمة المرور الجديدة' },
    succ: 'لقد تم تحديث كلمة السر الخاصة بك. يمكنك الآن استخدام كلمة المرور الجديدة للوصول إلى بوابة العميل.',
  },
  promotion: {
    clickToGet: 'فقط اضغط واحصل على 50 $ بونص',
    apply: 'قدم الآن',
    start: 'أبدا الأن .',
    tnc: `<li> 1. هذا العرض متاح لمدة 30 يوما . </li>
    <li> 2. هذا العرض متاح لجميع العملاء (الحاليين والجدد) الذين لديهم حساب حقيقي ولكنهم لم يجروا أي إيداع من قبل. </ li>
    <li> 3. هذا العرض متاح للعملاء الجدد في المناطق التالية: فرنسا ، المملكة المتحدة ، تايوان ، إيطاليا ، كندا ، بلجيكا ، سويسرا ، هونغ كونغ ، نيجيريا ، هولندا ، اليابان ، لوكسمبورغ ، مالطا ، ريونيون ، ألمانيا ، مارتينيك ، إسبانيا ، لاتفيا ، بولندا ، كوت ديفوار ، الدنمارك ، البرازيل ، نيوزيلندا ، البرتغال ، جزيرة مان ، جنوب إفريقيا ، تركيا ، رومانيا ، جوادلوب ، النمسا ، أيرلندا ، غيانا الفرنسية ، سوازيلاند ، الأرجنتين ، أوكرانيا ، اليونان ، السويد ، موناكو ، المجر ، هايتي ، فنلندا ، بوليفيا ، شيلي ، كوستاريكا ، كرواتيا ، جمهورية التشيك ، إستونيا ، المكسيك ، النرويج ، الفلبين.</ li>
    <li> 4. هذا العرض ساري على الحسابات القياسية والحسابات الإسلامية القياسية فقط。 </li>
    <li> 5. يُحظر الحصول على أموال المكافأة و / أو المكافأة التجارية على حسابين أو أكثر بنفس عنوان IP / المعرف / جواز السفر. بغض النظر عن عدد حسابات التداول التي قد يمتلكها العميل مع PU Prime ، فإن العرض الترويجي ينطبق على حساب واحد فقط. </ li>
    <li> 6. سيتلقى العملاء 50 دولارًا أمريكيًا (أو ما يعادلها) من الأرصدة بعد الاشتراك في هذا العرض بنجاح وليس هناك أي شرط لإجراء إيداع. </ li>
    <li> 7. لا يمكن سحب مبالغ الائتمان. ومع ذلك ، لا يزال بإمكانك سحب جزء من الأرباح المكتسبة من تداولك. </ li>
    <li> 8. يجب إغلاق جميع المراكز المفتوحة قبل التقدم بطلب السحب. بمجرد اختيار السحب ، سيتم خصم المكافأة من الحساب ؛ </li>
    <li> 9. يحتفظ PU Prime بالحق في تغيير الشروط أو رفض منح المكافأة في أي وقت. </ li>`,
    selectDesp: '* يرجى تحديد الحساب الذي ترغب في الانضمام إلى نشاط بونص عدم الإيداع.',
    descp1: 'هل تريد بدء التداول دون الالتزام باستثمار من البداية؟',
    descp2: 'استخدم بونصك البالغ 50 دولارًا لبدء جني الأرباح <br> دون المخاطرة بأي من أموالك على الإطلاق.',
    tnc2:
      '<li> الحد الأدنى للمبلغ الذي يمكنك سحبه هو 100 دولار أمريكي (أو ما يعادله) ، والحد الأقصى للمبلغ المراد سحبه هو 200 دولار أمريكي (أو ما يعادله). ستتم إزالة أي أرباح محققة علاوة على هذا المبلغ المحدد عندما نقوم بمعالجة طلب السحب الخاص بك. </ li>',
    header: 'شروط وأحكام العرض الترويجي',
    successful: 'تم التقديم بنجاح',
    failed: 'فشل التقديم',
    referFriends: {
      intro: `أكمل 3 خطوات لتحصل على {amount} الخاصة بك`,
      step1: 'الخطوة 1',
      step1Descp: 'افتح حسابًا بنجاح',
      step2: 'الخطوة 2',
      step2Descp: 'الإيداع بنجاح',
      step3: 'الخطوة 3',
      step3Descp: 'اكتمل حجم التداول',
      totalEanringTitle: `مُجمل الأرباح<br/>(بالدولار الأمريكي أو ما يعادله)`,
      totalEarningTip: '*تخضع  إضافة المكافأة إلى حسابك إلى موافقة.',
      redeem: 'مقايضة',
      intro2: 'في هذه الأثناء، يمكنك أيضًا إحالة أصدقائك',
      shareLink: 'شارك رابط إحالتك المخصص',
      shareLinkDescp: 'انقر زر "مشاركة" لتحصل على رابطك الفريد وانسخه عبر الوسائط الاجتماعية المتعددة.',
      share: 'مشاركة',
      total: 'إجمالي الإحالات المقايضة',
      social: 'شارك رابطك  من خلال الوسائط الاجتماعية المتعددة',
      clients: 'العملاء',
      table: {
        referralsNo: 'عدد الإحالات',
        name: 'الاسم',
        country: 'البلد',
        openAccount: 'افتح حسابًا بنجاح',
        despositSuccess: 'الإيداع بنجاح',
        volumeCompleted: 'اكتمل حجم التداول',
      },
      totalRedeemableTitle: `مُجمل الأرباح القابلة للمقايضة<br/>(بالدولار الأمريكي أو ما يعادله)`,
      totalRedeemableTip: '*تخضع  إضافة المكافأة إلى حسابك إلى موافقة.',
      referrer: 'المُحيل',
      referral: 'الإحالة',
      dialog: {
        confirmQ: 'مقايضة أرباحك',
        confirmQ2: 'المبلغ الإجمالي مُحولًا إلى عملتك الأساسية {amount} {currency}.',
        redeem: 'مقايضة الآن',
        agree: '* بالنقر على مقايضة الآن، فإنك توافق على كل {link}.',
        overBonus: 'اكتشف نظامنا أنك وصلت إلى حدنا الأقصى للمبلغ . للمطالبات، يرجى التواصل مع دعم العملاء الخاص بنا.',
      },
    },
    depositBonus: {
      banner: 'طالب بمكافأة رصيدك البالغة 50%!<br>احصل على ما يصل إلى 10،000 دولار',
      depositBonus: 'طالب بمكافأتك وعزز تداولاتك',
      title: 'هل تريد أن تزيد من هامش حسابك وأن تعزز أحجام تداولك؟ مول حسابك اليوم، وطالبنا بالمكافأة البالغة 50%!',
      fund: 'مول حسابك',
      tnc: '*تطبق الأحكام والشروط، {link} للمشاهدة',
      link: 'انقر هنا',
      bonusHistory: 'تاريخ المكافأة',
      overBonus:
        '* لقد بلغت الحد الأقصى لأرصدة المكافأة البالغ ${totalLimit} دولار، لا تؤهل الإيداعات الإضافية إلى أرصدة مكافآت.',
      opt: 'تأكيد الاشتراك والانسحاب',
      tick: 'يرجى إزالة العلامة من الصندوق إذا كنت ترغب في الانسحاب أو وضعها إذا كنت ترغب في الاشتراك.',
      saveChange: 'حفظ التغيرات',
      depositDate: 'تاريخ الإيداع',
      credit: 'الرصيد',
      optIn: 'تم الاشتراك بنجاح',
      optOut: 'تم الانسحاب بنجاح',
      optInDescription: 'يرجى النقر على الزر أدناه للاشتراك في المكافأة الإضافية البالغة 50%',
      optOutDescription: 'يرجى النقر على الزر أدناه للانسحاب من المكافأة الإضافية البالغة 50%',
      activate: 'تنشيط',
      signOut: 'تسجيل خروج',
      banner1: 'طالب بمكافأة رصيدك البالغة 50%',
      claimDescription: `<p>كيف تطالب</p>
      <li>1.	نشط قبل إيداعك </li>
      <li>2.	مول حسابك التداولي لتؤهل للمكافأة </li>
      <li>3.	ستضاف المكافأة تلقائيًا إلى حسابك </li>
      <li>4.	استمتع بتداولك </li>`,
      eligibleDescription: `<p>الأهلية</p>
      <li>1.	يجب أن تنشط المكافأة قبل إيداعك </li>
      <li>2.	المكافأة غير متاحة إلا للحساب القياسي والحساب القياسي الإسلامي والحساب الممتاز و الحساب الإسلامي الممتاز </li> 
      <li>3.	يمكنك طلب بونوص الإيداع بنسبة 50٪ مرة واحدة فقط ، وكل الإيداعات التالية لا تحسب إلا وفق نسبة 20%</li>`,
      optInDialog: `تهانينا!{br}
      لقد نجحت في تنشيط مكافأة الرصيد البالغة 50%.`,
      optOutDialog: `لقد انسحبت بنجاح من مكافأة الرصيد البالغة 50%.`,
    },
    cryptoCashback: {
      banner: {
        bannerContext: `
          <span class="title">ضاعف مدخراتك</span></br>
          <span class="desc"> واحصل على ما يصل إلى 10،000 دولار استرداد نقدي ورسوم مقايضة صفرية</span>
        `,
        activityBannerContext: `
          <span class="title">ضاعف ادخاراتك</span></br>
          <span class="desc">البيع في الاتجاه الهابط أو الشراء من القاع؟</span></br>
          <span class="desc">أحصل على ما يصل إلى 10,000 دولار كاسترداد نقدي عندما تتداول على العملات الرقمية مع PU Prime</span>
        `,
      },
      active: {
        title: `تفعيل الاسترداد النقدي للعملات المشفرة`,
        desc: `يرجى النقر على الزر أدناه للتفعيل والمشاركة في العرض`,
      },
      deactive: {
        title: `إلغاء تفعيل الاسترداد النقدي للعملات المشفرة`,
        desc: `يرجى النقر فوق الزر أدناه لإلغاء تفعيل عرض الاسترداد النقدي للعملات الرقمية والانسحاب منه.`,
      },
      button: {
        active: `تفعيل`,
        deactive: `إلغاء التفعيل`,
        submit: `إرسال`,
        fund: `قم بتمويل حسابك`,
      },
      claimTitle: `كيفية الحصول عليه`,
      claimDescriptions: [
        `قم بتفعيل العرض الترويجي قبل إجراء الإيداع.`,
        `قم بتمويل حساب التداول الخاص بك لتلبية متطلبات الإيداع（≥ 500 دولار أمريكي)`,
        `سيعتمد الاسترداد النقدي لكل حصة على إجمالي مبلغ الإيداع الصافي (راجع الشروط والأحكام).`,
        `استمتع بالتداول واحصل على المزيد من القيم مع كل تداول!`,
      ],
      eligibilityTitle: `الأهلية`,
      eligibleDescriptions: [
        `.يجب تفعيل هذا العرض الترويجي قبل الإيداع.`,
        `هذا العرض متاح فقط للحسابات النموذجية والحسابات الإسلامية النموذجية. إذا كان لدى العميل عدة حسابات تداول مؤهلة، فيمكن اختيار حساب واحد فقط مؤهل للمشاركة في هذا العرض الترويجي`,
        `يستمر هذا العرض لمدة 30 يومًا من تاريخ أول إيداع ناجح لك بعد التفعيل`,
        `تسري خدمة الاسترداد النقدي طبقاُ لحجم التداول على جميع منتجات العملات المشفرة باستثناء ما يلي: ALGUSD ، MTCUSD ، XRPUSD ، BCHUSD ، XLMUSD ، EOSUSD ، BTCBCH ، BTCETH BTCLTC ، BTCXAU ، ETHBCH ، ETHLTC ، ETHXAU`,
        `الحد الأقصى للاسترداد النقدي الإجمالي هو 1000$ لكل عميل`,
      ],
      tnc: '*تطبق الأحكام والشروط، {link} للمشاهدة',
      link: 'انقر هنا',
      message: {
        congratulate: `تهانينا!`,
        congratulateContext: `لقد قمت بتفعيل عرض الاسترداد النقدي للعملات المشفرة بنجاح.`,
        signedOutPromo: `بالنقر على زر التأكيد، سيتم تسجيل خروجك من العرض الترويجي.`,
        deactivePromo: `لقد تم إلغاء تفعيل عرض الاسترداد النقدي للعملات المشفرة بنجاح.`,
      },
      dialog: {
        selectAccountContext: `يرجى تحديد الحساب الذي ترغب في أن ينضم إلى عرض مكافأة التداول`,
        tnc: `الشروط والأحكام`,
        tncContext: {
          tnc1: `1. بعد تفعيل هذا العرض الترويجي، يتعين عليك أيضًا إيداع الأموال وتداول العملات المشفرة كما هو مطلوب لتلقي الاسترداد النقدي المقابل.`,
          tnc1desc: `* فقط الإيداعات الجديدة التي تم إجراؤها خلال فترة العرض الترويجي صالحة للعرض الترويجي. يستثنى من ذلك التحويلات الداخلية ومكافآت الإحالة إلى صديق والتسويات النقدية.`,
          tnc2: `2. العملاء المؤهلون (العملاء الحاليون والجدد على حد سواء)، الذين يستوفون المعايير المنصوص عليها هنا ، يمكنهم الحصول على استرداد نقدي، يساوي:`,
          tnc3: `3. المنتجات المؤهلة: جميع منتجات العملات المشفرة باستثناء ALGUSD و MTCUSD و XRPUSD و BCHUSD و XLMUSD و EOSUSD و BTCBCH و BTCETH و BTCLTC و BTCXAU و ETHBCH و ETHLTC و ETHXAU.`,
          tnc4: `4. حجم التداول الفعلي: الأوامر المفتوحة والمغلقة حديثًا خلال فترة العرض الترويجي. المراكز المفتوحة لمدة تقل عن 5 دقائق، والمراكز المغلقة، والمراكز التحوط لا تعتبر صفقات صالحة لهذا العرض الترويجي.`,
          tnc5: `5. مدة العرض الترويجي: 30 يومًا بعد قيام المشتركين بأول إيداع ناجح.`,
          tnc5desc: `* إذا قام العميل بتفعيل العرض ولم يقم بإيداع، فسينتهي العرض تلقائيًا أيضًا بعد 30 يومًا.`,
          tnc6: `6. لا يمكن استخدام هذا العرض بالتزامن مع أي عرض آخر.（باستثناء حملة "يمكنك أن تكون تاجرًا أيضًا"، قم بإحالة صديق وأحصل على مكافأة إيداع 50٪)`,
        },
        agreeTnc: `لقد قرأت ووافقت على الأحكام والشروط`,
      },
      field: {
        netDeposit: `صافي الإيداع الاستر`,
        usddesc: `الدولار الأمريكي (أو ما يعادله بالعملة)`,
        cashback: `داد النقدي`,
        standardLot: `الحصة القياسية`,
        date: `التاريخ`,
        depositAmount: `قيمة الايداع`,
        withdrawAmount: `قيمة السحب`,
        netDepositAmount: `صافي مبلغ الإيداع`,
        rebateLot: `الخصم / اللوت`,
        tradingDate: `تاريخ التداول`,
        tradingLot: `حصة التداول`,
        cashRebate: `الخصم النقدي`,
      },
      depositHistory: `سجل الإيداع`,
      tradingRewardHistory: `تاريخ مكافأة التداول`,
    },
    xmasMakeAWish: {
      banner: {
        bannerContext: `
          <div class="title">اربح جوائز تصل قيمتها إلى 5,000 دولار!</div>
          <div class="desc">في السحب المؤكد الفوز لدينا</div>
        `,
      },
      active: {
        title: 'قم بتفعيل برومو العرض MAKE A WISH',
        desc: 'الرجاء النقر فوق الزر أدناه لتنشيط عرض عيد الميلاد MAKE A WISH',
      },
      deactive: {
        title: 'برومو عرض عيد الميلاد MAKE A WISH',
        desc: 'الرجاء النقر فوق الزر أدناه لتنشيط عرض عيد الميلاد MAKE A WISH',
      },
      button: {
        active: 'تفعيل',
        deactive: 'إلغاء التفعيل',
        submit: 'استلام',
        fund: 'قم بتمويل حسابك',
        enterLuckyDraw: 'ادخل Lucky Draw',
        redeem: 'استرد',
        redeemed: 'افتدى',
      },
      claimTitle: 'كيف يمكن الحصول عليه',
      claimDescriptions: [
        'قم بتفعيل العرض الترويجي قبل الإيداع.​',
        'قم بتمويل حساب التداول الخاص بك لتلبية متطلبات الإيداع الخاصة بالهدية التي تريدها ، ثم قم بإجراء عدد الصفقات المقابل المطلوب. (انظر جدول الهدايا أدناه).',
        'عندما تكون مؤهلاً ، ستحصل على فرصة للفوز بإحدى الهدايا في فئتك المقابلة. (انظر جدول الهدايا أدناه).',
        'انقر على زر ادخل Lucky Draw لتستمتع بهديتك.',
        'سيتم تسجيل الهدايا التي تم ربحها ، وسيتم توزيع جميع الهدايا بعد 7 أيام من انتهاء العرض الترويجي.',
      ],
      eligibilityTitle: 'الأهلية',
      eligibleDescriptions: [
        'أنواع الحسابات المؤهلة: هذا العرض متاح فقط للحسابات القياسية والحسابات الإسلامية القياسية، سواء في MT4 أو MT5.',
        'إذا كان لدى العميل عدة حسابات قياسية، فيمكنه تحديد حساب قياسي واحد فقط للمشاركة في هذا العرض الترويجي.',
        'الدول المؤهلة: كل ما عدا الصين',
        'يجب أن تتم الإيداعات والصفقات خلال فترة العرض حتى تعتبر صالحة.',
        'حجم التداول الفعال: مراكز الفوركس والذهب والفضة والنفط الخام إذا احتفظت بها لأكثر من 5 دقائق.',
      ],
      moreDetail: '{prize} لمزيد من التفاصيل حول توزيع الجوائز، قم بعرض',
      prize: 'قائمة الجوائز هنا',
      tnc: '*الشروط والأحكام انقر  {link}  لمشاهدة',
      link: 'هنا',
      luckyDraw: {
        description: [
          `اضغط على زر Lucky Draw للفوز <span class="font-bold">بإحد</span> الجوائز المذكورة أعلاه!`,

          'ليست الهدايا التي تبحث عنها؟ استمر في الإيداع والتداول للوصول إلى المستوى الذي تريده.',
        ],
        mainTitle: 'فئة هديتك الحالية:',
        commingSoon: {
          title: `تمنى أمنية`,
          content: `قريبا في منتصف نوفمبر استعد لدخول السحب.`,
        },
        noChanceToDraw: {
          title: `الإيداع الآن`,
          content: `قم بتمويل حسابك بمبلغ لا يقل عن 200 $ للدخول في سحب الحظ`,
        },
        endPromotion: {
          title: `انتهى سحب الحظ`,
          content: `شكرا لكم على مشاركتكم! تأكد من الخصول على هداياك بحلول 31 مارس 2023.`,
        },
      },
      currentDepositLots: `الإيداع الحالي / اللوتات المتداولة`,
      transactionHistory: `تاريخ المعاملات`,
      transactionHistoryDesc: `*معاملة الإيداع هي المبلغ المودع وقيمة الإيداع التي خصمت من الدخل العائد عليك من السحب.`,
      giftRedemption: `استرداد الهدية`,
      giftRedemptionDesc: [
        `سنتصل بك مرة أخرى لاسترداد الهدية (الهدايا) المادية.`,
        `*يمكن استبدال جميع الجوائز فقط من 2 فبراير 2023 حتى 2 مارس 2023.`,
      ],
      field: {
        depositAmount: `قيمة الإيداع`,
        lotsTraded: `اللوتات المتداولة`,
        date: `التاريخ`,
        type: `النوع`,
        deposit: `*إيداع`,
        tradingLots: `الإيداع / حصص التداول`,
        prizeWon: `الجائزة / الجوائز التي فزت بها`,
        creditBonus: `مكافأة الائتمان`,
        cashVoucher: `قسيمة نقدية`,
        physicalRewards: `المكافآت المادية`,
        depositTradingVolume: `الإيداع / حصص التداول`,
        gifts: `الهدايا (بالدولار الأمريكي)`,
        accountID: `اسم الحساب`,
        current: `حالياً`,
        luckyDrawDeposit: `إيداع`,
      },
      dialog: {
        selectAccountContext: `يرجى تحديد الحساب الذي ترغب في تلقي المكافأة الائتمانية فيه (حساب MT4 / MT5 فقط)`,
        tnc: 'الشروط والأحكام',
        tncContext: {
          tnc1: `1. يحتاج العملاء إلى تنشيط هذا العرض الترويجي بنجاح داخل بوابة عملائهم أولاً ، ثم إيداع الأموال والتداول كما هو مطلوب لتلقي الهدايا المقابلة.`,
          tnc2: `2.	حجم التداول الفعال: اللوتات القياسية لأزواج العملات والذهب والفضة والنفط الخام الفوري والعقود الآجلة للنفط الخام. الأوامر المفتوحة والمغلقة حديثًا خلال فترة العرض الترويجي. الصفقات المفتوحة لأقل من 5 دقائق، والصفقات المغلقة، والصفقات المحمية لا تعتبر صفقات صالحة لهذا العرض الترويجي.`,
          tnc3: `3.	يتم رسم الإحصائيات وحجم الطلب بتوقيت ميتا تريدر4 01/11/2022-31/01/2023.`,
          tnc4: `4.	إذا لم يكن من الممكن شراء الهدية بسبب عوامل قاهرة مثل كون المنتج ليس متوفر بالمتجر أو نفاد المخزون، فيمكن للعميل التقدم للحصول على نقود معادلة لسعر السوق للهدية المذكورة. هذا السعر بالدولار الأمريكي (أو العملة المكافئة).`,
          tnc5: `5.	لا يمكن استخدام هذا العرض مع أي عرض آخر.（ 100 مكافأة تداول، 20٪ مكافأة حصرية ، 50٪ مكافأة إيداع، VN %20 مكافأة إيداع).`,
        },
        agreeTnc: `لقد أطلعت وأوافق على الشروط والأحكام`,
        prizeList: ` قائمة الجوائز هنا`,
        luckyDrawTransaction: `سحب الحظ`,
        xmasGift: `تهانينا على هدية عيد الميلاد!`,
      },
      message: {
        congratulate: 'تهانينا!',
        congratulateContext: `في السحب المؤكد الفوز لدينا برومو عرض عيد الميلاد MAKE A WISH`,
        signedOutPromo: `بالضغط على زر التأكيد، سيتم تسجيل خروجك من العرض الترويجي!`,
        deactivePromo: `لقد نجحت في إلغاء الاشتراك في عرض عيد الميلاد Make a Wish .`,
        redeemAmount: `سيتم إضافة المبلغ إلى حسابك!`,
      },
      tableContent: {
        luckyDraw: `Lucky Draw`,
        depositTrade: `الإيداع / التداول`,
      },
    },
    firstTimeDeposit: {
      banner: {
        bannerContext: `
          <div class='title'>احصل على <span class='bg_yellow'>نقد فوري</span> على إيداعك الأول</div>
          <div class="desc">احصل على ما يصل إلى 50 دولار أمريكي نقدًا عند إيداع إيداعك الأول مع PU Prime!</div>
        `,
      },
      active: {
        title: `نشط استردادك النقدي على إيداعك الأول`,
        desc: `يرجى نقر الزر التالي للتنشيط والاشتراك في النشاط.`,
      },
      deactive: {
        title: `إلغاء تنشيط استردادك النقدي على الإيداع الأول`,
        desc: `يُرجى نقر الزر التالي لإلغاء التنشيط والانسحاب من عرض الاسترداد النقدي على الإيداع الأول.`,
      },
      button: {
        active: `تنشيط`,
        deactive: `إلغاء التنشيط`,
        fund: `موِّل حساك.`,
        redeem: `مقايضة`,
      },
      claimTitle: `كيفية المطالبة`,
      claimDescriptions: [
        `نشّط العرض الترويجي قبل الإيداع.`,
        `موّل حسابك التداولي لتلبية متطلبات الإيداع للاسترداد النقدي الذي ترغب فيه (≥ 200 دولار أمريكي أو عملات مكافئة).`,
        `ستعتمد قيمة قسيمة الاسترداد النقدي على مبلغ إيداعك الأول (شاهد جدول الاسترداد النقدي في الأحكام والشروط).`,
        `لتنشيط قسيمة استردادك النقدي، تداول ≥ حصتي فوركس أو ذهب أو فضة أو نفط.`,
        `يمكن الآن مقايضة قسيمتك المُنشطة. سيظهر هذا المبلغ في رصيد حسابك.`,
      ],
      eligibilityTitle: `الأهلية`,
      eligibleDescription1: `أنواع الحسابات المؤهلة: هذا العرض غير مفتوح إلا للحسابات القياسية والحسابات الإسلامية القياسية، في كل من MT4/MT5.`,
      eligibleDescription2: `إذا كان لدى عميل العديد من الحسابات القياسية، فلن يمكنه إلا اختيار حساب قياسي واحد للاشتراك به في هذا العرض الترويجي.`,
      eligibleDescription3: `الدول المؤهلة: جميع الدول ما عدا بر الصين الرئيسي`,
      eligibleDescription4: `العملاء المؤهلون: العملاء الجدد والحاليين الذين ليس لديهم تاريخ إيداع، يشمل ذلك العملاء التابعين لوسطاء التقديم والتكلفة لكل إجراء.`,
      eligibleDescription4Desc: `*حسابات تداول وسيط التقديم والتكلفة لكل إجراء الشخصية غير مؤهلة.`,
      eligibleDescription5: `يجب إجراء الإيداع الأول أثناء فترة العرض الترويجي.`,
      eligibleDescription6: `الأوامر التي بقيت مفتوحة لمدة تقل عن 5 دقائق لا تُعتبر صالحة لهذا العرض الترويجي.`,
      tnc: `*تطبق الأحكام والشروط، {link} للمشاهدة`,
      link: `انقر هنا`,
      dialog: {
        selectAccountContext: `يرجى تحديد الحساب الذي ترغب في أن ينضم إلى عرض مكافأة التداول`,
        tnc: `الأحكام والشروط`,
        tncContext: {
          tnc1: `1. يتعين عليك أيضًا بعد تنشيط هذا العرض الترويجي أن تودع الأموال وتتداول حصتين معياريتين كما هو مطلوب للحصول على الاسترداد النقدي المقابل.`,
          tnc1desc: `*لا تصلح لهذا العرض الترويجي إلا الإيداعات الجديدة المودعة أثناء فترة العرض الترويجي. يُستثنى من ذلك التحويلات الداخلية، ومكافآت إحالة الأصدقاء والتسويات النقدية.`,
          tnc2: `2. لن يُحسب إلا الإيداع الأول المودع في أثناء هذه الفترة في حساب الاسترداد النقدي.`,
          tnc3: `3. يمكن للعملاء المؤهلين (كل من الجدد والقائمين)، والذين يحققون المعايير المنصوص عليها هنا، أن يحصلوا على استرداد نقدي يعادل:`,
          tnc4: `4. المنتجات المؤهلة: الفوركس والذهب والفضة والنفط.`,
          tnc5: `5. حجم التداول الفعال: الأوامر المفتوحة والمغلقة حديثًا أثناء فترة العرض الترويجي. لا تُعد المراكز المفتوحة لأقل من 5 دقائق، ولا المراكز المقفلة، ولا مراكز التحوط تداولات صالحة لهذا العرض الترويجي.`,
        },
        agreeTnc: `لقد قرأتُ الأحكام والشروط وأوافق عليها`,
        grossDeposit: `لإجمالي الإيداع بالدولار الأمريكي`,
        grossDepositDesc: `أو عملات مكافأة`,
        cbVoucher: `قسيمة الاسترداد النقدي`,
        tableDesc: `* يمكن للعملاء استرداد قسيمة نقدية تصل إلى 50 دولارًا (أو ما يعادلها).`,
      },
      voucher: {
        title: `FTD كاش باك`,
        accountID: `اسم الحساب`,
        accumulateVolume: `حجم التداول التراكمي`,
        standardLots: `حصص المعيارية`,
        desc: `تداول ≥ حصتي فوركس أو ذهب أو فضة أو نفط لتنشيطها`,
        clickForRedeem: `انقر فوق الزر التالي لإرساله إلى رصيد حسابك.`,
        ftdLessThan200: `إيداعك الأولي أقل من الحد الأدنى للمبلغ المطلوب.`,
      },
      message: {
        congratulate: `تهانينا!`,
        congratulateContext: `لقد نجحت في تفعيل عرض الاسترداد النقدي FTD.`,
        signedOutPromo: `بالنقر على زر التأكيد، سيُسجَّل خروجك من العرض الترويجي!`,
        deactivePromo: `لقد نجحت في إلغاء تنشيط عرض الاسترداد النقدي على الإيداع الأول.`,
        successRedeem: `لقد استرددت مبلغ الاسترداد النقدي لإيداع المرة الأولى بنجاح.`,
      },
    },
    notification: {
      ndb: {
        inst1: 'عزيزي العميل،',
        inst2: `نود تذكيرك بأن عرض البونص الترحيبي سينتهي في <span>{date} (GMT+3)</span>`,
        inst3:
          'وفقًا لشروط وأحكام العرض الترويجي، يرجى العلم بأن المكافأة على حسابك المودع ستتم إزالتها تلقائيًا بمجرد انتهاء العرض الترويجي. بالنسبة لتلك الحسابات التي لا تحتوي على إيداع أو سجل معاملات، ستتم إزالة المكافأة والرصيد تلقائيًا بمجرد انتهاء العرض الترويجي أيضًا.',
        inst4: `ذا كانت لديك أي أسئلة أو كنت بحاجة إلى أي مساعدة، فيرجى الاتصال بفريق الدعم لدينا عبر الدردشة الحية أو البريد الإلكتروني: <span>{mail}</span> <span>{phone}</span> أو الهاتف.`,
        cantInerTransfer: 'نظرًا لأنك تشارك في حملتنا ، فلن نتمكن من معالجة طلب التحويل الخاصبك',
      },
      db: {
        inst1: 'عزيزي العميل،',
        inst2: `نود أن نذكرك بأن عرض بونص الإيداع بنسبة 50٪ سينتهي في <span>{date} (GMT+3)</span>.`,
        inst3:
          'وفقًا لشروط وأحكام العرض الترويجي، يرجى العلم بأن المكافأة على حسابك ستتم إزالتها تلقائيًا بمجرد انتهاء العرض الترويجي. بالنسبة لتلك الحسابات التي لا تحتوي على إيداع أو سجل معاملات ، ستتم إزالة المكافأة والرصيد تلقائيًا بمجرد انتهاء العرض الترويجي أيضًا.',
        inst4: `إذا كانت لديك أي أسئلة أو كنت بحاجة إلى أي مساعدة ، فيرجى الاتصال بفريق الدعم لدينا عبر الدردشة المباشرة أو البريد الإلكتروني: <span>{mail}</span> <span>{phone}</span> أو الهاتف.`,
      },
    },
    editSuccess: '',
  },
  downloads: {
    mt4: 'منصة الميتا ترايدر 4',
    mt4Desc:
      'قم بتنزيل MetaTrader 4 لأجهزة الكمبيوتر الشخصية وأجهزة Mac ومتصفحات الويب والهواتف الذكية والأجهزة اللوحية',
    windows: 'ويندوز',
    mac: 'ماك',
    ip: 'إيفون / آيباد',
    android: 'اندرويد / تابليت',
    web: 'تداول بالويب',
    mt5: 'منصة ميتا ترايد 5',
    mt5Desc:
      'قم بتنزيل MetaTrader 5 لأجهزة الكمبيوتر الشخصية وأجهزة Mac ومتصفحات الويب والهواتف الذكية والأجهزة اللوحية',
    app: 'تنزيل تطبيقنا',
  },
  forgetPwReq: {
    tip: 'أدخل عنوان البريد الإلكتروني لحسابك لإعادة تعيين كلمة المرور الخاصة بك.',
    formValidation: { emailReq: 'مطلوب ايميل الدخول' },
    succ:
      'لقد أرسلنا إليك للتو بريدًا إلكترونيًا يحتوي على إرشادات لإعادة تعيين كلمة المرور الخاصة بك. <br> يُرجى التأكد من مراجعة <br> مجلد الرسائل غير المرغوب فيها في بريدك الإلكتروني.',
  },
  loginClient: {
    header: 'منفذ العميل',
    failedMasseage: 'حاول مرة اخرى',
    existingClientPortalAccess:
      'نرى أن لديك بالفعل <br /> وصول موجود إلى بوابة العميل. <br /> قم بتسجيل الدخول باستخدام بريدك الإلكتروني لفتح <br /> حساب جديد!',
    footer: `تحذير من المخاطر: تداول العقود مقابل الفروقات (CFD) يحمل درجة عالية من المخاطرة برأس المال الخاص بك ويمكن أن يؤدي إلى خسائر ، يجب أن تتداول فقط بأموال يمكنك تحمل خسارتها قد لا يكون تداول العقود مقابل الفروقات مناسبًا لجميع المستثمرين ، يرجى التأكد من فهمك الكامل للمخاطر التي تنطوي عليها واتخاذ الإجراءات المناسبة لإدارتها.<br><br><br>
    {platformEntityName} مرخصة ومنظمة من قبل هيئة الخدمات المالية في سيشيل برخصة رقم SD050.<br>
    شركة PU Prime LLC مرخصة ومنظمة من قبل هيئة الخدمات المالية في سانت فنسنت وجزر غرينادين برخصة رقم 271 LLC 2020.<br>
    The entities above are duly authorized to operate under the PU Prime brand and trademark.<br>`,
    formValidation: { emailReq: 'مطلوب عنوان البريد الإلكتروني' },
    invailidRafLink: `رابط الإحالة المستخدم غير صالح.سجل أدناه للحصول على حساب جديد.`,
  },
  myProfile: { changeDetails: 'إذا كنت ترغب في تحديث تفاصيل ملفك الشخصي الحالي ، فلا تتردد في الاتصال بـ {mailTo}' },
  openAdditAcc: {
    chooseTradePlat: 'اختر منصة التداول',
    chooseAccType: 'اختر نوع الحساب',
    chooseCurrency: 'اختر عملة الحساب',
    chooseLeverage: 'اختر الرافعة المالية',
    chooseBalance: 'رصيد الحساب',
    notes: 'ملاحظات اضافية',
    currError: 'يجب عليك تحديد عملة الحساب',
    platErr: 'عليك اختيار منصة التداول',
    accTypeErr: 'عليك اختيار نوع الحساب',
    leverageErr: 'عليك اختيار الرافعة',
    balanceErr: 'عليك اختيار الرصيد',
    succ:
      '<p> جارٍ إعداد حسابك الحي الإضافي وستتلقى رسالة بريد إلكتروني تحتوي على تفاصيل تسجيل الدخول قريبًا. يمكنك عرض الحالة الحالية لتطبيقك من الصفحة الرئيسية. </ p>',
    byTicking: 'لقد قرأت ووافقت على {tnc}.',
    tnc: 'الشروط والاحكام',
    pamm:
      'حسابات PAMM هي فقط للمستثمرين الذين لديهم ترتيبات إدارة أموال حالية. لا يمكن لأي شخص التداول في حسابات PAMM باستثناء مدير الأموال.',
    pammConfirm: 'يرجى تأكيد ما إذا كنت ستستمر في اختيار نوع الحساب هذا.',
  },
  openDemoAdditAcc: {
    header: 'تكوين الحساب التجريبي',
    button: 'افتح حساب تجريبي',
    liveButton: 'إفتح حساب حقيقي',
    succ: 'تم إعداد حسابك التجريبي الإضافي وستتلقى رسالة بريد إلكتروني تحتوي على تفاصيل تسجيل الدخول الخاصة بك قريبًا.',
  },
  resetPassword: {
    header: 'اعادة تعيين كلمة المرور',
    succ:
      'لقد تم تحديث كلمة السر الخاصة بك. يمكنك الآن استخدام كلمة المرور الجديدة للوصول إلى حساب تداول MT4 / MT5 الخاص بك.',
    succ2: 'لقد تم تحديث كلمة السر الخاصة بك.',
    failed: 'انتهت صلاحية الرمز',
    emailNotFound: 'للأسف لم يتم التعرف على عنوان بريدك الإلكتروني',
  },
  fileUploader: {
    supportedFormat: 'أنواع الملفات المدعومة: png ، jpg ، jpeg ، bmp ، pdf ، doc ، docx',
    maxSize: 'الحد الأقصى لحجم ملف التحميل: {maxSize} ميغا بايت',
    maxFileNum: 'أقصى رقم للملف: {maxFileNum}',
  },
  transcationHistory: {
    deposit: 'الايداعات',
    withdraw: 'السحوبات',
    desc: {
      incomplete:
        'إذا قمت بإنهاء عملية الإيداع أو فشلت في إكمالها ، فستظهر حالة المعاملة على أنها "غير مكتملة". سيُطلب منك إنشاء إيداع جديد حيث لا يمكن استئناف هذه المعاملة.',
      success: 'كانت معاملتك ناجحة. يجب أن تظهر أموالك في حسابك قريبًا',
      proc: 'معاملتك قيد المعالجة. يرجى الاتصال بـ {mailTo}',
      failed: 'فشلت معاملتك. يرجى الاتصال بـ {mailTo} للحصول على مزيد من المعلومات',
      rejected: 'تم رفض معاملتك. يرجى الاتصال بـ {mailTo} للحصول على مزيد من المعلومات',
    },
    withdrawDesc: {
      sub: 'تم تقديم معاملتك. الرجاء الانتظار حتى يكتمل.',
      suc: 'كانت معاملتك ناجحة. يجب أن تظهر أموالك في حسابك قريبًا',
      proc: 'معاملتك قيد المعالجة. يرجى الاتصال بـ {mailTo}',
      incomp: 'تتطلب معاملتك خطوات إضافية لإتمامها. يرجى الاتصال بـ {mailTo} للحصول على مزيد من المعلومات',
      cancel: 'تم إلغاء معاملتك. يرجى الاتصال بـ {mailTo} للحصول على مزيد من المعلومات',
      fail: 'فشلت معاملتك. يرجى الاتصال بـ {mailTo} للحصول على مزيد من المعلومات',
      rej: 'تم رفض معاملتك. يرجى الاتصال بـ {mailTo} للحصول على مزيد من المعلومات',
    },
    cancelWithdraw: `هل ترغب في إلغاء طلب السحب؟`,
    cancelError: `تتم معالجة عملية السحب الخاصة بك في الوقت الحالي. لذلك ، لا يمكننا قبول طلب الإلغاء الخاص بك. لمزيد من المعلومات ، يمكنك التواصل معنا باستخدام ميزة الدردشة الحية.`,
    dialog: {
      appliedAmount: `المبلغ المستخدم`,
      applicationTime: `وقت التطبيق`,
      processedTime: `وقت المعالجة`,
      accountCurrency: `عملة الحساب`,
      creditDeducted: `خصم الائتمان`,
      processedNote: `ملاحظة المعالجة`,
      bankName: `اسم البنك`,
      bankAccountNumber: `رقم الحساب المصرفي`,
      abaSortCode: `ABA / رمز الفرز`,
      swiftCode: `كود سويفت`,
      bankStatement: `كشف حساب بنكى`,
      bankAddress: `عنوان البنك`,
      accountName: `اسم الحساب`,
      accountNumber: `رقم الحساب`,
      cardholderName: `إسم صاحب البطاقة`,
      cardNumber: `رقم البطاقة`,
      cardExpiryDate: `تاريخ انتهاء صلاحية البطاقة`,
      walletAddress: `عنوان المحفظة`,
      ewalletEmailAddress: `عنوان البريد الإلكتروني للمحفظة الإلكترونية`,
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'للأسف لا يمكن متابعة تحويلك، لأن هذا سينتج عنه انخفاض الهامش المتاح في حسابك دون مستوى الإيقاف البالغ 100%',
      equityMargin: 'للأسف لا يمكننا حاليًا معالجة تحويلك لأن حسابك مُحوَّط بالكامل.',
      equityContinue:
        'لقد اكتشفنا أن لديك مراكز مفتوحة حاليًا، قد تتعرض للإيقاف بعد إرسال طلب سحبك. يرجى تأكيد أنك ترغب في المتابعة.',
      blackList: 'لقد حُظِر على هذا الحساب إجراء التحويلات بين الحسابات، يرجى التواصل مع {supportEmail}',
    },
    pleaseNote: 'يرجى الملاحظة:',
    suc: 'تم تحويل أموالك بنجاح',
    warn1:
      'أنت مسؤول عن الحفاظ على رصيد الحساب الذي لن يؤدي إلى استدعاء الهامش. إذا كان لديك أي مراكز مفتوحة حاليًا ، فيرجى التأكد من الحفاظ على الهامش المطلوب في حسابك. تتم معالجة الأموال المحولة إلى حسابات بالعملة نفسها على الفور.',
    warn2: 'في حالة تحويل الأموال بين حسابات بعملات مختلفة ، أرسل بريدًا إلكترونيًا إلى {mailTo}.',
    warn3: 'يرجى ملاحظة أنه لا يمكن تحويل الأموال بين حسابات للقراءة فقط.',
  },
  transferHistory: {
    payStatus: { processing: 'متابعة', paid: 'تم الدفع ', unpaid: 'لم يتم الدفع', rejected: 'تم الرفض' },
  },
  identityProof: {
    home: {
      header: 'طلب حساب غير مكتمل',
      content:
        'مرحبًا {name} ، طلب حسابك قد اكتمل تقريبًا ، ومع ذلك لا تزال بحاجة إلى إرسال وثائق إضافية لإتمام حسابك.',
    },
    shufti: {
      content: 'يرجى التأكد من مطابقة التفاصيل المقدمة لوثائق الهوية الخاصة بك ، وأنها باللغة نفسها.',
      countryOfDocument: 'البلد كما هو موضح في الوثيقة',
    },
    popup: {
      header: 'مرحبًا {name} ، قبل أن يتم الانتهاء من حسابك الحقيقي ، يتعين عليك تحميل المستند التالي للتحقق.',
      addressProofSpan: `<strong>تأكيد العنوان</strong><br />
      قم بإرسال أحد المستندات أدناه <br /> <br />
      - نسخة من فاتورة مرافق حديثة (لا يزيد عمرها عن 6 أشهر) <br />
      - نسخة من كشف حساب بنكي حديث (لا يزيد عن 6 أشهر) <br />
      <br />
      الأهمية! يجب أن يُظهر الاسم الموجود في المستند اسمك بالكامل وعنوانك الحالي بوضوح.`,
      idProofSpan: `<strong>تأكيد الهوية</strong><br />
      اختر أحد الخيارات أدناه <br /> <br />
      - نسخة من جواز السفر ساري المفعول <br />
      - نسخة من رخصة القيادة سارية المفعول <br />
      - صورة من بطاقة الرقم القومي (من الأمام والخلف) <br />
      <br />
      الأهمية! يجب أن يُظهر الاسم الموجود في المستند اسمك بالكامل بوضوح.`,
    },
    reasonType: {
      0: 'الصفحة الأولى من المستند مفقودة',
      1: 'الصفحة الخلفية من المستند مفقودة',
      2: 'الوثيقة غير واضحة',
      3: 'اسم فتح الحساب غير مطابق',
      4: 'الوثيقة منتهية الصلاحية',
      5: 'الوثيقة غير معرفة',
      6: 'الوثيقة ليست متكاملة',
      8: 'الوثيقة ليست متكاملة',
    },
    reason: 'تعذر التحقق من مستنداتك ، <br> السبب: {reason}. حاول مرة اخرى.',
  },
  header: { secPortal: 'منطقة العميل الآمنة <br />' },
  menu: {
    home: 'الرئيسية',
    accManagement: 'الحسابات',
    liveAcc: 'حسابات حقيقية',
    openAdditAcc: 'حسابات إضافية',
    homeDemo: 'حسابات تجريبية',
    depositFund: 'ايداع الاموال',
    withdrawFunds: 'سحب الاموال',
    transferBetweenAccs: 'تحويل الأموال',
    payDetails: 'تفاصيل الدفع',
    funds: 'اموال',
    transactionHis: 'سجل التحويلات',
    tranHis: 'التحويلات',
    download: 'التنزيلات ',
    profile: 'الملف الشخصي',
    myProfile: 'ملفي',
    cpPassword: 'تغيير كلمة المرور',
    contactUs: 'اتصل بنا',
    register: 'متابعة الطلب',
    tradingTools: 'ادوات تداول احترافية',
    MARKET_BUZZ: 'تنبيهات السوق',
    ECON_CALENDAR: 'التقويم الاقتصادي',
    FEAT_FX: 'الأفكار المميزة',
    FX_IDEAS: 'اراء وتحليلات',
    autochartist: 'برنامج Autochartist',
    promotion: 'العروض',
    noDeposit: 'لا مكافأة إيداع',
    referFriends: 'إحالة صديق .',
    depositBonus: '50% بونص على الإيداع',
    cryptoCashback: 'الاسترداد النقدي للعملات الرقمية (كاش باك)',
    firstTimeDeposit: `FTD كاش باك`,
    tradingTool: 'أدوات التداول',
  },
  contact: {
    click: 'انقر',
    assist: 'للحصول على المساعدة الآن',
    desc:
      'إن فريق دعم العملاء المحترف متعدد اللغات التابع لـ {platform} مستعد لمساعدتك ومساعدتك في توفير تجربة تداول لا تضاهى.',
    followUs: 'تابعنا',
    tip: 'للعملاء الذين حضروا عبر {email}',
    liveChat: 'الدردشة المباشرة',
  },
  depositResultDialog: { msg: 'هل أكملت إيداعك بنجاح' },
  dialog: { identityProof: { success: 'شكرا لك. لقد تلقينا مستنداتك بنجاح ونقوم حاليًا بمعالجتها للتحقق منها.' } },
  notification: {
    pendingIdentityProof: {
      header: 'معالجة طلب الحساب',
      id: 'اثبات الشخصية هو :   ',
      poa: 'العنوان هو :',
      both: 'اثبات الشخصية والعنوان هو',
      content:
        'يتم حاليًا معالجة إثبات {doc} الخاص بك وسيتم الانتهاء منه قريبًا. لتتبع معالجة حسابك المباشر بسرعة ، يمكنك تمويل حسابك الآن والحصول على أولوية فتح.',
    },
  },
  register: {
    progressStatus: { title: 'تقدم عملية التحقق', estimatedTimeTitle: 'الوقت المقدر', min: 'دقائق' },
    tabs: {
      personalDetails: 'تفاصيل شخصية',
      moreAboutYou: 'تقاصيل اكثر عنك',
      accountConfig: 'اعدادات الحساب',
      confirmYourId: 'تأكيد هويتك ',
      fundYourAcc: 'تمويل حسابك',
    },
    btn: { next: 'التالي', back: 'رجوع', fundnow: 'مول حسابك الان', upload: 'تحميل', done: 'تم ' },
    authority: {
      dialog1: 'يجب أن يكون لديك حساب حقيقي للوصول إلى هذا القسم من بوابة العميل.',
      dialog2:
        'افتح حسابًا حقيقيًا الآن لفتح الوصول الكامل إلى بوابة العميل الخاصة بك ، والتي تتضمن مجموعة من أدوات التداول والعروض الترويجية وغير ذلك الكثير.',
      openLiveAcc: 'إفتح حساب حقيقي',
    },
    demo: {
      congrad: 'تهانينا !',
      title: 'حسابك التجريبي لمدة 60 يومًا {platform} نشط الآن',
      title2: 'تفاصيل حسابك التجريبي',
      li: { login: 'تسجيل الدخول', srv: 'الخادم', expDate: 'تاريخ الانتهاء' },
      emailSent: '* تم أيضًا إرسال تفاصيل تسجيل الدخول إلى بريدك الإلكتروني المسجل.',
      dl: 'قم بتنزيل منصة التداول MT4 {platform} الخاصة بك',
      improveQ: 'هل ترغب في تحسين التداول الخاص بك',
      improveWay:
        'ببساطة ، افتح حسابًا حقيقيًا في دقائق معدودة لفتح مجموعة من أدوات التداول والعروض الترويجية الحصرية.',
      proTrade: 'تقارير تسويق احترافية',
      customerService: 'خدمة عملاء على مدار الساعة طوال أيام الأسبوع',
      competitivePromotionals: 'عروض ترويجية تنافسية',
      secure: 'تحويلات آمنة بتشفير 3D',
      openLiveAcc: 'إفتح حساب حقيقي',
    },
    personalDetails: {
      page1: {
        pageTitle: 'تفاصيل شخصية',
        userTitle: 'العنوان  ',
        firstName: 'الاسم الاول',
        lastName: 'الاسم الاخير',
        email: 'الايميل',
        dob: 'تاريخ الميلاد',
        idType: 'نوع الهوية',
        referral: 'هل تمت إحالتك من قبل أي شخص？ (اختياري)',
        middleName: 'الاسم الأوسط',
        nationality: 'الجنسية',
        phoneNum: 'رقم الهاتف',
        date: 'اليوم ',
        month: 'الشهر',
        year: 'السنة',
        placeOfBirth: 'تاريخ الميلاد',
        idNum: 'رقم التعريف',
        passportNum: 'رقم جواز السفر',
        driverLicenseNum: 'رقم رخصية القيادة',
        titleDropdown: { mr: 'السيد', mrs: 'السيدة', ms: 'الانسة', miss: 'السيدة', dr: 'دكتور/ة', prof: 'بروفسور/ة' },
        idTypeDropdown: { passport: 'جوا السفر', idCard: 'بطاقة الهوية الوطنية', driverLicense: 'رخصة القيادة' },
      },
      page2: {
        pageTitle: 'العنوان السكني الرئيسي',
        mainResidency: 'بلد الإقامة الرئيسي',
        streetNumber: 'رقم الشارع',
        streetName: 'اسم الشارع',
        address: 'العنوان',
        provinceOrState: 'المقاطعة / الولاية',
        cityOrSuburb: 'مدينة / ضاحية',
        postcode: 'الرمز البريدي',
        usQuestion:
          'هل أنت مواطن أمريكي / مقيم لأغراض ضريبية أو هل لديك أي روابط / روابط أمريكية (مثل حساب مصرفي ، وما إلى ذلك)',
      },
    },
    moreAboutYou: {
      pageTitle1: 'التوظيف والتفاصيل المالية',
      pageTitle2: 'المعرفة والخبرة في التداول',
      complianceExplain: 'يتم جمع الأسئلة التالية للامتثال لمتطلبات مكافحة غسل الأموال وتمويل الإرهاب.',
    },
    accountConfig: {
      pageTitle: 'اعدادات الحساب',
      chooseTradingPlatform: 'اختر منصة التداول',
      chooseAccType: 'اختر نوع الحساب',
      chooseAccCurrency: 'اختر عملة الحساب',
    },
    confirmYourId: {
      pageTitle: 'تأكيد هويتك ',
      description:
        'بصفتنا وسيط فوركس منظم ، فنحن مطالبون بالتحقق من هويتك. يرجى تحميل أحد المستندات التالية لإثبات الهوية وإثبات إثبات العنوان. بدلاً من ذلك ، يمكنك إرسال مستندات التحقق بالبريد الإلكتروني إلى {mailTo}',
      poidTitle: 'إثبات الهوية',
      porTitle: 'إثبات الإقامة',
      uploadDescription: 'اختر نوع المستند وقم بتحميله وفقًا لذلك',
      note: 'هام: يجب أن يُظهر الاسم الموجود في المستند اسمك بالكامل بوضوح.',
      pending: { upload: 'في انتظار التحميل', verification: 'في انتظار التحقق' },
      poidTypes: { photoId: 'صورة الهوية', passport: 'جوا السفر', drivingLicence: 'رخصة قيادة' },
      porTypes: `<li> فاتورة رسمية </ li>
      <li> كشف حساب بنكي </ li>
      <li> شهادة الإقامة </ li>`,
    },
    finishPage: {
      title: '{individualUserName} ، شكرًا لك على التقدم للحصول على حساب حقيقي على {platformName}!',
      contentDefault: `لقد تلقينا طلبك وقمنا بإعداد حسابك الحقيقي <b> {account} </b>. <br />
      ستتلقى قريبًا رسالة بريد إلكتروني على <b> {email} </b> تحتوي على تفاصيل تسجيل الدخول الخاصة بك ورابطًا لعرض حالة حسابك. <br />`,
      contentWithoutId3WithAccount: `تتم حاليًا معالجة حساب التداول الحي الخاص بك وسوف تتلقى رسالة بريد إلكتروني تحتوي على مزيد من المعلومات قريبًا. <br />
      لتتبع معالجة حسابك المباشر بسرعة ، يمكنك تمويل حسابك الآن والحصول على أولوية الفتح. <br />`,
      contentWithoutId3WithoutAccount:
        'تتم حاليًا معالجة حساب التداول الحي الخاص بك وسوف تتلقى رسالة بريد إلكتروني تحتوي على مزيد من المعلومات قريبًا. <br />',
    },
  },
  autochartist: {
    1: {
      title: `ماسح السوق`,
      desc01: `أول ماسح ضوئي للسوق في العالم يسمح لك بمسح قائمة مراقبة السوق الخاصة بك بحثًا عن فرص التداول.`,
      button01: `التحميل الان`,
    },
    2: {
      title: `تقرير السوق`,
      desc01: `توفر لك تقارير السوق من Autochartist لمحة سريعة عن النظرة الفنية للأسواق قبل افتتاح المراكز المالية الرئيسية.`,
      button01: `عرض تقارير السوق`,
    },
    3: {
      title: `بوابة أبحاث Autochartist`,
      desc01: `بوابة بحث شاملة بالكامل تتيح لك العثور على جميع البيانات المتاحة في مكان واحد.`,
      button01: `إطلاق`,
      button02: `دليل المستخدم`,
      button03: `الوصول إلى OTP`,
    },
    4: {
      title: `فرص التداول`,
      desc01: `تحقق من التحديثات المتعلقة بفرص التداول كل 15 دقيقة باستخدام الإعدادات الجديدة بناءً على جميع أنواع تحليل Autochartist.`,
      button01: `عرض فرص التداول`,
    },
    5: {
      title: `تطبيق Autochartist للهاتف المحمول`,
      desc01: `تلقي إشعارات الدفع وتتبع فرص التداول المصفاة ذات الاحتمالية العالية بناءً على قائمة مراقبتك داخل منصة التداول.`,
      desc02: `ملاحظات:`,
      desc03: `يمكن مسح رمز الاستجابة السريعة ضوئيًا من الكاميرا العادية ، بشرط أن يحتوي هاتف المستخدم على ماسح ضوئي QR مدمج ، أو من داخل تطبيق autochartist للجوال ، من خلال النقر على زر "تسجيل الدخول باستخدام رمز الاستجابة السريعة".`,
      desc04: `رمز الاستجابة السريعة صالح لمدة 10 دقائق فقط ، وبعد ذلك يجب تحديث الصورة لرمز QR جديد.`,
      desc05: `كود QR صالح للاستخدام الفردي فقط ، وبعد ذلك يجب تحديث الصورة لرمز QR جديد.`,
      button01: `تنزيل لـ IOS`,
      button02: `تنزيل لأندرويد`,
    },
    6: {
      title: `الاشتراك في النشرة الإخبارية`,
      desc01: `من خلال الاشتراك في نشرتنا الإخبارية ، ستتلقى رسائل بريد إلكتروني يومية مدعومة من Autochartist لمنحك لمحة عن أسواق الفوركس العالمية. التجارة مع اتحاد المحيط الهادئ والتجارة مع العالم.`,
      button01: `الإشتراك`,
    },
    footer: `يعد Autochartist الأداة الأكثر تقدمًا في العالم للتعرف التلقائي على فرص التداول في شكل أنماط الرسوم البيانية وأنماط فيبوناتشي والمستوى الرئيسي. تسمح ملكية Autochartist لمحركات البحث الخاصة بها بمراقبة آلاف الأدوات المالية على مدار 24 ساعة يوميًا للكشف عن فرص التداول فور حدوثها. بالإضافة إلى ذلك ، يقدم Autochartist أيضًا تحليل التقلبات كميزة ستمنحك تقييمًا أفضل للمخاطر والتقلبات على الأدوات التي تتداولها.`,
  },
  responseMsg: {
    410: 'فشل التحقق من صحة المعامل',
    411: 'كلمة سر خاطئة',
    420: 'المستخدم غير موجود',
    421: 'رفضت عملية إعادة تعيين كلمة المرور',
    490: 'الرجاء عدم تقديم معلومات بطاقة مكررة',
    500: 'حدث خطأ.',
    501: 'فشل التطبيق. الرجاء معاودة المحاولة في وقت لاحق.',
    505: 'فشلت عملية تحميل الملف',
    520: 'فشل تسجيل الدخول. حاول مرة اخرى.',
    521: 'تم تسجيل دخول المستخدم بالفعل على هذا الجهاز!',
    522: 'الرجاد الدخول على الحساب من جديد',
    523: 'الرجاد الدخول على الحساب من جديد',
    524: 'للأسف لم يتم التعرف على عنوان بريدك الإلكتروني',
    525: 'اسم المستخدم أو كلمة المرور غير صحيحة',
    527: 'المستخدم ليس وسيط تقديم',
    528: 'المستخدم غير موجود',
    529: 'اسم المستخدم أو كلمة المرور غير صحيحة',
    540: 'تعذر العثور على حساب التداول الخاص بك',
    541: 'لا يمكن العثور على حساب الخصم',
    542: 'لا يسمح لك بفتح حساب إضافي',
    544: 'لا يُسمح لك بتقديم طلب للحصول على حساب إضافي حتى يتم التحقق من إثبات هويتك',
    550: 'رقم الحساب غير صحيح',
    551: 'رصيدك غير كاف',
    553: 'لا يمكنك السحب / التحويل بسبب الرصيد في حسابك. يرجى الاتصال بـ {email} للحصول على مزيد من المساعدة',
    554: 'المبلغ المطلوب صفر أو فارغ',
    562: 'لم نتمكن من معالجة دفعتك ، يرجى المحاولة مرة أخرى. إذا استمرت المشكلة ، يرجى الاتصال بنا عبر الدردشة المباشرة أو البريد الإلكتروني {email}',
    564: 'البلد لا تتطابق مع قناة الدفع',
    565: 'رمز البنك غير صحيح',
    566: 'طريقة الدفع غير موجودة',
    568: 'هذا الحساب محظور من الإيداع، يرجى التواصل مع info@puprime.com',
    581: 'لقد نجحت في الاشتراك في الترقية. يرجى ملاحظة أنه يجب عليك عمل إيداع لسحب الأموال.',
    582: 'نظرًا لأنك دخلت الحملة ولديك صفقات مفتوحة ، فلن نتمكن من معالجة طلب السحب الخاص بك.',
    584: 'ليس لديك أي خصومات قابلة للتطبيق',
    587: 'رصيد غير كاف يرجى التواصل مع خدمة العملاء',
    590: 'لا يمكن العثور على حساب التحويل',
    591: 'لا يمكن العثور على حساب التحويل',
    593: 'لا يمكننا معالجة تحويل أموالك من الحساب المحدد لأن  حسابك يحتوي على رصيد.',
    594: 'لا يسمح بتحويل الأموال من هذا الحساب',
    595: 'نظرًا لأنك دخلت حملتنا ، فلن نتمكن من معالجة طلب التحويل الخاص بك',
    647: 'رمز التحقق غير صحيح',
    1001: ' لا يمكنك تسجيل الدخول إلى حسابك مؤقتاً، من فضلك  تواصل مع مدير الحساب الخاص بك.',
    session_timeout: 'انتهت مهلة الجلسة ، إعادة التوجيه إلى صفحة تسجيل الدخول.',
    vload: {
      4019: 'نوع القسيمة أو قيمتها غير مقبولَين',
      4071: 'شتباه في احتيال',
      4072: 'القسيمة مستخدمة',
      4073: 'القسيمة غير نشطة',
      4074: 'القسيمة منتهية الصلاحية',
      4075: 'لم نعثر على القسيمة',
      4080: 'تحتوي القسيمة على قيمة غير كافية',
      4444: 'استُخدِمت قسيمة غير صالحة. عدم تطابق العملة.',
      5000: 'بيانات المتجر غير متاحة',
    },
  },
  tradingTool: {
    mt4: {

    },
    pamm: {

    },
    copytrade: {

    }
  },
  activity: {
    myActivity: '',
    allActivity: '',
    activityContent: '',
    applyNow: '',
    activityStatus1: '',
    activityStatus2: '',
    activityStatus3: '',
    noActivity: '',
  }
}
